.calendarTemplate___2h3UJ {
  width: 74%;
  background: #fff;
  padding: 0 2.5rem 2rem 2.5rem;
}
.calendarTemplate___2h3UJ .headerCalendar___1t8LH {
  display: flex;
}
.calendarTemplate___2h3UJ .headerCalendar___1t8LH .nameCalendar___2aSwG {
  flex: 1 1;
  width: 50%;
}
.calendarTemplate___2h3UJ .headerCalendar___1t8LH .empty___3fOgq {
  flex: 1 1;
}
.calendarTemplate___2h3UJ .bodyCalendar___2TbcX {
  display: flex;
}
.calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 {
  flex: 1 1;
}
.calendarTemplate___2h3UJ .bodyCalendar___2TbcX .calendarBooking___1YGy- {
  flex: 1 1;
}
@media screen and (max-width: 1685px) {
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 .imageCustomer___37Ury {
    min-height: 450px;
  }
}
@media screen and (max-width: 1440px) {
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 .imageCustomer___37Ury {
    min-height: 400px;
  }
}
@media screen and (max-width: 1320px) {
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 .imageCustomer___37Ury {
    min-height: 350px;
  }
}
@media screen and (max-width: 1300px) {
  .calendarTemplate___2h3UJ {
    padding: 1rem;
  }
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 {
    padding: 0.5rem 0 1rem 0;
  }
}
@media screen and (max-width: 1070px) {
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 .imageCustomer___37Ury {
    min-height: 300px;
  }
}
@media screen and (max-width: 425px) {
  .calendarTemplate___2h3UJ {
    width: 100%;
    padding: unset;
    display: unset;
    height: unset;
  }
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .settingCalendar___3qol1 {
    padding: 1rem;
  }
  .calendarTemplate___2h3UJ .bodyCalendar___2TbcX .calendarBooking___1YGy- {
    padding-top: unset;
  }
}
.toolBox___2OzRY {
  width: 24%;
}
.toolBox___2OzRY .toolBoxItem___21mU7 {
  position: relative;
  background: #fff;
  border: 2px solid #4a6bc0;
  border-radius: 7px;
  margin-bottom: 1.8rem;
  cursor: pointer;
}
.toolBox___2OzRY .toolBoxItem___21mU7 .code___2Gkgu {
  position: absolute;
  background: #4a6bc0;
  border: 4px solid #fff;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
  left: -15px;
  top: -10px;
}
.toolBox___2OzRY .toolBoxItem___21mU7 .code___2Gkgu span {
  line-height: 20px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.toolBox___2OzRY .toolBoxItem___21mU7 .title___Hu0Re {
  font-size: 16px;
  background: #4a6bc0;
  color: #fff;
  padding: 0.2rem 0 0.2rem 1.5rem;
}
.toolBox___2OzRY .toolBoxItem___21mU7 .description___1IHaj {
  font-size: 14px;
  color: #3c3c3c;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
@media screen and (max-width: 425px) {
  .toolBox___2OzRY {
    display: none;
  }
}
.timeSettingTemplate___-7owo {
  display: inline-block;
  padding: 0.5rem 1.5rem !important;
}
@media screen and (max-width: 425px) {
  .timeSettingTemplate___-7owo {
    padding: 0.6rem 0 0.6rem 0.4rem !important;
  }
}
.markToolbox___2cmfL {
  position: absolute;
  background: #3c3c3c;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.toolBoxSetting___1fsH7 {
  position: absolute;
  background: #fff;
  z-index: 1;
  right: 1%;
  top: 7%;
  border-radius: 5px;
  max-width: 450px;
  padding: 0.8rem;
  border-top: 9px solid #4a6bc0;
}
.toolBoxSetting___1fsH7 .title___Hu0Re {
  color: #4a6bc0;
  font-size: 20px;
  padding-left: 0.5rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY {
  padding-left: 0.5rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepHeader___1wNd- {
  display: flex;
  font-size: 18px;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepHeader___1wNd- .stepName___1nkej {
  padding-right: 0.7rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepHeader___1wNd- .stepName___1nkej p {
  color: #4a6bc0;
  margin-bottom: 0;
  text-transform: uppercase;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepHeader___1wNd- span {
  color: #4a6bc0;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB {
  padding: 0 0 2rem 20px;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz {
  border: 2px solid #3c3c3c;
  border-radius: 15px;
  width: 65%;
  display: flex;
  font-size: 18px;
  background: #fff;
  margin-bottom: 1.5rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz div {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  flex: 1 1;
  text-align: center;
  border-radius: 12px 0 0 12px;
  color: #3c3c3c;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz div:last-child {
  border-radius: 0 12px 12px 0;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz div.active___2Mhax {
  background: #4a6bc0;
  color: #fff;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A:not(:last-child) {
  padding-bottom: 2rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingTitle___Pp4ap {
  font-size: 18px;
  color: #4a6bc0;
  margin-bottom: 0.5rem;
  display: flex;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc {
  width: 95%;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .inputTitle___1z8Bh {
  outline: none !important;
  border: 2px solid #3c3c3c !important;
  box-shadow: none;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .fontSize___1Na98 {
  width: 30%;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .inputName___sQdcS {
  border: 2px solid #3c3c3c;
  border-radius: 10px;
  width: 100%;
  outline: none;
  padding: 0.2rem 0.5rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 label:not(:last-child) {
  margin-bottom: 0.5rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ {
  display: flex;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ div {
  background: #4a6bc0;
  border: 4px solid #fff;
  border-radius: 25px;
  width: 29px;
  height: 29px;
  text-align: center;
  margin-right: 1.5rem;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ div span {
  line-height: 18px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ .label___2Jbmy {
  color: #3c3c3c;
  font-size: 18px;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.toolBoxSetting___1fsH7 .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .ant-select-single .ant-select-selector {
  width: 90px;
  border: 2px solid #3c3c3c !important;
  border-radius: 8px !important;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n {
  padding-left: 0 !important;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A:not(:last-child) {
  padding-bottom: 1.2rem !important;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc {
  width: 90%;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd {
  border: 2px solid #3773e1;
  border-radius: 6px;
  padding: 1rem 1.5rem;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd .formSettingTitle___Pp4ap {
  color: #3773e1 !important;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd .formSettingTitle___Pp4ap div {
  background: #3773e1 !important;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd .radioSelect___1OdmX {
  margin-bottom: 1rem;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .selectEvent___24cT2 {
  width: 100%;
  margin-bottom: 1.2rem;
}
.toolBoxSetting___1fsH7 .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .selectEvent___24cT2 .ant-select-selector {
  width: 100%;
  height: 35px;
  border: 2px solid #3773e1 !important;
}
.toolBoxSetting___1fsH7 .btnSubmit___1GC3S {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.toolBoxSetting___1fsH7 .btnSubmit___1GC3S button {
  min-width: 110px;
  height: 90%;
  border: 1px solid transparent;
  background: #3773e1;
  color: #fff;
  border-radius: 0.6rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  box-shadow: #00000017 0 2px 8px 0;
}
@media screen and (min-width: 1024px) {
  .toolBoxSetting___1fsH7 {
    width: 28%;
  }
}
.toolBoxSetting1___cfdMx {
  position: absolute;
  background: #fff;
  z-index: 1;
  right: -410px;
  top: 0;
  border-radius: 5px;
  width: 370px;
  padding: 0.8rem;
  border-top: 9px solid #4a6bc0;
}
.toolBoxSetting1___cfdMx .title___Hu0Re {
  color: #4a6bc0;
  font-size: 20px;
  padding-left: 0.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY {
  padding-left: 0.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepHeader___1wNd- {
  display: flex;
  font-size: 18px;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepHeader___1wNd- .stepName___1nkej {
  padding-right: 0.7rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepHeader___1wNd- .stepName___1nkej p {
  font-weight: bold;
  color: #4a6bc0;
  margin-bottom: 0;
  text-transform: uppercase;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepHeader___1wNd- span {
  font-weight: bold;
  color: #4a6bc0;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB {
  padding: 1rem 0 2rem 20px;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz {
  border: 2px solid #3c3c3c;
  border-radius: 15px;
  width: 65%;
  display: flex;
  font-size: 18px;
  background: #fff;
  margin-bottom: 1.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz div {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  flex: 1 1;
  text-align: center;
  border-radius: 12px 0 0 12px;
  color: #3c3c3c;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz div:last-child {
  border-radius: 0 12px 12px 0;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .switchSetting___3OsLz div.active___2Mhax {
  background: #4a6bc0;
  color: #fff;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A:not(:last-child) {
  padding-bottom: 2rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingTitle___Pp4ap {
  font-weight: bold;
  font-size: 18px;
  color: #3c3c3c;
  margin-bottom: 0.5rem;
  display: flex;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingTitle___Pp4ap div {
  height: 1.4rem;
  width: 7px;
  background: #3c3c3c;
  margin: auto 0;
  margin-right: 0.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc {
  width: 95%;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .inputTitle___1z8Bh {
  outline: none !important;
  border: 2px solid #3c3c3c !important;
  box-shadow: none;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .fontSize___1Na98 {
  width: 30%;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .inputName___sQdcS {
  border: 2px solid #3c3c3c;
  border-radius: 10px;
  width: 100%;
  outline: none;
  padding: 0.2rem 0.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 label:not(:last-child) {
  margin-bottom: 0.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ {
  display: flex;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ div {
  background: #4a6bc0;
  border: 4px solid #fff;
  border-radius: 25px;
  width: 29px;
  height: 29px;
  text-align: center;
  margin-right: 1.5rem;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ div span {
  line-height: 18px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .listCheckboxConfirm___2J7H3 .inputCheckbox___2EbUQ .label___2Jbmy {
  color: #3c3c3c;
  font-size: 18px;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.toolBoxSetting1___cfdMx .stepItem___3-pmY .stepContent___3_QPB .formSettingItem___3HB5A .ant-select-single .ant-select-selector {
  width: 90px;
  border: 2px solid #3c3c3c !important;
  border-radius: 8px !important;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n {
  padding-left: 0 !important;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A:not(:last-child) {
  padding-bottom: 1.2rem !important;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc {
  width: 90%;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd {
  border: 2px solid #3773e1;
  border-radius: 6px;
  padding: 1rem 1.5rem;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd .formSettingTitle___Pp4ap {
  color: #3773e1 !important;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd .formSettingTitle___Pp4ap div {
  background: #3773e1 !important;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .listMemberSelect___QPSGd .radioSelect___1OdmX {
  margin-bottom: 1rem;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .selectEvent___24cT2 {
  width: 100%;
  margin-bottom: 1.2rem;
}
.toolBoxSetting1___cfdMx .stepItemCalendar___2Ef-n .stepContent___3_QPB .formSettingItem___3HB5A .formSettingContent___3PATc .selectEvent___24cT2 .ant-select-selector {
  width: 100%;
  height: 35px;
  border: 2px solid #3773e1 !important;
}
.toolBoxSetting1___cfdMx .btnSubmit___1GC3S {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.toolBoxSetting1___cfdMx .btnSubmit___1GC3S button {
  min-width: 110px;
  height: 90%;
  border: 1px solid transparent;
  background: #3773e1;
  color: #fff;
  border-radius: 0.6rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  box-shadow: #00000017 0 2px 8px 0;
}
@media screen and (min-width: 1024px) {
  .toolBoxSetting1___cfdMx {
    width: 370px;
  }
}
@media screen and (min-width: 560px) {
  .toolBoxSettingA___3vctI {
    top: 5%;
    left: 38%;
  }
}
