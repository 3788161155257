.timeSetting___1q-O6 p {
  font-size: 14px;
  color: #3c3c3c;
  margin-bottom: 0;
  display: flex;
}
.timeSetting___1q-O6 p div {
  height: 14px;
  width: 14px;
  background: #3c3c3c;
  margin: auto 0.3rem 3px 0;
}
.timeSetting___1q-O6 p:first-child {
  font-weight: bold;
}
@media screen and (max-width: 425px) {
  .timeSetting___1q-O6 p:nth-child(2),
  .timeSetting___1q-O6 p:nth-child(3) {
    padding-left: 22px;
  }
}
