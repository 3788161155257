@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2lZEs {
  display: flex;
  justify-content: center;
}
button.btn___1yJP8 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1yJP8 {
    padding: 10px;
  }
}
button.btn___1yJP8:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1yJP8:focus {
  outline: none;
}
.btnWhite___22dLe.btn___1yJP8 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___22dLe.btn___1yJP8:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___LOUpq.btn___1yJP8,
.btnGreen___LOUpq.btn___1yJP8:hover,
.btnGreen___LOUpq.btn___1yJP8:active,
.btnGreen___LOUpq.btn___1yJP8:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3k0N_ {
  color: #fe0000;
}
.errorMessage___14ZZM {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1lWxB {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2vF32 {
  display: none !important;
}
.m-auto___2fs8s {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2BMKQ {
  max-width: 120px !important;
}
.fc___33mWD .fc-timegrid-col___1xO3e.fc-day-today___3eSjH {
  background-color: #ffffff;
}
.fc___33mWD .fc-timegrid-slot___1iJzD {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___33mWD .fc-timegrid-slot___1iJzD {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___29to1 .fc-direction-ltr___174X_ .fc-timegrid-slot-label-frame___2GRUr {
    font-size: 10px;
  }
  .bookingCalendar___29to1 .fc___33mWD .fc-timegrid-slot-label-cushion___2M9Gh {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___29to1 .fc___33mWD .fc-scrollgrid-section-header___3K_GO .fc-scroller___2S3Ch {
    overflow: unset !important;
  }
}
.ant-message-notice___1Zz_3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___KVxXW {
  width: 20px;
  height: 20px;
}
.bgTransparent___1IoSX {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1ZA7J {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___33u39 {
  position: relative;
}
.inputCustomValue___33u39 .ant-input___1vFkj {
  padding-right: 50px;
}
.inputCustomValueText___1hDJ5 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___it1f2 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___it1f2 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___if6Ts .ant-radio-inner___2Qhii {
  border-color: black !important ;
}
.ant-radio-checked___if6Ts .ant-radio-inner___2Qhii:after {
  background-color: black;
}
.ant-radio___35xea:hover .ant-radio-inner___2Qhii {
  border-color: black;
}
.ant-radio-checked___if6Ts .ant-radio-inner___2Qhii:focus {
  border-color: black;
}
.modal___1jCCJ {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1jCCJ .close___2sj1t {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1jCCJ .header___22kJR {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1jCCJ .header___22kJR p {
  margin: 0;
}
.modal___1jCCJ .content___3I-8H {
  text-align: center;
  font-size: 14px;
}
.modal___1jCCJ .content___3I-8H p {
  margin: 0;
}
.modal___1jCCJ .actions___3xgf9 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1jCCJ .actions___3xgf9 .button___3neln {
  margin-top: 10px;
}
.modal___1jCCJ .actions___3xgf9:hover {
  color: #33c3c7;
}
.fc___33mWD .fc-timegrid-slot-minor___1l6z4 {
  border-top-style: none;
}
.fc___33mWD .fc-timegrid-slot___1iJzD {
  height: 1.5em !important;
}
.messageError___1AOTi .ant-message-custom-content___Twmb_ {
  display: flex;
  text-align: start;
}
.messageError___1AOTi .ant-message-custom-content___Twmb_ span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___30TBl {
  background-color: #e6e4e4;
}
.notifyMessage___1T-06 .ant-message-notice-content___3LXFk {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1T-06 .ant-message-notice-content___3LXFk .anticon___2j3FG {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1T-06 .ant-message-notice-content___3LXFk span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1QViu {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1QViu .ant-notification-notice-message___Nbo07,
.notifyCustomize___1QViu .ant-notification-notice-close___Lev4L,
.notifyCustomize___1QViu .ant-notification-notice-icon___21cke {
  color: white;
}
.notifyCustomize___1QViu .ant-notification-notice-message___Nbo07 {
  margin-left: 35px;
}
.notifyCustomize___1QViu p {
  margin-bottom: 0;
}
.notifyCustomize___1QViu p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1xZfi {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1xZfi .ant-notification-notice-message___Nbo07 {
  margin-left: 35px;
}
.notifyAlertDuplicate___1xZfi p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1xZfi p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3inmJ {
  width: 434px !important;
}
.handleSubmitVote___3inmJ .ant-btn-primary___2dMGB {
  background: #59c3c7;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 .ant-modal-confirm-body___2m7B4 {
  display: flex !important;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 .ant-modal-confirm-body___2m7B4 .anticon___2j3FG {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 .ant-modal-confirm-btns___2pHUU button {
  font-weight: bold;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 .ant-modal-confirm-btns___2pHUU button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 .ant-modal-confirm-btns___2pHUU button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3inmJ .ant-modal-body___2EZU1 .ant-modal-confirm-btns___2pHUU .ant-btn-primary___2dMGB {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___dMCdN {
  margin-bottom: 0;
}
.mt-0___1rfaS {
  margin-top: 0;
}
.tooltipFormat___2wzOU p {
  margin-bottom: 0;
}
.bgWhite___1nME8 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3ceEo {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3ceEo:hover,
.bgPrimaryBlue___3ceEo:focus,
.bgPrimaryBlue___3ceEo:active {
  background: #4f7ac7;
}
.bgDarkBlue___3sVBd {
  background-color: #023768 !important;
}
.bgDarkBlue___3sVBd:hover,
.bgDarkBlue___3sVBd:focus,
.bgDarkBlue___3sVBd:active {
  background: #194d7d;
}
.bgGreyBlue___3qQkp {
  background-color: #ebf0f9;
}
.bgLightBlue___15f5O {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2htt- {
  background-color: #e4453a;
}
.bgLightRed___2htt-:hover,
.bgLightRed___2htt-:focus,
.bgLightRed___2htt-:active {
  background: #e17871;
}
.bgLightYellow___1D60M {
  background-color: #ffd603;
}
.bgDarkGray___NKadC {
  background-color: #333333;
}
.bgMediumGray___2w4dK {
  background-color: #a2a2a2;
}
.bgLightGray___d1xy7 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___opw4_ {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1TJ2a {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___6kNFR {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3IPx9 {
  color: #3368c7 !important;
}
.textDarkBlue___2cPVl {
  color: #023768 !important;
}
.textLightBlue___3yMcd {
  color: #b2cbf7;
}
.textDarkGray___3qAwj {
  color: #333333 !important;
}
.textMediumGray___3SImy {
  color: #a2a2a2 !important;
}
.textLightGray___39Jxg {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1hcKP {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3-MTz {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2Wxjj {
  border-radius: 5px !important;
}
.rounded-xs___3MZrP {
  border-radius: 10px !important;
}
.rounded-sm___MaSmW {
  border-radius: 15px !important;
}
.rounded-md___3Cb0h {
  border-radius: 20px !important;
}
.rounded-lg___1gSNf {
  border-radius: 25px !important;
}
.rounded-full___3lfVD {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1EHMw {
  margin: 0;
}
.mt-2___N1qrs {
  margin-top: 0.5rem;
}
.my-2___2xSSR {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___WIeYs {
  padding: 0;
}
.px-1___3q8gn {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3-Zei {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___23zuu {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1kerC {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___CkYnV {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___VAqvE {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3qvDP {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2msuX {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3poCF {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1xjxy {
  padding-bottom: 20px;
}
.h-full___3JOyk {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___jY33X {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2O4CH {
  font-weight: bold !important;
}
.fontWeight400___3FDV8 {
  font-weight: 400 !important;
}
.fontWeight500___3H1sT {
  font-weight: 500 !important;
}
.fontWeight600___39qYN {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___FlqBD {
  font-size: 12px !important;
}
.fontSize14___309eM {
  font-size: 14px !important;
}
.fontSize16___dpWi7 {
  font-size: 16px !important;
}
.fontSize18___1pbP0 {
  font-size: 18px !important;
}
.fontSize20___2NS4m {
  font-size: 20px !important;
}
.fontSize24___1NCdX {
  font-size: 24px !important;
}
.eventName___2nnGi {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3bQKZ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___bzG7W > tr > th,
.ant-table-tbody___HhWHb > tr.ant-table-row___2kr28 > td,
.ant-table-summary___ESa_r tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___bzG7W > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1ZiT6 .ant-table-thead___bzG7W > tr > th,
.table-portrait___1ZiT6 .ant-table-tbody___HhWHb > tr.ant-table-row___2kr28 > td,
.table-portrait___1ZiT6 .ant-table-summary___ESa_r tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1ZiT6 .bgLightGray___d1xy7 td {
  background: #ebebeb;
}
.table-portrait___1ZiT6 .bgLightBlue___15f5O td {
  background: #b2cbf7;
}
.eventContent___im-lA .timeText___3uhlB {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___HhWHb > tr.bgLightGray___d1xy7:hover > td,
.ant-table-tbody___HhWHb > tr > td.ant-table-cell-row-hover___3pNnB {
  background: #ebebeb;
}
.ant-table-tbody___HhWHb > tr.bgLightBlue___15f5O:hover > td,
.ant-table-tbody___HhWHb > tr > td.ant-table-cell-row-hover___3pNnB {
  background: #b2cbf7;
}
.ant-table-tbody___HhWHb > tr.bgWhite___1nME8:hover > td,
.ant-table-tbody___HhWHb > tr > td.ant-table-cell-row-hover___3pNnB {
  background: #ffffff;
}
