@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___146L9 {
  display: flex;
  justify-content: center;
}
button.btn___1aid4 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1aid4 {
    padding: 10px;
  }
}
button.btn___1aid4:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1aid4:focus {
  outline: none;
}
.btnWhite___RRiyI.btn___1aid4 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___RRiyI.btn___1aid4:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3Ao1f.btn___1aid4,
.btnGreen___3Ao1f.btn___1aid4:hover,
.btnGreen___3Ao1f.btn___1aid4:active,
.btnGreen___3Ao1f.btn___1aid4:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1Jjl9 {
  color: #fe0000;
}
.errorMessage___3XcXz {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1B-X7 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1pCAj {
  display: none !important;
}
.m-auto___IXhgG {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___23x1P {
  max-width: 120px !important;
}
.fc___M8Ol2 .fc-timegrid-col___1Wx1C.fc-day-today___2pMZf {
  background-color: #ffffff;
}
.fc___M8Ol2 .fc-timegrid-slot___2wRnH {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___M8Ol2 .fc-timegrid-slot___2wRnH {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3sqrA .fc-direction-ltr___SWsgu .fc-timegrid-slot-label-frame___1wLiy {
    font-size: 10px;
  }
  .bookingCalendar___3sqrA .fc___M8Ol2 .fc-timegrid-slot-label-cushion___uNvGF {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3sqrA .fc___M8Ol2 .fc-scrollgrid-section-header___s1hlP .fc-scroller___jdGJo {
    overflow: unset !important;
  }
}
.ant-message-notice___2DEQJ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2jTDy {
  width: 20px;
  height: 20px;
}
.bgTransparent___36Lgz {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___LP5q_ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1dosA {
  position: relative;
}
.inputCustomValue___1dosA .ant-input___2FW9_ {
  padding-right: 50px;
}
.inputCustomValueText___2YHeA {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___27cz- .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___27cz- .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2WcIp .ant-radio-inner___C0uYv {
  border-color: black !important ;
}
.ant-radio-checked___2WcIp .ant-radio-inner___C0uYv:after {
  background-color: black;
}
.ant-radio___3sSAr:hover .ant-radio-inner___C0uYv {
  border-color: black;
}
.ant-radio-checked___2WcIp .ant-radio-inner___C0uYv:focus {
  border-color: black;
}
.modal___YGDK9 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___YGDK9 .close___1Ov10 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___YGDK9 .header___3w28T {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___YGDK9 .header___3w28T p {
  margin: 0;
}
.modal___YGDK9 .content___2eGGJ {
  text-align: center;
  font-size: 14px;
}
.modal___YGDK9 .content___2eGGJ p {
  margin: 0;
}
.modal___YGDK9 .actions___2T3yO {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___YGDK9 .actions___2T3yO .button___26JCZ {
  margin-top: 10px;
}
.modal___YGDK9 .actions___2T3yO:hover {
  color: #33c3c7;
}
.fc___M8Ol2 .fc-timegrid-slot-minor___2U1_y {
  border-top-style: none;
}
.fc___M8Ol2 .fc-timegrid-slot___2wRnH {
  height: 1.5em !important;
}
.messageError___2zJp- .ant-message-custom-content___2R0ql {
  display: flex;
  text-align: start;
}
.messageError___2zJp- .ant-message-custom-content___2R0ql span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___KaoNW {
  background-color: #e6e4e4;
}
.notifyMessage___3IWLd .ant-message-notice-content___huxXF {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3IWLd .ant-message-notice-content___huxXF .anticon___1rVUM {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3IWLd .ant-message-notice-content___huxXF span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1Wecl {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1Wecl .ant-notification-notice-message___f5S3D,
.notifyCustomize___1Wecl .ant-notification-notice-close___1oCrV,
.notifyCustomize___1Wecl .ant-notification-notice-icon___3bOGP {
  color: white;
}
.notifyCustomize___1Wecl .ant-notification-notice-message___f5S3D {
  margin-left: 35px;
}
.notifyCustomize___1Wecl p {
  margin-bottom: 0;
}
.notifyCustomize___1Wecl p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1B_wY {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1B_wY .ant-notification-notice-message___f5S3D {
  margin-left: 35px;
}
.notifyAlertDuplicate___1B_wY p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1B_wY p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2By5u {
  width: 434px !important;
}
.handleSubmitVote___2By5u .ant-btn-primary___21iR_ {
  background: #59c3c7;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 .ant-modal-confirm-body___3Re7b {
  display: flex !important;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 .ant-modal-confirm-body___3Re7b .anticon___1rVUM {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 .ant-modal-confirm-btns___3XGgF button {
  font-weight: bold;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 .ant-modal-confirm-btns___3XGgF button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 .ant-modal-confirm-btns___3XGgF button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2By5u .ant-modal-body___LFG37 .ant-modal-confirm-btns___3XGgF .ant-btn-primary___21iR_ {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3jCsV {
  margin-bottom: 0;
}
.mt-0___zcumm {
  margin-top: 0;
}
.tooltipFormat___1kd2K p {
  margin-bottom: 0;
}
.bgWhite___3xLGY {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3nxk- {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3nxk-:hover,
.bgPrimaryBlue___3nxk-:focus,
.bgPrimaryBlue___3nxk-:active {
  background: #4f7ac7;
}
.bgDarkBlue___UzNVz {
  background-color: #023768 !important;
}
.bgDarkBlue___UzNVz:hover,
.bgDarkBlue___UzNVz:focus,
.bgDarkBlue___UzNVz:active {
  background: #194d7d;
}
.bgGreyBlue___3vitm {
  background-color: #ebf0f9;
}
.bgLightBlue___3kaLJ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3j_ih {
  background-color: #e4453a;
}
.bgLightRed___3j_ih:hover,
.bgLightRed___3j_ih:focus,
.bgLightRed___3j_ih:active {
  background: #e17871;
}
.bgLightYellow___3k2WQ {
  background-color: #ffd603;
}
.bgDarkGray___32nf3 {
  background-color: #333333;
}
.bgMediumGray___2vw8y {
  background-color: #a2a2a2;
}
.bgLightGray___ypFxU {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___29PCi {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___k_ZFc {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___tVY-h {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3MBWn {
  color: #3368c7 !important;
}
.textDarkBlue___1pP9H {
  color: #023768 !important;
}
.textLightBlue___17nZa {
  color: #b2cbf7;
}
.textDarkGray___2niZt {
  color: #333333 !important;
}
.textMediumGray___3P89D {
  color: #a2a2a2 !important;
}
.textLightGray___30q0x {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1gt7S {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___24bb7 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3t5m3 {
  border-radius: 5px !important;
}
.rounded-xs___1ok2r {
  border-radius: 10px !important;
}
.rounded-sm___24GZt {
  border-radius: 15px !important;
}
.rounded-md___3ElkV {
  border-radius: 20px !important;
}
.rounded-lg___1_eOt {
  border-radius: 25px !important;
}
.rounded-full___1nH6M {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___aHP0n {
  margin: 0;
}
.mt-2___1uFac {
  margin-top: 0.5rem;
}
.my-2___2pKkD {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___kLTzX {
  padding: 0;
}
.px-1___1nSjv {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2SufL {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1qDRw {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___mXGyv {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1yhDF {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1w4kU {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2ItvJ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1K7a3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3_5sa {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___rg9Ix {
  padding-bottom: 20px;
}
.h-full___1hwqN {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___25MiA {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2j8F6 {
  font-weight: bold !important;
}
.fontWeight400___3wQ_L {
  font-weight: 400 !important;
}
.fontWeight500___q-Ieg {
  font-weight: 500 !important;
}
.fontWeight600___9aj2K {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2axIk {
  font-size: 12px !important;
}
.fontSize14___1kiAY {
  font-size: 14px !important;
}
.fontSize16___2RcAm {
  font-size: 16px !important;
}
.fontSize18___3u4tS {
  font-size: 18px !important;
}
.fontSize20___2fWkS {
  font-size: 20px !important;
}
.fontSize24___dFSYm {
  font-size: 24px !important;
}
.eventName___1cvN_ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3Nrsz {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2dq1U > tr > th,
.ant-table-tbody___2W3qh > tr.ant-table-row___39RlY > td,
.ant-table-summary___z2JBB tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2dq1U > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1Mdzc .ant-table-thead___2dq1U > tr > th,
.table-portrait___1Mdzc .ant-table-tbody___2W3qh > tr.ant-table-row___39RlY > td,
.table-portrait___1Mdzc .ant-table-summary___z2JBB tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1Mdzc .bgLightGray___ypFxU td {
  background: #ebebeb;
}
.table-portrait___1Mdzc .bgLightBlue___3kaLJ td {
  background: #b2cbf7;
}
.eventContent___hsbnV .timeText___1xC_O {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2W3qh > tr.bgLightGray___ypFxU:hover > td,
.ant-table-tbody___2W3qh > tr > td.ant-table-cell-row-hover___16Knq {
  background: #ebebeb;
}
.ant-table-tbody___2W3qh > tr.bgLightBlue___3kaLJ:hover > td,
.ant-table-tbody___2W3qh > tr > td.ant-table-cell-row-hover___16Knq {
  background: #b2cbf7;
}
.ant-table-tbody___2W3qh > tr.bgWhite___3xLGY:hover > td,
.ant-table-tbody___2W3qh > tr > td.ant-table-cell-row-hover___16Knq {
  background: #ffffff;
}
.calendarCreation___3ZPH5 {
  display: flex;
  flex-flow: row;
}
.calendarCreation___3ZPH5 .settingBasic___3wGe4 {
  display: none;
  width: 45%;
  max-width: 495px;
  background: #f1fbfb;
  transition: width 2s ease;
}
.calendarCreation___3ZPH5 .settingBasic___3wGe4.active___2jJCV {
  background: #fff;
  border-right: 1px #f7f5f8 solid;
}
.calendarCreation___3ZPH5 .settingBasicActive___2V3FH {
  display: unset;
}
.calendarCreation___3ZPH5 .settingBasicMb___1ys5N {
  display: none;
  top: 20px;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz {
  padding: 20px 30px;
  background: #fff;
  width: 100%;
  position: relative;
  transition: width 2s ease;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp {
  text-align: center;
  font-size: 20px;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp br {
  display: none;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX {
  display: flex;
  justify-content: space-around;
  margin: 30px 0 20px;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX button {
  height: 40px;
  width: 28%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF {
  background: white;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
  z-index: 350;
  display: none;
  justify-content: space-evenly;
  padding: 0.5rem 0.1rem;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF button {
  width: 40%;
  padding: 0.8rem 0.1rem;
  border: none;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF button:first-child {
  background: transparent;
  color: #33c3c7;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF button:last-child {
  background: #9d9d9d;
  color: white;
  font-weight: 600;
  border-radius: 6px;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF button:last-child.active___2jJCV {
  background: #00b6d3;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W {
  background: #f5f5f5;
  height: 20px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  bottom: 9vh;
  left: 0;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .previewBtnZone___O51N_ {
  position: absolute;
  top: 100%;
  left: 1%;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .previewBtnZone___O51N_ .previewButton___2wsYw {
  border: transparent;
  outline: none;
  cursor: pointer;
  height: 32px;
  min-width: 170px;
  background-color: #555555;
  color: #fffefe;
  text-align: center;
  border-radius: 6px;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .listBtn___3a178 {
  text-align: center;
  background: #f5f5f5;
  border: 2px solid #ececec;
  padding: 0.5rem 0;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .listBtn___3a178 button {
  height: 48px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 17px;
  border-radius: 19px;
  width: 100%;
  border: transparent;
  outline: none;
  cursor: pointer;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .listBtn___3a178 button:first-child {
  margin: 0 20px 0 0;
  color: #535353;
  background-color: #fffefe;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border: none;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .listBtn___3a178 button:last-child {
  margin: 0 20px;
  color: #fffefe;
  background-color: #00b6d3;
  transition: 0.3s;
}
.calendarCreation___3ZPH5 .formCalendar___1MeBz .btnSaveGroup___saM0W .listBtn___3a178 .disabledBtnSave___1GJQ5 {
  background: #a6a3a3 !important;
}
.calendarCreation___3ZPH5 .formCalendarActive___1vUFu {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .calendarCreation___3ZPH5 .settingBasic___3wGe4 {
    width: 40%;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp br {
    display: block;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX {
    flex-direction: column;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX button {
    width: 30%;
    margin: auto;
    min-width: 200px;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX button:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .calendarCreation___3ZPH5 {
    padding: 25px 0;
    flex-wrap: wrap;
    position: relative;
  }
  .calendarCreation___3ZPH5 .settingBasicActive___2V3FH {
    top: -40px;
    position: absolute;
    z-index: 250;
    width: calc(100vw - 28px);
    max-width: unset;
  }
  .calendarCreation___3ZPH5 .settingBasic___3wGe4 {
    display: none;
  }
  .calendarCreation___3ZPH5 .settingBasicMb___1ys5N {
    display: unset;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz {
    padding: 0;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp br {
    display: block;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX {
    flex-direction: column;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX button {
    width: 30%;
    margin: auto;
    min-width: 200px;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .saveToTemplateBtn___3ELcX button:not(:last-child) {
    margin-bottom: 2rem;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF {
    display: flex;
  }
}
@media screen and (max-width: 470px) {
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp {
    font-size: 18px;
  }
}
@media screen and (max-width: 425px) {
  .calendarCreation___3ZPH5 {
    padding: 1rem 0 2rem 0;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp {
    font-size: 16px;
  }
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .groupSaveMb___1JWDF {
    display: none;
  }
}
@media screen and (max-width: 381px) {
  .calendarCreation___3ZPH5 .formCalendar___1MeBz .registerTemplateText___3E8Tp {
    font-size: 15px;
  }
}
.calendarSM___1p2vR {
  display: flex;
  flex-flow: row;
}
.advancedSettingContainer___1GPbA {
  max-width: 1080px;
  padding: 30px 15px 80px;
  margin: auto;
}
.advancedSettingContainer___1GPbA .collapseSettingContainer___GdvTu {
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 35px;
}
.advancedSettingContainer___1GPbA .messageSettingContainer___IVSKh .ant-collapse-header .ant-collapse-arrow {
  left: 250px !important;
}
.advancedSettingContainer___1GPbA .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 180px;
  right: unset;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%);
}
.advancedSettingContainer___1GPbA .ant-collapse {
  border: 0;
  background-color: #fff;
}
.advancedSettingContainer___1GPbA .ant-collapse-header,
.advancedSettingContainer___1GPbA .ant-collapse > .ant-collapse-item:last-child,
.advancedSettingContainer___1GPbA .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: #fff;
  border-radius: 20px;
}
.advancedSettingContainer___1GPbA .ant-collapse-content,
.advancedSettingContainer___1GPbA .ant-collapse-item:last-child > .ant-collapse-content {
  border-top: 0;
  border-radius: 0 0 20px 20px;
}
.advancedSettingContainer___1GPbA .ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.advancedSettingContainer___1GPbA .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 10px;
  height: 23px;
  min-width: 99px;
  border: 2px solid #d9d9d9 !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: #414141;
  line-height: 23px;
  display: flex;
  align-items: center;
}
.advancedSettingContainer___1GPbA .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.advancedSettingContainer___1GPbA .ant-picker {
  max-width: 100px;
  font-size: 13px;
  height: 24px;
  border-radius: 15px;
  border-width: 2px;
}
.advancedSettingContainer___1GPbA .ant-picker-input > input {
  font-size: 13px;
}
.advancedSettingContainer___1GPbA .scheduleSetting___37XKx {
  padding: 24px 64px 0 64px;
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 40px;
}
.advancedSettingContainer___1GPbA .timeSetting___3j53- {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___1GPbA .messageSetting___3u1UD {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 .dropdownHeaderTitle___pb6PP {
  font-size: 26px;
}
.advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 img {
  height: 23px;
  width: 23px;
  margin-right: 8px;
}
.advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 a {
  font-size: 25px;
  color: #414141;
}
.advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 a .anticon-down {
  margin-left: 30px;
}
.advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 .helperDropdown___1uuEX {
  margin-left: 55px;
}
@media screen and (max-width: 768px) {
  .advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 {
    position: relative;
    width: 100%;
  }
  .advancedSettingContainer___1GPbA .dropdownHeader___2WJY4 .dropdownHeaderTitle___pb6PP {
    font-size: 16px;
  }
}
.buttonZoneContainer___1Dn64 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.buttonZoneContainer___1Dn64 .backBtn___3U7wm {
  height: 48px;
  min-width: 148px;
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #ffffff;
  color: #414141;
  box-shadow: 1px 1px 12px #b3bcbc60;
}
.helper___2jTDy {
  margin-left: 75px;
  margin-top: 7px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 767px) {
  .helper___2jTDy {
    position: absolute;
    right: 0;
  }
}
.helper___2jTDy img {
  position: relative;
}
.helper___2jTDy .helperTooltip___6go0g {
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 50px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 120px;
  width: 400px;
  background-color: #414141;
  color: #fffefe;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.helper___2jTDy .helperTooltip___6go0g::-webkit-scrollbar {
  display: none;
}
.helper___2jTDy .helperTooltip___6go0g img {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.helper___2jTDy:hover .helperTooltip___6go0g {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
.tooltipAdvanced___2IWKb {
  white-space: pre-line;
}
.tooltipAdvanced___2IWKb .ant-tooltip-inner {
  height: 119px;
  min-width: 300px;
  justify-content: center;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  .tooltipAdvanced___2IWKb .ant-tooltip-inner {
    min-width: auto;
    height: auto;
  }
}
.tooltipAdvanced___2IWKb .ant-tooltip-content .ant-tooltip-arrow {
  width: 30px;
  height: 30px;
}
.tooltipAdvanced___2IWKb .ant-tooltip-content .ant-tooltip-arrow-content {
  background-color: transparent;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.75);
  transform: unset;
  box-shadow: unset;
  top: 13px;
}
.modalDelete___3dv5Y .modalTitle___cCPMz {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;
  text-align: center;
}
.modalDelete___3dv5Y .modalDescription_____twg {
  text-align: center;
  font-size: 17px;
  margin-bottom: 50px;
}
.modalDelete___3dv5Y .btn.btnWhite:hover {
  border: 2px solid #909090;
}
@media screen and (max-width: 768px) {
  .padding___3cLAe {
    padding: 0 15px;
  }
}
.basicSettingModal___1b-3i .ant-modal-body {
  padding: 0;
}
