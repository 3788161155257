@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2yJNR {
  display: flex;
  justify-content: center;
}
button.btn____ltSW {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn____ltSW {
    padding: 10px;
  }
}
button.btn____ltSW:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn____ltSW:focus {
  outline: none;
}
.btnWhite___3vOtr.btn____ltSW {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3vOtr.btn____ltSW:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2fBsq.btn____ltSW,
.btnGreen___2fBsq.btn____ltSW:hover,
.btnGreen___2fBsq.btn____ltSW:active,
.btnGreen___2fBsq.btn____ltSW:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3jbB7 {
  color: #fe0000;
}
.errorMessage___2MHcJ {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2TNRE {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3KD1- {
  display: none !important;
}
.m-auto___2NUZU {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3HG9y {
  max-width: 120px !important;
}
.fc___2MaC5 .fc-timegrid-col___1iC-B.fc-day-today___18UnI {
  background-color: #ffffff;
}
.fc___2MaC5 .fc-timegrid-slot___38iBG {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2MaC5 .fc-timegrid-slot___38iBG {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3j2cf .fc-direction-ltr___2YzI9 .fc-timegrid-slot-label-frame___3QZkL {
    font-size: 10px;
  }
  .bookingCalendar___3j2cf .fc___2MaC5 .fc-timegrid-slot-label-cushion___1HfgY {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3j2cf .fc___2MaC5 .fc-scrollgrid-section-header___33mVf .fc-scroller___1ms3k {
    overflow: unset !important;
  }
}
.ant-message-notice___3k_FD {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___lHK2f {
  width: 20px;
  height: 20px;
}
.bgTransparent___Aa6W0 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___2RrqB {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3FT0r {
  position: relative;
}
.inputCustomValue___3FT0r .ant-input___3zgen {
  padding-right: 50px;
}
.inputCustomValueText___PDA8w {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2bzN_ .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2bzN_ .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___D2Btr .ant-radio-inner___cjsta {
  border-color: black !important ;
}
.ant-radio-checked___D2Btr .ant-radio-inner___cjsta:after {
  background-color: black;
}
.ant-radio___2lODb:hover .ant-radio-inner___cjsta {
  border-color: black;
}
.ant-radio-checked___D2Btr .ant-radio-inner___cjsta:focus {
  border-color: black;
}
.modal___3ZWf4 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3ZWf4 .close___Pfsy8 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3ZWf4 .header___22Ic3 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3ZWf4 .header___22Ic3 p {
  margin: 0;
}
.modal___3ZWf4 .content___1Rsyi {
  text-align: center;
  font-size: 14px;
}
.modal___3ZWf4 .content___1Rsyi p {
  margin: 0;
}
.modal___3ZWf4 .actions___1chhV {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3ZWf4 .actions___1chhV .button___28Po2 {
  margin-top: 10px;
}
.modal___3ZWf4 .actions___1chhV:hover {
  color: #33c3c7;
}
.fc___2MaC5 .fc-timegrid-slot-minor___RC8eH {
  border-top-style: none;
}
.fc___2MaC5 .fc-timegrid-slot___38iBG {
  height: 1.5em !important;
}
.messageError___3LPto .ant-message-custom-content___3Lix0 {
  display: flex;
  text-align: start;
}
.messageError___3LPto .ant-message-custom-content___3Lix0 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2eGL1 {
  background-color: #e6e4e4;
}
.notifyMessage___1KlNr .ant-message-notice-content___1FUZI {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1KlNr .ant-message-notice-content___1FUZI .anticon___LpwJ9 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1KlNr .ant-message-notice-content___1FUZI span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1_wIi {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1_wIi .ant-notification-notice-message___1p_Ub,
.notifyCustomize___1_wIi .ant-notification-notice-close___3OaD8,
.notifyCustomize___1_wIi .ant-notification-notice-icon___3t3aJ {
  color: white;
}
.notifyCustomize___1_wIi .ant-notification-notice-message___1p_Ub {
  margin-left: 35px;
}
.notifyCustomize___1_wIi p {
  margin-bottom: 0;
}
.notifyCustomize___1_wIi p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___fsOpe {
  padding: 16px 14px;
}
.notifyAlertDuplicate___fsOpe .ant-notification-notice-message___1p_Ub {
  margin-left: 35px;
}
.notifyAlertDuplicate___fsOpe p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___fsOpe p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1IPID {
  width: 434px !important;
}
.handleSubmitVote___1IPID .ant-btn-primary___X6Me8 {
  background: #59c3c7;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g .ant-modal-confirm-body___2Vrws {
  display: flex !important;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g .ant-modal-confirm-body___2Vrws .anticon___LpwJ9 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g .ant-modal-confirm-btns___2Fzvj button {
  font-weight: bold;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g .ant-modal-confirm-btns___2Fzvj button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g .ant-modal-confirm-btns___2Fzvj button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1IPID .ant-modal-body___Zxi6g .ant-modal-confirm-btns___2Fzvj .ant-btn-primary___X6Me8 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2DscW {
  margin-bottom: 0;
}
.mt-0___1bY-E {
  margin-top: 0;
}
.tooltipFormat___2bMzJ p {
  margin-bottom: 0;
}
.bgWhite___3w8Uq {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___omdDZ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___omdDZ:hover,
.bgPrimaryBlue___omdDZ:focus,
.bgPrimaryBlue___omdDZ:active {
  background: #4f7ac7;
}
.bgDarkBlue___34ns1 {
  background-color: #023768 !important;
}
.bgDarkBlue___34ns1:hover,
.bgDarkBlue___34ns1:focus,
.bgDarkBlue___34ns1:active {
  background: #194d7d;
}
.bgGreyBlue___2ldRG {
  background-color: #ebf0f9;
}
.bgLightBlue___2nxzP {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1R4GC {
  background-color: #e4453a;
}
.bgLightRed___1R4GC:hover,
.bgLightRed___1R4GC:focus,
.bgLightRed___1R4GC:active {
  background: #e17871;
}
.bgLightYellow___1yq_J {
  background-color: #ffd603;
}
.bgDarkGray___2DTb5 {
  background-color: #333333;
}
.bgMediumGray___3PFae {
  background-color: #a2a2a2;
}
.bgLightGray___2aQ2_ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2xtx5 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2PVBt {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___mjB-5 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3lXt1 {
  color: #3368c7 !important;
}
.textDarkBlue___3BP3D {
  color: #023768 !important;
}
.textLightBlue___2pz1r {
  color: #b2cbf7;
}
.textDarkGray___2alBE {
  color: #333333 !important;
}
.textMediumGray___35Lpo {
  color: #a2a2a2 !important;
}
.textLightGray___172e5 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___127Ex {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3FODz {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___26Rh1 {
  border-radius: 5px !important;
}
.rounded-xs___3Rt6I {
  border-radius: 10px !important;
}
.rounded-sm___3a2r8 {
  border-radius: 15px !important;
}
.rounded-md___2zn7E {
  border-radius: 20px !important;
}
.rounded-lg___nZ8RS {
  border-radius: 25px !important;
}
.rounded-full___v-YQs {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___29EDt {
  margin: 0;
}
.mt-2___2IdZT {
  margin-top: 0.5rem;
}
.my-2___14-LZ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___CVjq8 {
  padding: 0;
}
.px-1___17asa {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2zjKp {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3oqQG {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2wijD {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___38Nrf {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___Hqd8k {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3mG3i {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1wVGB {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___18EHk {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2DhMX {
  padding-bottom: 20px;
}
.h-full___3qunU {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3w08s {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2BuCl {
  font-weight: bold !important;
}
.fontWeight400___q9vbb {
  font-weight: 400 !important;
}
.fontWeight500___3XTjW {
  font-weight: 500 !important;
}
.fontWeight600___8OAX8 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1S8zT {
  font-size: 12px !important;
}
.fontSize14___Kr-h7 {
  font-size: 14px !important;
}
.fontSize16___28X6p {
  font-size: 16px !important;
}
.fontSize18___43f8Y {
  font-size: 18px !important;
}
.fontSize20___1zofn {
  font-size: 20px !important;
}
.fontSize24___3kole {
  font-size: 24px !important;
}
.eventName___2qCuW {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3dtzK {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1Yh-m > tr > th,
.ant-table-tbody___eEion > tr.ant-table-row___37AB9 > td,
.ant-table-summary___1dH7S tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1Yh-m > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2_4s2 .ant-table-thead___1Yh-m > tr > th,
.table-portrait___2_4s2 .ant-table-tbody___eEion > tr.ant-table-row___37AB9 > td,
.table-portrait___2_4s2 .ant-table-summary___1dH7S tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2_4s2 .bgLightGray___2aQ2_ td {
  background: #ebebeb;
}
.table-portrait___2_4s2 .bgLightBlue___2nxzP td {
  background: #b2cbf7;
}
.eventContent___2_5d6 .timeText___YThDx {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___eEion > tr.bgLightGray___2aQ2_:hover > td,
.ant-table-tbody___eEion > tr > td.ant-table-cell-row-hover___1hwsh {
  background: #ebebeb;
}
.ant-table-tbody___eEion > tr.bgLightBlue___2nxzP:hover > td,
.ant-table-tbody___eEion > tr > td.ant-table-cell-row-hover___1hwsh {
  background: #b2cbf7;
}
.ant-table-tbody___eEion > tr.bgWhite___3w8Uq:hover > td,
.ant-table-tbody___eEion > tr > td.ant-table-cell-row-hover___1hwsh {
  background: #ffffff;
}
.CalendarContainer___2eqJ7 {
  max-width: 554px;
  width: 100%;
  padding: 30px 54px 54px;
  box-shadow: 1px 1px 10px #b3bcbc60;
  border-radius: 15px;
  margin: 60px auto 40px;
}
@media screen and (max-width: 767px) {
  .CalendarContainer___2eqJ7 {
    padding: 30px 15px;
    margin: 30px auto;
  }
}
.CalendarContainer___2eqJ7 .header___22Ic3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CalendarContainer___2eqJ7 .header___22Ic3 div {
  margin-right: 13px;
  margin-left: 13px;
}
.CalendarContainer___2eqJ7 .calendarLabel___2kiTQ {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.CalendarContainer___2eqJ7 .lineCalendar___10X2I {
  border-bottom: 1px solid #a1a0a0;
  margin: 0 12px 15px;
}
.CalendarContainer___2eqJ7 .week-cell {
  text-align: center;
  color: #707070;
  font-size: 20px;
}
.CalendarContainer___2eqJ7 .anticon {
  font-size: 20px;
}
.CalendarContainer___2eqJ7 .datetime {
  font-size: 20px;
  font-weight: 600;
}
.CalendarContainer___2eqJ7 .errorMessage___2MHcJ {
  color: red;
  text-align: center;
  margin-top: 20px;
}
.CalendarContainer___2eqJ7 .dayContainer___3326T {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-gap: 15px;
}
@media screen and (max-width: 767px) {
  .CalendarContainer___2eqJ7 .dayContainer___3326T {
    grid-gap: 3px;
  }
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell {
  text-align: center;
  font-size: 20px;
  border-radius: 5px;
  padding-top: 13px 15px;
  border: 3px solid transparent;
  width: 50px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell {
    margin: auto;
    font-size: 13px;
    width: 100%;
  }
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell:hover {
  background: #ffffff;
  border: 3px solid #33c3c7;
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell-active {
  background-color: #33c3c7 !important;
  color: #ffffff;
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell-available {
  background-color: #ddf6f5;
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell-disabled {
  cursor: default;
  pointer-events: none;
  background: #ffffff;
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell-current {
  position: relative;
}
.CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell-current::after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: grey;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  left: 44%;
}
@media screen and (max-width: 767px) {
  .CalendarContainer___2eqJ7 .dayContainer___3326T .day-cell-current::after {
    bottom: -2px;
  }
}
