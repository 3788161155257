@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3v-NP {
  display: flex;
  justify-content: center;
}
button.btn___plegy {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___plegy {
    padding: 10px;
  }
}
button.btn___plegy:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___plegy:focus {
  outline: none;
}
.btnWhite___3qtC3.btn___plegy {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3qtC3.btn___plegy:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3Xd5c.btn___plegy,
.btnGreen___3Xd5c.btn___plegy:hover,
.btnGreen___3Xd5c.btn___plegy:active,
.btnGreen___3Xd5c.btn___plegy:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3E_rE {
  color: #fe0000;
}
.errorMessage___24Tky {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___21AJP {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1h4y1 {
  display: none !important;
}
.m-auto___1vrC1 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1yKM7 {
  max-width: 120px !important;
}
.fc___IoPOM .fc-timegrid-col___g59ru.fc-day-today___z2WMm {
  background-color: #ffffff;
}
.fc___IoPOM .fc-timegrid-slot___1oJDB {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___IoPOM .fc-timegrid-slot___1oJDB {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___qLmiG .fc-direction-ltr___39Asz .fc-timegrid-slot-label-frame___ZzgMt {
    font-size: 10px;
  }
  .bookingCalendar___qLmiG .fc___IoPOM .fc-timegrid-slot-label-cushion___3tP2p {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___qLmiG .fc___IoPOM .fc-scrollgrid-section-header___3a-vV .fc-scroller___3ay6L {
    overflow: unset !important;
  }
}
.ant-message-notice___1-17k {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3kBft {
  width: 20px;
  height: 20px;
}
.bgTransparent___uEocJ {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___erdNs {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___wcbXQ {
  position: relative;
}
.inputCustomValue___wcbXQ .ant-input___2Hf8D {
  padding-right: 50px;
}
.inputCustomValueText___IQ95B {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2wvpo .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2wvpo .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1DN1Z .ant-radio-inner___3b-7R {
  border-color: black !important ;
}
.ant-radio-checked___1DN1Z .ant-radio-inner___3b-7R:after {
  background-color: black;
}
.ant-radio___lQ0X1:hover .ant-radio-inner___3b-7R {
  border-color: black;
}
.ant-radio-checked___1DN1Z .ant-radio-inner___3b-7R:focus {
  border-color: black;
}
.modal___1MMxO {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1MMxO .close___ElrFl {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1MMxO .header___1MLCp {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1MMxO .header___1MLCp p {
  margin: 0;
}
.modal___1MMxO .content___3woVM {
  text-align: center;
  font-size: 14px;
}
.modal___1MMxO .content___3woVM p {
  margin: 0;
}
.modal___1MMxO .actions___1RTBy {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1MMxO .actions___1RTBy .button___krDAS {
  margin-top: 10px;
}
.modal___1MMxO .actions___1RTBy:hover {
  color: #33c3c7;
}
.fc___IoPOM .fc-timegrid-slot-minor___1TmG4 {
  border-top-style: none;
}
.fc___IoPOM .fc-timegrid-slot___1oJDB {
  height: 1.5em !important;
}
.messageError___ojDhF .ant-message-custom-content___3I8-u {
  display: flex;
  text-align: start;
}
.messageError___ojDhF .ant-message-custom-content___3I8-u span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1golq {
  background-color: #e6e4e4;
}
.notifyMessage___3Rdgw .ant-message-notice-content___nkmUs {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3Rdgw .ant-message-notice-content___nkmUs .anticon___23fXv {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3Rdgw .ant-message-notice-content___nkmUs span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___xsYXM {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___xsYXM .ant-notification-notice-message___aWPhH,
.notifyCustomize___xsYXM .ant-notification-notice-close___SEGEo,
.notifyCustomize___xsYXM .ant-notification-notice-icon___2bl9I {
  color: white;
}
.notifyCustomize___xsYXM .ant-notification-notice-message___aWPhH {
  margin-left: 35px;
}
.notifyCustomize___xsYXM p {
  margin-bottom: 0;
}
.notifyCustomize___xsYXM p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3aaoa {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3aaoa .ant-notification-notice-message___aWPhH {
  margin-left: 35px;
}
.notifyAlertDuplicate___3aaoa p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3aaoa p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3UEVQ {
  width: 434px !important;
}
.handleSubmitVote___3UEVQ .ant-btn-primary___3IZkk {
  background: #59c3c7;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf .ant-modal-confirm-body___3J6XP {
  display: flex !important;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf .ant-modal-confirm-body___3J6XP .anticon___23fXv {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf .ant-modal-confirm-btns___1haLd button {
  font-weight: bold;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf .ant-modal-confirm-btns___1haLd button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf .ant-modal-confirm-btns___1haLd button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3UEVQ .ant-modal-body____Rpnf .ant-modal-confirm-btns___1haLd .ant-btn-primary___3IZkk {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___RJocH {
  margin-bottom: 0;
}
.mt-0___229GP {
  margin-top: 0;
}
.tooltipFormat___1kgPy p {
  margin-bottom: 0;
}
.bgWhite___2Aebi {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___Fv1DW {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___Fv1DW:hover,
.bgPrimaryBlue___Fv1DW:focus,
.bgPrimaryBlue___Fv1DW:active {
  background: #4f7ac7;
}
.bgDarkBlue___-NfTg {
  background-color: #023768 !important;
}
.bgDarkBlue___-NfTg:hover,
.bgDarkBlue___-NfTg:focus,
.bgDarkBlue___-NfTg:active {
  background: #194d7d;
}
.bgGreyBlue___25A26 {
  background-color: #ebf0f9;
}
.bgLightBlue___32Fje {
  background-color: #b2cbf7 !important;
}
.bgLightRed___C30Jw {
  background-color: #e4453a;
}
.bgLightRed___C30Jw:hover,
.bgLightRed___C30Jw:focus,
.bgLightRed___C30Jw:active {
  background: #e17871;
}
.bgLightYellow___20P4e {
  background-color: #ffd603;
}
.bgDarkGray___2upIE {
  background-color: #333333;
}
.bgMediumGray___mmgSH {
  background-color: #a2a2a2;
}
.bgLightGray___1NhFQ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___SU8us {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___a0Jy2 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3D5aF {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3zn3m {
  color: #3368c7 !important;
}
.textDarkBlue___KENgB {
  color: #023768 !important;
}
.textLightBlue___3Ladr {
  color: #b2cbf7;
}
.textDarkGray___1-EdQ {
  color: #333333 !important;
}
.textMediumGray___1_a53 {
  color: #a2a2a2 !important;
}
.textLightGray___24WMK {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2pLJs {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2-MSO {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2NulP {
  border-radius: 5px !important;
}
.rounded-xs___rdwBm {
  border-radius: 10px !important;
}
.rounded-sm___17hxX {
  border-radius: 15px !important;
}
.rounded-md___3b0p0 {
  border-radius: 20px !important;
}
.rounded-lg___3NmQt {
  border-radius: 25px !important;
}
.rounded-full___3WPIT {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3U2wI {
  margin: 0;
}
.mt-2___3VPsC {
  margin-top: 0.5rem;
}
.my-2___KZ2A3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3xiKG {
  padding: 0;
}
.px-1___kCNg0 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3kaXa {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2bnkk {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1CQp0 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3WUQD {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2GHhX {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___nDfko {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1HTzN {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___aFHTQ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2XAtj {
  padding-bottom: 20px;
}
.h-full___38Yaz {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3HMas {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2O8T4 {
  font-weight: bold !important;
}
.fontWeight400___1-mZv {
  font-weight: 400 !important;
}
.fontWeight500___3zCiF {
  font-weight: 500 !important;
}
.fontWeight600___23RM2 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___23Jao {
  font-size: 12px !important;
}
.fontSize14___1YbtD {
  font-size: 14px !important;
}
.fontSize16___3ZaEO {
  font-size: 16px !important;
}
.fontSize18___2iIme {
  font-size: 18px !important;
}
.fontSize20___2jv2X {
  font-size: 20px !important;
}
.fontSize24___23vwi {
  font-size: 24px !important;
}
.eventName___i3UYy {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___33gdF {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___ESUF2 > tr > th,
.ant-table-tbody___1NKRP > tr.ant-table-row___lsNm- > td,
.ant-table-summary___-O76r tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___ESUF2 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___ySK3A .ant-table-thead___ESUF2 > tr > th,
.table-portrait___ySK3A .ant-table-tbody___1NKRP > tr.ant-table-row___lsNm- > td,
.table-portrait___ySK3A .ant-table-summary___-O76r tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___ySK3A .bgLightGray___1NhFQ td {
  background: #ebebeb;
}
.table-portrait___ySK3A .bgLightBlue___32Fje td {
  background: #b2cbf7;
}
.eventContent___2ikZG .timeText___2G5oJ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1NKRP > tr.bgLightGray___1NhFQ:hover > td,
.ant-table-tbody___1NKRP > tr > td.ant-table-cell-row-hover___1Z2Cw {
  background: #ebebeb;
}
.ant-table-tbody___1NKRP > tr.bgLightBlue___32Fje:hover > td,
.ant-table-tbody___1NKRP > tr > td.ant-table-cell-row-hover___1Z2Cw {
  background: #b2cbf7;
}
.ant-table-tbody___1NKRP > tr.bgWhite___2Aebi:hover > td,
.ant-table-tbody___1NKRP > tr > td.ant-table-cell-row-hover___1Z2Cw {
  background: #ffffff;
}
.calendarCreation___2ii71 {
  max-width: 1080px;
  margin: auto;
  padding: 25px 15px 0;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt {
  display: flex;
  align-items: center;
  padding-bottom: 1.3rem;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt .titleIcon___BBcL5 {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt .titleIcon___BBcL5 .bolderColIcon___2e6v_ {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 8px;
  margin-right: 5px;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt .titleIcon___BBcL5 .normalColIcon___2VRUE {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 3px;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt h2 {
  color: #000000;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt h2 span {
  font-weight: 600;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt h2 br {
  display: none;
}
.calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt p {
  font-size: 16px;
  width: 100%;
  padding-left: 58px;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 {
  margin-bottom: 3rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9.mb0___3ucod {
  margin-bottom: 0;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 {
  display: flex;
  font-size: 16px;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 p {
  color: #000000;
  margin-bottom: 0;
  padding-right: 0.5rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 span {
  color: #a3a3a3;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 div {
  background: #969696;
  color: white;
  padding: 0.25rem 0.2rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 div span {
  color: white;
  font-weight: 600;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 {
  width: 70%;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 p {
  color: #000000;
  font-size: 16px;
  padding-left: 1rem;
  margin-bottom: 0;
  font-weight: 500;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 p br {
  display: none;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 input {
  width: 100%;
  border: 1px solid #707070;
  padding: 0.5rem 0.7rem;
  border-radius: 7px;
  outline: none;
  margin-top: 0.5rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .groupCheckbox___2vEys {
  padding-left: 1rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .groupCheckbox___2vEys .textCheckbox___1YIhr p {
  padding-left: 0;
  margin-bottom: 0;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .formAskCalendar___3X1PT {
  display: block;
  margin-top: 1rem;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .formAskCalendar___3X1PT textarea {
  color: #000000;
  margin-bottom: 0.5rem;
  border: 1px solid #3c3c3c !important;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .formAskCalendar___3X1PT textarea::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .formAskCalendar___3X1PT textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr {
  width: 20%;
  margin: auto 0;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr button {
  width: 100%;
  border: 3px solid #33c3c7;
  border-radius: 7px;
  background: transparent;
  font-weight: 500;
  padding: 0.5rem 0;
  cursor: pointer;
  color: #000000;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr button span {
  font-weight: 600;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr button p {
  color: #000000;
  padding-left: 0;
  font-weight: 500;
  margin-bottom: 0;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr.mt5___1sr9g {
  margin-top: 5%;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM.borderBottom___1lJx9 {
  border-bottom: 2px solid #b5b5b5;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9 .formAskCalendar___3X1PT {
  display: none;
}
.calendarCreation___2ii71 .optionCalendar___1UlN9:nth-child(4) {
  padding-top: 1.2rem;
}
.calendarCreation___2ii71 .checkboxAskCalendar___dKa-G {
  display: flex;
  padding-left: 1rem;
}
.calendarCreation___2ii71 .checkboxAskCalendar___dKa-G input {
  margin-top: 5px;
  height: 17px !important;
  width: 20px !important;
  cursor: pointer;
}
.calendarCreation___2ii71 .checkboxAskCalendar___dKa-G label {
  padding-left: 7px;
}
.calendarCreation___2ii71 .checkboxAskCalendar___dKa-G label p {
  font-size: 14px !important;
  cursor: pointer;
  padding-left: 0 !important;
  margin-bottom: 0;
}
.calendarCreation___2ii71 .checkboxAskCalendar___dKa-G label p span {
  color: #1253e6;
}
@media screen and (max-width: 425px) {
  .calendarCreation___2ii71 {
    padding: 11px 9px;
  }
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt {
    padding-bottom: 0.7rem;
  }
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt .titleIcon___BBcL5 {
    margin-right: 7px;
  }
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt h2 {
    font-size: 18px;
  }
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt h2 br {
    display: unset;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 {
    margin-bottom: 2rem;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 p {
    font-weight: 400;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM.pd1___1ynMt {
    padding-bottom: 1rem;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 {
    width: 70%;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 p {
    padding-left: 0;
    font-weight: 400;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 p br {
    display: unset;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 input {
    width: 90%;
    border: 2px solid #3c3c3c;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .groupCheckbox___2vEys {
    padding-left: 1rem;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .groupCheckbox___2vEys .textCheckbox___1YIhr p {
    padding-left: 0;
    margin-bottom: 0;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .groupLeft___2FA15 .formAskCalendar___3X1PT {
    display: none;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr {
    width: 30%;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr button {
    font-weight: 500;
    padding: 0.5rem 0;
    border-radius: 5px;
    border: 2px solid #33c3c7;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr button p {
    padding-left: 0;
    margin-bottom: 0;
    font-weight: 500;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr.mt5___1sr9g {
    margin-top: unset;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM.borderBottom___1lJx9 {
    border-bottom: 2px solid #b5b5b5;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .formAskCalendar___3X1PT {
    display: unset;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .formAskCalendar___3X1PT textarea {
    border: 2px solid #3c3c3c !important;
  }
  .calendarCreation___2ii71 .checkboxAskCalendar___dKa-G label p {
    font-size: 12px;
  }
}
@media screen and (max-width: 395px) {
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt {
    padding-bottom: 0.7rem;
  }
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt .titleIcon___BBcL5 {
    margin-right: 7px;
  }
  .calendarCreation___2ii71 .calendarInfoContent___2m_2C .stepCalendarTitle___3WqTt h2 {
    font-size: 16px;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 {
    margin-bottom: 2rem;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .title___11XB2 {
    font-size: 14px;
  }
  .calendarCreation___2ii71 .optionCalendar___1UlN9 .content___3woVM .btnRight___2qQpr button {
    font-size: 13px;
  }
}
