.documentation___2_fZ0 {
  width: 100%;
  height: 100%;
  max-height: calc(100dvh - 50px);
  overflow-y: scroll;
  margin: auto;
}
.documentation___2_fZ0 section {
  padding: 10px;
}
.documentation___2_fZ0 section .content___cbI9f {
  padding: 0 10px;
}
.documentation___2_fZ0 section .content___cbI9f .title___VLj6V {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.documentation___2_fZ0 section .content___cbI9f .title___VLj6V .titleText___1bN3g {
  margin-right: 10px;
}
.documentation___2_fZ0 section .image___2d34C {
  padding-top: 10px;
}
.documentation___2_fZ0 section .image___2d34C img {
  width: 100%;
}
.documentation___2_fZ0 section .description___2R6HY {
  padding-top: 10px;
}
.documentation___2_fZ0 section .description___2R6HY .descItem___1Xfdf {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: start;
}
.documentation___2_fZ0 section .description___2R6HY .descItem___1Xfdf .descItemIcon___2qAg2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}
.documentation___2_fZ0 section .description___2R6HY .descItemText___3Y6PI {
  width: 100%;
  padding-left: 5px;
}
