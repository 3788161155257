@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___Zo98e {
  display: flex;
  justify-content: center;
}
button.btn___3gr3O {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3gr3O {
    padding: 10px;
  }
}
button.btn___3gr3O:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3gr3O:focus {
  outline: none;
}
.btnWhite___2i4m9.btn___3gr3O {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2i4m9.btn___3gr3O:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1HOao.btn___3gr3O,
.btnGreen___1HOao.btn___3gr3O:hover,
.btnGreen___1HOao.btn___3gr3O:active,
.btnGreen___1HOao.btn___3gr3O:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2WgOb {
  color: #fe0000;
}
.errorMessage___2ErEB {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2DFij {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___NauUw {
  display: none !important;
}
.m-auto___3YmXg {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2FQIP {
  max-width: 120px !important;
}
.fc___2rYxO .fc-timegrid-col___1p2dq.fc-day-today___3CL9H {
  background-color: #ffffff;
}
.fc___2rYxO .fc-timegrid-slot___2ZeNR {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2rYxO .fc-timegrid-slot___2ZeNR {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3aRtq .fc-direction-ltr___1MtOr .fc-timegrid-slot-label-frame___2CNic {
    font-size: 10px;
  }
  .bookingCalendar___3aRtq .fc___2rYxO .fc-timegrid-slot-label-cushion___1CmCr {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3aRtq .fc___2rYxO .fc-scrollgrid-section-header___1kgoE .fc-scroller___2DB_M {
    overflow: unset !important;
  }
}
.ant-message-notice___3VNll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2Xodz {
  width: 20px;
  height: 20px;
}
.bgTransparent___2HF5O {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1ha6_ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2zpbe {
  position: relative;
}
.inputCustomValue___2zpbe .ant-input___153ZW {
  padding-right: 50px;
}
.inputCustomValueText___2wbqI {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1MxGV .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1MxGV .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3j6hI .ant-radio-inner___3nLLh {
  border-color: black !important ;
}
.ant-radio-checked___3j6hI .ant-radio-inner___3nLLh:after {
  background-color: black;
}
.ant-radio___2vrxe:hover .ant-radio-inner___3nLLh {
  border-color: black;
}
.ant-radio-checked___3j6hI .ant-radio-inner___3nLLh:focus {
  border-color: black;
}
.modal___1Irap {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1Irap .close___1Wqwm {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1Irap .header___1rW98 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1Irap .header___1rW98 p {
  margin: 0;
}
.modal___1Irap .content___9jh1W {
  text-align: center;
  font-size: 14px;
}
.modal___1Irap .content___9jh1W p {
  margin: 0;
}
.modal___1Irap .actions___2t0fq {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1Irap .actions___2t0fq .button___20E7z {
  margin-top: 10px;
}
.modal___1Irap .actions___2t0fq:hover {
  color: #33c3c7;
}
.fc___2rYxO .fc-timegrid-slot-minor___2-N8d {
  border-top-style: none;
}
.fc___2rYxO .fc-timegrid-slot___2ZeNR {
  height: 1.5em !important;
}
.messageError___3VWt9 .ant-message-custom-content___gM6Im {
  display: flex;
  text-align: start;
}
.messageError___3VWt9 .ant-message-custom-content___gM6Im span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3m9_X {
  background-color: #e6e4e4;
}
.notifyMessage___PD8Ek .ant-message-notice-content___2wg6a {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___PD8Ek .ant-message-notice-content___2wg6a .anticon___urJPU {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___PD8Ek .ant-message-notice-content___2wg6a span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1tkA3 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1tkA3 .ant-notification-notice-message___KuIf8,
.notifyCustomize___1tkA3 .ant-notification-notice-close___1GgOr,
.notifyCustomize___1tkA3 .ant-notification-notice-icon___DcI8R {
  color: white;
}
.notifyCustomize___1tkA3 .ant-notification-notice-message___KuIf8 {
  margin-left: 35px;
}
.notifyCustomize___1tkA3 p {
  margin-bottom: 0;
}
.notifyCustomize___1tkA3 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3PzRL {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3PzRL .ant-notification-notice-message___KuIf8 {
  margin-left: 35px;
}
.notifyAlertDuplicate___3PzRL p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3PzRL p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___gdieM {
  width: 434px !important;
}
.handleSubmitVote___gdieM .ant-btn-primary___2kEu_ {
  background: #59c3c7;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 .ant-modal-confirm-body___3ApU6 {
  display: flex !important;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 .ant-modal-confirm-body___3ApU6 .anticon___urJPU {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 .ant-modal-confirm-btns___3RYDn button {
  font-weight: bold;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 .ant-modal-confirm-btns___3RYDn button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 .ant-modal-confirm-btns___3RYDn button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___gdieM .ant-modal-body___kItf6 .ant-modal-confirm-btns___3RYDn .ant-btn-primary___2kEu_ {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2WwLj {
  margin-bottom: 0;
}
.mt-0___1J7ZJ {
  margin-top: 0;
}
.tooltipFormat___2ejcI p {
  margin-bottom: 0;
}
.bgWhite___1WXIV {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1Vdhj {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1Vdhj:hover,
.bgPrimaryBlue___1Vdhj:focus,
.bgPrimaryBlue___1Vdhj:active {
  background: #4f7ac7;
}
.bgDarkBlue___2MKz6 {
  background-color: #023768 !important;
}
.bgDarkBlue___2MKz6:hover,
.bgDarkBlue___2MKz6:focus,
.bgDarkBlue___2MKz6:active {
  background: #194d7d;
}
.bgGreyBlue___kH-5n {
  background-color: #ebf0f9;
}
.bgLightBlue___2fL__ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___22JeE {
  background-color: #e4453a;
}
.bgLightRed___22JeE:hover,
.bgLightRed___22JeE:focus,
.bgLightRed___22JeE:active {
  background: #e17871;
}
.bgLightYellow___3rguc {
  background-color: #ffd603;
}
.bgDarkGray___3TvhO {
  background-color: #333333;
}
.bgMediumGray___1K2He {
  background-color: #a2a2a2;
}
.bgLightGray___QQTWk {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3cjLM {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___27g2C {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___mOmX8 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2GlPo {
  color: #3368c7 !important;
}
.textDarkBlue___2JwaC {
  color: #023768 !important;
}
.textLightBlue___1-ip3 {
  color: #b2cbf7;
}
.textDarkGray___yeYhD {
  color: #333333 !important;
}
.textMediumGray___1JfVs {
  color: #a2a2a2 !important;
}
.textLightGray___3rFBO {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3wtsm {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3kYYa {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3OO9R {
  border-radius: 5px !important;
}
.rounded-xs___glKEE {
  border-radius: 10px !important;
}
.rounded-sm___1HIUY {
  border-radius: 15px !important;
}
.rounded-md___1AcL8 {
  border-radius: 20px !important;
}
.rounded-lg___2TJXt {
  border-radius: 25px !important;
}
.rounded-full___NMT0M {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3eGO2 {
  margin: 0;
}
.mt-2___1aO44 {
  margin-top: 0.5rem;
}
.my-2___1Du-e {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___27r5c {
  padding: 0;
}
.px-1___2eT90 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___nVFnc {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2fGYb {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___175OU {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1O_LI {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2QlZX {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3tuRD {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2HvEU {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___HKB0i {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1Oj7Y {
  padding-bottom: 20px;
}
.h-full___33RJ9 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2A39R {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1hVMD {
  font-weight: bold !important;
}
.fontWeight400___3mChl {
  font-weight: 400 !important;
}
.fontWeight500___3miuj {
  font-weight: 500 !important;
}
.fontWeight600___LUVSF {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1H7bY {
  font-size: 12px !important;
}
.fontSize14___7J68T {
  font-size: 14px !important;
}
.fontSize16___2fsaE {
  font-size: 16px !important;
}
.fontSize18___20irL {
  font-size: 18px !important;
}
.fontSize20___AOJUJ {
  font-size: 20px !important;
}
.fontSize24___2QkLr {
  font-size: 24px !important;
}
.eventName___5EZoQ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___35PHq {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2Vf5i > tr > th,
.ant-table-tbody___3Q2NL > tr.ant-table-row___2n6Wo > td,
.ant-table-summary___3gMIB tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2Vf5i > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1AxLa .ant-table-thead___2Vf5i > tr > th,
.table-portrait___1AxLa .ant-table-tbody___3Q2NL > tr.ant-table-row___2n6Wo > td,
.table-portrait___1AxLa .ant-table-summary___3gMIB tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1AxLa .bgLightGray___QQTWk td {
  background: #ebebeb;
}
.table-portrait___1AxLa .bgLightBlue___2fL__ td {
  background: #b2cbf7;
}
.eventContent___2UxqE .timeText___1UuSd {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3Q2NL > tr.bgLightGray___QQTWk:hover > td,
.ant-table-tbody___3Q2NL > tr > td.ant-table-cell-row-hover___2Vvx0 {
  background: #ebebeb;
}
.ant-table-tbody___3Q2NL > tr.bgLightBlue___2fL__:hover > td,
.ant-table-tbody___3Q2NL > tr > td.ant-table-cell-row-hover___2Vvx0 {
  background: #b2cbf7;
}
.ant-table-tbody___3Q2NL > tr.bgWhite___1WXIV:hover > td,
.ant-table-tbody___3Q2NL > tr > td.ant-table-cell-row-hover___2Vvx0 {
  background: #ffffff;
}
.container___HLXJN {
  padding-bottom: 100px;
}
.container___HLXJN .header___1rW98 {
  margin: 40px 0;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container___HLXJN .title___rVZ49 {
  font-size: 30px;
}
.container___HLXJN .ant-picker {
  width: 100%;
  border-radius: 8px;
  border: 2px solid #ececec;
}
.container___HLXJN .ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f !important;
}
.container___HLXJN .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px;
}
.container___HLXJN .csvButton {
  font-size: 16px;
  max-width: 150px;
}
.container___HLXJN .createBtn {
  font-size: 16px;
  max-width: 120px;
}
.selectDatePicker___2mghj .ant-picker-time-panel-column {
  overflow-y: auto !important;
}
.createUserModal .btn-upload-csv .ant-upload {
  width: 100% !important;
}
.createUserModal button {
  border-radius: 8px;
  border: 2px solid #ececec !important;
  height: 34px;
}
.createUserModal button:hover, .createUserModal button:focus {
  color: unset;
}
.createUserModal button.ant-btn-primary {
  border-color: transparent !important;
}
.createUserModal .ant-form-item-with-help.ant-form-item-has-error button {
  border-color: #ff4d4f !important;
}
.createUserModal .ant-modal-body button {
  width: 100% !important;
}
