@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___16mmo {
  display: flex;
  justify-content: center;
}
button.btn___3jhwm {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3jhwm {
    padding: 10px;
  }
}
button.btn___3jhwm:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3jhwm:focus {
  outline: none;
}
.btnWhite___1ziE8.btn___3jhwm {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1ziE8.btn___3jhwm:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___24tbi.btn___3jhwm,
.btnGreen___24tbi.btn___3jhwm:hover,
.btnGreen___24tbi.btn___3jhwm:active,
.btnGreen___24tbi.btn___3jhwm:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___10YQQ {
  color: #fe0000;
}
.errorMessage___hH-1R {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2Ms3d {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3LvcG {
  display: none !important;
}
.m-auto___2k39n {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___mjTe7 {
  max-width: 120px !important;
}
.fc___2pJfc .fc-timegrid-col___uFa6f.fc-day-today___100ew {
  background-color: #ffffff;
}
.fc___2pJfc .fc-timegrid-slot___2kbqX {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2pJfc .fc-timegrid-slot___2kbqX {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___KCTam .fc-direction-ltr___1BE6m .fc-timegrid-slot-label-frame___3Gdrv {
    font-size: 10px;
  }
  .bookingCalendar___KCTam .fc___2pJfc .fc-timegrid-slot-label-cushion___26Ynl {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___KCTam .fc___2pJfc .fc-scrollgrid-section-header___2ZA_y .fc-scroller___2qx_Z {
    overflow: unset !important;
  }
}
.ant-message-notice___2b4YT {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1jmWY {
  width: 20px;
  height: 20px;
}
.bgTransparent___OYrOt {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___rJkxT {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___yjKjW {
  position: relative;
}
.inputCustomValue___yjKjW .ant-input___3vHGy {
  padding-right: 50px;
}
.inputCustomValueText___qSExQ {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___E92La .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___E92La .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2EKjf .ant-radio-inner___rBpYa {
  border-color: black !important ;
}
.ant-radio-checked___2EKjf .ant-radio-inner___rBpYa:after {
  background-color: black;
}
.ant-radio___1JcG9:hover .ant-radio-inner___rBpYa {
  border-color: black;
}
.ant-radio-checked___2EKjf .ant-radio-inner___rBpYa:focus {
  border-color: black;
}
.modal___3H1X_ {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3H1X_ .close___2eiYR {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3H1X_ .header___263rU {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3H1X_ .header___263rU p {
  margin: 0;
}
.modal___3H1X_ .content___1J_GJ {
  text-align: center;
  font-size: 14px;
}
.modal___3H1X_ .content___1J_GJ p {
  margin: 0;
}
.modal___3H1X_ .actions___1qKJU {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3H1X_ .actions___1qKJU .button___1NHOt {
  margin-top: 10px;
}
.modal___3H1X_ .actions___1qKJU:hover {
  color: #33c3c7;
}
.fc___2pJfc .fc-timegrid-slot-minor___1_neD {
  border-top-style: none;
}
.fc___2pJfc .fc-timegrid-slot___2kbqX {
  height: 1.5em !important;
}
.messageError___3XAIm .ant-message-custom-content___2hepn {
  display: flex;
  text-align: start;
}
.messageError___3XAIm .ant-message-custom-content___2hepn span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___HXkKV {
  background-color: #e6e4e4;
}
.notifyMessage___1k7hW .ant-message-notice-content___26Fcz {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1k7hW .ant-message-notice-content___26Fcz .anticon___1Tbei {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1k7hW .ant-message-notice-content___26Fcz span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___205LX {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___205LX .ant-notification-notice-message___1BLJF,
.notifyCustomize___205LX .ant-notification-notice-close___1fUqI,
.notifyCustomize___205LX .ant-notification-notice-icon___2naP2 {
  color: white;
}
.notifyCustomize___205LX .ant-notification-notice-message___1BLJF {
  margin-left: 35px;
}
.notifyCustomize___205LX p {
  margin-bottom: 0;
}
.notifyCustomize___205LX p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2JnOv {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2JnOv .ant-notification-notice-message___1BLJF {
  margin-left: 35px;
}
.notifyAlertDuplicate___2JnOv p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2JnOv p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___fY-1A {
  width: 434px !important;
}
.handleSubmitVote___fY-1A .ant-btn-primary___3th-T {
  background: #59c3c7;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs {
  padding: 15px 30px 18px;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs .ant-modal-confirm-body___1hC7r {
  display: flex !important;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs .ant-modal-confirm-body___1hC7r .anticon___1Tbei {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs .ant-modal-confirm-btns___1E2uG button {
  font-weight: bold;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs .ant-modal-confirm-btns___1E2uG button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs .ant-modal-confirm-btns___1E2uG button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___fY-1A .ant-modal-body___2NtKs .ant-modal-confirm-btns___1E2uG .ant-btn-primary___3th-T {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___VQ7BL {
  margin-bottom: 0;
}
.mt-0___1aQ8V {
  margin-top: 0;
}
.tooltipFormat___dSRKJ p {
  margin-bottom: 0;
}
.bgWhite___bhQEJ {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1jamL {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1jamL:hover,
.bgPrimaryBlue___1jamL:focus,
.bgPrimaryBlue___1jamL:active {
  background: #4f7ac7;
}
.bgDarkBlue___1lHz6 {
  background-color: #023768 !important;
}
.bgDarkBlue___1lHz6:hover,
.bgDarkBlue___1lHz6:focus,
.bgDarkBlue___1lHz6:active {
  background: #194d7d;
}
.bgGreyBlue___1DNV5 {
  background-color: #ebf0f9;
}
.bgLightBlue___2a1Xn {
  background-color: #b2cbf7 !important;
}
.bgLightRed___7AJia {
  background-color: #e4453a;
}
.bgLightRed___7AJia:hover,
.bgLightRed___7AJia:focus,
.bgLightRed___7AJia:active {
  background: #e17871;
}
.bgLightYellow___3mQhk {
  background-color: #ffd603;
}
.bgDarkGray___3xzWE {
  background-color: #333333;
}
.bgMediumGray___2rbRx {
  background-color: #a2a2a2;
}
.bgLightGray___3gYJB {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___39jtY {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1L_9O {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2pNXr {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___zLRGm {
  color: #3368c7 !important;
}
.textDarkBlue___26pcy {
  color: #023768 !important;
}
.textLightBlue___PQ-aL {
  color: #b2cbf7;
}
.textDarkGray___14v_n {
  color: #333333 !important;
}
.textMediumGray___nhs5A {
  color: #a2a2a2 !important;
}
.textLightGray___2dCOG {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1euNV {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1GQ-V {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3C6KN {
  border-radius: 5px !important;
}
.rounded-xs___3jZnv {
  border-radius: 10px !important;
}
.rounded-sm___3Plo_ {
  border-radius: 15px !important;
}
.rounded-md___xyCTr {
  border-radius: 20px !important;
}
.rounded-lg___3zn5u {
  border-radius: 25px !important;
}
.rounded-full___1F34_ {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___loFmL {
  margin: 0;
}
.mt-2___jPqPr {
  margin-top: 0.5rem;
}
.my-2___hUd3D {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3BwUs {
  padding: 0;
}
.px-1___2-PGC {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___36A8s {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3mEVW {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___G9U37 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1IR1t {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2I00Q {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2081O {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3oqLv {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2Di9B {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___vL0nd {
  padding-bottom: 20px;
}
.h-full___3ZIvL {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___GHUIR {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___7hcAo {
  font-weight: bold !important;
}
.fontWeight400___17A8Z {
  font-weight: 400 !important;
}
.fontWeight500___1zNon {
  font-weight: 500 !important;
}
.fontWeight600___1ZEKq {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2_8Vl {
  font-size: 12px !important;
}
.fontSize14___XBFnZ {
  font-size: 14px !important;
}
.fontSize16___qFRqN {
  font-size: 16px !important;
}
.fontSize18___3xcTE {
  font-size: 18px !important;
}
.fontSize20___3aldK {
  font-size: 20px !important;
}
.fontSize24___jxcp9 {
  font-size: 24px !important;
}
.eventName___3fVhC {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___286EK {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1afMK > tr > th,
.ant-table-tbody___3VrSP > tr.ant-table-row___1SB53 > td,
.ant-table-summary___sgOky tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1afMK > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2K3_I .ant-table-thead___1afMK > tr > th,
.table-portrait___2K3_I .ant-table-tbody___3VrSP > tr.ant-table-row___1SB53 > td,
.table-portrait___2K3_I .ant-table-summary___sgOky tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2K3_I .bgLightGray___3gYJB td {
  background: #ebebeb;
}
.table-portrait___2K3_I .bgLightBlue___2a1Xn td {
  background: #b2cbf7;
}
.eventContent___3Dx1I .timeText___27JAC {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3VrSP > tr.bgLightGray___3gYJB:hover > td,
.ant-table-tbody___3VrSP > tr > td.ant-table-cell-row-hover___-2Al3 {
  background: #ebebeb;
}
.ant-table-tbody___3VrSP > tr.bgLightBlue___2a1Xn:hover > td,
.ant-table-tbody___3VrSP > tr > td.ant-table-cell-row-hover___-2Al3 {
  background: #b2cbf7;
}
.ant-table-tbody___3VrSP > tr.bgWhite___bhQEJ:hover > td,
.ant-table-tbody___3VrSP > tr > td.ant-table-cell-row-hover___-2Al3 {
  background: #ffffff;
}
.container___2YNMM .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '*';
}
.container___2YNMM .ant-input-password {
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid #ececec !important;
}
.container___2YNMM .ant-input-password:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.container___2YNMM .ant-input-password:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.container___2YNMM .ant-form-item-has-error .ant-input-password:focus {
  border-right-width: 2px !important;
}
.container___2YNMM .customButton___HCmCo {
  font-size: 15px;
  max-width: 100px;
}
