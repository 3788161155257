@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1z4xY {
  display: flex;
  justify-content: center;
}
button.btn___171PB {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___171PB {
    padding: 10px;
  }
}
button.btn___171PB:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___171PB:focus {
  outline: none;
}
.btnWhite___2Ju6r.btn___171PB {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2Ju6r.btn___171PB:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___xpNeY.btn___171PB,
.btnGreen___xpNeY.btn___171PB:hover,
.btnGreen___xpNeY.btn___171PB:active,
.btnGreen___xpNeY.btn___171PB:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___10vwb {
  color: #fe0000;
}
.errorMessage___1z970 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___vVxWD {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2BxLF {
  display: none !important;
}
.m-auto___2JEUX {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1UtoM {
  max-width: 120px !important;
}
.fc___Wq5AJ .fc-timegrid-col___2ekiB.fc-day-today___11utj {
  background-color: #ffffff;
}
.fc___Wq5AJ .fc-timegrid-slot___PlzhS {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___Wq5AJ .fc-timegrid-slot___PlzhS {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___Yd61b .fc-direction-ltr___3OAn- .fc-timegrid-slot-label-frame___3jxMs {
    font-size: 10px;
  }
  .bookingCalendar___Yd61b .fc___Wq5AJ .fc-timegrid-slot-label-cushion___ul6ka {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___Yd61b .fc___Wq5AJ .fc-scrollgrid-section-header___8QB5R .fc-scroller___g451x {
    overflow: unset !important;
  }
}
.ant-message-notice___bbht_ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___20ptr {
  width: 20px;
  height: 20px;
}
.bgTransparent___2U6O4 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3PTt8 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3FLe8 {
  position: relative;
}
.inputCustomValue___3FLe8 .ant-input___3A_Tl {
  padding-right: 50px;
}
.inputCustomValueText___2Szyc {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3UKZv .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3UKZv .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___nP9C5 .ant-radio-inner___3m9NI {
  border-color: black !important ;
}
.ant-radio-checked___nP9C5 .ant-radio-inner___3m9NI:after {
  background-color: black;
}
.ant-radio___3uvsH:hover .ant-radio-inner___3m9NI {
  border-color: black;
}
.ant-radio-checked___nP9C5 .ant-radio-inner___3m9NI:focus {
  border-color: black;
}
.modal___oRFxm {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___oRFxm .close___nlNA5 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___oRFxm .header___U2Nzx {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___oRFxm .header___U2Nzx p {
  margin: 0;
}
.modal___oRFxm .content___1uWgg {
  text-align: center;
  font-size: 14px;
}
.modal___oRFxm .content___1uWgg p {
  margin: 0;
}
.modal___oRFxm .actions___3cXrD {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___oRFxm .actions___3cXrD .button___By3py {
  margin-top: 10px;
}
.modal___oRFxm .actions___3cXrD:hover {
  color: #33c3c7;
}
.fc___Wq5AJ .fc-timegrid-slot-minor___gJzX8 {
  border-top-style: none;
}
.fc___Wq5AJ .fc-timegrid-slot___PlzhS {
  height: 1.5em !important;
}
.messageError____2IPh .ant-message-custom-content___24FBW {
  display: flex;
  text-align: start;
}
.messageError____2IPh .ant-message-custom-content___24FBW span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1WMxE {
  background-color: #e6e4e4;
}
.notifyMessage___8yX6V .ant-message-notice-content___WP7fz {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___8yX6V .ant-message-notice-content___WP7fz .anticon___2j64D {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___8yX6V .ant-message-notice-content___WP7fz span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3o6ic {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3o6ic .ant-notification-notice-message___CJXjQ,
.notifyCustomize___3o6ic .ant-notification-notice-close___2cZNz,
.notifyCustomize___3o6ic .ant-notification-notice-icon___3F365 {
  color: white;
}
.notifyCustomize___3o6ic .ant-notification-notice-message___CJXjQ {
  margin-left: 35px;
}
.notifyCustomize___3o6ic p {
  margin-bottom: 0;
}
.notifyCustomize___3o6ic p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1y8H6 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1y8H6 .ant-notification-notice-message___CJXjQ {
  margin-left: 35px;
}
.notifyAlertDuplicate___1y8H6 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1y8H6 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2f528 {
  width: 434px !important;
}
.handleSubmitVote___2f528 .ant-btn-primary___k6QAE {
  background: #59c3c7;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF .ant-modal-confirm-body___3GB-i {
  display: flex !important;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF .ant-modal-confirm-body___3GB-i .anticon___2j64D {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF .ant-modal-confirm-btns___1oCZw button {
  font-weight: bold;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF .ant-modal-confirm-btns___1oCZw button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF .ant-modal-confirm-btns___1oCZw button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2f528 .ant-modal-body___23vwF .ant-modal-confirm-btns___1oCZw .ant-btn-primary___k6QAE {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3IOD- {
  margin-bottom: 0;
}
.mt-0___2B2X6 {
  margin-top: 0;
}
.tooltipFormat___ayglN p {
  margin-bottom: 0;
}
.bgWhite___2X-Zt {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2LYCW {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2LYCW:hover,
.bgPrimaryBlue___2LYCW:focus,
.bgPrimaryBlue___2LYCW:active {
  background: #4f7ac7;
}
.bgDarkBlue___HwUkH {
  background-color: #023768 !important;
}
.bgDarkBlue___HwUkH:hover,
.bgDarkBlue___HwUkH:focus,
.bgDarkBlue___HwUkH:active {
  background: #194d7d;
}
.bgGreyBlue___Rg33V {
  background-color: #ebf0f9;
}
.bgLightBlue___2tV_3 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2alo3 {
  background-color: #e4453a;
}
.bgLightRed___2alo3:hover,
.bgLightRed___2alo3:focus,
.bgLightRed___2alo3:active {
  background: #e17871;
}
.bgLightYellow___1wpi6 {
  background-color: #ffd603;
}
.bgDarkGray___w1nzv {
  background-color: #333333;
}
.bgMediumGray___3Mx4q {
  background-color: #a2a2a2;
}
.bgLightGray___3FiWh {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1gp_E {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3WKvc {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___tWPwv {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___FzS0P {
  color: #3368c7 !important;
}
.textDarkBlue___3chgA {
  color: #023768 !important;
}
.textLightBlue___1zr5v {
  color: #b2cbf7;
}
.textDarkGray___2uICH {
  color: #333333 !important;
}
.textMediumGray___2Q_1V {
  color: #a2a2a2 !important;
}
.textLightGray___3wcDy {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___14-ZU {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___ry22c {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1lWj8 {
  border-radius: 5px !important;
}
.rounded-xs___1JiBx {
  border-radius: 10px !important;
}
.rounded-sm___2lHBq {
  border-radius: 15px !important;
}
.rounded-md___1oekl {
  border-radius: 20px !important;
}
.rounded-lg___1a7B3 {
  border-radius: 25px !important;
}
.rounded-full___3jTh8 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___39hKU {
  margin: 0;
}
.mt-2___-4LZb {
  margin-top: 0.5rem;
}
.my-2___1HEPm {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___25aUi {
  padding: 0;
}
.px-1___19p1K {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2GZKA {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1n9tD {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2NM0x {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1r8GO {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___xGr_A {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3cpEm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3EBus {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1ht4b {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3_rht {
  padding-bottom: 20px;
}
.h-full___3at4g {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1qwYc {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___pcXVf {
  font-weight: bold !important;
}
.fontWeight400___10T2W {
  font-weight: 400 !important;
}
.fontWeight500___O_t-q {
  font-weight: 500 !important;
}
.fontWeight600___2VpAv {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3aLIc {
  font-size: 12px !important;
}
.fontSize14___8vTF5 {
  font-size: 14px !important;
}
.fontSize16___1xJFT {
  font-size: 16px !important;
}
.fontSize18___20rKX {
  font-size: 18px !important;
}
.fontSize20___3aClz {
  font-size: 20px !important;
}
.fontSize24___2BHV5 {
  font-size: 24px !important;
}
.eventName___y4UzN {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2TgY9 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___v3hxl > tr > th,
.ant-table-tbody___1vq4- > tr.ant-table-row___39oDY > td,
.ant-table-summary___2bIj5 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___v3hxl > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___hbR29 .ant-table-thead___v3hxl > tr > th,
.table-portrait___hbR29 .ant-table-tbody___1vq4- > tr.ant-table-row___39oDY > td,
.table-portrait___hbR29 .ant-table-summary___2bIj5 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___hbR29 .bgLightGray___3FiWh td {
  background: #ebebeb;
}
.table-portrait___hbR29 .bgLightBlue___2tV_3 td {
  background: #b2cbf7;
}
.eventContent___1xOnw .timeText___3Qx7A {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1vq4- > tr.bgLightGray___3FiWh:hover > td,
.ant-table-tbody___1vq4- > tr > td.ant-table-cell-row-hover___1dNH- {
  background: #ebebeb;
}
.ant-table-tbody___1vq4- > tr.bgLightBlue___2tV_3:hover > td,
.ant-table-tbody___1vq4- > tr > td.ant-table-cell-row-hover___1dNH- {
  background: #b2cbf7;
}
.ant-table-tbody___1vq4- > tr.bgWhite___2X-Zt:hover > td,
.ant-table-tbody___1vq4- > tr > td.ant-table-cell-row-hover___1dNH- {
  background: #ffffff;
}
.footer___3Cj25 {
  margin: 50px 0;
  padding-bottom: 3rem;
}
.footer___3Cj25 .footerContent___3V75F {
  display: flex;
  color: #989898;
  font-size: 20px;
  justify-content: center;
  letter-spacing: 2px;
}
.footer___3Cj25 .footerContent___3V75F a {
  color: #989898;
}
.footer___3Cj25 .footerContent___3V75F li {
  margin-right: 55px;
}
.footer___3Cj25 .footerContent___3V75F li:last-child {
  margin-right: 0;
}
.footer___3Cj25 .footerContent___3V75F .imgLogo___3XBnN {
  width: 140px;
}
@media screen and (max-width: 425px) {
  .footer___3Cj25 .footerContent___3V75F {
    flex-flow: wrap;
    letter-spacing: unset;
    font-size: 14px;
  }
  .footer___3Cj25 .footerContent___3V75F li {
    margin-right: unset;
  }
  .footer___3Cj25 .footerContent___3V75F li:nth-child(1) {
    width: 37%;
  }
  .footer___3Cj25 .footerContent___3V75F li:nth-child(2) {
    padding-left: 1rem;
  }
  .footer___3Cj25 .footerContent___3V75F li:nth-child(3) {
    width: 50%;
    text-align: right;
    padding-right: 0.5rem;
  }
  .footer___3Cj25 .footerContent___3V75F li:nth-child(4) {
    width: 50%;
    padding-left: 1rem;
  }
}
