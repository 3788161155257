.swipeable-list-item {
  overflow: unset;
}
.swipeable-item {
  margin-bottom: 10px;
}
.swipeableList {
  background: #EBF0F9;
  padding: 10px;
  color: #333333;
}

.swipableItem {
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
  background: #fafafa;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
}

.swipableItemInner {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}

.swipableItemInnerDiv {
  width: 4px;
  background: #3368c7;
  height: 20px;
  margin-right: 5px;
}

.flexSpaceBetween {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex2 {
  flex: 2 1;
}

.flex1 {
  flex: 1 1;
  padding-left: 5px;
}
.flex-0-5 {
  flex-grow: 0.5;
  flex-shrink: 1;
  flex-basis: 0;
}

.swipeable-list-item__content--remove {
  transform: translateX(-600px) !important;
}
