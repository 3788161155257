@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___ESOa4 {
  display: flex;
  justify-content: center;
}
button.btn___3O0Ix {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3O0Ix {
    padding: 10px;
  }
}
button.btn___3O0Ix:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3O0Ix:focus {
  outline: none;
}
.btnWhite___Gj2mz.btn___3O0Ix {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___Gj2mz.btn___3O0Ix:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___16bf7.btn___3O0Ix,
.btnGreen___16bf7.btn___3O0Ix:hover,
.btnGreen___16bf7.btn___3O0Ix:active,
.btnGreen___16bf7.btn___3O0Ix:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3fQcu {
  color: #fe0000;
}
.errorMessage___MROjL {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2wceP {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3Dwt0 {
  display: none !important;
}
.m-auto___2hDU_ {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___w1YOL {
  max-width: 120px !important;
}
.fc___3346G .fc-timegrid-col___b3wHp.fc-day-today___3AP1M {
  background-color: #ffffff;
}
.fc___3346G .fc-timegrid-slot___3DLCj {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3346G .fc-timegrid-slot___3DLCj {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1Mr8k .fc-direction-ltr___3jYXM .fc-timegrid-slot-label-frame___JEr9T {
    font-size: 10px;
  }
  .bookingCalendar___1Mr8k .fc___3346G .fc-timegrid-slot-label-cushion___D-0Q0 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1Mr8k .fc___3346G .fc-scrollgrid-section-header___2m63N .fc-scroller___2-rGR {
    overflow: unset !important;
  }
}
.ant-message-notice___3GnPp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___UqDcU {
  width: 20px;
  height: 20px;
}
.bgTransparent___3caRk {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___10Pjs {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2uVb- {
  position: relative;
}
.inputCustomValue___2uVb- .ant-input___2Xko4 {
  padding-right: 50px;
}
.inputCustomValueText___1BjcE {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2lFff .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2lFff .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3mAU3 .ant-radio-inner___3z2ZS {
  border-color: black !important ;
}
.ant-radio-checked___3mAU3 .ant-radio-inner___3z2ZS:after {
  background-color: black;
}
.ant-radio___2kgvq:hover .ant-radio-inner___3z2ZS {
  border-color: black;
}
.ant-radio-checked___3mAU3 .ant-radio-inner___3z2ZS:focus {
  border-color: black;
}
.modal___wCZfp {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___wCZfp .close___2A_y1 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___wCZfp .header___3ZA9_ {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___wCZfp .header___3ZA9_ p {
  margin: 0;
}
.modal___wCZfp .content___IotlT {
  text-align: center;
  font-size: 14px;
}
.modal___wCZfp .content___IotlT p {
  margin: 0;
}
.modal___wCZfp .actions___DhISQ {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___wCZfp .actions___DhISQ .button___3ksty {
  margin-top: 10px;
}
.modal___wCZfp .actions___DhISQ:hover {
  color: #33c3c7;
}
.fc___3346G .fc-timegrid-slot-minor___2TQzU {
  border-top-style: none;
}
.fc___3346G .fc-timegrid-slot___3DLCj {
  height: 1.5em !important;
}
.messageError___34-AA .ant-message-custom-content___2NviN {
  display: flex;
  text-align: start;
}
.messageError___34-AA .ant-message-custom-content___2NviN span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3IypX {
  background-color: #e6e4e4;
}
.notifyMessage___17MtA .ant-message-notice-content___3KByk {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___17MtA .ant-message-notice-content___3KByk .anticon___AD3xf {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___17MtA .ant-message-notice-content___3KByk span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2pIFt {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2pIFt .ant-notification-notice-message___3WlIj,
.notifyCustomize___2pIFt .ant-notification-notice-close___2UsXZ,
.notifyCustomize___2pIFt .ant-notification-notice-icon___cR5u- {
  color: white;
}
.notifyCustomize___2pIFt .ant-notification-notice-message___3WlIj {
  margin-left: 35px;
}
.notifyCustomize___2pIFt p {
  margin-bottom: 0;
}
.notifyCustomize___2pIFt p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3J-u8 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3J-u8 .ant-notification-notice-message___3WlIj {
  margin-left: 35px;
}
.notifyAlertDuplicate___3J-u8 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3J-u8 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2z0pu {
  width: 434px !important;
}
.handleSubmitVote___2z0pu .ant-btn-primary___Lng2X {
  background: #59c3c7;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy .ant-modal-confirm-body___OMq5B {
  display: flex !important;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy .ant-modal-confirm-body___OMq5B .anticon___AD3xf {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy .ant-modal-confirm-btns___31rRA button {
  font-weight: bold;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy .ant-modal-confirm-btns___31rRA button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy .ant-modal-confirm-btns___31rRA button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2z0pu .ant-modal-body___34QZy .ant-modal-confirm-btns___31rRA .ant-btn-primary___Lng2X {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3c1uu {
  margin-bottom: 0;
}
.mt-0___3u9Dd {
  margin-top: 0;
}
.tooltipFormat___1uEQG p {
  margin-bottom: 0;
}
.bgWhite___1i61o {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___pp0j- {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___pp0j-:hover,
.bgPrimaryBlue___pp0j-:focus,
.bgPrimaryBlue___pp0j-:active {
  background: #4f7ac7;
}
.bgDarkBlue___1Qjoa {
  background-color: #023768 !important;
}
.bgDarkBlue___1Qjoa:hover,
.bgDarkBlue___1Qjoa:focus,
.bgDarkBlue___1Qjoa:active {
  background: #194d7d;
}
.bgGreyBlue___2DUS9 {
  background-color: #ebf0f9;
}
.bgLightBlue___12cPB {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3UKXZ {
  background-color: #e4453a;
}
.bgLightRed___3UKXZ:hover,
.bgLightRed___3UKXZ:focus,
.bgLightRed___3UKXZ:active {
  background: #e17871;
}
.bgLightYellow___2Jjz6 {
  background-color: #ffd603;
}
.bgDarkGray___2IEm1 {
  background-color: #333333;
}
.bgMediumGray___2-5by {
  background-color: #a2a2a2;
}
.bgLightGray___23Irh {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___Ff1rG {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___g7S3X {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3mVLa {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3Tgmz {
  color: #3368c7 !important;
}
.textDarkBlue___2VW9A {
  color: #023768 !important;
}
.textLightBlue___coC4O {
  color: #b2cbf7;
}
.textDarkGray___1cNei {
  color: #333333 !important;
}
.textMediumGray___27Xv_ {
  color: #a2a2a2 !important;
}
.textLightGray___3u0Mb {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1_HZL {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2lCJQ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2N6vy {
  border-radius: 5px !important;
}
.rounded-xs___3QNcM {
  border-radius: 10px !important;
}
.rounded-sm___tCZbg {
  border-radius: 15px !important;
}
.rounded-md___3TygK {
  border-radius: 20px !important;
}
.rounded-lg___39xF7 {
  border-radius: 25px !important;
}
.rounded-full___TsqOl {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3G6Tq {
  margin: 0;
}
.mt-2___3851f {
  margin-top: 0.5rem;
}
.my-2___3fmEM {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3k1_8 {
  padding: 0;
}
.px-1___3xw9P {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___115fW {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3IrLc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2oQGi {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3eBOx {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1____MpLM {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2x5-I {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2kn_A {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3sV5G {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2VToe {
  padding-bottom: 20px;
}
.h-full___2QHp2 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1Zix3 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2fs0s {
  font-weight: bold !important;
}
.fontWeight400___14FjK {
  font-weight: 400 !important;
}
.fontWeight500___3K7be {
  font-weight: 500 !important;
}
.fontWeight600___d9GA8 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3jb0V {
  font-size: 12px !important;
}
.fontSize14___2OAwf {
  font-size: 14px !important;
}
.fontSize16___2itzE {
  font-size: 16px !important;
}
.fontSize18___25n5d {
  font-size: 18px !important;
}
.fontSize20___27gFY {
  font-size: 20px !important;
}
.fontSize24___16Kvx {
  font-size: 24px !important;
}
.eventName___3XjGC {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___d03hP {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___26QlN > tr > th,
.ant-table-tbody___2XZTz > tr.ant-table-row___2IZwe > td,
.ant-table-summary___2E7rZ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___26QlN > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___12o2B .ant-table-thead___26QlN > tr > th,
.table-portrait___12o2B .ant-table-tbody___2XZTz > tr.ant-table-row___2IZwe > td,
.table-portrait___12o2B .ant-table-summary___2E7rZ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___12o2B .bgLightGray___23Irh td {
  background: #ebebeb;
}
.table-portrait___12o2B .bgLightBlue___12cPB td {
  background: #b2cbf7;
}
.eventContent___299jp .timeText___1AJXG {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2XZTz > tr.bgLightGray___23Irh:hover > td,
.ant-table-tbody___2XZTz > tr > td.ant-table-cell-row-hover___2RlwV {
  background: #ebebeb;
}
.ant-table-tbody___2XZTz > tr.bgLightBlue___12cPB:hover > td,
.ant-table-tbody___2XZTz > tr > td.ant-table-cell-row-hover___2RlwV {
  background: #b2cbf7;
}
.ant-table-tbody___2XZTz > tr.bgWhite___1i61o:hover > td,
.ant-table-tbody___2XZTz > tr > td.ant-table-cell-row-hover___2RlwV {
  background: #ffffff;
}
@media screen and (max-width: 767px) {
  .bookingBottom___3orWH {
    padding-bottom: 0;
  }
}
.mainLayout___2MgCl {
  background-color: #fefefe;
  padding-bottom: 150px;
}
.mainLayout___2MgCl .mainContent___2cGNd {
  width: 100%;
  margin: auto;
  max-width: 1080px;
  padding: 0 15px;
}
.inviteMemberScreen___1vsHe .bigTitle___1m1P5 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.inviteMemberScreen___1vsHe .bigTitle___1m1P5 .wrapperCompanies___Ld55b {
  margin-left: 20px;
}
.inviteMemberScreen___1vsHe .bigTitle___1m1P5 .wrapperCompanies___Ld55b .companies___1hRnk {
  font-size: 15.75px;
  color: #9d9d9d;
}
.inviteMemberScreen___1vsHe .bigTitle___1m1P5 .bolderIcon___zEtfj {
  width: 10px;
  height: 36px;
  background-color: #33c3c7;
  margin-right: 5px;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .bigTitle___1m1P5 .bolderIcon___zEtfj {
    height: 28px;
    width: 7px;
  }
}
.inviteMemberScreen___1vsHe .bigTitle___1m1P5 .titleIcon___3BZYr {
  width: 4px;
  height: 36px;
  background-color: #33c3c7;
  margin-right: 17px;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .bigTitle___1m1P5 .titleIcon___3BZYr {
    height: 28px;
    width: 2px;
  }
}
.inviteMemberScreen___1vsHe .bigTitle___1m1P5 span {
  font-size: 24px;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .bigTitle___1m1P5 span {
    font-size: 20px;
  }
}
.inviteMemberScreen___1vsHe .progressBar___270X1 {
  padding: 0 55px;
  display: flex;
  position: relative;
  max-width: 800px;
  margin: auto;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .progressBar___270X1 {
    padding: 0 35px 0 20px;
  }
}
.inviteMemberScreen___1vsHe .progressBar___270X1::after {
  content: '';
  position: absolute;
  top: 90%;
  left: 95px;
  transform: translateY(-50%);
  width: calc(100% - 190px);
  height: 100%;
  padding: 0 35px;
  font-size: 20px;
  line-height: 1;
  color: #a1a0a0;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjYAAAAECAYAAACdm3W6AAAA/ElEQVRoge2YsRHCMAxFHxmFFWAEskJauwojhBEYw2lhBVZghayQFWhc5NLENjYXGan1k+6/O6nxYXTuBDyACeiMtTMbNTo3AD3wMtZeC/BRmUrziQ67cv6Rgzpv8zU4633KdPhHZ91VmQ5fOTfAHTgCFz8opAbf04/OXQrwsZlK8yDfOSWTOufna3DW+8zPp2RS5/yZ1Dmsdr2rDfBePE4BA5bcvOrPxcdmKs0vOanOKZnUOT9fg7PeZ34+JZM658+kzmG1611tjLU3oANaY+0zYABA63vOId9WsXxsptK8L9HOKZlK8+os01nvU6ZDLF+Ds+6qTIdYfu3wAZJTby0ImNz8AAAAAElFTkSuQmCC);
  background-size: contain;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .progressBar___270X1::after {
    left: 40px;
    width: calc(100% - 80px);
  }
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .firstStep___328CO {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 12.62px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .firstStep___328CO p {
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 7px);
  color: #414141;
  font-size: 16px;
  min-width: 120px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .progressBar___270X1 .firstStep___328CO p {
    font-size: 14px;
  }
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .activeStep___3Dmkg {
  background-color: #33c3c7 !important;
  color: #eff6fc !important;
  box-shadow: none !important;
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .secondStep___2QX-4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 16px;
  z-index: 1;
  cursor: pointer;
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .secondStep___2QX-4 .secondStepContent___962qS {
  width: 26px;
  height: 26px;
  box-shadow: 1px 1px 10px #b3bcbc60;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .secondStep___2QX-4 p {
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 7px);
  color: #414141;
  font-size: 16px;
  min-width: 120px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .progressBar___270X1 .secondStep___2QX-4 p {
    font-size: 14px;
  }
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .thirdStep___3wQ9w {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 16px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.inviteMemberScreen___1vsHe .progressBar___270X1 .thirdStep___3wQ9w p {
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 7px);
  color: #414141;
  font-size: 16px;
  min-width: 120px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .progressBar___270X1 .thirdStep___3wQ9w p {
    font-size: 14px;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf {
  margin-top: 73px;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6 {
  position: relative;
  padding: 0 15px 0 35px;
  min-height: 165px;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6 {
    padding: 30px 10px 15px;
    margin-bottom: 15px;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6::before {
  position: absolute;
  content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  overflow: hidden;
  color: #a1a0a0;
  line-height: 1;
  word-break: break-all;
  font-size: 20px;
  line-height: 13px;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6::before {
    width: 100%;
    height: 20px;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6::after {
  position: absolute;
  content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  overflow: hidden;
  color: #a1a0a0;
  line-height: 1;
  word-break: break-all;
  font-size: 20px;
  line-height: 13px;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6::after {
    width: 100%;
    height: 20px;
    bottom: 0;
    top: unset;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingInfo___1r4G6 .meetingMethod___2r1Rn {
  word-break: break-all;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingComment___1UrX6 {
  padding-left: 35px;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .meetingComment___1UrX6 {
    padding-left: 0;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .bigTitle___1m1P5 {
  margin-bottom: 37px;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .bigTitle___1m1P5 {
    margin-bottom: 15px;
    flex-wrap: nowrap;
    line-height: 1.3;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .noteStyle___32kb1 {
  color: #9d9d9d;
  font-size: 16px;
  margin-left: 35px;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J {
    margin-bottom: 15px;
    align-items: center;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J p {
  margin: 0;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J .infoCompany___1uJCd {
  font-size: 17px;
  margin-bottom: 12px;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J .avatar___2CXOy {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  overflow: hidden;
  position: relative;
  border: 2px solid #33c3c7;
}
@media screen and (max-width: 767px) {
  .inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J .avatar___2CXOy {
    width: 50px;
    height: 50px;
  }
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J .avatar___2CXOy img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-position: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J .infoName___1UUSl {
  font-size: 13px;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .userInfo___1F05J .meetingDetail___2vzlI {
  font-size: 10px;
  margin: 20px 0 30px 0;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL {
  padding: 25px 10px;
  box-shadow: 1px 1px 10px #b3bcbc60;
  border-radius: 20px;
  min-height: 420px;
  height: 430px;
  overflow-y: auto;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL::-webkit-scrollbar {
  display: none;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL .listFrame___mX6so {
  height: 390px;
  width: 100%;
  overflow-y: auto;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL .listFrame___mX6so::-webkit-scrollbar {
  display: none;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL .listFrame___mX6so .frame___3d2MP {
  height: 40px;
  min-width: 160px;
  margin-bottom: 14px;
  border: 2px solid #dadada;
  color: #414141;
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL .listFrame___mX6so .frame___3d2MP:hover {
  border: 3px solid #33c3c7;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL .listFrame___mX6so .chosenFrame___n8GUy {
  border: 3px solid #33c3c7;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .listTimeFrame___2a2SL .listFrame___mX6so .completedFrame___3KdOl {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .buttonSubmit___24IGU {
  text-align: center;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .buttonSubmit___24IGU button {
  width: auto;
  padding: 0 2rem !important;
  min-width: 170px;
  font-size: 20px;
  border-radius: 25px !important;
  cursor: pointer;
}
.inviteMemberScreen___1vsHe .firstStepDetail___3z_zf .stopInviteEmail___1TS5b {
  cursor: not-allowed !important;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 62px;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m .bigTitle___1m1P5 {
  margin-bottom: 0;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m .suggestLogin___1H-av {
  min-width: 336px;
  height: 100px;
  background: #ececec;
  padding: 15px 12px 10px 12px;
  border-radius: 5px;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m .suggestLogin___1H-av p {
  margin: 0;
  text-align: center;
  font-size: 12px;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m .suggestLogin___1H-av .btnZone___2_qap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m .suggestLogin___1H-av .btnZone___2_qap .signUpBtn___-tF-j {
  height: 35px;
  width: 146px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #33c3c7;
}
.inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m .suggestLogin___1H-av .btnZone___2_qap .signInBtn___1FhEu {
  height: 35px;
  width: 146px;
  font-size: 12px;
  color: #3c3c3c;
  border-radius: 5px;
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .secondStepHeader___2Cz_m {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq {
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA {
  max-width: 100%;
  background-color: #ececec;
  border-radius: 30px;
  display: flex;
  padding: 5px;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM {
  width: 100%;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .title___3tMEe {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .title___3tMEe span {
  font-weight: bold;
  font-size: 16px;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .title___3tMEe span:first-child {
  margin-right: 0.8rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .title___3tMEe span:last-child {
  margin-left: 0.8rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig {
  margin-bottom: 1.3rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW {
  width: 80%;
  border: 1px solid #d8d6d6;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr {
  border: 1px solid #d8d6d6;
  transition: ease-in 0.3s;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr:not(:last-child) td {
  border-bottom: 1px solid #d8d6d6;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr td {
  border: 1px solid #d8d6d6;
  padding: 10px;
  font-size: 18px;
  font-weight: 400 !important;
  color: #565656;
  background: #ffffff;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr td:first-child {
  border-right: 1px solid #d8d6d6;
  background: #f4f4f4;
  text-align: center;
  padding: 0 0.8rem;
}
@media screen and (max-width: 576px) {
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr td:first-child {
    width: 44px;
  }
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr td .tbInput___2eL2T {
  border: none !important;
  font-size: 16px;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr td .changeInput___20eMB {
  background: #b8f5f7;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr:hover {
  background: #b8f5f7;
  transition: ease-in 0.3s;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .dFlex___1Xfcf {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin: 1rem 0;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .dFlex___1Xfcf button {
  width: auto;
  padding: 0 2rem !important;
  min-width: 170px;
  font-size: 16px;
  border-radius: 25px !important;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .imgContainer___EeKud {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .imgContainer___EeKud .linkInviteImg____Lp-R {
  max-width: 80%;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA:first-child {
  width: 50%;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA:last-child {
  width: 48%;
  margin-top: 0.6rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA:last-child .itemInvite___25rWM .title___3tMEe {
  margin-top: 1.5rem;
}
.inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA:last-child .itemInvite___25rWM .dFlex___1Xfcf {
  margin: 2.5rem 0;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq {
    display: block;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA {
    width: 100% !important;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .title___3tMEe {
    margin: 1rem 0;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW {
    width: 80%;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW tr td {
    padding: 7px 10px;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .imgContainer___EeKud {
    margin-bottom: 1rem;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .imgContainer___EeKud .linkInviteImg____Lp-R {
    max-width: 50%;
  }
}
@media screen and (max-width: 425px) {
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq {
    padding: 0 15px;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .tbScroll___a88Ig .tableInvite___2LoWW {
    width: 85%;
  }
  .inviteMemberScreen___1vsHe .inviteMemberEmail___21iiq .inviteMemberMobile___9MWyA .itemInvite___25rWM .imgContainer___EeKud .linkInviteImg____Lp-R {
    max-width: 65%;
  }
}
.inviteMemberScreen___1vsHe .numberAccountContent___3SmH8 {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .numberAccountContent___3SmH8 {
    margin-bottom: 2rem;
  }
}
.inviteMemberScreen___1vsHe .numberAccountContent___3SmH8 .numberAccountBorder___3B8Kk {
  display: inline-block;
  width: 9px;
  height: 28px;
  background: #3c3c3c;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .numberAccountContent___3SmH8 .numberAccountBorder___3B8Kk {
    height: 24px;
  }
}
.inviteMemberScreen___1vsHe .numberAccountContent___3SmH8 .numberAccountTitle___3KUTg {
  font-size: 24px;
  color: #3c3c3c;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .numberAccountContent___3SmH8 .numberAccountTitle___3KUTg {
    font-size: 20px;
  }
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs {
  border: 1px solid #f0f0f0;
  margin-top: 30px;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs .ant-spin-nested-loading {
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table thead tr th {
  background: #ffffff;
  word-break: break-word;
  padding: 15px 20px;
  font-size: 24px;
  color: #565656;
  font-weight: 500 !important;
  border-right: 2px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table thead tr th:nth-child(1),
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table thead tr th:nth-child(2) {
  border-right: 0 solid !important;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table thead tr th {
    padding: 10px 0;
    font-size: 16px;
  }
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table thead tr th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    padding: 10px 10px;
  }
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td {
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 400 !important;
  color: #565656;
  border-right: 2px solid #f0f0f0 !important;
  border-bottom: 2px solid #f0f0f0 !important;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td:nth-child(1),
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td:nth-child(2) {
  border-right: 0 solid !important;
  border-bottom: 1px solid #f0f0f0;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td .btnVote___VWbg2 {
  padding: 2px 20px;
  border-radius: 7px;
  transition: ease 0.3s;
  cursor: pointer;
  border: 4px solid #70e2e5;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.19);
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td .btnVote___VWbg2:hover {
  opacity: 0.8;
  background: #ddf6f5;
  border-color: #33c3c7;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td .btnVoteActive___2FFuJ {
  padding: 10px 20px;
  border-radius: 10px;
  transition: ease 0.3s;
  cursor: pointer;
  border: none;
  background: #33c3c7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.19);
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td {
    font-size: 16px;
  }
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td:nth-child(1) {
    padding: 10px 0;
  }
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td:nth-child(2) {
    padding: 10px 0;
  }
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td:nth-child(3) {
    padding: 10px 0;
  }
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td .btnVote___VWbg2 {
    padding: 2px 14px;
  }
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr td .btnVoteActive___2FFuJ {
    padding: 11px 18px;
  }
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody tr:hover td {
  background: #caf8f8 !important;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs table tbody .mostVote___1fp70 {
  background: #caf8f8;
}
.inviteMemberScreen___1vsHe .tableVoted___2xWfs .sumaryRow___2Oxy0 {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .inviteMemberScreen___1vsHe .tableVoted___2xWfs .sumaryRow___2Oxy0 {
    font-size: 16px;
    font-weight: 400;
  }
}
.dFlexCenter___p_F8c {
  display: flex;
  justify-content: center;
}
.noteVote___2oaaQ {
  color: #909090;
  margin-left: 10px;
  font-size: 16px;
}
.teatArea___kdk2g {
  width: 90%;
  margin: 5%;
  border-radius: 0 !important;
}
.eventTypeOption___ZSHjA {
  border-radius: 20px !important;
  text-align: center;
}
