@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3BOAL {
  display: flex;
  justify-content: center;
}
button.btn___3EY7F {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3EY7F {
    padding: 10px;
  }
}
button.btn___3EY7F:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3EY7F:focus {
  outline: none;
}
.btnWhite___tRMG9.btn___3EY7F {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___tRMG9.btn___3EY7F:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___MFQqo.btn___3EY7F,
.btnGreen___MFQqo.btn___3EY7F:hover,
.btnGreen___MFQqo.btn___3EY7F:active,
.btnGreen___MFQqo.btn___3EY7F:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1_RUE {
  color: #fe0000;
}
.errorMessage___2oIt2 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1TWsU {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1ffS_ {
  display: none !important;
}
.m-auto___JbPCO {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1fU2S {
  max-width: 120px !important;
}
.fc___-Fd9c .fc-timegrid-col___1Az_E.fc-day-today___-_Riz {
  background-color: #ffffff;
}
.fc___-Fd9c .fc-timegrid-slot___2kEd9 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___-Fd9c .fc-timegrid-slot___2kEd9 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___26Ric .fc-direction-ltr___1hvA9 .fc-timegrid-slot-label-frame___2VBo5 {
    font-size: 10px;
  }
  .bookingCalendar___26Ric .fc___-Fd9c .fc-timegrid-slot-label-cushion___3jDEX {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___26Ric .fc___-Fd9c .fc-scrollgrid-section-header___nvILO .fc-scroller___1sji9 {
    overflow: unset !important;
  }
}
.ant-message-notice____yrKZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1_uhb {
  width: 20px;
  height: 20px;
}
.bgTransparent___2VxTJ {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___bvdhs {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___osvEd {
  position: relative;
}
.inputCustomValue___osvEd .ant-input___3RQyy {
  padding-right: 50px;
}
.inputCustomValueText___FgyXm {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3nP-w .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3nP-w .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2jMko .ant-radio-inner___1ayW5 {
  border-color: black !important ;
}
.ant-radio-checked___2jMko .ant-radio-inner___1ayW5:after {
  background-color: black;
}
.ant-radio___eFALq:hover .ant-radio-inner___1ayW5 {
  border-color: black;
}
.ant-radio-checked___2jMko .ant-radio-inner___1ayW5:focus {
  border-color: black;
}
.modal___3lF6O {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3lF6O .close___nkPiB {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3lF6O .header___2j24r {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3lF6O .header___2j24r p {
  margin: 0;
}
.modal___3lF6O .content___34gtw {
  text-align: center;
  font-size: 14px;
}
.modal___3lF6O .content___34gtw p {
  margin: 0;
}
.modal___3lF6O .actions___23YIX {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3lF6O .actions___23YIX .button___S0EC5 {
  margin-top: 10px;
}
.modal___3lF6O .actions___23YIX:hover {
  color: #33c3c7;
}
.fc___-Fd9c .fc-timegrid-slot-minor___skSw- {
  border-top-style: none;
}
.fc___-Fd9c .fc-timegrid-slot___2kEd9 {
  height: 1.5em !important;
}
.messageError___90qgx .ant-message-custom-content___1OPwJ {
  display: flex;
  text-align: start;
}
.messageError___90qgx .ant-message-custom-content___1OPwJ span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3QrSs {
  background-color: #e6e4e4;
}
.notifyMessage___3k8CM .ant-message-notice-content___25I_x {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3k8CM .ant-message-notice-content___25I_x .anticon___1t8nb {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3k8CM .ant-message-notice-content___25I_x span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1mdO3 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1mdO3 .ant-notification-notice-message___1R1U4,
.notifyCustomize___1mdO3 .ant-notification-notice-close___2i8qc,
.notifyCustomize___1mdO3 .ant-notification-notice-icon___74d06 {
  color: white;
}
.notifyCustomize___1mdO3 .ant-notification-notice-message___1R1U4 {
  margin-left: 35px;
}
.notifyCustomize___1mdO3 p {
  margin-bottom: 0;
}
.notifyCustomize___1mdO3 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2d3ZV {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2d3ZV .ant-notification-notice-message___1R1U4 {
  margin-left: 35px;
}
.notifyAlertDuplicate___2d3ZV p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2d3ZV p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1qjX4 {
  width: 434px !important;
}
.handleSubmitVote___1qjX4 .ant-btn-primary___jH_hK {
  background: #59c3c7;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 .ant-modal-confirm-body___24bD_ {
  display: flex !important;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 .ant-modal-confirm-body___24bD_ .anticon___1t8nb {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 .ant-modal-confirm-btns___2oLDO button {
  font-weight: bold;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 .ant-modal-confirm-btns___2oLDO button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 .ant-modal-confirm-btns___2oLDO button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1qjX4 .ant-modal-body___3j2H6 .ant-modal-confirm-btns___2oLDO .ant-btn-primary___jH_hK {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3GPc5 {
  margin-bottom: 0;
}
.mt-0___37T1I {
  margin-top: 0;
}
.tooltipFormat___qq-ti p {
  margin-bottom: 0;
}
.bgWhite___27uAR {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1pTCD {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1pTCD:hover,
.bgPrimaryBlue___1pTCD:focus,
.bgPrimaryBlue___1pTCD:active {
  background: #4f7ac7;
}
.bgDarkBlue___2p06G {
  background-color: #023768 !important;
}
.bgDarkBlue___2p06G:hover,
.bgDarkBlue___2p06G:focus,
.bgDarkBlue___2p06G:active {
  background: #194d7d;
}
.bgGreyBlue___30AeL {
  background-color: #ebf0f9;
}
.bgLightBlue___2DZ0a {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3XurN {
  background-color: #e4453a;
}
.bgLightRed___3XurN:hover,
.bgLightRed___3XurN:focus,
.bgLightRed___3XurN:active {
  background: #e17871;
}
.bgLightYellow___2mO09 {
  background-color: #ffd603;
}
.bgDarkGray___1e3Ua {
  background-color: #333333;
}
.bgMediumGray___2nCxh {
  background-color: #a2a2a2;
}
.bgLightGray___3lFQd {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3wVgO {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue____RGjk {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3WNDw {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2fTr5 {
  color: #3368c7 !important;
}
.textDarkBlue___2ybTA {
  color: #023768 !important;
}
.textLightBlue___D-j87 {
  color: #b2cbf7;
}
.textDarkGray___1UGpz {
  color: #333333 !important;
}
.textMediumGray___pxs5b {
  color: #a2a2a2 !important;
}
.textLightGray___29EIh {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2x9jF {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1Z8Xq {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___ptRX7 {
  border-radius: 5px !important;
}
.rounded-xs___2P3iy {
  border-radius: 10px !important;
}
.rounded-sm___2Q0ot {
  border-radius: 15px !important;
}
.rounded-md___3Kyhb {
  border-radius: 20px !important;
}
.rounded-lg___3-AXm {
  border-radius: 25px !important;
}
.rounded-full___ELkRB {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___SSI9L {
  margin: 0;
}
.mt-2___W57N4 {
  margin-top: 0.5rem;
}
.my-2___21pBd {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1GrXy {
  padding: 0;
}
.px-1___26wlJ {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2bIFf {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2CRx- {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2EF0X {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___moqZ9 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___ZtKr6 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___Whhwz {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3BAzM {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1xGkM {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___ZavF7 {
  padding-bottom: 20px;
}
.h-full___17UFu {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___283f7 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1FDia {
  font-weight: bold !important;
}
.fontWeight400___2BAbK {
  font-weight: 400 !important;
}
.fontWeight500___33WqF {
  font-weight: 500 !important;
}
.fontWeight600___2zg2D {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___27or3 {
  font-size: 12px !important;
}
.fontSize14___nYfW0 {
  font-size: 14px !important;
}
.fontSize16___FBFBX {
  font-size: 16px !important;
}
.fontSize18___zzQqx {
  font-size: 18px !important;
}
.fontSize20___1NQS6 {
  font-size: 20px !important;
}
.fontSize24___1j2JO {
  font-size: 24px !important;
}
.eventName___3P9pn {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___38qK0 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3Rcsx > tr > th,
.ant-table-tbody___2LtKU > tr.ant-table-row___4FrNe > td,
.ant-table-summary___2B8Dh tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3Rcsx > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3UUXe .ant-table-thead___3Rcsx > tr > th,
.table-portrait___3UUXe .ant-table-tbody___2LtKU > tr.ant-table-row___4FrNe > td,
.table-portrait___3UUXe .ant-table-summary___2B8Dh tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3UUXe .bgLightGray___3lFQd td {
  background: #ebebeb;
}
.table-portrait___3UUXe .bgLightBlue___2DZ0a td {
  background: #b2cbf7;
}
.eventContent___2xfoy .timeText___hU2DO {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2LtKU > tr.bgLightGray___3lFQd:hover > td,
.ant-table-tbody___2LtKU > tr > td.ant-table-cell-row-hover___10ekc {
  background: #ebebeb;
}
.ant-table-tbody___2LtKU > tr.bgLightBlue___2DZ0a:hover > td,
.ant-table-tbody___2LtKU > tr > td.ant-table-cell-row-hover___10ekc {
  background: #b2cbf7;
}
.ant-table-tbody___2LtKU > tr.bgWhite___27uAR:hover > td,
.ant-table-tbody___2LtKU > tr > td.ant-table-cell-row-hover___10ekc {
  background: #ffffff;
}
.mainHeader___1jl9z {
  background-color: #fafafa;
  border-bottom: 1px solid #f3f3f3;
}
.hideHeader___Y-cAF {
  display: none;
}
.hideLogo___3Lysw {
  display: none;
}
.header___2j24r {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 0 15px;
}
.header___2j24r .headerTitle___2aB05 {
  display: flex;
  width: 100%;
  max-width: 20%;
}
@media screen and (max-width: 768px) {
  .header___2j24r .headerTitle___2aB05 {
    width: auto;
    max-width: unset;
  }
  .header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 {
    color: #414141;
  }
  .header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 .imgLogo___sHzpv {
    width: 25px;
    height: 40px;
    background-position: unset;
    background-size: unset;
  }
  .header___2j24r .headerTitle___2aB05 .headerMenu___19uOi {
    max-width: unset;
  }
  .header___2j24r .headerTitle___2aB05 .headerMenuItemAvt___1LZ3q {
    margin-right: 5px;
    margin-top: 0;
  }
}
.header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 {
  margin: auto 0;
  color: #414141;
}
.header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 .imgLogo___sHzpv {
  width: 132px;
  height: 30px;
  cursor: pointer;
  background-image: url(./static/logo-black.0de3d552.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media screen and (max-width: 575px) {
  .header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 .imgLogo___sHzpv {
    width: 30px;
    background-image: url(./static/logoMobile.f846061a.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 .imgLogoNotLogin___3LkzG {
  width: 132px;
  height: 30px;
  cursor: pointer;
  background-image: url(./static/logo-black.0de3d552.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media screen and (max-width: 305px) {
  .header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 {
    padding: 0 11px 0 0;
  }
  .header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 .imgLogoNotLogin___3LkzG {
    width: 15px;
    background-position: unset;
    background-size: unset;
  }
}
.header___2j24r .headerTitle___2aB05 .headerTitleText___1K0u2 {
  background: #fff;
  border: 1px solid #ececec;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 20px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  max-width: 300px;
  width: 100%;
  font-size: 20px;
  color: #3c3c3c;
}
@media screen and (max-width: 1024px) {
  .header___2j24r .headerTitle___2aB05 .headerTitleText___1K0u2 {
    display: none;
  }
}
.header___2j24r .headerMenu___19uOi {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  justify-content: flex-end;
}
.header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
  cursor: pointer;
  font-size: 14px;
  margin-right: 56px;
}
.header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC:hover span {
  font-weight: bold;
}
@media screen and (max-width: 920px) {
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    font-size: 12px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 536px) {
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC .textAccount____aPek {
    display: none;
  }
}
@media screen and (max-width: 476px) {
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    margin-right: unset;
  }
}
@media screen and (max-width: 378px) {
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    font-size: 11px;
  }
}
.header___2j24r .headerMenu___19uOi .headerMenuItemLast___1bWX4 {
  margin-right: 0;
  display: flex;
  align-items: center;
}
.header___2j24r .headerMenu___19uOi .fontWeightNormal___3EUOF {
  font-weight: normal;
}
.header___2j24r .headerMenu___19uOi .buttonDeleteCalendarContainer___2eoLZ {
  display: none;
}
.header___2j24r .headerMenu___19uOi .buttonDeleteCalendarContainer___2eoLZ button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.header___2j24r .headerMenu___19uOi .buttonDeleteCalendarContainer___2eoLZ button img {
  color: white;
  margin-right: 5px;
}
.header___2j24r .headerMenu___19uOi .buttonDeleteCalendarContainer___2eoLZ button .buttonDeleteCalendar___2GU33 {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
}
.header___2j24r .headerMenuItemAvt___1LZ3q {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  margin-top: 3px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #33c3c7;
  display: inline-table;
}
.header___2j24r .headerMenuItemAvt___1LZ3q img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.header___2j24r .headerMenuItemAvt___1LZ3q .defaultAvatar___3Qzg3 {
  width: 100%;
  height: 26px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.header___2j24r .headerMenuItemAvt___1LZ3q .defaultAvatar___3Qzg3 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}
.header___2j24r .ant-dropdown-link {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
}
.header___2j24r .ant-dropdown-trigger > .anticon.anticon-down,
.header___2j24r .ant-dropdown-link > .anticon.anticon-down,
.header___2j24r .ant-dropdown-button > .anticon.anticon-down {
  font-size: 13px;
  margin-left: 5px;
}
@media screen and (max-width: 1024px) {
  .header___2j24r .headerTitle___2aB05 {
    max-width: 20%;
  }
  .header___2j24r .headerTitle___2aB05 .headerTitleText___1K0u2 {
    display: none;
  }
}
@media screen and (max-width: 920px) {
  .header___2j24r .headerMenuItem___6ocOC {
    font-size: 12px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .header___2j24r .headerTitle___2aB05 {
    width: auto;
    max-width: unset;
  }
  .header___2j24r .headerTitle___2aB05 .headerLogo___1guf3 {
    color: #414141;
  }
  .header___2j24r .headerMenu___19uOi {
    max-width: unset;
  }
  .header___2j24r .headerMenuItemAvt___1LZ3q {
    margin-right: 5px;
    margin-top: 0;
  }
}
@media screen and (max-width: 536px) {
  .header___2j24r .headerMenu___19uOi {
    max-width: unset;
  }
}
@media screen and (max-width: 476px) {
  .header___2j24r {
    height: unset;
    padding: 0.5rem 7px;
  }
  .header___2j24r .headerMenu___19uOi {
    max-width: unset;
  }
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    margin-right: 8px;
  }
}
@media screen and (max-width: 425px) {
  .header___2j24r .headerMenu___19uOi {
    justify-content: space-between;
  }
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    font-size: 11px;
  }
}
@media screen and (max-width: 408px) {
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    margin-right: 5px;
  }
}
@media screen and (max-width: 378px) {
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    font-size: 11px;
  }
}
@media screen and (max-width: 375px) {
  .header___2j24r .headerMenu___19uOi {
    justify-content: flex-end;
  }
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC {
    font-size: 12px;
    padding-left: 0.5rem;
  }
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC.qAItem___3coI9,
  .header___2j24r .headerMenu___19uOi .headerMenuItem___6ocOC.homeItem___1MM_z {
    display: none;
  }
}
.headerCalendar___LOLXB {
  max-width: none;
  max-width: initial;
}
@media screen and (max-width: 1024px) {
  .headerCalendar___LOLXB .headerMenu___19uOi {
    justify-content: space-around;
  }
}
@media screen and (max-width: 768px) {
  .headerCalendar___LOLXB .headerMenu___19uOi {
    justify-content: space-between;
    padding-left: 0.5rem;
  }
  .headerCalendar___LOLXB .headerMenu___19uOi .headerMenuItem___6ocOC:not(:first-child) {
    display: none;
  }
  .headerCalendar___LOLXB .headerMenu___19uOi .dropDownItem___lN_vO {
    display: none;
  }
  .headerCalendar___LOLXB .headerMenu___19uOi .buttonDeleteCalendarContainer___2eoLZ {
    display: unset;
  }
}
@media screen and (max-width: 375px) {
  .headerCalendar___LOLXB .headerMenu___19uOi .headerMenuItem___6ocOC.homeItem___1MM_z {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .headerCalendarCopy___10DZE .headerMenu___19uOi {
    justify-content: space-between;
  }
  .headerCalendarCopy___10DZE .headerMenu___19uOi .dropDownItem___lN_vO:nth-child(2),
  .headerCalendarCopy___10DZE .headerMenu___19uOi .dropDownItem___lN_vO:nth-child(3) {
    display: none;
  }
  .headerCalendarCopy___10DZE .headerMenu___19uOi .headerMenuItem___6ocOC:not(:first-child) {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .headerCalendarCopy___10DZE .headerMenu___19uOi {
    justify-content: space-between;
  }
  .headerCalendarCopy___10DZE .headerMenu___19uOi .headerMenuItem___6ocOC.homeItem___1MM_z {
    display: unset;
  }
}
.headerSettingAdvanceCalendar___1GTtA {
  max-width: 1080px !important;
}
.headerLogin___30zND {
  padding: 0 15px;
}
.headerLogin___30zND .headerMenu___19uOi .dropDownItem___lN_vO {
  display: none;
}
@media screen and (max-width: 638px) {
  .headerLogin___30zND .headerMenu___19uOi {
    justify-content: flex-end;
  }
  .headerLogin___30zND .headerMenu___19uOi .headerMenuItem___6ocOC {
    display: unset !important;
  }
}
.dropdownMenu___LF2tx {
  border-radius: 20px !important;
  padding: 0px !important;
  overflow: hidden;
  min-width: 160px;
  left: -20px;
  top: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.dropdownMenu___LF2tx li:last-child {
  border-top: 1px solid #a1a0a0;
}
.dropdownMenuSelectCalendar___3UfUQ {
  max-height: 500px;
  overflow: auto;
  border-radius: 4px !important;
  padding: 10px 0 !important;
  width: 280px;
  left: 65px;
  position: absolute;
  top: 7px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
@media screen and (max-width: 767px) {
  .dropdownMenuSelectCalendar___3UfUQ {
    left: 30px;
  }
}
.dropdownMenuSelectCalendar___3UfUQ .unstyleItem___bGFzr {
  padding: 0;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: transparent !important;
}
.dropdownMenuSelectCalendar___3UfUQ .unstyleItem___bGFzr:hover {
  background-color: #f0f0f0;
}
.dropdownMenuSelectCalendar___3UfUQ .unstyleItem___bGFzr .customInput___6B9YO {
  width: 100%;
  padding: 5px 10px;
}
.dropdownMenuSelectCalendar___3UfUQ .unstyleItem___bGFzr .customInput___6B9YO .ant-select-selector {
  border: 3px solid #ebebeb !important;
}
.dropdownMenuSelectCalendar___3UfUQ .unstyleItem___bGFzr .customInput___6B9YO .ant-select-selector input {
  padding: 4px 0;
}
@media screen and (max-width: 415px) {
  .dropdownMenuSelectCalendar___3UfUQ .unstyleItem___bGFzr .customInput___6B9YO .ant-select-selector {
    width: 249px;
  }
}
.dropdownMenuSelectCalendar___3UfUQ .wrapperProfile___112nG {
  padding: 5px 5px 5px 1.5rem;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}
.dropdownMenuSelectCalendar___3UfUQ .wrapperProfile___112nG:hover {
  background-color: #f0f0f0;
}
.dropdownMenuSelectCalendar___3UfUQ .wrapperProfile___112nG .avatar___2iWe_ .defaultAvatar___3Qzg3 {
  width: 40px;
  height: 40px;
}
.dropdownMenuSelectCalendar___3UfUQ .wrapperProfileTeam___Opcm0 .avatar___2iWe_ {
  border: none;
  margin-right: 22px;
}
.dropdownMenuSelectCalendar___3UfUQ .wrapperProfileTeam___Opcm0 .avatar___2iWe_ img {
  display: none;
}
.dropdownMenuAccount___3rBrt {
  margin-top: 10px;
  border-radius: 3px !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownMenuAccount___3rBrt li:last-child {
  border-top: 1px solid #a1a0a0;
}
.dropdownSettingTemplate___1WdB- {
  margin-top: 10px;
  border-radius: 3px !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownSettingTemplate___1WdB- li img {
  margin-right: 0.8rem;
}
.dropdownSettingTemplate___1WdB- li span {
  color: #3c3c3c !important;
}
.wrapperUser___JSSM5 {
  color: #565656;
  display: flex;
  justify-content: space-between;
}
.wrapperUser___JSSM5 .linkAdd___ujT-r {
  color: #1ca9ad;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 16px;
}
.wrapperUser___JSSM5 .linkAdd___ujT-r .iconGreen___1siEj {
  width: 6px !important;
  height: 50%;
  background: #33c3c7;
  margin: auto;
}
.wrapperUser___JSSM5 .linkAdd___ujT-r .iconGrey___1pCtJ {
  width: 6px !important;
  height: 50%;
  background: #33c3c7;
  margin: auto;
}
.wrapperUser___JSSM5 .linkAdd___ujT-r p {
  font-size: 20px;
  padding-left: 0.5rem;
  margin-bottom: 0;
}
.wrapperUser___JSSM5 .linkAdd___ujT-r:first-child {
  padding-left: 0.3rem;
  color: #33c3c7;
}
.wrapperUser___JSSM5 .linkAdd___ujT-r:last-child {
  font-weight: 500;
}
.wrapperProfileMember___2OzqQ {
  display: flex;
  align-items: center;
}
.wrapperProfileMember___2OzqQ .avatar___2iWe_ {
  width: 36px;
  height: 36px;
}
.wrapperProfileMember___2OzqQ .avatar___2iWe_ .defaultAvatar___3Qzg3 {
  width: 36px;
  height: 36px;
}
.avatar___2iWe_ {
  width: 40px;
  height: 40px;
  margin-right: 14px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #33c3c7;
  display: inline-table;
}
.avatar___2iWe_ .defaultAvatar___3Qzg3 {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.avatar___2iWe_ .defaultAvatar___3Qzg3 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .avatar___2iWe_ {
    margin-right: 5px;
    margin-top: 0;
  }
}
.titleMain___3JYwx {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1.8rem;
  color: #000000;
  font-weight: 500;
  font-size: 20px;
}
.nameAccount___3JRUp {
  padding-top: 0;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logoutConfirm___3nP-w {
  border-radius: 20px !important;
}
.disabledLink___3vvc9 {
  cursor: not-allowed;
}
.confirmDeleteTemplate___2G7vg .ant-modal-confirm-btns button:first-child {
  background: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
.confirmDeleteTemplate___2G7vg .ant-modal-confirm-btns button:last-child {
  background: white;
  border-color: #bbb6b6;
  color: #3c3c3c;
}
.greyHover___3vMDl {
  margin-top: 0 !important;
  margin-bottom: 0.4rem !important;
  background: transparent !important;
  padding-left: 1.5rem !important;
}
.greyHover___3vMDl .listUser___3YkOC {
  display: flex;
  justify-items: center;
}
.greyHover___3vMDl .listUser___3YkOC .iconUser___1OuVZ {
  width: 40px;
  text-align: center;
  border-radius: 25px;
  background-color: #b6b6b6;
}
.greyHover___3vMDl .listUser___3YkOC .iconUser___1OuVZ img {
  margin-bottom: 5px;
}
.greyHover___3vMDl .listUser___3YkOC a {
  font-size: 16px;
  padding-left: 0.5rem;
}
.greyHover___3vMDl span a {
  color: rgba(0, 0, 0, 0.85) !important;
}
.greyHover___3vMDl:hover {
  background-color: #f0f0f0 !important;
}
.greyHover___3vMDl:hover span a {
  color: rgba(0, 0, 0, 0.85) !important;
}
.notifySettingTemplate___3awDi span a {
  color: white;
}
.notifySettingTemplate___3awDi .ant-notification-notice-close {
  display: none;
}
