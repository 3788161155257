.btnGroupNextPre___28Ppk {
  display: flex;
}
.btnGroupNextPre___28Ppk button {
  height: 90%;
  border: 1px solid transparent;
  background: #fff;
  color: #3c3c3c;
  border-radius: 0.6rem;
  padding: 0 1.2rem;
  cursor: pointer;
  box-shadow: #00000017 0 2px 8px 0;
}
.btnGroupNextPre___28Ppk button:first-child {
  margin: auto 1rem auto 0;
}
.btnGroupNextPre___28Ppk button:last-child {
  color: #fff;
  background: #3773e1;
  margin: auto 0 auto 1rem;
}
.btnGroupNextPre___28Ppk button .iconNextLef___2GPUD {
  margin-right: 0.5rem;
}
.btnGroupNextPre___28Ppk button .iconNextRight____rkqC {
  margin-left: 0.5rem;
}
@media screen and (max-width: 1346px) {
  .btnGroupNextPre___28Ppk button {
    padding: 0 0.8rem;
  }
  .btnGroupNextPre___28Ppk button:first-child {
    margin: auto 0.5rem auto 0;
  }
  .btnGroupNextPre___28Ppk button:last-child {
    color: #fff;
    background: #3773e1;
    margin: auto 0 auto 0.5rem;
  }
}
@media screen and (max-width: 425px) {
  .btnGroupNextPre___28Ppk {
    display: none;
  }
}
