@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___26kle {
  display: flex;
  justify-content: center;
}
button.btn___z00RT {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___z00RT {
    padding: 10px;
  }
}
button.btn___z00RT:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___z00RT:focus {
  outline: none;
}
.btnWhite___xJRNp.btn___z00RT {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___xJRNp.btn___z00RT:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___xDDlr.btn___z00RT,
.btnGreen___xDDlr.btn___z00RT:hover,
.btnGreen___xDDlr.btn___z00RT:active,
.btnGreen___xDDlr.btn___z00RT:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3LSX3 {
  color: #fe0000;
}
.errorMessage___1oxRb {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___W__pM {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___31Usn {
  display: none !important;
}
.m-auto___157vT {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3Pcyq {
  max-width: 120px !important;
}
.fc___rNkK8 .fc-timegrid-col___3Hc8x.fc-day-today___3HLhJ {
  background-color: #ffffff;
}
.fc___rNkK8 .fc-timegrid-slot___2Jx4m {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___rNkK8 .fc-timegrid-slot___2Jx4m {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3dNVn .fc-direction-ltr___TXnJ0 .fc-timegrid-slot-label-frame___PNFgk {
    font-size: 10px;
  }
  .bookingCalendar___3dNVn .fc___rNkK8 .fc-timegrid-slot-label-cushion___3tMQF {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3dNVn .fc___rNkK8 .fc-scrollgrid-section-header___1Pb3h .fc-scroller___37Qn- {
    overflow: unset !important;
  }
}
.ant-message-notice___2RulY {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1RjA9 {
  width: 20px;
  height: 20px;
}
.bgTransparent___3bQUr {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3BI0o {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1NUIP {
  position: relative;
}
.inputCustomValue___1NUIP .ant-input___3E9gV {
  padding-right: 50px;
}
.inputCustomValueText___1VnBj {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2TymX .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2TymX .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___9LbDH .ant-radio-inner___3W_zg {
  border-color: black !important ;
}
.ant-radio-checked___9LbDH .ant-radio-inner___3W_zg:after {
  background-color: black;
}
.ant-radio___2NB70:hover .ant-radio-inner___3W_zg {
  border-color: black;
}
.ant-radio-checked___9LbDH .ant-radio-inner___3W_zg:focus {
  border-color: black;
}
.modal___1ZdN1 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1ZdN1 .close___2vMz1 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1ZdN1 .header___3mh-k {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1ZdN1 .header___3mh-k p {
  margin: 0;
}
.modal___1ZdN1 .content___3bN-i {
  text-align: center;
  font-size: 14px;
}
.modal___1ZdN1 .content___3bN-i p {
  margin: 0;
}
.modal___1ZdN1 .actions___UxaJE {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1ZdN1 .actions___UxaJE .button___1Lndu {
  margin-top: 10px;
}
.modal___1ZdN1 .actions___UxaJE:hover {
  color: #33c3c7;
}
.fc___rNkK8 .fc-timegrid-slot-minor___1FzzY {
  border-top-style: none;
}
.fc___rNkK8 .fc-timegrid-slot___2Jx4m {
  height: 1.5em !important;
}
.messageError___-beQC .ant-message-custom-content___1IceX {
  display: flex;
  text-align: start;
}
.messageError___-beQC .ant-message-custom-content___1IceX span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___26x0L {
  background-color: #e6e4e4;
}
.notifyMessage___2L-1s .ant-message-notice-content___1E7wE {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2L-1s .ant-message-notice-content___1E7wE .anticon___TIlNB {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2L-1s .ant-message-notice-content___1E7wE span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___zVwgA {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___zVwgA .ant-notification-notice-message___1uo3i,
.notifyCustomize___zVwgA .ant-notification-notice-close___Wo8e5,
.notifyCustomize___zVwgA .ant-notification-notice-icon___1hHcI {
  color: white;
}
.notifyCustomize___zVwgA .ant-notification-notice-message___1uo3i {
  margin-left: 35px;
}
.notifyCustomize___zVwgA p {
  margin-bottom: 0;
}
.notifyCustomize___zVwgA p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1PNU3 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1PNU3 .ant-notification-notice-message___1uo3i {
  margin-left: 35px;
}
.notifyAlertDuplicate___1PNU3 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1PNU3 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3U-BS {
  width: 434px !important;
}
.handleSubmitVote___3U-BS .ant-btn-primary___s4SGk {
  background: #59c3c7;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm .ant-modal-confirm-body___1DnPu {
  display: flex !important;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm .ant-modal-confirm-body___1DnPu .anticon___TIlNB {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm .ant-modal-confirm-btns___1-3Vw button {
  font-weight: bold;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm .ant-modal-confirm-btns___1-3Vw button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm .ant-modal-confirm-btns___1-3Vw button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3U-BS .ant-modal-body___38snm .ant-modal-confirm-btns___1-3Vw .ant-btn-primary___s4SGk {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3F0Bk {
  margin-bottom: 0;
}
.mt-0___rcFtS {
  margin-top: 0;
}
.tooltipFormat___1JoET p {
  margin-bottom: 0;
}
.bgWhite___nvtd1 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___9tXY1 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___9tXY1:hover,
.bgPrimaryBlue___9tXY1:focus,
.bgPrimaryBlue___9tXY1:active {
  background: #4f7ac7;
}
.bgDarkBlue___19jPJ {
  background-color: #023768 !important;
}
.bgDarkBlue___19jPJ:hover,
.bgDarkBlue___19jPJ:focus,
.bgDarkBlue___19jPJ:active {
  background: #194d7d;
}
.bgGreyBlue___1e3-g {
  background-color: #ebf0f9;
}
.bgLightBlue___2dD-u {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3MzB- {
  background-color: #e4453a;
}
.bgLightRed___3MzB-:hover,
.bgLightRed___3MzB-:focus,
.bgLightRed___3MzB-:active {
  background: #e17871;
}
.bgLightYellow___1GN22 {
  background-color: #ffd603;
}
.bgDarkGray___BBuNN {
  background-color: #333333;
}
.bgMediumGray___2Cz4j {
  background-color: #a2a2a2;
}
.bgLightGray___1Cc56 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___iQoGU {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___MWFUE {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___19rRT {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue____xZ-4 {
  color: #3368c7 !important;
}
.textDarkBlue___Gbu3X {
  color: #023768 !important;
}
.textLightBlue___p75dZ {
  color: #b2cbf7;
}
.textDarkGray___3wNO7 {
  color: #333333 !important;
}
.textMediumGray___1ylyi {
  color: #a2a2a2 !important;
}
.textLightGray___1oge9 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___Tqe9y {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3wpfX {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___12T9b {
  border-radius: 5px !important;
}
.rounded-xs___1_ZKZ {
  border-radius: 10px !important;
}
.rounded-sm___2Mutc {
  border-radius: 15px !important;
}
.rounded-md___1iSvP {
  border-radius: 20px !important;
}
.rounded-lg___3oW1a {
  border-radius: 25px !important;
}
.rounded-full___16N2R {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___cEBCu {
  margin: 0;
}
.mt-2___3sM_5 {
  margin-top: 0.5rem;
}
.my-2___6xYLL {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2fk0O {
  padding: 0;
}
.px-1___3LbEQ {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___PsHIG {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___10WQB {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1WvxB {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___qSQzs {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1fu8V {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3DxXA {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2Q-oN {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3L5wG {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2X2a8 {
  padding-bottom: 20px;
}
.h-full___3cBjw {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2fXJH {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1WENJ {
  font-weight: bold !important;
}
.fontWeight400___3Snyf {
  font-weight: 400 !important;
}
.fontWeight500___OYWcY {
  font-weight: 500 !important;
}
.fontWeight600___271-F {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2dLJq {
  font-size: 12px !important;
}
.fontSize14___2VwPS {
  font-size: 14px !important;
}
.fontSize16___j_niO {
  font-size: 16px !important;
}
.fontSize18___dejHA {
  font-size: 18px !important;
}
.fontSize20___2G_OQ {
  font-size: 20px !important;
}
.fontSize24___2PYjP {
  font-size: 24px !important;
}
.eventName___1XFFH {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1y_Qa {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3ZCVN > tr > th,
.ant-table-tbody___1DFQc > tr.ant-table-row___2Z0_W > td,
.ant-table-summary___1AVyT tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3ZCVN > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1Y_so .ant-table-thead___3ZCVN > tr > th,
.table-portrait___1Y_so .ant-table-tbody___1DFQc > tr.ant-table-row___2Z0_W > td,
.table-portrait___1Y_so .ant-table-summary___1AVyT tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1Y_so .bgLightGray___1Cc56 td {
  background: #ebebeb;
}
.table-portrait___1Y_so .bgLightBlue___2dD-u td {
  background: #b2cbf7;
}
.eventContent___1YnYM .timeText___vvYaE {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1DFQc > tr.bgLightGray___1Cc56:hover > td,
.ant-table-tbody___1DFQc > tr > td.ant-table-cell-row-hover___2GZcj {
  background: #ebebeb;
}
.ant-table-tbody___1DFQc > tr.bgLightBlue___2dD-u:hover > td,
.ant-table-tbody___1DFQc > tr > td.ant-table-cell-row-hover___2GZcj {
  background: #b2cbf7;
}
.ant-table-tbody___1DFQc > tr.bgWhite___nvtd1:hover > td,
.ant-table-tbody___1DFQc > tr > td.ant-table-cell-row-hover___2GZcj {
  background: #ffffff;
}
.calendarEditContainer___EKxpU {
  padding: 20px;
}
.calendarEditContainer___EKxpU .labelName___2HP8y {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}
.calendarEditContainer___EKxpU .labelName___2HP8y .labelIcon___36dbb {
  padding-right: 3px;
  width: 20px;
}
.calendarEditContainer___EKxpU .inputField___3MDCg {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
}
.calendarEditContainer___EKxpU .saveBtn___1peYJ {
  width: 50%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
