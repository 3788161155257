@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___32rHz {
  display: flex;
  justify-content: center;
}
button.btn___3WxD1 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3WxD1 {
    padding: 10px;
  }
}
button.btn___3WxD1:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3WxD1:focus {
  outline: none;
}
.btnWhite___1dDuZ.btn___3WxD1 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1dDuZ.btn___3WxD1:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3TEWT.btn___3WxD1,
.btnGreen___3TEWT.btn___3WxD1:hover,
.btnGreen___3TEWT.btn___3WxD1:active,
.btnGreen___3TEWT.btn___3WxD1:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___-qSYR {
  color: #fe0000;
}
.errorMessage___22j8P {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1e_mf {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___OJzHY {
  display: none !important;
}
.m-auto___2y8MK {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___QOeMa {
  max-width: 120px !important;
}
.fc___sX5k- .fc-timegrid-col___1h_SX.fc-day-today___2mk5N {
  background-color: #ffffff;
}
.fc___sX5k- .fc-timegrid-slot___1BzKQ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___sX5k- .fc-timegrid-slot___1BzKQ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1tUWf .fc-direction-ltr___1sz67 .fc-timegrid-slot-label-frame___1bd0J {
    font-size: 10px;
  }
  .bookingCalendar___1tUWf .fc___sX5k- .fc-timegrid-slot-label-cushion___21XDS {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1tUWf .fc___sX5k- .fc-scrollgrid-section-header___11ii7 .fc-scroller___1WK3Z {
    overflow: unset !important;
  }
}
.ant-message-notice___1qLPD {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___iN1iy {
  width: 20px;
  height: 20px;
}
.bgTransparent___327FL {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1oFx8 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___61zda {
  position: relative;
}
.inputCustomValue___61zda .ant-input___1H-8R {
  padding-right: 50px;
}
.inputCustomValueText___1741E {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___30t4f .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___30t4f .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2KiH_ .ant-radio-inner___1YWxi {
  border-color: black !important ;
}
.ant-radio-checked___2KiH_ .ant-radio-inner___1YWxi:after {
  background-color: black;
}
.ant-radio___2gXxr:hover .ant-radio-inner___1YWxi {
  border-color: black;
}
.ant-radio-checked___2KiH_ .ant-radio-inner___1YWxi:focus {
  border-color: black;
}
.modal___3n-xY {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3n-xY .close___kNNIO {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3n-xY .header___g-dmj {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3n-xY .header___g-dmj p {
  margin: 0;
}
.modal___3n-xY .content___1RpJ0 {
  text-align: center;
  font-size: 14px;
}
.modal___3n-xY .content___1RpJ0 p {
  margin: 0;
}
.modal___3n-xY .actions____O3j9 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3n-xY .actions____O3j9 .button___j5T_K {
  margin-top: 10px;
}
.modal___3n-xY .actions____O3j9:hover {
  color: #33c3c7;
}
.fc___sX5k- .fc-timegrid-slot-minor___1jYyF {
  border-top-style: none;
}
.fc___sX5k- .fc-timegrid-slot___1BzKQ {
  height: 1.5em !important;
}
.messageError___BdNAI .ant-message-custom-content___2lXSY {
  display: flex;
  text-align: start;
}
.messageError___BdNAI .ant-message-custom-content___2lXSY span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3wxLH {
  background-color: #e6e4e4;
}
.notifyMessage___NShz2 .ant-message-notice-content___3M44I {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___NShz2 .ant-message-notice-content___3M44I .anticon___EWCx1 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___NShz2 .ant-message-notice-content___3M44I span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3rTmV {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3rTmV .ant-notification-notice-message___yIq86,
.notifyCustomize___3rTmV .ant-notification-notice-close___1T1x3,
.notifyCustomize___3rTmV .ant-notification-notice-icon___Nr43h {
  color: white;
}
.notifyCustomize___3rTmV .ant-notification-notice-message___yIq86 {
  margin-left: 35px;
}
.notifyCustomize___3rTmV p {
  margin-bottom: 0;
}
.notifyCustomize___3rTmV p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___nRYoh {
  padding: 16px 14px;
}
.notifyAlertDuplicate___nRYoh .ant-notification-notice-message___yIq86 {
  margin-left: 35px;
}
.notifyAlertDuplicate___nRYoh p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___nRYoh p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1U40O {
  width: 434px !important;
}
.handleSubmitVote___1U40O .ant-btn-primary___1n_oZ {
  background: #59c3c7;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ .ant-modal-confirm-body___PD3MG {
  display: flex !important;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ .ant-modal-confirm-body___PD3MG .anticon___EWCx1 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ .ant-modal-confirm-btns___2kIiY button {
  font-weight: bold;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ .ant-modal-confirm-btns___2kIiY button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ .ant-modal-confirm-btns___2kIiY button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1U40O .ant-modal-body___2eTyJ .ant-modal-confirm-btns___2kIiY .ant-btn-primary___1n_oZ {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3_89e {
  margin-bottom: 0;
}
.mt-0___3fRi1 {
  margin-top: 0;
}
.tooltipFormat___mtOWL p {
  margin-bottom: 0;
}
.bgWhite___2G8I3 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___38XNN {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___38XNN:hover,
.bgPrimaryBlue___38XNN:focus,
.bgPrimaryBlue___38XNN:active {
  background: #4f7ac7;
}
.bgDarkBlue___2UbrG {
  background-color: #023768 !important;
}
.bgDarkBlue___2UbrG:hover,
.bgDarkBlue___2UbrG:focus,
.bgDarkBlue___2UbrG:active {
  background: #194d7d;
}
.bgGreyBlue___32YTQ {
  background-color: #ebf0f9;
}
.bgLightBlue___3H9j- {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2MOL8 {
  background-color: #e4453a;
}
.bgLightRed___2MOL8:hover,
.bgLightRed___2MOL8:focus,
.bgLightRed___2MOL8:active {
  background: #e17871;
}
.bgLightYellow___3RCod {
  background-color: #ffd603;
}
.bgDarkGray___14Y2k {
  background-color: #333333;
}
.bgMediumGray___3YpEp {
  background-color: #a2a2a2;
}
.bgLightGray___vd5ef {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2SF5M {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2M6r1 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1ps7I {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___fUtVW {
  color: #3368c7 !important;
}
.textDarkBlue___U7B7W {
  color: #023768 !important;
}
.textLightBlue___2lJdY {
  color: #b2cbf7;
}
.textDarkGray___2vXOY {
  color: #333333 !important;
}
.textMediumGray___1QiUB {
  color: #a2a2a2 !important;
}
.textLightGray___3Cqzt {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2OP5b {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2Du3- {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2OkFf {
  border-radius: 5px !important;
}
.rounded-xs___3U4Fl {
  border-radius: 10px !important;
}
.rounded-sm___1EZj1 {
  border-radius: 15px !important;
}
.rounded-md___EpPsJ {
  border-radius: 20px !important;
}
.rounded-lg___3-jhL {
  border-radius: 25px !important;
}
.rounded-full___2UWqV {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2mC0l {
  margin: 0;
}
.mt-2___1w896 {
  margin-top: 0.5rem;
}
.my-2___IGzX4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1hXaD {
  padding: 0;
}
.px-1___1V_i_ {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___34vWl {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___z5RQH {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___zOrKt {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1CMd5 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1HCVi {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___BqHSv {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2WR8r {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___JWtKU {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___B5vuA {
  padding-bottom: 20px;
}
.h-full___1TgZa {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3gXx7 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___ztfC9 {
  font-weight: bold !important;
}
.fontWeight400____5UhT {
  font-weight: 400 !important;
}
.fontWeight500___11Lu8 {
  font-weight: 500 !important;
}
.fontWeight600___1tUQP {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1TVw4 {
  font-size: 12px !important;
}
.fontSize14___QGAs7 {
  font-size: 14px !important;
}
.fontSize16___1e7xU {
  font-size: 16px !important;
}
.fontSize18___JG0-b {
  font-size: 18px !important;
}
.fontSize20___2fl-f {
  font-size: 20px !important;
}
.fontSize24___3CGOt {
  font-size: 24px !important;
}
.eventName___13jo4 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___17gds {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___f6JWm > tr > th,
.ant-table-tbody___1aNV4 > tr.ant-table-row___36Q3K > td,
.ant-table-summary___18w5j tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___f6JWm > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1U2n5 .ant-table-thead___f6JWm > tr > th,
.table-portrait___1U2n5 .ant-table-tbody___1aNV4 > tr.ant-table-row___36Q3K > td,
.table-portrait___1U2n5 .ant-table-summary___18w5j tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1U2n5 .bgLightGray___vd5ef td {
  background: #ebebeb;
}
.table-portrait___1U2n5 .bgLightBlue___3H9j- td {
  background: #b2cbf7;
}
.eventContent___3tm51 .timeText___1d_f8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1aNV4 > tr.bgLightGray___vd5ef:hover > td,
.ant-table-tbody___1aNV4 > tr > td.ant-table-cell-row-hover___2Mpsc {
  background: #ebebeb;
}
.ant-table-tbody___1aNV4 > tr.bgLightBlue___3H9j-:hover > td,
.ant-table-tbody___1aNV4 > tr > td.ant-table-cell-row-hover___2Mpsc {
  background: #b2cbf7;
}
.ant-table-tbody___1aNV4 > tr.bgWhite___2G8I3:hover > td,
.ant-table-tbody___1aNV4 > tr > td.ant-table-cell-row-hover___2Mpsc {
  background: #ffffff;
}
.profileContainer___2EVc9 {
  max-width: 1080px;
  margin: auto;
  width: 100%;
}
.profileContainer___2EVc9 .header___g-dmj {
  font-size: 24px;
  font-weight: bold;
}
.profileContainer___2EVc9 .avtImage___2XY6N {
  border-radius: 50%;
  border: 3px solid #33c3c7;
  display: inline-table;
}
.profileContainer___2EVc9 .informationForm___jQTMs {
  padding: 0 35px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .informationForm___jQTMs {
    padding: 0;
  }
}
.profileContainer___2EVc9 button {
  border-radius: 20px;
  height: 49px;
  padding: 4px 15px !important;
}
.profileContainer___2EVc9 .groupBtn___3i0Vc {
  display: flex;
  justify-content: center;
}
.profileContainer___2EVc9 .groupBtn___3i0Vc button {
  height: 49px;
  min-width: 130px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .groupBtn___3i0Vc button {
    min-width: auto;
  }
}
.profileContainer___2EVc9 .groupBtn___3i0Vc button:first-child {
  margin-right: 30px !important;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .groupBtn___3i0Vc button:first-child {
    margin-right: 15px !important;
  }
}
.profileContainer___2EVc9 .headTitle___1j_0m {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .headTitle___1j_0m {
    margin-bottom: 20px;
  }
}
.profileContainer___2EVc9 .headTitle___1j_0m .bolderIcon___A3Gnf {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.profileContainer___2EVc9 .headTitle___1j_0m .titleIcon___1TryP {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.profileContainer___2EVc9 .headTitle___1j_0m span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .headTitle___1j_0m span {
    font-size: 20px;
  }
}
.profileContainer___2EVc9 .ant-avatar img {
  object-fit: fill;
}
.profileContainer___2EVc9 .ant-avatar.ant-avatar-circle {
  border: 0;
}
.profileContainer___2EVc9 input {
  height: 58px;
  border-radius: 16px !important;
  font-size: 16px;
}
.profileContainer___2EVc9 label {
  font-size: 16px;
}
.profileContainer___2EVc9 .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}
.profileContainer___2EVc9 .btn.btn-custom-height {
  font-size: 16px;
}
.profileContainer___2EVc9 .avatarRow___3EPb4 .avatarButton___2zVhH {
  margin-left: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .avatarRow___3EPb4 .avatarButton___2zVhH {
    margin-left: 25px;
  }
}
.profileContainer___2EVc9 .urlRow___19M2h .ant-input-group-addon {
  background: none;
  border: none;
  padding-left: 0;
  font-size: 16px;
}
.profileContainer___2EVc9 .urlRow___19M2h .ant-input {
  border-radius: 8px;
}
.profileContainer___2EVc9 .wrapperAvatar___2uT6Y {
  width: 136px;
  height: 136px;
}
.profileContainer___2EVc9 .wrapperAvatar___2uT6Y img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profileContainer___2EVc9 .btnUpdate___1NQWF {
  margin-bottom: 0;
}
.profileContainer___2EVc9 .btnUpdate___1NQWF button {
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 20px;
  height: 49px;
  width: 209px;
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .btnUpdate___1NQWF button {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.profileContainer___2EVc9 .groupPart___3KkU2 {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .groupPart___3KkU2 {
    margin-bottom: 50px;
  }
}
.profileContainer___2EVc9 .groupPart___3KkU2 .partName___i6Sws {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .groupPart___3KkU2 .partName___i6Sws {
    margin-bottom: 30px;
  }
}
.profileContainer___2EVc9 .groupPart___3KkU2 .disconnectBtn___3cOdV:not(:disabled) {
  color: #ffffff;
  background-color: #b6b6b6;
}
.profileContainer___2EVc9 .groupPart___3KkU2 .disconnectBtn___3cOdV:not(:disabled):hover {
  color: #ffffff;
  background-color: #b6b6b6;
  opacity: 0.8;
}
.profileContainer___2EVc9 .groupPart___3KkU2 button {
  border-radius: 20px;
  margin-left: 90px;
  font-size: 20px;
  max-width: 340px;
  width: 340px;
  height: 49px;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .groupPart___3KkU2 button {
    margin-left: 0;
    font-size: 18px;
  }
}
.profileContainer___2EVc9 .groupPart___3KkU2 .partNameTitle___2v2xl {
  font-size: 30px;
  color: #3c3c3c;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .profileContainer___2EVc9 .groupPart___3KkU2 .partNameTitle___2v2xl {
    font-size: 18px;
  }
}
.profileContainer___2EVc9 .groupPart___3KkU2 .partNameBorder___2LU1P {
  display: inline-block;
  width: 9px;
  height: 30px;
  background: dodgerblue;
  margin-right: 10px;
}
.profileContainer___2EVc9 .backToHome___14iUO button {
  border-radius: 20px;
  height: 49px;
  font-size: 20px;
  width: 209px;
}
