.container___3zXLg {
  padding: 20px;
}
.container___3zXLg .addPartner___1GDs3 {
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}
.container___3zXLg .addPartner___1GDs3 .addPartnerBtn___2hgbx {
  border: none;
  height: 40px;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.container___3zXLg .savePartnerBtn___2DCcO {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.container___3zXLg .selectField___3rycj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container___3zXLg .selectField___3rycj .formField___2h2Tw {
  width: 100%;
  margin-top: 10px;
}
.container___3zXLg .selectField___3rycj .formField___2h2Tw .inputField___OsEUi {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.container___3zXLg .removeSelectBtn___3zPuI {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0 10px;
  height: auto;
}
