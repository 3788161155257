@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1uzQS {
  display: flex;
  justify-content: center;
}
button.btn___gPlxp {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___gPlxp {
    padding: 10px;
  }
}
button.btn___gPlxp:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___gPlxp:focus {
  outline: none;
}
.btnWhite___2i1SF.btn___gPlxp {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2i1SF.btn___gPlxp:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___wK7T1.btn___gPlxp,
.btnGreen___wK7T1.btn___gPlxp:hover,
.btnGreen___wK7T1.btn___gPlxp:active,
.btnGreen___wK7T1.btn___gPlxp:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2FViR {
  color: #fe0000;
}
.errorMessage___3-EPB {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3U9M5 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3B5s0 {
  display: none !important;
}
.m-auto___1gtRA {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2AggO {
  max-width: 120px !important;
}
.fc___v5LEY .fc-timegrid-col___3iclb.fc-day-today___3fPr6 {
  background-color: #ffffff;
}
.fc___v5LEY .fc-timegrid-slot___2dL8I {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___v5LEY .fc-timegrid-slot___2dL8I {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1qG9a .fc-direction-ltr___2XSQt .fc-timegrid-slot-label-frame___1Xc8b {
    font-size: 10px;
  }
  .bookingCalendar___1qG9a .fc___v5LEY .fc-timegrid-slot-label-cushion___24qM- {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1qG9a .fc___v5LEY .fc-scrollgrid-section-header___1YhxG .fc-scroller___1a1RN {
    overflow: unset !important;
  }
}
.ant-message-notice___3nQ6S {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2kU_N {
  width: 20px;
  height: 20px;
}
.bgTransparent___3O5Kx {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___G9Qqr {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1uePj {
  position: relative;
}
.inputCustomValue___1uePj .ant-input___j89Dv {
  padding-right: 50px;
}
.inputCustomValueText___2AGzy {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1QxEm .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1QxEm .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2UlUg .ant-radio-inner___2qxky {
  border-color: black !important ;
}
.ant-radio-checked___2UlUg .ant-radio-inner___2qxky:after {
  background-color: black;
}
.ant-radio___1znXM:hover .ant-radio-inner___2qxky {
  border-color: black;
}
.ant-radio-checked___2UlUg .ant-radio-inner___2qxky:focus {
  border-color: black;
}
.modal___IigjN {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___IigjN .close___2GW7a {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___IigjN .header___FLdEY {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___IigjN .header___FLdEY p {
  margin: 0;
}
.modal___IigjN .content___2FmJK {
  text-align: center;
  font-size: 14px;
}
.modal___IigjN .content___2FmJK p {
  margin: 0;
}
.modal___IigjN .actions___1DAd4 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___IigjN .actions___1DAd4 .button____L05x {
  margin-top: 10px;
}
.modal___IigjN .actions___1DAd4:hover {
  color: #33c3c7;
}
.fc___v5LEY .fc-timegrid-slot-minor___1CDsf {
  border-top-style: none;
}
.fc___v5LEY .fc-timegrid-slot___2dL8I {
  height: 1.5em !important;
}
.messageError___3DeVO .ant-message-custom-content___3LnQr {
  display: flex;
  text-align: start;
}
.messageError___3DeVO .ant-message-custom-content___3LnQr span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2Q557 {
  background-color: #e6e4e4;
}
.notifyMessage___1QXxI .ant-message-notice-content___2_WY3 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1QXxI .ant-message-notice-content___2_WY3 .anticon___d1vdY {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1QXxI .ant-message-notice-content___2_WY3 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3nMJh {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3nMJh .ant-notification-notice-message___318sh,
.notifyCustomize___3nMJh .ant-notification-notice-close___T1wYp,
.notifyCustomize___3nMJh .ant-notification-notice-icon___3vTnx {
  color: white;
}
.notifyCustomize___3nMJh .ant-notification-notice-message___318sh {
  margin-left: 35px;
}
.notifyCustomize___3nMJh p {
  margin-bottom: 0;
}
.notifyCustomize___3nMJh p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2EzUw {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2EzUw .ant-notification-notice-message___318sh {
  margin-left: 35px;
}
.notifyAlertDuplicate___2EzUw p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2EzUw p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___878Ag {
  width: 434px !important;
}
.handleSubmitVote___878Ag .ant-btn-primary___3A3ZC {
  background: #59c3c7;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k {
  padding: 15px 30px 18px;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k .ant-modal-confirm-body___2RpV0 {
  display: flex !important;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k .ant-modal-confirm-body___2RpV0 .anticon___d1vdY {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k .ant-modal-confirm-btns___3qNe2 button {
  font-weight: bold;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k .ant-modal-confirm-btns___3qNe2 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k .ant-modal-confirm-btns___3qNe2 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___878Ag .ant-modal-body___ttW0k .ant-modal-confirm-btns___3qNe2 .ant-btn-primary___3A3ZC {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3uF-N {
  margin-bottom: 0;
}
.mt-0___2pbcz {
  margin-top: 0;
}
.tooltipFormat___2UgrC p {
  margin-bottom: 0;
}
.bgWhite___1zYPE {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___WZ6_C {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___WZ6_C:hover,
.bgPrimaryBlue___WZ6_C:focus,
.bgPrimaryBlue___WZ6_C:active {
  background: #4f7ac7;
}
.bgDarkBlue___24s_L {
  background-color: #023768 !important;
}
.bgDarkBlue___24s_L:hover,
.bgDarkBlue___24s_L:focus,
.bgDarkBlue___24s_L:active {
  background: #194d7d;
}
.bgGreyBlue___2oPaw {
  background-color: #ebf0f9;
}
.bgLightBlue___2igdJ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___Iy7C8 {
  background-color: #e4453a;
}
.bgLightRed___Iy7C8:hover,
.bgLightRed___Iy7C8:focus,
.bgLightRed___Iy7C8:active {
  background: #e17871;
}
.bgLightYellow___2ntqq {
  background-color: #ffd603;
}
.bgDarkGray___2Pwu4 {
  background-color: #333333;
}
.bgMediumGray___1wPM9 {
  background-color: #a2a2a2;
}
.bgLightGray___3Mc2w {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___22U7P {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___22PsZ {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3adEa {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3RKLc {
  color: #3368c7 !important;
}
.textDarkBlue___2tz9T {
  color: #023768 !important;
}
.textLightBlue___2Pvus {
  color: #b2cbf7;
}
.textDarkGray___3YzBu {
  color: #333333 !important;
}
.textMediumGray___1ehif {
  color: #a2a2a2 !important;
}
.textLightGray___12GjT {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1CvgL {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___32wLw {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1nEQI {
  border-radius: 5px !important;
}
.rounded-xs___3TSoI {
  border-radius: 10px !important;
}
.rounded-sm___1fMZ8 {
  border-radius: 15px !important;
}
.rounded-md___1iQqc {
  border-radius: 20px !important;
}
.rounded-lg___1uUNV {
  border-radius: 25px !important;
}
.rounded-full___xC7WL {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1bY_w {
  margin: 0;
}
.mt-2___10V1Q {
  margin-top: 0.5rem;
}
.my-2___3k-dQ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___BZjRa {
  padding: 0;
}
.px-1___3Gksd {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2cchz {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1NLh8 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2RMMF {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1hnkQ {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2WtrR {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2upvT {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1nSjS {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___gnd7h {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3KC28 {
  padding-bottom: 20px;
}
.h-full___2aGqz {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___16PdL {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3UKc2 {
  font-weight: bold !important;
}
.fontWeight400___3Td_k {
  font-weight: 400 !important;
}
.fontWeight500___3jzKL {
  font-weight: 500 !important;
}
.fontWeight600___3ystf {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1MEFv {
  font-size: 12px !important;
}
.fontSize14___nNi1S {
  font-size: 14px !important;
}
.fontSize16___2_xfc {
  font-size: 16px !important;
}
.fontSize18___2NE1_ {
  font-size: 18px !important;
}
.fontSize20___1kTxy {
  font-size: 20px !important;
}
.fontSize24___3QPm6 {
  font-size: 24px !important;
}
.eventName___3x57L {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1aTUN {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___UhH70 > tr > th,
.ant-table-tbody___z5Glg > tr.ant-table-row___2-lul > td,
.ant-table-summary___2HjYe tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___UhH70 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2HVqJ .ant-table-thead___UhH70 > tr > th,
.table-portrait___2HVqJ .ant-table-tbody___z5Glg > tr.ant-table-row___2-lul > td,
.table-portrait___2HVqJ .ant-table-summary___2HjYe tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2HVqJ .bgLightGray___3Mc2w td {
  background: #ebebeb;
}
.table-portrait___2HVqJ .bgLightBlue___2igdJ td {
  background: #b2cbf7;
}
.eventContent___16mvI .timeText___roTAA {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___z5Glg > tr.bgLightGray___3Mc2w:hover > td,
.ant-table-tbody___z5Glg > tr > td.ant-table-cell-row-hover___7j0WT {
  background: #ebebeb;
}
.ant-table-tbody___z5Glg > tr.bgLightBlue___2igdJ:hover > td,
.ant-table-tbody___z5Glg > tr > td.ant-table-cell-row-hover___7j0WT {
  background: #b2cbf7;
}
.ant-table-tbody___z5Glg > tr.bgWhite___1zYPE:hover > td,
.ant-table-tbody___z5Glg > tr > td.ant-table-cell-row-hover___7j0WT {
  background: #ffffff;
}
.profileContainer___1M5jL {
  max-width: 1080px;
  margin: auto;
  width: 100%;
}
.profileContainer___1M5jL .header___FLdEY {
  font-size: 24px;
  font-weight: bold;
}
.profileContainer___1M5jL .avtImage___11LJE {
  border-radius: 50%;
  border: 3px solid #33c3c7;
  display: inline-table;
}
.profileContainer___1M5jL .informationForm___Bc7V- {
  padding: 0 35px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .informationForm___Bc7V- {
    padding: 0;
  }
}
.profileContainer___1M5jL button {
  border-radius: 8px;
  height: 49px;
  padding: 4px 15px !important;
}
.profileContainer___1M5jL .groupBtn___7fF3i {
  display: flex;
  justify-content: center;
}
.profileContainer___1M5jL .groupBtn___7fF3i button {
  height: 49px;
  min-width: 130px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .groupBtn___7fF3i button {
    min-width: auto;
  }
}
.profileContainer___1M5jL .groupBtn___7fF3i button:first-child {
  margin-right: 30px !important;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .groupBtn___7fF3i button:first-child {
    margin-right: 15px !important;
  }
}
.profileContainer___1M5jL .headTitle___36DTb {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .headTitle___36DTb {
    margin-bottom: 20px;
  }
}
.profileContainer___1M5jL .headTitle___36DTb .bolderIcon___2Th4X {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.profileContainer___1M5jL .headTitle___36DTb .titleIcon___1ZMlq {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.profileContainer___1M5jL .headTitle___36DTb span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .headTitle___36DTb span {
    font-size: 20px;
  }
}
.profileContainer___1M5jL .ant-avatar img {
  object-fit: fill;
}
.profileContainer___1M5jL .ant-avatar.ant-avatar-circle {
  border: 0;
}
.profileContainer___1M5jL input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
}
.profileContainer___1M5jL label {
  font-size: 16px;
  font-weight: 600;
}
.profileContainer___1M5jL button {
  border-radius: 8px;
}
.profileContainer___1M5jL .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}
.profileContainer___1M5jL .btn.btn-custom-height {
  font-size: 16px;
}
.profileContainer___1M5jL .avatarRow___2kTt- .avatarButton___3oUC- {
  margin-left: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .avatarRow___2kTt- .avatarButton___3oUC- {
    margin-left: 25px;
  }
}
.profileContainer___1M5jL .urlRow___1cM9u .ant-input-group-addon {
  background: none;
  border: none;
  padding-left: 0;
  font-size: 16px;
}
.profileContainer___1M5jL .urlRow___1cM9u .ant-input {
  border-radius: 8px;
}
.profileContainer___1M5jL .wrapperAvatar___1tUue {
  width: 136px;
  height: 136px;
}
.profileContainer___1M5jL .wrapperAvatar___1tUue img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profileContainer___1M5jL .btnUpdate___2QKuB {
  margin-bottom: 0;
}
.profileContainer___1M5jL .btnUpdate___2QKuB button {
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 20px;
  height: 49px;
  width: 209px;
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .btnUpdate___2QKuB button {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.profileContainer___1M5jL .groupPart___2QNGg {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .groupPart___2QNGg {
    margin-bottom: 50px;
  }
}
.profileContainer___1M5jL .groupPart___2QNGg .partName___25PuY {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .groupPart___2QNGg .partName___25PuY {
    margin-bottom: 30px;
  }
}
.profileContainer___1M5jL .groupPart___2QNGg .disconnectBtn___1q-kI:not(:disabled) {
  color: #ffffff;
  background-color: #b6b6b6;
}
.profileContainer___1M5jL .groupPart___2QNGg .disconnectBtn___1q-kI:not(:disabled):hover {
  color: #ffffff;
  background-color: #b6b6b6;
  opacity: 0.8;
}
.profileContainer___1M5jL .groupPart___2QNGg button {
  border-radius: 20px;
  margin-left: 90px;
  font-size: 20px;
  max-width: 340px;
  width: 340px;
  height: 49px;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .groupPart___2QNGg button {
    margin-left: 0;
    font-size: 18px;
  }
}
.profileContainer___1M5jL .groupPart___2QNGg .partNameTitle___2aj5- {
  font-size: 30px;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .profileContainer___1M5jL .groupPart___2QNGg .partNameTitle___2aj5- {
    font-size: 18px;
  }
}
.profileContainer___1M5jL .groupPart___2QNGg .partNameBorder___Fa0MA {
  display: inline-block;
  width: 9px;
  height: 30px;
  background: #3c3c3c;
  margin-right: 10px;
}
.profileContainer___1M5jL .backToHome___3vVfq button {
  border-radius: 20px;
  height: 49px;
  font-size: 20px;
  width: 209px;
}
