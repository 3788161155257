.sidebarContainer___10A3u .sidebarItem___Y56U6 {
  border: none;
  background-color: transparent;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6.notWeight___1RC1N .ant-collapse-header-text {
  font-weight: 400 !important;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-drawer {
  z-index: 200 !important;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item {
  border: none;
  background-color: transparent;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-header,
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-content {
  padding: 0 0 10px 0;
  border: none;
  background-color: transparent;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon .anticon-right,
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-content .ant-collapse-expand-icon .anticon-right {
  top: 40%;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-header .ant-collapse-header-text,
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-content .ant-collapse-header-text {
  font-weight: 600;
  color: #263240;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-header .info,
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-content .info {
  display: flex;
  align-items: center;
  font-weight: 700;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-header .info img,
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-content .info img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: #7f7e7e;
  border-radius: 100%;
}
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-header .ant-collapse-content-box,
.sidebarContainer___10A3u .sidebarItem___Y56U6 .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  height: 100%;
  overflow-y: hidden;
}
.sidebarContainer___10A3u .addCalendarBtn___3APCs {
  color: #263240;
  font-weight: 500;
  margin-top: 10px;
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 5px 0;
}
.sidebarContainer___10A3u .listCheckBoxTeamMember___2W8ky {
  display: flex;
  overflow-wrap: anywhere;
}
.sidebarContainer___10A3u .listCheckBoxTeamMember___2W8ky .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--background-color);
  border-color: var(--border-color);
}
