@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1eHpa {
  display: flex;
  justify-content: center;
}
button.btn___wGndU {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___wGndU {
    padding: 10px;
  }
}
button.btn___wGndU:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___wGndU:focus {
  outline: none;
}
.btnWhite___1lLl0.btn___wGndU {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1lLl0.btn___wGndU:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2REM6.btn___wGndU,
.btnGreen___2REM6.btn___wGndU:hover,
.btnGreen___2REM6.btn___wGndU:active,
.btnGreen___2REM6.btn___wGndU:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1NDmb {
  color: #fe0000;
}
.errorMessage___3zx4G {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1BCg5 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___39Jhn {
  display: none !important;
}
.m-auto___Ly7Bx {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1TUKf {
  max-width: 120px !important;
}
.fc___15TW2 .fc-timegrid-col___3Z82O.fc-day-today___Vf-Kb {
  background-color: #ffffff;
}
.fc___15TW2 .fc-timegrid-slot___2gzSs {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___15TW2 .fc-timegrid-slot___2gzSs {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___phJe3 .fc-direction-ltr___7Gi7h .fc-timegrid-slot-label-frame___1cqAb {
    font-size: 10px;
  }
  .bookingCalendar___phJe3 .fc___15TW2 .fc-timegrid-slot-label-cushion___3_cPe {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___phJe3 .fc___15TW2 .fc-scrollgrid-section-header___70jYi .fc-scroller___faQ65 {
    overflow: unset !important;
  }
}
.ant-message-notice___C7-7D {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1K23x {
  width: 20px;
  height: 20px;
}
.bgTransparent___3jVRl {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___cw_xv {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1myzN {
  position: relative;
}
.inputCustomValue___1myzN .ant-input___3qKBd {
  padding-right: 50px;
}
.inputCustomValueText___XmhE0 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2OqOt .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2OqOt .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2-jai .ant-radio-inner___3i5Iv {
  border-color: black !important ;
}
.ant-radio-checked___2-jai .ant-radio-inner___3i5Iv:after {
  background-color: black;
}
.ant-radio___3DjBk:hover .ant-radio-inner___3i5Iv {
  border-color: black;
}
.ant-radio-checked___2-jai .ant-radio-inner___3i5Iv:focus {
  border-color: black;
}
.modal___2eQ8W {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2eQ8W .close___1lVg4 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2eQ8W .header___xj7v4 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2eQ8W .header___xj7v4 p {
  margin: 0;
}
.modal___2eQ8W .content___2LwuH {
  text-align: center;
  font-size: 14px;
}
.modal___2eQ8W .content___2LwuH p {
  margin: 0;
}
.modal___2eQ8W .actions___aWySO {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2eQ8W .actions___aWySO .button___by6M1 {
  margin-top: 10px;
}
.modal___2eQ8W .actions___aWySO:hover {
  color: #33c3c7;
}
.fc___15TW2 .fc-timegrid-slot-minor___27E67 {
  border-top-style: none;
}
.fc___15TW2 .fc-timegrid-slot___2gzSs {
  height: 1.5em !important;
}
.messageError___1S_GY .ant-message-custom-content___1Sd13 {
  display: flex;
  text-align: start;
}
.messageError___1S_GY .ant-message-custom-content___1Sd13 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1D4OP {
  background-color: #e6e4e4;
}
.notifyMessage___2uAOK .ant-message-notice-content___19F_Y {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2uAOK .ant-message-notice-content___19F_Y .anticon___EIgkh {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2uAOK .ant-message-notice-content___19F_Y span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1DYMT {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1DYMT .ant-notification-notice-message___3_A-T,
.notifyCustomize___1DYMT .ant-notification-notice-close___2bffb,
.notifyCustomize___1DYMT .ant-notification-notice-icon___2dm32 {
  color: white;
}
.notifyCustomize___1DYMT .ant-notification-notice-message___3_A-T {
  margin-left: 35px;
}
.notifyCustomize___1DYMT p {
  margin-bottom: 0;
}
.notifyCustomize___1DYMT p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___4fIMZ {
  padding: 16px 14px;
}
.notifyAlertDuplicate___4fIMZ .ant-notification-notice-message___3_A-T {
  margin-left: 35px;
}
.notifyAlertDuplicate___4fIMZ p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___4fIMZ p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3-qKB {
  width: 434px !important;
}
.handleSubmitVote___3-qKB .ant-btn-primary___8lfCj {
  background: #59c3c7;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs .ant-modal-confirm-body___ScdNo {
  display: flex !important;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs .ant-modal-confirm-body___ScdNo .anticon___EIgkh {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs .ant-modal-confirm-btns___fZvkG button {
  font-weight: bold;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs .ant-modal-confirm-btns___fZvkG button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs .ant-modal-confirm-btns___fZvkG button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3-qKB .ant-modal-body___nZxDs .ant-modal-confirm-btns___fZvkG .ant-btn-primary___8lfCj {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3jDSo {
  margin-bottom: 0;
}
.mt-0___2Cjqx {
  margin-top: 0;
}
.tooltipFormat___1AVF9 p {
  margin-bottom: 0;
}
.bgWhite___1Ttz1 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___upps5 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___upps5:hover,
.bgPrimaryBlue___upps5:focus,
.bgPrimaryBlue___upps5:active {
  background: #4f7ac7;
}
.bgDarkBlue___1XmpJ {
  background-color: #023768 !important;
}
.bgDarkBlue___1XmpJ:hover,
.bgDarkBlue___1XmpJ:focus,
.bgDarkBlue___1XmpJ:active {
  background: #194d7d;
}
.bgGreyBlue___2u-Sx {
  background-color: #ebf0f9;
}
.bgLightBlue___2Mbd5 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2VS-e {
  background-color: #e4453a;
}
.bgLightRed___2VS-e:hover,
.bgLightRed___2VS-e:focus,
.bgLightRed___2VS-e:active {
  background: #e17871;
}
.bgLightYellow___1_UL4 {
  background-color: #ffd603;
}
.bgDarkGray___1Ms0m {
  background-color: #333333;
}
.bgMediumGray___OuufU {
  background-color: #a2a2a2;
}
.bgLightGray___3bDlh {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___O54kM {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___UJE-G {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___vLHr5 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___8jqQ7 {
  color: #3368c7 !important;
}
.textDarkBlue___2gsHz {
  color: #023768 !important;
}
.textLightBlue___2IV-v {
  color: #b2cbf7;
}
.textDarkGray___ikxUf {
  color: #333333 !important;
}
.textMediumGray___1alOc {
  color: #a2a2a2 !important;
}
.textLightGray___7P6n8 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3drAd {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2nueQ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1iyP4 {
  border-radius: 5px !important;
}
.rounded-xs___3NFPz {
  border-radius: 10px !important;
}
.rounded-sm___2CL9m {
  border-radius: 15px !important;
}
.rounded-md___1Cmh5 {
  border-radius: 20px !important;
}
.rounded-lg___13ajg {
  border-radius: 25px !important;
}
.rounded-full___1OyIY {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1en5I {
  margin: 0;
}
.mt-2___3gQEb {
  margin-top: 0.5rem;
}
.my-2___2EW5k {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___28ebP {
  padding: 0;
}
.px-1___v4NDG {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3DyBX {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___pkIB- {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___sqzEi {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2_jwh {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3TzhZ {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3konJ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3____24FO {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___JUJNr {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1T9Jp {
  padding-bottom: 20px;
}
.h-full___PCk_a {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2qiUY {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___njkVN {
  font-weight: bold !important;
}
.fontWeight400___1mz7H {
  font-weight: 400 !important;
}
.fontWeight500___2HTM4 {
  font-weight: 500 !important;
}
.fontWeight600___1sxL1 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2_YfN {
  font-size: 12px !important;
}
.fontSize14___MrBTY {
  font-size: 14px !important;
}
.fontSize16___d0mZw {
  font-size: 16px !important;
}
.fontSize18___3I3f1 {
  font-size: 18px !important;
}
.fontSize20___2aN7Y {
  font-size: 20px !important;
}
.fontSize24___1hgle {
  font-size: 24px !important;
}
.eventName___14QG4 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1KAEQ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2u1Wi > tr > th,
.ant-table-tbody___3X2Qo > tr.ant-table-row___2n23L > td,
.ant-table-summary___gPZV7 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2u1Wi > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3ywAE .ant-table-thead___2u1Wi > tr > th,
.table-portrait___3ywAE .ant-table-tbody___3X2Qo > tr.ant-table-row___2n23L > td,
.table-portrait___3ywAE .ant-table-summary___gPZV7 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3ywAE .bgLightGray___3bDlh td {
  background: #ebebeb;
}
.table-portrait___3ywAE .bgLightBlue___2Mbd5 td {
  background: #b2cbf7;
}
.eventContent___3WXff .timeText___29Hm7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3X2Qo > tr.bgLightGray___3bDlh:hover > td,
.ant-table-tbody___3X2Qo > tr > td.ant-table-cell-row-hover___1vToT {
  background: #ebebeb;
}
.ant-table-tbody___3X2Qo > tr.bgLightBlue___2Mbd5:hover > td,
.ant-table-tbody___3X2Qo > tr > td.ant-table-cell-row-hover___1vToT {
  background: #b2cbf7;
}
.ant-table-tbody___3X2Qo > tr.bgWhite___1Ttz1:hover > td,
.ant-table-tbody___3X2Qo > tr > td.ant-table-cell-row-hover___1vToT {
  background: #ffffff;
}
.setting___rOZ15 {
  margin: 0 0 60px;
  padding: 0px 0 40px;
}
@media screen and (max-width: 767px) {
  .setting___rOZ15 {
    padding: 0;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .setting___rOZ15 {
    padding: 60px 15px 80px;
  }
}
.setting___rOZ15 .partName___3T06W {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .setting___rOZ15 .partName___3T06W {
    margin-bottom: 30px;
  }
}
.setting___rOZ15 .partNameTitle___1Lu3P {
  font-size: 30px;
  color: #3c3c3c;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .setting___rOZ15 .partNameTitle___1Lu3P {
    font-size: 18px;
  }
}
.setting___rOZ15 .partNameBorder___2E03f {
  display: inline-block;
  width: 9px;
  height: 30px;
  background: dodgerblue;
  margin-right: 10px;
}
.setting___rOZ15 .settingBtnGroup___1l1VN {
  margin-bottom: 50px;
  margin-top: 25px;
}
.setting___rOZ15 .settingBtnGroup___1l1VN button {
  border-radius: 20px;
  margin-left: 0;
  font-size: 20px;
  height: 49px;
}
.setting___rOZ15 .settingBtnGroup___1l1VN .cancelBtn___GZkrf {
  color: #ffffff;
  background-color: #b6b6b6;
}
.setting___rOZ15 .settingBtnGroup___1l1VN .cancelBtn___GZkrf:hover {
  color: #ffffff;
  background-color: #b6b6b6;
  opacity: 0.8;
}
.setting___rOZ15 .settingIcon___3Zn8Z {
  font-size: 23px;
  margin: 0 24px;
}
@media screen and (max-width: 767px) {
  .setting___rOZ15 .settingIcon___3Zn8Z {
    margin: 0 5px;
  }
}
.setting___rOZ15 .labelCheckbox___2GBvT {
  font-size: 16px;
}
.labelCheckbox___2GBvT {
  margin-left: 30px;
  font-size: 25px;
  display: flex;
  align-items: center;
}
.checkboxSelect___1Kkjl {
  display: flex;
  margin-left: 60px;
  align-items: center;
  min-width: 50%;
}
@media screen and (max-width: 767px) {
  .checkboxSelect___1Kkjl {
    margin-left: 0;
  }
}
.selectTime___IDcPH {
  width: 100%;
  max-width: 170px;
  height: 50px;
  border: 2px solid #d4d4d4;
  border-radius: 15px;
  padding: 0 28px;
  font-size: 20px;
}
.checkboxCustom___1ksn6 {
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .checkboxCustom___1ksn6 {
    margin-bottom: 0;
  }
}
.checkboxCustom___1ksn6 .checkboxContent___1A9FA {
  display: flex;
  width: 100%;
}
.checkboxItem___3DTzy {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 615px;
}
@media screen and (max-width: 767px) {
  .checkboxItem___3DTzy {
    justify-content: space-between;
  }
}
.checkboxItem___3DTzy .errorTime___HMZRc {
  color: #fe0000;
  display: block;
  width: 100%;
  margin-left: 20px;
}
.timeBlock___2_HD2 {
  position: relative;
  display: flex;
  justify-content: center;
}
.setting___rOZ15 .ant-checkbox-input,
.setting___rOZ15 .ant-checkbox-inner {
  display: none;
}
.setting___rOZ15 .ant-picker .ant-picker-input .ant-picker-suffix {
  display: unset;
}
.setting___rOZ15 .ant-checkbox-checked::after {
  display: none;
}
.setting___rOZ15 .ant-checkbox::before {
  content: '';
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: -15px;
  border-radius: 4px;
  box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.26);
}
.setting___rOZ15 .ant-checkbox.ant-checkbox-checked::before {
  background: url(./static/icon-checked.3d17b49b.svg) center no-repeat;
  background-size: 70% 70%;
}
.setting___rOZ15 .ant-checkbox-group {
  width: 100%;
}
.setting___rOZ15 .ant-select {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 30px;
  border-radius: 15px;
  font-size: 20px;
  display: flex;
}
.setting___rOZ15 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  height: auto;
  padding: 0px 28px;
  background: transparent;
  box-shadow: none;
}
.setting___rOZ15 .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}
.setting___rOZ15 .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.setting___rOZ15 .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
.setting___rOZ15 .ant-picker {
  height: 30px;
  border: 2px solid #d4d4d4;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #333;
  padding: 0 10px 0 28px;
}
@media screen and (max-width: 767px) {
  .setting___rOZ15 .ant-picker {
    max-width: 95px;
    padding: 0 10px;
    height: 30px;
  }
}
.setting___rOZ15 .ant-picker-input > input {
  font-size: 16px;
}
.setting___rOZ15 .btn.btnWhite:hover {
  border: 2px solid #909090;
}
@media screen and (max-width: 767px) {
  .timePicker___3TQMO .ant-picker-time-panel-column {
    float: left;
    height: 110%;
    overflow-y: auto;
  }
  .timePicker___3TQMO .ant-picker-time-panel-column::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .timePicker___3TQMO .ant-picker-time-panel-column::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
  }
  .timePicker___3TQMO .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }
}
.timePicker___3TQMO .ant-picker-panel .ant-picker-footer {
  display: none;
}
.timePicker___3TQMO .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.timePicker___3TQMO .ant-picker-time-panel-column {
  width: 80px;
}
.timePicker___3TQMO .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___3TQMO .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell::after {
  content: '時';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
.timePicker___3TQMO .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___3TQMO .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell::after {
  content: '分';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
