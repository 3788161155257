@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1LOBd {
  display: flex;
  justify-content: center;
}
button.btn___fVHHa {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___fVHHa {
    padding: 10px;
  }
}
button.btn___fVHHa:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___fVHHa:focus {
  outline: none;
}
.btnWhite___2Ub1N.btn___fVHHa {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2Ub1N.btn___fVHHa:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2Sn1l.btn___fVHHa,
.btnGreen___2Sn1l.btn___fVHHa:hover,
.btnGreen___2Sn1l.btn___fVHHa:active,
.btnGreen___2Sn1l.btn___fVHHa:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___w2T2W {
  color: #fe0000;
}
.errorMessage___1vyMh {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1x-86 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3EP7S {
  display: none !important;
}
.m-auto___3o1Vu {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___35NAn {
  max-width: 120px !important;
}
.fc___6DRlQ .fc-timegrid-col___2IuQE.fc-day-today___2HkWr {
  background-color: #ffffff;
}
.fc___6DRlQ .fc-timegrid-slot___3aI-S {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___6DRlQ .fc-timegrid-slot___3aI-S {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3YoJJ .fc-direction-ltr___1CPJw .fc-timegrid-slot-label-frame___14JPD {
    font-size: 10px;
  }
  .bookingCalendar___3YoJJ .fc___6DRlQ .fc-timegrid-slot-label-cushion___1KzK5 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3YoJJ .fc___6DRlQ .fc-scrollgrid-section-header___1Q5R3 .fc-scroller___1eK7h {
    overflow: unset !important;
  }
}
.ant-message-notice___1_T-Q {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3kyb- {
  width: 20px;
  height: 20px;
}
.bgTransparent___ZQAeL {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3hmTb {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2Uy6p {
  position: relative;
}
.inputCustomValue___2Uy6p .ant-input___2w6_W {
  padding-right: 50px;
}
.inputCustomValueText___6aQEo {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___YlR7t .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___YlR7t .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___35bDj .ant-radio-inner___25cO_ {
  border-color: black !important ;
}
.ant-radio-checked___35bDj .ant-radio-inner___25cO_:after {
  background-color: black;
}
.ant-radio___Bkfy7:hover .ant-radio-inner___25cO_ {
  border-color: black;
}
.ant-radio-checked___35bDj .ant-radio-inner___25cO_:focus {
  border-color: black;
}
.modal___1D_fC {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1D_fC .close___1u3cw {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1D_fC .header___1RCZj {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1D_fC .header___1RCZj p {
  margin: 0;
}
.modal___1D_fC .content___3Y6iI {
  text-align: center;
  font-size: 14px;
}
.modal___1D_fC .content___3Y6iI p {
  margin: 0;
}
.modal___1D_fC .actions___awf2U {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1D_fC .actions___awf2U .button___23iQd {
  margin-top: 10px;
}
.modal___1D_fC .actions___awf2U:hover {
  color: #33c3c7;
}
.fc___6DRlQ .fc-timegrid-slot-minor___1juk4 {
  border-top-style: none;
}
.fc___6DRlQ .fc-timegrid-slot___3aI-S {
  height: 1.5em !important;
}
.messageError___OFQ4K .ant-message-custom-content___b887F {
  display: flex;
  text-align: start;
}
.messageError___OFQ4K .ant-message-custom-content___b887F span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2cgF5 {
  background-color: #e6e4e4;
}
.notifyMessage___2yRUa .ant-message-notice-content___2o6XD {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2yRUa .ant-message-notice-content___2o6XD .anticon___3crHE {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2yRUa .ant-message-notice-content___2o6XD span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2CEYg {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2CEYg .ant-notification-notice-message___2LP8h,
.notifyCustomize___2CEYg .ant-notification-notice-close___2uYKT,
.notifyCustomize___2CEYg .ant-notification-notice-icon___2yL6u {
  color: white;
}
.notifyCustomize___2CEYg .ant-notification-notice-message___2LP8h {
  margin-left: 35px;
}
.notifyCustomize___2CEYg p {
  margin-bottom: 0;
}
.notifyCustomize___2CEYg p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___31d4p {
  padding: 16px 14px;
}
.notifyAlertDuplicate___31d4p .ant-notification-notice-message___2LP8h {
  margin-left: 35px;
}
.notifyAlertDuplicate___31d4p p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___31d4p p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___31G9w {
  width: 434px !important;
}
.handleSubmitVote___31G9w .ant-btn-primary___26TPI {
  background: #59c3c7;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 .ant-modal-confirm-body___15Hx_ {
  display: flex !important;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 .ant-modal-confirm-body___15Hx_ .anticon___3crHE {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 .ant-modal-confirm-btns___2YYdm button {
  font-weight: bold;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 .ant-modal-confirm-btns___2YYdm button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 .ant-modal-confirm-btns___2YYdm button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___31G9w .ant-modal-body___19hd7 .ant-modal-confirm-btns___2YYdm .ant-btn-primary___26TPI {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1MvOK {
  margin-bottom: 0;
}
.mt-0___2HIzV {
  margin-top: 0;
}
.tooltipFormat___5DHOe p {
  margin-bottom: 0;
}
.bgWhite___clEsj {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2avM2 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2avM2:hover,
.bgPrimaryBlue___2avM2:focus,
.bgPrimaryBlue___2avM2:active {
  background: #4f7ac7;
}
.bgDarkBlue___yQw5M {
  background-color: #023768 !important;
}
.bgDarkBlue___yQw5M:hover,
.bgDarkBlue___yQw5M:focus,
.bgDarkBlue___yQw5M:active {
  background: #194d7d;
}
.bgGreyBlue___3Qvur {
  background-color: #ebf0f9;
}
.bgLightBlue___2DtOO {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3DlPC {
  background-color: #e4453a;
}
.bgLightRed___3DlPC:hover,
.bgLightRed___3DlPC:focus,
.bgLightRed___3DlPC:active {
  background: #e17871;
}
.bgLightYellow___1lsYE {
  background-color: #ffd603;
}
.bgDarkGray___242fJ {
  background-color: #333333;
}
.bgMediumGray___1V__T {
  background-color: #a2a2a2;
}
.bgLightGray___36yL8 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3MrsV {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3MQ-k {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2LMpR {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1_BQO {
  color: #3368c7 !important;
}
.textDarkBlue___1_Dze {
  color: #023768 !important;
}
.textLightBlue___diSV8 {
  color: #b2cbf7;
}
.textDarkGray___1zt8F {
  color: #333333 !important;
}
.textMediumGray___18COo {
  color: #a2a2a2 !important;
}
.textLightGray___HP9_j {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1FFwz {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2e9Rz {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1GjuC {
  border-radius: 5px !important;
}
.rounded-xs___25eQ_ {
  border-radius: 10px !important;
}
.rounded-sm___1tV7v {
  border-radius: 15px !important;
}
.rounded-md___1tV4- {
  border-radius: 20px !important;
}
.rounded-lg___1esL8 {
  border-radius: 25px !important;
}
.rounded-full___1bJ-n {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___DKEn4 {
  margin: 0;
}
.mt-2___1wP6X {
  margin-top: 0.5rem;
}
.my-2___1NPIT {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___18viE {
  padding: 0;
}
.px-1___34xn- {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___GsA_b {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___uyHDv {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___TdQZG {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1kh-U {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___GxZiF {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3lgq4 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3J8dH {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___26no7 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3p8Kq {
  padding-bottom: 20px;
}
.h-full___2TzfM {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___rEKy2 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2wbBS {
  font-weight: bold !important;
}
.fontWeight400___6kZSL {
  font-weight: 400 !important;
}
.fontWeight500___xbCgB {
  font-weight: 500 !important;
}
.fontWeight600___3tmj4 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3WhoH {
  font-size: 12px !important;
}
.fontSize14___16Po6 {
  font-size: 14px !important;
}
.fontSize16___3wHD1 {
  font-size: 16px !important;
}
.fontSize18___3SeHJ {
  font-size: 18px !important;
}
.fontSize20___CVACN {
  font-size: 20px !important;
}
.fontSize24___E9uAu {
  font-size: 24px !important;
}
.eventName___214uJ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3j8c5 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1F8p- > tr > th,
.ant-table-tbody___30_1M > tr.ant-table-row___2GNZ1 > td,
.ant-table-summary___2TdNx tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1F8p- > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___b5cbK .ant-table-thead___1F8p- > tr > th,
.table-portrait___b5cbK .ant-table-tbody___30_1M > tr.ant-table-row___2GNZ1 > td,
.table-portrait___b5cbK .ant-table-summary___2TdNx tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___b5cbK .bgLightGray___36yL8 td {
  background: #ebebeb;
}
.table-portrait___b5cbK .bgLightBlue___2DtOO td {
  background: #b2cbf7;
}
.eventContent___aE1TI .timeText___2NDiI {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___30_1M > tr.bgLightGray___36yL8:hover > td,
.ant-table-tbody___30_1M > tr > td.ant-table-cell-row-hover___fh5lD {
  background: #ebebeb;
}
.ant-table-tbody___30_1M > tr.bgLightBlue___2DtOO:hover > td,
.ant-table-tbody___30_1M > tr > td.ant-table-cell-row-hover___fh5lD {
  background: #b2cbf7;
}
.ant-table-tbody___30_1M > tr.bgWhite___clEsj:hover > td,
.ant-table-tbody___30_1M > tr > td.ant-table-cell-row-hover___fh5lD {
  background: #ffffff;
}
.mainLayout___1Wou3 {
  background-color: #fefefe;
}
.mainLayout___1Wou3 .mainContent___1beX9 {
  width: 100%;
  margin: auto;
  max-width: 1080px;
}
.scheduleAdjustment___2uhAq .inforTimeSelect___1_rrd {
  display: none;
  padding: 0.8rem 0;
  background: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  margin-bottom: 1rem;
}
.scheduleAdjustment___2uhAq .inforTimeSelect___1_rrd .titleTime___2pbBk {
  display: flex;
  width: 95%;
  margin: auto;
}
.scheduleAdjustment___2uhAq .inforTimeSelect___1_rrd .titleTime___2pbBk .boldLeft___1Mh7H {
  width: 6px;
  height: 18px;
  background: #3c3c3c;
  margin: auto 5px auto 0;
}
.scheduleAdjustment___2uhAq .inforTimeSelect___1_rrd .titleTime___2pbBk label {
  color: #3c3c3c;
  font-size: 18px;
}
.scheduleAdjustment___2uhAq .inforTimeSelect___1_rrd .rightTime___39Val {
  width: 95%;
  margin: auto;
  font-size: 1rem;
  padding-left: 13px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C {
  border-radius: 6px;
  padding: 35px 0 10px 0;
  background: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx {
  width: 70%;
  margin: auto;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label {
  color: #3c3c3c;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv {
  display: flex;
  justify-items: center;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:first-child {
  height: 17px;
  margin: auto 8px auto 0;
  width: 6px;
  background: #3c3c3c;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:first-child.itemPlus___2pT0G {
  height: 6px;
  width: 17px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child .formLabel___3QW2M {
  font-size: 20px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child .required___w2T2W {
  margin-left: 20px;
  font-size: 17px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child .guestFormLabel___1akVw {
  font-size: 16px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child .guestFormLabel___1akVw .random___1x-86 {
  font-size: 15px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx .addContactDescription___KYLUH {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: -8px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .scheduleCollapse___1Q5xF label::before {
  top: 0;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .addContactForm___2P2a2 label {
  font-size: 16px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .addContactForm___2P2a2 .textArea___3t2Sg {
  resize: none;
  height: 100px;
  position: relative;
  overflow-x: auto;
  border: 2px solid #414141 !important;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .addContactForm___2P2a2 .textArea___3t2Sg:hover,
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .addContactForm___2P2a2 .textArea___3t2Sg:visited {
  border-color: #33c3c7 !important;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .inputSchedule___2HmVi {
  height: 42px;
  border-radius: 10px;
  border: 2px solid #414141 !important;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .inputSchedule___2HmVi:hover,
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .inputSchedule___2HmVi:visited {
  border-color: #33c3c7 !important;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .mb15___I38Qs {
  margin-bottom: 15px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .rightTime___39Val {
  font-size: 16px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .dateTime___1Q8Zi {
  margin-right: 20px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .labelRight___3zAC0 {
  margin-bottom: 20px;
}
.scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .fs18___NQGlk {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2uhAq .inforTimeSelect___1_rrd {
    display: block;
  }
  .scheduleAdjustment___2uhAq .mt30___2SWM8 {
    margin-top: 30px;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx {
    width: 95%;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .scheduleLabelCustomMobileEmail___e1snB {
    margin-bottom: 15px;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .rightTime___39Val {
    font-size: 16px !important;
    margin: 15px 0 !important;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .labelRight___3zAC0 {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child {
    font-size: 18px;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child .formLabel___3QW2M {
    font-size: 18px;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx label .formInputItem___1-cUv div:last-child .required___w2T2W {
    font-size: 17px;
  }
  .scheduleAdjustment___2uhAq .thirdStepDetail___3p50C .formScheduleAdjust___2cUqx .addContactDescription___KYLUH {
    font-size: 14px;
  }
}
.addContact___3RXDI {
  background: #fafafa;
  color: #33c3c7;
  font-size: 17px;
  border: 0 solid #fafafa !important;
  padding: 0;
  font-weight: 700;
  box-shadow: none;
  margin-bottom: 24px;
  margin-top: 2px;
  outline: none;
  transition: all 0s;
  cursor: pointer;
}
.addContact___3RXDI:hover {
  color: #33c3c7 !important;
  background: #fafafa !important;
  border-color: #fafafa !important;
}
@media screen and (max-width: 767px) {
  .addContact___3RXDI {
    margin-bottom: 12px;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.step3___2HrHc {
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .step3___2HrHc {
    padding-bottom: 0;
  }
}
.step3___2HrHc .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 9px 12px;
  background-color: #eaeaea;
  border: 0;
  border-radius: 0;
}
.step3___2HrHc .ant-collapse {
  border: 0;
}
.step3___2HrHc .ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.step3___2HrHc .ant-collapse-content {
  background: #fcfcfc;
  border: 2px solid #f5f5f5;
}
.scheduleBtnGroup___yImX4 {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 30px auto;
}
.scheduleBtnGroup___yImX4 button {
  min-width: 150px;
  height: 42px;
  border-radius: 15px;
  border: none;
  box-shadow: 1px 1px 10px #b3bcbc60;
  font-weight: bold;
}
.scheduleBtnGroup___yImX4 .btnWhite___2Ub1N {
  margin-right: 42px;
  color: #3c3c3c;
}
.scheduleBtnGroup___yImX4 .btnGreen___2Sn1l {
  background-color: #00b6d3;
  color: #ffffff;
}
@media screen and (max-width: 425px) {
  .scheduleBtnGroup___yImX4 {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    width: 100%;
    background: white;
    position: fixed;
    z-index: 230;
    padding: 16px 24px;
    margin: 0;
    bottom: 0;
  }
  .scheduleBtnGroup___yImX4 button {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 5px;
    border: none;
    min-width: 100px;
  }
  .scheduleBtnGroup___yImX4 .btnWhite___2Ub1N {
    margin-right: 16px;
  }
  .scheduleBtnGroup___yImX4 .btnGreen___2Sn1l {
    width: 85%;
  }
}
