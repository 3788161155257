.confirmTemplate___1g5VR {
  width: calc(100% - 450px);
  background: #fff;
  display: flex;
  box-shadow: #00000026 0 7px 29px 0;
  justify-content: space-between;
}
.confirmTemplate___1g5VR .confirmSetting___2WEaR {
  width: 50%;
  padding: 54px 1.2rem 0 1.2rem;
  position: relative;
}
.confirmTemplate___1g5VR .confirmSetting___2WEaR .inforSettingItem___1H7tl {
  margin-bottom: 2rem !important;
  cursor: pointer;
}
.confirmTemplate___1g5VR .confirmSetting___2WEaR .blur___5TKoe {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: #e3e9ed;
  opacity: 0.6;
  z-index: 2;
}
.confirmTemplate___1g5VR .confirmSetting___2WEaR .iconBlur___2vjhp {
  position: absolute;
  top: 20px;
  right: 24px;
  z-index: 3;
  cursor: pointer;
}
.confirmTemplate___1g5VR .formSetting___2X4CA {
  width: 50%;
  padding: 54px 1.2rem 0 1.2rem;
}
.confirmTemplate___1g5VR .formSetting___2X4CA .hideTitleCalendar___2NiXg {
  opacity: 0;
}
@media screen and (max-width: 1620px) {
  .confirmTemplate___1g5VR .confirmSetting___2WEaR .imageCustomer___M5lgi {
    min-height: 450px;
  }
}
@media screen and (max-width: 1480px) {
  .confirmTemplate___1g5VR .confirmSetting___2WEaR .imageCustomer___M5lgi {
    min-height: 400px;
  }
}
@media screen and (max-width: 1390px) {
  .confirmTemplate___1g5VR .confirmSetting___2WEaR .imageCustomer___M5lgi {
    min-height: 350px;
  }
}
@media screen and (max-width: 1290px) {
  .confirmTemplate___1g5VR .confirmSetting___2WEaR .imageCustomer___M5lgi {
    min-height: 300px;
  }
}
@media screen and (max-width: 1185px) {
  .confirmTemplate___1g5VR {
    width: calc(100% - 400px);
  }
  .confirmTemplate___1g5VR .confirmSetting___2WEaR .imageCustomer___M5lgi {
    min-height: 250px;
  }
}
@media screen and (max-width: 1070px) {
  .confirmTemplate___1g5VR .confirmSetting___2WEaR .imageCustomer___M5lgi {
    min-height: 300px;
  }
}
.toolBox___32-7w {
  border-radius: 5px;
  max-width: 370px;
  width: 370px;
  overflow: auto;
  background: #dff3ff;
  padding: 0.8rem;
  min-height: 100vh;
  box-shadow: #00000026 0 1px 2px 0, #00000026 0 2px 6px 2px;
  /* custom scrollbar */
}
.toolBox___32-7w::-webkit-scrollbar {
  width: 16px;
}
.toolBox___32-7w::-webkit-scrollbar-track {
  background-color: transparent;
}
.toolBox___32-7w::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.toolBox___32-7w::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.toolBox___32-7w .title___2XO3H {
  margin-bottom: 2rem;
  text-align: center;
}
.toolBox___32-7w .title___2XO3H p {
  color: #4a6bc0;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
}
.toolBox___32-7w .stepItem___2TUsQ {
  padding-left: 0.8rem;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 {
  display: flex;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 .lineItem___KQbkv {
  display: flex;
  height: 21px;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 .lineItem___KQbkv div {
  background: #4a6bc0;
  height: 100%;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 .lineItem___KQbkv div.lineBig___tBpi3 {
  width: 6px;
  margin-right: 0.1rem;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 .lineItem___KQbkv div.lineChild___2pU0y {
  width: 2px;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 .stepName___2D4sN {
  padding: 0 0.7rem;
}
.toolBox___32-7w .stepItem___2TUsQ .stepHeader___178z6 .stepName___2D4sN p {
  font-size: 16px;
  color: #3c3c3c;
  margin-bottom: 0;
  text-transform: uppercase;
}
.toolBox___32-7w .stepItem___2TUsQ .stepContent___e6cWZ {
  padding: 1rem 0 2rem 20px;
}
.toolBox___32-7w .stepItem___2TUsQ .stepContent___e6cWZ .groupBtnAdd___2_07q {
  width: 90%;
}
.toolBox___32-7w .stepItem___2TUsQ .stepContent___e6cWZ .groupBtnAdd___2_07q button {
  cursor: pointer;
  width: 100%;
  background: #4a6bc0;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 16px;
  padding: 0.35rem 0;
}
.toolBox___32-7w .stepItem___2TUsQ .stepContent___e6cWZ .groupBtnAdd___2_07q button svg {
  margin-right: 6px;
}
.toolBox___32-7w .stepItem___2TUsQ .stepContent___e6cWZ .groupBtnAdd___2_07q button:not(:last-child) {
  margin-bottom: 0.8rem;
}
.editInputContainer___2RiDS {
  font-size: 16px;
  padding: 26px 16px 26px 26px;
  letter-spacing: -2px;
}
.editInputContainer___2RiDS .bigTitle___3tJUF {
  color: #4a6bc0;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: bold;
}
.editInputContainer___2RiDS .title___2XO3H {
  color: #4a6bc0;
  position: relative;
  text-align: left;
  padding-left: 25px;
  line-height: 24px;
  margin-bottom: 20px;
}
.editInputContainer___2RiDS .title___2XO3H ::before {
  content: '';
  width: 7px;
  height: 24px;
  background-color: #4a6bc0;
  position: absolute;
  top: 0;
  left: 0;
}
.editInputContainer___2RiDS .title___2XO3H ::after {
  content: '';
  width: 2px;
  height: 24px;
  background-color: #4a6bc0;
  position: absolute;
  top: 0;
  left: 9px;
}
.editInputContainer___2RiDS .title___2XO3H span {
  font-weight: bold;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 {
  margin-bottom: 32px;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .inputCmt___2g_Zz,
.editInputContainer___2RiDS .inputContainer___20Jq4 .textAreaCpt___YX2v1 {
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
  border: 1px solid #3c3c3c !important;
  border-radius: 10px;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .inputCmt___2g_Zz :hover,
.editInputContainer___2RiDS .inputContainer___20Jq4 .textAreaCpt___YX2v1 :hover {
  border-color: #3c3c3c !important;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .inputCmt___2g_Zz {
  height: 36px;
  margin-bottom: 10px;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .inputCmt___2g_Zz :hover {
  border-color: #3c3c3c !important;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .textAreaCpt___YX2v1 {
  height: 84px;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .primaryBtn___3kxej {
  display: block;
  width: 130px;
  height: 39px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 21px;
  padding: 9px 0px;
  background: #4a6bc0;
  margin: 2px auto;
  color: #fff;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .primaryBtn___3kxej :hover {
  background: #4a6bc0;
}
.editInputContainer___2RiDS .inputContainer___20Jq4 .secondaryBtn___Iuc2p {
  display: block;
  width: 130px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 16px;
  padding: 8px 0;
  border: none;
  color: #3c3c3c;
  background-color: #fff;
  box-shadow: #00000017 0 2px 8px 0;
  margin: 20px auto;
}
.editInputContainer___2RiDS .checkboxContainer___1b3NN {
  margin-bottom: 32px;
}
.editInputContainer___2RiDS .checkboxContainer___1b3NN .checkbox___2Wj_c {
  width: 18px;
  height: 18px;
  border: 1px solid #3c3c3c;
  border-radius: 0;
  margin-right: 9px;
}
.editInputContainer___2RiDS .checkboxContainer___1b3NN input[type='checkbox'] {
  vertical-align: sub;
}
.editInputContainer___2RiDS .checkboxContainer___1b3NN .checkboxLabel___29I3e {
  font-size: 14px;
  line-height: 19px;
  color: #3c3c3c;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 {
  margin-bottom: 48px;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 .inputCmt___2g_Zz {
  font-size: 14px;
  line-height: 16px;
  padding: 8px 25px 8px 40px;
  border: 1px solid #3c3c3c !important;
  border-radius: 10px;
  height: 36px;
  margin-bottom: 10px;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 .inputCmt___2g_Zz :hover {
  border-color: #3c3c3c;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 .inputComponent___3QspK {
  display: flex;
  position: relative;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 .inputComponent___3QspK .iconTrash___3IYp5 {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 .inputArrorButton___1Umcr {
  width: 30px;
  height: 36px;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-right: 2px solid #3c3c3c;
  text-align: center;
  padding: 7px 0px;
  cursor: pointer;
}
.editInputContainer___2RiDS .optionCheckbox___1XtB6 .btnAddMore___1UJM2 {
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #4a6bc0;
  margin-top: 3px;
}
.inputNavigator___3lJEj .secondaryTitle___1Wh0c {
  margin-bottom: 10px;
  margin-right: 8px;
  color: #3c3c3c;
  font-size: 16px;
  letter-spacing: 1px;
  position: relative;
  line-height: 22px;
  padding-left: 12px;
}
.inputNavigator___3lJEj .secondaryTitle___1Wh0c ::before {
  content: '';
  width: 4px;
  height: 14px;
  background-color: #4a6bc0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.inputNavigator___3lJEj .primaryTitle___25rc2 {
  margin-bottom: 10px;
  margin-right: 8px;
  color: #3c3c3c;
  font-size: 16px;
  letter-spacing: 1px;
}
.inputNavigator___3lJEj .input___gaR-J {
  height: 36px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
  border: 1px solid #3c3c3c !important;
  border-radius: 10px;
}
.inputNavigator___3lJEj .input___gaR-J :hover {
  border-color: #3c3c3c !important;
}
.inputNavigator___3lJEj .textAreaCptNav___3cWLX {
  height: 150px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 8px 10px;
  border: 1px solid #3c3c3c !important;
  border-radius: 10px;
}
.inputNavigator___3lJEj .textAreaCptNav___3cWLX :hover {
  border-color: #3c3c3c !important;
}
.smallText___2rmRH {
  color: #3c3c3c;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
}
.inLineTitle___1M6Wk {
  display: inline-block;
}
.circleBlue___1s1me {
  background-color: #4a6bc0;
  text-align: center;
  width: 35px;
  height: 35px;
  margin-right: 7px;
  margin-bottom: 3px;
  border-radius: 50%;
  position: relative;
  line-height: 32px;
}
.circleBlueDown___2IfRj {
  background-color: #4a6bc0;
  text-align: center;
  width: 35px;
  height: 35px;
  margin-bottom: 3px;
  margin-right: 7px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
}
.circleBlueDown___2IfRj img {
  margin: auto 0;
  height: 19px;
}
.circleRed___Lhfm4 {
  width: 35px;
  height: 35px;
  margin-bottom: 3px;
  margin-right: 5px;
  background-color: #b63e2e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.circleRed___Lhfm4 img {
  margin: auto 0;
  height: 19px;
}
