.privacyPolicy___uRtau .content___HNXuD {
  padding: 75px 0 65px 0;
  text-align: justify;
  width: 100%;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .privacyPolicy___uRtau .content___HNXuD {
    padding: 30px 10px;
  }
}
.privacyPolicy___uRtau .content___HNXuD h2 {
  font-size: 40px;
  letter-spacing: 0px;
  color: #161615;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .privacyPolicy___uRtau .content___HNXuD h2 {
    font-size: 30px;
  }
}
.privacyPolicy___uRtau .content___HNXuD .title___BUZyd {
  font-size: 20px;
  letter-spacing: 0px;
  color: #161615;
  margin-bottom: 10px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .privacyPolicy___uRtau .content___HNXuD .title___BUZyd {
    font-size: 18px;
  }
}
.privacyPolicy___uRtau .content___HNXuD p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #161615;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .privacyPolicy___uRtau .content___HNXuD p {
    font-size: 14px;
  }
}
