@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1e0SA {
  display: flex;
  justify-content: center;
}
button.btn___3V9rU {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3V9rU {
    padding: 10px;
  }
}
button.btn___3V9rU:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3V9rU:focus {
  outline: none;
}
.btnWhite___2wL5F.btn___3V9rU {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2wL5F.btn___3V9rU:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1XDaS.btn___3V9rU,
.btnGreen___1XDaS.btn___3V9rU:hover,
.btnGreen___1XDaS.btn___3V9rU:active,
.btnGreen___1XDaS.btn___3V9rU:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2J9Mp {
  color: #fe0000;
}
.errorMessage___1CLff {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1RIV8 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1Rseo {
  display: none !important;
}
.m-auto___1glL7 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3QSzP {
  max-width: 120px !important;
}
.fc___wm3QR .fc-timegrid-col___3SskF.fc-day-today___1avEh {
  background-color: #ffffff;
}
.fc___wm3QR .fc-timegrid-slot___3aUiS {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___wm3QR .fc-timegrid-slot___3aUiS {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___c8cPI .fc-direction-ltr___8Whxt .fc-timegrid-slot-label-frame___3mzxu {
    font-size: 10px;
  }
  .bookingCalendar___c8cPI .fc___wm3QR .fc-timegrid-slot-label-cushion___F_Ei1 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___c8cPI .fc___wm3QR .fc-scrollgrid-section-header___3h5Nn .fc-scroller___2olww {
    overflow: unset !important;
  }
}
.ant-message-notice___1Iypn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2SW8g {
  width: 20px;
  height: 20px;
}
.bgTransparent___3o5R2 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3yb-y {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___Z-ASl {
  position: relative;
}
.inputCustomValue___Z-ASl .ant-input___2CgFD {
  padding-right: 50px;
}
.inputCustomValueText___2ooWw {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3FL77 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3FL77 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1lAeh .ant-radio-inner___1c_AA {
  border-color: black !important ;
}
.ant-radio-checked___1lAeh .ant-radio-inner___1c_AA:after {
  background-color: black;
}
.ant-radio___3ajrT:hover .ant-radio-inner___1c_AA {
  border-color: black;
}
.ant-radio-checked___1lAeh .ant-radio-inner___1c_AA:focus {
  border-color: black;
}
.modal___1xoEW {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1xoEW .close___3aJDL {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1xoEW .header___2Fm5e {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1xoEW .header___2Fm5e p {
  margin: 0;
}
.modal___1xoEW .content___DSjPq {
  text-align: center;
  font-size: 14px;
}
.modal___1xoEW .content___DSjPq p {
  margin: 0;
}
.modal___1xoEW .actions___27sHR {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1xoEW .actions___27sHR .button___2VVTo {
  margin-top: 10px;
}
.modal___1xoEW .actions___27sHR:hover {
  color: #33c3c7;
}
.fc___wm3QR .fc-timegrid-slot-minor___RO5pF {
  border-top-style: none;
}
.fc___wm3QR .fc-timegrid-slot___3aUiS {
  height: 1.5em !important;
}
.messageError___1FTJ5 .ant-message-custom-content___24DlP {
  display: flex;
  text-align: start;
}
.messageError___1FTJ5 .ant-message-custom-content___24DlP span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2itEL {
  background-color: #e6e4e4;
}
.notifyMessage___36gFu .ant-message-notice-content___k2AWZ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___36gFu .ant-message-notice-content___k2AWZ .anticon___3MOrF {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___36gFu .ant-message-notice-content___k2AWZ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2-IPP {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2-IPP .ant-notification-notice-message___3hSCf,
.notifyCustomize___2-IPP .ant-notification-notice-close___3nhzp,
.notifyCustomize___2-IPP .ant-notification-notice-icon___RoqbJ {
  color: white;
}
.notifyCustomize___2-IPP .ant-notification-notice-message___3hSCf {
  margin-left: 35px;
}
.notifyCustomize___2-IPP p {
  margin-bottom: 0;
}
.notifyCustomize___2-IPP p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3oKQl {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3oKQl .ant-notification-notice-message___3hSCf {
  margin-left: 35px;
}
.notifyAlertDuplicate___3oKQl p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3oKQl p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1ASbQ {
  width: 434px !important;
}
.handleSubmitVote___1ASbQ .ant-btn-primary___3YAlG {
  background: #59c3c7;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku .ant-modal-confirm-body___24m91 {
  display: flex !important;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku .ant-modal-confirm-body___24m91 .anticon___3MOrF {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku .ant-modal-confirm-btns___ijB9I button {
  font-weight: bold;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku .ant-modal-confirm-btns___ijB9I button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku .ant-modal-confirm-btns___ijB9I button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1ASbQ .ant-modal-body___MY2Ku .ant-modal-confirm-btns___ijB9I .ant-btn-primary___3YAlG {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2UiUP {
  margin-bottom: 0;
}
.mt-0___2L6wb {
  margin-top: 0;
}
.tooltipFormat___3GCkB p {
  margin-bottom: 0;
}
.bgWhite___3LGz1 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1M_DJ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1M_DJ:hover,
.bgPrimaryBlue___1M_DJ:focus,
.bgPrimaryBlue___1M_DJ:active {
  background: #4f7ac7;
}
.bgDarkBlue___1zONp {
  background-color: #023768 !important;
}
.bgDarkBlue___1zONp:hover,
.bgDarkBlue___1zONp:focus,
.bgDarkBlue___1zONp:active {
  background: #194d7d;
}
.bgGreyBlue___2pRhJ {
  background-color: #ebf0f9;
}
.bgLightBlue___1ScrU {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2dcUO {
  background-color: #e4453a;
}
.bgLightRed___2dcUO:hover,
.bgLightRed___2dcUO:focus,
.bgLightRed___2dcUO:active {
  background: #e17871;
}
.bgLightYellow___3iY-H {
  background-color: #ffd603;
}
.bgDarkGray___2Dgee {
  background-color: #333333;
}
.bgMediumGray___2P-ar {
  background-color: #a2a2a2;
}
.bgLightGray___3B-0d {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3zz8t {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2RDI8 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1R-Fg {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2c7Cs {
  color: #3368c7 !important;
}
.textDarkBlue___1Gkw1 {
  color: #023768 !important;
}
.textLightBlue___3sZum {
  color: #b2cbf7;
}
.textDarkGray___1XnM2 {
  color: #333333 !important;
}
.textMediumGray___3f2HG {
  color: #a2a2a2 !important;
}
.textLightGray___7WFWy {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2NgGJ {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2xcEW {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___5iQRp {
  border-radius: 5px !important;
}
.rounded-xs___1uEMb {
  border-radius: 10px !important;
}
.rounded-sm___2ysc8 {
  border-radius: 15px !important;
}
.rounded-md___3WTj1 {
  border-radius: 20px !important;
}
.rounded-lg___x7sBS {
  border-radius: 25px !important;
}
.rounded-full___3VoKS {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___mB5kV {
  margin: 0;
}
.mt-2___3Bu91 {
  margin-top: 0.5rem;
}
.my-2___3MGIc {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___uVInq {
  padding: 0;
}
.px-1___1nSUW {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3Ygv3 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___nC5lK {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___WzCqh {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3qJip {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1DZcw {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___kxq3j {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1Plcx {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___129rQ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3puvi {
  padding-bottom: 20px;
}
.h-full___1gqBM {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1clEw {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2BDUt {
  font-weight: bold !important;
}
.fontWeight400___1zGno {
  font-weight: 400 !important;
}
.fontWeight500___3OVgA {
  font-weight: 500 !important;
}
.fontWeight600___1RclY {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1ED0_ {
  font-size: 12px !important;
}
.fontSize14___2QfZx {
  font-size: 14px !important;
}
.fontSize16___2CDR9 {
  font-size: 16px !important;
}
.fontSize18___2jAO8 {
  font-size: 18px !important;
}
.fontSize20___3qDvZ {
  font-size: 20px !important;
}
.fontSize24___isMa9 {
  font-size: 24px !important;
}
.eventName___3Y_F2 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3tVeD {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___211fb > tr > th,
.ant-table-tbody___1BH8t > tr.ant-table-row___2Pa7E > td,
.ant-table-summary___1PbgZ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___211fb > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___QAXye .ant-table-thead___211fb > tr > th,
.table-portrait___QAXye .ant-table-tbody___1BH8t > tr.ant-table-row___2Pa7E > td,
.table-portrait___QAXye .ant-table-summary___1PbgZ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___QAXye .bgLightGray___3B-0d td {
  background: #ebebeb;
}
.table-portrait___QAXye .bgLightBlue___1ScrU td {
  background: #b2cbf7;
}
.eventContent___2Pa8c .timeText___37gWY {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1BH8t > tr.bgLightGray___3B-0d:hover > td,
.ant-table-tbody___1BH8t > tr > td.ant-table-cell-row-hover___1aFS_ {
  background: #ebebeb;
}
.ant-table-tbody___1BH8t > tr.bgLightBlue___1ScrU:hover > td,
.ant-table-tbody___1BH8t > tr > td.ant-table-cell-row-hover___1aFS_ {
  background: #b2cbf7;
}
.ant-table-tbody___1BH8t > tr.bgWhite___3LGz1:hover > td,
.ant-table-tbody___1BH8t > tr > td.ant-table-cell-row-hover___1aFS_ {
  background: #ffffff;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ {
  padding: 30px 0 30px;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .inputField___GV6qX {
  margin: 0 auto 20px auto;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .inputField___GV6qX .fieldLabel___2C1QV {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .inputField___GV6qX .fieldLabel___2C1QV .inputRequired___3ApZx {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 10px;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .inputField___GV6qX input {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .inputField___GV6qX .errorNotice___13SiG {
  color: #ff4d4f;
  font-size: 14px;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .btnZone___1NN9w {
  margin-top: 20px;
  margin-bottom: 25px;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .btnZone___1NN9w .signUpBtn___36ibt {
  width: 100%;
  height: 40px;
  background-color: #023768;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .btnZone___1NN9w .signUpBtn___36ibt:hover {
  background-color: #1f4f96;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .ant-form-item {
  margin: 0;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .ant-form {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
.resetPassword___2KgGd .bodyContent___2o_ZZ .ant-form .fieldFlex___3YE91 .ant-row .ant-col > div:nth-child(2) {
  width: 150px;
}
