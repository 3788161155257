.container___2G7Ry {
  max-width: 1080px;
  margin: auto;
  padding: 45px 15px 0;
  width: 100%;
}
.container___2G7Ry .title___2De5T {
  color: black;
  position: relative;
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
  left: 24px;
}
.container___2G7Ry .title___2De5T ::before {
  content: '';
  background-color: black;
  width: 6px;
  height: 27px;
  position: absolute;
  top: 0;
  left: -24px;
}
.container___2G7Ry .title___2De5T ::after {
  content: '';
  background-color: black;
  width: 2px;
  height: 27px;
  position: absolute;
  top: 0;
  left: -15px;
}
.container___2G7Ry .table___3SWqy table {
  border: 2px solid #e4e4e4 !important;
}
.container___2G7Ry .table___3SWqy table thead tr th {
  background: #fff;
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #3c3c3c;
  text-align: center;
}
.container___2G7Ry .table___3SWqy table thead tr th:not(:last-child) {
  border-right: 2px solid #e4e4e4 !important;
}
.rowOdd___1opze {
  background: #fff;
}
.rowOdd___1opze td {
  font-family: NotoSansJP-Black;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.rowOdd___1opze td:not(:last-child) {
  border-right: 2px solid #e4e4e4 !important;
}
.rowEven___4L4Dc {
  background-color: #f1f1f1;
}
.rowEven___4L4Dc td {
  font-family: NotoSansJP-Black;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.rowEven___4L4Dc td:not(:last-child) {
  border-right: 2px solid #e4e4e4 !important;
}
