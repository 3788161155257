.defaultURL___2WA_r {
  max-width: 1080px;
  margin: auto;
  position: relative;
  padding: 72px 0 182px 0;
}
.defaultURL___2WA_r.defaultURLNotFound___3FKPg {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
}
@media screen and (max-width: 767px) {
  .defaultURL___2WA_r {
    padding: 10px 0 182px 0;
  }
}
.defaultURL___2WA_r .notFound___13GoF {
  text-align: center;
}
.defaultURL___2WA_r .headInfo___23igB {
  font-size: 25px;
  color: #000000;
  margin: 0 0 72px 0;
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .defaultURL___2WA_r .headInfo___23igB {
    font-size: 18px;
    margin: 0 0 5px 0;
  }
}
.defaultURL___2WA_r .suggestion___wJYcV {
  font-size: 24px;
  margin-bottom: 30px;
  text-indent: 15px;
  padding: 0 15px;
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  .defaultURL___2WA_r .suggestion___wJYcV {
    font-size: 14px;
    text-indent: 0;
    margin-bottom: 5px;
  }
}
.defaultURL___2WA_r .listEventType___8NGEb {
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: auto;
}
@media screen and (max-width: 1199px) {
  .defaultURL___2WA_r .listEventType___8NGEb {
    justify-content: center;
  }
}
.defaultURL___2WA_r .backToHome___2qjiD {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
}
.defaultURL___2WA_r .backToHome___2qjiD img {
  background-color: #0f87e4;
  width: 25px;
  border-radius: 100%;
  margin-right: 5px;
}
.defaultURL___2WA_r .ant-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .defaultURL___2WA_r .ant-pagination {
    padding: 0 30px 30px;
  }
}
