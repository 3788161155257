@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3tMOA {
  display: flex;
  justify-content: center;
}
button.btn___158zc {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___158zc {
    padding: 10px;
  }
}
button.btn___158zc:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___158zc:focus {
  outline: none;
}
.btnWhite___9OuN3.btn___158zc {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___9OuN3.btn___158zc:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2mKJk.btn___158zc,
.btnGreen___2mKJk.btn___158zc:hover,
.btnGreen___2mKJk.btn___158zc:active,
.btnGreen___2mKJk.btn___158zc:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2vNQP {
  color: #fe0000;
}
.errorMessage___2rqQI {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2MM5j {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___36FPW {
  display: none !important;
}
.m-auto___1vf2I {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2B04c {
  max-width: 120px !important;
}
.fc___1FGGG .fc-timegrid-col___3PfVK.fc-day-today___3pJcP {
  background-color: #ffffff;
}
.fc___1FGGG .fc-timegrid-slot___16q-e {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1FGGG .fc-timegrid-slot___16q-e {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___29iFO .fc-direction-ltr___3Q1uc .fc-timegrid-slot-label-frame___3y059 {
    font-size: 10px;
  }
  .bookingCalendar___29iFO .fc___1FGGG .fc-timegrid-slot-label-cushion___2bdWD {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___29iFO .fc___1FGGG .fc-scrollgrid-section-header___1uIzE .fc-scroller___1EiUH {
    overflow: unset !important;
  }
}
.ant-message-notice___2IHwO {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2bPGp {
  width: 20px;
  height: 20px;
}
.bgTransparent___2i68O {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___17RKY {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3tp6T {
  position: relative;
}
.inputCustomValue___3tp6T .ant-input___1Iff6 {
  padding-right: 50px;
}
.inputCustomValueText___2QI5R {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3E_U- .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3E_U- .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___LZq0Q .ant-radio-inner___1T9bz {
  border-color: black !important ;
}
.ant-radio-checked___LZq0Q .ant-radio-inner___1T9bz:after {
  background-color: black;
}
.ant-radio___1wWI8:hover .ant-radio-inner___1T9bz {
  border-color: black;
}
.ant-radio-checked___LZq0Q .ant-radio-inner___1T9bz:focus {
  border-color: black;
}
.modal___3OFVI {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3OFVI .close___3OuyT {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3OFVI .header___1TxU0 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3OFVI .header___1TxU0 p {
  margin: 0;
}
.modal___3OFVI .content___1pKgD {
  text-align: center;
  font-size: 14px;
}
.modal___3OFVI .content___1pKgD p {
  margin: 0;
}
.modal___3OFVI .actions___2DYZi {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3OFVI .actions___2DYZi .button___2Ozj3 {
  margin-top: 10px;
}
.modal___3OFVI .actions___2DYZi:hover {
  color: #33c3c7;
}
.fc___1FGGG .fc-timegrid-slot-minor___3On8C {
  border-top-style: none;
}
.fc___1FGGG .fc-timegrid-slot___16q-e {
  height: 1.5em !important;
}
.messageError___3JTqO .ant-message-custom-content___KfZXh {
  display: flex;
  text-align: start;
}
.messageError___3JTqO .ant-message-custom-content___KfZXh span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___hKx60 {
  background-color: #e6e4e4;
}
.notifyMessage___1YZAP .ant-message-notice-content___n6v-u {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1YZAP .ant-message-notice-content___n6v-u .anticon___1WpqG {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1YZAP .ant-message-notice-content___n6v-u span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3xBL6 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3xBL6 .ant-notification-notice-message___3rFGY,
.notifyCustomize___3xBL6 .ant-notification-notice-close___3QOaz,
.notifyCustomize___3xBL6 .ant-notification-notice-icon___W-Avo {
  color: white;
}
.notifyCustomize___3xBL6 .ant-notification-notice-message___3rFGY {
  margin-left: 35px;
}
.notifyCustomize___3xBL6 p {
  margin-bottom: 0;
}
.notifyCustomize___3xBL6 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3awy7 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3awy7 .ant-notification-notice-message___3rFGY {
  margin-left: 35px;
}
.notifyAlertDuplicate___3awy7 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3awy7 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2VjTp {
  width: 434px !important;
}
.handleSubmitVote___2VjTp .ant-btn-primary___1UPO5 {
  background: #59c3c7;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW .ant-modal-confirm-body___10qTH {
  display: flex !important;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW .ant-modal-confirm-body___10qTH .anticon___1WpqG {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW .ant-modal-confirm-btns___3V3ya button {
  font-weight: bold;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW .ant-modal-confirm-btns___3V3ya button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW .ant-modal-confirm-btns___3V3ya button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2VjTp .ant-modal-body___3GMPW .ant-modal-confirm-btns___3V3ya .ant-btn-primary___1UPO5 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1-GZs {
  margin-bottom: 0;
}
.mt-0___fQCjZ {
  margin-top: 0;
}
.tooltipFormat___1wVmm p {
  margin-bottom: 0;
}
.bgWhite___kOo6p {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___36Q03 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___36Q03:hover,
.bgPrimaryBlue___36Q03:focus,
.bgPrimaryBlue___36Q03:active {
  background: #4f7ac7;
}
.bgDarkBlue___QQGm5 {
  background-color: #023768 !important;
}
.bgDarkBlue___QQGm5:hover,
.bgDarkBlue___QQGm5:focus,
.bgDarkBlue___QQGm5:active {
  background: #194d7d;
}
.bgGreyBlue___3vxRL {
  background-color: #ebf0f9;
}
.bgLightBlue___2fTKj {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3gate {
  background-color: #e4453a;
}
.bgLightRed___3gate:hover,
.bgLightRed___3gate:focus,
.bgLightRed___3gate:active {
  background: #e17871;
}
.bgLightYellow___3iPrL {
  background-color: #ffd603;
}
.bgDarkGray___2AD2L {
  background-color: #333333;
}
.bgMediumGray___2mt8m {
  background-color: #a2a2a2;
}
.bgLightGray___1Icpp {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3iCKT {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2aVZd {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1Ddc2 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1NkrP {
  color: #3368c7 !important;
}
.textDarkBlue___3_jdM {
  color: #023768 !important;
}
.textLightBlue___3lKhL {
  color: #b2cbf7;
}
.textDarkGray___15flE {
  color: #333333 !important;
}
.textMediumGray___xLMXa {
  color: #a2a2a2 !important;
}
.textLightGray___1RdDT {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2EeQS {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1fwcp {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2_rXr {
  border-radius: 5px !important;
}
.rounded-xs___18wCk {
  border-radius: 10px !important;
}
.rounded-sm___3r1gr {
  border-radius: 15px !important;
}
.rounded-md___ZWsRS {
  border-radius: 20px !important;
}
.rounded-lg___FzEK_ {
  border-radius: 25px !important;
}
.rounded-full___h8nM- {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___lJ5qh {
  margin: 0;
}
.mt-2___9hgpJ {
  margin-top: 0.5rem;
}
.my-2___2RMgs {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1HRSg {
  padding: 0;
}
.px-1___2vayv {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2vWc6 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2cfyu {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2BoNV {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2h3UP {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1OBjx {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2BLgD {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___RwgF5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1miTR {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3rZnb {
  padding-bottom: 20px;
}
.h-full___2JSJ6 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___19-2T {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold____hH40 {
  font-weight: bold !important;
}
.fontWeight400___2E3qe {
  font-weight: 400 !important;
}
.fontWeight500___1a2h- {
  font-weight: 500 !important;
}
.fontWeight600___1VeiI {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___Yc7R_ {
  font-size: 12px !important;
}
.fontSize14___2NYy5 {
  font-size: 14px !important;
}
.fontSize16___1w0MO {
  font-size: 16px !important;
}
.fontSize18___1a8ZV {
  font-size: 18px !important;
}
.fontSize20___1dGTn {
  font-size: 20px !important;
}
.fontSize24___1wxTe {
  font-size: 24px !important;
}
.eventName___2fC5x {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1yAju {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1auc0 > tr > th,
.ant-table-tbody___30gWC > tr.ant-table-row___1Iwgu > td,
.ant-table-summary___3wEZs tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1auc0 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2N7Ft .ant-table-thead___1auc0 > tr > th,
.table-portrait___2N7Ft .ant-table-tbody___30gWC > tr.ant-table-row___1Iwgu > td,
.table-portrait___2N7Ft .ant-table-summary___3wEZs tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2N7Ft .bgLightGray___1Icpp td {
  background: #ebebeb;
}
.table-portrait___2N7Ft .bgLightBlue___2fTKj td {
  background: #b2cbf7;
}
.eventContent___2B_Ok .timeText___M5wyB {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___30gWC > tr.bgLightGray___1Icpp:hover > td,
.ant-table-tbody___30gWC > tr > td.ant-table-cell-row-hover___onzew {
  background: #ebebeb;
}
.ant-table-tbody___30gWC > tr.bgLightBlue___2fTKj:hover > td,
.ant-table-tbody___30gWC > tr > td.ant-table-cell-row-hover___onzew {
  background: #b2cbf7;
}
.ant-table-tbody___30gWC > tr.bgWhite___kOo6p:hover > td,
.ant-table-tbody___30gWC > tr > td.ant-table-cell-row-hover___onzew {
  background: #ffffff;
}
.LayoutContainer___2Fmi7 {
  margin: auto;
  height: 100vh;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 {
  background: #9af0ed;
  flex: 0 0 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
  width: 180px !important;
  z-index: 50;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9::-webkit-scrollbar {
  display: none;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .ant-menu,
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #9af0ed;
  color: black;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .menu_item_active {
  font-weight: bold;
  background: rgba(255, 255, 255, 0.4) !important;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .ant-menu-item-group {
  margin-bottom: 20px;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .ant-menu-item-group-title {
  font-size: 18px;
  color: grey;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .ant-menu-item {
  font-size: 18px;
  margin: 0 !important;
  height: 30px;
  line-height: 30px;
  color: black;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .logoutTitle {
  cursor: pointer;
}
.LayoutContainer___2Fmi7 .adminSider___2Wdw9 .logoutTitle .ant-menu-item-group-title {
  color: black;
}
.LayoutContainer___2Fmi7 .headerLogo___1wtty {
  padding: 30px 20px 15px;
  text-align: center;
  background: #ffffff;
}
.LayoutContainer___2Fmi7 .imgLogo___1ofio {
  width: 130px;
}
.LayoutContainer___2Fmi7 .layoutContent___a2EkV {
  background: #ffffff;
}
.LayoutContainer___2Fmi7 .layoutContent___a2EkV::-webkit-scrollbar {
  display: none;
}
.LayoutContainer___2Fmi7 .contentContainer___1U0fx {
  padding: 20px;
  margin-left: 180px;
  overflow: 'visible';
  overflow: 'initial';
}
.LayoutContainer___2Fmi7 .logoutConfirm___3E_U- {
  z-index: 100;
}
