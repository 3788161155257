@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2vB22 {
  display: flex;
  justify-content: center;
}
button.btn___1JRSv {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1JRSv {
    padding: 10px;
  }
}
button.btn___1JRSv:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1JRSv:focus {
  outline: none;
}
.btnWhite___2zHz9.btn___1JRSv {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2zHz9.btn___1JRSv:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3Ep-f.btn___1JRSv,
.btnGreen___3Ep-f.btn___1JRSv:hover,
.btnGreen___3Ep-f.btn___1JRSv:active,
.btnGreen___3Ep-f.btn___1JRSv:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1pJ1v {
  color: #fe0000;
}
.errorMessage___1Y9CT {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1E5BG {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3AiFD {
  display: none !important;
}
.m-auto___1rnfb {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3_oPG {
  max-width: 120px !important;
}
.fc___1vt6T .fc-timegrid-col___2Y11w.fc-day-today___3ErJa {
  background-color: #ffffff;
}
.fc___1vt6T .fc-timegrid-slot___2hRkY {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1vt6T .fc-timegrid-slot___2hRkY {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___13RFm .fc-direction-ltr___3WUg7 .fc-timegrid-slot-label-frame___3yAJY {
    font-size: 10px;
  }
  .bookingCalendar___13RFm .fc___1vt6T .fc-timegrid-slot-label-cushion___2N1U6 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___13RFm .fc___1vt6T .fc-scrollgrid-section-header___2WJGM .fc-scroller___2379i {
    overflow: unset !important;
  }
}
.ant-message-notice___2Hoad {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1V476 {
  width: 20px;
  height: 20px;
}
.bgTransparent___39X3m {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3fCoz {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1CP4S {
  position: relative;
}
.inputCustomValue___1CP4S .ant-input___2EBdQ {
  padding-right: 50px;
}
.inputCustomValueText___dba63 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___31USD .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___31USD .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___zxB0_ .ant-radio-inner___17_C6 {
  border-color: black !important ;
}
.ant-radio-checked___zxB0_ .ant-radio-inner___17_C6:after {
  background-color: black;
}
.ant-radio___3iH8S:hover .ant-radio-inner___17_C6 {
  border-color: black;
}
.ant-radio-checked___zxB0_ .ant-radio-inner___17_C6:focus {
  border-color: black;
}
.modal___3qNoh {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3qNoh .close___1A24- {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3qNoh .header___1p_pf {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3qNoh .header___1p_pf p {
  margin: 0;
}
.modal___3qNoh .content___2xTlT {
  text-align: center;
  font-size: 14px;
}
.modal___3qNoh .content___2xTlT p {
  margin: 0;
}
.modal___3qNoh .actions___3mlYa {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3qNoh .actions___3mlYa .button___2Sqcs {
  margin-top: 10px;
}
.modal___3qNoh .actions___3mlYa:hover {
  color: #33c3c7;
}
.fc___1vt6T .fc-timegrid-slot-minor___1-S7o {
  border-top-style: none;
}
.fc___1vt6T .fc-timegrid-slot___2hRkY {
  height: 1.5em !important;
}
.messageError___foOW4 .ant-message-custom-content___cAEho {
  display: flex;
  text-align: start;
}
.messageError___foOW4 .ant-message-custom-content___cAEho span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3hS-w {
  background-color: #e6e4e4;
}
.notifyMessage___IfMTf .ant-message-notice-content___1AP2l {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___IfMTf .ant-message-notice-content___1AP2l .anticon___1NhAw {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___IfMTf .ant-message-notice-content___1AP2l span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1oHgF {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1oHgF .ant-notification-notice-message___33fy1,
.notifyCustomize___1oHgF .ant-notification-notice-close___2tKSH,
.notifyCustomize___1oHgF .ant-notification-notice-icon___nK5Pn {
  color: white;
}
.notifyCustomize___1oHgF .ant-notification-notice-message___33fy1 {
  margin-left: 35px;
}
.notifyCustomize___1oHgF p {
  margin-bottom: 0;
}
.notifyCustomize___1oHgF p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2zOhE {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2zOhE .ant-notification-notice-message___33fy1 {
  margin-left: 35px;
}
.notifyAlertDuplicate___2zOhE p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2zOhE p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1Danb {
  width: 434px !important;
}
.handleSubmitVote___1Danb .ant-btn-primary___3kU8K {
  background: #59c3c7;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p .ant-modal-confirm-body___3NhXE {
  display: flex !important;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p .ant-modal-confirm-body___3NhXE .anticon___1NhAw {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p .ant-modal-confirm-btns___2XX4z button {
  font-weight: bold;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p .ant-modal-confirm-btns___2XX4z button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p .ant-modal-confirm-btns___2XX4z button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1Danb .ant-modal-body___1596p .ant-modal-confirm-btns___2XX4z .ant-btn-primary___3kU8K {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___6xP9p {
  margin-bottom: 0;
}
.mt-0___1s2gQ {
  margin-top: 0;
}
.tooltipFormat___3Oswb p {
  margin-bottom: 0;
}
.bgWhite___1haju {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3SeVZ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3SeVZ:hover,
.bgPrimaryBlue___3SeVZ:focus,
.bgPrimaryBlue___3SeVZ:active {
  background: #4f7ac7;
}
.bgDarkBlue___2lHN6 {
  background-color: #023768 !important;
}
.bgDarkBlue___2lHN6:hover,
.bgDarkBlue___2lHN6:focus,
.bgDarkBlue___2lHN6:active {
  background: #194d7d;
}
.bgGreyBlue___1LJ47 {
  background-color: #ebf0f9;
}
.bgLightBlue___36Loc {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2XJY4 {
  background-color: #e4453a;
}
.bgLightRed___2XJY4:hover,
.bgLightRed___2XJY4:focus,
.bgLightRed___2XJY4:active {
  background: #e17871;
}
.bgLightYellow___2bFxi {
  background-color: #ffd603;
}
.bgDarkGray___3feuv {
  background-color: #333333;
}
.bgMediumGray___3mwwH {
  background-color: #a2a2a2;
}
.bgLightGray___177xy {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___21szZ {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___J4NfU {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2Bb3o {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___FJTQp {
  color: #3368c7 !important;
}
.textDarkBlue___3tejd {
  color: #023768 !important;
}
.textLightBlue___GmSwE {
  color: #b2cbf7;
}
.textDarkGray___iN-OC {
  color: #333333 !important;
}
.textMediumGray___2L30y {
  color: #a2a2a2 !important;
}
.textLightGray___J_Bbs {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___idnHa {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___38xBJ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___jg3tC {
  border-radius: 5px !important;
}
.rounded-xs___1wJXM {
  border-radius: 10px !important;
}
.rounded-sm___3_dmH {
  border-radius: 15px !important;
}
.rounded-md___3RJGS {
  border-radius: 20px !important;
}
.rounded-lg___3z6qs {
  border-radius: 25px !important;
}
.rounded-full___2EjkZ {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3EfiH {
  margin: 0;
}
.mt-2___15GCT {
  margin-top: 0.5rem;
}
.my-2___2BPoo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1JUae {
  padding: 0;
}
.px-1___1DjCp {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1FlRN {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3UWf6 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___SkSHw {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___t7B5o {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2n8_K {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2sMTD {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2cir3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1olpH {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___CTt-V {
  padding-bottom: 20px;
}
.h-full___2y4Tk {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2bMYt {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___mmm_p {
  font-weight: bold !important;
}
.fontWeight400___3QnYD {
  font-weight: 400 !important;
}
.fontWeight500___3ihSw {
  font-weight: 500 !important;
}
.fontWeight600___To8rq {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2ud-L {
  font-size: 12px !important;
}
.fontSize14___2xZEv {
  font-size: 14px !important;
}
.fontSize16___17l8g {
  font-size: 16px !important;
}
.fontSize18___RQ_lT {
  font-size: 18px !important;
}
.fontSize20___rY5TS {
  font-size: 20px !important;
}
.fontSize24___2z2Zv {
  font-size: 24px !important;
}
.eventName___3-hpa {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___13Kp2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3txbI > tr > th,
.ant-table-tbody___1BLXG > tr.ant-table-row___2igjD > td,
.ant-table-summary___1QClw tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3txbI > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1ImvI .ant-table-thead___3txbI > tr > th,
.table-portrait___1ImvI .ant-table-tbody___1BLXG > tr.ant-table-row___2igjD > td,
.table-portrait___1ImvI .ant-table-summary___1QClw tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1ImvI .bgLightGray___177xy td {
  background: #ebebeb;
}
.table-portrait___1ImvI .bgLightBlue___36Loc td {
  background: #b2cbf7;
}
.eventContent___1H8LD .timeText___2J1bs {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1BLXG > tr.bgLightGray___177xy:hover > td,
.ant-table-tbody___1BLXG > tr > td.ant-table-cell-row-hover___17-A4 {
  background: #ebebeb;
}
.ant-table-tbody___1BLXG > tr.bgLightBlue___36Loc:hover > td,
.ant-table-tbody___1BLXG > tr > td.ant-table-cell-row-hover___17-A4 {
  background: #b2cbf7;
}
.ant-table-tbody___1BLXG > tr.bgWhite___1haju:hover > td,
.ant-table-tbody___1BLXG > tr > td.ant-table-cell-row-hover___17-A4 {
  background: #ffffff;
}
.settingUrl___21H7s {
  padding-bottom: 105px;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s {
    padding-bottom: 150px;
  }
}
.settingUrl___21H7s .urlRule___3I3I2 {
  text-align: center;
  font-size: 20px;
  color: #101010;
  padding-top: 50px;
  padding-bottom: 43px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fcfcfc;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlRule___3I3I2 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.settingUrl___21H7s .urlRule___3I3I2 .urlRuleGreen___zkLmu {
  color: #07989c;
  font-size: 29px;
  line-height: 1.68;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlRule___3I3I2 .urlRuleGreen___zkLmu {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlRule___3I3I2 .subUrlRule___2yjK1 {
    font-size: 12px;
  }
}
.settingUrl___21H7s .urlContent___2t-G8 {
  color: #414141;
  max-width: 850px;
  margin: 80px auto 60px;
  font-size: 25px;
  line-height: 1.8;
}
.settingUrl___21H7s .urlContent___2t-G8 .urlContentDescript___1EoM5 {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlContent___2t-G8 {
    font-size: 18px;
    padding: 0 15px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .settingUrl___21H7s .urlContent___2t-G8 {
    padding: 0 15px;
  }
}
.settingUrl___21H7s .urlContent___2t-G8 .urlContentGrey___89r7K {
  font-size: 14px;
  color: #9d9d9d;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlContent___2t-G8 .urlContentGrey___89r7K {
    font-size: 15px;
  }
}
.settingUrl___21H7s .urlContent___2t-G8 .urlForm___1YR8w {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlContent___2t-G8 .urlForm___1YR8w {
    flex-wrap: wrap;
  }
}
.settingUrl___21H7s .urlContent___2t-G8 .urlLabel___237xa {
  font-size: 16px;
  margin-right: 15px;
  letter-spacing: 3px;
  color: #414141;
}
@media screen and (max-width: 767px) {
  .settingUrl___21H7s .urlContent___2t-G8 .urlLabel___237xa {
    margin-bottom: 10px;
  }
}
.settingUrl___21H7s .urlContent___2t-G8 .urlInput___upAbd {
  height: 60px;
  width: 100%;
  border: 1px solid #33c3c7;
  border-radius: 15px;
  padding: 0 22px;
  color: #252525;
  font-size: 16px;
}
.settingUrl___21H7s .urlContent___2t-G8 .urlInput___upAbd:focus {
  outline: none;
}
.settingUrl___21H7s .urlContent___2t-G8 .urlFormItem___1CIUV {
  margin-bottom: 85px;
}
.settingUrl___21H7s .urlContent___2t-G8 .btn.btnWhite {
  font-size: 16px;
}
.settingUrl___21H7s .urlContent___2t-G8 .btn.btnWhite:hover {
  border: 2px solid #909090;
}
.settingUrl___21H7s .urlContent___2t-G8 .btn.btnGreen {
  font-size: 16px;
}
.settingUrl___21H7s .urlContent___2t-G8 .ant-form-item-explain.ant-form-item-explain-error {
  margin-left: 155px;
  margin-top: 10px;
}
