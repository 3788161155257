.suggestLogin___12_gU {
  background: #ececec;
  padding: 15px 1rem 10px 1rem;
  border-radius: 5px;
  margin-top: 0.6rem;
}
.suggestLogin___12_gU p {
  margin: 0;
  text-align: center;
  font-size: 12px;
}
.suggestLogin___12_gU .btnZone___3Y2CU {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.suggestLogin___12_gU .btnZone___3Y2CU button {
  width: 77%;
  font-size: 15px;
  border-radius: 5px;
  font-weight: 500;
}
.suggestLogin___12_gU .btnZone___3Y2CU button.signUpBtn___19DSy {
  margin-bottom: 5px;
  color: #ffffff;
  background-color: #33c3c7;
}
.suggestLogin___12_gU .btnZone___3Y2CU button.signInBtn___nfl9T {
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  color: #3c3c3c;
  background-color: #ffffff;
}
@media screen and (max-width: 838px) {
  .suggestLogin___12_gU .btnZone___3Y2CU button {
    width: 80%;
  }
}
