@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
:global .ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  :global .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
:global .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
:global .ant-tabs-tab {
  padding: 0 0 12px 0;
}
:global .ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
:global .ant-tabs-tab-btn:active {
  color: #33c3c7;
}
:global .ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
:global .ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
:global .ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
:global .ant-select-selector {
  border-radius: 5px !important;
}
:global .fc-event-main {
  background-color: transparent;
}
:global .ant-switch-checked {
  background-color: #e3f4f4;
}
:global .ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
:global .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
:global .fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
:global .fc-timegrid-event {
  border: 1px solid transparent;
}
:global .fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
:global .fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
:global .ant-btn:hover {
  border-color: transparent;
}
:global .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
:global .ant-picker:hover,
:global .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
:global .ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
:global .ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
:global .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
:global .ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
:global .ant-input-affix-wrapper {
  border-width: 2px;
}
:global .ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
:global .ant-spin-dot-item {
  background-color: #33c3c7;
}
:global .ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  :global .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  :global .ant-tooltip {
    max-width: 70%;
  }
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active,
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus,
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner,
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner,
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after,
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after,
:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
:global .ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
:global .ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
:global .ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
:global .ant-picker-time-panel-column > li:active,
:global .ant-picker-time-panel-column > li:focus,
:global .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
:global .ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner,
:global .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner,
:global .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
:global .ant-picker-time-panel-column > li:active::after,
:global .ant-picker-time-panel-column > li:focus::after,
:global .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
:global .ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
:global .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
:global .ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup {
  display: flex;
  justify-content: center;
}
button.btn {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn {
    padding: 10px;
  }
}
button.btn:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn:focus {
  outline: none;
}
.btnWhite.btn {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite.btn:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen.btn,
.btnGreen.btn:hover,
.btnGreen.btn:active,
.btnGreen.btn:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required {
  color: #fe0000;
}
.errorMessage {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption {
  display: none !important;
}
.m-auto {
  margin: auto;
}
:global .btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
:global .btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
:global .btn-grey,
:global .btn-grey:hover,
:global .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
:global .btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
:global .btn-white__shadow,
:global .btn-white__shadow:hover {
  color: #3c3c3c;
}
:global .btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
:global .btn-small {
  padding: 4px 15px !important;
}
:global .loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
:global .ant-tooltip-inner {
  word-break: break-all;
}
:global .modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
:global .modalPeriod .ant-modal-body {
  padding-top: 0;
}
:global .ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width {
  max-width: 120px !important;
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: #ffffff;
}
.fc .fc-timegrid-slot {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc .fc-timegrid-slot {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar .fc-direction-ltr .fc-timegrid-slot-label-frame {
    font-size: 10px;
  }
  .bookingCalendar .fc .fc-timegrid-slot-label-cushion {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar .fc .fc-scrollgrid-section-header .fc-scroller {
    overflow: unset !important;
  }
}
.ant-message-notice {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper {
  width: 20px;
  height: 20px;
}
.bgTransparent {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue {
  position: relative;
}
.inputCustomValue .ant-input {
  padding-right: 50px;
}
.inputCustomValueText {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm :global .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm :global .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: black !important ;
}
.ant-radio-checked .ant-radio-inner:after {
  background-color: black;
}
.ant-radio:hover .ant-radio-inner {
  border-color: black;
}
.ant-radio-checked .ant-radio-inner:focus {
  border-color: black;
}
.modal {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal .close {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal .header {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal .header p {
  margin: 0;
}
.modal .content {
  text-align: center;
  font-size: 14px;
}
.modal .content p {
  margin: 0;
}
.modal .actions {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal .actions .button {
  margin-top: 10px;
}
.modal .actions:hover {
  color: #33c3c7;
}
.fc .fc-timegrid-slot-minor {
  border-top-style: none;
}
.fc .fc-timegrid-slot {
  height: 1.5em !important;
}
.messageError .ant-message-custom-content {
  display: flex;
  text-align: start;
}
.messageError .ant-message-custom-content span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey {
  background-color: #e6e4e4;
}
.notifyMessage .ant-message-notice-content {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage .ant-message-notice-content .anticon {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage .ant-message-notice-content span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize .ant-notification-notice-message,
.notifyCustomize .ant-notification-notice-close,
.notifyCustomize .ant-notification-notice-icon {
  color: white;
}
.notifyCustomize .ant-notification-notice-message {
  margin-left: 35px;
}
.notifyCustomize p {
  margin-bottom: 0;
}
.notifyCustomize p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate {
  padding: 16px 14px;
}
.notifyAlertDuplicate .ant-notification-notice-message {
  margin-left: 35px;
}
.notifyAlertDuplicate p {
  margin-bottom: 0;
}
.notifyAlertDuplicate p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote {
  width: 434px !important;
}
.handleSubmitVote .ant-btn-primary {
  background: #59c3c7;
}
.handleSubmitVote .ant-modal-body {
  padding: 15px 30px 18px;
}
.handleSubmitVote .ant-modal-body .ant-modal-confirm-body {
  display: flex !important;
}
.handleSubmitVote .ant-modal-body .ant-modal-confirm-body .anticon {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote .ant-modal-body .ant-modal-confirm-btns button {
  font-weight: bold;
}
.handleSubmitVote .ant-modal-body .ant-modal-confirm-btns button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote .ant-modal-body .ant-modal-confirm-btns button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-0 {
  margin-top: 0;
}
.tooltipFormat p {
  margin-bottom: 0;
}
.bgWhite {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue:hover,
.bgPrimaryBlue:focus,
.bgPrimaryBlue:active {
  background: #4f7ac7;
}
.bgDarkBlue {
  background-color: #023768 !important;
}
.bgDarkBlue:hover,
.bgDarkBlue:focus,
.bgDarkBlue:active {
  background: #194d7d;
}
.bgGreyBlue {
  background-color: #ebf0f9;
}
.bgLightBlue {
  background-color: #b2cbf7 !important;
}
.bgLightRed {
  background-color: #e4453a;
}
.bgLightRed:hover,
.bgLightRed:focus,
.bgLightRed:active {
  background: #e17871;
}
.bgLightYellow {
  background-color: #ffd603;
}
.bgDarkGray {
  background-color: #333333;
}
.bgMediumGray {
  background-color: #a2a2a2;
}
.bgLightGray {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue {
  color: #3368c7 !important;
}
.textDarkBlue {
  color: #023768 !important;
}
.textLightBlue {
  color: #b2cbf7;
}
.textDarkGray {
  color: #333333 !important;
}
.textMediumGray {
  color: #a2a2a2 !important;
}
.textLightGray {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded {
  border-radius: 5px !important;
}
.rounded-xs {
  border-radius: 10px !important;
}
.rounded-sm {
  border-radius: 15px !important;
}
.rounded-md {
  border-radius: 20px !important;
}
.rounded-lg {
  border-radius: 25px !important;
}
.rounded-full {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0 {
  margin: 0;
}
.mt-2 {
  margin-top: 0.5rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0 {
  padding: 0;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 20px;
}
.h-full {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold {
  font-weight: bold !important;
}
.fontWeight400 {
  font-weight: 400 !important;
}
.fontWeight500 {
  font-weight: 500 !important;
}
.fontWeight600 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12 {
  font-size: 12px !important;
}
.fontSize14 {
  font-size: 14px !important;
}
.fontSize16 {
  font-size: 16px !important;
}
.fontSize18 {
  font-size: 18px !important;
}
.fontSize20 {
  font-size: 20px !important;
}
.fontSize24 {
  font-size: 24px !important;
}
.eventName {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr.ant-table-row > td,
.ant-table-summary tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait .ant-table-thead > tr > th,
.table-portrait .ant-table-tbody > tr.ant-table-row > td,
.table-portrait .ant-table-summary tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait .bgLightGray td {
  background: #ebebeb;
}
.table-portrait .bgLightBlue td {
  background: #b2cbf7;
}
.eventContent .timeText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody > tr.bgLightGray:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #ebebeb;
}
.ant-table-tbody > tr.bgLightBlue:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #b2cbf7;
}
.ant-table-tbody > tr.bgWhite:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #ffffff;
}
