@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1p1FR {
  display: flex;
  justify-content: center;
}
button.btn___zuyqj {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___zuyqj {
    padding: 10px;
  }
}
button.btn___zuyqj:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___zuyqj:focus {
  outline: none;
}
.btnWhite___1tAil.btn___zuyqj {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1tAil.btn___zuyqj:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3OGbY.btn___zuyqj,
.btnGreen___3OGbY.btn___zuyqj:hover,
.btnGreen___3OGbY.btn___zuyqj:active,
.btnGreen___3OGbY.btn___zuyqj:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___cRr9- {
  color: #fe0000;
}
.errorMessage___2kVxR {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2ZrTN {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___sFgz7 {
  display: none !important;
}
.m-auto___1pEY6 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1gOeh {
  max-width: 120px !important;
}
.fc___LOnNl .fc-timegrid-col___3RWP3.fc-day-today___3O32_ {
  background-color: #ffffff;
}
.fc___LOnNl .fc-timegrid-slot___1cfyQ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___LOnNl .fc-timegrid-slot___1cfyQ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1GQA- .fc-direction-ltr___2W-n- .fc-timegrid-slot-label-frame___3QFXe {
    font-size: 10px;
  }
  .bookingCalendar___1GQA- .fc___LOnNl .fc-timegrid-slot-label-cushion___3OHqW {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1GQA- .fc___LOnNl .fc-scrollgrid-section-header___YH1d6 .fc-scroller___1THJ2 {
    overflow: unset !important;
  }
}
.ant-message-notice___106Q3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3pkcL {
  width: 20px;
  height: 20px;
}
.bgTransparent___1P6ob {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3rvlJ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2sWH2 {
  position: relative;
}
.inputCustomValue___2sWH2 .ant-input___1Ojnv {
  padding-right: 50px;
}
.inputCustomValueText___19pGE {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3ho9L .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3ho9L .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2z474 .ant-radio-inner___2thFL {
  border-color: black !important ;
}
.ant-radio-checked___2z474 .ant-radio-inner___2thFL:after {
  background-color: black;
}
.ant-radio___2u9dP:hover .ant-radio-inner___2thFL {
  border-color: black;
}
.ant-radio-checked___2z474 .ant-radio-inner___2thFL:focus {
  border-color: black;
}
.modal___StWOP {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___StWOP .close___HareT {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___StWOP .header___2_2Au {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___StWOP .header___2_2Au p {
  margin: 0;
}
.modal___StWOP .content___3jdH0 {
  text-align: center;
  font-size: 14px;
}
.modal___StWOP .content___3jdH0 p {
  margin: 0;
}
.modal___StWOP .actions___3SfIy {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___StWOP .actions___3SfIy .button___XdM_t {
  margin-top: 10px;
}
.modal___StWOP .actions___3SfIy:hover {
  color: #33c3c7;
}
.fc___LOnNl .fc-timegrid-slot-minor___1V0U4 {
  border-top-style: none;
}
.fc___LOnNl .fc-timegrid-slot___1cfyQ {
  height: 1.5em !important;
}
.messageError___2C6S9 .ant-message-custom-content___1EDYw {
  display: flex;
  text-align: start;
}
.messageError___2C6S9 .ant-message-custom-content___1EDYw span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1iwSY {
  background-color: #e6e4e4;
}
.notifyMessage___1dHI1 .ant-message-notice-content___HZH74 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1dHI1 .ant-message-notice-content___HZH74 .anticon___255vU {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1dHI1 .ant-message-notice-content___HZH74 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2Wygh {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2Wygh .ant-notification-notice-message___3SKR9,
.notifyCustomize___2Wygh .ant-notification-notice-close___35tt2,
.notifyCustomize___2Wygh .ant-notification-notice-icon___1y8r8 {
  color: white;
}
.notifyCustomize___2Wygh .ant-notification-notice-message___3SKR9 {
  margin-left: 35px;
}
.notifyCustomize___2Wygh p {
  margin-bottom: 0;
}
.notifyCustomize___2Wygh p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1nfQv {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1nfQv .ant-notification-notice-message___3SKR9 {
  margin-left: 35px;
}
.notifyAlertDuplicate___1nfQv p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1nfQv p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2gdjh {
  width: 434px !important;
}
.handleSubmitVote___2gdjh .ant-btn-primary___tvlkn {
  background: #59c3c7;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 .ant-modal-confirm-body___3dg_R {
  display: flex !important;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 .ant-modal-confirm-body___3dg_R .anticon___255vU {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 .ant-modal-confirm-btns___3hlXE button {
  font-weight: bold;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 .ant-modal-confirm-btns___3hlXE button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 .ant-modal-confirm-btns___3hlXE button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2gdjh .ant-modal-body___kM1o5 .ant-modal-confirm-btns___3hlXE .ant-btn-primary___tvlkn {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3wtKS {
  margin-bottom: 0;
}
.mt-0___-uByC {
  margin-top: 0;
}
.tooltipFormat___21O3r p {
  margin-bottom: 0;
}
.bgWhite___6gDTe {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2Vss_ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2Vss_:hover,
.bgPrimaryBlue___2Vss_:focus,
.bgPrimaryBlue___2Vss_:active {
  background: #4f7ac7;
}
.bgDarkBlue___3CkKA {
  background-color: #023768 !important;
}
.bgDarkBlue___3CkKA:hover,
.bgDarkBlue___3CkKA:focus,
.bgDarkBlue___3CkKA:active {
  background: #194d7d;
}
.bgGreyBlue___3lBDq {
  background-color: #ebf0f9;
}
.bgLightBlue___1Hut7 {
  background-color: #b2cbf7 !important;
}
.bgLightRed____Wpx2 {
  background-color: #e4453a;
}
.bgLightRed____Wpx2:hover,
.bgLightRed____Wpx2:focus,
.bgLightRed____Wpx2:active {
  background: #e17871;
}
.bgLightYellow___3LpEQ {
  background-color: #ffd603;
}
.bgDarkGray___25_4B {
  background-color: #333333;
}
.bgMediumGray___Ao3lx {
  background-color: #a2a2a2;
}
.bgLightGray___1Ubjk {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___OeSp7 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___W6K70 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1vOFe {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3HyxL {
  color: #3368c7 !important;
}
.textDarkBlue___21cAo {
  color: #023768 !important;
}
.textLightBlue___36mmD {
  color: #b2cbf7;
}
.textDarkGray___3cP22 {
  color: #333333 !important;
}
.textMediumGray___FSbUo {
  color: #a2a2a2 !important;
}
.textLightGray___RyZM5 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2Uplw {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___zknSE {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___eqJVq {
  border-radius: 5px !important;
}
.rounded-xs___1OpFz {
  border-radius: 10px !important;
}
.rounded-sm___1_C5T {
  border-radius: 15px !important;
}
.rounded-md___3UpUE {
  border-radius: 20px !important;
}
.rounded-lg___3TzWZ {
  border-radius: 25px !important;
}
.rounded-full___2ZdAl {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1kPaC {
  margin: 0;
}
.mt-2___7rcsG {
  margin-top: 0.5rem;
}
.my-2___2WM5A {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___nRFkt {
  padding: 0;
}
.px-1___2AKzU {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___dgIfz {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___30Jvz {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2EjY3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___231k9 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1irGe {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2Y863 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___ENTaI {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1ondo {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___140eS {
  padding-bottom: 20px;
}
.h-full___36bv- {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1NJlg {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___vDLTG {
  font-weight: bold !important;
}
.fontWeight400___vOQBZ {
  font-weight: 400 !important;
}
.fontWeight500___2SgZn {
  font-weight: 500 !important;
}
.fontWeight600___2kzSU {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1iZqS {
  font-size: 12px !important;
}
.fontSize14___1Wpjd {
  font-size: 14px !important;
}
.fontSize16___3wDJD {
  font-size: 16px !important;
}
.fontSize18___3vyyC {
  font-size: 18px !important;
}
.fontSize20___1pEw5 {
  font-size: 20px !important;
}
.fontSize24___1EDgP {
  font-size: 24px !important;
}
.eventName___vgDXy {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___SuMKQ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___USqQa > tr > th,
.ant-table-tbody___19dpS > tr.ant-table-row___1ldyA > td,
.ant-table-summary___UkybL tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___USqQa > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___SXSvo .ant-table-thead___USqQa > tr > th,
.table-portrait___SXSvo .ant-table-tbody___19dpS > tr.ant-table-row___1ldyA > td,
.table-portrait___SXSvo .ant-table-summary___UkybL tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___SXSvo .bgLightGray___1Ubjk td {
  background: #ebebeb;
}
.table-portrait___SXSvo .bgLightBlue___1Hut7 td {
  background: #b2cbf7;
}
.eventContent___CrlL8 .timeText___1jlsz {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___19dpS > tr.bgLightGray___1Ubjk:hover > td,
.ant-table-tbody___19dpS > tr > td.ant-table-cell-row-hover___3Hsxw {
  background: #ebebeb;
}
.ant-table-tbody___19dpS > tr.bgLightBlue___1Hut7:hover > td,
.ant-table-tbody___19dpS > tr > td.ant-table-cell-row-hover___3Hsxw {
  background: #b2cbf7;
}
.ant-table-tbody___19dpS > tr.bgWhite___6gDTe:hover > td,
.ant-table-tbody___19dpS > tr > td.ant-table-cell-row-hover___3Hsxw {
  background: #ffffff;
}
.confirmBooking___1QJes {
  padding: 60px 0 30px 0;
  font-weight: 500;
}
.confirmBooking___1QJes .mb_20___25WPJ {
  margin-bottom: 20px !important;
}
.confirmBooking___1QJes .mainContent___3RO1b {
  width: 100%;
  margin: auto;
  max-width: 1080px;
  position: relative;
  padding: 0 15px;
}
.confirmBooking___1QJes .stepCalendarTitle___3fggO {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.confirmBooking___1QJes .stepCalendarTitle___3fggO .titleIcon___bNCWX {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.confirmBooking___1QJes .stepCalendarTitle___3fggO .titleIcon___bNCWX .bolderColIcon___2agKy {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 8px;
  margin-right: 5px;
}
.confirmBooking___1QJes .stepCalendarTitle___3fggO .titleIcon___bNCWX .normalColIcon___372qQ {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 3px;
}
.confirmBooking___1QJes .stepCalendarTitle___3fggO h2 {
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}
.confirmBooking___1QJes .stepCalendarTitle___3fggO p {
  margin-bottom: 0;
}
.confirmBooking___1QJes .textLeft___1XeNw {
  margin-left: 2.2rem;
  font-size: 20px;
  margin-top: 1rem;
}
.confirmBooking___1QJes .textLeft___1XeNw .eventInfo___gC-Eb {
  font-size: 20px;
  color: #3d3d3d;
}
.confirmBooking___1QJes .textLeft___1XeNw .eventInfo___gC-Eb .locationLink___4gmY8 {
  color: #53acff;
  padding-left: 5px;
  word-break: break-all;
}
.confirmBooking___1QJes .textLeft___1XeNw .eventInfo___gC-Eb .notOpenLink___2myzl {
  color: #3d3d3d;
  cursor: text;
}
.confirmBooking___1QJes .textLeft___1XeNw .eventInfo___gC-Eb .meetingPlace___1lm-x {
  word-break: break-all;
}
.confirmBooking___1QJes .textLeft___1XeNw .eventInfoNoBottom___3APhE {
  margin-top: 1rem;
  color: #3d3d3d;
}
.confirmBooking___1QJes .textLeft___1XeNw .eventRegister___220mD {
  padding-top: 1.5rem;
}
.confirmBooking___1QJes .grid___3WL_9 {
  display: flex;
  margin: 2.3rem auto 3rem auto;
  justify-content: center;
}
.confirmBooking___1QJes .grid___3WL_9 div:first-child {
  margin-right: 3rem;
}
.confirmBooking___1QJes .grid___3WL_9 div a {
  border-color: transparent;
  background: #e8fafa;
  display: flex;
  height: auto;
  align-items: center;
  width: 370px;
  justify-content: center;
  border-radius: 6px;
  padding: 0.6rem 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.confirmBooking___1QJes .grid___3WL_9 div a img {
  padding-right: 0.5rem;
}
.confirmBooking___1QJes .grid___3WL_9 div a span {
  font-size: 18px;
  text-align: left;
  text-align: initial;
  width: auto;
  width: initial;
  font-weight: 500;
}
.confirmBooking___1QJes .grid___3WL_9 div a .textSM___1pbzl {
  display: none;
}
.confirmBooking___1QJes .grid___3WL_9 div a .linkSm___2IU75 {
  color: #33c3c7;
  margin-left: 5px;
  display: none;
}
.confirmBooking___1QJes .banner___25Olx {
  background: #33c3c7;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  padding: 0.5rem 0;
}
.confirmBooking___1QJes .banner___25Olx div {
  display: none;
}
.confirmBooking___1QJes .btnServices___4PCnu {
  margin: 2rem 0;
  text-align: center;
}
.confirmBooking___1QJes .btnServices___4PCnu div:first-child {
  margin-bottom: 1rem;
}
.confirmBooking___1QJes .btnServices___4PCnu button {
  font-size: 20px;
  height: auto;
  border-radius: 7px;
  padding: 0.3rem 3.5rem;
  width: 280px;
}
.confirmBooking___1QJes .btnServices___4PCnu button.btnRegister___21wNW {
  background: #33c3c7;
  border-color: transparent;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  font-weight: 500;
  transition: all 0.3s;
}
.confirmBooking___1QJes .btnServices___4PCnu button.btnRegister___21wNW:hover {
  color: rgba(0, 0, 0, 0.85);
}
.confirmBooking___1QJes .btnServices___4PCnu button.btnHome___2H2tg {
  background: #ffffff;
  border: 3px solid #33c3c7;
  color: #33c3c7;
}
.confirmBooking___1QJes .note___3PK66 {
  margin-left: 2.2rem;
}
.confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 {
  display: flex;
}
.confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p {
  font-size: 20px;
  margin-bottom: 0.5rem;
}
.confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p br {
  display: none;
}
.confirmBooking___1QJes .note___3PK66 .noteItemEnd___1dMm6 span {
  font-size: 20px;
}
.confirmBooking___1QJes .note___3PK66 .noteLast___1ZMsL {
  margin-top: 3.2rem;
  font-weight: bold;
  font-size: 25px;
}
.confirmBooking___1QJes .hidePc___3d2eP {
  display: none !important;
}
.confirmBooking___1QJes .inforConfirmSuccess___101RR {
  background: #33c3c7;
  color: #ffffff;
  padding: 1.7rem 1.9rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1.2rem;
  font-size: 20px;
}
.confirmBooking___1QJes .inforConfirmSuccess___101RR .comment___2F_Xw p a {
  color: white;
  font-weight: bold;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}
.confirmBooking___1QJes .inforConfirmSuccess___101RR .time___356iU {
  color: #252525;
  font-weight: bold;
  background: #eaeaea;
  display: inline-block;
  padding: 3px 8px;
}
.confirmBooking___1QJes .inforConfirmSuccess___101RR .time___356iU span {
  font-weight: normal;
  font-weight: initial;
}
.confirmBooking___1QJes .inforConfirmSuccess___101RR .descriptImage___3o_YV {
  padding-top: 1rem;
  margin-bottom: 0;
}
.confirmBooking___1QJes .description___2ghFG {
  font-weight: bold;
  font-size: 20px;
  margin-left: 2.2rem;
}
.confirmBooking___1QJes .suggestBanner___2Vbu6 {
  height: 123px;
  width: 100%;
  background-color: #eef8f8;
  color: #33c3c7;
  font-size: 20px;
  position: relative;
  margin-bottom: 110px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes .suggestBanner___2Vbu6 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .confirmBooking___1QJes .suggestBanner___2Vbu6 {
    font-size: 29px;
  }
}
.confirmBooking___1QJes .suggestBanner___2Vbu6 .mainContent___3RO1b {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.confirmBooking___1QJes .suggestBanner___2Vbu6 .mainContent___3RO1b span {
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes .suggestBanner___2Vbu6 .mainContent___3RO1b span {
    font-size: 20px;
  }
}
.confirmBooking___1QJes .suggestBanner___2Vbu6 .freeTrialBanner___3sqJj {
  font-size: 16px;
  color: #ffffff;
  background-color: #33c3c7;
  position: absolute;
  right: 38px;
  bottom: -23px;
  height: 46px;
  padding: 0 40px 0 42px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes .suggestBanner___2Vbu6 .freeTrialBanner___3sqJj {
    font-size: 13px;
    right: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}
.confirmBooking___1QJes .suggestBanner___2Vbu6 .freeTrialBanner___3sqJj .freeTrialBannerContent___1Zg3D {
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  border-right: 30px solid #33c3c7;
  position: absolute;
  left: -30px;
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes .suggestBanner___2Vbu6 .freeTrialBanner___3sqJj .freeTrialBannerContent___1Zg3D {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
}
.confirmBooking___1QJes .btnZone___28AZl {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmBooking___1QJes .btnZone___28AZl .registerBtn___3HhKr {
  margin-top: 50px;
  height: 88px;
  width: 550px;
  border-radius: 10px;
  background-color: #e3f4f4;
  font-size: 32px;
  color: #666666;
  border: none;
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes .btnZone___28AZl .registerBtn___3HhKr {
    max-width: 550px;
    width: 100%;
    padding: 0 15px;
    font-size: 20px;
    height: 60px;
  }
}
.confirmBooking___1QJes .btnZone___28AZl .backToHomeBtn___29qBp {
  margin: 35px 0 0 0;
  height: 88px;
  width: 550px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 32px;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes .btnZone___28AZl .backToHomeBtn___29qBp {
    max-width: 550px;
    width: 100%;
    padding: 0 15px;
    height: 60px;
    font-size: 20px;
  }
}
.confirmBooking___1QJes .ant-btn > span {
  display: block;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .confirmBooking___1QJes .banner___25Olx {
    margin: 0 -15px;
  }
}
@media screen and (max-width: 872px) {
  .confirmBooking___1QJes .grid___3WL_9 div a {
    width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .confirmBooking___1QJes {
    padding: 20px 0 0 0;
  }
  .confirmBooking___1QJes .mainContent___3RO1b {
    padding: 0 25px;
  }
  .confirmBooking___1QJes .textLeft___1XeNw {
    margin-left: 12px;
  }
  .confirmBooking___1QJes .textLeft___1XeNw .eventInfo___gC-Eb {
    margin-bottom: 0;
  }
  .confirmBooking___1QJes .stepCalendarTitle___3fggO {
    flex-wrap: nowrap;
    margin-bottom: 2rem;
  }
  .confirmBooking___1QJes .stepCalendarTitle___3fggO .titleIcon___bNCWX {
    margin-right: 10px;
  }
  .confirmBooking___1QJes .hidePc___3d2eP {
    display: block !important;
  }
  .confirmBooking___1QJes .note___3PK66 {
    margin-left: 12px;
  }
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 .hideSm___1ijt_ {
    display: none;
  }
  .confirmBooking___1QJes .inforConfirmSuccess___101RR {
    margin-left: 0;
    padding: 1.7rem 1.4rem;
  }
  .confirmBooking___1QJes .inforConfirmSuccess___101RR .comment___2F_Xw p span {
    display: none;
  }
  .confirmBooking___1QJes .description___2ghFG {
    margin-left: 12px;
  }
  .confirmBooking___1QJes .banner___25Olx {
    margin: 0 -25px;
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
  }
}
@media screen and (max-width: 710px) {
  .confirmBooking___1QJes .grid___3WL_9 {
    flex-direction: column;
    margin-top: 1rem;
  }
  .confirmBooking___1QJes .grid___3WL_9 div {
    margin: auto !important;
  }
  .confirmBooking___1QJes .grid___3WL_9 div:first-child {
    margin-bottom: 1rem !important;
  }
  .confirmBooking___1QJes .grid___3WL_9 div a {
    width: 390px;
  }
  .confirmBooking___1QJes .grid___3WL_9 div a .textSM___1pbzl {
    display: block;
  }
  .confirmBooking___1QJes .grid___3WL_9 div a .textPc___ekOrI {
    display: none;
  }
  .confirmBooking___1QJes .grid___3WL_9 div a .linkSm___2IU75 {
    display: block;
  }
  .confirmBooking___1QJes .banner___25Olx div {
    display: block;
  }
}
@media screen and (max-width: 590px) {
  .confirmBooking___1QJes .textLeft___1XeNw .eventRegister___220mD {
    text-align: center;
  }
}
@media screen and (max-width: 510px) {
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p,
  .confirmBooking___1QJes .note___3PK66 .noteLast___1ZMsL,
  .confirmBooking___1QJes .noteItemEnd___1dMm6 p {
    font-size: 16px;
  }
  .confirmBooking___1QJes .inforConfirmSuccess___101RR {
    font-size: 15px;
    padding: 1.7rem 1.1rem;
  }
  .confirmBooking___1QJes .description___2ghFG {
    font-size: 15px;
  }
}
@media screen and (max-width: 425px) {
  .confirmBooking___1QJes .mainContent___3RO1b {
    padding: 0 12px;
  }
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p,
  .confirmBooking___1QJes .note___3PK66 .noteLast___1ZMsL,
  .confirmBooking___1QJes .noteItemEnd___1dMm6 p {
    font-size: 14px;
  }
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p br {
    display: unset;
  }
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 span {
    font-size: 14px;
  }
  .confirmBooking___1QJes .inforConfirmSuccess___101RR {
    font-size: 14px;
  }
  .confirmBooking___1QJes .textLeft___1XeNw .eventRegister___220mD {
    font-size: 14px;
  }
  .confirmBooking___1QJes .textLeft___1XeNw .eventInfo___gC-Eb {
    font-size: 16px;
  }
  .confirmBooking___1QJes .textLeft___1XeNw .eventInfoNoBottom___3APhE {
    font-size: 17px;
  }
  .confirmBooking___1QJes .grid___3WL_9 div {
    width: 100%;
  }
  .confirmBooking___1QJes .grid___3WL_9 div a {
    width: unset !important;
  }
  .confirmBooking___1QJes .grid___3WL_9 div a span {
    font-size: 14px;
  }
  .confirmBooking___1QJes .banner___25Olx {
    font-size: 1.3rem;
    margin: 0 -12px;
    padding: 0.5rem 0;
  }
}
@media screen and (max-width: 375px) {
  .confirmBooking___1QJes .grid___3WL_9 div a {
    width: 335px;
  }
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p {
    font-size: 13px;
  }
  .confirmBooking___1QJes .note___3PK66 .noteItem___8ZfK0 p br {
    display: unset;
  }
}
