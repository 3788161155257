@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___29ciD {
  display: flex;
  justify-content: center;
}
button.btn___1xsG7 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1xsG7 {
    padding: 10px;
  }
}
button.btn___1xsG7:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1xsG7:focus {
  outline: none;
}
.btnWhite___1v1L1.btn___1xsG7 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1v1L1.btn___1xsG7:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3WwPQ.btn___1xsG7,
.btnGreen___3WwPQ.btn___1xsG7:hover,
.btnGreen___3WwPQ.btn___1xsG7:active,
.btnGreen___3WwPQ.btn___1xsG7:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___11wff {
  color: #fe0000;
}
.errorMessage___rL9r9 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2xUrx {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___mzoxm {
  display: none !important;
}
.m-auto___2vrFD {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___30nGK {
  max-width: 120px !important;
}
.fc___2PxzD .fc-timegrid-col___TKoik.fc-day-today____GVrI {
  background-color: #ffffff;
}
.fc___2PxzD .fc-timegrid-slot___2yULh {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2PxzD .fc-timegrid-slot___2yULh {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___20D3L .fc-direction-ltr___2t5CP .fc-timegrid-slot-label-frame___28O0P {
    font-size: 10px;
  }
  .bookingCalendar___20D3L .fc___2PxzD .fc-timegrid-slot-label-cushion___AYfaO {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___20D3L .fc___2PxzD .fc-scrollgrid-section-header___1K1HS .fc-scroller___3-ZaX {
    overflow: unset !important;
  }
}
.ant-message-notice___JgOij {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2pVQA {
  width: 20px;
  height: 20px;
}
.bgTransparent___2ehKG {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3U8p4 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___d4Nbe {
  position: relative;
}
.inputCustomValue___d4Nbe .ant-input___1jqkC {
  padding-right: 50px;
}
.inputCustomValueText___3Pk7l {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2zM-w .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2zM-w .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2kjEi .ant-radio-inner___20cyz {
  border-color: black !important ;
}
.ant-radio-checked___2kjEi .ant-radio-inner___20cyz:after {
  background-color: black;
}
.ant-radio___DXZzm:hover .ant-radio-inner___20cyz {
  border-color: black;
}
.ant-radio-checked___2kjEi .ant-radio-inner___20cyz:focus {
  border-color: black;
}
.modal___2py-b {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2py-b .close___hZ-Zs {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2py-b .header___2oPhG {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2py-b .header___2oPhG p {
  margin: 0;
}
.modal___2py-b .content___15VGj {
  text-align: center;
  font-size: 14px;
}
.modal___2py-b .content___15VGj p {
  margin: 0;
}
.modal___2py-b .actions___YZOHd {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2py-b .actions___YZOHd .button___2LEOH {
  margin-top: 10px;
}
.modal___2py-b .actions___YZOHd:hover {
  color: #33c3c7;
}
.fc___2PxzD .fc-timegrid-slot-minor___3iUhX {
  border-top-style: none;
}
.fc___2PxzD .fc-timegrid-slot___2yULh {
  height: 1.5em !important;
}
.messageError___wNpgk .ant-message-custom-content___1E9Cs {
  display: flex;
  text-align: start;
}
.messageError___wNpgk .ant-message-custom-content___1E9Cs span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2tGyG {
  background-color: #e6e4e4;
}
.notifyMessage___22h9U .ant-message-notice-content___3-c9J {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___22h9U .ant-message-notice-content___3-c9J .anticon___33pfu {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___22h9U .ant-message-notice-content___3-c9J span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___h81ba {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___h81ba .ant-notification-notice-message___2U6sN,
.notifyCustomize___h81ba .ant-notification-notice-close___22qRR,
.notifyCustomize___h81ba .ant-notification-notice-icon___3APlM {
  color: white;
}
.notifyCustomize___h81ba .ant-notification-notice-message___2U6sN {
  margin-left: 35px;
}
.notifyCustomize___h81ba p {
  margin-bottom: 0;
}
.notifyCustomize___h81ba p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___95W1i {
  padding: 16px 14px;
}
.notifyAlertDuplicate___95W1i .ant-notification-notice-message___2U6sN {
  margin-left: 35px;
}
.notifyAlertDuplicate___95W1i p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___95W1i p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___n_Ku- {
  width: 434px !important;
}
.handleSubmitVote___n_Ku- .ant-btn-primary___2YzeZ {
  background: #59c3c7;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy {
  padding: 15px 30px 18px;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy .ant-modal-confirm-body___1p92r {
  display: flex !important;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy .ant-modal-confirm-body___1p92r .anticon___33pfu {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy .ant-modal-confirm-btns___2qY78 button {
  font-weight: bold;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy .ant-modal-confirm-btns___2qY78 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy .ant-modal-confirm-btns___2qY78 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___n_Ku- .ant-modal-body___zlROy .ant-modal-confirm-btns___2qY78 .ant-btn-primary___2YzeZ {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1aNI_ {
  margin-bottom: 0;
}
.mt-0___1V9Yo {
  margin-top: 0;
}
.tooltipFormat___1aynX p {
  margin-bottom: 0;
}
.bgWhite___3koIy {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1HKcz {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1HKcz:hover,
.bgPrimaryBlue___1HKcz:focus,
.bgPrimaryBlue___1HKcz:active {
  background: #4f7ac7;
}
.bgDarkBlue___3fTzc {
  background-color: #023768 !important;
}
.bgDarkBlue___3fTzc:hover,
.bgDarkBlue___3fTzc:focus,
.bgDarkBlue___3fTzc:active {
  background: #194d7d;
}
.bgGreyBlue___3eiLG {
  background-color: #ebf0f9;
}
.bgLightBlue___1br3s {
  background-color: #b2cbf7 !important;
}
.bgLightRed___bWHbH {
  background-color: #e4453a;
}
.bgLightRed___bWHbH:hover,
.bgLightRed___bWHbH:focus,
.bgLightRed___bWHbH:active {
  background: #e17871;
}
.bgLightYellow___1bxyt {
  background-color: #ffd603;
}
.bgDarkGray___2IDEo {
  background-color: #333333;
}
.bgMediumGray___h7vwp {
  background-color: #a2a2a2;
}
.bgLightGray___2eBHd {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1Zdlm {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3UTmi {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2Aumt {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1Rwsk {
  color: #3368c7 !important;
}
.textDarkBlue___1nSOb {
  color: #023768 !important;
}
.textLightBlue___N61iy {
  color: #b2cbf7;
}
.textDarkGray___3dzTw {
  color: #333333 !important;
}
.textMediumGray___2Lpte {
  color: #a2a2a2 !important;
}
.textLightGray___3BEF1 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___Ye0mg {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___FdF6h {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___nbQ80 {
  border-radius: 5px !important;
}
.rounded-xs___1gmo3 {
  border-radius: 10px !important;
}
.rounded-sm___2LgAu {
  border-radius: 15px !important;
}
.rounded-md___2dKRM {
  border-radius: 20px !important;
}
.rounded-lg___3llEY {
  border-radius: 25px !important;
}
.rounded-full___2zipR {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1KaOD {
  margin: 0;
}
.mt-2___1zXmJ {
  margin-top: 0.5rem;
}
.my-2___-pOQA {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___18v-_ {
  padding: 0;
}
.px-1___37ol9 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___NitXP {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___nKHXu {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___jMROf {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3Srtq {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2b82P {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3NF3i {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___q7uos {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___n0G5s {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___b-320 {
  padding-bottom: 20px;
}
.h-full___SZHSR {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___151GA {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1O7O9 {
  font-weight: bold !important;
}
.fontWeight400___7kwBt {
  font-weight: 400 !important;
}
.fontWeight500___W_uOb {
  font-weight: 500 !important;
}
.fontWeight600___2H9Q- {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___ywrz5 {
  font-size: 12px !important;
}
.fontSize14___DDscp {
  font-size: 14px !important;
}
.fontSize16___17zW0 {
  font-size: 16px !important;
}
.fontSize18___34bqS {
  font-size: 18px !important;
}
.fontSize20___OL9SQ {
  font-size: 20px !important;
}
.fontSize24___22T7l {
  font-size: 24px !important;
}
.eventName___3ONd2 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1pJuo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3wz0u > tr > th,
.ant-table-tbody___2l-Ct > tr.ant-table-row___1f7u- > td,
.ant-table-summary___21M0H tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3wz0u > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1Ibiu .ant-table-thead___3wz0u > tr > th,
.table-portrait___1Ibiu .ant-table-tbody___2l-Ct > tr.ant-table-row___1f7u- > td,
.table-portrait___1Ibiu .ant-table-summary___21M0H tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1Ibiu .bgLightGray___2eBHd td {
  background: #ebebeb;
}
.table-portrait___1Ibiu .bgLightBlue___1br3s td {
  background: #b2cbf7;
}
.eventContent___2bMoE .timeText___2e2Zp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2l-Ct > tr.bgLightGray___2eBHd:hover > td,
.ant-table-tbody___2l-Ct > tr > td.ant-table-cell-row-hover___15m6B {
  background: #ebebeb;
}
.ant-table-tbody___2l-Ct > tr.bgLightBlue___1br3s:hover > td,
.ant-table-tbody___2l-Ct > tr > td.ant-table-cell-row-hover___15m6B {
  background: #b2cbf7;
}
.ant-table-tbody___2l-Ct > tr.bgWhite___3koIy:hover > td,
.ant-table-tbody___2l-Ct > tr > td.ant-table-cell-row-hover___15m6B {
  background: #ffffff;
}
@media screen and (max-width: 767px) {
  .bookingBottom___17rc4 {
    padding-bottom: 0;
  }
}
.mainLayout___3dln5 {
  background-color: #fefefe;
}
.mainLayout___3dln5 .mainContent___3cKJG {
  width: 100%;
  margin: auto;
  max-width: 1300px;
  padding: 0 15px;
}
@media screen and (max-width: 1300px) {
  .mainLayout___3dln5 .mainContent___3cKJG {
    width: 100% !important;
    padding: 0;
  }
  .mainLayout___3dln5 .mainContent___3cKJG .bookingCalendar___20D3L {
    box-shadow: none;
  }
}
.bookingStep1___3z34a {
  font-weight: 500;
}
.scheduleAdjustment___2z--L {
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L {
    padding: 0;
  }
}
.scheduleAdjustment___2z--L .bigTitle___RyMBW {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.scheduleAdjustment___2z--L .bigTitle___RyMBW .bolderIcon___BrVqm {
  width: 10px;
  height: 36px;
  background-color: #33c3c7;
  margin-right: 5px;
  display: inline-block;
}
.scheduleAdjustment___2z--L .bigTitle___RyMBW .titleIcon___3yXsf {
  width: 4px;
  height: 36px;
  background-color: #33c3c7;
  margin-right: 17px;
  display: inline-block;
}
.scheduleAdjustment___2z--L .bigTitle___RyMBW span {
  font-size: 24px;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___2z--L .bigTitle___RyMBW span {
    font-size: 16px;
  }
}
.scheduleAdjustment___2z--L .secondStepHeader___2e4E0 .searchKeyword___2nl7h textarea {
  padding: 8px 10px;
  background: #fbfbfb;
  border: 2px solid #ebebeb;
  border-radius: 11px;
  width: 100%;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ {
  display: flex;
  margin-top: 1rem;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountBorder___1H1M6 {
  display: inline-block;
  width: 9px;
  height: 37px;
  background: #3c3c3c;
  margin-right: 10px;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL {
  font-size: 24px;
  color: #3c3c3c;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL br {
  display: none;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL img {
  background: #f0f0f0;
  height: 32px;
  width: 32px;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL img:not(:last-child) {
  margin-right: 0.4rem;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL .imgCircle___2iWv8 {
  padding: 6px;
  border-radius: 50%;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL .imgTriangle___1xwTq {
  padding: 7px;
  border-radius: 48%;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountTitle___2-cqL .imgX___2PlQc {
  padding: 7px;
  border-radius: 50%;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .numberAccountDescription___3CRyE {
  font-size: 1rem;
  color: #a1a0a0;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .pinnedText___3WiMo {
  display: none;
  font-size: 18px;
  margin: 10px 0 15px 0;
  justify-content: flex-start;
  align-items: center;
}
.scheduleAdjustment___2z--L .numberAccountContent___bzoP_ .pinnedText___3WiMo img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L .numberAccountContent___bzoP_ {
    display: none;
  }
}
.scheduleAdjustment___2z--L .intruction___sOgqv {
  font-size: 21px;
  color: #414141;
  margin: 18px 0 24px 0;
  padding-left: 17px;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L .intruction___sOgqv {
    border-left-width: 5px;
    font-size: 16px;
  }
}
.scheduleAdjustment___2z--L .progressBar___1guLe > div {
  padding: 0 55px;
  display: flex;
  position: relative;
  margin: auto;
  justify-content: space-between;
  max-width: 63%;
}
.scheduleAdjustment___2z--L .progressBar___1guLe > div::after {
  content: '';
  position: absolute;
  top: 90%;
  left: 95px;
  transform: translateY(-50%);
  width: calc(100% - 190px);
  height: 100%;
  padding: 0 35px;
  font-size: 20px;
  line-height: 1;
  color: #a1a0a0;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjYAAAAECAYAAACdm3W6AAAA/ElEQVRoge2YsRHCMAxFHxmFFWAEskJauwojhBEYw2lhBVZghayQFWhc5NLENjYXGan1k+6/O6nxYXTuBDyACeiMtTMbNTo3AD3wMtZeC/BRmUrziQ67cv6Rgzpv8zU4633KdPhHZ91VmQ5fOTfAHTgCFz8opAbf04/OXQrwsZlK8yDfOSWTOufna3DW+8zPp2RS5/yZ1Dmsdr2rDfBePE4BA5bcvOrPxcdmKs0vOanOKZnUOT9fg7PeZ34+JZM658+kzmG1611tjLU3oANaY+0zYABA63vOId9WsXxsptK8L9HOKZlK8+os01nvU6ZDLF+Ds+6qTIdYfu3wAZJTby0ImNz8AAAAAElFTkSuQmCC);
  background-size: contain;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L .progressBar___1guLe > div::after {
    left: 40px;
    width: calc(100% - 120px);
  }
}
.scheduleAdjustment___2z--L .progressBar___1guLe > div .currentStep___bRe8o {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #33c3c7;
  border: 1px solid #33c3c7;
  font-size: 14px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.scheduleAdjustment___2z--L .progressBar___1guLe > div .currentStep___bRe8o p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 7px);
  font-size: 16px;
  min-width: 190px;
  text-align: center;
  font-weight: 500;
  color: #3c3c3c;
}
.scheduleAdjustment___2z--L .progressBar___1guLe > div .activeStep___2SCQx {
  background-color: #33c3c7 !important;
  color: #eff6fc !important;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L .progressBar___1guLe {
    padding-top: 12px;
    padding-bottom: 40px;
    background-color: #f6f6f6;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div {
    padding: 0 70px 0 35px;
    max-width: 75%;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div::after {
    left: 40px;
    width: calc(100% - 120px);
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div .currentStep___bRe8o p {
    font-size: 0.8rem;
    margin-bottom: 0;
    font-weight: 600;
    color: #bebebe;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div .activeStep___2SCQx p {
    color: #414141 !important;
    opacity: 1 !important;
  }
}
@media screen and (max-width: 425px) {
  .scheduleAdjustment___2z--L .progressBar___1guLe {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div {
    max-width: 85%;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div::after {
    top: 100%;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div .currentStep___bRe8o {
    width: 21px;
    height: 21px;
    font-size: 13px;
  }
  .scheduleAdjustment___2z--L .progressBar___1guLe > div .currentStep___bRe8o p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}
.scheduleAdjustment___2z--L .pinnedText___3WiMo {
  font-size: 16px;
  margin: 0 0 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___2z--L .pinnedText___3WiMo {
    margin: 0 0 15px 0;
  }
}
.scheduleAdjustment___2z--L .pinnedText___3WiMo img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.scheduleAdjustment___2z--L .pinnedTextSecond___JHRR8 {
  margin: 0 0 15px 0;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI {
  margin-top: 32px;
  padding-top: 25px;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .meetingInfo___2RfvC {
  position: relative;
  padding-bottom: 1.2rem;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .meetingInfo___2RfvC .meetingMethod___1DMWf {
  word-break: break-all;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .meetingInfo___2RfvC .meetingDetail___3rJYQ .timeBooking___11ecC {
  color: #33c3c7;
  padding-bottom: 0.5rem;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .meetingComment___2Fo5W .pinnedText___3WiMo {
  align-items: initial;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .meetingComment___2Fo5W .pinnedText___3WiMo img {
  margin-top: 5px;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .bigTitle___RyMBW {
  margin-bottom: 37px;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___2z--L .firstStepDetail___1A1cI .bigTitle___RyMBW {
    margin-bottom: 15px;
    flex-wrap: nowrap;
    line-height: 1.3;
  }
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt p {
  margin: 0;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt .infoCompany___HdWNZ {
  font-size: 17px;
  margin-bottom: 12px;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt .avatar___2AljI {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  overflow: hidden;
  position: relative;
  border: 2px solid #33c3c7;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt .avatar___2AljI {
    width: 50px;
    height: 50px;
  }
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt .avatar___2AljI img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-position: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .userInfo___37ABt .infoName___2kriJ {
  font-size: 13px;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR {
  padding: 25px 10px;
  box-shadow: 1px 1px 10px #b3bcbc60;
  border-radius: 20px;
  min-height: 420px;
  height: 430px;
  overflow-y: auto;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR::-webkit-scrollbar {
  display: none;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR .listFrame___3ledE {
  height: 390px;
  width: 100%;
  overflow-y: auto;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR .listFrame___3ledE::-webkit-scrollbar {
  display: none;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR .listFrame___3ledE .frame___3o2GD {
  height: 40px;
  min-width: 160px;
  margin-bottom: 14px;
  border: 2px solid #dadada;
  color: #414141;
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR .listFrame___3ledE .frame___3o2GD:hover {
  border: 3px solid #33c3c7;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR .listFrame___3ledE .chosenFrame___rlkgI {
  border: 3px solid #33c3c7;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .listTimeFrame___20BCR .listFrame___3ledE .completedFrame___19UUD {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.scheduleAdjustment___2z--L .firstStepDetail___1A1cI .boxBooking___3shcx {
  padding-left: 0.7rem;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L .firstStepDetail___1A1cI .boxInforUser___3Hc4r {
    display: none;
  }
  .scheduleAdjustment___2z--L .firstStepDetail___1A1cI .boxBooking___3shcx {
    max-width: 100% !important;
    width: 100% !important;
    flex: 0 0 100% !important;
    padding-left: 0;
  }
}
@media screen and (max-width: 375px) {
  .scheduleAdjustment___2z--L .firstStepDetail___1A1cI {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___2z--L .secondStepDetail___3lOPU {
    margin-top: 1px;
    padding-top: 0;
  }
}
.scheduleAdjustment___2z--L .btnNext___2fiqc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 0 25px 0;
}
.scheduleAdjustment___2z--L .btnNext___2fiqc button {
  min-width: 146px;
  height: 48px;
  background-color: #33c3c7;
  border-radius: 20px;
  font-size: 18px;
  color: #ffffff;
}
.scheduleAdjustment___2z--L .mt10___1k8uI {
  margin-top: 10px;
}
.scheduleAdjustment___2z--L .mt15___2zxb1 {
  margin-top: 15px;
}
.scheduleAdjustment___2z--L .mt7___19htl {
  margin-top: 7px;
}
.scheduleAdjustmentModal___2q2U1 {
  max-width: 350px;
  margin: auto;
}
.scheduleAdjustmentModal___2q2U1 .firstStepDetail___1A1cI {
  margin-top: 0;
  padding-top: 0;
}
.scheduleAdjustmentModal___2q2U1 .firstStepDetail___1A1cI .userInfo___37ABt {
  margin-bottom: 0;
}
.scheduleAdjustmentModal___2q2U1 .firstStepDetail___1A1cI .meetingInfo___2RfvC {
  padding-bottom: 0;
}
.scheduleAdjustmentModal___2q2U1 .dottedBar___a2udS {
  font-size: 20px;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  line-height: 1;
  color: #a1a0a0;
  padding: 15px 0;
}
.closeBtn___1EFt5 {
  position: absolute;
  top: -38px;
  right: -2px;
}
.modalTitle___2-Vlp {
  background-color: #efefef;
  padding: 24px;
  font-size: 16px;
}
.modalTitle___2-Vlp span {
  color: #33c3c7;
}
.modalBody___3xJjB {
  padding: 24px;
  min-height: calc(100vh - 135px);
}
.modalBody___3xJjB .userInfo___37ABt {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.modalBody___3xJjB .userInfo___37ABt p {
  margin: 0;
}
.modalBody___3xJjB .userInfo___37ABt .infoCompany___HdWNZ {
  font-size: 17px;
  margin-bottom: 12px;
}
.modalBody___3xJjB .userInfo___37ABt .avatar___2AljI {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  overflow: hidden;
  position: relative;
  border: 2px solid #33c3c7;
}
@media screen and (max-width: 767px) {
  .modalBody___3xJjB .userInfo___37ABt .avatar___2AljI {
    width: 50px;
    height: 50px;
  }
}
.modalBody___3xJjB .userInfo___37ABt .avatar___2AljI img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-position: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.modalBody___3xJjB .userInfo___37ABt .infoName___2kriJ {
  font-size: 13px;
}
.modalBody___3xJjB .pinnedText___3WiMo {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  font-weight: bold;
  font-size: 16px;
}
.modalBody___3xJjB .pinnedText___3WiMo img {
  position: absolute;
  left: 0;
}
.modalBody___3xJjB .meetingInfo___2RfvC .meetingDetail___3rJYQ {
  padding-left: 32px;
}
.modalBody___3xJjB .meetingInfo___2RfvC .meetingDetail___3rJYQ p {
  margin-bottom: 0;
}
.modalBody___3xJjB .meetingInfo___2RfvC .meetingDetail___3rJYQ p span {
  color: #33c3c7;
}
.modalBody___3xJjB .meetingComment___2Fo5W .comment___ar4Dt {
  padding-left: 32px;
  white-space: pre-line;
}
.modalBody___3xJjB .modalBtn___2bw0Z {
  background-color: #33c3c7;
  color: white;
  margin-top: 16px;
  font-weight: 600;
}
.eventTitle___3EFxq {
  display: none;
}
@media screen and (max-width: 767px) {
  .eventTitle___3EFxq {
    color: #414141;
    display: flex;
    align-items: flex-start;
    padding: 16px 8px;
  }
  .eventTitle___3EFxq .titleIcon___3yXsf {
    margin-right: 8px;
    display: flex;
    height: 32px;
  }
  .eventTitle___3EFxq .titleIcon___3yXsf .bolderColIcon___1HbXM {
    display: inline-block;
    background-color: #33c3c7;
    width: 6px;
    margin-right: 2px;
  }
  .eventTitle___3EFxq .titleIcon___3yXsf .normalColIcon___2o77m {
    display: inline-block;
    background-color: #33c3c7;
    width: 2px;
  }
  .eventTitle___3EFxq p {
    margin: 0;
    font-size: 20px;
  }
}
@media screen and (max-width: 425px) {
  .eventTitle___3EFxq .titleIcon___3yXsf {
    height: 24px;
  }
  .eventTitle___3EFxq p {
    font-size: 16px;
    font-weight: 600;
  }
  .eventTitle___3EFxq p br {
    display: unset;
  }
}
@media screen and (max-width: 375px) {
  .eventTitle___3EFxq {
    padding: 10px 8px;
  }
}
.footerNotLoginMobile___2SuU5 {
  display: none;
}
@media screen and (max-width: 767px) {
  .footerNotLoginMobile___2SuU5 {
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    background: white;
    position: fixed;
    bottom: 0;
    z-index: 100;
    padding: 16px 24px;
  }
  .footerNotLoginMobile___2SuU5 p {
    margin: 0;
  }
  .footerNotLoginMobile___2SuU5 div {
    margin-left: 16px;
  }
  .footerNotLoginMobile___2SuU5 div button {
    min-width: 100px;
    height: auto;
    font-weight: lighter;
    border-radius: 7px;
    font-size: 12px;
    white-space: pre-line;
  }
  .footerNotLoginMobile___2SuU5 div button:hover {
    border: 3px solid #33c3c7;
  }
  .footerNotLoginMobile___2SuU5 div button:first-child {
    background-color: #33c3c7;
    color: #ffffff;
    padding: 8px 16px !important;
  }
}
@media screen and (max-width: 425px) {
  .footerNotLoginMobile___2SuU5 {
    padding: 16px 10px;
  }
  .footerNotLoginMobile___2SuU5 p {
    font-size: 13px;
  }
  .footerNotLoginMobile___2SuU5 div {
    margin-left: 5px;
  }
  .footerNotLoginMobile___2SuU5 div button {
    min-width: 100px;
    height: auto;
    font-weight: lighter;
    border-radius: 7px;
    font-size: 11px;
    white-space: pre-line;
  }
  .footerNotLoginMobile___2SuU5 div button:hover {
    border: 3px solid #33c3c7;
  }
  .footerNotLoginMobile___2SuU5 div button:first-child {
    background-color: #33c3c7;
    color: #ffffff;
    padding: 8px 4px !important;
  }
}
.btnColor___287A0 {
  background-color: #00b6d3 !important;
}
