@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1htjO {
  display: flex;
  justify-content: center;
}
button.btn___18NcE {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___18NcE {
    padding: 10px;
  }
}
button.btn___18NcE:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___18NcE:focus {
  outline: none;
}
.btnWhite___3B2_e.btn___18NcE {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3B2_e.btn___18NcE:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___13j_H.btn___18NcE,
.btnGreen___13j_H.btn___18NcE:hover,
.btnGreen___13j_H.btn___18NcE:active,
.btnGreen___13j_H.btn___18NcE:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___26Wnt {
  color: #fe0000;
}
.errorMessage___3tWCM {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___bKsv9 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___QVBTw {
  display: none !important;
}
.m-auto___1t9j8 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___jAl11 {
  max-width: 120px !important;
}
.fc____4s7J .fc-timegrid-col___1-pyE.fc-day-today___3ewNe {
  background-color: #ffffff;
}
.fc____4s7J .fc-timegrid-slot___3n_3h {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc____4s7J .fc-timegrid-slot___3n_3h {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2HCbQ .fc-direction-ltr___Q0NHn .fc-timegrid-slot-label-frame___jCgMp {
    font-size: 10px;
  }
  .bookingCalendar___2HCbQ .fc____4s7J .fc-timegrid-slot-label-cushion___34kVv {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2HCbQ .fc____4s7J .fc-scrollgrid-section-header___2baxD .fc-scroller___3Duhy {
    overflow: unset !important;
  }
}
.ant-message-notice___2VjKA {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1UCnA {
  width: 20px;
  height: 20px;
}
.bgTransparent___2aNTI {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___15e_8 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2qCSV {
  position: relative;
}
.inputCustomValue___2qCSV .ant-input___23diY {
  padding-right: 50px;
}
.inputCustomValueText___1brMs {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3MBvb .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3MBvb .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1pt1H .ant-radio-inner___1LjgR {
  border-color: black !important ;
}
.ant-radio-checked___1pt1H .ant-radio-inner___1LjgR:after {
  background-color: black;
}
.ant-radio___1qLZV:hover .ant-radio-inner___1LjgR {
  border-color: black;
}
.ant-radio-checked___1pt1H .ant-radio-inner___1LjgR:focus {
  border-color: black;
}
.modal___3WPWR {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3WPWR .close___1fWMa {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3WPWR .header___1axPD {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3WPWR .header___1axPD p {
  margin: 0;
}
.modal___3WPWR .content___1ErEU {
  text-align: center;
  font-size: 14px;
}
.modal___3WPWR .content___1ErEU p {
  margin: 0;
}
.modal___3WPWR .actions___Q8T21 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3WPWR .actions___Q8T21 .button___2W_K3 {
  margin-top: 10px;
}
.modal___3WPWR .actions___Q8T21:hover {
  color: #33c3c7;
}
.fc____4s7J .fc-timegrid-slot-minor___2c_x3 {
  border-top-style: none;
}
.fc____4s7J .fc-timegrid-slot___3n_3h {
  height: 1.5em !important;
}
.messageError___3QK7e .ant-message-custom-content___3zZio {
  display: flex;
  text-align: start;
}
.messageError___3QK7e .ant-message-custom-content___3zZio span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___224ZW {
  background-color: #e6e4e4;
}
.notifyMessage___GPQhe .ant-message-notice-content___2s_eA {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___GPQhe .ant-message-notice-content___2s_eA .anticon___2z_cN {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___GPQhe .ant-message-notice-content___2s_eA span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3KQgt {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3KQgt .ant-notification-notice-message___C35FB,
.notifyCustomize___3KQgt .ant-notification-notice-close___l78Ku,
.notifyCustomize___3KQgt .ant-notification-notice-icon___2DjNL {
  color: white;
}
.notifyCustomize___3KQgt .ant-notification-notice-message___C35FB {
  margin-left: 35px;
}
.notifyCustomize___3KQgt p {
  margin-bottom: 0;
}
.notifyCustomize___3KQgt p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3__1A {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3__1A .ant-notification-notice-message___C35FB {
  margin-left: 35px;
}
.notifyAlertDuplicate___3__1A p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3__1A p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___k5zf6 {
  width: 434px !important;
}
.handleSubmitVote___k5zf6 .ant-btn-primary___sJdvW {
  background: #59c3c7;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt {
  padding: 15px 30px 18px;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt .ant-modal-confirm-body___8BK0r {
  display: flex !important;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt .ant-modal-confirm-body___8BK0r .anticon___2z_cN {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt .ant-modal-confirm-btns___3T0zv button {
  font-weight: bold;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt .ant-modal-confirm-btns___3T0zv button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt .ant-modal-confirm-btns___3T0zv button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___k5zf6 .ant-modal-body___nw4zt .ant-modal-confirm-btns___3T0zv .ant-btn-primary___sJdvW {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___26fPV {
  margin-bottom: 0;
}
.mt-0___G2o2K {
  margin-top: 0;
}
.tooltipFormat___2jl-- p {
  margin-bottom: 0;
}
.bgWhite___nR2Ax {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2mLfg {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2mLfg:hover,
.bgPrimaryBlue___2mLfg:focus,
.bgPrimaryBlue___2mLfg:active {
  background: #4f7ac7;
}
.bgDarkBlue___2BvZY {
  background-color: #023768 !important;
}
.bgDarkBlue___2BvZY:hover,
.bgDarkBlue___2BvZY:focus,
.bgDarkBlue___2BvZY:active {
  background: #194d7d;
}
.bgGreyBlue___2BiG4 {
  background-color: #ebf0f9;
}
.bgLightBlue___2tUsP {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2SMo6 {
  background-color: #e4453a;
}
.bgLightRed___2SMo6:hover,
.bgLightRed___2SMo6:focus,
.bgLightRed___2SMo6:active {
  background: #e17871;
}
.bgLightYellow___AfU5B {
  background-color: #ffd603;
}
.bgDarkGray___cIVg3 {
  background-color: #333333;
}
.bgMediumGray___3WAHQ {
  background-color: #a2a2a2;
}
.bgLightGray___ao2MZ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3vgQK {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1Hhbp {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3KRXj {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1P46f {
  color: #3368c7 !important;
}
.textDarkBlue___2qpsQ {
  color: #023768 !important;
}
.textLightBlue___3Isbj {
  color: #b2cbf7;
}
.textDarkGray___1UtWG {
  color: #333333 !important;
}
.textMediumGray___1BYsu {
  color: #a2a2a2 !important;
}
.textLightGray___rMHoI {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___8WZNb {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2AHxM {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2iF-R {
  border-radius: 5px !important;
}
.rounded-xs___1AnlP {
  border-radius: 10px !important;
}
.rounded-sm___1LtEZ {
  border-radius: 15px !important;
}
.rounded-md___3G5rq {
  border-radius: 20px !important;
}
.rounded-lg___1GLsD {
  border-radius: 25px !important;
}
.rounded-full___22rQ9 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___15SAi {
  margin: 0;
}
.mt-2___1JMT0 {
  margin-top: 0.5rem;
}
.my-2___238z2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___6EWsL {
  padding: 0;
}
.px-1___2i_mH {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2aSk4 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2ucnW {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___29Pwr {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___36Kw8 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1-tLx {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1UgyH {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3E1JV {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3So75 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1rYFp {
  padding-bottom: 20px;
}
.h-full___2ZY5d {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___W1ymP {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3FSZ6 {
  font-weight: bold !important;
}
.fontWeight400___39p_v {
  font-weight: 400 !important;
}
.fontWeight500___z_XoG {
  font-weight: 500 !important;
}
.fontWeight600___2Lc-J {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1XqIP {
  font-size: 12px !important;
}
.fontSize14___11RoN {
  font-size: 14px !important;
}
.fontSize16___13t9h {
  font-size: 16px !important;
}
.fontSize18___27YPg {
  font-size: 18px !important;
}
.fontSize20___3_nXN {
  font-size: 20px !important;
}
.fontSize24___1PkIo {
  font-size: 24px !important;
}
.eventName___1fb1h {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1RLuv {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3vTv_ > tr > th,
.ant-table-tbody___2ljRW > tr.ant-table-row___wBbRQ > td,
.ant-table-summary___143KV tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3vTv_ > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2SF2y .ant-table-thead___3vTv_ > tr > th,
.table-portrait___2SF2y .ant-table-tbody___2ljRW > tr.ant-table-row___wBbRQ > td,
.table-portrait___2SF2y .ant-table-summary___143KV tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2SF2y .bgLightGray___ao2MZ td {
  background: #ebebeb;
}
.table-portrait___2SF2y .bgLightBlue___2tUsP td {
  background: #b2cbf7;
}
.eventContent___tBbkC .timeText___3Y7Ty {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2ljRW > tr.bgLightGray___ao2MZ:hover > td,
.ant-table-tbody___2ljRW > tr > td.ant-table-cell-row-hover___O-9FM {
  background: #ebebeb;
}
.ant-table-tbody___2ljRW > tr.bgLightBlue___2tUsP:hover > td,
.ant-table-tbody___2ljRW > tr > td.ant-table-cell-row-hover___O-9FM {
  background: #b2cbf7;
}
.ant-table-tbody___2ljRW > tr.bgWhite___nR2Ax:hover > td,
.ant-table-tbody___2ljRW > tr > td.ant-table-cell-row-hover___O-9FM {
  background: #ffffff;
}
.stepCalendarTitle___2hQIu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
}
.stepCalendarTitle___2hQIu .titleIcon___1UimM {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.stepCalendarTitle___2hQIu .titleIcon___1UimM .bolderColIcon___1HvPU {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 8px;
  margin-right: 5px;
}
.stepCalendarTitle___2hQIu .titleIcon___1UimM .normalColIcon___1ZJok {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 3px;
}
.stepCalendarTitle___2hQIu h2 {
  margin: 0;
  font-size: 24px;
}
.stepCalendarTitle___2hQIu h2.titleStep2___20hBr {
  font-size: 16px;
}
.stepCalendarTitle___2hQIu p {
  font-size: 16px;
  width: 100%;
  padding-left: 58px;
}
.calendarInfoContainer___1em-t {
  display: flex;
  flex-wrap: wrap;
}
.calendarInfoContainer___1em-t .inputField___2vqhu {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}
.calendarInfoContainer___1em-t .inputField___2vqhu .fieldIcon___3L_ZG {
  width: 7px;
  height: 17px;
  margin-right: 6px;
  background-color: #414141;
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .inputField___2vqhu .fieldIcon___3L_ZG {
    display: none;
  }
}
.calendarInfoContainer___1em-t .inputField___2vqhu p {
  margin: 0;
  min-width: 150px;
  font-size: 16px;
}
.calendarInfoContainer___1em-t .inputField___2vqhu input {
  flex-grow: 1;
}
.calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa {
  width: 100%;
  height: 100%;
  font-size: 16px;
}
.calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa .ant-select-selection-search-input,
.calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa .ant-select-selection-placeholder,
.calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa .ant-select-selection-item {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa .ant-select-selection-search-input,
  .calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa .ant-select-selection-placeholder,
  .calendarInfoContainer___1em-t .inputField___2vqhu .selectInput___3ilsa .ant-select-selection-item {
    font-size: 14px;
  }
}
.calendarInfoContainer___1em-t .inputField___2vqhu .errorNotice___15TPe {
  position: absolute;
  font-size: 12px;
  color: red;
  line-height: 1;
  left: 0;
  bottom: -15px;
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .inputField___2vqhu .errorNotice___15TPe {
    position: unset;
    font-size: 11px;
  }
}
.calendarInfoContainer___1em-t .inputField___2vqhu .errorNotice___15TPe a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___1em-t .leftInfo___1IXVv {
  width: calc(50% - 18px);
  margin-right: 36px;
}
.calendarInfoContainer___1em-t .leftInfo___1IXVv .inputField___2vqhu {
  margin: 38px 0 0 0;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i {
  width: calc(50% - 18px);
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX p {
  text-align: right;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .onOff___28Ux2 {
  background-color: #94d6d6;
  width: 30px;
  height: 22px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .paging___2gnPg {
  display: flex;
  justify-content: flex-end;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .paging___2gnPg .onOff___28Ux2 {
  background-color: #94d6d6;
  box-shadow: -2px -2px 4px #b3bcbc, inset -2px -2px 3px 0px rgba(0, 0, 0, 0.36);
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .paging___2gnPg .activeIndex___2sDpU {
  background-color: #e3f4f4;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .paging___2gnPg div {
  width: 30px;
  height: 22px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: -2px -2px 4px #b3bcbc;
  margin-right: 5px;
  cursor: pointer;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .paging___2gnPg .pencilIcon___3aAxY {
  width: 18px;
  height: 18px;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  margin-left: 10px;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .paging___2gnPg .pencilIcon___3aAxY img {
  width: 18px;
  height: 18px;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .advancedSetting___2YDiP {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0 0 0;
}
.calendarInfoContainer___1em-t .rightInfo___qU-1i .stepActions___NGJGX .advancedSetting___2YDiP .pinionIcon___2IrS2 {
  width: 18px;
  height: 18px;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  margin-right: 10px;
}
.calendarInfoContainer___1em-t .moreInput___2DqP5 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .moreInput___2DqP5 {
    margin-top: 0;
  }
}
.calendarInfoContainer___1em-t .moreInput___2DqP5 .inputField___2vqhu {
  width: calc(50% - 18px);
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .moreInput___2DqP5 .inputField___2vqhu {
    width: 100%;
    align-items: flex-start;
  }
}
.calendarInfoContainer___1em-t .moreInput___2DqP5 .inputField___2vqhu:nth-child(odd) {
  margin-right: 36px;
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .moreInput___2DqP5 .inputField___2vqhu:nth-child(odd) {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 16px;
  }
}
.calendarInfoContainer___1em-t .moreInputField___2cdHt {
  width: calc(100% - 163px);
}
@media screen and (max-width: 767px) {
  .calendarInfoContainer___1em-t .moreInputField___2cdHt {
    width: calc(100% - 150px);
  }
}
.calendarViewContainer___1xwZD .calendarHeader___17kZa {
  margin: 5px 0 5px 0;
}
.calendarViewContainer___1xwZD .calendarHeader___17kZa p {
  font-size: 13px;
  color: #c6c8c6;
  margin-bottom: 0px;
}
.calendarViewContainer___1xwZD .calendarHeader___17kZa .date___3ebi_ {
  font-size: 19px;
  color: #414141;
}
.calendarViewContainer___1xwZD .ant-switch-checked {
  min-width: 68px;
  height: 19px;
  border: 1px solid #e2dfdf;
  box-sizing: border-box;
  background-color: #e3f4f4 !important;
}
.calendarViewContainer___1xwZD .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 23px);
  width: 22px;
  height: 15px;
  top: 1px;
}
.calendarViewContainer___1xwZD .ant-switch-checked .ant-switch-handle::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAudJREFUOI1NlM2KHVUUhb/qW7e7YxttM9CJtmKrDQHF0BNFfACjw0Rw6BMY5xLyBupcHDlRM85IEAM+QRAUfwa300kgpPv+VdU5Z/85qLr3ZrBYB6r4ap21N1V9f3KfC1sjPrm4tw98ERHXIuKNiNgNdwIgYu29xVpultz9H3e/LSLfvnZ4OAWofnzwiI8v7l0G7pyKvvpfKTwWZWFGZ0ZyxyLQCCLA6M/ZnKUZrRp7BB9sj3mviklO6eq7x8d/VMv5/Dmq6t6fRQ4mRUjuNGYsVFmqkQewMYCHj2TvweciPCmCdC3vE3y2XU+klHdqqurLh2oHp6JYBOJOMqMdtEpsQx0+nLMHnRmNGloKnjN3m4aXxqODK1vVjZqITyciSDidO60ZSzMWasxV14k9INjAiwdLNRpVvGQ8ZTwl7s4LR8/sXKsDjs50k3CuxkyUcxEWqiR3PGAYIwAeUIYqPPfAlR60LW3lb9bhPpoPgKUaM1WmKgPYKO44Q9qBHdD3nDOe05C2w1OHdR3tiJ3a3Vmo0pizNGUmylSU2TA8Wa3WKm5Ev37ufQV5BU1416fuRlC7O1PV9SbMpNdclCJCuG+Aa7gTZj2o6/AuYd0Az4lcV9TubnPR0VSVhfZpp6poSoQIYTZcfrAIIpxQ7YFti7UN3nVYSlSloDvjXJvZX9vhl58UYa7KXGQzDCng/lRq1jWEat9p02Bdi6dElMwLEZjZ31vu/tOV8ZgzEWY5Y20zqMXbFmuawftk1iyfUv/Mu4TnTIjyVj0CuF2dTibPm/u9rx89fuXXs3NsNYiUCSl9FUPiiFjfIESG/d28+/pWxYcXdk/G4/Hb9fWTh7MfXrz00ee74zsvb9cHvyyE+1236Vi1r2K1DeFgQxUibIlwieBod4fDejSpR6OrN2/dmlXHv/2O58x3+8/uL5vmRts019uuO0wp7ZRSEBHcvf+TDW5mmBmqiqpmNfs3In7eqqpvvrp5cwrwP4MiKimxFYSsAAAAAElFTkSuQmCC) !important;
  right: 3px;
  left: 0 !important;
}
.calendarViewContainer___1xwZD .ant-switch {
  min-width: 68px;
  height: 21px;
  border: 1px solid #e2dfdf;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
}
.calendarViewContainer___1xwZD .ant-switch .ant-switch-handle {
  width: 22px;
  height: 15px;
  top: 1px;
}
.calendarViewContainer___1xwZD .ant-switch .ant-switch-handle::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAjhJREFUOI1tkz1OHEEQRr9me4Uj5AsYLJAILK1siUtYxNjXQHZsIS5gH8QyqX0P/yQEBCDyZT3TXVU9VUWw070D2pFKPcl8/epVTXhYLhFCQAjhJYBP7n7m7ofu/sLN4ADg3s714a1MlczsxsyuSinfXh8dLQEg/F+tEEJ4A+AX3PfdHeYON0N9nwbWx8zgZjAzqBl0GNCndM9E79+dnPwN3Wq1hxB+AzjAJNTG8i2hjbiGqiL1PVJKSCndicgiIoRzAAcANm3XCyaFqZIxvEIUERAzUkroU3rFzOcR7h8QQiMw9ydtNs9Tx/WSkZaIkHNGyhmp78HMH6MDx5j4rApUdaNiomEabGYtlHJu4cx8HN1s14ENreqmJo63BYsIcqVNCXntGMw8jza2Oh2Ybhne88FVWiJaKxgrr4kR68fVp5o12lIKVHXrqqnqegs227BWQgQRQTQzNrPd58SUM0QEqvpkE6rbMgzIOaPre/STcGaGqpZoqtfmvqiUqoo0DoNFGn3TAEBVMQwDUkroug5d1zUFpRS4+3VUsx9uttCx9Ty2lEfiQXWzyyOtmmEoBZmohRLRWp0ZdkL4Hu5vb/fM7I+I7KeU2gBqsA7Dk5+l6hKRNjwiarQA7ubz+SKa2aqUcppS+ply3q/DIGYIM8owtO2owVWFiIBFIKXAzBBCuJ/NZqcXl5cPUVXBIv9E5C0RfSaiM2I+JKJdEUEZP2qb495+nlIKTJUB3OzMZlcxxq9fLi6WAPAI1gYvMTb3g6oAAAAASUVORK5CYII=);
  background-color: unset;
  box-shadow: none;
  left: 3px;
  top: 3px;
}
.calendarViewContainer___1xwZD .ant-switch:hover {
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
}
.calendarViewContainer___1xwZD .fc-scroller .fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none;
}
.calendarViewContainer___1xwZD .fc-scrollgrid-section-header .fc-scroller::-webkit-scrollbar {
  border-bottom: 1px solid #ddd;
}
.calendarViewContainer___1xwZD .ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.calendarViewContainer___1xwZD .fc-event-main {
  background-color: #e3f4f4;
}
.calendarViewContainer___1xwZD .fc-media-screen .fc-timegrid-event {
  border: none;
}
.calendarViewContainer___1xwZD .fc-media-screen .fc-timegrid-event-harness {
  background-color: #e3f4f4;
  border-radius: 10px;
  overflow: hidden;
}
.calendarViewContainer___1xwZD .fc-media-screen .fc-timegrid-event {
  border-radius: 10px;
  overflow: hidden;
  background-color: #e3f4f4;
}
.calendarViewContainer___1xwZD .eventContent___tBbkC {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b6d3;
  border: 1px solid #9af0ed;
  border-radius: 5px;
  overflow: visible;
  position: relative;
}
.calendarViewContainer___1xwZD .eventContent___tBbkC .resizeIconTop___2ieXi {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.calendarViewContainer___1xwZD .eventContent___tBbkC .resizeIconTop___2ieXi div {
  height: 2px;
  background-color: #3c3e3e;
  width: 17px;
  margin: 1px 0;
}
.calendarViewContainer___1xwZD .eventContent___tBbkC:hover {
  background-color: #33c3c7;
}
.calendarViewContainer___1xwZD .eventContent___tBbkC:hover .resizeIconBottom___xLUz_,
.calendarViewContainer___1xwZD .eventContent___tBbkC:hover .resizeIconTop___2ieXi {
  opacity: 1;
}
.calendarViewContainer___1xwZD .eventContent___tBbkC .timeText___3Y7Ty {
  margin: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.calendarViewContainer___1xwZD .recentAdded___1Ki4- {
  border: 2px dashed #33c3c7;
  background-color: #d1f3f2;
}
.calendarViewContainer___1xwZD .recentAdded___1Ki4- .timeText___3Y7Ty {
  color: #818c8c;
}
.calendarViewContainer___1xwZD .recentAdded___1Ki4-:hover {
  border: 2px dashed #33c3c7;
  background-color: #d1f3f2;
}
.calendarViewContainer___1xwZD .currentEvent___2SoAZ {
  background-color: #279ca0;
}
.calendarViewContainer___1xwZD .currentEvent___2SoAZ .timeText___3Y7Ty {
  color: #ffffff;
}
.ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.fc-event-resizer-end {
  position: relative;
  height: 15px !important;
}
.fc-event-resizer-end::before {
  content: '';
  height: 2px;
  width: 17px;
  position: absolute;
  bottom: 10px;
  background-color: #3c3e3e;
  left: 50%;
  transform: translateX(-50%);
}
.fc-event-resizer-end::after {
  content: '';
  height: 2px;
  width: 17px;
  position: absolute;
  bottom: 5px;
  background-color: #3c3e3e;
  left: 50%;
  transform: translateX(-50%);
}
.basicSettingTemp___3aaLg .ant-select-selector {
  height: 32px !important;
}
.deleteEventBtn___2e1D9 {
  height: 14px;
  min-width: 60px;
  background-color: transparent;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}
.deleteEventBtn___2e1D9 img {
  margin-right: 7px;
  width: 11px;
  height: 13px;
}
.imageEventTypeIcon___1JVFb {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.createEventButtonGroup___2ro3D .editDetail___1qQpA {
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #707070;
  margin: 50px 0 70px 0;
}
.createEventButtonGroup___2ro3D .editDetail___1qQpA .editDetailButton___2w7c5 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 34px;
  min-width: 105px;
  padding: 0 20px;
  font-size: 14px;
  color: #434343;
  border-radius: 16px;
  background-color: #e8e6e6;
}
.createEventButtonGroup___2ro3D .buttonZone___1Obr_ .previewBtnZone___2flE0 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.createEventButtonGroup___2ro3D .buttonZone___1Obr_ .previewBtnZone___2flE0 .previewButton___KtLgK {
  margin-left: 26px;
  height: 32px;
  min-width: 170px;
  background-color: #555555;
  color: #fffefe;
  text-align: center;
  border-radius: 6px;
}
.createEventButtonGroup___2ro3D .buttonZone___1Obr_ .cancelButton___1IZgX {
  height: 48px;
  min-width: 150px;
  padding: 0 55px;
  font-size: 17px;
  color: #535353;
  border-radius: 19px;
  background-color: #fffefe;
  margin: 0 20px;
  box-shadow: 3px -1px 15px #b3bcbc60;
  border: none;
}
.createEventButtonGroup___2ro3D .buttonZone___1Obr_ .submitButton___3UN7n {
  height: 48px;
  min-width: 150px;
  padding: 0 55px;
  font-size: 17px;
  color: #fffefe;
  border-radius: 19px;
  background-color: #33c3c7;
  margin: 0 20px;
}
.basicSettingButtonZone___2wG8w {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.basicSettingButtonZone___2wG8w .cancelBtn___2jTiq {
  height: 48px;
  min-width: 148px;
  background-color: #e8e6e6;
  border: 1px solid #e8e6e6;
  border-radius: 24px;
  margin: 0 10px;
  font-size: 14px;
  color: #414141;
}
@media screen and (max-width: 767px) {
  .basicSettingButtonZone___2wG8w .cancelBtn___2jTiq {
    font-size: 14px;
  }
}
.basicSettingButtonZone___2wG8w .saveBtn___apyfy {
  height: 48px;
  min-width: 150px;
  padding: 0 55px;
  font-size: 14px;
  color: #fffefe;
  border-radius: 24px;
  background-color: #33c3c7;
  margin: 0 10px;
  border: 0;
}
@media screen and (max-width: 767px) {
  .basicSettingButtonZone___2wG8w .saveBtn___apyfy {
    font-size: 14px;
  }
}
.templateContent___1XNxP .ant-select {
  height: auto !important;
}
.templateContent___1XNxP .ant-select-single .ant-select-selector .ant-select-selection-item {
  justify-content: flex-start !important;
}
