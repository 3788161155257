.eventTemplate___vYf3D {
  max-width: 1080px;
  margin: auto;
  padding-top: 50px;
  padding-right: 15px;
  padding-left: 15px;
}
.eventTemplate___vYf3D .headTitle___2oiCJ {
  display: flex;
  align-items: center;
  margin-bottom: 65px;
}
@media screen and (max-width: 767px) {
  .eventTemplate___vYf3D .headTitle___2oiCJ {
    margin-bottom: 0;
  }
}
.eventTemplate___vYf3D .headTitle___2oiCJ .bolderIcon___ch6f2 {
  width: 8px;
  height: 35px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.eventTemplate___vYf3D .headTitle___2oiCJ .titleIcon___1qGId {
  width: 3px;
  height: 35px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.eventTemplate___vYf3D .headTitle___2oiCJ span {
  color: #3c3c3c;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .eventTemplate___vYf3D .headTitle___2oiCJ span {
    font-size: 16px;
    line-height: 1.3;
  }
}
.eventTemplate___vYf3D .templateIndex___1sgFc .listTemplate___nz1em {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 46px 0 76px 172px;
}
@media screen and (max-width: 767px) {
  .eventTemplate___vYf3D .templateIndex___1sgFc .listTemplate___nz1em {
    margin: 30px 0;
  }
}
.eventTemplate___vYf3D .templateIndex___1sgFc .listTemplate___nz1em .selections___10cWZ {
  height: 68px;
  width: 128px;
  border: 2px solid #c8c8c8;
  color: #c8c8c8;
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 3px;
}
.eventTemplate___vYf3D .templateIndex___1sgFc .listTemplate___nz1em .selections___10cWZ:hover {
  border: 2px solid #33c3c7;
}
.eventTemplate___vYf3D .templateIndex___1sgFc .listTemplate___nz1em .currentSelection___2Xvwi {
  border: 2px solid #33c3c7;
  background-color: #33c3c7;
  color: #ffffff;
}
.wrapperFooter___2_HmG div {
  padding: 50px 0;
  position: unset;
}
