.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K {
  padding-bottom: 4rem;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedTitle___1rl4O {
  text-align: center;
  padding-bottom: 0.7rem;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-gap: 4px;
  gap: 4px;
  height: 408px;
  padding: 0 57px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N {
  border: 4px solid #40a9ff;
  width: 100%;
  height: 100%;
  padding: 14px;
  cursor: pointer;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N:hover {
  background-color: #33c3c7 !important;
  color: white !important;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N:hover h2 {
  color: white !important;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child {
  height: 280px;
  border: 2px solid #1a1b1b60;
  border-radius: 12px;
  background-color: white;
  position: relative;
  padding: 40px 18px 0 18px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child > div:first-child {
  width: 100%;
  height: 37px;
  background-color: #e8e3e3;
  border-radius: 10px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child > div:nth-child(2) {
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: space-around;
  grid-gap: 10px;
  gap: 10px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child > div:nth-child(2) > div {
  width: 100%;
  height: 100%;
  background-color: #e8e3e3;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child > div:nth-child(2) > div:last-child {
  display: none;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child > div:last-child {
  flex-grow: 1;
  width: 100%;
  background-color: #e8e3e3;
  padding: 17px 0px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:first-child::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 23px;
  background-color: #d4d1d1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N button {
  display: block;
  margin: 0 auto;
  border-radius: 16px;
  font-weight: bold;
  background-color: #33c3c7;
  color: white;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N .embedLinkButton___377Pm {
  text-align: center;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  color: #33c3c7;
  padding: 4px 15px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N a {
  display: block;
  margin: 0 auto;
  font-size: 14px;
  width: 157px;
  height: 32px;
  padding-top: 3px;
  font-weight: bold;
  background-color: #e8e3e3;
  color: #33c3c7;
  text-align: center;
  border-bottom: 2px solid #33c3c7;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N h2 {
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 1.2rem;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N p {
  margin-bottom: 2px;
  font-size: 12px;
  padding-left: 11px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N > div:last-child {
  margin: 0 auto;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N:last-child > div:first-child > div:nth-child(2) {
  grid-gap: 3px;
  gap: 3px;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N:last-child > div:first-child > div:nth-child(2) > div:nth-child(2) {
  background: #0bc3c6;
}
.dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N:last-child > div:first-child > div:nth-child(2) > div:last-child {
  display: unset;
  width: 10%;
}
@media screen and (max-width: 768px) {
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 {
    display: block;
  }
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N {
    width: 100%;
  }
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N:last-child {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 556px) {
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 {
    padding: 0;
  }
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N button {
    font-size: 10px;
  }
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N h2 {
    font-size: 16px;
  }
  .dialogChooseTemplate___3lvMu .bodyTemplate___2FV5K .embedModalContent___N2UA4 .embedModalInner___2gZ-N p {
    font-size: 10px;
  }
}
.dialogChooseTemplate___3lvMu .ant-modal-content {
  border-radius: 15px !important;
}
