@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1bGYP {
  display: flex;
  justify-content: center;
}
button.btn___1KouN {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1KouN {
    padding: 10px;
  }
}
button.btn___1KouN:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1KouN:focus {
  outline: none;
}
.btnWhite___1rP6R.btn___1KouN {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1rP6R.btn___1KouN:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2zbmY.btn___1KouN,
.btnGreen___2zbmY.btn___1KouN:hover,
.btnGreen___2zbmY.btn___1KouN:active,
.btnGreen___2zbmY.btn___1KouN:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___29F_P {
  color: #fe0000;
}
.errorMessage___2leRS {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1CNUC {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2HH0U {
  display: none !important;
}
.m-auto___T5UoO {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2xjt3 {
  max-width: 120px !important;
}
.fc___1IZLR .fc-timegrid-col___3g4sk.fc-day-today___3r_CT {
  background-color: #ffffff;
}
.fc___1IZLR .fc-timegrid-slot___3OdMn {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1IZLR .fc-timegrid-slot___3OdMn {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1YMg- .fc-direction-ltr___1YpBt .fc-timegrid-slot-label-frame___18gnG {
    font-size: 10px;
  }
  .bookingCalendar___1YMg- .fc___1IZLR .fc-timegrid-slot-label-cushion___1psAt {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1YMg- .fc___1IZLR .fc-scrollgrid-section-header___8SAIh .fc-scroller___2IOOx {
    overflow: unset !important;
  }
}
.ant-message-notice___1IXpZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1scvW {
  width: 20px;
  height: 20px;
}
.bgTransparent___1bVFJ {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___RWMPA {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___Q8R3R {
  position: relative;
}
.inputCustomValue___Q8R3R .ant-input___2XEb6 {
  padding-right: 50px;
}
.inputCustomValueText___3LSWi {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3y7Aa .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3y7Aa .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1gH8c .ant-radio-inner___3a0V3 {
  border-color: black !important ;
}
.ant-radio-checked___1gH8c .ant-radio-inner___3a0V3:after {
  background-color: black;
}
.ant-radio___3Iq8T:hover .ant-radio-inner___3a0V3 {
  border-color: black;
}
.ant-radio-checked___1gH8c .ant-radio-inner___3a0V3:focus {
  border-color: black;
}
.modal___2z1Df {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2z1Df .close___3YHkm {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2z1Df .header___tYPzC {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2z1Df .header___tYPzC p {
  margin: 0;
}
.modal___2z1Df .content___1UWsw {
  text-align: center;
  font-size: 14px;
}
.modal___2z1Df .content___1UWsw p {
  margin: 0;
}
.modal___2z1Df .actions___5M3wS {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2z1Df .actions___5M3wS .button___1hZ6g {
  margin-top: 10px;
}
.modal___2z1Df .actions___5M3wS:hover {
  color: #33c3c7;
}
.fc___1IZLR .fc-timegrid-slot-minor___3_fj4 {
  border-top-style: none;
}
.fc___1IZLR .fc-timegrid-slot___3OdMn {
  height: 1.5em !important;
}
.messageError___DM2UR .ant-message-custom-content___1HAH_ {
  display: flex;
  text-align: start;
}
.messageError___DM2UR .ant-message-custom-content___1HAH_ span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2tgLZ {
  background-color: #e6e4e4;
}
.notifyMessage___1lBCq .ant-message-notice-content___3Rdcz {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1lBCq .ant-message-notice-content___3Rdcz .anticon___2Hl_6 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1lBCq .ant-message-notice-content___3Rdcz span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___4Auzn {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___4Auzn .ant-notification-notice-message___2_30W,
.notifyCustomize___4Auzn .ant-notification-notice-close___3WV20,
.notifyCustomize___4Auzn .ant-notification-notice-icon___vE1Az {
  color: white;
}
.notifyCustomize___4Auzn .ant-notification-notice-message___2_30W {
  margin-left: 35px;
}
.notifyCustomize___4Auzn p {
  margin-bottom: 0;
}
.notifyCustomize___4Auzn p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2pwT9 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2pwT9 .ant-notification-notice-message___2_30W {
  margin-left: 35px;
}
.notifyAlertDuplicate___2pwT9 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2pwT9 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___LJX5y {
  width: 434px !important;
}
.handleSubmitVote___LJX5y .ant-btn-primary___uqrmV {
  background: #59c3c7;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U {
  padding: 15px 30px 18px;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U .ant-modal-confirm-body___1B5_0 {
  display: flex !important;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U .ant-modal-confirm-body___1B5_0 .anticon___2Hl_6 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U .ant-modal-confirm-btns___b7c5i button {
  font-weight: bold;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U .ant-modal-confirm-btns___b7c5i button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U .ant-modal-confirm-btns___b7c5i button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___LJX5y .ant-modal-body___1pM0U .ant-modal-confirm-btns___b7c5i .ant-btn-primary___uqrmV {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1ra1z {
  margin-bottom: 0;
}
.mt-0___2qHQm {
  margin-top: 0;
}
.tooltipFormat___3kaWi p {
  margin-bottom: 0;
}
.bgWhite___2ie2G {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3FF5q {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3FF5q:hover,
.bgPrimaryBlue___3FF5q:focus,
.bgPrimaryBlue___3FF5q:active {
  background: #4f7ac7;
}
.bgDarkBlue___3kbdk {
  background-color: #023768 !important;
}
.bgDarkBlue___3kbdk:hover,
.bgDarkBlue___3kbdk:focus,
.bgDarkBlue___3kbdk:active {
  background: #194d7d;
}
.bgGreyBlue___26Bxh {
  background-color: #ebf0f9;
}
.bgLightBlue___3U9Hd {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1oR5R {
  background-color: #e4453a;
}
.bgLightRed___1oR5R:hover,
.bgLightRed___1oR5R:focus,
.bgLightRed___1oR5R:active {
  background: #e17871;
}
.bgLightYellow___2n9Gp {
  background-color: #ffd603;
}
.bgDarkGray___aOpf6 {
  background-color: #333333;
}
.bgMediumGray___2acV0 {
  background-color: #a2a2a2;
}
.bgLightGray___2HBFm {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___23eHY {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1kyZZ {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3qVE7 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___Yyd6T {
  color: #3368c7 !important;
}
.textDarkBlue___arMPm {
  color: #023768 !important;
}
.textLightBlue___131ta {
  color: #b2cbf7;
}
.textDarkGray___2dbSG {
  color: #333333 !important;
}
.textMediumGray___14hIs {
  color: #a2a2a2 !important;
}
.textLightGray___2CPgY {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___13Dj3 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary____pYN3 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3M5u2 {
  border-radius: 5px !important;
}
.rounded-xs___27moO {
  border-radius: 10px !important;
}
.rounded-sm___2H-_j {
  border-radius: 15px !important;
}
.rounded-md___2QHpG {
  border-radius: 20px !important;
}
.rounded-lg___2v3en {
  border-radius: 25px !important;
}
.rounded-full___1gV8Y {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3agEV {
  margin: 0;
}
.mt-2___3uSX1 {
  margin-top: 0.5rem;
}
.my-2___eq_CD {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___xq4Tj {
  padding: 0;
}
.px-1___3LVwi {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___sicZ3 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2nyo1 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___fvz_I {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3Ammf {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___IukLc {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1t78l {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1BLwV {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3MBoT {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___29xTO {
  padding-bottom: 20px;
}
.h-full___1Equ2 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1_zgO {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1e70J {
  font-weight: bold !important;
}
.fontWeight400___KxhkQ {
  font-weight: 400 !important;
}
.fontWeight500___2THEG {
  font-weight: 500 !important;
}
.fontWeight600___2qaeo {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3CvlO {
  font-size: 12px !important;
}
.fontSize14___279kV {
  font-size: 14px !important;
}
.fontSize16___1FwGz {
  font-size: 16px !important;
}
.fontSize18___4n2Ry {
  font-size: 18px !important;
}
.fontSize20___2XUum {
  font-size: 20px !important;
}
.fontSize24___1B_Po {
  font-size: 24px !important;
}
.eventName___Wo-Jf {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1ForQ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2rSEg > tr > th,
.ant-table-tbody___1WOiv > tr.ant-table-row___-OfGd > td,
.ant-table-summary___29S7M tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2rSEg > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1kC5v .ant-table-thead___2rSEg > tr > th,
.table-portrait___1kC5v .ant-table-tbody___1WOiv > tr.ant-table-row___-OfGd > td,
.table-portrait___1kC5v .ant-table-summary___29S7M tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1kC5v .bgLightGray___2HBFm td {
  background: #ebebeb;
}
.table-portrait___1kC5v .bgLightBlue___3U9Hd td {
  background: #b2cbf7;
}
.eventContent___3Srka .timeText___1a-nu {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1WOiv > tr.bgLightGray___2HBFm:hover > td,
.ant-table-tbody___1WOiv > tr > td.ant-table-cell-row-hover___hpKKH {
  background: #ebebeb;
}
.ant-table-tbody___1WOiv > tr.bgLightBlue___3U9Hd:hover > td,
.ant-table-tbody___1WOiv > tr > td.ant-table-cell-row-hover___hpKKH {
  background: #b2cbf7;
}
.ant-table-tbody___1WOiv > tr.bgWhite___2ie2G:hover > td,
.ant-table-tbody___1WOiv > tr > td.ant-table-cell-row-hover___hpKKH {
  background: #ffffff;
}
.buttonZone___ISaWY {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.buttonZone___ISaWY .cancelBtn___3pwxM {
  height: 48px;
  min-width: 148px;
  background-color: #e8e6e6;
  border: 1px solid #e8e6e6;
  border-radius: 24px;
  margin: 0 10px;
  color: #414141;
}
.buttonZone___ISaWY .saveBtn___1slAh {
  height: 48px;
  min-width: 148px;
  background-color: #33c3c7;
  border-radius: 24px;
  border: 1px solid #33c3c7;
  margin: 0 10px;
  color: #ffffff;
}
.helper___1scvW {
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.helper___1scvW img {
  position: relative;
}
.helper___1scvW .helperTooltip___8CiPw {
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 50px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 120px;
  width: 400px;
  background-color: #414141;
  color: #fffefe;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.helper___1scvW .helperTooltip___8CiPw::-webkit-scrollbar {
  display: none;
}
.helper___1scvW .helperTooltip___8CiPw img {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.helper___1scvW:hover .helperTooltip___8CiPw {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
.devideIcon___1vfd2 {
  margin: 0 9px;
}
.textareaField___1Yy3y {
  margin-bottom: 28px;
}
.selectField___BRnOO {
  min-height: 110px;
  position: relative;
}
.selectField___BRnOO .helper___1scvW {
  margin-top: 0;
}
.selectField___BRnOO .errorNotice___1-xdL {
  position: absolute;
  bottom: -18px;
  left: 0;
  font-size: 12px;
  color: #ff4d4f;
}
.selectField___BRnOO .titleField___1bV46,
.textareaField___1Yy3y .titleField___1bV46 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
}
.selectField___BRnOO .titleField___1bV46 .titleFieldIcon___3WTzZ,
.textareaField___1Yy3y .titleField___1bV46 .titleFieldIcon___3WTzZ {
  width: 7px;
  height: 17px;
  background-color: #414141;
  margin-right: 5px;
}
.selectField___BRnOO .titleField___1bV46 p,
.textareaField___1Yy3y .titleField___1bV46 p {
  font-size: 17px;
  color: #414141;
  margin: 0 10px 0 0;
}
@media screen and (max-width: 767px) {
  .selectField___BRnOO .titleField___1bV46 .titleFieldIconOther___3Rw_6,
  .textareaField___1Yy3y .titleField___1bV46 .titleFieldIconOther___3Rw_6 {
    font-size: 16px;
  }
}
.selectField___BRnOO .selections___22AsI .devideIcon___1vfd2,
.textareaField___1Yy3y .selections___22AsI .devideIcon___1vfd2 {
  margin: 0 9px;
}
.selectField___BRnOO textarea,
.textareaField___1Yy3y textarea {
  height: 120px;
  width: 100%;
  font-size: 17px;
  border-radius: 17px;
  border: 2px solid #ebebeb;
}
.selectField___BRnOO textarea:hover,
.textareaField___1Yy3y textarea:hover {
  border: #33c3c7 2px solid !important;
}
@media screen and (max-width: 767px) {
  .timePicker___OTsRa .ant-picker-time-panel-column {
    float: left;
    height: 110%;
    overflow-y: auto;
  }
  .timePicker___OTsRa .ant-picker-time-panel-column::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .timePicker___OTsRa .ant-picker-time-panel-column::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
  }
  .timePicker___OTsRa .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }
}
.timePicker___OTsRa .ant-picker-panel .ant-picker-footer {
  display: none;
}
.timePicker___OTsRa .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.timePicker___OTsRa .ant-picker-time-panel-column {
  width: 80px;
}
.timePicker___OTsRa .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___OTsRa .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell::after {
  content: '時';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
.timePicker___OTsRa .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___OTsRa .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell::after {
  content: '分';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
.tooltipAdvanced___kJLOm {
  white-space: pre-line;
}
.displayNone___26Pv4 {
  display: none;
}
