@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1KHin {
  display: flex;
  justify-content: center;
}
button.btn___1WW7L {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1WW7L {
    padding: 10px;
  }
}
button.btn___1WW7L:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1WW7L:focus {
  outline: none;
}
.btnWhite___2g-JL.btn___1WW7L {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2g-JL.btn___1WW7L:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2d8UF.btn___1WW7L,
.btnGreen___2d8UF.btn___1WW7L:hover,
.btnGreen___2d8UF.btn___1WW7L:active,
.btnGreen___2d8UF.btn___1WW7L:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___938-c {
  color: #fe0000;
}
.errorMessage___T3I0E {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2_ZPP {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2IiIp {
  display: none !important;
}
.m-auto___18KEh {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___P1ydd {
  max-width: 120px !important;
}
.fc___2jATw .fc-timegrid-col___1GZyT.fc-day-today___1H2Qi {
  background-color: #ffffff;
}
.fc___2jATw .fc-timegrid-slot___yRfsV {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2jATw .fc-timegrid-slot___yRfsV {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3RIRc .fc-direction-ltr___2xaKP .fc-timegrid-slot-label-frame___2uHYI {
    font-size: 10px;
  }
  .bookingCalendar___3RIRc .fc___2jATw .fc-timegrid-slot-label-cushion___36Qpp {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3RIRc .fc___2jATw .fc-scrollgrid-section-header___tMXHJ .fc-scroller___1R9jc {
    overflow: unset !important;
  }
}
.ant-message-notice___3hBP5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___MgJX2 {
  width: 20px;
  height: 20px;
}
.bgTransparent___fBnKM {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3xcnS {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___ZEAqi {
  position: relative;
}
.inputCustomValue___ZEAqi .ant-input___SdQ_u {
  padding-right: 50px;
}
.inputCustomValueText___3IYec {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___21Q6A .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___21Q6A .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3mwG1 .ant-radio-inner___2qcrI {
  border-color: black !important ;
}
.ant-radio-checked___3mwG1 .ant-radio-inner___2qcrI:after {
  background-color: black;
}
.ant-radio___15Nb3:hover .ant-radio-inner___2qcrI {
  border-color: black;
}
.ant-radio-checked___3mwG1 .ant-radio-inner___2qcrI:focus {
  border-color: black;
}
.modal___24Vxk {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___24Vxk .close___1Dsw_ {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___24Vxk .header___3Lkpd {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___24Vxk .header___3Lkpd p {
  margin: 0;
}
.modal___24Vxk .content___3n0lY {
  text-align: center;
  font-size: 14px;
}
.modal___24Vxk .content___3n0lY p {
  margin: 0;
}
.modal___24Vxk .actions___3tQgF {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___24Vxk .actions___3tQgF .button___SAoGN {
  margin-top: 10px;
}
.modal___24Vxk .actions___3tQgF:hover {
  color: #33c3c7;
}
.fc___2jATw .fc-timegrid-slot-minor___2MMRs {
  border-top-style: none;
}
.fc___2jATw .fc-timegrid-slot___yRfsV {
  height: 1.5em !important;
}
.messageError___2qMb_ .ant-message-custom-content___2Tt_T {
  display: flex;
  text-align: start;
}
.messageError___2qMb_ .ant-message-custom-content___2Tt_T span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1qJPx {
  background-color: #e6e4e4;
}
.notifyMessage___2-LkR .ant-message-notice-content___kXItX {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2-LkR .ant-message-notice-content___kXItX .anticon___2aZrb {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2-LkR .ant-message-notice-content___kXItX span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___35b9o {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___35b9o .ant-notification-notice-message___1x2Og,
.notifyCustomize___35b9o .ant-notification-notice-close___3y2zO,
.notifyCustomize___35b9o .ant-notification-notice-icon___2JOBR {
  color: white;
}
.notifyCustomize___35b9o .ant-notification-notice-message___1x2Og {
  margin-left: 35px;
}
.notifyCustomize___35b9o p {
  margin-bottom: 0;
}
.notifyCustomize___35b9o p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___8Baw4 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___8Baw4 .ant-notification-notice-message___1x2Og {
  margin-left: 35px;
}
.notifyAlertDuplicate___8Baw4 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___8Baw4 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___DA-1i {
  width: 434px !important;
}
.handleSubmitVote___DA-1i .ant-btn-primary___uNbZV {
  background: #59c3c7;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy {
  padding: 15px 30px 18px;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy .ant-modal-confirm-body___2lYqM {
  display: flex !important;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy .ant-modal-confirm-body___2lYqM .anticon___2aZrb {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy .ant-modal-confirm-btns___3qeg9 button {
  font-weight: bold;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy .ant-modal-confirm-btns___3qeg9 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy .ant-modal-confirm-btns___3qeg9 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___DA-1i .ant-modal-body___2o2fy .ant-modal-confirm-btns___3qeg9 .ant-btn-primary___uNbZV {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3yun_ {
  margin-bottom: 0;
}
.mt-0___2gZva {
  margin-top: 0;
}
.tooltipFormat___4Kve5 p {
  margin-bottom: 0;
}
.bgWhite___tidcq {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2pOZV {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2pOZV:hover,
.bgPrimaryBlue___2pOZV:focus,
.bgPrimaryBlue___2pOZV:active {
  background: #4f7ac7;
}
.bgDarkBlue___1ibS8 {
  background-color: #023768 !important;
}
.bgDarkBlue___1ibS8:hover,
.bgDarkBlue___1ibS8:focus,
.bgDarkBlue___1ibS8:active {
  background: #194d7d;
}
.bgGreyBlue___wlLx5 {
  background-color: #ebf0f9;
}
.bgLightBlue___1qNZS {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2uoSP {
  background-color: #e4453a;
}
.bgLightRed___2uoSP:hover,
.bgLightRed___2uoSP:focus,
.bgLightRed___2uoSP:active {
  background: #e17871;
}
.bgLightYellow___3SMSs {
  background-color: #ffd603;
}
.bgDarkGray___3fRGm {
  background-color: #333333;
}
.bgMediumGray___NjREy {
  background-color: #a2a2a2;
}
.bgLightGray___1GATd {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2MhX0 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___PTNZa {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2tITL {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3twjg {
  color: #3368c7 !important;
}
.textDarkBlue___1-0tE {
  color: #023768 !important;
}
.textLightBlue___1MQgJ {
  color: #b2cbf7;
}
.textDarkGray___3x4jH {
  color: #333333 !important;
}
.textMediumGray___3JH_F {
  color: #a2a2a2 !important;
}
.textLightGray___HvQqN {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___17mze {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___10GNn {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___DB53h {
  border-radius: 5px !important;
}
.rounded-xs___FBD8P {
  border-radius: 10px !important;
}
.rounded-sm___3w1XV {
  border-radius: 15px !important;
}
.rounded-md___3459Z {
  border-radius: 20px !important;
}
.rounded-lg___Ik6pz {
  border-radius: 25px !important;
}
.rounded-full___3W6wv {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2rixH {
  margin: 0;
}
.mt-2___2ArpA {
  margin-top: 0.5rem;
}
.my-2___S_XyV {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2MYHp {
  padding: 0;
}
.px-1___3BiIp {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___25Y5G {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1OpAK {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___EbpBt {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1DmKl {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3Jx3U {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3jlTa {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1u17k {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2_1Mr {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1DYlZ {
  padding-bottom: 20px;
}
.h-full___1bpbO {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3UgAV {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___aM922 {
  font-weight: bold !important;
}
.fontWeight400___2h_PG {
  font-weight: 400 !important;
}
.fontWeight500___18DIL {
  font-weight: 500 !important;
}
.fontWeight600___1fTWj {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___36ROg {
  font-size: 12px !important;
}
.fontSize14___1rp5d {
  font-size: 14px !important;
}
.fontSize16___2dV1F {
  font-size: 16px !important;
}
.fontSize18___UL-V0 {
  font-size: 18px !important;
}
.fontSize20___exrbo {
  font-size: 20px !important;
}
.fontSize24___1q8Zu {
  font-size: 24px !important;
}
.eventName___bZb9- {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3ROfw {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___17h1n > tr > th,
.ant-table-tbody___3lI5c > tr.ant-table-row___3zsD0 > td,
.ant-table-summary___1-Fx_ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___17h1n > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3Ak6E .ant-table-thead___17h1n > tr > th,
.table-portrait___3Ak6E .ant-table-tbody___3lI5c > tr.ant-table-row___3zsD0 > td,
.table-portrait___3Ak6E .ant-table-summary___1-Fx_ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3Ak6E .bgLightGray___1GATd td {
  background: #ebebeb;
}
.table-portrait___3Ak6E .bgLightBlue___1qNZS td {
  background: #b2cbf7;
}
.eventContent___1Spto .timeText___3annH {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3lI5c > tr.bgLightGray___1GATd:hover > td,
.ant-table-tbody___3lI5c > tr > td.ant-table-cell-row-hover___2PhPl {
  background: #ebebeb;
}
.ant-table-tbody___3lI5c > tr.bgLightBlue___1qNZS:hover > td,
.ant-table-tbody___3lI5c > tr > td.ant-table-cell-row-hover___2PhPl {
  background: #b2cbf7;
}
.ant-table-tbody___3lI5c > tr.bgWhite___tidcq:hover > td,
.ant-table-tbody___3lI5c > tr > td.ant-table-cell-row-hover___2PhPl {
  background: #ffffff;
}
.complete___3tim9 {
  padding-bottom: 120px;
}
.paymentComplete___2Ymhp {
  max-width: 1080px;
  width: 100%;
  margin: auto;
  padding: 0 15px 80px;
}
@media screen and (max-width: 767px) {
  .paymentComplete___2Ymhp {
    padding: 0 15px 130px;
    padding: 0;
  }
}
.paymentComplete___2Ymhp .titleIcon___3V8JS {
  padding: 0px 15px;
  margin: 65px 0px 55px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .paymentComplete___2Ymhp .titleIcon___3V8JS {
    padding: 0;
    margin: 0;
  }
}
.paymentComplete___2Ymhp .titleIcon___3V8JS .bolderColIcon___OVR8z {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 13px;
  margin-right: 6px;
}
.paymentComplete___2Ymhp .titleIcon___3V8JS .normalColIcon___3uWUG {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 5px;
}
.paymentComplete___2Ymhp .paymentTitle___xwvqK {
  padding: 0 15px;
  margin: 65px 30px 55px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .paymentComplete___2Ymhp .paymentTitle___xwvqK {
    margin: 20px 0;
  }
}
.paymentComplete___2Ymhp .paymentTitle___xwvqK .paymentTitleText___N1bpE {
  color: #3c3c3c;
  font-size: 30px;
  font-weight: 700;
  display: inline-block;
  padding-left: 15px;
}
@media screen and (max-width: 767px) {
  .paymentComplete___2Ymhp .paymentTitle___xwvqK .paymentTitleText___N1bpE {
    font-size: 20px;
  }
}
.paymentComplete___2Ymhp .paymentDescription___2kirm {
  font-size: 14px;
  color: #3c3c3c;
  line-height: 1.54;
  padding: 0px 75px;
}
@media screen and (max-width: 767px) {
  .paymentComplete___2Ymhp .paymentDescription___2kirm {
    padding: 0 15px;
  }
}
.paymentComplete___2Ymhp .paymentBtnGroup___2ugP9 {
  display: flex;
  justify-content: center;
  padding-bottom: 90px;
  margin-top: 100px;
}
.paymentComplete___2Ymhp .paymentBtnGroup___2ugP9 button:first-child {
  margin-right: 50px;
}
@media screen and (max-width: 767px) {
  .paymentComplete___2Ymhp .paymentBtnGroup___2ugP9 {
    margin-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.paymentComplete___2Ymhp .btn.btnWhite:hover {
  border: 2px solid #909090;
}
.paymentComplete___2Ymhp .btn.btn-custom-height {
  font-size: 16px;
}
