@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2bme4 {
  display: flex;
  justify-content: center;
}
button.btn___3u3qG {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3u3qG {
    padding: 10px;
  }
}
button.btn___3u3qG:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3u3qG:focus {
  outline: none;
}
.btnWhite___1JLRl.btn___3u3qG {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1JLRl.btn___3u3qG:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1qP1H.btn___3u3qG,
.btnGreen___1qP1H.btn___3u3qG:hover,
.btnGreen___1qP1H.btn___3u3qG:active,
.btnGreen___1qP1H.btn___3u3qG:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2JdFv {
  color: #fe0000;
}
.errorMessage___MSUxH {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1knsA {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2J_9E {
  display: none !important;
}
.m-auto___1SCz7 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1AscV {
  max-width: 120px !important;
}
.fc___1_kFn .fc-timegrid-col___29GHJ.fc-day-today___3LYKg {
  background-color: #ffffff;
}
.fc___1_kFn .fc-timegrid-slot___12R3N {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1_kFn .fc-timegrid-slot___12R3N {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___lLPLs .fc-direction-ltr___3l2Xd .fc-timegrid-slot-label-frame___31z3j {
    font-size: 10px;
  }
  .bookingCalendar___lLPLs .fc___1_kFn .fc-timegrid-slot-label-cushion___n_fyw {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___lLPLs .fc___1_kFn .fc-scrollgrid-section-header___kUN3D .fc-scroller___u2WcM {
    overflow: unset !important;
  }
}
.ant-message-notice___2kqux {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___b5RWq {
  width: 20px;
  height: 20px;
}
.bgTransparent___WoA56 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___17MGC {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___16eRJ {
  position: relative;
}
.inputCustomValue___16eRJ .ant-input___Lr2p7 {
  padding-right: 50px;
}
.inputCustomValueText___1fCua {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3ATOf .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3ATOf .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2y33B .ant-radio-inner___3xXUx {
  border-color: black !important ;
}
.ant-radio-checked___2y33B .ant-radio-inner___3xXUx:after {
  background-color: black;
}
.ant-radio___1krAW:hover .ant-radio-inner___3xXUx {
  border-color: black;
}
.ant-radio-checked___2y33B .ant-radio-inner___3xXUx:focus {
  border-color: black;
}
.modal___1hNuG {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1hNuG .close___1FsJQ {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1hNuG .header___272Vi {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1hNuG .header___272Vi p {
  margin: 0;
}
.modal___1hNuG .content___x3Sne {
  text-align: center;
  font-size: 14px;
}
.modal___1hNuG .content___x3Sne p {
  margin: 0;
}
.modal___1hNuG .actions___1IWfe {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1hNuG .actions___1IWfe .button___1i43P {
  margin-top: 10px;
}
.modal___1hNuG .actions___1IWfe:hover {
  color: #33c3c7;
}
.fc___1_kFn .fc-timegrid-slot-minor___3oJPf {
  border-top-style: none;
}
.fc___1_kFn .fc-timegrid-slot___12R3N {
  height: 1.5em !important;
}
.messageError___XXwl0 .ant-message-custom-content___3vpko {
  display: flex;
  text-align: start;
}
.messageError___XXwl0 .ant-message-custom-content___3vpko span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3qGqb {
  background-color: #e6e4e4;
}
.notifyMessage___7fHs- .ant-message-notice-content___PRSss {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___7fHs- .ant-message-notice-content___PRSss .anticon___3lJG1 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___7fHs- .ant-message-notice-content___PRSss span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3Du4x {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3Du4x .ant-notification-notice-message___13M6U,
.notifyCustomize___3Du4x .ant-notification-notice-close___2hCd_,
.notifyCustomize___3Du4x .ant-notification-notice-icon___2zlo8 {
  color: white;
}
.notifyCustomize___3Du4x .ant-notification-notice-message___13M6U {
  margin-left: 35px;
}
.notifyCustomize___3Du4x p {
  margin-bottom: 0;
}
.notifyCustomize___3Du4x p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1yNGP {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1yNGP .ant-notification-notice-message___13M6U {
  margin-left: 35px;
}
.notifyAlertDuplicate___1yNGP p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1yNGP p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1s45u {
  width: 434px !important;
}
.handleSubmitVote___1s45u .ant-btn-primary___1PpPN {
  background: #59c3c7;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 .ant-modal-confirm-body___1Kto4 {
  display: flex !important;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 .ant-modal-confirm-body___1Kto4 .anticon___3lJG1 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 .ant-modal-confirm-btns___lfBcH button {
  font-weight: bold;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 .ant-modal-confirm-btns___lfBcH button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 .ant-modal-confirm-btns___lfBcH button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1s45u .ant-modal-body___iL8r9 .ant-modal-confirm-btns___lfBcH .ant-btn-primary___1PpPN {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3VVl5 {
  margin-bottom: 0;
}
.mt-0___tYn7X {
  margin-top: 0;
}
.tooltipFormat___3-yJ_ p {
  margin-bottom: 0;
}
.bgWhite___3SETZ {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___jZn-4 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___jZn-4:hover,
.bgPrimaryBlue___jZn-4:focus,
.bgPrimaryBlue___jZn-4:active {
  background: #4f7ac7;
}
.bgDarkBlue___sn0rX {
  background-color: #023768 !important;
}
.bgDarkBlue___sn0rX:hover,
.bgDarkBlue___sn0rX:focus,
.bgDarkBlue___sn0rX:active {
  background: #194d7d;
}
.bgGreyBlue___1XV-O {
  background-color: #ebf0f9;
}
.bgLightBlue___eSh1A {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1fdKo {
  background-color: #e4453a;
}
.bgLightRed___1fdKo:hover,
.bgLightRed___1fdKo:focus,
.bgLightRed___1fdKo:active {
  background: #e17871;
}
.bgLightYellow___16GBq {
  background-color: #ffd603;
}
.bgDarkGray___247E6 {
  background-color: #333333;
}
.bgMediumGray___2uxK_ {
  background-color: #a2a2a2;
}
.bgLightGray___2TkAO {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___h1ST- {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1j5L5 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3w90e {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2gRha {
  color: #3368c7 !important;
}
.textDarkBlue___1Rill {
  color: #023768 !important;
}
.textLightBlue___EaK84 {
  color: #b2cbf7;
}
.textDarkGray___3FwZq {
  color: #333333 !important;
}
.textMediumGray___3p6eJ {
  color: #a2a2a2 !important;
}
.textLightGray___2h8x7 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___rbdON {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___U_gE6 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3jS50 {
  border-radius: 5px !important;
}
.rounded-xs___1zamN {
  border-radius: 10px !important;
}
.rounded-sm___VL1V6 {
  border-radius: 15px !important;
}
.rounded-md___pbNEL {
  border-radius: 20px !important;
}
.rounded-lg___1nd-Z {
  border-radius: 25px !important;
}
.rounded-full___1-Mtp {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___peRFq {
  margin: 0;
}
.mt-2___iDNYx {
  margin-top: 0.5rem;
}
.my-2___2D6pp {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2fGon {
  padding: 0;
}
.px-1___3-Wwf {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3PNMB {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___z90qp {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1cwls {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2syDS {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2AEFW {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3Eidi {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3rYcb {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1iB6p {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2ddCW {
  padding-bottom: 20px;
}
.h-full___1VnJ5 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1H7Bx {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___28Q3H {
  font-weight: bold !important;
}
.fontWeight400___1yzUr {
  font-weight: 400 !important;
}
.fontWeight500___3zmYq {
  font-weight: 500 !important;
}
.fontWeight600___3cGOf {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2ul3J {
  font-size: 12px !important;
}
.fontSize14___20yrr {
  font-size: 14px !important;
}
.fontSize16___3G2Hv {
  font-size: 16px !important;
}
.fontSize18___3kV8V {
  font-size: 18px !important;
}
.fontSize20___1Hult {
  font-size: 20px !important;
}
.fontSize24___34COx {
  font-size: 24px !important;
}
.eventName___1Xqm7 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3Wgr0 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2lOLr > tr > th,
.ant-table-tbody___3RksP > tr.ant-table-row___AQtJY > td,
.ant-table-summary___3GLUN tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2lOLr > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3FiXo .ant-table-thead___2lOLr > tr > th,
.table-portrait___3FiXo .ant-table-tbody___3RksP > tr.ant-table-row___AQtJY > td,
.table-portrait___3FiXo .ant-table-summary___3GLUN tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3FiXo .bgLightGray___2TkAO td {
  background: #ebebeb;
}
.table-portrait___3FiXo .bgLightBlue___eSh1A td {
  background: #b2cbf7;
}
.eventContent___18Qxm .timeText___31KrL {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3RksP > tr.bgLightGray___2TkAO:hover > td,
.ant-table-tbody___3RksP > tr > td.ant-table-cell-row-hover___3GNPG {
  background: #ebebeb;
}
.ant-table-tbody___3RksP > tr.bgLightBlue___eSh1A:hover > td,
.ant-table-tbody___3RksP > tr > td.ant-table-cell-row-hover___3GNPG {
  background: #b2cbf7;
}
.ant-table-tbody___3RksP > tr.bgWhite___3SETZ:hover > td,
.ant-table-tbody___3RksP > tr > td.ant-table-cell-row-hover___3GNPG {
  background: #ffffff;
}
.changePassword___1KGZi {
  padding: 0 0 220px 0;
}
.changePassword___1KGZi .inputField___3IYZY {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
}
.changePassword___1KGZi .headTitle___2qMox {
  display: flex;
  align-items: center;
  margin: 58px auto 50px auto;
  padding: 0 15px;
  max-width: 1080px;
}
.changePassword___1KGZi .headTitle___2qMox .bolderIcon___2ck16 {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.changePassword___1KGZi .headTitle___2qMox .titleIcon___3MGiH {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.changePassword___1KGZi .headTitle___2qMox span {
  color: #3c3c3c;
  font-size: 30px;
}
.changePassword___1KGZi p {
  text-indent: 37px;
  font-size: 18px;
  padding: 0 15px;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
.changePassword___1KGZi .bodyContent___3o3zr {
  position: relative;
  padding: 30px 10px;
}
.changePassword___1KGZi .bodyContent___3o3zr .bodyContainer___PqWJq {
  max-width: 510px;
  margin: auto;
}
.changePassword___1KGZi .bodyContent___3o3zr .fieldName___JwIhm {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin: 16px 0 8px 0;
  line-height: 1;
  font-weight: 600;
}
.changePassword___1KGZi .bodyContent___3o3zr .fieldName___JwIhm .required___2JdFv {
  color: #fe0000;
  font-size: 18px;
  margin-left: 65px;
}
.changePassword___1KGZi .bodyContent___3o3zr span {
  font-size: 18px;
  line-height: 1;
}
.changePassword___1KGZi .bodyContent___3o3zr .inputField___3IYZY {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
}
.changePassword___1KGZi .bodyContent___3o3zr .btnZone___pv_p6 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 0;
}
.changePassword___1KGZi .bodyContent___3o3zr .btnZone___pv_p6 .signUpBtn___pnNvv {
  min-width: 265px;
  height: 50px;
  background-color: #1f3c53;
  color: #ffffff;
  font-size: 18px;
  border-radius: 8px;
  margin: auto;
}
.changePassword___1KGZi .ant-input-affix-wrapper > input.ant-input {
  font-size: 22px;
}
.changePassword___1KGZi .bgTransparent___WoA56 {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 111;
}
.changePassword___1KGZi .password___3iZtL {
  z-index: 9999;
}
