@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___SXLdr {
  display: flex;
  justify-content: center;
}
button.btn___3jzdn {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3jzdn {
    padding: 10px;
  }
}
button.btn___3jzdn:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3jzdn:focus {
  outline: none;
}
.btnWhite___2K8b-.btn___3jzdn {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2K8b-.btn___3jzdn:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3MzJ6.btn___3jzdn,
.btnGreen___3MzJ6.btn___3jzdn:hover,
.btnGreen___3MzJ6.btn___3jzdn:active,
.btnGreen___3MzJ6.btn___3jzdn:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2R5gv {
  color: #fe0000;
}
.errorMessage___2GMaC {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1DOCz {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2UiQg {
  display: none !important;
}
.m-auto___3U7ut {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1ppjl {
  max-width: 120px !important;
}
.fc___DrUob .fc-timegrid-col___1vT_X.fc-day-today___3m4QQ {
  background-color: #ffffff;
}
.fc___DrUob .fc-timegrid-slot___395Dt {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___DrUob .fc-timegrid-slot___395Dt {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___T9J14 .fc-direction-ltr___1nm5J .fc-timegrid-slot-label-frame___K4636 {
    font-size: 10px;
  }
  .bookingCalendar___T9J14 .fc___DrUob .fc-timegrid-slot-label-cushion___2d0s4 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___T9J14 .fc___DrUob .fc-scrollgrid-section-header___bZjPs .fc-scroller___1C_5f {
    overflow: unset !important;
  }
}
.ant-message-notice___1tC9y {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___pd9pT {
  width: 20px;
  height: 20px;
}
.bgTransparent___2r4mf {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___22nyn {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___LGOK0 {
  position: relative;
}
.inputCustomValue___LGOK0 .ant-input___tteGW {
  padding-right: 50px;
}
.inputCustomValueText___3Qcl1 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2Wkis .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2Wkis .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___aH457 .ant-radio-inner___1zQ6h {
  border-color: black !important ;
}
.ant-radio-checked___aH457 .ant-radio-inner___1zQ6h:after {
  background-color: black;
}
.ant-radio___2PB8A:hover .ant-radio-inner___1zQ6h {
  border-color: black;
}
.ant-radio-checked___aH457 .ant-radio-inner___1zQ6h:focus {
  border-color: black;
}
.modal___3S6Sf {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3S6Sf .close___2p_70 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3S6Sf .header___2sx0z {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3S6Sf .header___2sx0z p {
  margin: 0;
}
.modal___3S6Sf .content___14PD0 {
  text-align: center;
  font-size: 14px;
}
.modal___3S6Sf .content___14PD0 p {
  margin: 0;
}
.modal___3S6Sf .actions___2cMCH {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3S6Sf .actions___2cMCH .button___3DWXo {
  margin-top: 10px;
}
.modal___3S6Sf .actions___2cMCH:hover {
  color: #33c3c7;
}
.fc___DrUob .fc-timegrid-slot-minor___SPoFv {
  border-top-style: none;
}
.fc___DrUob .fc-timegrid-slot___395Dt {
  height: 1.5em !important;
}
.messageError___3sy6v .ant-message-custom-content___1sqGL {
  display: flex;
  text-align: start;
}
.messageError___3sy6v .ant-message-custom-content___1sqGL span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2hQnM {
  background-color: #e6e4e4;
}
.notifyMessage___2ohYG .ant-message-notice-content___3c7yV {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2ohYG .ant-message-notice-content___3c7yV .anticon___2sG9i {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2ohYG .ant-message-notice-content___3c7yV span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3Owlb {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3Owlb .ant-notification-notice-message___34btI,
.notifyCustomize___3Owlb .ant-notification-notice-close___353N-,
.notifyCustomize___3Owlb .ant-notification-notice-icon___1KHlq {
  color: white;
}
.notifyCustomize___3Owlb .ant-notification-notice-message___34btI {
  margin-left: 35px;
}
.notifyCustomize___3Owlb p {
  margin-bottom: 0;
}
.notifyCustomize___3Owlb p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3Te9D {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3Te9D .ant-notification-notice-message___34btI {
  margin-left: 35px;
}
.notifyAlertDuplicate___3Te9D p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3Te9D p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1iFqK {
  width: 434px !important;
}
.handleSubmitVote___1iFqK .ant-btn-primary___ppYpB {
  background: #59c3c7;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm .ant-modal-confirm-body___1iyWp {
  display: flex !important;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm .ant-modal-confirm-body___1iyWp .anticon___2sG9i {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm .ant-modal-confirm-btns___1wIa8 button {
  font-weight: bold;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm .ant-modal-confirm-btns___1wIa8 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm .ant-modal-confirm-btns___1wIa8 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1iFqK .ant-modal-body___32rMm .ant-modal-confirm-btns___1wIa8 .ant-btn-primary___ppYpB {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3Y11s {
  margin-bottom: 0;
}
.mt-0___3DZva {
  margin-top: 0;
}
.tooltipFormat___381C2 p {
  margin-bottom: 0;
}
.bgWhite___3lRv6 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2Z03H {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2Z03H:hover,
.bgPrimaryBlue___2Z03H:focus,
.bgPrimaryBlue___2Z03H:active {
  background: #4f7ac7;
}
.bgDarkBlue___113cO {
  background-color: #023768 !important;
}
.bgDarkBlue___113cO:hover,
.bgDarkBlue___113cO:focus,
.bgDarkBlue___113cO:active {
  background: #194d7d;
}
.bgGreyBlue___3iHKf {
  background-color: #ebf0f9;
}
.bgLightBlue___1UqUN {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2VJkg {
  background-color: #e4453a;
}
.bgLightRed___2VJkg:hover,
.bgLightRed___2VJkg:focus,
.bgLightRed___2VJkg:active {
  background: #e17871;
}
.bgLightYellow___3zqiS {
  background-color: #ffd603;
}
.bgDarkGray___swEf7 {
  background-color: #333333;
}
.bgMediumGray___2diy- {
  background-color: #a2a2a2;
}
.bgLightGray___2rMe_ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1Rr3j {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1h6Rz {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2bDL5 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1Z2Vk {
  color: #3368c7 !important;
}
.textDarkBlue___1HG5s {
  color: #023768 !important;
}
.textLightBlue___smHe9 {
  color: #b2cbf7;
}
.textDarkGray___2apNk {
  color: #333333 !important;
}
.textMediumGray___24C2X {
  color: #a2a2a2 !important;
}
.textLightGray___221j8 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___rqjc0 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___21IGv {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___c639I {
  border-radius: 5px !important;
}
.rounded-xs___27p6W {
  border-radius: 10px !important;
}
.rounded-sm___2wO9I {
  border-radius: 15px !important;
}
.rounded-md___W3ZA_ {
  border-radius: 20px !important;
}
.rounded-lg___bqsRA {
  border-radius: 25px !important;
}
.rounded-full___e65fH {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___CcsLS {
  margin: 0;
}
.mt-2___y0287 {
  margin-top: 0.5rem;
}
.my-2___3d_Xg {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3QO9T {
  padding: 0;
}
.px-1___3JxK2 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2nbsH {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1Einl {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2MQcw {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1_gDR {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2r85S {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___16-x9 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2gBpt {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3EoWR {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___32ulb {
  padding-bottom: 20px;
}
.h-full___3LFNJ {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___fbMoJ {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3ZG3P {
  font-weight: bold !important;
}
.fontWeight400___3fXGv {
  font-weight: 400 !important;
}
.fontWeight500___EI-kK {
  font-weight: 500 !important;
}
.fontWeight600___C964R {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___23pb9 {
  font-size: 12px !important;
}
.fontSize14___3eStI {
  font-size: 14px !important;
}
.fontSize16___20zm_ {
  font-size: 16px !important;
}
.fontSize18___3Od6W {
  font-size: 18px !important;
}
.fontSize20___a7qHz {
  font-size: 20px !important;
}
.fontSize24___35dUO {
  font-size: 24px !important;
}
.eventName___ZnanF {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___ohtRz {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___21XdG > tr > th,
.ant-table-tbody___3LXF_ > tr.ant-table-row___3tWpf > td,
.ant-table-summary___178cY tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___21XdG > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3oNfx .ant-table-thead___21XdG > tr > th,
.table-portrait___3oNfx .ant-table-tbody___3LXF_ > tr.ant-table-row___3tWpf > td,
.table-portrait___3oNfx .ant-table-summary___178cY tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3oNfx .bgLightGray___2rMe_ td {
  background: #ebebeb;
}
.table-portrait___3oNfx .bgLightBlue___1UqUN td {
  background: #b2cbf7;
}
.eventContent___3DmNS .timeText___14l9p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3LXF_ > tr.bgLightGray___2rMe_:hover > td,
.ant-table-tbody___3LXF_ > tr > td.ant-table-cell-row-hover___24Mrx {
  background: #ebebeb;
}
.ant-table-tbody___3LXF_ > tr.bgLightBlue___1UqUN:hover > td,
.ant-table-tbody___3LXF_ > tr > td.ant-table-cell-row-hover___24Mrx {
  background: #b2cbf7;
}
.ant-table-tbody___3LXF_ > tr.bgWhite___3lRv6:hover > td,
.ant-table-tbody___3LXF_ > tr > td.ant-table-cell-row-hover___24Mrx {
  background: #ffffff;
}
.collaborationContainer___2RQhE {
  max-width: 1080px;
  margin: auto;
  width: 100%;
}
.collaborationContainer___2RQhE .header___2sx0z {
  font-size: 24px;
  font-weight: bold;
}
.collaborationContainer___2RQhE .btnSocial___v-JNJ {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  color: #555555;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #999999;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
}
.collaborationContainer___2RQhE .btnSocial___v-JNJ img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.collaborationContainer___2RQhE .avtImage___2P9y5 {
  border-radius: 50%;
  border: 3px solid #33c3c7;
  display: inline-table;
}
.collaborationContainer___2RQhE .informationForm___22C5N {
  padding: 0 35px;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .informationForm___22C5N {
    padding: 0;
  }
}
.collaborationContainer___2RQhE .headTitle___2fYt2 {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .headTitle___2fYt2 {
    margin-bottom: 20px;
  }
}
.collaborationContainer___2RQhE .headTitle___2fYt2 .bolderIcon___APwhX {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.collaborationContainer___2RQhE .headTitle___2fYt2 .titleIcon___3iIyK {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.collaborationContainer___2RQhE .headTitle___2fYt2 span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .headTitle___2fYt2 span {
    font-size: 20px;
  }
}
.collaborationContainer___2RQhE .ant-avatar img {
  object-fit: fill;
}
.collaborationContainer___2RQhE .ant-avatar.ant-avatar-circle {
  border: 0;
}
.collaborationContainer___2RQhE input {
  height: 58px;
  border-radius: 16px !important;
  font-size: 16px;
}
.collaborationContainer___2RQhE label {
  font-size: 16px;
}
.collaborationContainer___2RQhE .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}
.collaborationContainer___2RQhE .btn.btn-custom-height {
  font-size: 16px;
}
.collaborationContainer___2RQhE .avatarRow___1AcVf .avatarButton___1Tmr2 {
  margin-left: 50px;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .avatarRow___1AcVf .avatarButton___1Tmr2 {
    margin-left: 25px;
  }
}
.collaborationContainer___2RQhE .urlRow___2dgAK .ant-input-group-addon {
  background: none;
  border: none;
  padding-left: 0;
  font-size: 16px;
}
.collaborationContainer___2RQhE .urlRow___2dgAK .ant-input {
  border-radius: 8px;
}
.collaborationContainer___2RQhE .wrapperAvatar___3TeIw {
  width: 136px;
  height: 136px;
}
.collaborationContainer___2RQhE .wrapperAvatar___3TeIw img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.collaborationContainer___2RQhE .groupPart___3Bm_l {
  margin-bottom: 50px;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 10px;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .groupPart___3Bm_l {
    margin-bottom: 50px;
  }
}
.collaborationContainer___2RQhE .groupPart___3Bm_l .partName___1Tcmk {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .groupPart___3Bm_l .partName___1Tcmk {
    margin-bottom: 30px;
  }
}
.collaborationContainer___2RQhE .groupPart___3Bm_l .disconnectBtn___32Lwh:not(:disabled) {
  color: #ffffff;
  background-color: #b6b6b6;
}
.collaborationContainer___2RQhE .groupPart___3Bm_l .disconnectBtn___32Lwh:not(:disabled):hover {
  color: #ffffff;
  background-color: #b6b6b6;
  opacity: 0.8;
}
.collaborationContainer___2RQhE .groupPart___3Bm_l .partNameTitle___3wG7r {
  font-size: 30px;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .collaborationContainer___2RQhE .groupPart___3Bm_l .partNameTitle___3wG7r {
    font-size: 18px;
  }
}
.collaborationContainer___2RQhE .groupPart___3Bm_l .partNameBorder___1yVBC {
  display: inline-block;
  width: 9px;
  height: 30px;
  background: #3c3c3c;
  margin-right: 10px;
}
.collaborationContainer___2RQhE .backToHome___2wRNs button {
  height: 30px;
  font-size: 20px;
  width: 209px;
}
