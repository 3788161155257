@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2exq_ {
  display: flex;
  justify-content: center;
}
button.btn___2Io2u {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2Io2u {
    padding: 10px;
  }
}
button.btn___2Io2u:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2Io2u:focus {
  outline: none;
}
.btnWhite___35qPE.btn___2Io2u {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___35qPE.btn___2Io2u:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2jwAU.btn___2Io2u,
.btnGreen___2jwAU.btn___2Io2u:hover,
.btnGreen___2jwAU.btn___2Io2u:active,
.btnGreen___2jwAU.btn___2Io2u:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1Z22L {
  color: #fe0000;
}
.errorMessage___38-nQ {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3JeIz {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___iEwXq {
  display: none !important;
}
.m-auto___3mfnt {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___19OZN {
  max-width: 120px !important;
}
.fc___1V2cD .fc-timegrid-col___1v6ou.fc-day-today___3Ls7l {
  background-color: #ffffff;
}
.fc___1V2cD .fc-timegrid-slot___3-hv2 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1V2cD .fc-timegrid-slot___3-hv2 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3AEBl .fc-direction-ltr___1xTmF .fc-timegrid-slot-label-frame___1VqMb {
    font-size: 10px;
  }
  .bookingCalendar___3AEBl .fc___1V2cD .fc-timegrid-slot-label-cushion___2Ld0y {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3AEBl .fc___1V2cD .fc-scrollgrid-section-header___29FLT .fc-scroller___3rRWY {
    overflow: unset !important;
  }
}
.ant-message-notice___2fHDk {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2gW_P {
  width: 20px;
  height: 20px;
}
.bgTransparent___ePz-s {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___2l4qS {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___18ZA0 {
  position: relative;
}
.inputCustomValue___18ZA0 .ant-input___3TA7n {
  padding-right: 50px;
}
.inputCustomValueText___rgV0s {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1flp2 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1flp2 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3vjdc .ant-radio-inner___n8UYr {
  border-color: black !important ;
}
.ant-radio-checked___3vjdc .ant-radio-inner___n8UYr:after {
  background-color: black;
}
.ant-radio___3XTG1:hover .ant-radio-inner___n8UYr {
  border-color: black;
}
.ant-radio-checked___3vjdc .ant-radio-inner___n8UYr:focus {
  border-color: black;
}
.modal___izHpd {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___izHpd .close___28F9E {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___izHpd .header___6RAw9 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___izHpd .header___6RAw9 p {
  margin: 0;
}
.modal___izHpd .content___MrQ3S {
  text-align: center;
  font-size: 14px;
}
.modal___izHpd .content___MrQ3S p {
  margin: 0;
}
.modal___izHpd .actions___1fwBP {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___izHpd .actions___1fwBP .button___pIexV {
  margin-top: 10px;
}
.modal___izHpd .actions___1fwBP:hover {
  color: #33c3c7;
}
.fc___1V2cD .fc-timegrid-slot-minor___-Br_I {
  border-top-style: none;
}
.fc___1V2cD .fc-timegrid-slot___3-hv2 {
  height: 1.5em !important;
}
.messageError___3lzQw .ant-message-custom-content___3z9iz {
  display: flex;
  text-align: start;
}
.messageError___3lzQw .ant-message-custom-content___3z9iz span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___rEGcb {
  background-color: #e6e4e4;
}
.notifyMessage___s0Ken .ant-message-notice-content___3oxLe {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___s0Ken .ant-message-notice-content___3oxLe .anticon___3BvBt {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___s0Ken .ant-message-notice-content___3oxLe span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2D9s7 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2D9s7 .ant-notification-notice-message___3cSNA,
.notifyCustomize___2D9s7 .ant-notification-notice-close___1D0N5,
.notifyCustomize___2D9s7 .ant-notification-notice-icon___3-Lky {
  color: white;
}
.notifyCustomize___2D9s7 .ant-notification-notice-message___3cSNA {
  margin-left: 35px;
}
.notifyCustomize___2D9s7 p {
  margin-bottom: 0;
}
.notifyCustomize___2D9s7 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2AprZ {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2AprZ .ant-notification-notice-message___3cSNA {
  margin-left: 35px;
}
.notifyAlertDuplicate___2AprZ p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2AprZ p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3vT0t {
  width: 434px !important;
}
.handleSubmitVote___3vT0t .ant-btn-primary___1aNTz {
  background: #59c3c7;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH .ant-modal-confirm-body___149hu {
  display: flex !important;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH .ant-modal-confirm-body___149hu .anticon___3BvBt {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH .ant-modal-confirm-btns___1hZUL button {
  font-weight: bold;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH .ant-modal-confirm-btns___1hZUL button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH .ant-modal-confirm-btns___1hZUL button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3vT0t .ant-modal-body___2HimH .ant-modal-confirm-btns___1hZUL .ant-btn-primary___1aNTz {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___20aoa {
  margin-bottom: 0;
}
.mt-0___3su1i {
  margin-top: 0;
}
.tooltipFormat___1gHXB p {
  margin-bottom: 0;
}
.bgWhite___GQ-kp {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2WzOq {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2WzOq:hover,
.bgPrimaryBlue___2WzOq:focus,
.bgPrimaryBlue___2WzOq:active {
  background: #4f7ac7;
}
.bgDarkBlue___3wI8Q {
  background-color: #023768 !important;
}
.bgDarkBlue___3wI8Q:hover,
.bgDarkBlue___3wI8Q:focus,
.bgDarkBlue___3wI8Q:active {
  background: #194d7d;
}
.bgGreyBlue___2iUGD {
  background-color: #ebf0f9;
}
.bgLightBlue___2LY5f {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3tkeW {
  background-color: #e4453a;
}
.bgLightRed___3tkeW:hover,
.bgLightRed___3tkeW:focus,
.bgLightRed___3tkeW:active {
  background: #e17871;
}
.bgLightYellow___mFcr9 {
  background-color: #ffd603;
}
.bgDarkGray___12fz- {
  background-color: #333333;
}
.bgMediumGray___2MxJU {
  background-color: #a2a2a2;
}
.bgLightGray___2HLna {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___22oDQ {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___10N9E {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3vd2E {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2G_wo {
  color: #3368c7 !important;
}
.textDarkBlue___1R3EA {
  color: #023768 !important;
}
.textLightBlue___uGXtw {
  color: #b2cbf7;
}
.textDarkGray___1jVvx {
  color: #333333 !important;
}
.textMediumGray___3YxcD {
  color: #a2a2a2 !important;
}
.textLightGray___32mEU {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3Et0L {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___vI_Jy {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3VCrL {
  border-radius: 5px !important;
}
.rounded-xs___23nSW {
  border-radius: 10px !important;
}
.rounded-sm___2SANQ {
  border-radius: 15px !important;
}
.rounded-md___1vuxO {
  border-radius: 20px !important;
}
.rounded-lg___3uDP1 {
  border-radius: 25px !important;
}
.rounded-full___2E25l {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___W340q {
  margin: 0;
}
.mt-2___BqQYp {
  margin-top: 0.5rem;
}
.my-2___1a6Zg {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3aZ-Y {
  padding: 0;
}
.px-1___1qGOs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___D-vWs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2MdBw {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___18rva {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1R1ud {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2QoOM {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___21CF_ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___mpIMX {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___jG8tj {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___7_yM_ {
  padding-bottom: 20px;
}
.h-full___1JFHr {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1_GUN {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3toa4 {
  font-weight: bold !important;
}
.fontWeight400___Ihkjy {
  font-weight: 400 !important;
}
.fontWeight500___3xqGT {
  font-weight: 500 !important;
}
.fontWeight600___26AmF {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1Lg_2 {
  font-size: 12px !important;
}
.fontSize14___2xcbG {
  font-size: 14px !important;
}
.fontSize16___1tF-o {
  font-size: 16px !important;
}
.fontSize18___3N0yZ {
  font-size: 18px !important;
}
.fontSize20___38kDm {
  font-size: 20px !important;
}
.fontSize24___3Wxmb {
  font-size: 24px !important;
}
.eventName___3fU_q {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1vwfD {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___256hU > tr > th,
.ant-table-tbody___2wXMz > tr.ant-table-row___2i8dn > td,
.ant-table-summary___12481 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___256hU > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1SciY .ant-table-thead___256hU > tr > th,
.table-portrait___1SciY .ant-table-tbody___2wXMz > tr.ant-table-row___2i8dn > td,
.table-portrait___1SciY .ant-table-summary___12481 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1SciY .bgLightGray___2HLna td {
  background: #ebebeb;
}
.table-portrait___1SciY .bgLightBlue___2LY5f td {
  background: #b2cbf7;
}
.eventContent___2jfVR .timeText___3oVUb {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2wXMz > tr.bgLightGray___2HLna:hover > td,
.ant-table-tbody___2wXMz > tr > td.ant-table-cell-row-hover___S0sxo {
  background: #ebebeb;
}
.ant-table-tbody___2wXMz > tr.bgLightBlue___2LY5f:hover > td,
.ant-table-tbody___2wXMz > tr > td.ant-table-cell-row-hover___S0sxo {
  background: #b2cbf7;
}
.ant-table-tbody___2wXMz > tr.bgWhite___GQ-kp:hover > td,
.ant-table-tbody___2wXMz > tr > td.ant-table-cell-row-hover___S0sxo {
  background: #ffffff;
}
.cancelBooking___gcJtf {
  width: 100%;
  margin: auto;
  max-width: 1080px;
  padding: 60px 15px 0;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf {
    padding: 60px 15px 80px;
  }
}
.cancelBooking___gcJtf .progressBar___1R9L- {
  display: flex;
  position: relative;
  max-width: 375px;
  margin: 0 auto 80px auto;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .progressBar___1R9L- {
    padding: 0 40px 0 30px;
  }
}
.cancelBooking___gcJtf .progressBar___1R9L- .firstStep___weG1v {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 12px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.cancelBooking___gcJtf .progressBar___1R9L- .firstStep___weG1v p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  color: #414141;
  font-size: 13px;
  min-width: 120px;
  text-align: center;
}
.cancelBooking___gcJtf .progressBar___1R9L- .activeStep___1W4Y- {
  background-color: #33c3c7;
  color: #eff6fc;
  box-shadow: none;
}
.cancelBooking___gcJtf .progressBar___1R9L- .dottedBar___17pU8 {
  height: 30px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.cancelBooking___gcJtf .progressBar___1R9L- .dottedBar___17pU8::before {
  content: '.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   ';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  font-size: 20px;
  line-height: 1;
  color: #a1a0a0;
}
.cancelBooking___gcJtf .progressBar___1R9L- .secondStep___3falF {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 12px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc30;
  cursor: pointer;
  position: relative;
}
.cancelBooking___gcJtf .progressBar___1R9L- .secondStep___3falF p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  color: #414141;
  font-size: 13px;
  min-width: 120px;
  text-align: center;
}
.cancelBooking___gcJtf .pageTitle___vb2Ku {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 38px;
  margin-left: 12px;
}
.cancelBooking___gcJtf .pageTitle___vb2Ku .titleIcon___1DN0G {
  width: 8px;
  height: 23px;
  background-color: #33c3c7;
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
}
.cancelBooking___gcJtf .pageTitle___vb2Ku span {
  font-size: 24px;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .pageTitle___vb2Ku span {
    font-size: 20px;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi {
  display: flex;
  margin-bottom: 36px;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .divTitle___2KMK7 {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .divTitle___2KMK7 .blackIcon___1xBZC {
  width: 8px;
  height: 18px;
  background-color: #3c3c3c;
  margin-right: 6px;
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .divTitle___2KMK7 span {
  color: #3c3c3c;
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi .divTitle___2KMK7 span {
    font-size: 16px;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .comment___1huQB {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi .comment___1huQB {
    width: 100%;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .comment___1huQB .note___2oH-5 {
  font-size: 18px;
  color: #3c3c3c;
  font-family: '';
  padding-left: 12px;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi .comment___1huQB .note___2oH-5 {
    font-size: 16px;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .comment___1huQB textarea {
  height: 185px;
  width: 490px;
  border-radius: 10px;
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S {
    width: 100%;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM {
  padding: 0 12px;
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM > p:first-child {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM > p:first-child {
    font-size: 16px;
  }
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM .groupLine___jZkwK {
  margin: 12px 0;
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM .groupLine___jZkwK p {
  margin: 0;
}
.cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM p {
  line-height: 1.5;
  margin-bottom: 12px;
  font-size: 17px;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .cancelationDetail___2ekRi .content___MrQ3S .detail___ULgFM p {
    font-size: 16px;
  }
}
.cancelBooking___gcJtf .btnZone___XIgoB {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .btnZone___XIgoB {
    flex-wrap: wrap;
  }
}
.cancelBooking___gcJtf .btnZone___XIgoB .backBtn___3AJJk {
  height: 49px;
  min-width: 150px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 1px 1px 15px #b3bcbc60;
  color: #9c9c9c;
  font-size: 18px;
  border: none;
  margin: 30px 10px;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .btnZone___XIgoB .backBtn___3AJJk {
    margin: 10px;
  }
}
.cancelBooking___gcJtf .btnZone___XIgoB .confirmCancelBtn___13FMm {
  height: 49px;
  min-width: 312px;
  margin: 20px 10px;
  border-radius: 20px;
  color: #ffffff;
  font-size: 18px;
  background-color: #33c3c7;
  border: none;
}
@media screen and (max-width: 767px) {
  .cancelBooking___gcJtf .btnZone___XIgoB .confirmCancelBtn___13FMm {
    margin: 0;
  }
}
.cancelBookingLayout___2QuRc {
  position: relative;
  min-height: 100vh;
  padding-bottom: 80px;
}
