.signUp___18rIT {
  max-width: 510px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .signUp___18rIT {
    padding: 0 15px;
  }
}
.signUp___18rIT .fieldName___61nUj {
  font-size: 25px;
  color: #3c3c3c;
  margin: 40px 0 10px 0;
  line-height: 1;
}
.signUp___18rIT .fieldName___61nUj .required___2ATqf {
  color: #fe0000;
  font-size: 18px;
  margin-left: 65px;
}
@media screen and (max-width: 767px) {
  .signUp___18rIT .fieldName___61nUj .required___2ATqf {
    margin-left: 20px;
  }
}
.signUp___18rIT .passNotes___Djnt- {
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .signUp___18rIT .passNotes___Djnt- {
    font-size: 15px;
  }
}
.signUp___18rIT .inputField___R5FB9 {
  width: 100%;
  height: 58px;
  border-radius: 20px;
  font-size: 22px;
}
.signUp___18rIT .checkerViet___3h_S- {
  margin: 90px 0 40px 0;
  text-align: center;
}
.signUp___18rIT .useRecommendPass___bSMwy {
  width: 100%;
  height: 54px;
  border-radius: 20px;
  box-shadow: 0px 3px 2px #bfbfbf;
  background-color: #e3f4f4;
  color: #686868;
  font-size: 18px;
}
.signUp___18rIT .btnZone___30tsS {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.signUp___18rIT .btnZone___30tsS .signUpBtn___1WMYq {
  min-width: 265px;
  height: 50px;
  background-color: #33c3c7;
  color: #ffffff;
  font-size: 18px;
  border-radius: 20px;
  margin: auto;
}
.signUp___18rIT .remindOpenMail___2XDsR {
  font-size: 15px;
  color: #3c3c3c;
  text-align: center;
  margin-bottom: 230px;
}
.signUp___18rIT .passwordAuto___1ny1e {
  margin-bottom: 10px;
}
.signUp___18rIT .ant-checkbox-inner {
  border: 2px solid #e2e2e2 !important;
}
.signUp___18rIT .ant-checkbox-checked .ant-checkbox-inner {
  border: 2px solid #33c3c7 !important;
  background-color: #33c3c7;
}
.signUp___18rIT .ant-input-affix-wrapper > input.ant-input {
  font-size: 22px;
}
.signUp___18rIT .bgTransparent___3gkUh {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 111;
}
.signUp___18rIT .password___2koBz {
  z-index: 9999;
}
