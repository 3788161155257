@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___JPYUp {
  display: flex;
  justify-content: center;
}
button.btn___32_kn {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___32_kn {
    padding: 10px;
  }
}
button.btn___32_kn:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___32_kn:focus {
  outline: none;
}
.btnWhite___FMLOI.btn___32_kn {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___FMLOI.btn___32_kn:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___p_SF4.btn___32_kn,
.btnGreen___p_SF4.btn___32_kn:hover,
.btnGreen___p_SF4.btn___32_kn:active,
.btnGreen___p_SF4.btn___32_kn:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___VDmdv {
  color: #fe0000;
}
.errorMessage___1le4K {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2cdbE {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___19WNh {
  display: none !important;
}
.m-auto___14_lK {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2if0K {
  max-width: 120px !important;
}
.fc___27jUZ .fc-timegrid-col___xLetS.fc-day-today___1h6uf {
  background-color: #ffffff;
}
.fc___27jUZ .fc-timegrid-slot___gtcIj {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___27jUZ .fc-timegrid-slot___gtcIj {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___aZLvu .fc-direction-ltr___3m31d .fc-timegrid-slot-label-frame___YGKMS {
    font-size: 10px;
  }
  .bookingCalendar___aZLvu .fc___27jUZ .fc-timegrid-slot-label-cushion___7a0zZ {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___aZLvu .fc___27jUZ .fc-scrollgrid-section-header___H6b3C .fc-scroller___1Vra8 {
    overflow: unset !important;
  }
}
.ant-message-notice___OZNhN {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2EmNS {
  width: 20px;
  height: 20px;
}
.bgTransparent___3z4P7 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___YBz8M {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___JHEBt {
  position: relative;
}
.inputCustomValue___JHEBt .ant-input___3QuoA {
  padding-right: 50px;
}
.inputCustomValueText___Je1Df {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___TdGaE .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___TdGaE .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___140BY .ant-radio-inner___l6dGt {
  border-color: black !important ;
}
.ant-radio-checked___140BY .ant-radio-inner___l6dGt:after {
  background-color: black;
}
.ant-radio___dsiVx:hover .ant-radio-inner___l6dGt {
  border-color: black;
}
.ant-radio-checked___140BY .ant-radio-inner___l6dGt:focus {
  border-color: black;
}
.modal___3o2oo {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3o2oo .close___3bAHD {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3o2oo .header___YvmZP {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3o2oo .header___YvmZP p {
  margin: 0;
}
.modal___3o2oo .content___ISbf8 {
  text-align: center;
  font-size: 14px;
}
.modal___3o2oo .content___ISbf8 p {
  margin: 0;
}
.modal___3o2oo .actions___3nd1- {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3o2oo .actions___3nd1- .button___3HdS0 {
  margin-top: 10px;
}
.modal___3o2oo .actions___3nd1-:hover {
  color: #33c3c7;
}
.fc___27jUZ .fc-timegrid-slot-minor___Vq1Jx {
  border-top-style: none;
}
.fc___27jUZ .fc-timegrid-slot___gtcIj {
  height: 1.5em !important;
}
.messageError___1mG6- .ant-message-custom-content___3D-X2 {
  display: flex;
  text-align: start;
}
.messageError___1mG6- .ant-message-custom-content___3D-X2 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___10bmM {
  background-color: #e6e4e4;
}
.notifyMessage___1JkHq .ant-message-notice-content___2qWu_ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1JkHq .ant-message-notice-content___2qWu_ .anticon___1zLQw {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1JkHq .ant-message-notice-content___2qWu_ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1TG-e {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1TG-e .ant-notification-notice-message___XCyo6,
.notifyCustomize___1TG-e .ant-notification-notice-close___1Setk,
.notifyCustomize___1TG-e .ant-notification-notice-icon___1-4pr {
  color: white;
}
.notifyCustomize___1TG-e .ant-notification-notice-message___XCyo6 {
  margin-left: 35px;
}
.notifyCustomize___1TG-e p {
  margin-bottom: 0;
}
.notifyCustomize___1TG-e p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___387aa {
  padding: 16px 14px;
}
.notifyAlertDuplicate___387aa .ant-notification-notice-message___XCyo6 {
  margin-left: 35px;
}
.notifyAlertDuplicate___387aa p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___387aa p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___20g3u {
  width: 434px !important;
}
.handleSubmitVote___20g3u .ant-btn-primary___2p3la {
  background: #59c3c7;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 .ant-modal-confirm-body___2Y1U9 {
  display: flex !important;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 .ant-modal-confirm-body___2Y1U9 .anticon___1zLQw {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 .ant-modal-confirm-btns___1p2hB button {
  font-weight: bold;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 .ant-modal-confirm-btns___1p2hB button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 .ant-modal-confirm-btns___1p2hB button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___20g3u .ant-modal-body___1ntK9 .ant-modal-confirm-btns___1p2hB .ant-btn-primary___2p3la {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2idzN {
  margin-bottom: 0;
}
.mt-0___3vZLS {
  margin-top: 0;
}
.tooltipFormat___t3RyL p {
  margin-bottom: 0;
}
.bgWhite___3s4zv {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___32FlC {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___32FlC:hover,
.bgPrimaryBlue___32FlC:focus,
.bgPrimaryBlue___32FlC:active {
  background: #4f7ac7;
}
.bgDarkBlue___sVfbr {
  background-color: #023768 !important;
}
.bgDarkBlue___sVfbr:hover,
.bgDarkBlue___sVfbr:focus,
.bgDarkBlue___sVfbr:active {
  background: #194d7d;
}
.bgGreyBlue___Tc62T {
  background-color: #ebf0f9;
}
.bgLightBlue___lMooN {
  background-color: #b2cbf7 !important;
}
.bgLightRed___VPJ9Q {
  background-color: #e4453a;
}
.bgLightRed___VPJ9Q:hover,
.bgLightRed___VPJ9Q:focus,
.bgLightRed___VPJ9Q:active {
  background: #e17871;
}
.bgLightYellow___2H18f {
  background-color: #ffd603;
}
.bgDarkGray___2lcWk {
  background-color: #333333;
}
.bgMediumGray___GI2E3 {
  background-color: #a2a2a2;
}
.bgLightGray___MM4OJ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2B95U {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2szhs {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3BRVk {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2ovln {
  color: #3368c7 !important;
}
.textDarkBlue___3tLUx {
  color: #023768 !important;
}
.textLightBlue___3cQCi {
  color: #b2cbf7;
}
.textDarkGray___3ODaG {
  color: #333333 !important;
}
.textMediumGray___38z8Z {
  color: #a2a2a2 !important;
}
.textLightGray___qxvGI {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___30YeP {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1j6mm {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___25-_Y {
  border-radius: 5px !important;
}
.rounded-xs___1U-J9 {
  border-radius: 10px !important;
}
.rounded-sm___PQrMD {
  border-radius: 15px !important;
}
.rounded-md___1f-JY {
  border-radius: 20px !important;
}
.rounded-lg___34Oiy {
  border-radius: 25px !important;
}
.rounded-full___jqMki {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1ajB5 {
  margin: 0;
}
.mt-2___2fKr4 {
  margin-top: 0.5rem;
}
.my-2___KSFbo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3jhn1 {
  padding: 0;
}
.px-1___ENpqZ {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1gTmB {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2deIm {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___NVWDU {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2crrj {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1-fUn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3-TrR {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1-i3H {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1YuTq {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3ctIu {
  padding-bottom: 20px;
}
.h-full___1G9pR {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2-0VR {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1C8P5 {
  font-weight: bold !important;
}
.fontWeight400___3XfeK {
  font-weight: 400 !important;
}
.fontWeight500___2XfvV {
  font-weight: 500 !important;
}
.fontWeight600___3-0le {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12____CBar {
  font-size: 12px !important;
}
.fontSize14___3zl_e {
  font-size: 14px !important;
}
.fontSize16___2iF1R {
  font-size: 16px !important;
}
.fontSize18___2CZP_ {
  font-size: 18px !important;
}
.fontSize20___190-x {
  font-size: 20px !important;
}
.fontSize24___3s4eI {
  font-size: 24px !important;
}
.eventName___2TBep {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___DkG55 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___4D7Rb > tr > th,
.ant-table-tbody___2CzHU > tr.ant-table-row___3boLh > td,
.ant-table-summary___wOF5E tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___4D7Rb > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___TzMlt .ant-table-thead___4D7Rb > tr > th,
.table-portrait___TzMlt .ant-table-tbody___2CzHU > tr.ant-table-row___3boLh > td,
.table-portrait___TzMlt .ant-table-summary___wOF5E tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___TzMlt .bgLightGray___MM4OJ td {
  background: #ebebeb;
}
.table-portrait___TzMlt .bgLightBlue___lMooN td {
  background: #b2cbf7;
}
.eventContent___3OTqF .timeText___21slp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2CzHU > tr.bgLightGray___MM4OJ:hover > td,
.ant-table-tbody___2CzHU > tr > td.ant-table-cell-row-hover___1VO8E {
  background: #ebebeb;
}
.ant-table-tbody___2CzHU > tr.bgLightBlue___lMooN:hover > td,
.ant-table-tbody___2CzHU > tr > td.ant-table-cell-row-hover___1VO8E {
  background: #b2cbf7;
}
.ant-table-tbody___2CzHU > tr.bgWhite___3s4zv:hover > td,
.ant-table-tbody___2CzHU > tr > td.ant-table-cell-row-hover___1VO8E {
  background: #ffffff;
}
.modalInputNumber___8xScu {
  width: 100% !important;
}
.btnZoneModal___1Jwkg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.btnZoneModal___1Jwkg .updateBtn___2gKqN {
  height: 35px;
  font-size: 14px;
  color: #fffefe;
  border-radius: 19px;
  background-color: #33c3c7;
  border-color: #33c3c7;
  margin: 0 20px;
  width: 100%;
  max-width: 150px;
  padding: 0 15px;
}
.btnZoneModal___1Jwkg .cancelBtn___ju8AG {
  height: 35px;
  font-size: 14px;
  color: #535353;
  border-radius: 19px;
  background-color: #fffefe;
  margin: 0 20px;
  box-shadow: 1px 1px 15px #b3bcbc60;
  border: none;
  width: 100%;
  max-width: 150px;
  padding: 0 15px;
}
.errorNotice___2Wp0B {
  color: #ff4d4f;
  font-size: 12px;
}
.changeTime___3n8e5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 60px;
}
.changeTime___3n8e5 .center___3jT1v {
  text-align: center;
}
.changeTime___3n8e5 .title___1uLrE {
  font-size: 26px;
  font-weight: 700;
  border-bottom: 4px solid #33c3c7;
  display: inline-block;
  margin-bottom: 50px;
}
.changeTime___3n8e5 .description___Q_U3E {
  display: flex;
  align-items: center;
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .changeTime___3n8e5 .description___Q_U3E {
    font-size: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .changeTime___3n8e5 .description___Q_U3E img {
    margin-top: -7px;
    width: 29px;
    height: 25px;
  }
}
@media screen and (max-width: 767px) {
  .changeTime___3n8e5 .description___Q_U3E div {
    text-align: left;
  }
}
.changeTime___3n8e5 .description2___BNr5C {
  font-size: 22px;
  text-align: left;
  padding-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .changeTime___3n8e5 .description2___BNr5C {
    font-size: 12px;
  }
}
.changeTime___3n8e5 .form___HDf6B {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.changeTime___3n8e5 .form___HDf6B .text___2EPw- {
  margin-right: 45px;
  font-size: 22px;
}
.changeTime___3n8e5 .form___HDf6B .text___2EPw-:last-child {
  margin-right: 0;
}
.changeTime___3n8e5 .form___HDf6B .ant-form-item {
  margin-bottom: 0;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number {
  width: 58px;
  height: 58px;
  border-radius: 13px;
  border: 0;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  margin-right: 10px;
}
.changeTime___3n8e5 .form___HDf6B .ant-form-item .ant-input-number-handler-wrap {
  opacity: 1;
  border: 0;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-up {
  position: absolute;
  top: -57px;
  width: 58px;
  left: -36px;
  height: 42px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAnElEQVQokY3RsQpBcRTH8Q/dsngEkcED2O9AGcQDyCuYzEoms81mlVWRwW402O02D0AyIFL3f+9vOp3fOd9+nZOL41hABUywwSFpKAoR0EQb9xAkHwBMsUMdY2xRywqpYo/RX7+DI/ppkB5OaCWkK2KJ2e9u/sdcYPWu0zTEGQ1ehy1jji6uuGWA5FDBGoPI90NHXPDIAPmkL6HwBMCWF0Xn+GuwAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-up svg {
  display: none;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-up:hover {
  height: 42px !important;
  background-color: #ddf6f5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAnElEQVQokY3RsQpBcRTH8Q/dsngEkcED2O9AGcQDyCuYzEoms81mlVWRwW402O02D0AyIFL3f+9vOp3fOd9+nZOL41hABUywwSFpKAoR0EQb9xAkHwBMsUMdY2xRywqpYo/RX7+DI/ppkB5OaCWkK2KJ2e9u/sdcYPWu0zTEGQ1ehy1jji6uuGWA5FDBGoPI90NHXPDIAPmkL6HwBMCWF0Xn+GuwAAAAAElFTkSuQmCC);
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-up:active {
  height: 42px !important;
  background-color: #33c3c7;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAJJJREFUKJGNkSEOwlAQBaekSU0lkpAguASqOBIOQLgC9wCLw3EMVH0lV8DiEJUQBkMINJ//O8m6eW+TXVQiU6g7dRbzcuLMgQXwAJq/VmTD1l9O6jTkhsITtTZMq65TJau3mGKvDrolpXrsEf7molYqmToGDsASuAH3xLEBMmAItMAmh8+HzsAVePYoASiBEVC8AI7WLXosqfA2AAAAAElFTkSuQmCC);
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down {
  position: absolute;
  bottom: -131px;
  border: 0;
  height: auto;
  width: 66px;
  left: -40px;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down svg {
  display: none;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down .ant-input-number-handler-down-inner {
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAl0lEQVQYlY3OsQpBcRiG8Z/TGczqXAvl7C5ABoNdFOUOrEabshkscgEuQNmUXdmIpIjVQumUf+cdn973+b5CmqYJehjghJd8KSLBDPMYZ4xQQien5JsxhrhGH3BHF1Uccgj2qKGPK0SZwhoVLAKSJcpY/cKsCI5ooI3nD7+hhTou2VEcuDzB5vPBE03s/pVDIthiikdIAm8E2x3HKxuWhQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  width: 58px;
  height: 42px;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down:hover {
  height: auto !important;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down:hover .ant-input-number-handler-down-inner {
  height: 42px !important;
  background-color: #ddf6f5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAl0lEQVQYlY3OsQpBcRiG8Z/TGczqXAvl7C5ABoNdFOUOrEabshkscgEuQNmUXdmIpIjVQumUf+cdn973+b5CmqYJehjghJd8KSLBDPMYZ4xQQien5JsxhrhGH3BHF1Uccgj2qKGPK0SZwhoVLAKSJcpY/cKsCI5ooI3nD7+hhTou2VEcuDzB5vPBE03s/pVDIthiikdIAm8E2x3HKxuWhQAAAABJRU5ErkJggg==);
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down:active {
  background-color: transparent;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-handler-down:active .ant-input-number-handler-down-inner {
  height: 42px !important;
  background-color: #33c3c7;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAjElEQVQYlZXRoQrCYBiF4VdYWLGIg92EYPYCvAq7zeCdGNbWDAvivQhaFeuSxWF8TT+Mof+2D0475ynfRM2AHbAHauDDsEuBDDgCFSrqVC0cfwd1phKgkJX6HADc1XV724VQc/UUQc7qvLv7BYVs1aYFvNTNv34MQl2qD/WmLmLdpOczF6AE3sA1VvwCmqo9E5QyjH4AAAAASUVORK5CYII=);
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-input-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changeTime___3n8e5 .form___HDf6B .ant-input-number-input {
  height: 100%;
  font-size: 26px;
  padding: 0 0 3px 0;
  z-index: 111;
  width: 30px;
  text-align: center;
}
.btnScheduleTime___1WJGK {
  height: 47px;
  width: 100%;
  max-width: 150px;
  border-radius: 20px;
  font-size: 18px;
  border: 0;
  margin-bottom: 30px;
}
.btnScheduleTime___1WJGK:hover {
  opacity: 0.8;
}
.cancelScheduleBtn___pDBT_ {
  background-color: #e8e6e6;
  margin-right: 55px;
}
.cancelScheduleBtn___pDBT_:hover,
.cancelScheduleBtn___pDBT_:focus,
.cancelScheduleBtn___pDBT_:active {
  background-color: #e8e6e6;
  color: #3c3c3c;
}
.updateScheduleBtn___rdtZF {
  background-color: #33c3c7;
  color: #fff;
}
.updateScheduleBtn___rdtZF:hover,
.updateScheduleBtn___rdtZF:focus,
.updateScheduleBtn___rdtZF:active {
  background-color: #33c3c7;
  color: #fff;
}
