@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1kXsx {
  display: flex;
  justify-content: center;
}
button.btn___2e3EN {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2e3EN {
    padding: 10px;
  }
}
button.btn___2e3EN:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2e3EN:focus {
  outline: none;
}
.btnWhite___3_HAe.btn___2e3EN {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3_HAe.btn___2e3EN:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___NSTQk.btn___2e3EN,
.btnGreen___NSTQk.btn___2e3EN:hover,
.btnGreen___NSTQk.btn___2e3EN:active,
.btnGreen___NSTQk.btn___2e3EN:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1sFKl {
  color: #fe0000;
}
.errorMessage___amG1M {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___CxwLD {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___20Be4 {
  display: none !important;
}
.m-auto___3qQO_ {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3OKUB {
  max-width: 120px !important;
}
.fc___1tfPd .fc-timegrid-col___3Fcqv.fc-day-today___1eHth {
  background-color: #ffffff;
}
.fc___1tfPd .fc-timegrid-slot___QIcNS {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1tfPd .fc-timegrid-slot___QIcNS {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___-IKR2 .fc-direction-ltr___1XcKB .fc-timegrid-slot-label-frame___1Q9Q0 {
    font-size: 10px;
  }
  .bookingCalendar___-IKR2 .fc___1tfPd .fc-timegrid-slot-label-cushion___2O_ao {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___-IKR2 .fc___1tfPd .fc-scrollgrid-section-header___1FWZk .fc-scroller___jmsBF {
    overflow: unset !important;
  }
}
.ant-message-notice___2UNGw {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1ud4T {
  width: 20px;
  height: 20px;
}
.bgTransparent___3iGty {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3__DB {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___35Ogh {
  position: relative;
}
.inputCustomValue___35Ogh .ant-input___3n2dR {
  padding-right: 50px;
}
.inputCustomValueText___3roDb {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___UtJP7 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___UtJP7 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1DKs3 .ant-radio-inner___1cc5p {
  border-color: black !important ;
}
.ant-radio-checked___1DKs3 .ant-radio-inner___1cc5p:after {
  background-color: black;
}
.ant-radio___dTCuf:hover .ant-radio-inner___1cc5p {
  border-color: black;
}
.ant-radio-checked___1DKs3 .ant-radio-inner___1cc5p:focus {
  border-color: black;
}
.modal___1elDz {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1elDz .close___MAO4U {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1elDz .header___1VGg6 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1elDz .header___1VGg6 p {
  margin: 0;
}
.modal___1elDz .content___2UuFJ {
  text-align: center;
  font-size: 14px;
}
.modal___1elDz .content___2UuFJ p {
  margin: 0;
}
.modal___1elDz .actions___1DSk4 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1elDz .actions___1DSk4 .button___3AGE6 {
  margin-top: 10px;
}
.modal___1elDz .actions___1DSk4:hover {
  color: #33c3c7;
}
.fc___1tfPd .fc-timegrid-slot-minor___3FtmQ {
  border-top-style: none;
}
.fc___1tfPd .fc-timegrid-slot___QIcNS {
  height: 1.5em !important;
}
.messageError___3JJ26 .ant-message-custom-content___1esYT {
  display: flex;
  text-align: start;
}
.messageError___3JJ26 .ant-message-custom-content___1esYT span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___39wgx {
  background-color: #e6e4e4;
}
.notifyMessage___3gpyX .ant-message-notice-content___odCpx {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3gpyX .ant-message-notice-content___odCpx .anticon___16ib0 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3gpyX .ant-message-notice-content___odCpx span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3jFvt {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3jFvt .ant-notification-notice-message___2qNYv,
.notifyCustomize___3jFvt .ant-notification-notice-close___2ItWI,
.notifyCustomize___3jFvt .ant-notification-notice-icon___c0UAm {
  color: white;
}
.notifyCustomize___3jFvt .ant-notification-notice-message___2qNYv {
  margin-left: 35px;
}
.notifyCustomize___3jFvt p {
  margin-bottom: 0;
}
.notifyCustomize___3jFvt p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3kkZ2 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3kkZ2 .ant-notification-notice-message___2qNYv {
  margin-left: 35px;
}
.notifyAlertDuplicate___3kkZ2 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3kkZ2 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3dhL0 {
  width: 434px !important;
}
.handleSubmitVote___3dhL0 .ant-btn-primary___3Pu7Q {
  background: #59c3c7;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV .ant-modal-confirm-body___3TQ-x {
  display: flex !important;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV .ant-modal-confirm-body___3TQ-x .anticon___16ib0 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV .ant-modal-confirm-btns___2dcFe button {
  font-weight: bold;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV .ant-modal-confirm-btns___2dcFe button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV .ant-modal-confirm-btns___2dcFe button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3dhL0 .ant-modal-body___3sxIV .ant-modal-confirm-btns___2dcFe .ant-btn-primary___3Pu7Q {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2j02n {
  margin-bottom: 0;
}
.mt-0___3T2On {
  margin-top: 0;
}
.tooltipFormat___1rdCY p {
  margin-bottom: 0;
}
.bgWhite___IM4kl {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1TNBG {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1TNBG:hover,
.bgPrimaryBlue___1TNBG:focus,
.bgPrimaryBlue___1TNBG:active {
  background: #4f7ac7;
}
.bgDarkBlue___2R-U5 {
  background-color: #023768 !important;
}
.bgDarkBlue___2R-U5:hover,
.bgDarkBlue___2R-U5:focus,
.bgDarkBlue___2R-U5:active {
  background: #194d7d;
}
.bgGreyBlue___2ZU7w {
  background-color: #ebf0f9;
}
.bgLightBlue___2c9TV {
  background-color: #b2cbf7 !important;
}
.bgLightRed___wklFh {
  background-color: #e4453a;
}
.bgLightRed___wklFh:hover,
.bgLightRed___wklFh:focus,
.bgLightRed___wklFh:active {
  background: #e17871;
}
.bgLightYellow___1p9Du {
  background-color: #ffd603;
}
.bgDarkGray___2Hgus {
  background-color: #333333;
}
.bgMediumGray___3IDjt {
  background-color: #a2a2a2;
}
.bgLightGray___31S2k {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1ehjf {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3TB_A {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___duypy {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___15SkA {
  color: #3368c7 !important;
}
.textDarkBlue___mfAIK {
  color: #023768 !important;
}
.textLightBlue___bfmTV {
  color: #b2cbf7;
}
.textDarkGray___1Jd1_ {
  color: #333333 !important;
}
.textMediumGray___2kPMd {
  color: #a2a2a2 !important;
}
.textLightGray___evgeV {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1qKP3 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___aS73_ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3s6jb {
  border-radius: 5px !important;
}
.rounded-xs___2xdxV {
  border-radius: 10px !important;
}
.rounded-sm___1tsHN {
  border-radius: 15px !important;
}
.rounded-md___174qd {
  border-radius: 20px !important;
}
.rounded-lg___PsM2e {
  border-radius: 25px !important;
}
.rounded-full___2UkOA {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1nZwA {
  margin: 0;
}
.mt-2___2tkBQ {
  margin-top: 0.5rem;
}
.my-2___3xELY {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___39UZY {
  padding: 0;
}
.px-1___OBIcG {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2dQPx {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___8yPFn {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2jVYO {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___IpZAf {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___v702Q {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___18eV_ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2fm-9 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___15TGY {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3BhmA {
  padding-bottom: 20px;
}
.h-full___1naiQ {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3hK0a {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___GhThE {
  font-weight: bold !important;
}
.fontWeight400___1c8Rj {
  font-weight: 400 !important;
}
.fontWeight500___-bvJb {
  font-weight: 500 !important;
}
.fontWeight600___1KH-v {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3Lv84 {
  font-size: 12px !important;
}
.fontSize14___FypNv {
  font-size: 14px !important;
}
.fontSize16___2-A2K {
  font-size: 16px !important;
}
.fontSize18___1oFrR {
  font-size: 18px !important;
}
.fontSize20___3XBQw {
  font-size: 20px !important;
}
.fontSize24___2C1X0 {
  font-size: 24px !important;
}
.eventName___1Um1u {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___33Aok {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___FXLea > tr > th,
.ant-table-tbody___2UDLv > tr.ant-table-row___3wfBO > td,
.ant-table-summary___1ndd7 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___FXLea > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3vOT8 .ant-table-thead___FXLea > tr > th,
.table-portrait___3vOT8 .ant-table-tbody___2UDLv > tr.ant-table-row___3wfBO > td,
.table-portrait___3vOT8 .ant-table-summary___1ndd7 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3vOT8 .bgLightGray___31S2k td {
  background: #ebebeb;
}
.table-portrait___3vOT8 .bgLightBlue___2c9TV td {
  background: #b2cbf7;
}
.eventContent___3y4wK .timeText___2MR80 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2UDLv > tr.bgLightGray___31S2k:hover > td,
.ant-table-tbody___2UDLv > tr > td.ant-table-cell-row-hover___3AMpd {
  background: #ebebeb;
}
.ant-table-tbody___2UDLv > tr.bgLightBlue___2c9TV:hover > td,
.ant-table-tbody___2UDLv > tr > td.ant-table-cell-row-hover___3AMpd {
  background: #b2cbf7;
}
.ant-table-tbody___2UDLv > tr.bgWhite___IM4kl:hover > td,
.ant-table-tbody___2UDLv > tr > td.ant-table-cell-row-hover___3AMpd {
  background: #ffffff;
}
.footerCalendarCreation___2hZue {
  width: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3;
}
.footerCalendarCreation___2hZue .footerCalendarCreationContent__item___3fD8M {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  border-bottom: 1px solid #3368c7;
}
.footerCalendarCreation___2hZue .footerCalendarCreationContent__item___3fD8M .footerCalendarCreation__title___1Fl3s {
  font-size: 1.2rem;
  font-weight: 700;
}
.footerCalendarCreation___2hZue .footerCalendarCreationContent__item___3fD8M .footerCalendarCreation__action___miOHu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerCalendarCreation___2hZue .footerCalendarCreationContent__item___3fD8M .footerCalendarCreation__action___miOHu .footerCalendarCreation__action__select___h18D6 {
  height: auto;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  padding: 2px 5px;
}
.footerCalendarCreation___2hZue .footerCalendarCreationContent__item___3fD8M .footerCalendarCreation__action___miOHu .footerCalendarCreation__action__button___2jWe_ {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerCalendarCreation___2hZue .footerCalendarCreationContent__item___3fD8M .footerCalendarCreation__action___miOHu .footerCalendarCreation__action__button___2jWe_ > img {
  width: 20px;
}
.footerCalendarCreation___2hZue .footerCalendarCreation__form___2xFmY {
  padding: 7px 20px;
  width: 100%;
  display: flex;
  align-items: center;
}
.footerCalendarCreation___2hZue .footerCalendarCreation__form___2xFmY .inputField___16ezZ {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}
.footerCalendarCreation___2hZue .footerCalendarCreation__form___2xFmY .saveBtn___5croC {
  width: 20%;
  height: 40px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.footerCalendarCreation___2hZue .footerCalendarCreation__form___2xFmY .ant-form-item {
  width: 100%;
}
