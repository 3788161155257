.navigation___1MlXK {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #999999;
}
.navigation___1MlXK .navItemLeft___nzJ_B {
  display: flex;
  align-items: center;
}
.navigation___1MlXK .navItemLeft___nzJ_B .navMenu___PUvIp {
  border: none;
  background: transparent;
  padding-left: 0;
}
.navigation___1MlXK .navItemLeft___nzJ_B .navMenu___PUvIp > img {
  width: 25px;
}
.navigation___1MlXK .navItemRight___YsEeE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo {
  display: flex;
  align-items: center;
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo .btnDayActionItem___6D9H8 {
  border: none;
  background: transparent;
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo .btnDayActionItem___6D9H8 .prevBtnMobile___-zwGW {
  height: 15px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo .btnDayActionItem___6D9H8 .prevBtnMobile___-zwGW:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(0deg);
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo .btnDayActionItem___6D9H8 .nextBtnMobile___2A3dv {
  height: 15px;
  width: 20px;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo .btnDayActionItem___6D9H8 .nextBtnMobile___2A3dv:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(180deg);
}
.navigation___1MlXK .navItemRight___YsEeE .navDayAction___w0ggo .btnDayActionName___2cIhb {
  border: 1px solid #0c3dc9;
  background: transparent;
  border-radius: 5px;
  color: #0c3dc9;
  font-weight: 500;
}
.navigation___1MlXK .navItemRight___YsEeE .calendarViewBtn___Z_ks8 {
  border: none;
  padding: 0;
}
.navigation___1MlXK .navItemRight___YsEeE .calendarViewBtn___Z_ks8 .calendarViewIcon___1FFBt {
  width: 20px;
}
.navigation___1MlXK .ant-select .ant-select-selector {
  border: none !important;
}
.navigation___1MlXK .ant-select .ant-select-selector .ant-select-selection-item {
  font-weight: 600;
  font-size: 1.2rem;
  color: #1f4f96;
}
.modalContent___1cRjC {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.modalContent___1cRjC .modalItem___1UJPy {
  display: flex;
  align-items: center;
  border: none;
  margin: 10px 0;
  padding: 20px;
  box-shadow: none;
  font-weight: 500;
  font-size: 1.2rem;
}
.modalContent___1cRjC .modalItem___1UJPy img {
  width: 20px;
  margin-right: 5px;
}
.modalContent___1cRjC hr {
  border: 0.5px solid #ececec;
  width: 100%;
}
