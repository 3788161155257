@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3uxrD {
  display: flex;
  justify-content: center;
}
button.btn___1U8bv {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1U8bv {
    padding: 10px;
  }
}
button.btn___1U8bv:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1U8bv:focus {
  outline: none;
}
.btnWhite___1meB6.btn___1U8bv {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1meB6.btn___1U8bv:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2cpB_.btn___1U8bv,
.btnGreen___2cpB_.btn___1U8bv:hover,
.btnGreen___2cpB_.btn___1U8bv:active,
.btnGreen___2cpB_.btn___1U8bv:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3ARzL {
  color: #fe0000;
}
.errorMessage___2U8kf {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2EGcl {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___25BNs {
  display: none !important;
}
.m-auto___C8dKt {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3mv9J {
  max-width: 120px !important;
}
.fc___22bq6 .fc-timegrid-col___10K2M.fc-day-today___2Xoqb {
  background-color: #ffffff;
}
.fc___22bq6 .fc-timegrid-slot___nzs3v {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___22bq6 .fc-timegrid-slot___nzs3v {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___OzJRx .fc-direction-ltr___1a04f .fc-timegrid-slot-label-frame___2JSxT {
    font-size: 10px;
  }
  .bookingCalendar___OzJRx .fc___22bq6 .fc-timegrid-slot-label-cushion___1XCGg {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___OzJRx .fc___22bq6 .fc-scrollgrid-section-header___3KxsD .fc-scroller___1E09J {
    overflow: unset !important;
  }
}
.ant-message-notice___15mOZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2V-Gp {
  width: 20px;
  height: 20px;
}
.bgTransparent___zLV9r {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1-EC3 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___W5C4h {
  position: relative;
}
.inputCustomValue___W5C4h .ant-input___Ix2yV {
  padding-right: 50px;
}
.inputCustomValueText___13Lmm {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___18gVw .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___18gVw .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___PFPoH .ant-radio-inner___1vif1 {
  border-color: black !important ;
}
.ant-radio-checked___PFPoH .ant-radio-inner___1vif1:after {
  background-color: black;
}
.ant-radio___1XgI2:hover .ant-radio-inner___1vif1 {
  border-color: black;
}
.ant-radio-checked___PFPoH .ant-radio-inner___1vif1:focus {
  border-color: black;
}
.modal___2mKjs {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2mKjs .close___3GmFi {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2mKjs .header___2gKKA {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2mKjs .header___2gKKA p {
  margin: 0;
}
.modal___2mKjs .content___2iLYK {
  text-align: center;
  font-size: 14px;
}
.modal___2mKjs .content___2iLYK p {
  margin: 0;
}
.modal___2mKjs .actions___3znhL {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2mKjs .actions___3znhL .button___1ZSbc {
  margin-top: 10px;
}
.modal___2mKjs .actions___3znhL:hover {
  color: #33c3c7;
}
.fc___22bq6 .fc-timegrid-slot-minor___2GaTU {
  border-top-style: none;
}
.fc___22bq6 .fc-timegrid-slot___nzs3v {
  height: 1.5em !important;
}
.messageError___2bv3b .ant-message-custom-content___1ER3_ {
  display: flex;
  text-align: start;
}
.messageError___2bv3b .ant-message-custom-content___1ER3_ span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2GCT1 {
  background-color: #e6e4e4;
}
.notifyMessage___1uDz5 .ant-message-notice-content___3qsKv {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1uDz5 .ant-message-notice-content___3qsKv .anticon___2IBxQ {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1uDz5 .ant-message-notice-content___3qsKv span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___kgI1n {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___kgI1n .ant-notification-notice-message___3UDha,
.notifyCustomize___kgI1n .ant-notification-notice-close___Pf2VI,
.notifyCustomize___kgI1n .ant-notification-notice-icon___bTvhW {
  color: white;
}
.notifyCustomize___kgI1n .ant-notification-notice-message___3UDha {
  margin-left: 35px;
}
.notifyCustomize___kgI1n p {
  margin-bottom: 0;
}
.notifyCustomize___kgI1n p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___34rKY {
  padding: 16px 14px;
}
.notifyAlertDuplicate___34rKY .ant-notification-notice-message___3UDha {
  margin-left: 35px;
}
.notifyAlertDuplicate___34rKY p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___34rKY p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1b5Tm {
  width: 434px !important;
}
.handleSubmitVote___1b5Tm .ant-btn-primary___2g8hd {
  background: #59c3c7;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A .ant-modal-confirm-body___3l_an {
  display: flex !important;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A .ant-modal-confirm-body___3l_an .anticon___2IBxQ {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A .ant-modal-confirm-btns___9akB9 button {
  font-weight: bold;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A .ant-modal-confirm-btns___9akB9 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A .ant-modal-confirm-btns___9akB9 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1b5Tm .ant-modal-body___EgP6A .ant-modal-confirm-btns___9akB9 .ant-btn-primary___2g8hd {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2t5Uq {
  margin-bottom: 0;
}
.mt-0___3m2i_ {
  margin-top: 0;
}
.tooltipFormat___ZHGc7 p {
  margin-bottom: 0;
}
.bgWhite___1yd_r {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1YqQi {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1YqQi:hover,
.bgPrimaryBlue___1YqQi:focus,
.bgPrimaryBlue___1YqQi:active {
  background: #4f7ac7;
}
.bgDarkBlue___3KV26 {
  background-color: #023768 !important;
}
.bgDarkBlue___3KV26:hover,
.bgDarkBlue___3KV26:focus,
.bgDarkBlue___3KV26:active {
  background: #194d7d;
}
.bgGreyBlue___1mtjR {
  background-color: #ebf0f9;
}
.bgLightBlue___m51Mo {
  background-color: #b2cbf7 !important;
}
.bgLightRed___YXBrs {
  background-color: #e4453a;
}
.bgLightRed___YXBrs:hover,
.bgLightRed___YXBrs:focus,
.bgLightRed___YXBrs:active {
  background: #e17871;
}
.bgLightYellow___nkgQy {
  background-color: #ffd603;
}
.bgDarkGray___1BxEk {
  background-color: #333333;
}
.bgMediumGray___17zQB {
  background-color: #a2a2a2;
}
.bgLightGray___3US5V {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2PjXG {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___ijMWF {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___6IatE {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2A2wI {
  color: #3368c7 !important;
}
.textDarkBlue___1A83S {
  color: #023768 !important;
}
.textLightBlue___jJ35a {
  color: #b2cbf7;
}
.textDarkGray___3Dx0s {
  color: #333333 !important;
}
.textMediumGray___ovVOk {
  color: #a2a2a2 !important;
}
.textLightGray___2vIXo {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1eFQN {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3nrCd {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2Pf11 {
  border-radius: 5px !important;
}
.rounded-xs___35CU9 {
  border-radius: 10px !important;
}
.rounded-sm___3eZd8 {
  border-radius: 15px !important;
}
.rounded-md___jcqee {
  border-radius: 20px !important;
}
.rounded-lg___2R9Z0 {
  border-radius: 25px !important;
}
.rounded-full___3WoU2 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3gaWZ {
  margin: 0;
}
.mt-2___1S4oO {
  margin-top: 0.5rem;
}
.my-2___2d7lU {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1WUnt {
  padding: 0;
}
.px-1___AOFpv {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2xtox {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3HHtU {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3uHSF {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3IQPD {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3FRXC {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___35YZ1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1qREo {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3wVcp {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2cAn1 {
  padding-bottom: 20px;
}
.h-full___1LGhM {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1Rqgh {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2u-LI {
  font-weight: bold !important;
}
.fontWeight400___1pF8H {
  font-weight: 400 !important;
}
.fontWeight500___2y2MK {
  font-weight: 500 !important;
}
.fontWeight600___2dcTG {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3OcLQ {
  font-size: 12px !important;
}
.fontSize14___2bpvx {
  font-size: 14px !important;
}
.fontSize16___2HJvH {
  font-size: 16px !important;
}
.fontSize18___1tksj {
  font-size: 18px !important;
}
.fontSize20___rTRW8 {
  font-size: 20px !important;
}
.fontSize24___2Yakv {
  font-size: 24px !important;
}
.eventName___2R-5o {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___6p5dY {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1kL5j > tr > th,
.ant-table-tbody___3rjON > tr.ant-table-row___3nUOi > td,
.ant-table-summary___CxA1d tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1kL5j > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3ZGoC .ant-table-thead___1kL5j > tr > th,
.table-portrait___3ZGoC .ant-table-tbody___3rjON > tr.ant-table-row___3nUOi > td,
.table-portrait___3ZGoC .ant-table-summary___CxA1d tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3ZGoC .bgLightGray___3US5V td {
  background: #ebebeb;
}
.table-portrait___3ZGoC .bgLightBlue___m51Mo td {
  background: #b2cbf7;
}
.eventContent___JfVpK .timeText___1GVNq {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3rjON > tr.bgLightGray___3US5V:hover > td,
.ant-table-tbody___3rjON > tr > td.ant-table-cell-row-hover___3N4Ga {
  background: #ebebeb;
}
.ant-table-tbody___3rjON > tr.bgLightBlue___m51Mo:hover > td,
.ant-table-tbody___3rjON > tr > td.ant-table-cell-row-hover___3N4Ga {
  background: #b2cbf7;
}
.ant-table-tbody___3rjON > tr.bgWhite___1yd_r:hover > td,
.ant-table-tbody___3rjON > tr > td.ant-table-cell-row-hover___3N4Ga {
  background: #ffffff;
}
.contractConfirm___2-Aqh {
  max-width: 1080px;
  margin: auto;
}
.contractConfirm___2-Aqh .headTitle___16kux {
  display: flex;
  align-items: center;
  margin-bottom: 55px;
  margin-top: 35px;
}
.contractConfirm___2-Aqh .headTitle___16kux .titleIcon___BvfvS {
  margin-right: 16px;
}
.contractConfirm___2-Aqh .headTitle___16kux .titleIcon___BvfvS .bolderCol___2bKhR {
  height: 52px;
  width: 12px;
  background-color: #33c3c7;
  display: inline-block;
  margin-right: 8px;
}
.contractConfirm___2-Aqh .headTitle___16kux .titleIcon___BvfvS .thinnerCol___o3Dgx {
  height: 52px;
  width: 4px;
  background-color: #33c3c7;
  display: inline-block;
}
.contractConfirm___2-Aqh .headTitle___16kux p {
  font-size: 45px;
  font-family: 'HGSGothic';
  margin: 0;
}
.contractConfirm___2-Aqh .reminder___2zz80 {
  font-size: 26px;
  color: #414141;
  text-indent: 38px;
  margin: 0;
}
.contractConfirm___2-Aqh .tableContent___3FJON {
  margin: 50px 0 93px 0;
}
.contractConfirm___2-Aqh .tableContent___3FJON table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.contractConfirm___2-Aqh .tableContent___3FJON tr:first-child td {
  border-top: none;
}
.contractConfirm___2-Aqh .tableContent___3FJON tr {
  font-size: 26px;
  font-family: 'HGSGothic';
  color: #3d3d3d;
}
.contractConfirm___2-Aqh .tableContent___3FJON tr td:first-child {
  padding-left: 108px;
}
.contractConfirm___2-Aqh .tableContent___3FJON tr td:last-child {
  padding-left: 61px;
}
.contractConfirm___2-Aqh .tableContent___3FJON td {
  border-left: 3px solid #f3f3f3;
  border-top: 3px solid #f3f3f3;
  text-align: left;
  padding: 8px;
  width: 50%;
}
.contractConfirm___2-Aqh .tableContent___3FJON td .changeBtn___3gOCL {
  height: 42px;
  min-width: 93px;
  border: 3px solid #33c3c7;
  font-size: 19px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #414141;
  margin: 0 66px;
}
.contractConfirm___2-Aqh .tableContent___3FJON td:first-child {
  border-left: none;
}
.contractConfirm___2-Aqh .tableContent___3FJON th:first-child {
  border-left: none;
}
.contractConfirm___2-Aqh .tableContent___3FJON th {
  border-left: 3px solid #f3f3f3;
  border-top: none;
  text-align: left;
  padding: 8px;
}
.contractConfirm___2-Aqh .btnZone___25nC0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
.contractConfirm___2-Aqh .btnZone___25nC0 .returnBtn___3uj-4 {
  height: 66px;
  min-width: 205px;
  border: 1px solid #d3d3d3;
  font-size: 26px;
  font-family: 'HGSGothic';
  border-radius: 10px;
  background-color: #ffffff;
  color: #909090;
  margin: 0 40px;
}
.contractConfirm___2-Aqh .btnZone___25nC0 .paymentConfirmBtn___3-HaG {
  height: 66px;
  min-width: 325px;
  border: 1px solid #d3d3d3;
  font-size: 26px;
  font-family: 'HGSGothic';
  border-radius: 10px;
  background-color: #33c3c7;
  color: #ffffff;
  margin: 0 40px;
}
