@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___lOuCy {
  display: flex;
  justify-content: center;
}
button.btn___15CDm {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___15CDm {
    padding: 10px;
  }
}
button.btn___15CDm:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___15CDm:focus {
  outline: none;
}
.btnWhite___1hNQ8.btn___15CDm {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1hNQ8.btn___15CDm:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1UbAr.btn___15CDm,
.btnGreen___1UbAr.btn___15CDm:hover,
.btnGreen___1UbAr.btn___15CDm:active,
.btnGreen___1UbAr.btn___15CDm:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1v7vD {
  color: #fe0000;
}
.errorMessage___PtaQ_ {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___27SQj {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2Trff {
  display: none !important;
}
.m-auto___WWVbl {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___258l4 {
  max-width: 120px !important;
}
.fc___2IGKz .fc-timegrid-col___3zcNl.fc-day-today___3EEL_ {
  background-color: #ffffff;
}
.fc___2IGKz .fc-timegrid-slot___2DllK {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2IGKz .fc-timegrid-slot___2DllK {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2QjXK .fc-direction-ltr___1BMta .fc-timegrid-slot-label-frame___1v58m {
    font-size: 10px;
  }
  .bookingCalendar___2QjXK .fc___2IGKz .fc-timegrid-slot-label-cushion___2bgam {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2QjXK .fc___2IGKz .fc-scrollgrid-section-header___3hCr5 .fc-scroller___2hTUT {
    overflow: unset !important;
  }
}
.ant-message-notice___1lfYu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___DQprx {
  width: 20px;
  height: 20px;
}
.bgTransparent___2aq0X {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___9Y-Rl {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2DCKb {
  position: relative;
}
.inputCustomValue___2DCKb .ant-input___1E8Tv {
  padding-right: 50px;
}
.inputCustomValueText___2jkSG {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___28G1K .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___28G1K .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2sxN4 .ant-radio-inner___3bR-3 {
  border-color: black !important ;
}
.ant-radio-checked___2sxN4 .ant-radio-inner___3bR-3:after {
  background-color: black;
}
.ant-radio___1Jn3H:hover .ant-radio-inner___3bR-3 {
  border-color: black;
}
.ant-radio-checked___2sxN4 .ant-radio-inner___3bR-3:focus {
  border-color: black;
}
.modal___1gG7C {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1gG7C .close___3AstG {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1gG7C .header___1X1LB {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1gG7C .header___1X1LB p {
  margin: 0;
}
.modal___1gG7C .content___LkOk- {
  text-align: center;
  font-size: 14px;
}
.modal___1gG7C .content___LkOk- p {
  margin: 0;
}
.modal___1gG7C .actions___F34IX {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1gG7C .actions___F34IX .button___Fgws6 {
  margin-top: 10px;
}
.modal___1gG7C .actions___F34IX:hover {
  color: #33c3c7;
}
.fc___2IGKz .fc-timegrid-slot-minor___1vB7C {
  border-top-style: none;
}
.fc___2IGKz .fc-timegrid-slot___2DllK {
  height: 1.5em !important;
}
.messageError___3W6xr .ant-message-custom-content___1-R8J {
  display: flex;
  text-align: start;
}
.messageError___3W6xr .ant-message-custom-content___1-R8J span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___nHZWq {
  background-color: #e6e4e4;
}
.notifyMessage___1BCPX .ant-message-notice-content___2kWeL {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1BCPX .ant-message-notice-content___2kWeL .anticon___3_2ui {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1BCPX .ant-message-notice-content___2kWeL span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3Qoxu {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3Qoxu .ant-notification-notice-message___3sLRG,
.notifyCustomize___3Qoxu .ant-notification-notice-close___2mVqT,
.notifyCustomize___3Qoxu .ant-notification-notice-icon___3bu1I {
  color: white;
}
.notifyCustomize___3Qoxu .ant-notification-notice-message___3sLRG {
  margin-left: 35px;
}
.notifyCustomize___3Qoxu p {
  margin-bottom: 0;
}
.notifyCustomize___3Qoxu p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2sJE_ {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2sJE_ .ant-notification-notice-message___3sLRG {
  margin-left: 35px;
}
.notifyAlertDuplicate___2sJE_ p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2sJE_ p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___Z65K8 {
  width: 434px !important;
}
.handleSubmitVote___Z65K8 .ant-btn-primary___33gI1 {
  background: #59c3c7;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda {
  padding: 15px 30px 18px;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda .ant-modal-confirm-body___2ZrQf {
  display: flex !important;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda .ant-modal-confirm-body___2ZrQf .anticon___3_2ui {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda .ant-modal-confirm-btns___1bCSF button {
  font-weight: bold;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda .ant-modal-confirm-btns___1bCSF button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda .ant-modal-confirm-btns___1bCSF button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___Z65K8 .ant-modal-body___3gOda .ant-modal-confirm-btns___1bCSF .ant-btn-primary___33gI1 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3t9L0 {
  margin-bottom: 0;
}
.mt-0___2ei5- {
  margin-top: 0;
}
.tooltipFormat___28rNy p {
  margin-bottom: 0;
}
.bgWhite___2wgNE {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2xAY2 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2xAY2:hover,
.bgPrimaryBlue___2xAY2:focus,
.bgPrimaryBlue___2xAY2:active {
  background: #4f7ac7;
}
.bgDarkBlue___3y_7P {
  background-color: #023768 !important;
}
.bgDarkBlue___3y_7P:hover,
.bgDarkBlue___3y_7P:focus,
.bgDarkBlue___3y_7P:active {
  background: #194d7d;
}
.bgGreyBlue___1nfzC {
  background-color: #ebf0f9;
}
.bgLightBlue___21ieS {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3T0y7 {
  background-color: #e4453a;
}
.bgLightRed___3T0y7:hover,
.bgLightRed___3T0y7:focus,
.bgLightRed___3T0y7:active {
  background: #e17871;
}
.bgLightYellow___1NJC- {
  background-color: #ffd603;
}
.bgDarkGray___2Pm-0 {
  background-color: #333333;
}
.bgMediumGray___2f9io {
  background-color: #a2a2a2;
}
.bgLightGray___mkVk3 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2s-Pa {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1SNRe {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2Um0n {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3EVi0 {
  color: #3368c7 !important;
}
.textDarkBlue___2FOhu {
  color: #023768 !important;
}
.textLightBlue___1rEbN {
  color: #b2cbf7;
}
.textDarkGray___2GMHh {
  color: #333333 !important;
}
.textMediumGray___2mKoG {
  color: #a2a2a2 !important;
}
.textLightGray___2lVTR {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1hBOd {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___mA91q {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3Uok0 {
  border-radius: 5px !important;
}
.rounded-xs___aoBtq {
  border-radius: 10px !important;
}
.rounded-sm___HI6Hy {
  border-radius: 15px !important;
}
.rounded-md___2692d {
  border-radius: 20px !important;
}
.rounded-lg___1Olsa {
  border-radius: 25px !important;
}
.rounded-full___2Cp9Y {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1tQUk {
  margin: 0;
}
.mt-2___3DBFy {
  margin-top: 0.5rem;
}
.my-2___25glA {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2uE86 {
  padding: 0;
}
.px-1___2_xkX {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___4fq8i {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___pQskm {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2BKKu {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___20cZp {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2e2v9 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___29AEo {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3oOH_ {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2W4ht {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2ZAHE {
  padding-bottom: 20px;
}
.h-full___2WTmy {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___csI9S {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3U5Ur {
  font-weight: bold !important;
}
.fontWeight400___3woeZ {
  font-weight: 400 !important;
}
.fontWeight500___3vtkQ {
  font-weight: 500 !important;
}
.fontWeight600___EfuUc {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3Wt2z {
  font-size: 12px !important;
}
.fontSize14___2x0vI {
  font-size: 14px !important;
}
.fontSize16___3cLFl {
  font-size: 16px !important;
}
.fontSize18___21mjH {
  font-size: 18px !important;
}
.fontSize20___1nj_V {
  font-size: 20px !important;
}
.fontSize24___N4Ls- {
  font-size: 24px !important;
}
.eventName___1GyX3 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1MR6V {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3K27z > tr > th,
.ant-table-tbody___3Wll- > tr.ant-table-row___2i_tc > td,
.ant-table-summary___3FFiB tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3K27z > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3XAes .ant-table-thead___3K27z > tr > th,
.table-portrait___3XAes .ant-table-tbody___3Wll- > tr.ant-table-row___2i_tc > td,
.table-portrait___3XAes .ant-table-summary___3FFiB tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3XAes .bgLightGray___mkVk3 td {
  background: #ebebeb;
}
.table-portrait___3XAes .bgLightBlue___21ieS td {
  background: #b2cbf7;
}
.eventContent___tMmVu .timeText___2qwh7 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3Wll- > tr.bgLightGray___mkVk3:hover > td,
.ant-table-tbody___3Wll- > tr > td.ant-table-cell-row-hover___11oy1 {
  background: #ebebeb;
}
.ant-table-tbody___3Wll- > tr.bgLightBlue___21ieS:hover > td,
.ant-table-tbody___3Wll- > tr > td.ant-table-cell-row-hover___11oy1 {
  background: #b2cbf7;
}
.ant-table-tbody___3Wll- > tr.bgWhite___2wgNE:hover > td,
.ant-table-tbody___3Wll- > tr > td.ant-table-cell-row-hover___11oy1 {
  background: #ffffff;
}
.appointmentSelectionConfirmContainer___1l3FZ {
  max-width: 1080px;
  margin: auto;
  width: 100%;
}
.appointmentSelectionConfirmContainer___1l3FZ .header___1X1LB {
  font-size: 24px;
  font-weight: bold;
}
.appointmentSelectionConfirmContainer___1l3FZ .inputField___2pU6H {
  max-width: 508px;
  margin: 0 auto 20px auto;
}
.appointmentSelectionConfirmContainer___1l3FZ .inputField___2pU6H .fieldFlex___3Zla7 {
  display: flex;
  justify-content: space-between;
}
.appointmentSelectionConfirmContainer___1l3FZ .inputField___2pU6H .fieldLabel___YUsEB {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.appointmentSelectionConfirmContainer___1l3FZ .inputField___2pU6H .fieldLabel___YUsEB .inputRequired___gnd4x {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 10px;
}
.appointmentSelectionConfirmContainer___1l3FZ .inputField___2pU6H input {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.appointmentSelectionConfirmContainer___1l3FZ .inputField___2pU6H .errorNotice___2Da8A {
  color: #ff4d4f;
  font-size: 14px;
}
.appointmentSelectionConfirmContainer___1l3FZ .btnZone___2I6qw {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.appointmentSelectionConfirmContainer___1l3FZ .btnZone___2I6qw .confirmBtn___2vPkz {
  width: 100%;
  height: 40px;
  background-color: #023768;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.appointmentSelectionConfirmContainer___1l3FZ .btnZone___2I6qw .confirmBtn___2vPkz:hover {
  background-color: #1f4f96;
}
.appointmentSelectionConfirmContainer___1l3FZ .btnZone___2I6qw .backBtn___3KW7T {
  width: 100%;
  height: 40px;
  background-color: #9db9fa;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.appointmentSelectionConfirmContainer___1l3FZ .btnZone___2I6qw .backBtn___3KW7T:hover {
  background-color: #9db9fa;
}
