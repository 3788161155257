@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1ABZI {
  display: flex;
  justify-content: center;
}
button.btn___26406 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___26406 {
    padding: 10px;
  }
}
button.btn___26406:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___26406:focus {
  outline: none;
}
.btnWhite___s4wRi.btn___26406 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___s4wRi.btn___26406:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2UKUD.btn___26406,
.btnGreen___2UKUD.btn___26406:hover,
.btnGreen___2UKUD.btn___26406:active,
.btnGreen___2UKUD.btn___26406:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2x-07 {
  color: #fe0000;
}
.errorMessage___1bKXv {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1ZCp7 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___IapvW {
  display: none !important;
}
.m-auto___3qNN1 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3rWQd {
  max-width: 120px !important;
}
.fc___2QdSN .fc-timegrid-col___2qIKl.fc-day-today___1CtdW {
  background-color: #ffffff;
}
.fc___2QdSN .fc-timegrid-slot___3I2Q_ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2QdSN .fc-timegrid-slot___3I2Q_ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___15Np0 .fc-direction-ltr___A9g26 .fc-timegrid-slot-label-frame___3x9ly {
    font-size: 10px;
  }
  .bookingCalendar___15Np0 .fc___2QdSN .fc-timegrid-slot-label-cushion___2CbKc {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___15Np0 .fc___2QdSN .fc-scrollgrid-section-header___3LpIS .fc-scroller___rpkm3 {
    overflow: unset !important;
  }
}
.ant-message-notice___qUvhI {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3TpkJ {
  width: 20px;
  height: 20px;
}
.bgTransparent___oqjcP {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___sp5Md {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___v_J7X {
  position: relative;
}
.inputCustomValue___v_J7X .ant-input___3tJ59 {
  padding-right: 50px;
}
.inputCustomValueText___2cB2S {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___Jix9i .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___Jix9i .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___29HlU .ant-radio-inner___12k4i {
  border-color: black !important ;
}
.ant-radio-checked___29HlU .ant-radio-inner___12k4i:after {
  background-color: black;
}
.ant-radio___2vE-k:hover .ant-radio-inner___12k4i {
  border-color: black;
}
.ant-radio-checked___29HlU .ant-radio-inner___12k4i:focus {
  border-color: black;
}
.modal___3QoKE {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3QoKE .close___1_ljt {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3QoKE .header___3Cclc {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3QoKE .header___3Cclc p {
  margin: 0;
}
.modal___3QoKE .content___116vA {
  text-align: center;
  font-size: 14px;
}
.modal___3QoKE .content___116vA p {
  margin: 0;
}
.modal___3QoKE .actions___3ysc- {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3QoKE .actions___3ysc- .button___2L2Um {
  margin-top: 10px;
}
.modal___3QoKE .actions___3ysc-:hover {
  color: #33c3c7;
}
.fc___2QdSN .fc-timegrid-slot-minor___3TfBB {
  border-top-style: none;
}
.fc___2QdSN .fc-timegrid-slot___3I2Q_ {
  height: 1.5em !important;
}
.messageError___1YTlB .ant-message-custom-content___1riYl {
  display: flex;
  text-align: start;
}
.messageError___1YTlB .ant-message-custom-content___1riYl span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1GxtA {
  background-color: #e6e4e4;
}
.notifyMessage___zSSs5 .ant-message-notice-content___2RTVB {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___zSSs5 .ant-message-notice-content___2RTVB .anticon___1f8UL {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___zSSs5 .ant-message-notice-content___2RTVB span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___33ufI {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___33ufI .ant-notification-notice-message___sMTKU,
.notifyCustomize___33ufI .ant-notification-notice-close___1zqsg,
.notifyCustomize___33ufI .ant-notification-notice-icon___sWCPp {
  color: white;
}
.notifyCustomize___33ufI .ant-notification-notice-message___sMTKU {
  margin-left: 35px;
}
.notifyCustomize___33ufI p {
  margin-bottom: 0;
}
.notifyCustomize___33ufI p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2JkAj {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2JkAj .ant-notification-notice-message___sMTKU {
  margin-left: 35px;
}
.notifyAlertDuplicate___2JkAj p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2JkAj p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___18SFK {
  width: 434px !important;
}
.handleSubmitVote___18SFK .ant-btn-primary___2Jfuv {
  background: #59c3c7;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA {
  padding: 15px 30px 18px;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA .ant-modal-confirm-body___2Ai7i {
  display: flex !important;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA .ant-modal-confirm-body___2Ai7i .anticon___1f8UL {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA .ant-modal-confirm-btns___15LVO button {
  font-weight: bold;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA .ant-modal-confirm-btns___15LVO button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA .ant-modal-confirm-btns___15LVO button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___18SFK .ant-modal-body___1O_wA .ant-modal-confirm-btns___15LVO .ant-btn-primary___2Jfuv {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1M2Hx {
  margin-bottom: 0;
}
.mt-0___TVx7e {
  margin-top: 0;
}
.tooltipFormat___xH03A p {
  margin-bottom: 0;
}
.bgWhite___JnUDt {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3OLbw {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3OLbw:hover,
.bgPrimaryBlue___3OLbw:focus,
.bgPrimaryBlue___3OLbw:active {
  background: #4f7ac7;
}
.bgDarkBlue___1Wrvr {
  background-color: #023768 !important;
}
.bgDarkBlue___1Wrvr:hover,
.bgDarkBlue___1Wrvr:focus,
.bgDarkBlue___1Wrvr:active {
  background: #194d7d;
}
.bgGreyBlue___oD72x {
  background-color: #ebf0f9;
}
.bgLightBlue___3iaWw {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1ZflS {
  background-color: #e4453a;
}
.bgLightRed___1ZflS:hover,
.bgLightRed___1ZflS:focus,
.bgLightRed___1ZflS:active {
  background: #e17871;
}
.bgLightYellow___9cqG5 {
  background-color: #ffd603;
}
.bgDarkGray___1RzcT {
  background-color: #333333;
}
.bgMediumGray___waSOG {
  background-color: #a2a2a2;
}
.bgLightGray___1JWd2 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___243gB {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3nhb9 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1CelY {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3iQ85 {
  color: #3368c7 !important;
}
.textDarkBlue___33bmy {
  color: #023768 !important;
}
.textLightBlue___1wpz3 {
  color: #b2cbf7;
}
.textDarkGray___1zImj {
  color: #333333 !important;
}
.textMediumGray___28rru {
  color: #a2a2a2 !important;
}
.textLightGray___3F4Qe {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3t0Nx {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2ejAg {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2Kgd1 {
  border-radius: 5px !important;
}
.rounded-xs___v9JvZ {
  border-radius: 10px !important;
}
.rounded-sm___2N-o0 {
  border-radius: 15px !important;
}
.rounded-md___37UJw {
  border-radius: 20px !important;
}
.rounded-lg___3ekVs {
  border-radius: 25px !important;
}
.rounded-full___2v5nD {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1vIK0 {
  margin: 0;
}
.mt-2___3Wb8- {
  margin-top: 0.5rem;
}
.my-2___35Asm {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1g3z_ {
  padding: 0;
}
.px-1___3Pvh3 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2xtw_ {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1_yho {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3ij47 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2Jqca {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___SNRNZ {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___BGnm- {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___26PJA {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1jvY0 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1i5ab {
  padding-bottom: 20px;
}
.h-full___2sYME {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3xyqk {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___27p5L {
  font-weight: bold !important;
}
.fontWeight400___2lx_3 {
  font-weight: 400 !important;
}
.fontWeight500___2Qg90 {
  font-weight: 500 !important;
}
.fontWeight600___1L9iX {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___FPiXq {
  font-size: 12px !important;
}
.fontSize14___27aXL {
  font-size: 14px !important;
}
.fontSize16___37dLD {
  font-size: 16px !important;
}
.fontSize18___JOQO0 {
  font-size: 18px !important;
}
.fontSize20___3ypSZ {
  font-size: 20px !important;
}
.fontSize24___35lTL {
  font-size: 24px !important;
}
.eventName___3BrFB {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___24dlB {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2ypTO > tr > th,
.ant-table-tbody___1j8Em > tr.ant-table-row___2VrcQ > td,
.ant-table-summary___2ub3e tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2ypTO > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3_f59 .ant-table-thead___2ypTO > tr > th,
.table-portrait___3_f59 .ant-table-tbody___1j8Em > tr.ant-table-row___2VrcQ > td,
.table-portrait___3_f59 .ant-table-summary___2ub3e tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3_f59 .bgLightGray___1JWd2 td {
  background: #ebebeb;
}
.table-portrait___3_f59 .bgLightBlue___3iaWw td {
  background: #b2cbf7;
}
.eventContent___1ckc- .timeText___3dsj3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1j8Em > tr.bgLightGray___1JWd2:hover > td,
.ant-table-tbody___1j8Em > tr > td.ant-table-cell-row-hover___35-r1 {
  background: #ebebeb;
}
.ant-table-tbody___1j8Em > tr.bgLightBlue___3iaWw:hover > td,
.ant-table-tbody___1j8Em > tr > td.ant-table-cell-row-hover___35-r1 {
  background: #b2cbf7;
}
.ant-table-tbody___1j8Em > tr.bgWhite___JnUDt:hover > td,
.ant-table-tbody___1j8Em > tr > td.ant-table-cell-row-hover___35-r1 {
  background: #ffffff;
}
.eventTypeBadge___3xtLA {
  padding: 35px 40px 28px 40px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #b3bcbc60;
  margin: 16px 18px;
  width: 486px;
  max-height: none;
  max-width: calc(100% - 36px);
}
@media screen and (max-width: 767px) {
  .eventTypeBadge___3xtLA {
    padding: 15px 30px;
    margin: 5px 18px;
    max-height: none;
  }
}
.eventTypeBadge___3xtLA .headTitle___3P9Gn {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 3px;
}
@media screen and (max-width: 767px) {
  .eventTypeBadge___3xtLA .headTitle___3P9Gn {
    margin-bottom: 15px;
  }
}
.eventTypeBadge___3xtLA .headTitle___3P9Gn .bolderIcon___3EoAW {
  width: 6px;
  height: 24px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.eventTypeBadge___3xtLA .headTitle___3P9Gn .titleIcon___33tXz {
  width: 2px;
  height: 24px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.eventTypeBadge___3xtLA .headTitle___3P9Gn span {
  color: #3c3c3c;
  font-size: 25px;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .eventTypeBadge___3xtLA .headTitle___3P9Gn span {
    font-size: 20px;
  }
}
.eventTypeBadge___3xtLA p {
  font-size: 23px;
  margin-bottom: 12px;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .eventTypeBadge___3xtLA p {
    font-size: 16px;
  }
}
.eventTypeBadge___3xtLA .eventName___3BrFB {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: calc(100% - 60px);
  text-overflow: ellipsis;
  word-break: break-word;
}
.eventTypeBadge___3xtLA .btnZone___1Fd-i {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}
.eventTypeBadge___3xtLA .btnZone___1Fd-i button {
  height: 49px;
  width: 150px;
  font-size: 17px;
  border-radius: 20px;
  color: #3c3c3c;
  background-color: #ddf6f5;
  border: 2px solid #ddf6f5;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .eventTypeBadge___3xtLA .btnZone___1Fd-i button {
    height: 40px;
  }
}
.eventTypeBadge___3xtLA .btnZone___1Fd-i button:hover {
  border: 2px solid #33c3c7;
}
.eventTypeBadge___3xtLA .btnZone___1Fd-i button:active,
.eventTypeBadge___3xtLA .btnZone___1Fd-i button:focus {
  background-color: #33c3c7;
  color: #fff;
}
.eventTypeBadge___3xtLA .location___3qtVn {
  display: flex;
}
.eventTypeBadge___3xtLA .location___3qtVn span {
  word-break: keep-all;
}
.eventTypeBadge___3xtLA .locationName___2O7vY,
.eventTypeBadge___3xtLA .locationNameTitle___19SCO {
  font-size: 23px;
  margin-bottom: 12px;
  line-height: 1;
  width: calc(100% - 130px);
  word-break: break-all !important;
}
@media screen and (max-width: 767px) {
  .eventTypeBadge___3xtLA .locationName___2O7vY,
  .eventTypeBadge___3xtLA .locationNameTitle___19SCO {
    font-size: 16px;
  }
}
