@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___daSuv {
  display: flex;
  justify-content: center;
}
button.btn___i7pbh {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___i7pbh {
    padding: 10px;
  }
}
button.btn___i7pbh:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___i7pbh:focus {
  outline: none;
}
.btnWhite___2VF8u.btn___i7pbh {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2VF8u.btn___i7pbh:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2YN7_.btn___i7pbh,
.btnGreen___2YN7_.btn___i7pbh:hover,
.btnGreen___2YN7_.btn___i7pbh:active,
.btnGreen___2YN7_.btn___i7pbh:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___cAC-Q {
  color: #fe0000;
}
.errorMessage___2ar4k {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1Kf3E {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2eZdG {
  display: none !important;
}
.m-auto___1Izpc {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___ZGykk {
  max-width: 120px !important;
}
.fc___2t6uf .fc-timegrid-col___19mYL.fc-day-today___1A9X3 {
  background-color: #ffffff;
}
.fc___2t6uf .fc-timegrid-slot___2xuxK {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2t6uf .fc-timegrid-slot___2xuxK {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___272nX .fc-direction-ltr___QAVEE .fc-timegrid-slot-label-frame___17SQP {
    font-size: 10px;
  }
  .bookingCalendar___272nX .fc___2t6uf .fc-timegrid-slot-label-cushion___2m5f8 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___272nX .fc___2t6uf .fc-scrollgrid-section-header___lYbSH .fc-scroller___1lJrJ {
    overflow: unset !important;
  }
}
.ant-message-notice___2tt0j {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1GTAG {
  width: 20px;
  height: 20px;
}
.bgTransparent___3uhlv {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3DMEO {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2ixso {
  position: relative;
}
.inputCustomValue___2ixso .ant-input___3J6wG {
  padding-right: 50px;
}
.inputCustomValueText___2biAB {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2q3Af .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2q3Af .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___bETgU .ant-radio-inner___3Zan7 {
  border-color: black !important ;
}
.ant-radio-checked___bETgU .ant-radio-inner___3Zan7:after {
  background-color: black;
}
.ant-radio___3b7Qb:hover .ant-radio-inner___3Zan7 {
  border-color: black;
}
.ant-radio-checked___bETgU .ant-radio-inner___3Zan7:focus {
  border-color: black;
}
.modal___sx6F0 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___sx6F0 .close___2BZ19 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___sx6F0 .header___2JupY {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___sx6F0 .header___2JupY p {
  margin: 0;
}
.modal___sx6F0 .content___3GDC_ {
  text-align: center;
  font-size: 14px;
}
.modal___sx6F0 .content___3GDC_ p {
  margin: 0;
}
.modal___sx6F0 .actions___3vnGp {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___sx6F0 .actions___3vnGp .button___2qotQ {
  margin-top: 10px;
}
.modal___sx6F0 .actions___3vnGp:hover {
  color: #33c3c7;
}
.fc___2t6uf .fc-timegrid-slot-minor___33t31 {
  border-top-style: none;
}
.fc___2t6uf .fc-timegrid-slot___2xuxK {
  height: 1.5em !important;
}
.messageError___1MAjG .ant-message-custom-content___3n9zh {
  display: flex;
  text-align: start;
}
.messageError___1MAjG .ant-message-custom-content___3n9zh span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2OhJu {
  background-color: #e6e4e4;
}
.notifyMessage___3oQLI .ant-message-notice-content___3fUkt {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3oQLI .ant-message-notice-content___3fUkt .anticon___2X87k {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3oQLI .ant-message-notice-content___3fUkt span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2mNnt {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2mNnt .ant-notification-notice-message___2-fwJ,
.notifyCustomize___2mNnt .ant-notification-notice-close___KOR94,
.notifyCustomize___2mNnt .ant-notification-notice-icon___1mNzS {
  color: white;
}
.notifyCustomize___2mNnt .ant-notification-notice-message___2-fwJ {
  margin-left: 35px;
}
.notifyCustomize___2mNnt p {
  margin-bottom: 0;
}
.notifyCustomize___2mNnt p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___24fga {
  padding: 16px 14px;
}
.notifyAlertDuplicate___24fga .ant-notification-notice-message___2-fwJ {
  margin-left: 35px;
}
.notifyAlertDuplicate___24fga p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___24fga p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3qB9Q {
  width: 434px !important;
}
.handleSubmitVote___3qB9Q .ant-btn-primary___2GJYY {
  background: #59c3c7;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS .ant-modal-confirm-body___2HY8v {
  display: flex !important;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS .ant-modal-confirm-body___2HY8v .anticon___2X87k {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS .ant-modal-confirm-btns___cgpAQ button {
  font-weight: bold;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS .ant-modal-confirm-btns___cgpAQ button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS .ant-modal-confirm-btns___cgpAQ button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3qB9Q .ant-modal-body___iNqbS .ant-modal-confirm-btns___cgpAQ .ant-btn-primary___2GJYY {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1ZzI_ {
  margin-bottom: 0;
}
.mt-0___INkmi {
  margin-top: 0;
}
.tooltipFormat___RNWyf p {
  margin-bottom: 0;
}
.bgWhite___xVts0 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1FfU- {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1FfU-:hover,
.bgPrimaryBlue___1FfU-:focus,
.bgPrimaryBlue___1FfU-:active {
  background: #4f7ac7;
}
.bgDarkBlue___2JByM {
  background-color: #023768 !important;
}
.bgDarkBlue___2JByM:hover,
.bgDarkBlue___2JByM:focus,
.bgDarkBlue___2JByM:active {
  background: #194d7d;
}
.bgGreyBlue___Yp7X1 {
  background-color: #ebf0f9;
}
.bgLightBlue___15_cw {
  background-color: #b2cbf7 !important;
}
.bgLightRed___330hy {
  background-color: #e4453a;
}
.bgLightRed___330hy:hover,
.bgLightRed___330hy:focus,
.bgLightRed___330hy:active {
  background: #e17871;
}
.bgLightYellow___15yqh {
  background-color: #ffd603;
}
.bgDarkGray___3S4NZ {
  background-color: #333333;
}
.bgMediumGray___tNXPI {
  background-color: #a2a2a2;
}
.bgLightGray___1j5VS {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2G65Q {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3iq29 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2PSmG {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___361dC {
  color: #3368c7 !important;
}
.textDarkBlue___8dlU7 {
  color: #023768 !important;
}
.textLightBlue___2_Qeh {
  color: #b2cbf7;
}
.textDarkGray___27SqQ {
  color: #333333 !important;
}
.textMediumGray___1ncXn {
  color: #a2a2a2 !important;
}
.textLightGray___2AHG9 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1D9qN {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2dPxT {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3-wqZ {
  border-radius: 5px !important;
}
.rounded-xs___1MghY {
  border-radius: 10px !important;
}
.rounded-sm___D0Too {
  border-radius: 15px !important;
}
.rounded-md___2q1zs {
  border-radius: 20px !important;
}
.rounded-lg___1hFe2 {
  border-radius: 25px !important;
}
.rounded-full___3PWv- {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3lxln {
  margin: 0;
}
.mt-2___1YG5l {
  margin-top: 0.5rem;
}
.my-2___3M3Sq {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2hGhB {
  padding: 0;
}
.px-1___2vjFL {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___SBJ1- {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1dT7- {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___29kjy {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3dCat {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___30OTA {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___jzVf0 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3FhWh {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1hwEj {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1jGkB {
  padding-bottom: 20px;
}
.h-full___1al8Y {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3VSCH {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1eL-3 {
  font-weight: bold !important;
}
.fontWeight400___3F2-- {
  font-weight: 400 !important;
}
.fontWeight500___ZgXEf {
  font-weight: 500 !important;
}
.fontWeight600___3G4n4 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3rIDt {
  font-size: 12px !important;
}
.fontSize14___1TzGR {
  font-size: 14px !important;
}
.fontSize16___34kuB {
  font-size: 16px !important;
}
.fontSize18___10WbM {
  font-size: 18px !important;
}
.fontSize20___1RRhD {
  font-size: 20px !important;
}
.fontSize24___3NvUB {
  font-size: 24px !important;
}
.eventName___fw9gZ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___yFVr0 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___poUns > tr > th,
.ant-table-tbody___3drzu > tr.ant-table-row___SYOyT > td,
.ant-table-summary___IlU2Y tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___poUns > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___TyC1Y .ant-table-thead___poUns > tr > th,
.table-portrait___TyC1Y .ant-table-tbody___3drzu > tr.ant-table-row___SYOyT > td,
.table-portrait___TyC1Y .ant-table-summary___IlU2Y tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___TyC1Y .bgLightGray___1j5VS td {
  background: #ebebeb;
}
.table-portrait___TyC1Y .bgLightBlue___15_cw td {
  background: #b2cbf7;
}
.eventContent___Qlk1D .timeText___3xXt9 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3drzu > tr.bgLightGray___1j5VS:hover > td,
.ant-table-tbody___3drzu > tr > td.ant-table-cell-row-hover___n1e62 {
  background: #ebebeb;
}
.ant-table-tbody___3drzu > tr.bgLightBlue___15_cw:hover > td,
.ant-table-tbody___3drzu > tr > td.ant-table-cell-row-hover___n1e62 {
  background: #b2cbf7;
}
.ant-table-tbody___3drzu > tr.bgWhite___xVts0:hover > td,
.ant-table-tbody___3drzu > tr > td.ant-table-cell-row-hover___n1e62 {
  background: #ffffff;
}
.calendarInfoContainer___3gFE0 {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
  position: relative;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1.3rem;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 .bolderColIcon___1OwCX {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 8px;
  margin-right: 5px;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 .normalColIcon___k0SXG {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 3px;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR h2 {
  margin: 0;
  font-size: 24px;
}
.calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR p {
  font-size: 16px;
  width: 100%;
  padding-left: 58px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ {
  width: 100%;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
  display: flex;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 {
  margin-right: 10px;
  display: flex;
  height: 27px;
  padding-top: 4px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 div {
  display: inline-block;
  background-color: #33c3c7;
  height: 100%;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 div.bolderColIcon___1OwCX {
  width: 8px;
  margin-right: 5px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 div.normalColIcon___k0SXG {
  width: 3px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ {
  font-size: 18px;
  width: 100%;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .title___29i1L {
  font-weight: 600;
  color: #33c3c7;
  text-transform: uppercase;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .description___OxdxE {
  color: #3c3c3c;
  padding-bottom: 0.8rem;
  font-weight: 600;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .description___OxdxE p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: normal;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .descriptionOption___Gi311 {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .descriptionOption___Gi311 div {
  width: 50%;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .descriptionOption___Gi311 div:last-child {
  text-align: right;
  color: #33c3c7;
  cursor: pointer;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputRadio___2GVDr .inputBlockTime___3fTI8 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputTool___2EJW7 p {
  font-size: 14px;
  margin-bottom: 0.2rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputTool___2EJW7 p span {
  color: #33c3c7;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputTool___2EJW7 div {
  display: flex;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputTool___2EJW7 div input {
  padding: 7px 11px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputTool___2EJW7 div span {
  width: 7%;
  line-height: 40px;
  text-align: right;
  font-size: 16px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC {
  display: flex;
  justify-content: space-between;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr {
  border: 1px solid #e2dfdf;
  border-radius: 13px;
  width: 24%;
  text-align: center;
  cursor: pointer;
  background: #fff;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr img {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr p {
  font-size: 12px;
  margin-bottom: 0.15rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:hover {
  border-color: #33c3c7;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:nth-child(1) p {
  text-transform: uppercase;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:nth-child(2) img {
  padding-bottom: 0.8rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:nth-child(3) img {
  padding-bottom: 0.15rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:nth-child(4) img {
  padding-top: 1.2rem;
  padding-bottom: 0.7rem;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationActive___1o4E- {
  border: 2px solid #33c3c7;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .btnDropdownTemplate___lvxvC {
  width: 100%;
  padding: 8px 10px;
  height: unset;
  position: relative;
  border: 2px solid #f0eeee;
  border-radius: 7px;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .btnDropdownTemplate___lvxvC p {
  margin-bottom: 0;
  color: #3c3c3c;
  font-weight: 500;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .btnDropdownTemplate___lvxvC img {
  position: absolute;
  right: 2.9%;
  top: 40%;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .errorNotice___2oFKl {
  visibility: hidden;
  font-size: 11px;
  line-height: 1;
  transition: visibility 0.3s, color 0s;
  color: transparent;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .errorNotice___2oFKl a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .showErrorNotice___1a00K {
  visibility: visible;
  color: red;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .ant-radio-inner {
  height: 27px;
  width: 27px;
  position: relative;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .ant-radio-inner:after {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #00b6d3;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid white;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .ant-radio:hover .ant-radio-inner {
  border-color: #00b6d3 !important;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .ant-radio-checked .ant-radio-inner:focus {
  border-color: #00b6d3 !important;
}
.calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .ant-radio-wrapper span:last-child {
  margin: auto 0;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae {
  width: 49%;
  display: flex;
  font-size: 16px;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .fieldIcon___U2BaX {
  width: 7px;
  min-width: 7px;
  height: 17px;
  margin-top: 5px;
  background-color: #414141;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae p {
  margin: 0;
  min-width: 150px;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae input {
  flex-grow: 1;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .selectInput___3WxGw {
  width: 100%;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .errorNotice___2oFKl {
  visibility: hidden;
  font-size: 12px;
  line-height: 1;
  transition: visibility 0.3s, color 0s;
  color: transparent;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .errorNotice___2oFKl a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .showErrorNotice___1a00K {
  visibility: visible;
  color: red;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .errorNoticeLocation___1KAnS {
  font-size: 12px;
  color: red;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .errorNoticeLocation___1KAnS a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .moreInputField___3AiGO {
  width: 100%;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .radioSelect___3aAdS {
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid #ececec !important;
  width: 100%;
  padding: 2px 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .radioSelect___3aAdS .ant-radio-group {
  display: flex;
  justify-content: space-around;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae .title___29i1L {
  display: flex;
}
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(2),
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(4),
.calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(6) {
  margin-left: auto;
}
.calendarInfoContainer___3gFE0 .btnCloseModal___12A4U {
  display: none;
  background: transparent;
  outline: none;
  border: 0 transparent;
  position: absolute;
  right: -3px;
  top: 11px;
}
.calendarInfoContainer___3gFE0 .btnCloseModal___12A4U img {
  width: 60%;
  height: 60%;
}
.calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
  border: none;
  border-radius: 0 0 5px 5px;
  display: none;
  justify-content: space-evenly;
  padding: 1rem 0;
  width: 100%;
  margin-top: 1rem;
}
.calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K button {
  width: 40%;
  padding: 0.5rem 0.2rem;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  color: white;
}
.calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K button:first-child {
  background: #d2d2d2;
}
.calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K button:last-child {
  background: #00b6d3;
}
.calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K button:last-child.active___2sDzx {
  background: #3f949e;
}
@media screen and (max-width: 1440px) {
  .calendarInfoContainer___3gFE0 {
    padding: 20px 20px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR h2 {
    font-size: 22px;
  }
}
@media screen and (max-width: 1409px) {
  .calendarInfoContainer___3gFE0 {
    padding: 20px 20px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 {
    margin-right: 5px;
    height: 23px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .title___29i1L {
    font-size: 16px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .description___OxdxE {
    font-size: 15px;
  }
}
@media screen and (max-width: 1280px) {
  .calendarInfoContainer___3gFE0 {
    padding: 20px 18px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .calendarInfoContainer___3gFE0 {
    padding: 14px 10px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 {
    margin-right: 7px;
    height: 28px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 .bolderColIcon___1OwCX {
    height: 100%;
    margin-right: 3px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 .normalColIcon___k0SXG {
    height: 100%;
    width: 3px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR h2 {
    margin: 0;
    font-size: 20px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 {
    margin-right: 5px;
    height: 23px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .title___29i1L {
    font-size: 18px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .description___OxdxE,
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .descriptionOption___Gi311 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR h2 {
    font-size: 17px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 {
    margin-right: 5px;
    height: 23px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .title___29i1L {
    font-size: 18px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .description___OxdxE,
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .descriptionOption___Gi311 {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .calendarInfoContainer___3gFE0 {
    padding: 25px 0 0 0;
  }
  .calendarInfoContainer___3gFE0 .btnCloseModal___12A4U {
    display: unset;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 {
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR {
    flex-wrap: nowrap;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR .titleIcon___19JR2 {
    display: none;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR h2 {
    font-weight: 600;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ {
    padding: 0 10px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 {
    margin-right: 5px;
    height: 25px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 div.bolderColIcon___1OwCX {
    width: 4px;
    margin-right: 2px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 div.normalColIcon___k0SXG {
    width: 2px;
  }
  .calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K {
    display: flex;
  }
  .calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K button {
    width: 31%;
    padding: 0.8rem 0.2rem;
  }
  .calendarInfoContainer___3gFE0 .moreInput___1PNgm {
    flex-wrap: unset;
    flex-direction: column;
  }
  .calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae {
    width: 100%;
    align-items: unset;
    flex-direction: column;
  }
  .calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(2),
  .calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(4),
  .calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(6) {
    margin-left: unset;
  }
  .calendarInfoContainer___3gFE0 .moreInput___1PNgm .inputField___1lDae:nth-child(6) {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 425px) {
  .calendarInfoContainer___3gFE0 {
    padding: 25px 0 6rem 0;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ {
    padding: 0 10px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
    padding: 0;
    margin-top: 1rem;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .titleIcon___19JR2 div.normalColIcon___k0SXG {
    display: none;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr {
    border-radius: 7px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .inputTool___2EJW7 p {
    font-size: 11px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .description___OxdxE {
    padding-bottom: 0.5rem;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 {
    padding: 0 10px;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 .stepCalendarTitle___1IwXR {
    padding-bottom: 0;
  }
  .calendarInfoContainer___3gFE0 .calendarInfoContent___15ru5 button {
    width: 46%;
  }
  .calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K {
    position: fixed;
    bottom: 4%;
    width: 95.6%;
  }
  .calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K button {
    width: 46%;
  }
}
@media screen and (max-width: 375px) {
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 {
    padding: 0;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr img {
    padding: 0 0 0.3rem 0 !important;
    width: 26px;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr p {
    font-size: 9px;
    margin-bottom: 0;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:nth-child(3) {
    padding: 0.5rem 0;
  }
  .calendarInfoContainer___3gFE0 .formSettingBasic___3GEuQ .stepSetting___rEy2A .stepSettingItem___22XA3 .content___3GDC_ .locationSetting___P_WaC .locationItem___3L6Tr:nth-child(3) img {
    width: 25px;
    margin-top: 0.3rem;
    padding: 0 !important;
  }
  .calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K {
    width: 95.1%;
  }
}
@media screen and (max-width: 339px) {
  .calendarInfoContainer___3gFE0 .groupSaveBasicSetting___Kr68K {
    width: 94.8%;
  }
}
.optionStyle___2AVIw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.optionStyle___2AVIw .edit___1V2Qw {
  display: none;
}
.optionStyle___2AVIw:hover .edit___1V2Qw {
  display: block;
  width: 40px;
  height: 22px;
  display: flex;
  justify-content: flex-end;
  z-index: 111;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz {
  display: flex;
  justify-content: space-between;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX {
  display: flex;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTool___2Etv8 {
  position: absolute;
  top: 18px;
  left: -21px;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTool___2Etv8.active___2sDzx {
  position: unset;
  margin-right: 1rem;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTool___2Etv8.active___2sDzx .btnTogetherSettingBasic___3JMNc img {
  transform: rotateY(180deg);
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTool___2Etv8 .btnTogetherSettingBasic___3JMNc {
  background: white;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 7px;
  cursor: pointer;
  height: 35px;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI {
  display: flex;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 {
  margin-right: 15px;
  display: flex;
  height: 35px;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 div {
  background-color: #33c3c7;
  display: inline-block;
  height: 100%;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 .bolderColIcon___1OwCX {
  width: 8px;
  margin-right: 5px;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 .normalColIcon___k0SXG {
  width: 3px;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleText___2wpw7 h2 {
  margin: 0;
  font-size: 24px;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleText___2wpw7 h2 br {
  display: none;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderRight___2z_Wf {
  display: none;
}
.calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderRight___2z_Wf button {
  background: #636363;
  color: white;
  border: 1px solid #636363;
  border-radius: 6px;
  padding: 5px 1rem;
}
.calendarViewContainer___1cufR .titleDescript___1VSnb {
  margin-left: 30px;
  font-size: 16px;
  margin-bottom: 0;
}
.calendarViewContainer___1cufR .buttonDeleteCalendarContainer___1C0eN {
  text-align: right;
  padding-bottom: 1rem;
}
.calendarViewContainer___1cufR .buttonDeleteCalendarContainer___1C0eN button {
  background: transparent;
  border: 0 solid;
  cursor: pointer;
}
.calendarViewContainer___1cufR .buttonDeleteCalendarContainer___1C0eN button img {
  color: white;
  margin-right: 5px;
}
.calendarViewContainer___1cufR .buttonDeleteCalendarContainer___1C0eN button .buttonDeleteCalendar___38NQA {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
}
.calendarViewContainer___1cufR .showOnMobile___3s5FO {
  display: none;
}
.calendarViewContainer___1cufR .notShowOnMobile___acu-l {
  display: block;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #33c3c7;
  border: 1px solid #9af0ed;
  border-radius: 5px;
  overflow: visible;
  position: relative;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D .resizeIconTop___1KodZ {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D .resizeIconTop___1KodZ div {
  height: 2px;
  background-color: #3c3e3e;
  width: 17px;
  margin: 1px 0;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D:hover {
  background-color: #33c3c7;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D:hover .resizeIconBottom___OamX8,
.calendarViewContainer___1cufR .eventContent___Qlk1D:hover .resizeIconTop___1KodZ {
  opacity: 1;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D .timeText___3xXt9 {
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.calendarViewContainer___1cufR .eventContent___Qlk1D .timeText___3xXt9 p {
  font-weight: bold;
  margin: 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___1cufR .eventContent___Qlk1D .timeText___3xXt9 p p {
    margin: 10px 0;
  }
  .calendarViewContainer___1cufR .eventContent___Qlk1D .timeText___3xXt9 p span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    left: 50%;
  }
}
.calendarViewContainer___1cufR .recentAdded___2PgDv:hover,
.calendarViewContainer___1cufR .recentAdded___2PgDv:focus,
.calendarViewContainer___1cufR .recentAdded___2PgDv:active,
.calendarViewContainer___1cufR .recentAdded___2PgDv:visited {
  border: 2px dotted #33c3c7;
  background-color: #d1f3f2;
}
.calendarViewContainer___1cufR .recentAdded___2PgDv:hover .timeText___3xXt9,
.calendarViewContainer___1cufR .recentAdded___2PgDv:focus .timeText___3xXt9,
.calendarViewContainer___1cufR .recentAdded___2PgDv:active .timeText___3xXt9,
.calendarViewContainer___1cufR .recentAdded___2PgDv:visited .timeText___3xXt9 {
  color: #818c8c !important;
}
.calendarViewContainer___1cufR .isBooked___uopPm {
  pointer-events: none;
  background-color: #e8e6e6;
  display: flex;
  overflow: hidden;
  border: 1px solid #e8e6e6;
}
.calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 {
  margin: 0;
  width: 100%;
  justify-content: left;
  flex-wrap: wrap;
  overflow: hidden;
}
.calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .time___2s5HH {
  width: 100%;
  text-align: left;
  color: #5b5b5b;
  padding: 0 5px;
  font-weight: normal;
  letter-spacing: 1px;
  text-shadow: 1px 0 0 #5b5b5b, 1px 0 0 #5b5b5b;
  position: relative;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .time___2s5HH {
    text-shadow: none;
    text-align: center;
    padding: 0;
    font-size: 11px;
  }
  .calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .time___2s5HH p {
    margin: 10px 0;
  }
  .calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .time___2s5HH span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    left: 50%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .time___2s5HH {
    font-size: 10px;
  }
}
.calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .eventName___fw9gZ {
  width: 100%;
  text-align: left;
  color: #5b5b5b;
  font-weight: bold;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___1cufR .isBooked___uopPm .timeText___3xXt9 .eventName___fw9gZ {
    padding: 0;
    font-weight: normal;
    font-size: 11px;
    text-align: center;
  }
}
.calendarViewContainer___1cufR .isBooked___uopPm:hover {
  background-color: #e8e6e6;
  border: 1px solid #555555;
}
.calendarViewContainer___1cufR .isBooked___uopPm:hover .resizeIconBottom___OamX8,
.calendarViewContainer___1cufR .isBooked___uopPm:hover .resizeIconTop___1KodZ {
  opacity: 0;
}
.calendarViewContainer___1cufR .currentEvent___1xtkJ {
  background-color: #279ca0;
}
.calendarViewContainer___1cufR .currentEvent___1xtkJ .timeText___3xXt9 {
  color: #ffffff;
}
.calendarViewContainer___1cufR .ant-switch-checked {
  min-width: 68px;
  height: 19px;
  border: 1px solid #e2dfdf;
  box-sizing: border-box;
  background-color: #e3f4f4 !important;
}
.calendarViewContainer___1cufR .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 23px);
  width: 22px;
  height: 15px;
  top: 1px;
}
.calendarViewContainer___1cufR .ant-switch-checked .ant-switch-handle::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAudJREFUOI1NlM2KHVUUhb/qW7e7YxttM9CJtmKrDQHF0BNFfACjw0Rw6BMY5xLyBupcHDlRM85IEAM+QRAUfwa300kgpPv+VdU5Z/85qLr3ZrBYB6r4ap21N1V9f3KfC1sjPrm4tw98ERHXIuKNiNgNdwIgYu29xVpultz9H3e/LSLfvnZ4OAWofnzwiI8v7l0G7pyKvvpfKTwWZWFGZ0ZyxyLQCCLA6M/ZnKUZrRp7BB9sj3mviklO6eq7x8d/VMv5/Dmq6t6fRQ4mRUjuNGYsVFmqkQewMYCHj2TvweciPCmCdC3vE3y2XU+klHdqqurLh2oHp6JYBOJOMqMdtEpsQx0+nLMHnRmNGloKnjN3m4aXxqODK1vVjZqITyciSDidO60ZSzMWasxV14k9INjAiwdLNRpVvGQ8ZTwl7s4LR8/sXKsDjs50k3CuxkyUcxEWqiR3PGAYIwAeUIYqPPfAlR60LW3lb9bhPpoPgKUaM1WmKgPYKO44Q9qBHdD3nDOe05C2w1OHdR3tiJ3a3Vmo0pizNGUmylSU2TA8Wa3WKm5Ev37ufQV5BU1416fuRlC7O1PV9SbMpNdclCJCuG+Aa7gTZj2o6/AuYd0Az4lcV9TubnPR0VSVhfZpp6poSoQIYTZcfrAIIpxQ7YFti7UN3nVYSlSloDvjXJvZX9vhl58UYa7KXGQzDCng/lRq1jWEat9p02Bdi6dElMwLEZjZ31vu/tOV8ZgzEWY5Y20zqMXbFmuawftk1iyfUv/Mu4TnTIjyVj0CuF2dTibPm/u9rx89fuXXs3NsNYiUCSl9FUPiiFjfIESG/d28+/pWxYcXdk/G4/Hb9fWTh7MfXrz00ee74zsvb9cHvyyE+1236Vi1r2K1DeFgQxUibIlwieBod4fDejSpR6OrN2/dmlXHv/2O58x3+8/uL5vmRts019uuO0wp7ZRSEBHcvf+TDW5mmBmqiqpmNfs3In7eqqpvvrp5cwrwP4MiKimxFYSsAAAAAElFTkSuQmCC) !important;
  right: 3px;
  left: 0 !important;
}
.calendarViewContainer___1cufR .ant-switch {
  min-width: 68px;
  height: 21px;
  border: 1px solid #e2dfdf;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
}
@media screen and (max-width: 576px) {
  .calendarViewContainer___1cufR .ant-switch {
    min-width: 30px;
  }
}
.calendarViewContainer___1cufR .ant-switch .ant-switch-handle {
  width: 22px;
  height: 15px;
  top: 1px;
}
.calendarViewContainer___1cufR .ant-switch .ant-switch-handle::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAjhJREFUOI1tkz1OHEEQRr9me4Uj5AsYLJAILK1siUtYxNjXQHZsIS5gH8QyqX0P/yQEBCDyZT3TXVU9VUWw070D2pFKPcl8/epVTXhYLhFCQAjhJYBP7n7m7ofu/sLN4ADg3s714a1MlczsxsyuSinfXh8dLQEg/F+tEEJ4A+AX3PfdHeYON0N9nwbWx8zgZjAzqBl0GNCndM9E79+dnPwN3Wq1hxB+AzjAJNTG8i2hjbiGqiL1PVJKSCndicgiIoRzAAcANm3XCyaFqZIxvEIUERAzUkroU3rFzOcR7h8QQiMw9ydtNs9Tx/WSkZaIkHNGyhmp78HMH6MDx5j4rApUdaNiomEabGYtlHJu4cx8HN1s14ENreqmJo63BYsIcqVNCXntGMw8jza2Oh2Ybhne88FVWiJaKxgrr4kR68fVp5o12lIKVHXrqqnqegs227BWQgQRQTQzNrPd58SUM0QEqvpkE6rbMgzIOaPre/STcGaGqpZoqtfmvqiUqoo0DoNFGn3TAEBVMQwDUkroug5d1zUFpRS4+3VUsx9uttCx9Ty2lEfiQXWzyyOtmmEoBZmohRLRWp0ZdkL4Hu5vb/fM7I+I7KeU2gBqsA7Dk5+l6hKRNjwiarQA7ubz+SKa2aqUcppS+ply3q/DIGYIM8owtO2owVWFiIBFIKXAzBBCuJ/NZqcXl5cPUVXBIv9E5C0RfSaiM2I+JKJdEUEZP2qb495+nlIKTJUB3OzMZlcxxq9fLi6WAPAI1gYvMTb3g6oAAAAASUVORK5CYII=);
  background-color: unset;
  box-shadow: none;
  left: 3px;
  top: 3px;
}
.calendarViewContainer___1cufR .ant-switch:hover {
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
}
.calendarViewContainer___1cufR .fc-scroller .fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none;
}
.calendarViewContainer___1cufR .fc-scrollgrid-section-header .fc-scroller::-webkit-scrollbar {
  border-bottom: 1px solid #ddd;
}
.calendarViewContainer___1cufR .ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.calendarViewContainer___1cufR .fc-event-main {
  background-color: transparent;
  border-radius: 7px;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___1cufR .fc .fc-view-harness {
    min-height: 400px;
  }
}
.calendarViewContainer___1cufR .fc-media-screen .fc-timegrid-event {
  border: none;
}
.calendarViewContainer___1cufR .fc-media-screen .fc-timegrid-event-harness {
  background-color: #e3f4f4;
  border-radius: 10px;
  overflow: unset;
}
.calendarViewContainer___1cufR .fc-media-screen .fc-timegrid-event-harness:hover {
  width: 100% !important;
  left: 0 !important;
  z-index: 0.9 !important;
}
.calendarViewContainer___1cufR .fc-media-screen .fc-timegrid-event {
  border-radius: 10px;
  overflow: unset;
  background-color: #e3f4f4;
}
@media screen and (max-width: 768px) {
  .calendarViewContainer___1cufR .stepHeaderTool___2Etv8,
  .calendarViewContainer___1cufR .titleDescript___1VSnb,
  .calendarViewContainer___1cufR .buttonDeleteCalendarContainer___1C0eN {
    display: none;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz {
    padding: 0 15px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderRight___2z_Wf {
    display: unset;
  }
}
@media screen and (max-width: 700px) {
  .calendarViewContainer___1cufR .date___2NXAk {
    margin-bottom: 0.2rem;
    font-size: 18px;
    font-weight: 500;
  }
  .calendarViewContainer___1cufR .notShowOnMobile___acu-l {
    display: none;
  }
  .calendarViewContainer___1cufR .showOnMobile___3s5FO {
    display: block;
    margin-bottom: 0.2rem;
    font-weight: 500;
  }
}
@media screen and (max-width: 612px) {
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 {
    height: 25px;
    margin-right: 10px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 .bolderColIcon___1OwCX {
    width: 5px;
    margin-right: 2px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleIcon___19JR2 .normalColIcon___k0SXG {
    width: 2px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleText___2wpw7 h2 {
    font-weight: 600;
    font-size: 19px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleText___2wpw7 h2 br {
    display: block;
  }
  .calendarViewContainer___1cufR .titleDescript___1VSnb {
    margin-left: 25px;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 550px) {
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleText___2wpw7 h2 {
    font-size: 17px;
  }
}
@media screen and (max-width: 425px) {
  .calendarViewContainer___1cufR .titleDescript___1VSnb {
    font-size: 13px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderLeft___263GX .stepHeaderTitle___D5uMI .titleText___2wpw7 h2 {
    font-size: 13px;
  }
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderRight___2z_Wf button {
    font-size: 12px;
    padding: 3px 0.8rem;
  }
}
@media screen and (max-width: 375px) {
  .calendarViewContainer___1cufR .stepHeader___3q4Zz .stepHeaderRight___2z_Wf button {
    padding: 3px 0.5rem;
  }
}
.ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.fc-timegrid-event-harness .fc-timegrid-event .fc-event-resizer {
  display: none;
}
.fc-timegrid-event-harness .fc-timegrid-event.fc-event-selected .fc-event-resizer {
  display: block;
}
.fc-event-resizer-end {
  position: relative;
  height: 15px !important;
  background: transparent !important;
  border: none !important;
}
.fc-event-resizer-end::before {
  content: '';
  height: 2px;
  width: 17px;
  position: absolute;
  bottom: auto !important;
  background-color: #3c3e3e;
  left: 50% !important;
  right: auto !important;
  top: 3px !important;
  transform: translateX(-50%);
}
.fc-event-resizer-end::after {
  content: '';
  height: 2px;
  width: 17px;
  position: absolute;
  bottom: 5px !important;
  background-color: #3c3e3e;
  left: 50% !important;
  transform: translateX(-50%);
}
.recentAdded {
  border: 2px dashed #1890ff !important;
}
.syncData {
  border: 2px dashed #e8e6e6 !important;
}
.deleteEventBtn___20J0q {
  background-color: #555555;
  position: absolute;
  bottom: calc(100% + 13px);
  height: 25px;
  width: 75px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 8px;
  z-index: 99;
}
@media screen and (max-width: 767px) {
  .deleteEventBtn___20J0q {
    height: 40px;
    width: 40px;
    flex-flow: column;
  }
}
.deleteEventBtn___20J0q::after {
  content: '';
  width: 10px;
  height: 24px;
  border-top: 10px solid #555555;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
  top: 100%;
}
.deleteEventBtn___20J0q img {
  margin-right: 7px;
  width: 11px;
  height: 13px;
}
@media screen and (max-width: 767px) {
  .deleteEventBtn___20J0q img {
    margin-right: 0;
  }
}
.imageEventTypeIcon___2SD88 {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-top: -6px;
}
.createEventButtonGroup___1uDZX .editDetail___l2IdD {
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #707070;
  margin: 50px 0 70px 0;
}
.createEventButtonGroup___1uDZX .editDetail___l2IdD .editDetailButton___1QlO5 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 34px;
  min-width: 105px;
  padding: 0 20px;
  font-size: 14px;
  color: #434343;
  border-radius: 16px;
  background-color: #e8e6e6;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 {
  display: flex;
  flex-flow: row;
  margin-bottom: 3rem;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .previewBtnZone___1iRD9 {
  width: 34%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .previewBtnZone___1iRD9 .previewButton___385kR {
  margin: auto;
  height: 32px;
  min-width: 170px;
  background-color: #555555;
  color: #fffefe;
  text-align: center;
  border-radius: 6px;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .btnGroup___daSuv {
  width: 66%;
  display: flex;
  justify-content: flex-start;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .btnGroup___daSuv button {
  height: 48px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 17px;
  border-radius: 19px;
  width: 100%;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .btnGroup___daSuv button:first-child {
  margin: 0 20px 0 0;
  color: #535353;
  background-color: #fffefe;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border: none;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .btnGroup___daSuv button:last-child {
  margin: 0 20px;
  color: #fffefe;
  background-color: #00b6d3;
  transition: 0.3s;
}
.createEventButtonGroup___1uDZX .buttonZone___2yvT3 .btnGroup___daSuv .disabledBtnSave___282yK {
  background: #a6a3a3 !important;
}
@media screen and (max-width: 768px) {
  .createEventButtonGroup___1uDZX .editDetail___l2IdD {
    margin-bottom: 2rem;
  }
  .createEventButtonGroup___1uDZX .buttonZone___2yvT3 {
    flex-flow: column;
    margin-bottom: 1rem;
  }
  .createEventButtonGroup___1uDZX .buttonZone___2yvT3 .previewBtnZone___1iRD9 {
    width: 100%;
    justify-content: center;
    order: 2;
  }
  .createEventButtonGroup___1uDZX .buttonZone___2yvT3 .btnGroup___daSuv {
    display: none;
  }
}
.calendarView___3T5l6 {
  width: 100%;
  position: relative;
}
.calendarView___3T5l6 .disabledNav___1lwVM {
  pointer-events: none;
  display: none !important;
}
.calendarView___3T5l6 .ant-spin-nested-loading {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.calendarView___3T5l6 .fc .fc-scroller {
  overflow: auto !important;
}
.calendarView___3T5l6 .calendarContainer___1PULO {
  display: flex;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E {
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: -39px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E .currentMonth___3wo62 {
  font-size: 19px;
  letter-spacing: 2px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E .groupBtn___1ukLK {
  display: flex;
  justify-content: space-between;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E .groupBtnPrevious___2P15b {
  justify-content: space-between;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E button {
  display: flex;
  background: transparent;
  border: 0;
  padding: 2px 0;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E button span {
  line-height: 35px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E button .nextBtnMobile___1pC8N {
  height: 35px;
  width: 30px;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E button .nextBtnMobile___1pC8N:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(180deg);
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E button .prevBtnMobile___3bMPb {
  height: 35px;
  width: 30px;
  border: none;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E button .prevBtnMobile___3bMPb:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(0deg);
}
.calendarView___3T5l6 .calendarContainer___1PULO .multiBtn___3P8jf {
  justify-content: space-between;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA,
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .nextBtn___y3RhN {
  background-color: #ffffff;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 3px, rgba(0, 0, 0, 0.23) 0 0 7px;
  border: none;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 52px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA .btnImg___r5fSS,
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .nextBtn___y3RhN .btnImg___r5fSS {
  height: 13px;
  width: 14px;
  background-repeat: no-repeat;
  background-position: center;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA {
  left: 58px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA .btnImg___r5fSS {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  transform: rotate(180deg);
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA:hover .btnPreImg___3czPZ {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(0deg);
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .nextBtn___y3RhN {
  right: -18px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .nextBtn___y3RhN .btnImg___r5fSS {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
}
.calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .nextBtn___y3RhN:hover .btnNextImg___32hH0 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(180deg);
}
@media screen and (max-width: 1023px) {
  .calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .nextBtn___y3RhN {
    right: -12px;
  }
}
@media screen and (max-width: 768px) {
  .calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA {
    top: 0;
    overflow-x: auto;
    right: 0;
    width: 100%;
    justify-content: flex-end;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA {
    position: -webkit-sticky;
    position: sticky;
    left: 50px;
    box-shadow: none;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA .prevBtn___3tMQA {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    box-shadow: none;
  }
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx {
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
  margin-right: 10px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04 {
  display: flex;
  color: #000000;
  cursor: pointer;
  padding: 1rem;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04 .beforeFieldIcon___2_r6X {
  font-size: 16px;
  width: 7px;
  min-width: 7px;
  height: 17px;
  margin-right: 6px;
  background-color: #414141;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04 .listTeamTitle___2ZR3D {
  margin-bottom: 0;
  margin-right: 10px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04 .chevronDownImage___3Oioe {
  color: #565656;
  margin-left: 1rem;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04:hover {
  color: #33c3c7;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04:hover .beforeFieldIcon___2_r6X {
  background-color: #33c3c7;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonTeamMemberShow___2Zj04:hover .chevronDownImage___3Oioe {
  color: #33c3c7;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .listCheckBoxTeamMember___3YIjr {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-wrap: anywhere;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .listCheckBoxTeamMember___3YIjr .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--background-color);
  border-color: var(--border-color);
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .addMember___3LHrg {
  padding: 1rem 1rem 0 1rem;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonAddMember___BoCzd {
  padding-left: 1rem;
  padding-top: 1rem;
  color: #07989c;
  cursor: pointer;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .buttonAddMember___BoCzd:hover {
  color: #33c3c7;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx .lineClamp___1tXft {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy {
  position: absolute;
  z-index: 100;
  top: 46px;
  left: -1%;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC {
  border-radius: 7px;
  border: none;
  height: auto;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 6px, rgba(0, 0, 0, 0.23) 0 0 6px;
  z-index: 1;
  background: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 13px 4px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC .userBoldImage___3Gjyl {
  color: #565656;
  margin-left: 3px;
  width: 17px;
  height: 16px;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC .chevronRightImage___QT_7d {
  font-size: 15px;
  color: #565656;
}
.calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC:hover .chevronRightImage___QT_7d {
  color: #33c3c7;
}
@media screen and (max-width: 768px) {
  .calendarView___3T5l6 {
    overflow-x: auto;
    padding-top: 55px;
  }
  .calendarView___3T5l6 .fc .fc-view-harness {
    min-height: 400px;
  }
  .calendarView___3T5l6 .fc .fc-timegrid-slot .fc-timegrid-slot-label-frame {
    display: flex;
    justify-content: center;
  }
  .calendarView___3T5l6 .fc .fc-timegrid-slot .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
    width: 50px;
    text-align: center;
    white-space: break-spaces;
  }
  .calendarView___3T5l6 .ant-spin-nested-loading {
    box-shadow: unset;
    margin: auto;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO {
    position: relative;
    display: block;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .btnAction___15muA {
    display: none;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .btnActionMobile___2j21E {
    display: flex;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy {
    top: -20px;
    left: 1.5%;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC {
    padding: 10px 0 10px 4px;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC .userBoldImage___3Gjyl {
    height: 17px;
    width: 17px;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .teamMemberHide___3d6jy .buttonTeamMemberHide___3OWBC .chevronRightImage___QT_7d {
    font-size: 17px;
  }
  .calendarView___3T5l6 .calendarContainer___1PULO .teamMemberShow___3gKpx {
    position: absolute;
    background: white;
    z-index: 100;
    left: 1%;
    top: -3.5%;
  }
}
@media screen and (max-width: 550px) {
  .calendarView___3T5l6 .btnActionMobile___2j21E .groupBtn___1ukLK {
    width: 30%;
  }
  .calendarView___3T5l6 .btnActionMobile___2j21E .teamMemberHide___3d6jy {
    left: 2%;
  }
}
@media screen and (max-width: 768px) {
  .calendarView___3T5l6 {
    overflow-x: auto;
    padding-top: 40px;
  }
}
.sendEmailPop___33Xda {
  text-align: center;
  height: 276px !important;
}
.sendEmailPop___33Xda .close___2BZ19 {
  margin-left: auto;
  padding: 0 !important;
}
.sendEmailPop___33Xda .content___3GDC_ {
  padding-left: 24px;
  padding-right: 24px;
}
.sendEmailPop___33Xda .content___3GDC_ .inputSendMail___2j76z {
  border: 2px solid #000 !important;
}
.sendEmailPop___33Xda .button___2qotQ {
  margin-right: 5px;
  background-color: #33c3c7;
  color: #ffffff;
  border-radius: 12px;
  padding: 4px 24px;
  border: 0;
}
.headerTableCalendar___1XpyZ {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
}
.headerTableCalendar___1XpyZ p {
  margin-bottom: 0;
}
.headerTableCalendar___1XpyZ .titleDaySm___26t9y {
  display: none;
}
.headerTableCalendar___1XpyZ .date___2NXAk {
  font-size: 24px;
  font-weight: 500;
}
.headerTableCalendar___1XpyZ .dateSm___2qVvo {
  font-size: 24px;
  font-weight: 500;
  display: none;
}
.headerTableCalendar___1XpyZ .titleMonth___2QV30,
.headerTableCalendar___1XpyZ .titleDay___2YV3v {
  display: block;
  font-weight: 500;
  color: #8d928d;
  font-size: 15px;
}
.headerTableCalendar___1XpyZ .currentDay___YQbrN {
  background: #0c3dc9;
  color: #ffffff;
  border-radius: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}
.headerTableCalendar___1XpyZ .switchMobile___3ojJQ {
  border: 1px solid #e8e6e6;
  background: #ffffff;
  padding: 5px 3px 1px 3px;
  border-radius: 26px;
  box-shadow: 0 2px 1px grey;
  min-height: 58px;
  cursor: pointer;
  width: 30px;
  margin: 0 auto 0.4rem auto;
  display: none;
  transition: all 0.3s;
}
.headerTableCalendar___1XpyZ .switchMobile___3ojJQ .checkedTime___3j-Z9 {
  height: 22px;
  width: 22px;
  border-radius: 25px;
  background: #e2dfdf;
  margin: auto;
  box-shadow: 0px -1.4px 0px grey;
  border: 1px solid #cfd4d4;
  transition: all 0.3s;
}
.headerTableCalendar___1XpyZ .switchMobile___3ojJQ .dayWeek___3_pgx {
  margin-top: 3px;
}
.headerTableCalendar___1XpyZ .switchMobile___3ojJQ .dayWeek___3_pgx p {
  margin-bottom: 0;
  font-size: 9px;
  font-weight: normal;
}
.headerTableCalendar___1XpyZ .activeSwitchSM___3Ry9G {
  background: #e3f4f4;
}
.headerTableCalendar___1XpyZ .activeSwitchSM___3Ry9G .checkedTime___3j-Z9 {
  background: #33c3c7;
  border-color: #33c3c7;
}
.headerTableCalendar___1XpyZ .activeSwitchSM___3Ry9G .dayWeek___3_pgx {
  height: 28px;
  display: flex;
}
.headerTableCalendar___1XpyZ .activeSwitchSM___3Ry9G .dayWeek___3_pgx p {
  margin: auto;
}
.headerTableCalendar___1XpyZ .holidayName___39Mb1 {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  background-color: #0c3dc9;
  border-radius: 5px;
  padding: 3px;
}
@media screen and (max-width: 700px) {
  .headerTableCalendar___1XpyZ .switchMobile___3ojJQ {
    display: block;
  }
  .headerTableCalendar___1XpyZ .switchStatus___2xVn5 {
    display: none;
  }
  .headerTableCalendar___1XpyZ .date___2NXAk {
    font-size: 16px;
    display: none;
  }
  .headerTableCalendar___1XpyZ .titleMonth___2QV30,
  .headerTableCalendar___1XpyZ .titleDay___2YV3v {
    display: none;
  }
  .headerTableCalendar___1XpyZ .titleDaySm___26t9y {
    display: block;
    margin-bottom: 0.2rem;
    font-weight: 500;
  }
  .headerTableCalendar___1XpyZ .dateSm___2qVvo {
    font-size: 16px;
    display: unset;
  }
}
@media screen and (max-width: 700px) {
  .headerTableCalendar___1XpyZ {
    padding: 0;
  }
}
.mt0___2wrFH {
  margin-top: 0 !important;
}
@media screen and (max-width: 767px) {
  .bookingCalendarParent___1FbBS {
    position: relative;
    overflow: scroll;
    height: 570px;
  }
  .bookingCalendarParent___1FbBS > div {
    position: absolute;
  }
}
.dropdownMenuTemplate___3aSIr {
  overflow: hidden;
  width: 80%;
  margin: 10px auto 0 auto;
  padding: 0.5rem 0;
  left: 65px;
  border: 1px solid #707070;
  border-radius: 5px;
}
.dropdownMenuTemplate___3aSIr li:not(:last-child) {
  margin-bottom: 0.5rem !important;
}
.dropdownMenuTemplate___3aSIr li.dropdownMenuIcon___18mwK {
  background-color: white !important;
  color: black;
}
.dropdownMenuTemplate___3aSIr li.dropdownMenuIcon___18mwK:last-child {
  margin: 0;
}
.dropdownMenuTemplate___3aSIr li.dropdownMenuIcon___18mwK:hover {
  background-color: #f0f0f0 !important;
  color: black;
}
.dropdownMenuTemplate___3aSIr li.disabledTemplate___2HATg {
  background-color: white !important;
  color: black !important;
  cursor: not-allowed;
}
.dropdownMenuTemplate___3aSIr li.disabledTemplate___2HATg:hover {
  background-color: #f0f0f0 !important;
}
.dropdownMenuTemplate___3aSIr li span:last-child {
  margin-left: 0.5rem;
  font-weight: 500;
  color: #3c3c3c;
}
.dropdownMenuTemplate___3aSIr .activeIndex___19ik- {
  background-color: #e3f4f4;
}
.dropdownMenuTemplate___3aSIr .pageIndex___VNhuf {
  background: transparent !important;
}
.dropdownMenuTemplate___3aSIr .pageIndex___VNhuf:hover {
  background-color: #f0f0f0 !important;
}
.dropdownMenuTemplate___3aSIr .disabledTemplate___2HATg {
  background-color: #95959560 !important;
  cursor: not-allowed;
}
.dropdownMenuTemplate___3aSIr .disabledTemplate___2HATg:hover {
  background-color: #95959560 !important;
}
.content___3GDC_ {
  text-align: center;
}
.btnTeamList___Bk03R {
  width: 100%;
  text-align: center;
}
.btnTeamList___Bk03R .button___2qotQ {
  margin-top: 20px;
  background-color: #33c3c7;
  color: #ffffff;
  border-radius: 12px;
  padding: 4px 24px;
  border: 0;
}
