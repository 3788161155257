@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___35Sfb {
  display: flex;
  justify-content: center;
}
button.btn___mNPqy {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___mNPqy {
    padding: 10px;
  }
}
button.btn___mNPqy:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___mNPqy:focus {
  outline: none;
}
.btnWhite___1T_KF.btn___mNPqy {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1T_KF.btn___mNPqy:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___379hc.btn___mNPqy,
.btnGreen___379hc.btn___mNPqy:hover,
.btnGreen___379hc.btn___mNPqy:active,
.btnGreen___379hc.btn___mNPqy:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1FHoV {
  color: #fe0000;
}
.errorMessage___3YGK_ {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2c8s7 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2oQU1 {
  display: none !important;
}
.m-auto___2VH8W {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___17icX {
  max-width: 120px !important;
}
.fc___y20eY .fc-timegrid-col___3NIQk.fc-day-today___1nlt- {
  background-color: #ffffff;
}
.fc___y20eY .fc-timegrid-slot___2nKWv {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___y20eY .fc-timegrid-slot___2nKWv {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___Pga76 .fc-direction-ltr___1hYcP .fc-timegrid-slot-label-frame___1U52z {
    font-size: 10px;
  }
  .bookingCalendar___Pga76 .fc___y20eY .fc-timegrid-slot-label-cushion___3ebT8 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___Pga76 .fc___y20eY .fc-scrollgrid-section-header___1X-T6 .fc-scroller___3IBu_ {
    overflow: unset !important;
  }
}
.ant-message-notice___1pj-B {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___29C3Y {
  width: 20px;
  height: 20px;
}
.bgTransparent___p332R {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1VY7F {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1q1P2 {
  position: relative;
}
.inputCustomValue___1q1P2 .ant-input___3ZsFV {
  padding-right: 50px;
}
.inputCustomValueText___-xXaI {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3a27c .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3a27c .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___27RKX .ant-radio-inner___HcTHO {
  border-color: black !important ;
}
.ant-radio-checked___27RKX .ant-radio-inner___HcTHO:after {
  background-color: black;
}
.ant-radio___3I_lw:hover .ant-radio-inner___HcTHO {
  border-color: black;
}
.ant-radio-checked___27RKX .ant-radio-inner___HcTHO:focus {
  border-color: black;
}
.modal___3Lpz2 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3Lpz2 .close___2auYQ {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3Lpz2 .header___2A_Tx {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3Lpz2 .header___2A_Tx p {
  margin: 0;
}
.modal___3Lpz2 .content___2xSZv {
  text-align: center;
  font-size: 14px;
}
.modal___3Lpz2 .content___2xSZv p {
  margin: 0;
}
.modal___3Lpz2 .actions___3Sb9l {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3Lpz2 .actions___3Sb9l .button___3_N5P {
  margin-top: 10px;
}
.modal___3Lpz2 .actions___3Sb9l:hover {
  color: #33c3c7;
}
.fc___y20eY .fc-timegrid-slot-minor___1qOcj {
  border-top-style: none;
}
.fc___y20eY .fc-timegrid-slot___2nKWv {
  height: 1.5em !important;
}
.messageError___2falv .ant-message-custom-content___z-jH3 {
  display: flex;
  text-align: start;
}
.messageError___2falv .ant-message-custom-content___z-jH3 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1_exr {
  background-color: #e6e4e4;
}
.notifyMessage___3JIBM .ant-message-notice-content___2rxLK {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3JIBM .ant-message-notice-content___2rxLK .anticon___1JlzL {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3JIBM .ant-message-notice-content___2rxLK span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3Txso {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3Txso .ant-notification-notice-message___1pu6_,
.notifyCustomize___3Txso .ant-notification-notice-close___2M0H9,
.notifyCustomize___3Txso .ant-notification-notice-icon___31yLa {
  color: white;
}
.notifyCustomize___3Txso .ant-notification-notice-message___1pu6_ {
  margin-left: 35px;
}
.notifyCustomize___3Txso p {
  margin-bottom: 0;
}
.notifyCustomize___3Txso p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___I9_w8 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___I9_w8 .ant-notification-notice-message___1pu6_ {
  margin-left: 35px;
}
.notifyAlertDuplicate___I9_w8 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___I9_w8 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1e22G {
  width: 434px !important;
}
.handleSubmitVote___1e22G .ant-btn-primary___JNXJF {
  background: #59c3c7;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c .ant-modal-confirm-body___2Slij {
  display: flex !important;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c .ant-modal-confirm-body___2Slij .anticon___1JlzL {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c .ant-modal-confirm-btns___mWZlM button {
  font-weight: bold;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c .ant-modal-confirm-btns___mWZlM button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c .ant-modal-confirm-btns___mWZlM button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1e22G .ant-modal-body___12d2c .ant-modal-confirm-btns___mWZlM .ant-btn-primary___JNXJF {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3onEF {
  margin-bottom: 0;
}
.mt-0___1r12C {
  margin-top: 0;
}
.tooltipFormat___3YhfM p {
  margin-bottom: 0;
}
.bgWhite___2_SWk {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___vkOea {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___vkOea:hover,
.bgPrimaryBlue___vkOea:focus,
.bgPrimaryBlue___vkOea:active {
  background: #4f7ac7;
}
.bgDarkBlue___Mt5wq {
  background-color: #023768 !important;
}
.bgDarkBlue___Mt5wq:hover,
.bgDarkBlue___Mt5wq:focus,
.bgDarkBlue___Mt5wq:active {
  background: #194d7d;
}
.bgGreyBlue___1Nwpn {
  background-color: #ebf0f9;
}
.bgLightBlue___3XZG3 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2oA8- {
  background-color: #e4453a;
}
.bgLightRed___2oA8-:hover,
.bgLightRed___2oA8-:focus,
.bgLightRed___2oA8-:active {
  background: #e17871;
}
.bgLightYellow___1rOYV {
  background-color: #ffd603;
}
.bgDarkGray___3tWNu {
  background-color: #333333;
}
.bgMediumGray___dWbDT {
  background-color: #a2a2a2;
}
.bgLightGray___3MVtn {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2ehC0 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1D6fQ {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2EqVM {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___zwSOk {
  color: #3368c7 !important;
}
.textDarkBlue___2WkW4 {
  color: #023768 !important;
}
.textLightBlue___Wh9xB {
  color: #b2cbf7;
}
.textDarkGray___1WfFW {
  color: #333333 !important;
}
.textMediumGray___3DfIz {
  color: #a2a2a2 !important;
}
.textLightGray___o_LRU {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2XAsj {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3Bo1X {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___30znO {
  border-radius: 5px !important;
}
.rounded-xs___2Y27P {
  border-radius: 10px !important;
}
.rounded-sm___2ONGk {
  border-radius: 15px !important;
}
.rounded-md___2Zv2n {
  border-radius: 20px !important;
}
.rounded-lg___zg_PL {
  border-radius: 25px !important;
}
.rounded-full___GVjzu {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___JiGTd {
  margin: 0;
}
.mt-2___2aCDw {
  margin-top: 0.5rem;
}
.my-2___2EEFl {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1QtuM {
  padding: 0;
}
.px-1___2kK2U {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2uZ4t {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___39zpS {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___zNruE {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___dsap4 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___5UbrB {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___291Gc {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3p3eL {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2YLzl {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2zO9G {
  padding-bottom: 20px;
}
.h-full___uOF65 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___bDNtY {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___BzVmx {
  font-weight: bold !important;
}
.fontWeight400___2jV8B {
  font-weight: 400 !important;
}
.fontWeight500___MOHnc {
  font-weight: 500 !important;
}
.fontWeight600___bttmm {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2OnVy {
  font-size: 12px !important;
}
.fontSize14___1Hfti {
  font-size: 14px !important;
}
.fontSize16___1BTil {
  font-size: 16px !important;
}
.fontSize18___mie0S {
  font-size: 18px !important;
}
.fontSize20___1elhL {
  font-size: 20px !important;
}
.fontSize24___3tLqD {
  font-size: 24px !important;
}
.eventName___1wUVo {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___fXkpE {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3QjNI > tr > th,
.ant-table-tbody___1Uwhh > tr.ant-table-row___Hj3uE > td,
.ant-table-summary___3n-j8 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3QjNI > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___Mobzz .ant-table-thead___3QjNI > tr > th,
.table-portrait___Mobzz .ant-table-tbody___1Uwhh > tr.ant-table-row___Hj3uE > td,
.table-portrait___Mobzz .ant-table-summary___3n-j8 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___Mobzz .bgLightGray___3MVtn td {
  background: #ebebeb;
}
.table-portrait___Mobzz .bgLightBlue___3XZG3 td {
  background: #b2cbf7;
}
.eventContent___ssipR .timeText___nyW6i {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1Uwhh > tr.bgLightGray___3MVtn:hover > td,
.ant-table-tbody___1Uwhh > tr > td.ant-table-cell-row-hover___1U-SJ {
  background: #ebebeb;
}
.ant-table-tbody___1Uwhh > tr.bgLightBlue___3XZG3:hover > td,
.ant-table-tbody___1Uwhh > tr > td.ant-table-cell-row-hover___1U-SJ {
  background: #b2cbf7;
}
.ant-table-tbody___1Uwhh > tr.bgWhite___2_SWk:hover > td,
.ant-table-tbody___1Uwhh > tr > td.ant-table-cell-row-hover___1U-SJ {
  background: #ffffff;
}
.login___2kkWo {
  max-width: 1080px;
  margin: auto;
  padding: 36px 15px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
}
.login___2kkWo .eventTitle___3oEGL {
  color: #3c3c3c;
  display: flex;
  align-items: center;
}
.login___2kkWo .eventTitle___3oEGL .titleIcon___3VbB1 {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.login___2kkWo .eventTitle___3oEGL .titleIcon___3VbB1 .bolderColIcon___2_zMT {
  display: inline-block;
  background-color: #33c3c7;
  margin-right: 5px;
  height: 30px;
  width: 8px;
}
@media screen and (max-width: 767px) {
  .login___2kkWo .eventTitle___3oEGL .titleIcon___3VbB1 .bolderColIcon___2_zMT {
    height: 28px;
    width: 6.5px;
  }
}
.login___2kkWo .eventTitle___3oEGL .titleIcon___3VbB1 .normalColIcon___8Wpdl {
  display: inline-block;
  background-color: #33c3c7;
  height: 30px;
  width: 4px;
}
@media screen and (max-width: 767px) {
  .login___2kkWo .eventTitle___3oEGL .titleIcon___3VbB1 .normalColIcon___8Wpdl {
    height: 28px;
    width: 2px;
  }
}
.login___2kkWo .eventTitle___3oEGL h2 {
  font-size: 28px;
  margin-bottom: 0;
  width: 55%;
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .login___2kkWo .eventTitle___3oEGL h2 {
    font-size: 24px;
    font-weight: bold;
    margin-left: 7px;
  }
}
@media screen and (max-width: 440px) {
  .login___2kkWo .eventTitle___3oEGL h2 {
    margin-left: -7px;
  }
}
.login___2kkWo .eventTitle___3oEGL .scrollToCardPersonButtonContainer___2M-VI {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 5%;
}
.login___2kkWo .eventTitle___3oEGL .scrollToCardPersonButtonContainer___2M-VI .scrollToCardPersonButton___1OMBd {
  margin: 0;
  font-size: 16px;
  background-color: #e8e6e6;
  padding: 1px 9px 3px;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 500;
}
.login___2kkWo .loginHeader___2anRE {
  justify-content: space-between;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .login___2kkWo .loginHeader___2anRE {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}
.login___2kkWo .subTitle___23tCl {
  margin-top: 8px;
  margin-left: 38px;
  font-size: 14px;
}
@media screen and (max-width: 440px) {
  .login___2kkWo .subTitle___23tCl {
    margin-left: 23px;
    font-size: 12px;
  }
}
.login___2kkWo .main___Nv5ws {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 832px) {
  .login___2kkWo .main___Nv5ws {
    display: block;
  }
}
.login___2kkWo .card___3ZLk5 {
  width: 420px;
  position: relative;
  border-radius: 16px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
@media screen and (max-width: 832px) {
  .login___2kkWo .card___3ZLk5 {
    margin: auto;
    width: 90%;
  }
}
.login___2kkWo .card___3ZLk5:not(:last-child) {
  margin-right: 32px;
}
@media screen and (max-width: 832px) {
  .login___2kkWo .card___3ZLk5:not(:last-child) {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}
.login___2kkWo .card___3ZLk5 .grid___20SHr {
  position: relative;
  padding: 24px 24px 5px 24px;
  height: auto;
}
.login___2kkWo .card___3ZLk5 .grid___20SHr:not(:first-child) {
  border-top: 1.5px solid rgba(0, 0, 0, 0.12);
}
.login___2kkWo .card___3ZLk5 .grid___20SHr > div button {
  margin: auto;
}
.login___2kkWo .card___3ZLk5 .grid___20SHr > div:not(:last-child) {
  margin-bottom: 16px;
}
.login___2kkWo .card___3ZLk5 .title___3o5X4 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.login___2kkWo .card___3ZLk5 .title___3o5X4 img {
  height: 35px;
  margin-right: 16px;
}
@media screen and (max-width: 832px) {
  .login___2kkWo .card___3ZLk5 .title___3o5X4 img {
    height: 26px;
  }
}
.login___2kkWo .card___3ZLk5 .title___3o5X4 div h1 {
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 500;
}
@media screen and (max-width: 832px) {
  .login___2kkWo .card___3ZLk5 .title___3o5X4 div h1 {
    font-size: 20px;
  }
}
.login___2kkWo .card___3ZLk5 .title___3o5X4 div p {
  margin-bottom: 0;
  transition: 0.3s;
  color: red;
  opacity: 0;
}
.login___2kkWo .card___3ZLk5 .title___3o5X4 div .highLightP___1MOez {
  opacity: 1;
}
.login___2kkWo .card___3ZLk5 .footer___1zZrm {
  padding: 32px;
}
.login___2kkWo .card___3ZLk5 .footer___1zZrm .loginLinkOther___FNzSN {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  text-align: center;
  color: #353535;
}
@media screen and (max-width: 832px) {
}
.login___2kkWo .card___3ZLk5 .footer___1zZrm .registerBtn___JoQpI {
  font-size: 16px;
  color: #fff;
  background: #d8d8d8;
  display: block;
  text-align: center;
  margin: auto;
  border-radius: 6px;
  padding: 5px 40px;
  height: auto;
  font-weight: bold;
}
.login___2kkWo .loginButton___1Qeou {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  background-color: #e3f4f4;
  width: 326px;
  border: 0;
  height: auto;
  padding: 16px;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 832px) {
  .login___2kkWo .loginButton___1Qeou {
    font-size: 10.5px;
    padding: 10px;
    width: 249px;
  }
}
.login___2kkWo .loginButton___1Qeou img {
  margin-right: 8px;
}
.login___2kkWo .loginButton___1Qeou .rightSpan___6DEvt {
  color: #33c3c7;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
@media screen and (max-width: 832px) {
  .login___2kkWo .loginButton___1Qeou .rightSpan___6DEvt {
    font-size: 10.5px;
  }
}
.login___2kkWo .loginButton___1Qeou:hover {
  background-color: #33c3c7;
  color: #fff !important;
}
.login___2kkWo .loginButton___1Qeou:hover span:last-child {
  color: #fff !important;
}
.login___2kkWo .highlightBtn___3f9vI {
  background-color: #33c3c7;
  color: #fff;
  opacity: 0.8;
}
.login___2kkWo .ant-checkbox-input,
.login___2kkWo .ant-checkbox-inner {
  display: none;
}
.login___2kkWo .ant-checkbox-checked::after {
  display: none;
}
.login___2kkWo .ant-checkbox::before {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  border-radius: 6px;
  border: 3px solid #7f7f7f;
  top: -18px;
}
@media screen and (max-width: 767px) {
  .login___2kkWo .ant-checkbox::before {
    left: -40px;
  }
}
.login___2kkWo .ant-checkbox.ant-checkbox-checked::before {
  background: url(./static/icon-checked.3d17b49b.svg) center no-repeat;
  background-size: 70% 70%;
}
.safari___7nOpR {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: white;
  text-align: center;
}
.safari___7nOpR .safariLogo___1O6ch {
  margin-top: 36px;
}
.safari___7nOpR .bigText___S7eI9 {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 45px;
  margin-top: 22px;
}
.safari___7nOpR .smallText___1yOfx {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}
.safari___7nOpR .link___1NQ5E {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}
.safari___7nOpR .link___1NQ5E a {
  color: #0f62ff;
  text-decoration: underline;
  cursor: pointer;
}
