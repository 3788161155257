@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___24KuZ {
  display: flex;
  justify-content: center;
}
button.btn___1ykbt {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1ykbt {
    padding: 10px;
  }
}
button.btn___1ykbt:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1ykbt:focus {
  outline: none;
}
.btnWhite___1WFU7.btn___1ykbt {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1WFU7.btn___1ykbt:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3IhMl.btn___1ykbt,
.btnGreen___3IhMl.btn___1ykbt:hover,
.btnGreen___3IhMl.btn___1ykbt:active,
.btnGreen___3IhMl.btn___1ykbt:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2lAJz {
  color: #fe0000;
}
.errorMessage___20xXO {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___pBGSg {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2JSW8 {
  display: none !important;
}
.m-auto___2EBJc {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___cR3ZR {
  max-width: 120px !important;
}
.fc___3IsEC .fc-timegrid-col___TghKW.fc-day-today___1J-Xe {
  background-color: #ffffff;
}
.fc___3IsEC .fc-timegrid-slot___2J5GC {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3IsEC .fc-timegrid-slot___2J5GC {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3o21_ .fc-direction-ltr___1pI5p .fc-timegrid-slot-label-frame___2grHS {
    font-size: 10px;
  }
  .bookingCalendar___3o21_ .fc___3IsEC .fc-timegrid-slot-label-cushion___1v2Vu {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3o21_ .fc___3IsEC .fc-scrollgrid-section-header___jVGLY .fc-scroller___3fsBr {
    overflow: unset !important;
  }
}
.ant-message-notice___2HvSk {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3y5mv {
  width: 20px;
  height: 20px;
}
.bgTransparent___mgB5- {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1m0Tw {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___20Xve {
  position: relative;
}
.inputCustomValue___20Xve .ant-input___29Va0 {
  padding-right: 50px;
}
.inputCustomValueText___2yxoD {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3CoUg .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3CoUg .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2sWk3 .ant-radio-inner___3fZ-7 {
  border-color: black !important ;
}
.ant-radio-checked___2sWk3 .ant-radio-inner___3fZ-7:after {
  background-color: black;
}
.ant-radio___SZ8ii:hover .ant-radio-inner___3fZ-7 {
  border-color: black;
}
.ant-radio-checked___2sWk3 .ant-radio-inner___3fZ-7:focus {
  border-color: black;
}
.modal___19nwP {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___19nwP .close___3v9O9 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___19nwP .header___3Y9sI {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___19nwP .header___3Y9sI p {
  margin: 0;
}
.modal___19nwP .content___1HwjE {
  text-align: center;
  font-size: 14px;
}
.modal___19nwP .content___1HwjE p {
  margin: 0;
}
.modal___19nwP .actions___295uO {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___19nwP .actions___295uO .button___lEsLt {
  margin-top: 10px;
}
.modal___19nwP .actions___295uO:hover {
  color: #33c3c7;
}
.fc___3IsEC .fc-timegrid-slot-minor___3s0Fi {
  border-top-style: none;
}
.fc___3IsEC .fc-timegrid-slot___2J5GC {
  height: 1.5em !important;
}
.messageError___1x2eH .ant-message-custom-content___1sps9 {
  display: flex;
  text-align: start;
}
.messageError___1x2eH .ant-message-custom-content___1sps9 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1pye5 {
  background-color: #e6e4e4;
}
.notifyMessage___R9BOP .ant-message-notice-content___360HQ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___R9BOP .ant-message-notice-content___360HQ .anticon___1nli_ {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___R9BOP .ant-message-notice-content___360HQ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2V0xn {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2V0xn .ant-notification-notice-message___2DVzW,
.notifyCustomize___2V0xn .ant-notification-notice-close___2qPNM,
.notifyCustomize___2V0xn .ant-notification-notice-icon___3DcCi {
  color: white;
}
.notifyCustomize___2V0xn .ant-notification-notice-message___2DVzW {
  margin-left: 35px;
}
.notifyCustomize___2V0xn p {
  margin-bottom: 0;
}
.notifyCustomize___2V0xn p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___24TfU {
  padding: 16px 14px;
}
.notifyAlertDuplicate___24TfU .ant-notification-notice-message___2DVzW {
  margin-left: 35px;
}
.notifyAlertDuplicate___24TfU p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___24TfU p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1K9oE {
  width: 434px !important;
}
.handleSubmitVote___1K9oE .ant-btn-primary___2NE-Q {
  background: #59c3c7;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy .ant-modal-confirm-body___3IuT_ {
  display: flex !important;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy .ant-modal-confirm-body___3IuT_ .anticon___1nli_ {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy .ant-modal-confirm-btns___1VR1Z button {
  font-weight: bold;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy .ant-modal-confirm-btns___1VR1Z button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy .ant-modal-confirm-btns___1VR1Z button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1K9oE .ant-modal-body___IXGvy .ant-modal-confirm-btns___1VR1Z .ant-btn-primary___2NE-Q {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2CABN {
  margin-bottom: 0;
}
.mt-0___21rOt {
  margin-top: 0;
}
.tooltipFormat___1nMGJ p {
  margin-bottom: 0;
}
.bgWhite___3ummZ {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___vURDb {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___vURDb:hover,
.bgPrimaryBlue___vURDb:focus,
.bgPrimaryBlue___vURDb:active {
  background: #4f7ac7;
}
.bgDarkBlue___CIeZo {
  background-color: #023768 !important;
}
.bgDarkBlue___CIeZo:hover,
.bgDarkBlue___CIeZo:focus,
.bgDarkBlue___CIeZo:active {
  background: #194d7d;
}
.bgGreyBlue___1k5aQ {
  background-color: #ebf0f9;
}
.bgLightBlue___1YhYZ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1sZKB {
  background-color: #e4453a;
}
.bgLightRed___1sZKB:hover,
.bgLightRed___1sZKB:focus,
.bgLightRed___1sZKB:active {
  background: #e17871;
}
.bgLightYellow___utAuD {
  background-color: #ffd603;
}
.bgDarkGray___3dbY- {
  background-color: #333333;
}
.bgMediumGray___23rGt {
  background-color: #a2a2a2;
}
.bgLightGray___BgfRa {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3-jsS {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2zXZt {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___zotdK {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2G6E4 {
  color: #3368c7 !important;
}
.textDarkBlue___l27dA {
  color: #023768 !important;
}
.textLightBlue___3woxN {
  color: #b2cbf7;
}
.textDarkGray___1n_Jf {
  color: #333333 !important;
}
.textMediumGray___CTip8 {
  color: #a2a2a2 !important;
}
.textLightGray___1FYBI {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___31hny {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3gcZ5 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3n5sM {
  border-radius: 5px !important;
}
.rounded-xs___2X2EO {
  border-radius: 10px !important;
}
.rounded-sm___3KUdW {
  border-radius: 15px !important;
}
.rounded-md___YYHO6 {
  border-radius: 20px !important;
}
.rounded-lg___3WeUi {
  border-radius: 25px !important;
}
.rounded-full___26FBj {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___peLGw {
  margin: 0;
}
.mt-2___2iPPw {
  margin-top: 0.5rem;
}
.my-2___2I-bG {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1gBjr {
  padding: 0;
}
.px-1___GsGML {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___bBeeJ {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3KUQm {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2yhJE {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3c-S- {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3eb-q {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1ZUOo {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1R2tR {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1aQpn {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3XbTe {
  padding-bottom: 20px;
}
.h-full___3WJmz {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___pM3e6 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___HhboW {
  font-weight: bold !important;
}
.fontWeight400___2rOGO {
  font-weight: 400 !important;
}
.fontWeight500___31BbX {
  font-weight: 500 !important;
}
.fontWeight600____GGqj {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2KsIT {
  font-size: 12px !important;
}
.fontSize14___1YtUB {
  font-size: 14px !important;
}
.fontSize16___3hyBR {
  font-size: 16px !important;
}
.fontSize18___OWUul {
  font-size: 18px !important;
}
.fontSize20___Cstcm {
  font-size: 20px !important;
}
.fontSize24___1xv69 {
  font-size: 24px !important;
}
.eventName___2zj-i {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3VV6y {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___14_0T > tr > th,
.ant-table-tbody___1g7d2 > tr.ant-table-row___2iUmH > td,
.ant-table-summary___2JnCt tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___14_0T > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1nV9m .ant-table-thead___14_0T > tr > th,
.table-portrait___1nV9m .ant-table-tbody___1g7d2 > tr.ant-table-row___2iUmH > td,
.table-portrait___1nV9m .ant-table-summary___2JnCt tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1nV9m .bgLightGray___BgfRa td {
  background: #ebebeb;
}
.table-portrait___1nV9m .bgLightBlue___1YhYZ td {
  background: #b2cbf7;
}
.eventContent___14_ZC .timeText___22z4M {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1g7d2 > tr.bgLightGray___BgfRa:hover > td,
.ant-table-tbody___1g7d2 > tr > td.ant-table-cell-row-hover___2dUqH {
  background: #ebebeb;
}
.ant-table-tbody___1g7d2 > tr.bgLightBlue___1YhYZ:hover > td,
.ant-table-tbody___1g7d2 > tr > td.ant-table-cell-row-hover___2dUqH {
  background: #b2cbf7;
}
.ant-table-tbody___1g7d2 > tr.bgWhite___3ummZ:hover > td,
.ant-table-tbody___1g7d2 > tr > td.ant-table-cell-row-hover___2dUqH {
  background: #ffffff;
}
.signIn___bXx47 {
  margin-top: 20px;
  padding: 20px;
}
.signIn___bXx47 .fieldName___c2dVe {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.signIn___bXx47 .inputField___MAxX4 {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
}
.signIn___bXx47 .btnZone___30TAi {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.signIn___bXx47 .btnZone___30TAi .signInBtn___QKrNP {
  width: 100%;
  height: 40px;
  background-color: #023768;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.signIn___bXx47 .btnZone___30TAi .signInBtn___QKrNP:hover {
  background-color: #1f4f96;
}
.signIn___bXx47 .forgotPassword___20TVG {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  color: #1f4f96;
  font-weight: 500;
  text-decoration: underline;
}
.signIn___bXx47 .registerHere___13dnK {
  text-align: center;
  font-weight: 500;
  margin-top: 50px;
}
.signIn___bXx47 .registerHere___13dnK a {
  color: #1f4f96;
  font-weight: 700;
  text-decoration: underline;
}
.signIn___bXx47 .divider___3uc1E {
  display: flex;
  font-weight: 500;
  color: #555555;
}
.signIn___bXx47 .divider___3uc1E:before,
.signIn___bXx47 .divider___3uc1E:after {
  content: '';
  flex: 1 1;
}
.signIn___bXx47 .line___FFZAm {
  align-items: center;
  margin: 1em -1em;
}
.signIn___bXx47 .line___FFZAm:before,
.signIn___bXx47 .line___FFZAm:after {
  height: 1px;
  margin: 0 1em;
}
.signIn___bXx47 .oneLine___2xIDm:before,
.signIn___bXx47 .oneLine___2xIDm:after {
  background: #dcdcdc;
}
.signIn___bXx47 .btnSocial___2YOmk {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
}
.signIn___bXx47 .btnSocial___2YOmk img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.signIn___bXx47 .logo___2oefU {
  margin-top: 20px;
  text-align: center;
}
.signIn___bXx47 .logo___2oefU img {
  width: 50%;
}
.ant-form-item .ant-form-item-control-input-content input {
  margin-bottom: 0;
}
