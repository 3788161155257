@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___xsh03 {
  display: flex;
  justify-content: center;
}
button.btn___1CP9t {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1CP9t {
    padding: 10px;
  }
}
button.btn___1CP9t:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1CP9t:focus {
  outline: none;
}
.btnWhite___3j9sE.btn___1CP9t {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3j9sE.btn___1CP9t:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___e_mdw.btn___1CP9t,
.btnGreen___e_mdw.btn___1CP9t:hover,
.btnGreen___e_mdw.btn___1CP9t:active,
.btnGreen___e_mdw.btn___1CP9t:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2mPMG {
  color: #fe0000;
}
.errorMessage___3TsbV {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1RIf- {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2rvw6 {
  display: none !important;
}
.m-auto___1IUVU {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2lwA- {
  max-width: 120px !important;
}
.fc___RXHHi .fc-timegrid-col___3CPan.fc-day-today___2lPQ6 {
  background-color: #ffffff;
}
.fc___RXHHi .fc-timegrid-slot___Ps1CW {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___RXHHi .fc-timegrid-slot___Ps1CW {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___yZRg8 .fc-direction-ltr___1RU3Y .fc-timegrid-slot-label-frame___39QVr {
    font-size: 10px;
  }
  .bookingCalendar___yZRg8 .fc___RXHHi .fc-timegrid-slot-label-cushion___3K7JN {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___yZRg8 .fc___RXHHi .fc-scrollgrid-section-header___1myuh .fc-scroller___HV5Jk {
    overflow: unset !important;
  }
}
.ant-message-notice___3aPwu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1KUMQ {
  width: 20px;
  height: 20px;
}
.bgTransparent___3RPKy {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___6PR9m {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1FGiY {
  position: relative;
}
.inputCustomValue___1FGiY .ant-input___7Okc0 {
  padding-right: 50px;
}
.inputCustomValueText___3y7UC {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1Kzpw .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1Kzpw .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2UE-8 .ant-radio-inner___1sQUj {
  border-color: black !important ;
}
.ant-radio-checked___2UE-8 .ant-radio-inner___1sQUj:after {
  background-color: black;
}
.ant-radio___3e3eM:hover .ant-radio-inner___1sQUj {
  border-color: black;
}
.ant-radio-checked___2UE-8 .ant-radio-inner___1sQUj:focus {
  border-color: black;
}
.modal___200H- {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___200H- .close___2YdJg {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___200H- .header___1h4qW {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___200H- .header___1h4qW p {
  margin: 0;
}
.modal___200H- .content___1a5xv {
  text-align: center;
  font-size: 14px;
}
.modal___200H- .content___1a5xv p {
  margin: 0;
}
.modal___200H- .actions___CVKOW {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___200H- .actions___CVKOW .button___asosg {
  margin-top: 10px;
}
.modal___200H- .actions___CVKOW:hover {
  color: #33c3c7;
}
.fc___RXHHi .fc-timegrid-slot-minor___1OsRx {
  border-top-style: none;
}
.fc___RXHHi .fc-timegrid-slot___Ps1CW {
  height: 1.5em !important;
}
.messageError___T_7zb .ant-message-custom-content___1XLSw {
  display: flex;
  text-align: start;
}
.messageError___T_7zb .ant-message-custom-content___1XLSw span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1zmdP {
  background-color: #e6e4e4;
}
.notifyMessage___28fIS .ant-message-notice-content___3st2p {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___28fIS .ant-message-notice-content___3st2p .anticon___1Gij9 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___28fIS .ant-message-notice-content___3st2p span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___36Pt7 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___36Pt7 .ant-notification-notice-message___1Q3bG,
.notifyCustomize___36Pt7 .ant-notification-notice-close___rrn2f,
.notifyCustomize___36Pt7 .ant-notification-notice-icon___23SUn {
  color: white;
}
.notifyCustomize___36Pt7 .ant-notification-notice-message___1Q3bG {
  margin-left: 35px;
}
.notifyCustomize___36Pt7 p {
  margin-bottom: 0;
}
.notifyCustomize___36Pt7 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___t4R-C {
  padding: 16px 14px;
}
.notifyAlertDuplicate___t4R-C .ant-notification-notice-message___1Q3bG {
  margin-left: 35px;
}
.notifyAlertDuplicate___t4R-C p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___t4R-C p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___32yOs {
  width: 434px !important;
}
.handleSubmitVote___32yOs .ant-btn-primary___IhiBn {
  background: #59c3c7;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 .ant-modal-confirm-body___3bnf5 {
  display: flex !important;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 .ant-modal-confirm-body___3bnf5 .anticon___1Gij9 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 .ant-modal-confirm-btns___1v6FZ button {
  font-weight: bold;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 .ant-modal-confirm-btns___1v6FZ button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 .ant-modal-confirm-btns___1v6FZ button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___32yOs .ant-modal-body___2byt1 .ant-modal-confirm-btns___1v6FZ .ant-btn-primary___IhiBn {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3Mshv {
  margin-bottom: 0;
}
.mt-0___20NSb {
  margin-top: 0;
}
.tooltipFormat___1A6wz p {
  margin-bottom: 0;
}
.bgWhite___AHcOC {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2dVVf {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2dVVf:hover,
.bgPrimaryBlue___2dVVf:focus,
.bgPrimaryBlue___2dVVf:active {
  background: #4f7ac7;
}
.bgDarkBlue___PwUFD {
  background-color: #023768 !important;
}
.bgDarkBlue___PwUFD:hover,
.bgDarkBlue___PwUFD:focus,
.bgDarkBlue___PwUFD:active {
  background: #194d7d;
}
.bgGreyBlue___1tF6f {
  background-color: #ebf0f9;
}
.bgLightBlue___m25dP {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3z-k9 {
  background-color: #e4453a;
}
.bgLightRed___3z-k9:hover,
.bgLightRed___3z-k9:focus,
.bgLightRed___3z-k9:active {
  background: #e17871;
}
.bgLightYellow___4-stM {
  background-color: #ffd603;
}
.bgDarkGray___1XdFM {
  background-color: #333333;
}
.bgMediumGray___29geD {
  background-color: #a2a2a2;
}
.bgLightGray___3j6Lt {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___16Zu- {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___f1uuC {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2-qXk {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2UXzB {
  color: #3368c7 !important;
}
.textDarkBlue___39M_q {
  color: #023768 !important;
}
.textLightBlue___1iLus {
  color: #b2cbf7;
}
.textDarkGray___37nmX {
  color: #333333 !important;
}
.textMediumGray___2c-Yf {
  color: #a2a2a2 !important;
}
.textLightGray___3-CwC {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2t8n1 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2LiBV {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2b3OO {
  border-radius: 5px !important;
}
.rounded-xs___2d0Db {
  border-radius: 10px !important;
}
.rounded-sm___22ZGv {
  border-radius: 15px !important;
}
.rounded-md___1D8qT {
  border-radius: 20px !important;
}
.rounded-lg___37nrI {
  border-radius: 25px !important;
}
.rounded-full___3MOoN {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___Oanca {
  margin: 0;
}
.mt-2___2lCgb {
  margin-top: 0.5rem;
}
.my-2___3GsPJ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1-FNx {
  padding: 0;
}
.px-1___17-jW {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2K9wB {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3CEED {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___28_0G {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2XPqF {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___cqpVZ {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3Ujoo {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___CnHhi {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3SJ6o {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1IMFJ {
  padding-bottom: 20px;
}
.h-full___3hRjj {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2o4rM {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold____KUoL {
  font-weight: bold !important;
}
.fontWeight400___svhBe {
  font-weight: 400 !important;
}
.fontWeight500___bzl3w {
  font-weight: 500 !important;
}
.fontWeight600___OecGT {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___LvniG {
  font-size: 12px !important;
}
.fontSize14___23H9L {
  font-size: 14px !important;
}
.fontSize16___34RdI {
  font-size: 16px !important;
}
.fontSize18___oQQ0Z {
  font-size: 18px !important;
}
.fontSize20___2G09K {
  font-size: 20px !important;
}
.fontSize24___X-S-N {
  font-size: 24px !important;
}
.eventName___3EFvf {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___TcHzT {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___5lDrS > tr > th,
.ant-table-tbody___1PhGq > tr.ant-table-row___15vtL > td,
.ant-table-summary___WXuVz tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___5lDrS > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3vl-k .ant-table-thead___5lDrS > tr > th,
.table-portrait___3vl-k .ant-table-tbody___1PhGq > tr.ant-table-row___15vtL > td,
.table-portrait___3vl-k .ant-table-summary___WXuVz tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3vl-k .bgLightGray___3j6Lt td {
  background: #ebebeb;
}
.table-portrait___3vl-k .bgLightBlue___m25dP td {
  background: #b2cbf7;
}
.eventContent___3TxeC .timeText___11eM8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1PhGq > tr.bgLightGray___3j6Lt:hover > td,
.ant-table-tbody___1PhGq > tr > td.ant-table-cell-row-hover___1nmpV {
  background: #ebebeb;
}
.ant-table-tbody___1PhGq > tr.bgLightBlue___m25dP:hover > td,
.ant-table-tbody___1PhGq > tr > td.ant-table-cell-row-hover___1nmpV {
  background: #b2cbf7;
}
.ant-table-tbody___1PhGq > tr.bgWhite___AHcOC:hover > td,
.ant-table-tbody___1PhGq > tr > td.ant-table-cell-row-hover___1nmpV {
  background: #ffffff;
}
.navBarContainer___13Qh7 {
  display: block;
  margin-top: 1rem;
  padding-left: 3rem;
}
.navBarContainer___13Qh7 .navBarTitle___1EWKw {
  font-size: large;
  font-weight: bold;
  width: 15rem;
  padding-left: 1rem;
}
.navBarContainer___13Qh7 .navBarMenu___3ziNJ {
  font-size: large;
  width: 15rem;
  margin-top: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
}
.navBarContainer___13Qh7 .navBarMenu___3ziNJ a {
  color: black;
}
.navBarContainer___13Qh7 .navBarMenu___3ziNJ:hover {
  font-size: large;
  width: 15rem;
  margin-top: 1rem;
  padding: 1rem;
  cursor: pointer;
  background-color: #33c3c7;
}
.navBarContainer___13Qh7 .navBarMenu___3ziNJ:hover a {
  color: white;
}
.navBarContainer___13Qh7 .active___2g9-K {
  font-size: large;
  width: 15rem;
  margin-top: 1rem;
  padding: 1rem;
  cursor: pointer;
  background-color: #33c3c7;
}
.navBarContainer___13Qh7 .active___2g9-K a {
  color: white;
}
