@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2ixXG {
  display: flex;
  justify-content: center;
}
button.btn___3-TL4 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3-TL4 {
    padding: 10px;
  }
}
button.btn___3-TL4:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3-TL4:focus {
  outline: none;
}
.btnWhite___5CjhE.btn___3-TL4 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___5CjhE.btn___3-TL4:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1TzjK.btn___3-TL4,
.btnGreen___1TzjK.btn___3-TL4:hover,
.btnGreen___1TzjK.btn___3-TL4:active,
.btnGreen___1TzjK.btn___3-TL4:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2Qi-u {
  color: #fe0000;
}
.errorMessage___AtKXf {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___acpYm {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1o3gC {
  display: none !important;
}
.m-auto___22Aa- {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___EswbN {
  max-width: 120px !important;
}
.fc___1mzwC .fc-timegrid-col___3s99E.fc-day-today___19TZM {
  background-color: #ffffff;
}
.fc___1mzwC .fc-timegrid-slot___yRt_u {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1mzwC .fc-timegrid-slot___yRt_u {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___39r_j .fc-direction-ltr___1FHE2 .fc-timegrid-slot-label-frame___3Zkal {
    font-size: 10px;
  }
  .bookingCalendar___39r_j .fc___1mzwC .fc-timegrid-slot-label-cushion___gQHpM {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___39r_j .fc___1mzwC .fc-scrollgrid-section-header___2R0NF .fc-scroller___uTpkj {
    overflow: unset !important;
  }
}
.ant-message-notice___3kjcs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___22kT0 {
  width: 20px;
  height: 20px;
}
.bgTransparent___3V6Nz {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3v2Zp {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3nnvS {
  position: relative;
}
.inputCustomValue___3nnvS .ant-input___1Y8CT {
  padding-right: 50px;
}
.inputCustomValueText___3Egen {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1L38B .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1L38B .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___rUhr5 .ant-radio-inner___3SALX {
  border-color: black !important ;
}
.ant-radio-checked___rUhr5 .ant-radio-inner___3SALX:after {
  background-color: black;
}
.ant-radio___TChGg:hover .ant-radio-inner___3SALX {
  border-color: black;
}
.ant-radio-checked___rUhr5 .ant-radio-inner___3SALX:focus {
  border-color: black;
}
.modal___yTVj- {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___yTVj- .close___grmem {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___yTVj- .header___3ocpr {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___yTVj- .header___3ocpr p {
  margin: 0;
}
.modal___yTVj- .content___3RDfR {
  text-align: center;
  font-size: 14px;
}
.modal___yTVj- .content___3RDfR p {
  margin: 0;
}
.modal___yTVj- .actions___30NTq {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___yTVj- .actions___30NTq .button___1TCa8 {
  margin-top: 10px;
}
.modal___yTVj- .actions___30NTq:hover {
  color: #33c3c7;
}
.fc___1mzwC .fc-timegrid-slot-minor___3K93h {
  border-top-style: none;
}
.fc___1mzwC .fc-timegrid-slot___yRt_u {
  height: 1.5em !important;
}
.messageError___2u0CH .ant-message-custom-content___2PUmq {
  display: flex;
  text-align: start;
}
.messageError___2u0CH .ant-message-custom-content___2PUmq span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___Qb6qL {
  background-color: #e6e4e4;
}
.notifyMessage___kr0ai .ant-message-notice-content___34xOb {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___kr0ai .ant-message-notice-content___34xOb .anticon___2eeUD {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___kr0ai .ant-message-notice-content___34xOb span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___W9ivS {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___W9ivS .ant-notification-notice-message___2NbNq,
.notifyCustomize___W9ivS .ant-notification-notice-close___be9Y9,
.notifyCustomize___W9ivS .ant-notification-notice-icon___hUFZv {
  color: white;
}
.notifyCustomize___W9ivS .ant-notification-notice-message___2NbNq {
  margin-left: 35px;
}
.notifyCustomize___W9ivS p {
  margin-bottom: 0;
}
.notifyCustomize___W9ivS p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2sNM_ {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2sNM_ .ant-notification-notice-message___2NbNq {
  margin-left: 35px;
}
.notifyAlertDuplicate___2sNM_ p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2sNM_ p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3T2DD {
  width: 434px !important;
}
.handleSubmitVote___3T2DD .ant-btn-primary___QCAvC {
  background: #59c3c7;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj .ant-modal-confirm-body___17zGU {
  display: flex !important;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj .ant-modal-confirm-body___17zGU .anticon___2eeUD {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj .ant-modal-confirm-btns___3XZzN button {
  font-weight: bold;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj .ant-modal-confirm-btns___3XZzN button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj .ant-modal-confirm-btns___3XZzN button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3T2DD .ant-modal-body___1Xiuj .ant-modal-confirm-btns___3XZzN .ant-btn-primary___QCAvC {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3ZAcI {
  margin-bottom: 0;
}
.mt-0___38fBZ {
  margin-top: 0;
}
.tooltipFormat___3KZxO p {
  margin-bottom: 0;
}
.bgWhite___3PaV- {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1BkB8 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1BkB8:hover,
.bgPrimaryBlue___1BkB8:focus,
.bgPrimaryBlue___1BkB8:active {
  background: #4f7ac7;
}
.bgDarkBlue___3VMqN {
  background-color: #023768 !important;
}
.bgDarkBlue___3VMqN:hover,
.bgDarkBlue___3VMqN:focus,
.bgDarkBlue___3VMqN:active {
  background: #194d7d;
}
.bgGreyBlue___14wGM {
  background-color: #ebf0f9;
}
.bgLightBlue___p44E_ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___13x8W {
  background-color: #e4453a;
}
.bgLightRed___13x8W:hover,
.bgLightRed___13x8W:focus,
.bgLightRed___13x8W:active {
  background: #e17871;
}
.bgLightYellow___qitmF {
  background-color: #ffd603;
}
.bgDarkGray___3bejZ {
  background-color: #333333;
}
.bgMediumGray___35W81 {
  background-color: #a2a2a2;
}
.bgLightGray___3yO6e {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___18wu_ {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___38TGE {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___mGHJk {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___bJ7a_ {
  color: #3368c7 !important;
}
.textDarkBlue___yU3Ub {
  color: #023768 !important;
}
.textLightBlue___hi3rA {
  color: #b2cbf7;
}
.textDarkGray___25m-F {
  color: #333333 !important;
}
.textMediumGray___apT8X {
  color: #a2a2a2 !important;
}
.textLightGray___txjut {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3I8h7 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2KMa8 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2dng7 {
  border-radius: 5px !important;
}
.rounded-xs___2oL52 {
  border-radius: 10px !important;
}
.rounded-sm___3s94L {
  border-radius: 15px !important;
}
.rounded-md___17i1E {
  border-radius: 20px !important;
}
.rounded-lg___6lr0c {
  border-radius: 25px !important;
}
.rounded-full___3_D4J {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2FvfN {
  margin: 0;
}
.mt-2___38vxc {
  margin-top: 0.5rem;
}
.my-2___3RYVJ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2JRmO {
  padding: 0;
}
.px-1___bZZQl {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___cGqOs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3fZb1 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1aj7C {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___5UDov {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___22uHW {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1CfaF {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___PZ3SM {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___YVuR5 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1DAcn {
  padding-bottom: 20px;
}
.h-full___1Ny8l {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___UbiKg {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___C5ZQF {
  font-weight: bold !important;
}
.fontWeight400___12AY9 {
  font-weight: 400 !important;
}
.fontWeight500___1bFOH {
  font-weight: 500 !important;
}
.fontWeight600___1YWjn {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1lZWB {
  font-size: 12px !important;
}
.fontSize14___1F6MY {
  font-size: 14px !important;
}
.fontSize16___2cXV8 {
  font-size: 16px !important;
}
.fontSize18___3H7cL {
  font-size: 18px !important;
}
.fontSize20___2eoeD {
  font-size: 20px !important;
}
.fontSize24___3Yk2n {
  font-size: 24px !important;
}
.eventName___2IbNy {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1BgeQ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1SCbe > tr > th,
.ant-table-tbody___3IOYq > tr.ant-table-row___2u7j5 > td,
.ant-table-summary___Me4HM tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1SCbe > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3JGYD .ant-table-thead___1SCbe > tr > th,
.table-portrait___3JGYD .ant-table-tbody___3IOYq > tr.ant-table-row___2u7j5 > td,
.table-portrait___3JGYD .ant-table-summary___Me4HM tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3JGYD .bgLightGray___3yO6e td {
  background: #ebebeb;
}
.table-portrait___3JGYD .bgLightBlue___p44E_ td {
  background: #b2cbf7;
}
.eventContent___zWqYr .timeText___15bty {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3IOYq > tr.bgLightGray___3yO6e:hover > td,
.ant-table-tbody___3IOYq > tr > td.ant-table-cell-row-hover___16Wkr {
  background: #ebebeb;
}
.ant-table-tbody___3IOYq > tr.bgLightBlue___p44E_:hover > td,
.ant-table-tbody___3IOYq > tr > td.ant-table-cell-row-hover___16Wkr {
  background: #b2cbf7;
}
.ant-table-tbody___3IOYq > tr.bgWhite___3PaV-:hover > td,
.ant-table-tbody___3IOYq > tr > td.ant-table-cell-row-hover___16Wkr {
  background: #ffffff;
}
.docDetailsContainer___1zamF {
  margin-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.docDetailsContainer___1zamF .docDetailTitle___C8rwq {
  font-size: xx-large;
  font-weight: bold;
}
.docDetailsContainer___1zamF img {
  max-width: 100%;
}
