.confirmBooking___LUCG0 {
  margin-top: 1.6rem;
}
.confirmBooking___LUCG0 .title___2DTTm {
  line-height: 21px;
  letter-spacing: 1px;
  color: #3c3c3c;
  position: relative;
  padding-left: 12px;
  font-family: 'NotoSansJP_Bold';
  font-size: 16px;
  font-weight: bold;
}
.confirmBooking___LUCG0 .title___2DTTm :before {
  content: '';
  width: 6px;
  height: 23px;
  background-color: #3c3c3c;
  position: absolute;
  top: 0;
  left: 0;
}
.confirmBooking___LUCG0 .title___2DTTm :after {
  content: '';
  width: 3px;
  height: 23px;
  background-color: #3c3c3c;
  position: absolute;
  top: 0;
  left: 9px;
}
.confirmBooking___LUCG0 .title___2DTTm span {
  margin-left: 8px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX {
  position: relative;
  padding: 6px 2px;
  border: 5px solid transparent;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX:last-child {
  margin-bottom: 20px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX:first-child {
  margin-top: 15px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .actionOverlay___t9PcV {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .actionOverlay___t9PcV > div {
  cursor: pointer;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .listOption___2KwbU {
  margin-bottom: 10px;
  color: #3c3c3c;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .subtitle___3ZzDE {
  padding-left: 12px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .subtitle___3ZzDE textarea {
  outline: none;
  border: none;
  width: 100%;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .checkboxLabel___3jqhQ {
  margin-left: 8px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .checkboxLink___1NtFX {
  margin-left: 8px;
  text-decoration: underline;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .overlay___3Rtvn {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: none;
  cursor: pointer;
  color: #fff;
  background-color: #666666;
  text-align: center;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .overlay___3Rtvn .overlayMessage___2Be7F {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX:hover .overlay___3Rtvn {
  display: block;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .inputTitle___3CxDv {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .inputTitle___3CxDv div:first-child {
  margin-right: 0.5rem;
  width: 6px;
  height: 18px;
  background-color: #3c3c3c;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .inputTitle___3CxDv input {
  border: transparent;
  outline: none;
  font-weight: bold;
  color: #3c3c3c;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .widthTest___1zpN6 {
  display: inline-block;
  position: absolute;
  z-index: -9999;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .inputTitle___3CxDv > div:nth-child(2) {
  line-height: 20px;
  letter-spacing: 1px;
  color: #3c3c3c;
  margin-right: 16px;
  outline: none;
  border: transparent;
  font-family: 'NotoSansJP_Bold';
  font-weight: bolder;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .inputTitleRequired___kYyeh {
  color: #fe0000;
  font-weight: bold;
  font-family: 'NotoSansJP_Regular';
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .notRequired___2l_0I {
  color: #a3a3a3;
  font-family: 'NotoSansJP_Regular';
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .input___2fqSK {
  height: 50px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px;
  border: 2px solid #3c3c3c !important;
  border-radius: 4px;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .input___2fqSK :hover {
  border-color: #3c3c3c;
}
.confirmBooking___LUCG0 .inputComponent___1ZrzX .inputEdit___3EMMN {
  color: #a7a4a4;
}
.confirmBooking___LUCG0 .action___3Um_6 {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  margin: 0 5px;
}
.confirmBooking___LUCG0 .action___3Um_6 button {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: 16px;
  padding: 8px 0;
}
.confirmBooking___LUCG0 .action___3Um_6 button span {
  font-weight: bold;
}
.confirmBooking___LUCG0 .action___3Um_6 .primaryBtn___2j24p {
  font-size: 22px;
  height: 36px;
  background: #1a33f2;
  color: #fff;
  margin-bottom: 24px;
}
.confirmBooking___LUCG0 .action___3Um_6 .primaryBtn___2j24p :hover {
  background: #4a6bc0;
}
.confirmBooking___LUCG0 .action___3Um_6 .secondaryBtn___2Tb4D {
  font-size: 18px;
  height: 36px;
  border: none;
  color: #3c3c3c;
  background-color: #fff;
  box-shadow: #00000017 0 2px 8px 0;
}
@media screen and (max-width: 425px) {
  .confirmBooking___LUCG0 {
    width: unset;
    padding: 0 1rem 1rem 1rem;
  }
  .confirmBooking___LUCG0 .inputComponent___1ZrzX {
    padding: 10px 0 0;
  }
  .confirmBooking___LUCG0 .inputComponent___1ZrzX .input___2fqSK {
    height: 40px;
  }
  .confirmBooking___LUCG0 .inputComponent___1ZrzX .inputTitle___3CxDv div:first-child {
    height: 19px;
  }
  .confirmBooking___LUCG0 .inputComponent___1ZrzX .subtitle___3ZzDE {
    font-size: 18px;
  }
  .confirmBooking___LUCG0 .action___3Um_6 {
    margin: 0 6px;
  }
}
.inputComponent___1ZrzX .checkboxContainer___2Ri4u {
  padding-left: 12px;
}
.inputComponent___1ZrzX .checkboxContainer___2Ri4u .checkbox___3LWuY {
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid #3c3c3c;
  border-radius: 0;
  margin-right: 9px;
  margin-bottom: 10px;
}
.inputComponent___1ZrzX .checkboxContainer___2Ri4u input[type='text'] {
  border: transparent;
  outline: none;
  width: 90%;
}
.inputComponent___1ZrzX .checkboxContainer___2Ri4u input[type='checkbox'] {
  vertical-align: sub;
  transform: scale(1.2);
  margin-right: 20px;
}
@media screen and (max-width: 425px) {
  .inputComponent___1ZrzX {
    font-size: 18px;
  }
  .inputComponent___1ZrzX .checkboxContainer___2Ri4u {
    margin-bottom: 10px;
  }
  .inputComponent___1ZrzX .checkboxContainer___2Ri4u .checkbox___3LWuY {
    width: 25px;
    height: 25px;
  }
  .inputComponent___1ZrzX .checkboxContainer___2Ri4u input[type='checkbox'] {
    transform: scale(1);
  }
}
.textareaSetting___1McyL {
  margin-top: 18px;
}
.textareaSetting___1McyL .textAreaCptNav___3OxIK {
  color: #2f3840;
  height: 130px;
  border: 1px solid #3c3c3c !important;
  border-radius: 10px;
}
.textareaSetting___1McyL .textAreaCptNav___3OxIK:hover {
  border-color: #3c3c3c !important;
}
.textareaSetting___1McyL .textAreaCptNav___3OxIK::-webkit-input-placeholder {
  color: #2f3840;
}
.textareaSetting___1McyL .textAreaCptNav___3OxIK::-ms-input-placeholder {
  color: #2f3840;
}
.textareaSetting___1McyL .textAreaCptNav___3OxIK::placeholder {
  color: #2f3840;
}
.textareaSetting___1McyL input {
  border: transparent;
  outline: none;
}
.textareaSetting___1McyL div {
  outline: none;
  border: transparent;
}
.errorMessage___TFyJY {
  color: #ff0000;
  font-size: 14px;
  line-height: 19px;
  margin-top: 8px;
}
.pd15___2tIf7 {
  margin-top: unset;
  width: 50%;
}
@media screen and (max-width: 425px) {
  .pd15___2tIf7 {
    width: 100%;
  }
}
@media screen and (min-width: 425px) {
  .pd15___2tIf7 {
    padding-top: 1rem !important;
  }
}
