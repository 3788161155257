@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2kGVx {
  display: flex;
  justify-content: center;
}
button.btn___1E12w {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1E12w {
    padding: 10px;
  }
}
button.btn___1E12w:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1E12w:focus {
  outline: none;
}
.btnWhite___2-Tnp.btn___1E12w {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2-Tnp.btn___1E12w:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1iME1.btn___1E12w,
.btnGreen___1iME1.btn___1E12w:hover,
.btnGreen___1iME1.btn___1E12w:active,
.btnGreen___1iME1.btn___1E12w:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___TKEDP {
  color: #fe0000;
}
.errorMessage___1n9wO {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1hFqS {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___kUblb {
  display: none !important;
}
.m-auto___a-_wm {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1Eu3X {
  max-width: 120px !important;
}
.fc___2c2fa .fc-timegrid-col___1Rwa9.fc-day-today___1QP3b {
  background-color: #ffffff;
}
.fc___2c2fa .fc-timegrid-slot___1avee {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2c2fa .fc-timegrid-slot___1avee {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3JhBb .fc-direction-ltr___Vm7On .fc-timegrid-slot-label-frame___PzuEP {
    font-size: 10px;
  }
  .bookingCalendar___3JhBb .fc___2c2fa .fc-timegrid-slot-label-cushion___2iCo0 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3JhBb .fc___2c2fa .fc-scrollgrid-section-header___13-Gl .fc-scroller___3Ufhi {
    overflow: unset !important;
  }
}
.ant-message-notice___1NUlk {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3h2HN {
  width: 20px;
  height: 20px;
}
.bgTransparent___1Yzay {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___FUyZc {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___36z1o {
  position: relative;
}
.inputCustomValue___36z1o .ant-input___1d6bs {
  padding-right: 50px;
}
.inputCustomValueText___34jq0 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3Pan9 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3Pan9 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___24FqP .ant-radio-inner___2XgEV {
  border-color: black !important ;
}
.ant-radio-checked___24FqP .ant-radio-inner___2XgEV:after {
  background-color: black;
}
.ant-radio___1PzNd:hover .ant-radio-inner___2XgEV {
  border-color: black;
}
.ant-radio-checked___24FqP .ant-radio-inner___2XgEV:focus {
  border-color: black;
}
.modal___3TN2L {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3TN2L .close___kq8yX {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3TN2L .header___3yCTV {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3TN2L .header___3yCTV p {
  margin: 0;
}
.modal___3TN2L .content___CNhbe {
  text-align: center;
  font-size: 14px;
}
.modal___3TN2L .content___CNhbe p {
  margin: 0;
}
.modal___3TN2L .actions___1C-zH {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3TN2L .actions___1C-zH .button___qi3IP {
  margin-top: 10px;
}
.modal___3TN2L .actions___1C-zH:hover {
  color: #33c3c7;
}
.fc___2c2fa .fc-timegrid-slot-minor___3tR31 {
  border-top-style: none;
}
.fc___2c2fa .fc-timegrid-slot___1avee {
  height: 1.5em !important;
}
.messageError___1SHTt .ant-message-custom-content___2B8qC {
  display: flex;
  text-align: start;
}
.messageError___1SHTt .ant-message-custom-content___2B8qC span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1Ua7K {
  background-color: #e6e4e4;
}
.notifyMessage___3MRcq .ant-message-notice-content___3ER3X {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3MRcq .ant-message-notice-content___3ER3X .anticon___1yko4 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3MRcq .ant-message-notice-content___3ER3X span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2Fll1 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2Fll1 .ant-notification-notice-message___1Us7P,
.notifyCustomize___2Fll1 .ant-notification-notice-close___2S0ZQ,
.notifyCustomize___2Fll1 .ant-notification-notice-icon___12Q5n {
  color: white;
}
.notifyCustomize___2Fll1 .ant-notification-notice-message___1Us7P {
  margin-left: 35px;
}
.notifyCustomize___2Fll1 p {
  margin-bottom: 0;
}
.notifyCustomize___2Fll1 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3msY3 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3msY3 .ant-notification-notice-message___1Us7P {
  margin-left: 35px;
}
.notifyAlertDuplicate___3msY3 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3msY3 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___20x-q {
  width: 434px !important;
}
.handleSubmitVote___20x-q .ant-btn-primary___3WD5O {
  background: #59c3c7;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 .ant-modal-confirm-body___1qkwy {
  display: flex !important;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 .ant-modal-confirm-body___1qkwy .anticon___1yko4 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 .ant-modal-confirm-btns___1c6US button {
  font-weight: bold;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 .ant-modal-confirm-btns___1c6US button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 .ant-modal-confirm-btns___1c6US button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___20x-q .ant-modal-body___2xBE5 .ant-modal-confirm-btns___1c6US .ant-btn-primary___3WD5O {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1Rsrv {
  margin-bottom: 0;
}
.mt-0___tRDB0 {
  margin-top: 0;
}
.tooltipFormat___19TAe p {
  margin-bottom: 0;
}
.bgWhite___2nGos {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2m40h {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2m40h:hover,
.bgPrimaryBlue___2m40h:focus,
.bgPrimaryBlue___2m40h:active {
  background: #4f7ac7;
}
.bgDarkBlue___3_wx- {
  background-color: #023768 !important;
}
.bgDarkBlue___3_wx-:hover,
.bgDarkBlue___3_wx-:focus,
.bgDarkBlue___3_wx-:active {
  background: #194d7d;
}
.bgGreyBlue___3Cand {
  background-color: #ebf0f9;
}
.bgLightBlue___IG3OD {
  background-color: #b2cbf7 !important;
}
.bgLightRed___agJZ5 {
  background-color: #e4453a;
}
.bgLightRed___agJZ5:hover,
.bgLightRed___agJZ5:focus,
.bgLightRed___agJZ5:active {
  background: #e17871;
}
.bgLightYellow___3tlTI {
  background-color: #ffd603;
}
.bgDarkGray___3R3Mt {
  background-color: #333333;
}
.bgMediumGray___3jaWO {
  background-color: #a2a2a2;
}
.bgLightGray___1WODw {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2yaIF {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2CZrM {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3_-p6 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___LHvMC {
  color: #3368c7 !important;
}
.textDarkBlue___20RvO {
  color: #023768 !important;
}
.textLightBlue___1zcVZ {
  color: #b2cbf7;
}
.textDarkGray___3BYzB {
  color: #333333 !important;
}
.textMediumGray___2V1lk {
  color: #a2a2a2 !important;
}
.textLightGray___KxwNw {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___tvplx {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___WGFQL {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1W3wl {
  border-radius: 5px !important;
}
.rounded-xs___1PUY0 {
  border-radius: 10px !important;
}
.rounded-sm___2eRNI {
  border-radius: 15px !important;
}
.rounded-md___CKSi1 {
  border-radius: 20px !important;
}
.rounded-lg___EdYqe {
  border-radius: 25px !important;
}
.rounded-full___2qwAx {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1DQQa {
  margin: 0;
}
.mt-2___2cvCD {
  margin-top: 0.5rem;
}
.my-2___4gaBE {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2TlsD {
  padding: 0;
}
.px-1___3BMh3 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3zkF8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2P1uE {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3pxL3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1Sxt3 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1rItv {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1cfYe {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1rdLE {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___17wEf {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___23yS4 {
  padding-bottom: 20px;
}
.h-full___2MJ3A {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1y4-E {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1Yn_v {
  font-weight: bold !important;
}
.fontWeight400___1geqw {
  font-weight: 400 !important;
}
.fontWeight500___1zlTn {
  font-weight: 500 !important;
}
.fontWeight600___3R4vL {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3eVip {
  font-size: 12px !important;
}
.fontSize14___2bLHZ {
  font-size: 14px !important;
}
.fontSize16___3hYub {
  font-size: 16px !important;
}
.fontSize18___25_Sc {
  font-size: 18px !important;
}
.fontSize20___1Hq54 {
  font-size: 20px !important;
}
.fontSize24___On0kd {
  font-size: 24px !important;
}
.eventName___3zSfR {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2oLt9 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1uxFh > tr > th,
.ant-table-tbody___1rGXW > tr.ant-table-row___3xCjO > td,
.ant-table-summary___1eRTz tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1uxFh > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___oKyFU .ant-table-thead___1uxFh > tr > th,
.table-portrait___oKyFU .ant-table-tbody___1rGXW > tr.ant-table-row___3xCjO > td,
.table-portrait___oKyFU .ant-table-summary___1eRTz tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___oKyFU .bgLightGray___1WODw td {
  background: #ebebeb;
}
.table-portrait___oKyFU .bgLightBlue___IG3OD td {
  background: #b2cbf7;
}
.eventContent___1QPMa .timeText___3SDHr {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1rGXW > tr.bgLightGray___1WODw:hover > td,
.ant-table-tbody___1rGXW > tr > td.ant-table-cell-row-hover___2lCHN {
  background: #ebebeb;
}
.ant-table-tbody___1rGXW > tr.bgLightBlue___IG3OD:hover > td,
.ant-table-tbody___1rGXW > tr > td.ant-table-cell-row-hover___2lCHN {
  background: #b2cbf7;
}
.ant-table-tbody___1rGXW > tr.bgWhite___2nGos:hover > td,
.ant-table-tbody___1rGXW > tr > td.ant-table-cell-row-hover___2lCHN {
  background: #ffffff;
}
.createTeam___3hq-D {
  max-width: 1080px;
  margin: auto;
  padding: 1rem 15px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
}
.createTeam___3hq-D .numberAccountContent___26SzK {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .numberAccountContent___26SzK {
    margin-bottom: 20px;
  }
}
.createTeam___3hq-D .numberAccountTitle___Z1r8q {
  font-weight: 500;
  font-size: 26px;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .numberAccountTitle___Z1r8q {
    font-size: 20px;
  }
}
.createTeam___3hq-D .numberAccountBorder___3U1IO {
  display: inline-block;
  width: 9px;
  height: 37px;
  background: #3c3c3c;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .numberAccountBorder___3U1IO {
    height: 31px;
  }
}
.createTeam___3hq-D input {
  height: 60px;
  font-size: 22px;
  border: 1px solid #b7b7b7;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D input {
    height: 40px;
    font-size: 16px;
  }
}
.createTeam___3hq-D .formItem___3fskf {
  margin-bottom: 45px;
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .formItem___3fskf {
    margin-bottom: 30px;
  }
}
.createTeam___3hq-D .label___mwFxP {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  color: #3c3c3c;
  margin-bottom: 18px;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .label___mwFxP {
    font-size: 18px;
    margin-bottom: 9px;
  }
}
.createTeam___3hq-D .labelNoInput___PrkdW {
  display: inline-block;
  font-size: 24px;
  color: #3c3c3c;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .labelNoInput___PrkdW {
    font-size: 18px;
  }
}
.createTeam___3hq-D .labelBorder___34pi0 {
  display: inline-block;
  width: 17px;
  height: 7px;
  background: #33c3c7;
  margin-right: 10px;
  margin-bottom: 5px;
}
.createTeam___3hq-D .required___TKEDP {
  font-size: 18px;
  margin-left: 20px;
  color: #fe0000;
}
.createTeam___3hq-D .paymentBtnGroup___185Cg {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .paymentBtnGroup___185Cg {
    padding-top: 20px;
  }
}
.createTeam___3hq-D .paymentBtnGroup___185Cg button:not(:last-child) {
  margin-right: 30px;
}
.createTeam___3hq-D .btnEdit___3Jadm {
  color: #565656;
  border: none;
  font-size: 20px;
  outline: none;
  background: #ffffff;
  cursor: pointer;
  margin: 0 5px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .btnEdit___3Jadm {
    font-size: 16px;
  }
}
.createTeam___3hq-D .btnEdit___3Jadm img {
  margin-bottom: 3px;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .btnEdit___3Jadm img {
    width: 15px;
    height: 15px;
  }
}
.createTeam___3hq-D .btnEdit___3Jadm span {
  display: inline-block;
  margin-left: 5px;
}
.createTeam___3hq-D .btnActionEdit___QKdXN {
  border: none;
  background: unset;
  cursor: pointer;
  margin-bottom: 3px;
}
.createTeam___3hq-D .btnActionEdit___QKdXN img {
  width: 20px;
  height: 20px;
}
.createTeam___3hq-D .btnActionDelete___PEyRQ {
  border: none;
  background: unset;
  cursor: pointer;
  margin-bottom: 3px;
}
.createTeam___3hq-D .btnActionDelete___PEyRQ img {
  width: 20px;
  height: 20px;
}
.createTeam___3hq-D .tableAccount___53OzP .inputContainer___GoJsZ {
  position: relative;
  padding: 5px 0;
}
.createTeam___3hq-D .tableAccount___53OzP .inputContainer___GoJsZ input {
  border-color: transparent !important;
  font-size: 16px;
}
.createTeam___3hq-D .tableAccount___53OzP .inputContainer___GoJsZ .textError___2GaRq {
  font-size: 12px;
  color: red;
  position: absolute;
}
.createTeam___3hq-D .tableAccount___53OzP .ant-table-body {
  padding: 0;
  overflow-y: auto !important;
}
.createTeam___3hq-D .tableAccount___53OzP .ant-select-single .ant-select-selector {
  font-size: 16px;
  border: 0 !important;
  border-radius: 0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: none !important;
}
.createTeam___3hq-D .tableAccount___53OzP table {
  overflow: hidden;
  border: 0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP thead tr th {
  text-align: center;
  color: #33c3c7;
  font-size: 16px;
  background: none;
  padding-bottom: 8px;
  border: 0 !important;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .tableAccount___53OzP thead tr th {
    font-size: 16px;
    padding-bottom: 0;
  }
  .createTeam___3hq-D .tableAccount___53OzP thead tr th:first-child {
    border-top-left-radius: 20px !important;
    border-left: 3px solid #f0f0f0 !important;
    border-top: 3px solid #f0f0f0 !important;
    border-right: 3px solid #f0f0f0 !important;
  }
  .createTeam___3hq-D .tableAccount___53OzP thead tr th:nth-child(2) {
    border-top: 3px solid #f0f0f0 !important;
    border-right: 3px solid #f0f0f0 !important;
  }
  .createTeam___3hq-D .tableAccount___53OzP thead tr th:nth-child(3) {
    border-top-right-radius: 20px !important;
    border-top: 3px solid #f0f0f0 !important;
    border-right: 3px solid #f0f0f0 !important;
  }
}
.createTeam___3hq-D .tableAccount___53OzP tbody .wrapperTd {
  background: #c5eeec;
}
.createTeam___3hq-D .tableAccount___53OzP tbody .wrapperTdEdit {
  background: #63c0c4;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:hover td {
  background: #f7ffff;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:hover td:nth-child(4) {
  background: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:hover .wrapperTd {
  background: #e3f4f4;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:hover .wrapperTdEdit {
  background: #33c3c7;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr {
  text-align: center;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr td {
  padding: 0 !important;
  font-size: 18px;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr td:nth-child(4) {
  border: 0 !important;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .tableAccount___53OzP tbody tr td {
    font-size: 16px;
  }
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr td .ant-select-selector {
  background: unset !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr td .ant-select-selector input {
  text-align: center;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr td .ant-select-selector .ant-select-selection-placeholder {
  text-align: center;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr td .ant-select-clear {
  background: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(1) {
  justify-content: center;
  border-top: 3px solid #f0f0f0 !important;
  border-left: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
  border-top-left-radius: 20px !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(1):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(2) {
  border-top: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(2):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(3) {
  border-top: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
  border-top-right-radius: 20px !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(3):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
@media screen and (max-width: 767px) {
  .createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(1) {
    border-top-left-radius: 0 !important;
  }
  .createTeam___3hq-D .tableAccount___53OzP tbody tr:nth-child(2) td:nth-child(3) {
    border-top-right-radius: 0 !important;
  }
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) td:nth-child(1) {
  border-top: 3px solid #f0f0f0 !important;
  border-left: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) td:nth-child(1):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) td:nth-child(2) {
  border-top: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) td:nth-child(2):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) td:nth-child(3) {
  border-top: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) td:nth-child(3):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:last-child td:nth-child(1) {
  border: 3px solid #f0f0f0 !important;
  border-bottom-left-radius: 20px !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:last-child td:nth-child(1):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:last-child td:nth-child(2) {
  border-top: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
  border-bottom: 3px solid #f0f0f0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:last-child td:nth-child(2):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:last-child td:nth-child(3) {
  border-top: 3px solid #f0f0f0 !important;
  border-right: 3px solid #f0f0f0 !important;
  border-bottom: 3px solid #f0f0f0 !important;
  border-bottom-right-radius: 20px !important;
}
.createTeam___3hq-D .tableAccount___53OzP tbody tr:last-child td:nth-child(3):hover {
  border: 3px solid #2fb9bd !important;
  transition: unset;
}
.createTeam___3hq-D .tableAccount___53OzP .ant-table-container {
  border: 0 !important;
}
.createTeam___3hq-D .tableAccount___53OzP .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.createTeam___3hq-D .tableAccount___53OzP .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}
.createTeam___3hq-D .tableAccount___53OzP .inValidRow___3LgWh td,
.createTeam___3hq-D .tableAccount___53OzP .inValidRow___3LgWh td input.ant-input,
.createTeam___3hq-D .tableAccount___53OzP .inValidRow___3LgWh td .ant-select-selector {
  background-color: #ffccc7 !important;
}
.createTeam___3hq-D .tableAccount___53OzP .inValidRow___3LgWh:hover td {
  background: #ffccc7 !important;
}
.createTeam___3hq-D .addMemberTeam___1ds3o {
  display: inline-block;
  color: #33c3c7;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1rem;
  margin-left: 20px;
  background: #ffffff;
  border: none;
  outline: none;
}
.createTeam___3hq-D .ant-select-selection-search .ant-select-selection-search-input {
  font-size: 18px;
}
.createTeam___3hq-D .ant-select-disabled {
  background: unset;
}
.createTeam___3hq-D .ant-select-disabled .ant-select-selector {
  background: unset !important;
}
.createTeam___3hq-D .backGround___3Uwoc .ant-select-selector___1e5mt {
  background: unset !important;
}
.createTeam___3hq-D .bgEdit___2-TDE .ant-select-selector___1e5mt {
  background: unset !important;
}
.createTeam___3hq-D .removeMemberConfirm___192z- {
  border-radius: 20px !important;
}
.modalDelete___3Bl08 .modalTitle___2CL4m {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;
  text-align: center;
}
.modalDelete___3Bl08 .modalDescription___tYnw0 {
  text-align: center;
  font-size: 17px;
  margin-bottom: 50px;
}
.modalDelete___3Bl08 .btn.btnWhite:hover {
  border: 2px solid #909090;
}
