@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2W6qE {
  display: flex;
  justify-content: center;
}
button.btn___3kU4t {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3kU4t {
    padding: 10px;
  }
}
button.btn___3kU4t:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3kU4t:focus {
  outline: none;
}
.btnWhite___2vg-u.btn___3kU4t {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2vg-u.btn___3kU4t:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3r8mI.btn___3kU4t,
.btnGreen___3r8mI.btn___3kU4t:hover,
.btnGreen___3r8mI.btn___3kU4t:active,
.btnGreen___3r8mI.btn___3kU4t:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1GM43 {
  color: #fe0000;
}
.errorMessage___PCj1Z {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___SEg17 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___ycs90 {
  display: none !important;
}
.m-auto___2FW7H {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2_RNH {
  max-width: 120px !important;
}
.fc___1Ngrc .fc-timegrid-col___16YCy.fc-day-today___1fENH {
  background-color: #ffffff;
}
.fc___1Ngrc .fc-timegrid-slot___bKNdi {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1Ngrc .fc-timegrid-slot___bKNdi {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3kmxT .fc-direction-ltr___2uLMO .fc-timegrid-slot-label-frame___2F1Y7 {
    font-size: 10px;
  }
  .bookingCalendar___3kmxT .fc___1Ngrc .fc-timegrid-slot-label-cushion___zQ34C {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3kmxT .fc___1Ngrc .fc-scrollgrid-section-header___13cyn .fc-scroller___1BIMy {
    overflow: unset !important;
  }
}
.ant-message-notice___37nWZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___14OO7 {
  width: 20px;
  height: 20px;
}
.bgTransparent___LDKT6 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___iV41m {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2Wynt {
  position: relative;
}
.inputCustomValue___2Wynt .ant-input___Gt2uu {
  padding-right: 50px;
}
.inputCustomValueText___1y-zS {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___G-MLa .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___G-MLa .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___ond_Q .ant-radio-inner___2krMk {
  border-color: black !important ;
}
.ant-radio-checked___ond_Q .ant-radio-inner___2krMk:after {
  background-color: black;
}
.ant-radio___3-99a:hover .ant-radio-inner___2krMk {
  border-color: black;
}
.ant-radio-checked___ond_Q .ant-radio-inner___2krMk:focus {
  border-color: black;
}
.modal___3gxfR {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3gxfR .close___3sm6m {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3gxfR .header___13xWr {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3gxfR .header___13xWr p {
  margin: 0;
}
.modal___3gxfR .content___2dRVB {
  text-align: center;
  font-size: 14px;
}
.modal___3gxfR .content___2dRVB p {
  margin: 0;
}
.modal___3gxfR .actions___2dH9U {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3gxfR .actions___2dH9U .button___3ig08 {
  margin-top: 10px;
}
.modal___3gxfR .actions___2dH9U:hover {
  color: #33c3c7;
}
.fc___1Ngrc .fc-timegrid-slot-minor___2Dkjs {
  border-top-style: none;
}
.fc___1Ngrc .fc-timegrid-slot___bKNdi {
  height: 1.5em !important;
}
.messageError___22Oy4 .ant-message-custom-content___LCair {
  display: flex;
  text-align: start;
}
.messageError___22Oy4 .ant-message-custom-content___LCair span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___W5C2W {
  background-color: #e6e4e4;
}
.notifyMessage___UkRP4 .ant-message-notice-content___2zRmx {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___UkRP4 .ant-message-notice-content___2zRmx .anticon___3hh38 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___UkRP4 .ant-message-notice-content___2zRmx span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2jIk_ {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2jIk_ .ant-notification-notice-message___1IIU3,
.notifyCustomize___2jIk_ .ant-notification-notice-close___1MdvF,
.notifyCustomize___2jIk_ .ant-notification-notice-icon___1OnFB {
  color: white;
}
.notifyCustomize___2jIk_ .ant-notification-notice-message___1IIU3 {
  margin-left: 35px;
}
.notifyCustomize___2jIk_ p {
  margin-bottom: 0;
}
.notifyCustomize___2jIk_ p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2ecjW {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2ecjW .ant-notification-notice-message___1IIU3 {
  margin-left: 35px;
}
.notifyAlertDuplicate___2ecjW p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2ecjW p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1LwXg {
  width: 434px !important;
}
.handleSubmitVote___1LwXg .ant-btn-primary___14YUK {
  background: #59c3c7;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq .ant-modal-confirm-body___3iYab {
  display: flex !important;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq .ant-modal-confirm-body___3iYab .anticon___3hh38 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq .ant-modal-confirm-btns___2Ya0u button {
  font-weight: bold;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq .ant-modal-confirm-btns___2Ya0u button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq .ant-modal-confirm-btns___2Ya0u button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1LwXg .ant-modal-body___2cWdq .ant-modal-confirm-btns___2Ya0u .ant-btn-primary___14YUK {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3QIEl {
  margin-bottom: 0;
}
.mt-0___18v7X {
  margin-top: 0;
}
.tooltipFormat___YN1g8 p {
  margin-bottom: 0;
}
.bgWhite___3Najc {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___14Vpn {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___14Vpn:hover,
.bgPrimaryBlue___14Vpn:focus,
.bgPrimaryBlue___14Vpn:active {
  background: #4f7ac7;
}
.bgDarkBlue___2WRuO {
  background-color: #023768 !important;
}
.bgDarkBlue___2WRuO:hover,
.bgDarkBlue___2WRuO:focus,
.bgDarkBlue___2WRuO:active {
  background: #194d7d;
}
.bgGreyBlue___1kEad {
  background-color: #ebf0f9;
}
.bgLightBlue___1BSg_ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2IPVB {
  background-color: #e4453a;
}
.bgLightRed___2IPVB:hover,
.bgLightRed___2IPVB:focus,
.bgLightRed___2IPVB:active {
  background: #e17871;
}
.bgLightYellow___2PiFC {
  background-color: #ffd603;
}
.bgDarkGray___13dmh {
  background-color: #333333;
}
.bgMediumGray___1n_yP {
  background-color: #a2a2a2;
}
.bgLightGray___3D-18 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2p967 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1svK- {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2Lbrx {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___28i9_ {
  color: #3368c7 !important;
}
.textDarkBlue___5ULOF {
  color: #023768 !important;
}
.textLightBlue___37HQN {
  color: #b2cbf7;
}
.textDarkGray___1P45G {
  color: #333333 !important;
}
.textMediumGray___3gr0d {
  color: #a2a2a2 !important;
}
.textLightGray___3YE-y {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2NHg2 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___LYVK- {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1BSQp {
  border-radius: 5px !important;
}
.rounded-xs___1Pw9F {
  border-radius: 10px !important;
}
.rounded-sm___3_k-Z {
  border-radius: 15px !important;
}
.rounded-md___3_BfG {
  border-radius: 20px !important;
}
.rounded-lg___2n4yh {
  border-radius: 25px !important;
}
.rounded-full___uRtF8 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___bJ27Y {
  margin: 0;
}
.mt-2___2Yr4h {
  margin-top: 0.5rem;
}
.my-2___bxd7J {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1aYsw {
  padding: 0;
}
.px-1___XESLA {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2ICFA {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___IiNUZ {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3_PwL {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2T50l {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2iknn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1_Qkf {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___21Hi6 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2Xxq_ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3vrIt {
  padding-bottom: 20px;
}
.h-full___1fqlM {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___K8rPr {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3xtnT {
  font-weight: bold !important;
}
.fontWeight400___2jwBQ {
  font-weight: 400 !important;
}
.fontWeight500___1Hr4D {
  font-weight: 500 !important;
}
.fontWeight600___1ML0V {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2olxk {
  font-size: 12px !important;
}
.fontSize14___Cjf4A {
  font-size: 14px !important;
}
.fontSize16___1wFhT {
  font-size: 16px !important;
}
.fontSize18___1qS4g {
  font-size: 18px !important;
}
.fontSize20___2lQY7 {
  font-size: 20px !important;
}
.fontSize24___1X66H {
  font-size: 24px !important;
}
.eventName___2gCOn {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2KDyQ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2F7Oy > tr > th,
.ant-table-tbody___3reo9 > tr.ant-table-row___3IvuQ > td,
.ant-table-summary___3neox tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2F7Oy > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2SW0y .ant-table-thead___2F7Oy > tr > th,
.table-portrait___2SW0y .ant-table-tbody___3reo9 > tr.ant-table-row___3IvuQ > td,
.table-portrait___2SW0y .ant-table-summary___3neox tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2SW0y .bgLightGray___3D-18 td {
  background: #ebebeb;
}
.table-portrait___2SW0y .bgLightBlue___1BSg_ td {
  background: #b2cbf7;
}
.eventContent___1psyV .timeText___2zDKg {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3reo9 > tr.bgLightGray___3D-18:hover > td,
.ant-table-tbody___3reo9 > tr > td.ant-table-cell-row-hover___7DiCo {
  background: #ebebeb;
}
.ant-table-tbody___3reo9 > tr.bgLightBlue___1BSg_:hover > td,
.ant-table-tbody___3reo9 > tr > td.ant-table-cell-row-hover___7DiCo {
  background: #b2cbf7;
}
.ant-table-tbody___3reo9 > tr.bgWhite___3Najc:hover > td,
.ant-table-tbody___3reo9 > tr > td.ant-table-cell-row-hover___7DiCo {
  background: #ffffff;
}
.addMemberComplete___3EXQL {
  max-width: 1080px;
  margin: auto;
  padding: 0 0px 165px;
}
.addMemberComplete___3EXQL .description___3NORj {
  font-size: 24px;
  padding-left: 45px;
  line-height: 1.54;
}
.addMemberComplete___3EXQL .paymentTitle___33rBa {
  padding: 0 15px;
  margin: 65px 0px 55px;
  display: flex;
  align-items: center;
}
.addMemberComplete___3EXQL .paymentTitle___33rBa .paymentTitleText___1ERKQ {
  color: #3c3c3c;
  font-size: 45px;
  font-weight: 700;
  display: inline-block;
}
.addMemberComplete___3EXQL .titleIcon___Tiylk {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.addMemberComplete___3EXQL .titleIcon___Tiylk .bolderColIcon___2H-Ls {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 13px;
  margin-right: 6px;
}
.addMemberComplete___3EXQL .titleIcon___Tiylk .normalColIcon___1M0mj {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 5px;
}
.addMemberComplete___3EXQL .btnGroup___2W6qE {
  margin-top: 50px;
}
