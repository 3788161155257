@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1yxWQ {
  display: flex;
  justify-content: center;
}
button.btn___1qbOv {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1qbOv {
    padding: 10px;
  }
}
button.btn___1qbOv:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1qbOv:focus {
  outline: none;
}
.btnWhite___cnzjM.btn___1qbOv {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___cnzjM.btn___1qbOv:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___7DU16.btn___1qbOv,
.btnGreen___7DU16.btn___1qbOv:hover,
.btnGreen___7DU16.btn___1qbOv:active,
.btnGreen___7DU16.btn___1qbOv:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3IBwf {
  color: #fe0000;
}
.errorMessage___3Xq2j {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___25yRm {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3hYDB {
  display: none !important;
}
.m-auto___1R2fw {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___nVfnq {
  max-width: 120px !important;
}
.fc___2uWQz .fc-timegrid-col___1qkcc.fc-day-today___1XGHF {
  background-color: #ffffff;
}
.fc___2uWQz .fc-timegrid-slot___23r2f {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2uWQz .fc-timegrid-slot___23r2f {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___Jg8PO .fc-direction-ltr___2BljN .fc-timegrid-slot-label-frame___fusfd {
    font-size: 10px;
  }
  .bookingCalendar___Jg8PO .fc___2uWQz .fc-timegrid-slot-label-cushion___2FQgN {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___Jg8PO .fc___2uWQz .fc-scrollgrid-section-header___1vYcj .fc-scroller___3R76F {
    overflow: unset !important;
  }
}
.ant-message-notice___2wJpK {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___25A8b {
  width: 20px;
  height: 20px;
}
.bgTransparent___13-qT {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___38SLY {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3CMnV {
  position: relative;
}
.inputCustomValue___3CMnV .ant-input___375Ju {
  padding-right: 50px;
}
.inputCustomValueText___zBAlE {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3rGHX .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3rGHX .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___nEx9I .ant-radio-inner___cHYO2 {
  border-color: black !important ;
}
.ant-radio-checked___nEx9I .ant-radio-inner___cHYO2:after {
  background-color: black;
}
.ant-radio___3bZtu:hover .ant-radio-inner___cHYO2 {
  border-color: black;
}
.ant-radio-checked___nEx9I .ant-radio-inner___cHYO2:focus {
  border-color: black;
}
.modal___3jp-b {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3jp-b .close___13PKc {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3jp-b .header___1NjJH {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3jp-b .header___1NjJH p {
  margin: 0;
}
.modal___3jp-b .content___230HL {
  text-align: center;
  font-size: 14px;
}
.modal___3jp-b .content___230HL p {
  margin: 0;
}
.modal___3jp-b .actions___2hWNS {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3jp-b .actions___2hWNS .button___29bFz {
  margin-top: 10px;
}
.modal___3jp-b .actions___2hWNS:hover {
  color: #33c3c7;
}
.fc___2uWQz .fc-timegrid-slot-minor___1uuVF {
  border-top-style: none;
}
.fc___2uWQz .fc-timegrid-slot___23r2f {
  height: 1.5em !important;
}
.messageError___1kUl4 .ant-message-custom-content___l18PE {
  display: flex;
  text-align: start;
}
.messageError___1kUl4 .ant-message-custom-content___l18PE span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2iY5_ {
  background-color: #e6e4e4;
}
.notifyMessage___2jroh .ant-message-notice-content___2Tr5V {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2jroh .ant-message-notice-content___2Tr5V .anticon___2yBdF {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2jroh .ant-message-notice-content___2Tr5V span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1X7iR {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1X7iR .ant-notification-notice-message___1fhEs,
.notifyCustomize___1X7iR .ant-notification-notice-close___bixWC,
.notifyCustomize___1X7iR .ant-notification-notice-icon___bU7rg {
  color: white;
}
.notifyCustomize___1X7iR .ant-notification-notice-message___1fhEs {
  margin-left: 35px;
}
.notifyCustomize___1X7iR p {
  margin-bottom: 0;
}
.notifyCustomize___1X7iR p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3UVh1 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3UVh1 .ant-notification-notice-message___1fhEs {
  margin-left: 35px;
}
.notifyAlertDuplicate___3UVh1 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3UVh1 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___BFh12 {
  width: 434px !important;
}
.handleSubmitVote___BFh12 .ant-btn-primary___1TeXg {
  background: #59c3c7;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK {
  padding: 15px 30px 18px;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK .ant-modal-confirm-body___2W5NC {
  display: flex !important;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK .ant-modal-confirm-body___2W5NC .anticon___2yBdF {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK .ant-modal-confirm-btns___1h6Gi button {
  font-weight: bold;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK .ant-modal-confirm-btns___1h6Gi button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK .ant-modal-confirm-btns___1h6Gi button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___BFh12 .ant-modal-body___df5xK .ant-modal-confirm-btns___1h6Gi .ant-btn-primary___1TeXg {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___CJ3q0 {
  margin-bottom: 0;
}
.mt-0___2QvIv {
  margin-top: 0;
}
.tooltipFormat___2F308 p {
  margin-bottom: 0;
}
.bgWhite___1pxld {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2fCfr {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2fCfr:hover,
.bgPrimaryBlue___2fCfr:focus,
.bgPrimaryBlue___2fCfr:active {
  background: #4f7ac7;
}
.bgDarkBlue___1Mzdc {
  background-color: #023768 !important;
}
.bgDarkBlue___1Mzdc:hover,
.bgDarkBlue___1Mzdc:focus,
.bgDarkBlue___1Mzdc:active {
  background: #194d7d;
}
.bgGreyBlue___wbgly {
  background-color: #ebf0f9;
}
.bgLightBlue___2Nofr {
  background-color: #b2cbf7 !important;
}
.bgLightRed___25hGA {
  background-color: #e4453a;
}
.bgLightRed___25hGA:hover,
.bgLightRed___25hGA:focus,
.bgLightRed___25hGA:active {
  background: #e17871;
}
.bgLightYellow___3jl0B {
  background-color: #ffd603;
}
.bgDarkGray___3Mhw- {
  background-color: #333333;
}
.bgMediumGray___2mheE {
  background-color: #a2a2a2;
}
.bgLightGray___1bP8u {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1azib {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___c-3FT {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1lnAk {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___14rSx {
  color: #3368c7 !important;
}
.textDarkBlue___2C5I8 {
  color: #023768 !important;
}
.textLightBlue___1o57_ {
  color: #b2cbf7;
}
.textDarkGray___1d7ld {
  color: #333333 !important;
}
.textMediumGray___3Q_zC {
  color: #a2a2a2 !important;
}
.textLightGray___136yB {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___25xtl {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___DzODo {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___207U1 {
  border-radius: 5px !important;
}
.rounded-xs___2gcNR {
  border-radius: 10px !important;
}
.rounded-sm___iXiLI {
  border-radius: 15px !important;
}
.rounded-md___2Qtv9 {
  border-radius: 20px !important;
}
.rounded-lg___2sDfk {
  border-radius: 25px !important;
}
.rounded-full___3WPr4 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3meRS {
  margin: 0;
}
.mt-2___1IeUI {
  margin-top: 0.5rem;
}
.my-2___3hd6R {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1i-ZO {
  padding: 0;
}
.px-1___btdyK {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3cPmJ {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___kUQp4 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2SlSy {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2xxtR {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___vdjXX {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___13VrM {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2Poom {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3tkDh {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___o9YSW {
  padding-bottom: 20px;
}
.h-full___1J5IH {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1lSuo {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3JqG0 {
  font-weight: bold !important;
}
.fontWeight400___34Ecn {
  font-weight: 400 !important;
}
.fontWeight500___2DyI5 {
  font-weight: 500 !important;
}
.fontWeight600___3eSU8 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2Kwfw {
  font-size: 12px !important;
}
.fontSize14___21kch {
  font-size: 14px !important;
}
.fontSize16___2CVSr {
  font-size: 16px !important;
}
.fontSize18___1gb_W {
  font-size: 18px !important;
}
.fontSize20___3T55P {
  font-size: 20px !important;
}
.fontSize24___11ycM {
  font-size: 24px !important;
}
.eventName___3ei5M {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___8C1wU {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1DoKj > tr > th,
.ant-table-tbody___93lR- > tr.ant-table-row___1-kgH > td,
.ant-table-summary___2RPeU tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1DoKj > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3-jVV .ant-table-thead___1DoKj > tr > th,
.table-portrait___3-jVV .ant-table-tbody___93lR- > tr.ant-table-row___1-kgH > td,
.table-portrait___3-jVV .ant-table-summary___2RPeU tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3-jVV .bgLightGray___1bP8u td {
  background: #ebebeb;
}
.table-portrait___3-jVV .bgLightBlue___2Nofr td {
  background: #b2cbf7;
}
.eventContent___MpFlm .timeText___1TuK3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___93lR- > tr.bgLightGray___1bP8u:hover > td,
.ant-table-tbody___93lR- > tr > td.ant-table-cell-row-hover___1UV1G {
  background: #ebebeb;
}
.ant-table-tbody___93lR- > tr.bgLightBlue___2Nofr:hover > td,
.ant-table-tbody___93lR- > tr > td.ant-table-cell-row-hover___1UV1G {
  background: #b2cbf7;
}
.ant-table-tbody___93lR- > tr.bgWhite___1pxld:hover > td,
.ant-table-tbody___93lR- > tr > td.ant-table-cell-row-hover___1UV1G {
  background: #ffffff;
}
.container___1fZ9i .title___2Bmus {
  margin: 40px 0;
  padding: 0 50px;
  font-size: 30px;
}
.container___1fZ9i .ant-picker {
  width: 100%;
  border-radius: 8px;
  border: 2px solid #ececec !important;
}
.container___1fZ9i .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px;
}
.container___1fZ9i button {
  font-size: 16px;
  max-width: 120px;
}
