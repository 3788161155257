@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1uQcD {
  display: flex;
  justify-content: center;
}
button.btn___KXRlh {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___KXRlh {
    padding: 10px;
  }
}
button.btn___KXRlh:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___KXRlh:focus {
  outline: none;
}
.btnWhite___K2pwX.btn___KXRlh {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___K2pwX.btn___KXRlh:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1hYih.btn___KXRlh,
.btnGreen___1hYih.btn___KXRlh:hover,
.btnGreen___1hYih.btn___KXRlh:active,
.btnGreen___1hYih.btn___KXRlh:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___ZdLLs {
  color: #fe0000;
}
.errorMessage___3uO7X {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1ITSY {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2ivjj {
  display: none !important;
}
.m-auto___1wwwl {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1r2rF {
  max-width: 120px !important;
}
.fc___1AQrF .fc-timegrid-col___1_htF.fc-day-today___Fp6aH {
  background-color: #ffffff;
}
.fc___1AQrF .fc-timegrid-slot___H02PI {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1AQrF .fc-timegrid-slot___H02PI {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1pqYC .fc-direction-ltr___3oVN5 .fc-timegrid-slot-label-frame___5dIKZ {
    font-size: 10px;
  }
  .bookingCalendar___1pqYC .fc___1AQrF .fc-timegrid-slot-label-cushion___1rNCG {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1pqYC .fc___1AQrF .fc-scrollgrid-section-header___1FSC7 .fc-scroller___3kBP3 {
    overflow: unset !important;
  }
}
.ant-message-notice___CXjsG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2a6z5 {
  width: 20px;
  height: 20px;
}
.bgTransparent___2xGjF {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___2EBHM {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___15mBP {
  position: relative;
}
.inputCustomValue___15mBP .ant-input___2gTjR {
  padding-right: 50px;
}
.inputCustomValueText___1DSk7 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3zxJ5 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3zxJ5 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2VudN .ant-radio-inner___36sPg {
  border-color: black !important ;
}
.ant-radio-checked___2VudN .ant-radio-inner___36sPg:after {
  background-color: black;
}
.ant-radio___1HomX:hover .ant-radio-inner___36sPg {
  border-color: black;
}
.ant-radio-checked___2VudN .ant-radio-inner___36sPg:focus {
  border-color: black;
}
.modal___3sTmF {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3sTmF .close___2zHJq {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3sTmF .header___1mHXQ {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3sTmF .header___1mHXQ p {
  margin: 0;
}
.modal___3sTmF .content___YlyHZ {
  text-align: center;
  font-size: 14px;
}
.modal___3sTmF .content___YlyHZ p {
  margin: 0;
}
.modal___3sTmF .actions___2qaWN {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3sTmF .actions___2qaWN .button___2vNIq {
  margin-top: 10px;
}
.modal___3sTmF .actions___2qaWN:hover {
  color: #33c3c7;
}
.fc___1AQrF .fc-timegrid-slot-minor___2Lkdd {
  border-top-style: none;
}
.fc___1AQrF .fc-timegrid-slot___H02PI {
  height: 1.5em !important;
}
.messageError___363hU .ant-message-custom-content___2qgEs {
  display: flex;
  text-align: start;
}
.messageError___363hU .ant-message-custom-content___2qgEs span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___23P_a {
  background-color: #e6e4e4;
}
.notifyMessage___3LXvn .ant-message-notice-content___RzID9 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3LXvn .ant-message-notice-content___RzID9 .anticon___3JA_L {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3LXvn .ant-message-notice-content___RzID9 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3yKzK {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3yKzK .ant-notification-notice-message___CNVQY,
.notifyCustomize___3yKzK .ant-notification-notice-close___39i7b,
.notifyCustomize___3yKzK .ant-notification-notice-icon___2u84o {
  color: white;
}
.notifyCustomize___3yKzK .ant-notification-notice-message___CNVQY {
  margin-left: 35px;
}
.notifyCustomize___3yKzK p {
  margin-bottom: 0;
}
.notifyCustomize___3yKzK p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1cz-R {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1cz-R .ant-notification-notice-message___CNVQY {
  margin-left: 35px;
}
.notifyAlertDuplicate___1cz-R p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1cz-R p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2P--Y {
  width: 434px !important;
}
.handleSubmitVote___2P--Y .ant-btn-primary___lbEo7 {
  background: #59c3c7;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL .ant-modal-confirm-body___3mSH- {
  display: flex !important;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL .ant-modal-confirm-body___3mSH- .anticon___3JA_L {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL .ant-modal-confirm-btns___1hah_ button {
  font-weight: bold;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL .ant-modal-confirm-btns___1hah_ button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL .ant-modal-confirm-btns___1hah_ button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2P--Y .ant-modal-body___2KYAL .ant-modal-confirm-btns___1hah_ .ant-btn-primary___lbEo7 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___22Etq {
  margin-bottom: 0;
}
.mt-0___3QY0O {
  margin-top: 0;
}
.tooltipFormat___2GKjG p {
  margin-bottom: 0;
}
.bgWhite___TO6Pu {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___pwAoK {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___pwAoK:hover,
.bgPrimaryBlue___pwAoK:focus,
.bgPrimaryBlue___pwAoK:active {
  background: #4f7ac7;
}
.bgDarkBlue___OcHIP {
  background-color: #023768 !important;
}
.bgDarkBlue___OcHIP:hover,
.bgDarkBlue___OcHIP:focus,
.bgDarkBlue___OcHIP:active {
  background: #194d7d;
}
.bgGreyBlue___1EK1S {
  background-color: #ebf0f9;
}
.bgLightBlue___7sqqD {
  background-color: #b2cbf7 !important;
}
.bgLightRed___Nc-P7 {
  background-color: #e4453a;
}
.bgLightRed___Nc-P7:hover,
.bgLightRed___Nc-P7:focus,
.bgLightRed___Nc-P7:active {
  background: #e17871;
}
.bgLightYellow___i654j {
  background-color: #ffd603;
}
.bgDarkGray___Q4Zck {
  background-color: #333333;
}
.bgMediumGray___2_7FP {
  background-color: #a2a2a2;
}
.bgLightGray___PNRN- {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___Lf0Hf {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2Hzbm {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___16gYz {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___26Vri {
  color: #3368c7 !important;
}
.textDarkBlue___E4Q5O {
  color: #023768 !important;
}
.textLightBlue___6XwM_ {
  color: #b2cbf7;
}
.textDarkGray___26955 {
  color: #333333 !important;
}
.textMediumGray___1tcud {
  color: #a2a2a2 !important;
}
.textLightGray___1Xa-G {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3jqE9 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2b7YA {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___DHHh- {
  border-radius: 5px !important;
}
.rounded-xs___hnPPD {
  border-radius: 10px !important;
}
.rounded-sm___2Jf6G {
  border-radius: 15px !important;
}
.rounded-md___1LdTj {
  border-radius: 20px !important;
}
.rounded-lg___1iZ4S {
  border-radius: 25px !important;
}
.rounded-full___3QBRV {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1ttps {
  margin: 0;
}
.mt-2___3pVHY {
  margin-top: 0.5rem;
}
.my-2___3CViy {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2FcfC {
  padding: 0;
}
.px-1___3REN3 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2nsHk {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3jHqc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2j-sQ {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___fzy62 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3lmhw {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1PAcj {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3Z2-v {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___22x4X {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___-eEB_ {
  padding-bottom: 20px;
}
.h-full___3pNrw {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3s9uJ {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3BOF_ {
  font-weight: bold !important;
}
.fontWeight400___2yzVS {
  font-weight: 400 !important;
}
.fontWeight500___3Z6hd {
  font-weight: 500 !important;
}
.fontWeight600___58Rk9 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1RlZ4 {
  font-size: 12px !important;
}
.fontSize14___26C3i {
  font-size: 14px !important;
}
.fontSize16___DteMh {
  font-size: 16px !important;
}
.fontSize18___2ha9V {
  font-size: 18px !important;
}
.fontSize20___320cp {
  font-size: 20px !important;
}
.fontSize24___1trNV {
  font-size: 24px !important;
}
.eventName___1NpkU {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2p88D {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2RHWb > tr > th,
.ant-table-tbody___sMEoY > tr.ant-table-row____UP29 > td,
.ant-table-summary___2ut-U tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2RHWb > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3nv4H .ant-table-thead___2RHWb > tr > th,
.table-portrait___3nv4H .ant-table-tbody___sMEoY > tr.ant-table-row____UP29 > td,
.table-portrait___3nv4H .ant-table-summary___2ut-U tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3nv4H .bgLightGray___PNRN- td {
  background: #ebebeb;
}
.table-portrait___3nv4H .bgLightBlue___7sqqD td {
  background: #b2cbf7;
}
.eventContent___1zwKJ .timeText___3nib5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___sMEoY > tr.bgLightGray___PNRN-:hover > td,
.ant-table-tbody___sMEoY > tr > td.ant-table-cell-row-hover___3Jr-0 {
  background: #ebebeb;
}
.ant-table-tbody___sMEoY > tr.bgLightBlue___7sqqD:hover > td,
.ant-table-tbody___sMEoY > tr > td.ant-table-cell-row-hover___3Jr-0 {
  background: #b2cbf7;
}
.ant-table-tbody___sMEoY > tr.bgWhite___TO6Pu:hover > td,
.ant-table-tbody___sMEoY > tr > td.ant-table-cell-row-hover___3Jr-0 {
  background: #ffffff;
}
.dropdownMenu___1jEy7 {
  border-radius: 20px !important;
  padding: 0px !important;
  overflow: hidden;
  min-width: 160px;
  left: -20px;
  top: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
}
.dropdownMenu___1jEy7 li:last-child {
  border-top: 1px solid #a1a0a0;
}
.dropdownMenuSelectCalendar___leEnc {
  max-height: 500px;
  overflow: auto;
  border-radius: 4px !important;
  padding: 10px 0 !important;
  width: 280px;
  left: 65px;
  position: absolute;
  top: 7px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownMenuSelectCalendar___leEnc .unstyleItem___3rrji {
  padding: 0;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: transparent !important;
}
.dropdownMenuSelectCalendar___leEnc .unstyleItem___3rrji:hover {
  background-color: #f0f0f0;
}
.dropdownMenuSelectCalendar___leEnc .unstyleItem___3rrji .customInput___5eSYU {
  width: 100%;
  padding: 5px 10px;
}
.dropdownMenuSelectCalendar___leEnc .unstyleItem___3rrji .customInput___5eSYU .ant-select-selector {
  border: 3px solid #ebebeb !important;
}
.dropdownMenuSelectCalendar___leEnc .unstyleItem___3rrji .customInput___5eSYU .ant-select-selector input {
  padding: 4px 0;
}
@media screen and (max-width: 415px) {
  .dropdownMenuSelectCalendar___leEnc .unstyleItem___3rrji .customInput___5eSYU .ant-select-selector {
    width: 249px;
  }
}
.dropdownMenuSelectCalendar___leEnc .wrapperProfile___hbvJv {
  padding: 5px 5px 5px 1.5rem;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}
.dropdownMenuSelectCalendar___leEnc .wrapperProfile___hbvJv:hover {
  background-color: #f0f0f0;
}
.dropdownMenuSelectCalendar___leEnc .wrapperProfile___hbvJv .avatar___t4oLI .defaultAvatar___3L680 {
  width: 40px;
  height: 40px;
}
.dropdownMenuSelectCalendar___leEnc .wrapperProfileTeam___1J4A1 .avatar___t4oLI {
  border: none;
  margin-right: 22px;
}
.dropdownMenuSelectCalendar___leEnc .wrapperProfileTeam___1J4A1 .avatar___t4oLI img {
  display: none;
}
@media screen and (max-width: 767px) {
  .dropdownMenuSelectCalendar___leEnc {
    left: 30px;
  }
}
.dropdownMenuSelectCalendarLeft___337I9 {
  max-height: 500px;
  overflow: auto;
  border-radius: 4px !important;
  padding: 10px 0 !important;
  width: 280px;
  left: 0;
  top: 0;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownMenuSelectCalendarLeft___337I9 .unstyleItem___3rrji {
  padding: 0;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: transparent !important;
}
.dropdownMenuSelectCalendarLeft___337I9 .unstyleItem___3rrji:hover {
  background-color: #f0f0f0;
}
.dropdownMenuSelectCalendarLeft___337I9 .unstyleItem___3rrji .customInput___5eSYU {
  width: 100%;
  padding: 5px 10px;
}
.dropdownMenuSelectCalendarLeft___337I9 .unstyleItem___3rrji .customInput___5eSYU .ant-select-selector {
  border: 3px solid #ebebeb !important;
}
.dropdownMenuSelectCalendarLeft___337I9 .unstyleItem___3rrji .customInput___5eSYU .ant-select-selector input {
  padding: 4px 0;
}
@media screen and (max-width: 415px) {
  .dropdownMenuSelectCalendarLeft___337I9 .unstyleItem___3rrji .customInput___5eSYU .ant-select-selector {
    width: 249px;
  }
}
.dropdownMenuSelectCalendarLeft___337I9 .wrapperProfile___hbvJv {
  padding: 5px 5px 5px 1.5rem;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}
.dropdownMenuSelectCalendarLeft___337I9 .wrapperProfile___hbvJv:hover {
  background-color: #f0f0f0;
}
.dropdownMenuSelectCalendarLeft___337I9 .wrapperProfile___hbvJv .avatar___t4oLI .defaultAvatar___3L680 {
  width: 40px;
  height: 40px;
}
.dropdownMenuSelectCalendarLeft___337I9 .wrapperProfileTeam___1J4A1 .avatar___t4oLI {
  border: none;
  margin-right: 22px;
}
.dropdownMenuSelectCalendarLeft___337I9 .wrapperProfileTeam___1J4A1 .avatar___t4oLI img {
  display: none;
}
.dropdownMenuAccount___2xNvi {
  margin-top: 10px;
  border-radius: 3px !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownMenuAccount___2xNvi li:last-child {
  border-top: 1px solid #a1a0a0;
}
.dropdownSettingTemplate___2lm28 {
  margin-top: 10px;
  border-radius: 3px !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownSettingTemplate___2lm28 li img {
  margin-right: 0.8rem;
}
.dropdownSettingTemplate___2lm28 li span {
  color: #3c3c3c !important;
}
.wrapperUser___1ADGT {
  color: #565656;
  display: flex;
  justify-content: space-between;
}
.wrapperUser___1ADGT .linkAdd___1IfgV {
  color: #1ca9ad;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 16px;
}
.wrapperUser___1ADGT .linkAdd___1IfgV .iconGreen___Rooks {
  width: 6px !important;
  height: 50%;
  background: #33c3c7;
  margin: auto;
}
.wrapperUser___1ADGT .linkAdd___1IfgV .iconGrey___3TdIc {
  width: 6px !important;
  height: 50%;
  background: #33c3c7;
  margin: auto;
}
.wrapperUser___1ADGT .linkAdd___1IfgV p {
  font-size: 20px;
  padding-left: 0.5rem;
  margin-bottom: 0;
}
.wrapperUser___1ADGT .linkAdd___1IfgV:first-child {
  padding-left: 0.3rem;
  color: #33c3c7;
}
.wrapperUser___1ADGT .linkAdd___1IfgV:last-child {
  font-weight: 500;
}
.wrapperProfileMember___nYKBu {
  display: flex;
  align-items: center;
}
.wrapperProfileMember___nYKBu .avatar___t4oLI {
  width: 36px;
  height: 36px;
}
.wrapperProfileMember___nYKBu .avatar___t4oLI .defaultAvatar___3L680 {
  width: 36px;
  height: 36px;
}
.avatar___t4oLI {
  width: 40px;
  height: 40px;
  margin-right: 14px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #33c3c7;
  display: inline-table;
}
.avatar___t4oLI .defaultAvatar___3L680 {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.avatar___t4oLI .defaultAvatar___3L680 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .avatar___t4oLI {
    margin-right: 5px;
    margin-top: 0;
  }
}
.titleMain___-mgcZ {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1.8rem;
  color: #000000;
  font-weight: 500;
  font-size: 20px;
}
.nameAccount___2USKR {
  padding-top: 0;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logoutConfirm___3zxJ5 {
  border-radius: 20px !important;
}
.disabledLink___-p6bz {
  cursor: not-allowed;
}
.confirmDeleteTemplate___ZpWBR .ant-modal-confirm-btns button:first-child {
  background: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
.confirmDeleteTemplate___ZpWBR .ant-modal-confirm-btns button:last-child {
  background: white;
  border-color: #bbb6b6;
  color: #3c3c3c;
}
.greyHover___186zt {
  margin-top: 0 !important;
  margin-bottom: 0.4rem !important;
  background: transparent !important;
  padding-left: 1.5rem !important;
}
.greyHover___186zt .listUser___1al19 {
  display: flex;
  justify-items: center;
}
.greyHover___186zt .listUser___1al19 .iconUser___2ifSM {
  width: 40px;
  text-align: center;
  border-radius: 25px;
  background-color: #b6b6b6;
}
.greyHover___186zt .listUser___1al19 .iconUser___2ifSM img {
  margin-bottom: 5px;
}
.greyHover___186zt .listUser___1al19 a {
  font-size: 16px;
  padding-left: 0.5rem;
}
.greyHover___186zt span a {
  color: rgba(0, 0, 0, 0.85) !important;
}
.greyHover___186zt:hover {
  background-color: #f0f0f0 !important;
}
.greyHover___186zt:hover span a {
  color: rgba(0, 0, 0, 0.85) !important;
}
.notifySettingTemplate___fyAgF span a {
  color: white;
}
.notifySettingTemplate___fyAgF .ant-notification-notice-close {
  display: none;
}
.headerV2___3_w2T {
  width: 300px;
  height: 100%;
  position: fixed;
  z-index: 150;
  border-right: 1px solid #eff0f1;
  padding: 2rem 0;
  background: #f1fcfb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.headerV2___3_w2T .listMenu___uBihV {
  padding: 0 3rem;
}
.headerV2___3_w2T .listMenu___uBihV .logoImage___212SW {
  margin-bottom: 3rem;
}
.headerV2___3_w2T .listMenu___uBihV .logoImage___212SW img {
  width: 132px;
  height: 30px;
  cursor: pointer;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa {
  display: flex;
  flex-direction: column;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .headerMenuItemLast___14kto {
  margin-right: 0;
  display: flex;
  align-items: center;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .dropdownItemLeft___3OBFi {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .dropdownItemLeft___3OBFi span.titleMenu___1gp2H {
  font-size: 18px !important;
  font-weight: bold;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .dropdownItemLeft___3OBFi span:last-child {
  font-size: 15px;
  margin: auto 0;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .dropdownItemLeft___3OBFi:hover span {
  font-weight: bold;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .fontWeightNormal___oi2cl {
  font-weight: normal;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .buttonDeleteCalendarContainer___1sD5F {
  display: none;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .buttonDeleteCalendarContainer___1sD5F button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .buttonDeleteCalendarContainer___1sD5F button img {
  color: white;
  margin-right: 5px;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .buttonDeleteCalendarContainer___1sD5F button .buttonDeleteCalendar___1Wync {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .closeMenu___2Snam {
  display: none;
  margin-bottom: 1.5rem;
}
.headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .dropdownItemProfileMb___3iLRe {
  display: none;
}
.headerV2___3_w2T .profile___3Glzy {
  display: flex;
  justify-content: center;
}
.headerV2___3_w2T .profile___3Glzy .headerMenuItemAvt___U4shv {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  margin-top: 3px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #33c3c7;
  display: inline-table;
}
.headerV2___3_w2T .profile___3Glzy .headerMenuItemAvt___U4shv img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.headerV2___3_w2T .profile___3Glzy .headerMenuItemAvt___U4shv .defaultAvatar___3L680 {
  width: 100%;
  height: 26px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.headerV2___3_w2T .profile___3Glzy .headerMenuItemAvt___U4shv .defaultAvatar___3L680 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}
.headerV2___3_w2T .profile___3Glzy .dropdownItemProfile___1Fp00 {
  cursor: pointer;
  justify-content: center;
  display: flex;
  margin-bottom: 1.5rem;
}
.headerV2___3_w2T .profile___3Glzy .dropdownItemProfile___1Fp00 .nameAccount___2USKR {
  margin: auto 0;
  font-size: 18px;
}
.headerV2___3_w2T .profile___3Glzy .dropdownItemProfile___1Fp00 span:last-child {
  font-size: 15px;
  margin: auto 0 auto 0.5rem;
}
.headerV2___3_w2T .profile___3Glzy .dropdownItemProfile___1Fp00:hover span {
  font-weight: bold;
}
.headerV2___3_w2T .profile___3Glzy .ant-dropdown {
  min-width: unset !important;
}
.headerV2___3_w2T.showMenu___2nJ7m {
  left: 0;
}
@media screen and (max-width: 1140px) {
  .headerV2___3_w2T {
    top: 0;
    left: -300px;
    transition: left 1s;
  }
  .headerV2___3_w2T .profile___3Glzy {
    display: none;
  }
  .headerV2___3_w2T .listMenu___uBihV .logoImage___212SW {
    display: none;
  }
  .headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .closeMenu___2Snam {
    display: unset;
  }
  .headerV2___3_w2T .listMenu___uBihV .headerMenu___1PHaa .dropdownItemProfileMb___3iLRe {
    display: flex;
  }
}
.headerV2Mb___2lE2t {
  display: none;
}
.headerV2Mb___2lE2t .logoImage___212SW {
  text-align: center;
  padding-top: 1.5rem;
  position: relative;
}
.headerV2Mb___2lE2t .logoImage___212SW img {
  width: 132px;
  height: 35px;
  cursor: pointer;
}
.headerV2Mb___2lE2t .logoImage___212SW .menuTogether___2oYB3 {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 9%;
  height: 55%;
  top: 40%;
  left: 5%;
  max-width: 35px;
  cursor: pointer;
  justify-content: space-around;
}
.headerV2Mb___2lE2t .logoImage___212SW .menuTogether___2oYB3 div {
  border-radius: 10px;
  height: 14%;
  width: 100%;
  background: #3c3c3c;
}
@media screen and (max-width: 1140px) {
  .headerV2Mb___2lE2t {
    display: unset;
  }
}
