@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1EXOF {
  display: flex;
  justify-content: center;
}
button.btn___2Dbi6 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2Dbi6 {
    padding: 10px;
  }
}
button.btn___2Dbi6:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2Dbi6:focus {
  outline: none;
}
.btnWhite___3Au89.btn___2Dbi6 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3Au89.btn___2Dbi6:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1eBTr.btn___2Dbi6,
.btnGreen___1eBTr.btn___2Dbi6:hover,
.btnGreen___1eBTr.btn___2Dbi6:active,
.btnGreen___1eBTr.btn___2Dbi6:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1NDEa {
  color: #fe0000;
}
.errorMessage___24wRG {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3B7rm {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1_9gw {
  display: none !important;
}
.m-auto___1jfOX {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___15Xgn {
  max-width: 120px !important;
}
.fc___1k27r .fc-timegrid-col___kbTzm.fc-day-today___fFLC9 {
  background-color: #ffffff;
}
.fc___1k27r .fc-timegrid-slot___29M6V {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1k27r .fc-timegrid-slot___29M6V {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___11r9i .fc-direction-ltr___2BFWx .fc-timegrid-slot-label-frame___rNr83 {
    font-size: 10px;
  }
  .bookingCalendar___11r9i .fc___1k27r .fc-timegrid-slot-label-cushion___znr77 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___11r9i .fc___1k27r .fc-scrollgrid-section-header___rGhnF .fc-scroller___1QxV- {
    overflow: unset !important;
  }
}
.ant-message-notice___1-IJf {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3eIcO {
  width: 20px;
  height: 20px;
}
.bgTransparent___14-SE {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1uQ3U {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___sHOMC {
  position: relative;
}
.inputCustomValue___sHOMC .ant-input___3Qvno {
  padding-right: 50px;
}
.inputCustomValueText___Bw4Ix {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3LTDt .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3LTDt .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1hux6 .ant-radio-inner___3whUQ {
  border-color: black !important ;
}
.ant-radio-checked___1hux6 .ant-radio-inner___3whUQ:after {
  background-color: black;
}
.ant-radio___24xxW:hover .ant-radio-inner___3whUQ {
  border-color: black;
}
.ant-radio-checked___1hux6 .ant-radio-inner___3whUQ:focus {
  border-color: black;
}
.modal___30jde {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___30jde .close___jyu40 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___30jde .header___2aGyw {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___30jde .header___2aGyw p {
  margin: 0;
}
.modal___30jde .content___aRqla {
  text-align: center;
  font-size: 14px;
}
.modal___30jde .content___aRqla p {
  margin: 0;
}
.modal___30jde .actions___1TFoP {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___30jde .actions___1TFoP .button___3UZ54 {
  margin-top: 10px;
}
.modal___30jde .actions___1TFoP:hover {
  color: #33c3c7;
}
.fc___1k27r .fc-timegrid-slot-minor___1PB5g {
  border-top-style: none;
}
.fc___1k27r .fc-timegrid-slot___29M6V {
  height: 1.5em !important;
}
.messageError___32po_ .ant-message-custom-content___1ciyq {
  display: flex;
  text-align: start;
}
.messageError___32po_ .ant-message-custom-content___1ciyq span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3AHjE {
  background-color: #e6e4e4;
}
.notifyMessage___38pTd .ant-message-notice-content___3tTzb {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___38pTd .ant-message-notice-content___3tTzb .anticon___S_V0E {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___38pTd .ant-message-notice-content___3tTzb span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___Hl4AU {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___Hl4AU .ant-notification-notice-message___2U_7t,
.notifyCustomize___Hl4AU .ant-notification-notice-close___3tslk,
.notifyCustomize___Hl4AU .ant-notification-notice-icon___3hRVu {
  color: white;
}
.notifyCustomize___Hl4AU .ant-notification-notice-message___2U_7t {
  margin-left: 35px;
}
.notifyCustomize___Hl4AU p {
  margin-bottom: 0;
}
.notifyCustomize___Hl4AU p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___tG7xB {
  padding: 16px 14px;
}
.notifyAlertDuplicate___tG7xB .ant-notification-notice-message___2U_7t {
  margin-left: 35px;
}
.notifyAlertDuplicate___tG7xB p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___tG7xB p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2LdyR {
  width: 434px !important;
}
.handleSubmitVote___2LdyR .ant-btn-primary___r71Gr {
  background: #59c3c7;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc .ant-modal-confirm-body___5yAWX {
  display: flex !important;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc .ant-modal-confirm-body___5yAWX .anticon___S_V0E {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc .ant-modal-confirm-btns___3itoy button {
  font-weight: bold;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc .ant-modal-confirm-btns___3itoy button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc .ant-modal-confirm-btns___3itoy button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2LdyR .ant-modal-body___3lckc .ant-modal-confirm-btns___3itoy .ant-btn-primary___r71Gr {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___12yWH {
  margin-bottom: 0;
}
.mt-0___22YBe {
  margin-top: 0;
}
.tooltipFormat___PeiWm p {
  margin-bottom: 0;
}
.bgWhite___1GnI8 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1HUc_ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1HUc_:hover,
.bgPrimaryBlue___1HUc_:focus,
.bgPrimaryBlue___1HUc_:active {
  background: #4f7ac7;
}
.bgDarkBlue___aMcjx {
  background-color: #023768 !important;
}
.bgDarkBlue___aMcjx:hover,
.bgDarkBlue___aMcjx:focus,
.bgDarkBlue___aMcjx:active {
  background: #194d7d;
}
.bgGreyBlue_____u2B {
  background-color: #ebf0f9;
}
.bgLightBlue___1H9hm {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3b8K2 {
  background-color: #e4453a;
}
.bgLightRed___3b8K2:hover,
.bgLightRed___3b8K2:focus,
.bgLightRed___3b8K2:active {
  background: #e17871;
}
.bgLightYellow___3X_dN {
  background-color: #ffd603;
}
.bgDarkGray___1kBBR {
  background-color: #333333;
}
.bgMediumGray___6p4XR {
  background-color: #a2a2a2;
}
.bgLightGray___2kR0i {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3WFLa {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___oqroC {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1ZPQN {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1_96Y {
  color: #3368c7 !important;
}
.textDarkBlue___3-oLO {
  color: #023768 !important;
}
.textLightBlue___1Klv0 {
  color: #b2cbf7;
}
.textDarkGray___2GeGf {
  color: #333333 !important;
}
.textMediumGray___2PGrQ {
  color: #a2a2a2 !important;
}
.textLightGray___2kkkE {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1TL6A {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___gdenb {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___6CKfw {
  border-radius: 5px !important;
}
.rounded-xs___2ohMJ {
  border-radius: 10px !important;
}
.rounded-sm___3cFzu {
  border-radius: 15px !important;
}
.rounded-md___27cFc {
  border-radius: 20px !important;
}
.rounded-lg___1sJg4 {
  border-radius: 25px !important;
}
.rounded-full___2xEnN {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1aPJF {
  margin: 0;
}
.mt-2___3oy2d {
  margin-top: 0.5rem;
}
.my-2___Ux9em {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___WBk7h {
  padding: 0;
}
.px-1___3RmAf {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___7-P3V {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3Gmmi {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2F17O {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1S4bE {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___18mKy {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1vLRl {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1OahC {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1SJTe {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___33Xer {
  padding-bottom: 20px;
}
.h-full___1O_eD {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___k8g8_ {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1OPI6 {
  font-weight: bold !important;
}
.fontWeight400___xYw26 {
  font-weight: 400 !important;
}
.fontWeight500___1fukQ {
  font-weight: 500 !important;
}
.fontWeight600___2msSn {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2gT_s {
  font-size: 12px !important;
}
.fontSize14___aiiNc {
  font-size: 14px !important;
}
.fontSize16___1wJ6E {
  font-size: 16px !important;
}
.fontSize18___3qGq2 {
  font-size: 18px !important;
}
.fontSize20___3hxUV {
  font-size: 20px !important;
}
.fontSize24___2y8xe {
  font-size: 24px !important;
}
.eventName___1Sjne {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3AYIK {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1FiMU > tr > th,
.ant-table-tbody___1iMk3 > tr.ant-table-row___BYj_7 > td,
.ant-table-summary___1k6Z_ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1FiMU > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3Nnap .ant-table-thead___1FiMU > tr > th,
.table-portrait___3Nnap .ant-table-tbody___1iMk3 > tr.ant-table-row___BYj_7 > td,
.table-portrait___3Nnap .ant-table-summary___1k6Z_ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3Nnap .bgLightGray___2kR0i td {
  background: #ebebeb;
}
.table-portrait___3Nnap .bgLightBlue___1H9hm td {
  background: #b2cbf7;
}
.eventContent___qJ1_X .timeText___1JcfC {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1iMk3 > tr.bgLightGray___2kR0i:hover > td,
.ant-table-tbody___1iMk3 > tr > td.ant-table-cell-row-hover___2hSnc {
  background: #ebebeb;
}
.ant-table-tbody___1iMk3 > tr.bgLightBlue___1H9hm:hover > td,
.ant-table-tbody___1iMk3 > tr > td.ant-table-cell-row-hover___2hSnc {
  background: #b2cbf7;
}
.ant-table-tbody___1iMk3 > tr.bgWhite___1GnI8:hover > td,
.ant-table-tbody___1iMk3 > tr > td.ant-table-cell-row-hover___2hSnc {
  background: #ffffff;
}
.embemModalTitle___3xCg1 {
  text-align: center;
}
.backButtonModal___1-4Nf {
  display: block;
  margin: 16px auto;
  width: 90px;
  border-radius: 12px;
  border: 2px solid #e8e3e3;
  box-shadow: 1px 1px 10px #b3bcbc60;
}
.backButtonModal___1-4Nf:hover {
  background-color: #33c3c7;
  color: white;
}
.embedModalContent___2TBP9 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-gap: 4px;
  gap: 4px;
  height: 408px;
  padding: 0px 57px;
}
@media screen and (max-width: 767px) {
  .embedModalContent___2TBP9 {
    display: block;
  }
  .embedModalContent___2TBP9 .embedModalInner___9dL3S {
    width: 100%;
  }
  .embedModalContent___2TBP9 .embedModalInner___9dL3S:last-child {
    margin-top: 1rem;
  }
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S {
  border: 4px solid #40a9ff;
  width: 100%;
  height: 100%;
  padding: 14px;
  cursor: pointer;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S:hover {
  background-color: #33c3c7 !important;
  color: white !important;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S:hover h2 {
  color: white !important;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S button {
  display: block;
  margin: 0 auto;
  border-radius: 16px;
  font-weight: bold;
  background-color: #33c3c7;
  color: white;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S .embedLinkButton___13goI {
  text-align: center;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  color: #33c3c7;
  padding: 4px 15px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S a {
  display: block;
  margin: 0 auto;
  font-size: 14px;
  width: 157px;
  height: 32px;
  padding-top: 3px;
  font-weight: bold;
  background-color: #e8e3e3;
  color: #33c3c7;
  text-align: center;
  border-bottom: 2px solid #33c3c7;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S h2 {
  margin-top: 8px;
  margin-bottom: 4px;
}
@media only screen and (max-width: 716px) {
  .embedModalContent___2TBP9 .embedModalInner___9dL3S h2 {
    font-size: 19px;
  }
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S p {
  margin-bottom: 2px;
  font-size: 12px;
  padding-left: 11px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:first-child {
  height: 280px;
  border: 2px solid #1a1b1b60;
  border-radius: 12px;
  background-color: white;
  position: relative;
  padding: 40px 18px 0 18px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:first-child > div:first-child {
  width: 100%;
  height: 37px;
  background-color: #e8e3e3;
  border-radius: 10px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:first-child > div:nth-child(2) {
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: space-around;
  grid-gap: 10px;
  gap: 10px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:first-child > div:nth-child(2) > div {
  width: 100%;
  height: 100%;
  background-color: #e8e3e3;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:first-child > div:last-child {
  flex-grow: 1;
  width: 100%;
  background-color: #e8e3e3;
  padding: 17px 0px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:first-child::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 23px;
  background-color: #d4d1d1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.embedModalContent___2TBP9 .embedModalInner___9dL3S > div:last-child {
  margin: 0 auto;
}
@media only screen and (max-width: 556px) {
  .embedModalContent___2TBP9 {
    padding: 0;
  }
  .embedModalContent___2TBP9 .embedModalInner___9dL3S button {
    font-size: 10px;
  }
  .embedModalContent___2TBP9 .embedModalInner___9dL3S h2 {
    font-size: 16px;
  }
  .embedModalContent___2TBP9 .embedModalInner___9dL3S p {
    font-size: 10px;
  }
}
.modalCopyContentEmbedLinkContainer___s1CMD {
  text-align: center;
}
.modalCopyContentEmbedLinkContainer___s1CMD h2 {
  font-size: 16px;
}
.modalCopyContentEmbedLinkContainer___s1CMD p {
  font-size: 12px;
}
@media screen and (max-width: 625px) {
  .modalCopyContentEmbedLinkContainer___s1CMD p {
    font-size: 10px;
  }
}
.modalCopyContentEmbedLinkContainer___s1CMD .textArea___K_H7b {
  width: 95%;
  margin-top: -22px;
  margin-bottom: 1rem;
  z-index: 0;
  border-radius: 0 !important;
}
.modalCopyContentEmbedLinkContainer___s1CMD .buttonCopyContent1___1-AOY {
  font-size: 16px;
  border-radius: 22px;
  background-color: #2eb9bd;
  color: white;
  z-index: 1;
  padding: 8px 15px;
  height: auto;
}
.modalCopyContentEmbedLinkContainer___s1CMD .buttonCopyContent2___56EHt {
  font-size: 16px;
  border-radius: 22px;
  background-color: #2eb9bd;
  color: white;
  padding: 8px 15px;
  height: auto;
}
.modalCopyContentEmbedLinkContainer___s1CMD .linkEmbedCode___2krvl {
  color: #0c3dc9;
}
.modalCopyContentEmbedLinkContainer___s1CMD .buttonFooterContainer___46ea9 {
  margin-top: 1rem;
}
.modalCopyContentEmbedLinkContainer___s1CMD .buttonFooterContainer___46ea9 button {
  font-size: 16px;
  border-radius: 20px;
  padding: 5px 30px;
  height: auto;
}
.modalCopyContentEmbedLinkContainer___s1CMD .buttonFooterContainer___46ea9 button:first-child:hover {
  border: #33c3c7 1px solid !important;
}
.modalCopyContentEmbedLinkContainer___s1CMD .buttonFooterContainer___46ea9 button:last-child {
  margin-left: 0.5rem;
  background-color: #ececec;
}
