@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___14Xbk {
  display: flex;
  justify-content: center;
}
button.btn___3rdvH {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3rdvH {
    padding: 10px;
  }
}
button.btn___3rdvH:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3rdvH:focus {
  outline: none;
}
.btnWhite___2Zepo.btn___3rdvH {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2Zepo.btn___3rdvH:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1QCvY.btn___3rdvH,
.btnGreen___1QCvY.btn___3rdvH:hover,
.btnGreen___1QCvY.btn___3rdvH:active,
.btnGreen___1QCvY.btn___3rdvH:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1O43A {
  color: #fe0000;
}
.errorMessage___3CfRU {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3pykD {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___19Coq {
  display: none !important;
}
.m-auto___3mCGy {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1gNM_ {
  max-width: 120px !important;
}
.fc___1k95I .fc-timegrid-col___zrUx1.fc-day-today___34CQL {
  background-color: #ffffff;
}
.fc___1k95I .fc-timegrid-slot___1ZU9M {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1k95I .fc-timegrid-slot___1ZU9M {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3MrON .fc-direction-ltr___2AjAD .fc-timegrid-slot-label-frame___3A3yt {
    font-size: 10px;
  }
  .bookingCalendar___3MrON .fc___1k95I .fc-timegrid-slot-label-cushion___1Yr4B {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3MrON .fc___1k95I .fc-scrollgrid-section-header___1-Y6E .fc-scroller___Bjsz0 {
    overflow: unset !important;
  }
}
.ant-message-notice___N8ANL {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1wPWC {
  width: 20px;
  height: 20px;
}
.bgTransparent___XSZEB {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3KLew {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3WbD9 {
  position: relative;
}
.inputCustomValue___3WbD9 .ant-input___1Qd5B {
  padding-right: 50px;
}
.inputCustomValueText___3HQDZ {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2UljN .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2UljN .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1b7ya .ant-radio-inner___-qdG9 {
  border-color: black !important ;
}
.ant-radio-checked___1b7ya .ant-radio-inner___-qdG9:after {
  background-color: black;
}
.ant-radio___17J58:hover .ant-radio-inner___-qdG9 {
  border-color: black;
}
.ant-radio-checked___1b7ya .ant-radio-inner___-qdG9:focus {
  border-color: black;
}
.modal___2u4w- {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2u4w- .close___18Yja {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2u4w- .header___2ImVg {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2u4w- .header___2ImVg p {
  margin: 0;
}
.modal___2u4w- .content___3xeMY {
  text-align: center;
  font-size: 14px;
}
.modal___2u4w- .content___3xeMY p {
  margin: 0;
}
.modal___2u4w- .actions___1WR5B {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2u4w- .actions___1WR5B .button___3ca6z {
  margin-top: 10px;
}
.modal___2u4w- .actions___1WR5B:hover {
  color: #33c3c7;
}
.fc___1k95I .fc-timegrid-slot-minor___2dUIl {
  border-top-style: none;
}
.fc___1k95I .fc-timegrid-slot___1ZU9M {
  height: 1.5em !important;
}
.messageError___QjALE .ant-message-custom-content___1Anj0 {
  display: flex;
  text-align: start;
}
.messageError___QjALE .ant-message-custom-content___1Anj0 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1zy-s {
  background-color: #e6e4e4;
}
.notifyMessage___16Gga .ant-message-notice-content___2187P {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___16Gga .ant-message-notice-content___2187P .anticon___1_E2g {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___16Gga .ant-message-notice-content___2187P span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2K5BW {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2K5BW .ant-notification-notice-message___11xH0,
.notifyCustomize___2K5BW .ant-notification-notice-close___1jQtd,
.notifyCustomize___2K5BW .ant-notification-notice-icon___3khVz {
  color: white;
}
.notifyCustomize___2K5BW .ant-notification-notice-message___11xH0 {
  margin-left: 35px;
}
.notifyCustomize___2K5BW p {
  margin-bottom: 0;
}
.notifyCustomize___2K5BW p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1zn8V {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1zn8V .ant-notification-notice-message___11xH0 {
  margin-left: 35px;
}
.notifyAlertDuplicate___1zn8V p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1zn8V p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3R3xi {
  width: 434px !important;
}
.handleSubmitVote___3R3xi .ant-btn-primary___2LyDe {
  background: #59c3c7;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI .ant-modal-confirm-body___3WQlR {
  display: flex !important;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI .ant-modal-confirm-body___3WQlR .anticon___1_E2g {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI .ant-modal-confirm-btns___2N-Dy button {
  font-weight: bold;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI .ant-modal-confirm-btns___2N-Dy button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI .ant-modal-confirm-btns___2N-Dy button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3R3xi .ant-modal-body___3SgKI .ant-modal-confirm-btns___2N-Dy .ant-btn-primary___2LyDe {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1RLE6 {
  margin-bottom: 0;
}
.mt-0___37Si- {
  margin-top: 0;
}
.tooltipFormat___1YXbT p {
  margin-bottom: 0;
}
.bgWhite___1Vbj_ {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1ye88 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1ye88:hover,
.bgPrimaryBlue___1ye88:focus,
.bgPrimaryBlue___1ye88:active {
  background: #4f7ac7;
}
.bgDarkBlue___1wizP {
  background-color: #023768 !important;
}
.bgDarkBlue___1wizP:hover,
.bgDarkBlue___1wizP:focus,
.bgDarkBlue___1wizP:active {
  background: #194d7d;
}
.bgGreyBlue___2StLU {
  background-color: #ebf0f9;
}
.bgLightBlue___1KADY {
  background-color: #b2cbf7 !important;
}
.bgLightRed___GNvck {
  background-color: #e4453a;
}
.bgLightRed___GNvck:hover,
.bgLightRed___GNvck:focus,
.bgLightRed___GNvck:active {
  background: #e17871;
}
.bgLightYellow___2pMO4 {
  background-color: #ffd603;
}
.bgDarkGray___121vx {
  background-color: #333333;
}
.bgMediumGray___3Pktq {
  background-color: #a2a2a2;
}
.bgLightGray___qP87y {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1HB6h {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2FkwE {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___cUkcR {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2hBEO {
  color: #3368c7 !important;
}
.textDarkBlue___10EKx {
  color: #023768 !important;
}
.textLightBlue___28Wv3 {
  color: #b2cbf7;
}
.textDarkGray___2VhZr {
  color: #333333 !important;
}
.textMediumGray___1_cSI {
  color: #a2a2a2 !important;
}
.textLightGray___2Eatk {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___284El {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2KI7c {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3weLZ {
  border-radius: 5px !important;
}
.rounded-xs___39q6F {
  border-radius: 10px !important;
}
.rounded-sm___rIxOb {
  border-radius: 15px !important;
}
.rounded-md___1Bp6a {
  border-radius: 20px !important;
}
.rounded-lg___38GIl {
  border-radius: 25px !important;
}
.rounded-full___3l5GK {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2C59k {
  margin: 0;
}
.mt-2___1x_DP {
  margin-top: 0.5rem;
}
.my-2___15_9g {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1N3cG {
  padding: 0;
}
.px-1___2G2nS {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2EbhL {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1vhFl {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3C6u4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2_hS1 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1I23X {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___evVuM {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___18Ck4 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___njJzJ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2Lu5L {
  padding-bottom: 20px;
}
.h-full___nBzOq {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2RLKF {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___8PoO6 {
  font-weight: bold !important;
}
.fontWeight400___3xOMD {
  font-weight: 400 !important;
}
.fontWeight500___SbiQR {
  font-weight: 500 !important;
}
.fontWeight600___27Ant {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1ScU5 {
  font-size: 12px !important;
}
.fontSize14___1mO2j {
  font-size: 14px !important;
}
.fontSize16___3n5l3 {
  font-size: 16px !important;
}
.fontSize18___lbpTu {
  font-size: 18px !important;
}
.fontSize20___Ll2TO {
  font-size: 20px !important;
}
.fontSize24___3Yja5 {
  font-size: 24px !important;
}
.eventName___1QZOJ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___31ITH {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3kr0V > tr > th,
.ant-table-tbody___3kolC > tr.ant-table-row___2SeEQ > td,
.ant-table-summary___3a1bd tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3kr0V > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3ZX0n .ant-table-thead___3kr0V > tr > th,
.table-portrait___3ZX0n .ant-table-tbody___3kolC > tr.ant-table-row___2SeEQ > td,
.table-portrait___3ZX0n .ant-table-summary___3a1bd tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3ZX0n .bgLightGray___qP87y td {
  background: #ebebeb;
}
.table-portrait___3ZX0n .bgLightBlue___1KADY td {
  background: #b2cbf7;
}
.eventContent___3-lB6 .timeText___1GqIJ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3kolC > tr.bgLightGray___qP87y:hover > td,
.ant-table-tbody___3kolC > tr > td.ant-table-cell-row-hover___cbe65 {
  background: #ebebeb;
}
.ant-table-tbody___3kolC > tr.bgLightBlue___1KADY:hover > td,
.ant-table-tbody___3kolC > tr > td.ant-table-cell-row-hover___cbe65 {
  background: #b2cbf7;
}
.ant-table-tbody___3kolC > tr.bgWhite___1Vbj_:hover > td,
.ant-table-tbody___3kolC > tr > td.ant-table-cell-row-hover___cbe65 {
  background: #ffffff;
}
.modalInputNumber___2GvIe {
  width: 100% !important;
}
.btnZoneModal___1RRXN {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.btnZoneModal___1RRXN .updateBtn___jygvb {
  height: 35px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 14px;
  color: #fffefe;
  border-radius: 19px;
  background-color: #33c3c7;
  border-color: #33c3c7;
  margin: 0 20px;
  width: 100%;
}
.btnZoneModal___1RRXN .cancelBtn___2DSCJ {
  height: 35px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 14px;
  color: #535353;
  border-radius: 19px;
  background-color: #fffefe;
  margin: 0 20px;
  box-shadow: 1px 1px 15px #b3bcbc60;
  border: none;
  width: 100%;
}
.errorNotice___eq-7J {
  color: #ff4d4f;
  font-size: 12px;
}
.changeTime___3uav3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 60px;
}
.changeTime___3uav3 .center___328DF {
  text-align: center;
}
.changeTime___3uav3 .title___1xOBy {
  font-size: 26px;
  font-weight: 700;
  border-bottom: 4px solid #33c3c7;
  display: inline-block;
  margin-bottom: 50px;
}
.changeTime___3uav3 .description___3mPRB {
  display: flex;
  align-items: center;
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .changeTime___3uav3 .description___3mPRB {
    font-size: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .changeTime___3uav3 .description___3mPRB img {
    margin-top: -7px;
    width: 29px;
    height: 25px;
  }
}
@media screen and (max-width: 767px) {
  .changeTime___3uav3 .description___3mPRB div {
    text-align: left;
  }
}
.changeTime___3uav3 .description2___zC8SK {
  font-size: 22px;
  text-align: left;
  padding-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .changeTime___3uav3 .description2___zC8SK {
    font-size: 12px;
  }
}
.changeTime___3uav3 .form___IMLlO {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.changeTime___3uav3 .form___IMLlO .text___2laIN {
  margin-right: 45px;
  font-size: 22px;
}
.changeTime___3uav3 .form___IMLlO .text___2laIN:last-child {
  margin-right: 0;
}
.changeTime___3uav3 .form___IMLlO .ant-form-item {
  margin-bottom: 0;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number {
  width: 58px;
  height: 58px;
  border-radius: 13px;
  border: 0;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  margin-right: 10px;
}
.changeTime___3uav3 .form___IMLlO .ant-form-item .ant-input-number-handler-wrap {
  opacity: 1;
  border: 0;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-up {
  position: absolute;
  top: -57px;
  width: 58px;
  left: -36px;
  height: 42px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAnElEQVQokY3RsQpBcRTH8Q/dsngEkcED2O9AGcQDyCuYzEoms81mlVWRwW402O02D0AyIFL3f+9vOp3fOd9+nZOL41hABUywwSFpKAoR0EQb9xAkHwBMsUMdY2xRywqpYo/RX7+DI/ppkB5OaCWkK2KJ2e9u/sdcYPWu0zTEGQ1ehy1jji6uuGWA5FDBGoPI90NHXPDIAPmkL6HwBMCWF0Xn+GuwAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-up svg {
  display: none;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-up:hover {
  height: 42px !important;
  background-color: #ddf6f5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAnElEQVQokY3RsQpBcRTH8Q/dsngEkcED2O9AGcQDyCuYzEoms81mlVWRwW402O02D0AyIFL3f+9vOp3fOd9+nZOL41hABUywwSFpKAoR0EQb9xAkHwBMsUMdY2xRywqpYo/RX7+DI/ppkB5OaCWkK2KJ2e9u/sdcYPWu0zTEGQ1ehy1jji6uuGWA5FDBGoPI90NHXPDIAPmkL6HwBMCWF0Xn+GuwAAAAAElFTkSuQmCC);
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-up:active {
  height: 42px !important;
  background-color: #33c3c7;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAJJJREFUKJGNkSEOwlAQBaekSU0lkpAguASqOBIOQLgC9wCLw3EMVH0lV8DiEJUQBkMINJ//O8m6eW+TXVQiU6g7dRbzcuLMgQXwAJq/VmTD1l9O6jTkhsITtTZMq65TJau3mGKvDrolpXrsEf7molYqmToGDsASuAH3xLEBMmAItMAmh8+HzsAVePYoASiBEVC8AI7WLXosqfA2AAAAAElFTkSuQmCC);
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down {
  position: absolute;
  bottom: -131px;
  border: 0;
  height: auto;
  width: 66px;
  left: -40px;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down svg {
  display: none;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down .ant-input-number-handler-down-inner {
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAl0lEQVQYlY3OsQpBcRiG8Z/TGczqXAvl7C5ABoNdFOUOrEabshkscgEuQNmUXdmIpIjVQumUf+cdn973+b5CmqYJehjghJd8KSLBDPMYZ4xQQien5JsxhrhGH3BHF1Uccgj2qKGPK0SZwhoVLAKSJcpY/cKsCI5ooI3nD7+hhTou2VEcuDzB5vPBE03s/pVDIthiikdIAm8E2x3HKxuWhQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  width: 58px;
  height: 42px;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down:hover {
  height: auto !important;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down:hover .ant-input-number-handler-down-inner {
  height: 42px !important;
  background-color: #ddf6f5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAl0lEQVQYlY3OsQpBcRiG8Z/TGczqXAvl7C5ABoNdFOUOrEabshkscgEuQNmUXdmIpIjVQumUf+cdn973+b5CmqYJehjghJd8KSLBDPMYZ4xQQien5JsxhrhGH3BHF1Uccgj2qKGPK0SZwhoVLAKSJcpY/cKsCI5ooI3nD7+hhTou2VEcuDzB5vPBE03s/pVDIthiikdIAm8E2x3HKxuWhQAAAABJRU5ErkJggg==);
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down:active {
  background-color: transparent;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-handler-down:active .ant-input-number-handler-down-inner {
  height: 42px !important;
  background-color: #33c3c7;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAjElEQVQYlZXRoQrCYBiF4VdYWLGIg92EYPYCvAq7zeCdGNbWDAvivQhaFeuSxWF8TT+Mof+2D0475ynfRM2AHbAHauDDsEuBDDgCFSrqVC0cfwd1phKgkJX6HADc1XV724VQc/UUQc7qvLv7BYVs1aYFvNTNv34MQl2qD/WmLmLdpOczF6AE3sA1VvwCmqo9E5QyjH4AAAAASUVORK5CYII=);
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-input-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changeTime___3uav3 .form___IMLlO .ant-input-number-input {
  height: 100%;
  font-size: 26px;
  padding: 0 0 5px 0;
  z-index: 111;
  width: 30px;
  text-align: center;
}
.btnScheduleTime___2ErPg {
  height: 47px;
  width: 100%;
  max-width: 150px;
  border-radius: 20px;
  font-size: 18px;
  border: 0;
  margin-bottom: 30px;
}
.btnScheduleTime___2ErPg:hover {
  opacity: 0.8;
}
.cancelScheduleBtn___19xRA {
  background-color: #e8e6e6;
  margin-right: 55px;
}
.cancelScheduleBtn___19xRA:hover,
.cancelScheduleBtn___19xRA:focus,
.cancelScheduleBtn___19xRA:active {
  background-color: #e8e6e6;
  color: #3c3c3c;
}
.updateScheduleBtn___i1k9W {
  background-color: #33c3c7;
  color: #fff;
}
.updateScheduleBtn___i1k9W:hover,
.updateScheduleBtn___i1k9W:focus,
.updateScheduleBtn___i1k9W:active {
  background-color: #33c3c7;
  color: #fff;
}
