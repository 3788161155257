.support___t9yCL {
  max-width: 1080px;
  margin: auto;
  padding: 200px 0;
}
.support___t9yCL h1 {
  padding: 0 0 100px 0;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}
.support___t9yCL p {
  font-size: 20px;
}
.support___t9yCL .title___2qWeD {
  font-size: 24px;
  color: #33c3c7;
}
