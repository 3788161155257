.home___3dbSu {
  background-image: url(./static/home_background.5d10c72f.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-bottom: 280px;
  position: relative;
  background-color: #f0f8fe;
  height: 100vh;
}
@media screen and (max-width: 767px) {
  .home___3dbSu {
    padding: 0 10px 280px 10px;
  }
}
.home___3dbSu .leftBottomBackground___1LSZg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 335px;
  height: 380px;
}
@media screen and (max-width: 767px) {
  .home___3dbSu .leftBottomBackground___1LSZg {
    width: 100%;
  }
}
.home___3dbSu .leftBottomBackground___1LSZg img {
  width: 100%;
  height: 100%;
}
.home___3dbSu .homeContent___1lD9g .title___wOLQG {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #038b8f;
  margin: 200px 0 20px 0;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .home___3dbSu .homeContent___1lD9g .title___wOLQG {
    font-size: 30px;
  }
}
.home___3dbSu .homeContent___1lD9g p {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #565656;
}
@media screen and (max-width: 767px) {
  .home___3dbSu .homeContent___1lD9g p {
    font-size: 14px;
  }
}
.home___3dbSu .homeContent___1lD9g .divide___1sizf {
  height: 30px;
  margin: 60px 0 15px 0;
  display: flex;
  align-items: center;
}
.home___3dbSu .homeContent___1lD9g .divide___1sizf span {
  color: #161615;
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  font-weight: bold;
  margin-right: 20px;
  line-height: 1;
  word-break: keep-all;
}
.home___3dbSu .homeContent___1lD9g .divide___1sizf div {
  background-color: #acd9db;
  height: 1px;
  width: 100%;
}
.home___3dbSu .homeContent___1lD9g .linkedList___27ZrO {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home___3dbSu .homeContent___1lD9g .linkedList___27ZrO .badge___3uWnP {
  height: 99px;
  width: 198px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ffffff);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 12px 5px 18px #279aa133;
  border: 1px solid #ffffff;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #161615;
}
@media screen and (max-width: 767px) {
  .home___3dbSu .homeContent___1lD9g .linkedList___27ZrO .badge___3uWnP {
    height: 60px;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    min-width: 120px;
    padding: 5px;
    font-size: 14px;
  }
}
.home___3dbSu .homeContent___1lD9g .linkedList___27ZrO .badge___3uWnP img {
  height: 65px;
  width: auto;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .home___3dbSu .homeContent___1lD9g .linkedList___27ZrO .badge___3uWnP img {
    height: 35px;
  }
}
.home___3dbSu .backgroundRight___3RbP5 {
  width: 100%;
  height: 100%;
}
