.menu-page .header {
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid darkblue;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
}
.menu-page .logoutConfirm {
  border-radius: 20px !important;
}
.menu-page .menu-content {
  background-color: #ddf3fc;
  min-height: 100vh;
  padding: 60px 40px;
  grid-gap: 20px;
  gap: 20px;
}
.menu-page .menu-content .content-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
}
