.buttonTemplate___20JsB {
  width: 84%;
  overflow: hidden;
}
.buttonTemplate___20JsB .display___G9s-n {
  width: 50%;
  height: 82vh;
  margin: auto;
  background: #fff;
  border: 2px solid #4a6bc0;
  text-align: center;
  padding-top: 2rem;
  overflow: auto;
}
.buttonTemplate___20JsB .display___G9s-n button span {
  word-break: break-all;
}
.buttonTemplate___20JsB .display___G9s-n button input {
  outline: none;
  border: transparent;
  color: #3c3c3c !important;
  width: 100%;
  width: '-webkit-fill-available';
}
@media screen and (max-width: 1440px) {
  .buttonTemplate___20JsB .display___G9s-n {
    width: 60%;
  }
}
@media screen and (max-width: 1190px) {
  .buttonTemplate___20JsB .display___G9s-n {
    width: 65%;
  }
}
