@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1LC3n {
  display: flex;
  justify-content: center;
}
button.btn___38FCj {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___38FCj {
    padding: 10px;
  }
}
button.btn___38FCj:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___38FCj:focus {
  outline: none;
}
.btnWhite___1zN30.btn___38FCj {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1zN30.btn___38FCj:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1_Cnc.btn___38FCj,
.btnGreen___1_Cnc.btn___38FCj:hover,
.btnGreen___1_Cnc.btn___38FCj:active,
.btnGreen___1_Cnc.btn___38FCj:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___18FeX {
  color: #fe0000;
}
.errorMessage___3VGVn {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3CL3t {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1pyhL {
  display: none !important;
}
.m-auto___11Vy_ {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2zNHz {
  max-width: 120px !important;
}
.fc___26q1F .fc-timegrid-col___3AW39.fc-day-today___2hiVC {
  background-color: #ffffff;
}
.fc___26q1F .fc-timegrid-slot___1apNN {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___26q1F .fc-timegrid-slot___1apNN {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___kIvFN .fc-direction-ltr___tYTgh .fc-timegrid-slot-label-frame___4qu6i {
    font-size: 10px;
  }
  .bookingCalendar___kIvFN .fc___26q1F .fc-timegrid-slot-label-cushion___3HjUJ {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___kIvFN .fc___26q1F .fc-scrollgrid-section-header___2pUp2 .fc-scroller___3DZUt {
    overflow: unset !important;
  }
}
.ant-message-notice___3z2Rk {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___OG3zm {
  width: 20px;
  height: 20px;
}
.bgTransparent___3lHhL {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___bZgCt {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2z58Y {
  position: relative;
}
.inputCustomValue___2z58Y .ant-input___3ewEI {
  padding-right: 50px;
}
.inputCustomValueText___K_Gl2 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3GRF3 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3GRF3 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked____hLUK .ant-radio-inner___JJ5tK {
  border-color: black !important ;
}
.ant-radio-checked____hLUK .ant-radio-inner___JJ5tK:after {
  background-color: black;
}
.ant-radio___Wn-tq:hover .ant-radio-inner___JJ5tK {
  border-color: black;
}
.ant-radio-checked____hLUK .ant-radio-inner___JJ5tK:focus {
  border-color: black;
}
.modal___24EqO {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___24EqO .close___3B3bU {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___24EqO .header___25KyP {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___24EqO .header___25KyP p {
  margin: 0;
}
.modal___24EqO .content___Kz2dA {
  text-align: center;
  font-size: 14px;
}
.modal___24EqO .content___Kz2dA p {
  margin: 0;
}
.modal___24EqO .actions____4rMu {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___24EqO .actions____4rMu .button___V6H1v {
  margin-top: 10px;
}
.modal___24EqO .actions____4rMu:hover {
  color: #33c3c7;
}
.fc___26q1F .fc-timegrid-slot-minor___2_-Da {
  border-top-style: none;
}
.fc___26q1F .fc-timegrid-slot___1apNN {
  height: 1.5em !important;
}
.messageError___1g-b2 .ant-message-custom-content___87Fxr {
  display: flex;
  text-align: start;
}
.messageError___1g-b2 .ant-message-custom-content___87Fxr span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2QTP2 {
  background-color: #e6e4e4;
}
.notifyMessage___1Nwfp .ant-message-notice-content___gTxt6 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1Nwfp .ant-message-notice-content___gTxt6 .anticon___2i-Rl {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1Nwfp .ant-message-notice-content___gTxt6 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1esuE {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1esuE .ant-notification-notice-message___3B1Wx,
.notifyCustomize___1esuE .ant-notification-notice-close___32T8b,
.notifyCustomize___1esuE .ant-notification-notice-icon___D_Ivv {
  color: white;
}
.notifyCustomize___1esuE .ant-notification-notice-message___3B1Wx {
  margin-left: 35px;
}
.notifyCustomize___1esuE p {
  margin-bottom: 0;
}
.notifyCustomize___1esuE p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3jEoz {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3jEoz .ant-notification-notice-message___3B1Wx {
  margin-left: 35px;
}
.notifyAlertDuplicate___3jEoz p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3jEoz p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3vjha {
  width: 434px !important;
}
.handleSubmitVote___3vjha .ant-btn-primary___3C2dF {
  background: #59c3c7;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK .ant-modal-confirm-body___3htRj {
  display: flex !important;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK .ant-modal-confirm-body___3htRj .anticon___2i-Rl {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK .ant-modal-confirm-btns___39X90 button {
  font-weight: bold;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK .ant-modal-confirm-btns___39X90 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK .ant-modal-confirm-btns___39X90 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3vjha .ant-modal-body___yyVWK .ant-modal-confirm-btns___39X90 .ant-btn-primary___3C2dF {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1gA20 {
  margin-bottom: 0;
}
.mt-0___3zCkW {
  margin-top: 0;
}
.tooltipFormat___3uBEL p {
  margin-bottom: 0;
}
.bgWhite___uOIAx {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___fuuU2 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___fuuU2:hover,
.bgPrimaryBlue___fuuU2:focus,
.bgPrimaryBlue___fuuU2:active {
  background: #4f7ac7;
}
.bgDarkBlue___3VDlB {
  background-color: #023768 !important;
}
.bgDarkBlue___3VDlB:hover,
.bgDarkBlue___3VDlB:focus,
.bgDarkBlue___3VDlB:active {
  background: #194d7d;
}
.bgGreyBlue___2mvtv {
  background-color: #ebf0f9;
}
.bgLightBlue___2QBNj {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2Nk8V {
  background-color: #e4453a;
}
.bgLightRed___2Nk8V:hover,
.bgLightRed___2Nk8V:focus,
.bgLightRed___2Nk8V:active {
  background: #e17871;
}
.bgLightYellow___3wEUH {
  background-color: #ffd603;
}
.bgDarkGray___39tsJ {
  background-color: #333333;
}
.bgMediumGray___3Q7Q- {
  background-color: #a2a2a2;
}
.bgLightGray___JbDVF {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3Yk1M {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___f6suA {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2Cf_j {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1Fagq {
  color: #3368c7 !important;
}
.textDarkBlue___G_KHI {
  color: #023768 !important;
}
.textLightBlue___1PtXW {
  color: #b2cbf7;
}
.textDarkGray___2UsN6 {
  color: #333333 !important;
}
.textMediumGray___1CZYG {
  color: #a2a2a2 !important;
}
.textLightGray___2fsuj {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1RP6- {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary____brNU {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___e_r7h {
  border-radius: 5px !important;
}
.rounded-xs___gDDBC {
  border-radius: 10px !important;
}
.rounded-sm___2_QPo {
  border-radius: 15px !important;
}
.rounded-md___2uHul {
  border-radius: 20px !important;
}
.rounded-lg___2Q0vd {
  border-radius: 25px !important;
}
.rounded-full___3fm15 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___ZPTnS {
  margin: 0;
}
.mt-2___13PNm {
  margin-top: 0.5rem;
}
.my-2___1gVKM {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2a4Nu {
  padding: 0;
}
.px-1___xS7Td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1l2he {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1sA82 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___xNZNp {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3HEll {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2yMFR {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3O8uY {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1Q6TE {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___16_md {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3FqkO {
  padding-bottom: 20px;
}
.h-full___2wq4Z {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3KLY7 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1H4VX {
  font-weight: bold !important;
}
.fontWeight400___BMGmQ {
  font-weight: 400 !important;
}
.fontWeight500___9f7g2 {
  font-weight: 500 !important;
}
.fontWeight600___a7gFp {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___27OEW {
  font-size: 12px !important;
}
.fontSize14___3VFV6 {
  font-size: 14px !important;
}
.fontSize16___3IuF0 {
  font-size: 16px !important;
}
.fontSize18___QW1zX {
  font-size: 18px !important;
}
.fontSize20___3p1g7 {
  font-size: 20px !important;
}
.fontSize24___3JGIX {
  font-size: 24px !important;
}
.eventName___1MXi4 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3jcky {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___upelA > tr > th,
.ant-table-tbody___G1bGO > tr.ant-table-row___v7302 > td,
.ant-table-summary___2FPl9 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___upelA > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2f7t2 .ant-table-thead___upelA > tr > th,
.table-portrait___2f7t2 .ant-table-tbody___G1bGO > tr.ant-table-row___v7302 > td,
.table-portrait___2f7t2 .ant-table-summary___2FPl9 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2f7t2 .bgLightGray___JbDVF td {
  background: #ebebeb;
}
.table-portrait___2f7t2 .bgLightBlue___2QBNj td {
  background: #b2cbf7;
}
.eventContent___39c2G .timeText___3sYsu {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___G1bGO > tr.bgLightGray___JbDVF:hover > td,
.ant-table-tbody___G1bGO > tr > td.ant-table-cell-row-hover___30SXO {
  background: #ebebeb;
}
.ant-table-tbody___G1bGO > tr.bgLightBlue___2QBNj:hover > td,
.ant-table-tbody___G1bGO > tr > td.ant-table-cell-row-hover___30SXO {
  background: #b2cbf7;
}
.ant-table-tbody___G1bGO > tr.bgWhite___uOIAx:hover > td,
.ant-table-tbody___G1bGO > tr > td.ant-table-cell-row-hover___30SXO {
  background: #ffffff;
}
.container___1525I {
  max-width: 1080px;
  margin: auto;
  padding: 45px 15px 90px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .container___1525I {
    padding: 45px 15px 220px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .container___1525I {
    padding: 45px 15px 110px;
  }
}
.container___1525I .mainRowItem___13rSu {
  padding: 50px 0;
}
@media screen and (max-width: 767px) {
  .container___1525I .mainRowItem___13rSu {
    padding-top: 20px;
  }
}
.container___1525I .mainRowItem___13rSu .title___1cmUJ {
  display: flex;
  align-items: center;
}
.container___1525I .mainRowItem___13rSu .title___1cmUJ .titleBorder___1KAqw {
  display: inline-block;
  width: 8px;
  height: 23px;
  background: #33c3c7;
  margin-right: 10px;
}
.container___1525I .mainRowItem___13rSu .title___1cmUJ .titleName___13Cvs {
  font-size: 24px;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .container___1525I .mainRowItem___13rSu .title___1cmUJ .titleName___13Cvs {
    font-size: 20px;
  }
}
.container___1525I .mainRowItem___13rSu .explainContent___1TekZ {
  padding: 10px 20px 50px;
  font-size: 16px;
}
.container___1525I .mainRowItem___13rSu .explainContent___1TekZ .describeAccountPersonalCanNotUse___13S6M {
  color: #9d9d9d;
  padding-top: 10px;
}
.container___1525I .mainRowItem___13rSu .groupImage___CiB-l {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.container___1525I .mainRowItem___13rSu .groupImage___CiB-l .between___3DEII {
  color: #33c3c7;
  padding: 0 30px;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .container___1525I .mainRowItem___13rSu .groupImage___CiB-l .between___3DEII {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .container___1525I .mainRowItem___13rSu .groupImage___CiB-l img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .container___1525I .mainRowItem___13rSu .groupImage___CiB-l img {
    padding: 0 20px;
  }
}
.container___1525I .ant-tabs-tab {
  font-size: 25px;
}
@media screen and (max-width: 767px) {
  .container___1525I .ant-tabs-tab {
    font-size: 20px;
  }
}
.container___1525I .href {
  border-radius: 15px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
  text-align: center;
}
