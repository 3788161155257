.loginZoomSuccess___3KOsP {
  padding: 100px 0;
}
.loginZoomSuccess___3KOsP h2 {
  text-align: center;
}
.loginZoomSuccess___3KOsP .btnBack___3alvW {
  margin-top: 30px;
  text-align: center;
  text-decoration: underline;
  display: block;
}
