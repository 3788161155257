@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3TZEC {
  display: flex;
  justify-content: center;
}
button.btn___3Mqxf {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3Mqxf {
    padding: 10px;
  }
}
button.btn___3Mqxf:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3Mqxf:focus {
  outline: none;
}
.btnWhite___1e6SL.btn___3Mqxf {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1e6SL.btn___3Mqxf:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1gqlJ.btn___3Mqxf,
.btnGreen___1gqlJ.btn___3Mqxf:hover,
.btnGreen___1gqlJ.btn___3Mqxf:active,
.btnGreen___1gqlJ.btn___3Mqxf:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___217kV {
  color: #fe0000;
}
.errorMessage___WEN1f {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___m6Fog {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3F6xF {
  display: none !important;
}
.m-auto___3C80g {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1zpwd {
  max-width: 120px !important;
}
.fc___2VmLf .fc-timegrid-col___1OHrw.fc-day-today___3QxdY {
  background-color: #ffffff;
}
.fc___2VmLf .fc-timegrid-slot___3XkUP {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2VmLf .fc-timegrid-slot___3XkUP {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3VNY5 .fc-direction-ltr___3ycPq .fc-timegrid-slot-label-frame___2B73r {
    font-size: 10px;
  }
  .bookingCalendar___3VNY5 .fc___2VmLf .fc-timegrid-slot-label-cushion___ZzkQA {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3VNY5 .fc___2VmLf .fc-scrollgrid-section-header___3J8Px .fc-scroller___1BI7- {
    overflow: unset !important;
  }
}
.ant-message-notice___3S_db {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___NckXC {
  width: 20px;
  height: 20px;
}
.bgTransparent___1hnZj {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3cCMZ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1jzmb {
  position: relative;
}
.inputCustomValue___1jzmb .ant-input___kycAB {
  padding-right: 50px;
}
.inputCustomValueText___27XCu {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___6dHw5 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___6dHw5 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___FbKbO .ant-radio-inner___16eTX {
  border-color: black !important ;
}
.ant-radio-checked___FbKbO .ant-radio-inner___16eTX:after {
  background-color: black;
}
.ant-radio___2rQHO:hover .ant-radio-inner___16eTX {
  border-color: black;
}
.ant-radio-checked___FbKbO .ant-radio-inner___16eTX:focus {
  border-color: black;
}
.modal___3vM6h {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3vM6h .close___2xarm {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3vM6h .header___2dg9Z {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3vM6h .header___2dg9Z p {
  margin: 0;
}
.modal___3vM6h .content___u6p1C {
  text-align: center;
  font-size: 14px;
}
.modal___3vM6h .content___u6p1C p {
  margin: 0;
}
.modal___3vM6h .actions___2ItvF {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3vM6h .actions___2ItvF .button___2XRks {
  margin-top: 10px;
}
.modal___3vM6h .actions___2ItvF:hover {
  color: #33c3c7;
}
.fc___2VmLf .fc-timegrid-slot-minor___2sMiG {
  border-top-style: none;
}
.fc___2VmLf .fc-timegrid-slot___3XkUP {
  height: 1.5em !important;
}
.messageError___Yg3gj .ant-message-custom-content___2I3S_ {
  display: flex;
  text-align: start;
}
.messageError___Yg3gj .ant-message-custom-content___2I3S_ span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3xzCa {
  background-color: #e6e4e4;
}
.notifyMessage___2Pqy_ .ant-message-notice-content___2OMFv {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2Pqy_ .ant-message-notice-content___2OMFv .anticon___w9HRw {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2Pqy_ .ant-message-notice-content___2OMFv span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1nvVx {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1nvVx .ant-notification-notice-message___33g6Y,
.notifyCustomize___1nvVx .ant-notification-notice-close___3ocEE,
.notifyCustomize___1nvVx .ant-notification-notice-icon___3Nyxe {
  color: white;
}
.notifyCustomize___1nvVx .ant-notification-notice-message___33g6Y {
  margin-left: 35px;
}
.notifyCustomize___1nvVx p {
  margin-bottom: 0;
}
.notifyCustomize___1nvVx p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1cnQd {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1cnQd .ant-notification-notice-message___33g6Y {
  margin-left: 35px;
}
.notifyAlertDuplicate___1cnQd p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1cnQd p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1k7Nv {
  width: 434px !important;
}
.handleSubmitVote___1k7Nv .ant-btn-primary___1Us-A {
  background: #59c3c7;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q .ant-modal-confirm-body___yEM6d {
  display: flex !important;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q .ant-modal-confirm-body___yEM6d .anticon___w9HRw {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q .ant-modal-confirm-btns___1Xgma button {
  font-weight: bold;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q .ant-modal-confirm-btns___1Xgma button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q .ant-modal-confirm-btns___1Xgma button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1k7Nv .ant-modal-body___3bS4Q .ant-modal-confirm-btns___1Xgma .ant-btn-primary___1Us-A {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2gG7N {
  margin-bottom: 0;
}
.mt-0___h8j3J {
  margin-top: 0;
}
.tooltipFormat___2Hv03 p {
  margin-bottom: 0;
}
.bgWhite___3UtJX {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___RY1QR {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___RY1QR:hover,
.bgPrimaryBlue___RY1QR:focus,
.bgPrimaryBlue___RY1QR:active {
  background: #4f7ac7;
}
.bgDarkBlue___1PU8N {
  background-color: #023768 !important;
}
.bgDarkBlue___1PU8N:hover,
.bgDarkBlue___1PU8N:focus,
.bgDarkBlue___1PU8N:active {
  background: #194d7d;
}
.bgGreyBlue___ZhIlA {
  background-color: #ebf0f9;
}
.bgLightBlue___14m_1 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___13ZwI {
  background-color: #e4453a;
}
.bgLightRed___13ZwI:hover,
.bgLightRed___13ZwI:focus,
.bgLightRed___13ZwI:active {
  background: #e17871;
}
.bgLightYellow___MH9fw {
  background-color: #ffd603;
}
.bgDarkGray___3F5-m {
  background-color: #333333;
}
.bgMediumGray___1Nrl2 {
  background-color: #a2a2a2;
}
.bgLightGray___18R8n {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2kD9o {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___EDfdy {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2WcLa {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3p0rP {
  color: #3368c7 !important;
}
.textDarkBlue___NvxtQ {
  color: #023768 !important;
}
.textLightBlue___W0rVg {
  color: #b2cbf7;
}
.textDarkGray___16wiO {
  color: #333333 !important;
}
.textMediumGray___3nX_7 {
  color: #a2a2a2 !important;
}
.textLightGray___3pXt- {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___16DjS {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2WIzU {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___rB1jC {
  border-radius: 5px !important;
}
.rounded-xs___3lP9y {
  border-radius: 10px !important;
}
.rounded-sm___KmLIy {
  border-radius: 15px !important;
}
.rounded-md___5bR_5 {
  border-radius: 20px !important;
}
.rounded-lg___3g3vq {
  border-radius: 25px !important;
}
.rounded-full___2eWkS {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2gyWT {
  margin: 0;
}
.mt-2___3mv0E {
  margin-top: 0.5rem;
}
.my-2___1CVi4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2piAL {
  padding: 0;
}
.px-1___2OsU_ {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___28min {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1TcoV {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2DAs1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3A6wv {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1xkSS {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1v6mO {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1dC3Y {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___35NPs {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___iqeai {
  padding-bottom: 20px;
}
.h-full___1l-hu {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___DiMNs {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2nKPl {
  font-weight: bold !important;
}
.fontWeight400___2kI-m {
  font-weight: 400 !important;
}
.fontWeight500___nRgJC {
  font-weight: 500 !important;
}
.fontWeight600___2ww7h {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3cl44 {
  font-size: 12px !important;
}
.fontSize14___2oNSu {
  font-size: 14px !important;
}
.fontSize16___1crA6 {
  font-size: 16px !important;
}
.fontSize18___2RIBo {
  font-size: 18px !important;
}
.fontSize20___3M6XA {
  font-size: 20px !important;
}
.fontSize24___3pTLl {
  font-size: 24px !important;
}
.eventName___GFZe7 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___30RLT {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3Yii6 > tr > th,
.ant-table-tbody___2mbJ4 > tr.ant-table-row___SUqra > td,
.ant-table-summary___1z7P8 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3Yii6 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___25mVn .ant-table-thead___3Yii6 > tr > th,
.table-portrait___25mVn .ant-table-tbody___2mbJ4 > tr.ant-table-row___SUqra > td,
.table-portrait___25mVn .ant-table-summary___1z7P8 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___25mVn .bgLightGray___18R8n td {
  background: #ebebeb;
}
.table-portrait___25mVn .bgLightBlue___14m_1 td {
  background: #b2cbf7;
}
.eventContent___2SX_t .timeText___3aefv {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2mbJ4 > tr.bgLightGray___18R8n:hover > td,
.ant-table-tbody___2mbJ4 > tr > td.ant-table-cell-row-hover___2JZm_ {
  background: #ebebeb;
}
.ant-table-tbody___2mbJ4 > tr.bgLightBlue___14m_1:hover > td,
.ant-table-tbody___2mbJ4 > tr > td.ant-table-cell-row-hover___2JZm_ {
  background: #b2cbf7;
}
.ant-table-tbody___2mbJ4 > tr.bgWhite___3UtJX:hover > td,
.ant-table-tbody___2mbJ4 > tr > td.ant-table-cell-row-hover___2JZm_ {
  background: #ffffff;
}
.confirmContractDetail___10oqN {
  padding: 0 15px 165px;
  max-width: 1080px;
  width: 100%;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN {
    padding: 0 15px 210px;
  }
}
.confirmContractDetail___10oqN .paymentTitle___W2sU- {
  margin: 65px 0px 55px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .paymentTitle___W2sU- {
    margin: 30px 0;
  }
}
.confirmContractDetail___10oqN .paymentTitle___W2sU- .paymentTitleText___TOBFi {
  color: #3c3c3c;
  font-size: 45px;
  font-weight: 500;
  display: inline-block;
}
.confirmContractDetail___10oqN .paymentTitle___W2sU- .confirmContractTitle___2s8U4 {
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .paymentTitle___W2sU- .confirmContractTitle___2s8U4 {
    font-size: 20px;
  }
}
.confirmContractDetail___10oqN .backButton___1gs3k .cardAsterisk___3RH6v {
  vertical-align: sub;
}
.confirmContractDetail___10oqN .backButton___1gs3k .changeBtn___U8Xt1 {
  height: 42px;
  border: 3px solid #33c3c7;
  font-size: 16px;
  border-radius: 15px;
  background-color: #ffffff;
  color: #414141;
  margin: 0 30px;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .backButton___1gs3k .changeBtn___U8Xt1 {
    height: 35px;
    font-size: 13px;
    margin: 0px 10px;
  }
}
.confirmContractDetail___10oqN .titleIcon___1tF7W {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.confirmContractDetail___10oqN .titleIcon___1tF7W .bolderColIcon___2JXgM {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 13px;
  margin-right: 6px;
}
.confirmContractDetail___10oqN .titleIcon___1tF7W .normalColIcon___2lIoW {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 5px;
}
.confirmContractDetail___10oqN .description___3oTTi {
  font-size: 23px;
  color: #3c3c3c;
  margin-bottom: 50px;
  margin-left: 40px;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .description___3oTTi {
    margin-left: 0;
    margin-bottom: 30px;
  }
}
.confirmContractDetail___10oqN .confirmContractDescript___Dw19F {
  font-size: 14px;
}
.confirmContractDetail___10oqN .table___7sUpi {
  margin: 30px 0;
}
.confirmContractDetail___10oqN .tr___1dDFx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmContractDetail___10oqN .tr___1dDFx {
  font-size: 23px;
  color: #3d3d3d;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .tr___1dDFx {
    font-size: 15px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .confirmContractDetail___10oqN .tr___1dDFx {
    font-size: 18px;
  }
}
.confirmContractDetail___10oqN .tr___1dDFx .td___1ZYoK {
  border-right: 2px solid #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  padding: 15px 50px;
  flex-basis: 50%;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .tr___1dDFx .td___1ZYoK {
    padding: 5px 10px;
    flex-basis: 50%;
    min-height: 60px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .confirmContractDetail___10oqN .tr___1dDFx .td___1ZYoK {
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 15px;
  }
}
.confirmContractDetail___10oqN .tr___1dDFx .td___1ZYoK:last-child {
  border-right: 0px;
}
.confirmContractDetail___10oqN .tr___1dDFx:last-child .td___1ZYoK {
  border-bottom: 0;
}
.confirmContractDetail___10oqN .confirmContractTr___2vIPW {
  font-size: 16px;
}
.confirmContractDetail___10oqN .confirmContractTrOther___q3hNZ {
  height: 69px;
}
.confirmContractDetail___10oqN .confirmContractTrOther___q3hNZ .td___1ZYoK {
  padding: 0 50px;
  height: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .confirmContractTrOther___q3hNZ .td___1ZYoK {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .confirmContractDetail___10oqN .confirmContractTrOther___q3hNZ .backButton___1gs3k {
    justify-content: space-between;
  }
  .confirmContractDetail___10oqN .confirmContractTrOther___q3hNZ .backButton___1gs3k .changeBtn___U8Xt1 {
    margin: 0 0 0 5px;
    padding: 0 10px;
  }
}
.confirmContractDetail___10oqN .tbody___1aE7r {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.confirmContractDetail___10oqN .change___2UMBD {
  font-size: 19px;
  border: 2px solid #33c3c7;
  border-radius: 10px;
  padding: 5px 22px;
  margin-left: 35px;
}
.confirmContractDetail___10oqN .btnGroup___3TZEC {
  margin-top: 50px;
}
.confirmContractDetail___10oqN .btnGroup___3TZEC button:not(:last-child) {
  margin-right: 30px;
}
.confirmContractDetail___10oqN .btn {
  font-size: 16px;
}
