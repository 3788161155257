@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2uri_ {
  display: flex;
  justify-content: center;
}
button.btn___2MT3b {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2MT3b {
    padding: 10px;
  }
}
button.btn___2MT3b:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2MT3b:focus {
  outline: none;
}
.btnWhite___HdoxZ.btn___2MT3b {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___HdoxZ.btn___2MT3b:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3WBWQ.btn___2MT3b,
.btnGreen___3WBWQ.btn___2MT3b:hover,
.btnGreen___3WBWQ.btn___2MT3b:active,
.btnGreen___3WBWQ.btn___2MT3b:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1lIl0 {
  color: #fe0000;
}
.errorMessage___2k729 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3lh_b {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2662w {
  display: none !important;
}
.m-auto___31z2y {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3qcK7 {
  max-width: 120px !important;
}
.fc___1qR-w .fc-timegrid-col___2obwE.fc-day-today___2cBm2 {
  background-color: #ffffff;
}
.fc___1qR-w .fc-timegrid-slot___2pX7p {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1qR-w .fc-timegrid-slot___2pX7p {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3Mn8K .fc-direction-ltr___2uqA8 .fc-timegrid-slot-label-frame___6NudV {
    font-size: 10px;
  }
  .bookingCalendar___3Mn8K .fc___1qR-w .fc-timegrid-slot-label-cushion___13ESJ {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3Mn8K .fc___1qR-w .fc-scrollgrid-section-header___1M9rg .fc-scroller___25Aby {
    overflow: unset !important;
  }
}
.ant-message-notice___1IPyS {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___uIYeS {
  width: 20px;
  height: 20px;
}
.bgTransparent___jbdUB {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___164XQ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___rE9tC {
  position: relative;
}
.inputCustomValue___rE9tC .ant-input___3PaTg {
  padding-right: 50px;
}
.inputCustomValueText___2KwhC {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___25CR7 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___25CR7 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___o33xm .ant-radio-inner___2aG-Y {
  border-color: black !important ;
}
.ant-radio-checked___o33xm .ant-radio-inner___2aG-Y:after {
  background-color: black;
}
.ant-radio___2Pijp:hover .ant-radio-inner___2aG-Y {
  border-color: black;
}
.ant-radio-checked___o33xm .ant-radio-inner___2aG-Y:focus {
  border-color: black;
}
.modal___2uJSu {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2uJSu .close___3pd8f {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2uJSu .header___3Vr4O {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2uJSu .header___3Vr4O p {
  margin: 0;
}
.modal___2uJSu .content___RwHBs {
  text-align: center;
  font-size: 14px;
}
.modal___2uJSu .content___RwHBs p {
  margin: 0;
}
.modal___2uJSu .actions___3yGF9 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2uJSu .actions___3yGF9 .button___zlXI0 {
  margin-top: 10px;
}
.modal___2uJSu .actions___3yGF9:hover {
  color: #33c3c7;
}
.fc___1qR-w .fc-timegrid-slot-minor___1jppl {
  border-top-style: none;
}
.fc___1qR-w .fc-timegrid-slot___2pX7p {
  height: 1.5em !important;
}
.messageError___3SzZQ .ant-message-custom-content___1hjU6 {
  display: flex;
  text-align: start;
}
.messageError___3SzZQ .ant-message-custom-content___1hjU6 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___365pa {
  background-color: #e6e4e4;
}
.notifyMessage___3JbXd .ant-message-notice-content___1153i {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3JbXd .ant-message-notice-content___1153i .anticon___3CwMM {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3JbXd .ant-message-notice-content___1153i span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1G_HP {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1G_HP .ant-notification-notice-message___3jN7l,
.notifyCustomize___1G_HP .ant-notification-notice-close___2mfXk,
.notifyCustomize___1G_HP .ant-notification-notice-icon___2tevc {
  color: white;
}
.notifyCustomize___1G_HP .ant-notification-notice-message___3jN7l {
  margin-left: 35px;
}
.notifyCustomize___1G_HP p {
  margin-bottom: 0;
}
.notifyCustomize___1G_HP p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1FWuS {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1FWuS .ant-notification-notice-message___3jN7l {
  margin-left: 35px;
}
.notifyAlertDuplicate___1FWuS p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1FWuS p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2B5N_ {
  width: 434px !important;
}
.handleSubmitVote___2B5N_ .ant-btn-primary___19nDD {
  background: #59c3c7;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr .ant-modal-confirm-body___1CjCY {
  display: flex !important;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr .ant-modal-confirm-body___1CjCY .anticon___3CwMM {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr .ant-modal-confirm-btns___3LA8- button {
  font-weight: bold;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr .ant-modal-confirm-btns___3LA8- button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr .ant-modal-confirm-btns___3LA8- button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2B5N_ .ant-modal-body___3kFTr .ant-modal-confirm-btns___3LA8- .ant-btn-primary___19nDD {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___29yw1 {
  margin-bottom: 0;
}
.mt-0___1hCSi {
  margin-top: 0;
}
.tooltipFormat___1hka6 p {
  margin-bottom: 0;
}
.bgWhite___29MD2 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1I5-H {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1I5-H:hover,
.bgPrimaryBlue___1I5-H:focus,
.bgPrimaryBlue___1I5-H:active {
  background: #4f7ac7;
}
.bgDarkBlue___3bdmx {
  background-color: #023768 !important;
}
.bgDarkBlue___3bdmx:hover,
.bgDarkBlue___3bdmx:focus,
.bgDarkBlue___3bdmx:active {
  background: #194d7d;
}
.bgGreyBlue___89MSb {
  background-color: #ebf0f9;
}
.bgLightBlue___303xe {
  background-color: #b2cbf7 !important;
}
.bgLightRed___scG0- {
  background-color: #e4453a;
}
.bgLightRed___scG0-:hover,
.bgLightRed___scG0-:focus,
.bgLightRed___scG0-:active {
  background: #e17871;
}
.bgLightYellow___23eEv {
  background-color: #ffd603;
}
.bgDarkGray___YKwz- {
  background-color: #333333;
}
.bgMediumGray___1BeT5 {
  background-color: #a2a2a2;
}
.bgLightGray___2PeD- {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___35Prj {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___cs4gK {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1RNmw {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2wn3S {
  color: #3368c7 !important;
}
.textDarkBlue___YhATg {
  color: #023768 !important;
}
.textLightBlue___1TC3M {
  color: #b2cbf7;
}
.textDarkGray___1yIre {
  color: #333333 !important;
}
.textMediumGray___pC1Ur {
  color: #a2a2a2 !important;
}
.textLightGray___2dqC1 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2IEbw {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3G80F {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___rDTKM {
  border-radius: 5px !important;
}
.rounded-xs___12CgT {
  border-radius: 10px !important;
}
.rounded-sm___2MN6R {
  border-radius: 15px !important;
}
.rounded-md___vdNzz {
  border-radius: 20px !important;
}
.rounded-lg___b2KLd {
  border-radius: 25px !important;
}
.rounded-full___9ggxL {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3DFZF {
  margin: 0;
}
.mt-2___1j3N- {
  margin-top: 0.5rem;
}
.my-2___2dsFR {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___X5V6q {
  padding: 0;
}
.px-1___36yw4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___29nDn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2PJEA {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1Wuzi {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___Vcj6z {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1kq54 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1lA5n {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___18jkW {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___365qJ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2DyTV {
  padding-bottom: 20px;
}
.h-full___3Uyy3 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___Dn37Q {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold____GhQl {
  font-weight: bold !important;
}
.fontWeight400___3ozU0 {
  font-weight: 400 !important;
}
.fontWeight500___3daHd {
  font-weight: 500 !important;
}
.fontWeight600___sTTiX {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1Z8Qo {
  font-size: 12px !important;
}
.fontSize14___3AokT {
  font-size: 14px !important;
}
.fontSize16___2SSKC {
  font-size: 16px !important;
}
.fontSize18___2pT3- {
  font-size: 18px !important;
}
.fontSize20___2ta0K {
  font-size: 20px !important;
}
.fontSize24___3BtV_ {
  font-size: 24px !important;
}
.eventName___1jVEL {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___cOg0V {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1GQhM > tr > th,
.ant-table-tbody___15r7q > tr.ant-table-row___3vNm0 > td,
.ant-table-summary___3fYTB tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1GQhM > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3EpIi .ant-table-thead___1GQhM > tr > th,
.table-portrait___3EpIi .ant-table-tbody___15r7q > tr.ant-table-row___3vNm0 > td,
.table-portrait___3EpIi .ant-table-summary___3fYTB tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3EpIi .bgLightGray___2PeD- td {
  background: #ebebeb;
}
.table-portrait___3EpIi .bgLightBlue___303xe td {
  background: #b2cbf7;
}
.eventContent___3EKmN .timeText___209CQ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___15r7q > tr.bgLightGray___2PeD-:hover > td,
.ant-table-tbody___15r7q > tr > td.ant-table-cell-row-hover___3XO3w {
  background: #ebebeb;
}
.ant-table-tbody___15r7q > tr.bgLightBlue___303xe:hover > td,
.ant-table-tbody___15r7q > tr > td.ant-table-cell-row-hover___3XO3w {
  background: #b2cbf7;
}
.ant-table-tbody___15r7q > tr.bgWhite___29MD2:hover > td,
.ant-table-tbody___15r7q > tr > td.ant-table-cell-row-hover___3XO3w {
  background: #ffffff;
}
.containerFirst___Ghw6x {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 45px 15px 10px 15px;
}
.containerFirst___Ghw6x .bigTitle___1kEj- {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  position: relative;
  padding-left: 25px;
  font-weight: bold;
  margin-bottom: 8px;
}
.containerFirst___Ghw6x .bigTitle___1kEj- :before {
  content: '';
  width: 8px;
  height: 36px;
  background-color: #23c5cb;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.containerFirst___Ghw6x .bigTitle___1kEj- :after {
  content: '';
  width: 3px;
  height: 36px;
  background-color: #23c5cb;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}
.containerFirst___Ghw6x .smallTitle____e41u {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.container___3PgRs {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 45px 15px;
}
.container___3PgRs .columnLeft___rSGew {
  padding-right: 100px;
  border-right: 1px solid #707070;
}
@media (max-width: 991px) {
  .container___3PgRs .columnLeft___rSGew {
    padding-left: 20px;
    padding-right: 20px;
    border-right: none;
    margin-bottom: 40px;
    width: 100%;
  }
}
.container___3PgRs .columnRight___3nBPk {
  padding-left: 100px;
}
@media (max-width: 991px) {
  .container___3PgRs .columnRight___3nBPk {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container___3PgRs .step___RPFIJ .sectionText___BcVxx {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  color: #ffffff;
  background: #969696;
  padding: 4px 16px;
  margin-bottom: 22px;
}
.container___3PgRs .step___RPFIJ .stepNumer___102sq {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #00c6c9;
  position: relative;
  padding-left: 16px;
  margin-bottom: 8px;
  margin-left: 8px;
}
.container___3PgRs .step___RPFIJ .stepNumer___102sq :before {
  content: '';
  width: 6px;
  height: 24px;
  background-color: #23c5cb;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.container___3PgRs .step___RPFIJ .stepNumer___102sq :after {
  content: '';
  width: 2px;
  height: 24px;
  background-color: #23c5cb;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
.container___3PgRs .step___RPFIJ .stepNumer___102sq span {
  margin-left: 2px;
}
.container___3PgRs .step___RPFIJ .stepText___1YTAV {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 8px;
  color: #000000;
}
.container___3PgRs .step___RPFIJ .stepText___1YTAV span {
  color: #00c6c9;
}
.container___3PgRs .step___RPFIJ .stepText___1YTAV b {
  margin-bottom: 4px;
}
.container___3PgRs .step___RPFIJ .stepText2___OHvG5 {
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 8px;
  color: #000000;
}
.container___3PgRs .step___RPFIJ .pl24___JXHr9 {
  padding-left: 24px;
}
.container___3PgRs .step___RPFIJ .pl4___S-4yT {
  padding-left: 4px;
}
.container___3PgRs .step___RPFIJ .textAreaCptNav___1vbRb {
  height: 130px;
  border-radius: 10px;
}
.container___3PgRs .step___RPFIJ .textAreaCptNav___1vbRb textarea {
  margin-bottom: 30px;
}
.container___3PgRs .step___RPFIJ .textAreaCptNav___1vbRb,
.container___3PgRs .step___RPFIJ .stepInput___1WOYO {
  border: 2px solid #707070;
  margin-bottom: 30px;
}
.container___3PgRs .step___RPFIJ .textAreaCptNav___1vbRb :hover,
.container___3PgRs .step___RPFIJ .stepInput___1WOYO :hover {
  border: 1px solid #707070 !important;
}
.container___3PgRs .step___RPFIJ .stepAction___34z8w {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container___3PgRs .step___RPFIJ .stepAction___34z8w button {
  color: #3c3c3c;
  font-family: Yu Gothic UI;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  padding: 4px 36px;
  border: 2px solid #33c3c7;
  border-radius: 5px;
}
.container___3PgRs .step___RPFIJ .stepAction___34z8w button.active___1m4zp {
  color: white;
  background: #33c3c7;
}
@media (min-width: 990px) {
  .container___3PgRs .step___RPFIJ .displayInlineBlock___1vgeo {
    display: inline-block;
  }
}
.mgt60___37Z_q {
  margin-top: 60px;
}
.mgt20___3eZeR {
  margin-top: 20px;
}
.beforeFieldIcon___1NWpx {
  display: inline-block;
  font-size: 16px;
  width: 7px;
  min-width: 7px;
  height: 17px;
  margin-right: 6px;
  background-color: #23c5cb;
}
.pdf25___DL2Il {
  padding-left: 25px;
}
.mgt8___L0tOg {
  margin-top: 8px;
}
