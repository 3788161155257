@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3uHYi {
  display: flex;
  justify-content: center;
}
button.btn___3exYd {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3exYd {
    padding: 10px;
  }
}
button.btn___3exYd:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3exYd:focus {
  outline: none;
}
.btnWhite___Sy4XX.btn___3exYd {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___Sy4XX.btn___3exYd:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2D5oa.btn___3exYd,
.btnGreen___2D5oa.btn___3exYd:hover,
.btnGreen___2D5oa.btn___3exYd:active,
.btnGreen___2D5oa.btn___3exYd:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2Da87 {
  color: #fe0000;
}
.errorMessage___2kBKI {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3UNmD {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2ZQ-w {
  display: none !important;
}
.m-auto___2LhsZ {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3A6lB {
  max-width: 120px !important;
}
.fc___3VK0U .fc-timegrid-col___OJFi_.fc-day-today___gUZlg {
  background-color: #ffffff;
}
.fc___3VK0U .fc-timegrid-slot___1MJbb {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3VK0U .fc-timegrid-slot___1MJbb {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___233ji .fc-direction-ltr___2BWMb .fc-timegrid-slot-label-frame___3lFRF {
    font-size: 10px;
  }
  .bookingCalendar___233ji .fc___3VK0U .fc-timegrid-slot-label-cushion___zDWWu {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___233ji .fc___3VK0U .fc-scrollgrid-section-header___-yIdz .fc-scroller___3-U4Y {
    overflow: unset !important;
  }
}
.ant-message-notice___1HZi7 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2JJpw {
  width: 20px;
  height: 20px;
}
.bgTransparent___3rw4C {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1DgYS {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___42okR {
  position: relative;
}
.inputCustomValue___42okR .ant-input____3ZbU {
  padding-right: 50px;
}
.inputCustomValueText___1rtZV {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3YF9W .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3YF9W .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3tcpr .ant-radio-inner___1SYYs {
  border-color: black !important ;
}
.ant-radio-checked___3tcpr .ant-radio-inner___1SYYs:after {
  background-color: black;
}
.ant-radio___2O43b:hover .ant-radio-inner___1SYYs {
  border-color: black;
}
.ant-radio-checked___3tcpr .ant-radio-inner___1SYYs:focus {
  border-color: black;
}
.modal___37_v5 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___37_v5 .close___dCc6j {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___37_v5 .header___PL9SC {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___37_v5 .header___PL9SC p {
  margin: 0;
}
.modal___37_v5 .content___3Wkbr {
  text-align: center;
  font-size: 14px;
}
.modal___37_v5 .content___3Wkbr p {
  margin: 0;
}
.modal___37_v5 .actions___3IpZb {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___37_v5 .actions___3IpZb .button___2BgiQ {
  margin-top: 10px;
}
.modal___37_v5 .actions___3IpZb:hover {
  color: #33c3c7;
}
.fc___3VK0U .fc-timegrid-slot-minor___1RMlC {
  border-top-style: none;
}
.fc___3VK0U .fc-timegrid-slot___1MJbb {
  height: 1.5em !important;
}
.messageError___2SWbc .ant-message-custom-content___3JSF8 {
  display: flex;
  text-align: start;
}
.messageError___2SWbc .ant-message-custom-content___3JSF8 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1CHwq {
  background-color: #e6e4e4;
}
.notifyMessage___2m9wk .ant-message-notice-content___uAbKB {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2m9wk .ant-message-notice-content___uAbKB .anticon___3WnsQ {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2m9wk .ant-message-notice-content___uAbKB span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2t-Rr {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2t-Rr .ant-notification-notice-message___KKjBc,
.notifyCustomize___2t-Rr .ant-notification-notice-close___1BzWD,
.notifyCustomize___2t-Rr .ant-notification-notice-icon___eS-TR {
  color: white;
}
.notifyCustomize___2t-Rr .ant-notification-notice-message___KKjBc {
  margin-left: 35px;
}
.notifyCustomize___2t-Rr p {
  margin-bottom: 0;
}
.notifyCustomize___2t-Rr p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3njex {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3njex .ant-notification-notice-message___KKjBc {
  margin-left: 35px;
}
.notifyAlertDuplicate___3njex p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3njex p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3gtK- {
  width: 434px !important;
}
.handleSubmitVote___3gtK- .ant-btn-primary___2K3qH {
  background: #59c3c7;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T .ant-modal-confirm-body___1AJFb {
  display: flex !important;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T .ant-modal-confirm-body___1AJFb .anticon___3WnsQ {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T .ant-modal-confirm-btns___27Oom button {
  font-weight: bold;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T .ant-modal-confirm-btns___27Oom button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T .ant-modal-confirm-btns___27Oom button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3gtK- .ant-modal-body___sA-9T .ant-modal-confirm-btns___27Oom .ant-btn-primary___2K3qH {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2pEmy {
  margin-bottom: 0;
}
.mt-0___3bOqU {
  margin-top: 0;
}
.tooltipFormat___2AMen p {
  margin-bottom: 0;
}
.bgWhite___3FbuO {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3vxt4 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3vxt4:hover,
.bgPrimaryBlue___3vxt4:focus,
.bgPrimaryBlue___3vxt4:active {
  background: #4f7ac7;
}
.bgDarkBlue___FXoKU {
  background-color: #023768 !important;
}
.bgDarkBlue___FXoKU:hover,
.bgDarkBlue___FXoKU:focus,
.bgDarkBlue___FXoKU:active {
  background: #194d7d;
}
.bgGreyBlue___327vx {
  background-color: #ebf0f9;
}
.bgLightBlue___4HJU7 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2Qhq- {
  background-color: #e4453a;
}
.bgLightRed___2Qhq-:hover,
.bgLightRed___2Qhq-:focus,
.bgLightRed___2Qhq-:active {
  background: #e17871;
}
.bgLightYellow___3lqDy {
  background-color: #ffd603;
}
.bgDarkGray___3EJpr {
  background-color: #333333;
}
.bgMediumGray___qvx22 {
  background-color: #a2a2a2;
}
.bgLightGray___2gwOk {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___gj8Bn {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___34ya2 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3lKvV {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___28L_x {
  color: #3368c7 !important;
}
.textDarkBlue___-GbG8 {
  color: #023768 !important;
}
.textLightBlue___3u33g {
  color: #b2cbf7;
}
.textDarkGray___xiWRD {
  color: #333333 !important;
}
.textMediumGray___3j4l7 {
  color: #a2a2a2 !important;
}
.textLightGray___Dc1P0 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2fES0 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1p6zc {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3M8R6 {
  border-radius: 5px !important;
}
.rounded-xs___-tnXN {
  border-radius: 10px !important;
}
.rounded-sm___2IioI {
  border-radius: 15px !important;
}
.rounded-md___1ELVq {
  border-radius: 20px !important;
}
.rounded-lg___2DNwh {
  border-radius: 25px !important;
}
.rounded-full___2cKIH {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1aILl {
  margin: 0;
}
.mt-2___e9IWk {
  margin-top: 0.5rem;
}
.my-2___16jTt {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___VvLFa {
  padding: 0;
}
.px-1___35-AI {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1kX6z {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2tqkc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3JccA {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___wdw86 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3qX69 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3fEZx {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2iS-G {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___eFHlq {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2j5KG {
  padding-bottom: 20px;
}
.h-full___2_PFd {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___LgRDv {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3nAAW {
  font-weight: bold !important;
}
.fontWeight400___20a3a {
  font-weight: 400 !important;
}
.fontWeight500___Qzs5n {
  font-weight: 500 !important;
}
.fontWeight600___1fk0d {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3wwQB {
  font-size: 12px !important;
}
.fontSize14___W3Ps1 {
  font-size: 14px !important;
}
.fontSize16___1wuE7 {
  font-size: 16px !important;
}
.fontSize18___3g-NU {
  font-size: 18px !important;
}
.fontSize20___3WSYa {
  font-size: 20px !important;
}
.fontSize24___1i2iO {
  font-size: 24px !important;
}
.eventName___2wGMm {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___349vM {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1udVk > tr > th,
.ant-table-tbody___1OO1Q > tr.ant-table-row___1l7St > td,
.ant-table-summary___2ZCvn tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1udVk > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2xv7T .ant-table-thead___1udVk > tr > th,
.table-portrait___2xv7T .ant-table-tbody___1OO1Q > tr.ant-table-row___1l7St > td,
.table-portrait___2xv7T .ant-table-summary___2ZCvn tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2xv7T .bgLightGray___2gwOk td {
  background: #ebebeb;
}
.table-portrait___2xv7T .bgLightBlue___4HJU7 td {
  background: #b2cbf7;
}
.eventContent___txZMC .timeText___wT03g {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1OO1Q > tr.bgLightGray___2gwOk:hover > td,
.ant-table-tbody___1OO1Q > tr > td.ant-table-cell-row-hover___13jgt {
  background: #ebebeb;
}
.ant-table-tbody___1OO1Q > tr.bgLightBlue___4HJU7:hover > td,
.ant-table-tbody___1OO1Q > tr > td.ant-table-cell-row-hover___13jgt {
  background: #b2cbf7;
}
.ant-table-tbody___1OO1Q > tr.bgWhite___3FbuO:hover > td,
.ant-table-tbody___1OO1Q > tr > td.ant-table-cell-row-hover___13jgt {
  background: #ffffff;
}
.termOfUser___1Iz2L .content___3Wkbr {
  padding: 75px 0 65px 0;
  text-align: justify;
}
@media screen and (max-width: 767px) {
  .termOfUser___1Iz2L .content___3Wkbr {
    padding: 30px 10px;
  }
}
.termOfUser___1Iz2L .content___3Wkbr h1 {
  width: 100%;
  text-align: center;
  margin: auto;
  font-weight: bold;
}
.termOfUser___1Iz2L .content___3Wkbr h2 {
  font-size: 40px;
  letter-spacing: 0px;
  color: #161615;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .termOfUser___1Iz2L .content___3Wkbr h2 {
    font-size: 30px;
  }
}
.termOfUser___1Iz2L .content___3Wkbr .title___11CYE {
  font-size: 20px;
  letter-spacing: 0px;
  color: #161615;
  margin-bottom: 10px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .termOfUser___1Iz2L .content___3Wkbr .title___11CYE {
    font-size: 18px;
  }
}
.termOfUser___1Iz2L .content___3Wkbr p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #161615;
  margin-bottom: 30px;
  text-align: justify;
  text-justify: inter-word;
}
@media screen and (max-width: 767px) {
  .termOfUser___1Iz2L .content___3Wkbr p {
    font-size: 14px;
  }
}
.termOfUser___1Iz2L .content___3Wkbr .termOfUserFooter___3ihJT {
  width: 100%;
}
.termOfUser___1Iz2L .link___1Qp7W {
  color: #038b8f;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #3368c7;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuLeft___JG4Rk {
  width: 20%;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  border: none;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuLeft___JG4Rk .backURL___3zJVm {
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuLeft___JG4Rk .backURL___3zJVm img {
  width: 100%;
  height: auto;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuLeft___JG4Rk .backURL___3zJVm .itemName___MX022 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuLeft___JG4Rk.bgBackBtn___1Wnau {
  background: rgba(255, 255, 255, 0.1);
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuLeft___JG4Rk span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerTitle___2E1vj {
  width: 60%;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  color: #333;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuRight___1HTUB {
  width: 20%;
  display: flex;
  justify-content: right;
  align-items: center;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuRight___1HTUB .backURL___3zJVm {
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuRight___1HTUB .backURL___3zJVm img {
  width: 100%;
  height: auto;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuRight___1HTUB .backURL___3zJVm span {
  padding-left: 5px;
}
.termOfUser___1Iz2L .mainHeader___1zvCR .flexHeader___27Cb8 .headerMenuRight___1HTUB .backURL___3zJVm .itemName___MX022 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
