@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3neEn {
  display: flex;
  justify-content: center;
}
button.btn___1Oy2R {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1Oy2R {
    padding: 10px;
  }
}
button.btn___1Oy2R:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1Oy2R:focus {
  outline: none;
}
.btnWhite___2mAUu.btn___1Oy2R {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2mAUu.btn___1Oy2R:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2Dg5p.btn___1Oy2R,
.btnGreen___2Dg5p.btn___1Oy2R:hover,
.btnGreen___2Dg5p.btn___1Oy2R:active,
.btnGreen___2Dg5p.btn___1Oy2R:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___7AmWE {
  color: #fe0000;
}
.errorMessage___35A4N {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___26e9Q {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___qSZV5 {
  display: none !important;
}
.m-auto___2qbJB {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2r_7b {
  max-width: 120px !important;
}
.fc___1lQjF .fc-timegrid-col___3MUyf.fc-day-today___1rII2 {
  background-color: #ffffff;
}
.fc___1lQjF .fc-timegrid-slot___d4LwC {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1lQjF .fc-timegrid-slot___d4LwC {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___CGPye .fc-direction-ltr___3xrqQ .fc-timegrid-slot-label-frame___MW7VK {
    font-size: 10px;
  }
  .bookingCalendar___CGPye .fc___1lQjF .fc-timegrid-slot-label-cushion___1PzLF {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___CGPye .fc___1lQjF .fc-scrollgrid-section-header___2LuY4 .fc-scroller___38ltz {
    overflow: unset !important;
  }
}
.ant-message-notice___2a2Cg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___xpUlT {
  width: 20px;
  height: 20px;
}
.bgTransparent___2YbLm {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___waKUj {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2QSqi {
  position: relative;
}
.inputCustomValue___2QSqi .ant-input___qKzbb {
  padding-right: 50px;
}
.inputCustomValueText___Ll8ep {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1nczB .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1nczB .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___FVMDX .ant-radio-inner___1Lr-Y {
  border-color: black !important ;
}
.ant-radio-checked___FVMDX .ant-radio-inner___1Lr-Y:after {
  background-color: black;
}
.ant-radio___1hkZ3:hover .ant-radio-inner___1Lr-Y {
  border-color: black;
}
.ant-radio-checked___FVMDX .ant-radio-inner___1Lr-Y:focus {
  border-color: black;
}
.modal___wJkna {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___wJkna .close___111Hq {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___wJkna .header___1BT99 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___wJkna .header___1BT99 p {
  margin: 0;
}
.modal___wJkna .content___10tAO {
  text-align: center;
  font-size: 14px;
}
.modal___wJkna .content___10tAO p {
  margin: 0;
}
.modal___wJkna .actions___2682c {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___wJkna .actions___2682c .button___2Ks-u {
  margin-top: 10px;
}
.modal___wJkna .actions___2682c:hover {
  color: #33c3c7;
}
.fc___1lQjF .fc-timegrid-slot-minor___24ad1 {
  border-top-style: none;
}
.fc___1lQjF .fc-timegrid-slot___d4LwC {
  height: 1.5em !important;
}
.messageError___3ld9u .ant-message-custom-content___2WSM7 {
  display: flex;
  text-align: start;
}
.messageError___3ld9u .ant-message-custom-content___2WSM7 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___qqmOX {
  background-color: #e6e4e4;
}
.notifyMessage___3BDJn .ant-message-notice-content___XTUCE {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3BDJn .ant-message-notice-content___XTUCE .anticon___lGp8D {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3BDJn .ant-message-notice-content___XTUCE span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1zXPI {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1zXPI .ant-notification-notice-message___1HyJm,
.notifyCustomize___1zXPI .ant-notification-notice-close___3d2Ww,
.notifyCustomize___1zXPI .ant-notification-notice-icon___1NGYv {
  color: white;
}
.notifyCustomize___1zXPI .ant-notification-notice-message___1HyJm {
  margin-left: 35px;
}
.notifyCustomize___1zXPI p {
  margin-bottom: 0;
}
.notifyCustomize___1zXPI p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1yoB4 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1yoB4 .ant-notification-notice-message___1HyJm {
  margin-left: 35px;
}
.notifyAlertDuplicate___1yoB4 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1yoB4 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2pW7w {
  width: 434px !important;
}
.handleSubmitVote___2pW7w .ant-btn-primary___1WdrB {
  background: #59c3c7;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF .ant-modal-confirm-body___1enuA {
  display: flex !important;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF .ant-modal-confirm-body___1enuA .anticon___lGp8D {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF .ant-modal-confirm-btns___2zRtl button {
  font-weight: bold;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF .ant-modal-confirm-btns___2zRtl button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF .ant-modal-confirm-btns___2zRtl button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2pW7w .ant-modal-body___30BOF .ant-modal-confirm-btns___2zRtl .ant-btn-primary___1WdrB {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3gs0l {
  margin-bottom: 0;
}
.mt-0___nAWoD {
  margin-top: 0;
}
.tooltipFormat___ff5TH p {
  margin-bottom: 0;
}
.bgWhite___1HvDC {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3OJmW {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3OJmW:hover,
.bgPrimaryBlue___3OJmW:focus,
.bgPrimaryBlue___3OJmW:active {
  background: #4f7ac7;
}
.bgDarkBlue___1eWQY {
  background-color: #023768 !important;
}
.bgDarkBlue___1eWQY:hover,
.bgDarkBlue___1eWQY:focus,
.bgDarkBlue___1eWQY:active {
  background: #194d7d;
}
.bgGreyBlue___EqARK {
  background-color: #ebf0f9;
}
.bgLightBlue___1-cTK {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3rzru {
  background-color: #e4453a;
}
.bgLightRed___3rzru:hover,
.bgLightRed___3rzru:focus,
.bgLightRed___3rzru:active {
  background: #e17871;
}
.bgLightYellow___izjN_ {
  background-color: #ffd603;
}
.bgDarkGray___1-ZvN {
  background-color: #333333;
}
.bgMediumGray___3JAeA {
  background-color: #a2a2a2;
}
.bgLightGray___2RYK0 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___ofAkS {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2jWSp {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3oUeR {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3CZjH {
  color: #3368c7 !important;
}
.textDarkBlue___3zjsQ {
  color: #023768 !important;
}
.textLightBlue___3oMlg {
  color: #b2cbf7;
}
.textDarkGray___22ZUC {
  color: #333333 !important;
}
.textMediumGray___2H_BN {
  color: #a2a2a2 !important;
}
.textLightGray___3kL5- {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1ILyg {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2L-w5 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3Ddry {
  border-radius: 5px !important;
}
.rounded-xs___HY5Ml {
  border-radius: 10px !important;
}
.rounded-sm___Gw7Cv {
  border-radius: 15px !important;
}
.rounded-md___h4iq4 {
  border-radius: 20px !important;
}
.rounded-lg___1DIzJ {
  border-radius: 25px !important;
}
.rounded-full___1Eg1H {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2sAdZ {
  margin: 0;
}
.mt-2___313-4 {
  margin-top: 0.5rem;
}
.my-2___3xgot {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2po5b {
  padding: 0;
}
.px-1___1hK6y {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3sPxf {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1jWAJ {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2bFgg {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___DQnnG {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___39SEY {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2L3t_ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2gU9n {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___8Uy1g {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2sRPC {
  padding-bottom: 20px;
}
.h-full___1uRt0 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___c0qv2 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3Y-CG {
  font-weight: bold !important;
}
.fontWeight400___196Hj {
  font-weight: 400 !important;
}
.fontWeight500___-YFyO {
  font-weight: 500 !important;
}
.fontWeight600___-SvTY {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___PHF1M {
  font-size: 12px !important;
}
.fontSize14___g3E85 {
  font-size: 14px !important;
}
.fontSize16___v9MaG {
  font-size: 16px !important;
}
.fontSize18___1WWx3 {
  font-size: 18px !important;
}
.fontSize20___2bL9t {
  font-size: 20px !important;
}
.fontSize24___2fHcY {
  font-size: 24px !important;
}
.eventName___2AlYV {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1tirT {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___20D8z > tr > th,
.ant-table-tbody___1JfNZ > tr.ant-table-row___OgS4O > td,
.ant-table-summary___33IH7 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___20D8z > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2Ox9W .ant-table-thead___20D8z > tr > th,
.table-portrait___2Ox9W .ant-table-tbody___1JfNZ > tr.ant-table-row___OgS4O > td,
.table-portrait___2Ox9W .ant-table-summary___33IH7 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2Ox9W .bgLightGray___2RYK0 td {
  background: #ebebeb;
}
.table-portrait___2Ox9W .bgLightBlue___1-cTK td {
  background: #b2cbf7;
}
.eventContent___9FqTs .timeText___2wY0b {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1JfNZ > tr.bgLightGray___2RYK0:hover > td,
.ant-table-tbody___1JfNZ > tr > td.ant-table-cell-row-hover___33sji {
  background: #ebebeb;
}
.ant-table-tbody___1JfNZ > tr.bgLightBlue___1-cTK:hover > td,
.ant-table-tbody___1JfNZ > tr > td.ant-table-cell-row-hover___33sji {
  background: #b2cbf7;
}
.ant-table-tbody___1JfNZ > tr.bgWhite___1HvDC:hover > td,
.ant-table-tbody___1JfNZ > tr > td.ant-table-cell-row-hover___33sji {
  background: #ffffff;
}
.calendarCreation___1a50D {
  max-width: 1080px;
  margin: auto;
  padding: 25px 15px 50px;
}
@media screen and (max-width: 767px) {
  .calendarCreation___1a50D {
    padding: 25px 15px 130px;
  }
}
.calendarCreation___1a50D .saveToTemplateBtn___25nJ6 {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
}
@media screen and (max-width: 767px) {
  .calendarCreation___1a50D .saveToTemplateBtn___25nJ6 {
    flex-wrap: wrap;
  }
}
.calendarCreation___1a50D .saveToTemplateBtn___25nJ6 button {
  height: 40px;
  width: 33%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .calendarCreation___1a50D .saveToTemplateBtn___25nJ6 button {
    width: 100%;
    margin-bottom: 15px;
  }
}
.calendarCreation___1a50D .registerTemplateText___3io0z {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
}
.createEventType___1Tn9m {
  padding-bottom: 80px;
}
.advancedSettingContainer___XXNtH {
  max-width: 1080px;
  padding: 30px 15px 80px;
  margin: auto;
}
.advancedSettingContainer___XXNtH .collapseSettingContainer___bI-xj {
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 35px;
}
.advancedSettingContainer___XXNtH .messageSettingContainer___2afUa .ant-collapse-header .ant-collapse-arrow {
  left: 250px !important;
}
.advancedSettingContainer___XXNtH .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 180px;
  right: unset;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%);
}
.advancedSettingContainer___XXNtH .ant-collapse {
  border: 0;
  background-color: #fff;
}
.advancedSettingContainer___XXNtH .ant-collapse-header,
.advancedSettingContainer___XXNtH .ant-collapse > .ant-collapse-item:last-child,
.advancedSettingContainer___XXNtH .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: #fff;
  border-radius: 20px;
}
.advancedSettingContainer___XXNtH .ant-collapse-content,
.advancedSettingContainer___XXNtH .ant-collapse-item:last-child > .ant-collapse-content {
  border-top: 0;
  border-radius: 0 0 20px 20px;
}
.advancedSettingContainer___XXNtH .ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.advancedSettingContainer___XXNtH .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 10px;
  height: 23px;
  min-width: 99px;
  border: 2px solid #d9d9d9 !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: #414141;
  line-height: 23px;
  display: flex;
  align-items: center;
}
.advancedSettingContainer___XXNtH .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.advancedSettingContainer___XXNtH .ant-picker {
  max-width: 100px;
  font-size: 13px;
  height: 24px;
  border-radius: 15px;
  border-width: 2px;
}
.advancedSettingContainer___XXNtH .ant-picker-input > input {
  font-size: 13px;
}
.advancedSettingContainer___XXNtH .scheduleSetting___bBO2z {
  padding: 24px 64px 0 64px;
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 40px;
}
.advancedSettingContainer___XXNtH .timeSetting___26kIE {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___XXNtH .messageSetting___2M_JJ {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___XXNtH .dropdownHeader___5RGja {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___XXNtH .dropdownHeader___5RGja {
    position: relative;
    width: 100%;
  }
}
.advancedSettingContainer___XXNtH .dropdownHeader___5RGja .dropdownHeaderTitle___2eVT5 {
  font-size: 26px;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___XXNtH .dropdownHeader___5RGja .dropdownHeaderTitle___2eVT5 {
    font-size: 16px;
  }
}
.advancedSettingContainer___XXNtH .dropdownHeader___5RGja img {
  height: 23px;
  width: 23px;
  margin-right: 8px;
}
.advancedSettingContainer___XXNtH .dropdownHeader___5RGja a {
  font-size: 25px;
  color: #414141;
}
.advancedSettingContainer___XXNtH .dropdownHeader___5RGja a .anticon-down {
  margin-left: 30px;
}
.buttonZoneContainer___2tqFY {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.buttonZoneContainer___2tqFY .backBtn___OhEj2 {
  height: 48px;
  min-width: 148px;
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #ffffff;
  color: #414141;
  box-shadow: 1px 1px 12px #b3bcbc60;
}
.helper___xpUlT {
  margin-left: 75px;
  margin-top: 7px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 767px) {
  .helper___xpUlT {
    position: absolute;
    right: 0;
  }
}
.helper___xpUlT img {
  position: relative;
}
.helper___xpUlT .helperTooltip___32eTo {
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 50px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 120px;
  width: 400px;
  background-color: #414141;
  color: #fffefe;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.helper___xpUlT .helperTooltip___32eTo::-webkit-scrollbar {
  display: none;
}
.helper___xpUlT .helperTooltip___32eTo img {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.helper___xpUlT:hover .helperTooltip___32eTo {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
.tooltipAdvanced___Sh46G {
  white-space: pre-line;
}
.tooltipAdvanced___Sh46G .ant-tooltip-inner {
  height: 119px;
  min-width: 300px;
  justify-content: center;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  .tooltipAdvanced___Sh46G .ant-tooltip-inner {
    min-width: auto;
    height: auto;
  }
}
.tooltipAdvanced___Sh46G .ant-tooltip-content .ant-tooltip-arrow {
  width: 30px;
  height: 30px;
}
.tooltipAdvanced___Sh46G .ant-tooltip-content .ant-tooltip-arrow-content {
  background-color: transparent;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.75);
  transform: unset;
  box-shadow: unset;
  top: 13px;
}
@media screen and (max-width: 767px) {
  .bookingCalendarParent___2DOki {
    position: relative;
    overflow: scroll;
    height: 80vh;
  }
  .bookingCalendarParent___2DOki > div {
    position: absolute;
  }
}
.btnCreateCalendar___3-rjd {
  position: absolute;
  right: 20px;
  bottom: 30px;
  padding: 15px;
  background: #0f87e4;
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  z-index: 999;
}
.calendarLinkOtherDesc___2W8ap {
  background-color: rgba(24, 144, 255, 0.15);
  border-radius: 20px;
  padding: 15px;
  color: #0f87e4;
}
.calendarLinkOtherDesc___2W8ap .calendarLinkOtherTitle___pHZ5F {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}
.calendarLinkOtherDesc___2W8ap .calendarLinkOtherTitle___pHZ5F > svg {
  fill: #3368c7;
  margin-right: 5px;
}
.calendarLinkOtherDesc___2W8ap .calendarLinkOtherContent___1WHXB {
  font-size: 0.9rem;
}
.calendarLinkOtherBtn___1FmzC {
  padding-top: 20px;
}
.calendarLinkOtherBtn___1FmzC .calendarLinkOtherItem___3GzEj {
  width: 80%;
  height: 40px;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b3bcbc;
  background: transparent;
  padding: 5px 10px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.calendarLinkOtherBtn___1FmzC .calendarLinkOtherItem___3GzEj > img {
  width: 25px;
  margin-right: 10px;
}
.calendarLinkOtherBtn___1FmzC .calendarLinkOtherItem___3GzEj.isLinked___2O5Uc {
  border: 1px solid #eb3c03;
  background-color: #ffe6e6;
}
.calendarLinkOtherFooter___CUVzm {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendarLinkOtherFooter___CUVzm > a {
  padding: 0 10px;
  color: #0f87e4;
  text-decoration: underline;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background: #0f87e4;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.75);
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon > svg {
  color: #ffffff;
}
