.qa___1-iLK {
  position: relative;
}
.qa___1-iLK .qaHeader___2SOVb {
  background-color: #fcfcfc;
  border-bottom: 2px solid #f3f3f3;
  padding: 70px 0 50px;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .title___2wNrI {
  font-size: 30px;
  display: block;
  text-align: center;
  margin-bottom: 60px;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .left___1tlkf {
  max-width: 680px;
  width: 100%;
  margin-top: -25px;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .left___1tlkf .leftTitle___HVPwt {
  font-size: 16px;
  margin-bottom: 10px;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .left___1tlkf .seachForm___3m3ca {
  display: flex;
  align-items: center;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .right___1MQ8h .rightBlock___31h1P {
  margin-bottom: 17px;
  background: #e3f4f4;
  border-radius: 20px;
  color: #666666;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 100%;
  padding: 0 27px;
  text-align: center;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .right___1MQ8h .rightBlock___31h1P:last-child {
  margin-bottom: 0;
}
.qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .right___1MQ8h .rightBlock___31h1P:hover {
  cursor: pointer;
}
.qa___1-iLK .qaContent___38ezG {
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 90px 15px 0;
}
.qa___1-iLK .qaContent___38ezG .title___2wNrI {
  font-size: 30px;
  text-align: center;
  margin-bottom: 88px;
}
@media screen and (max-width: 767px) {
  .qa___1-iLK .qaContent___38ezG .title___2wNrI {
    font-size: 20px;
  }
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .titleItem___OWUhN {
  font-size: 16px;
  border-left: 7px solid #33c3c7;
  padding-left: 10px;
  margin-bottom: 43px;
  line-height: 1.2;
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO ul {
  font-size: 16px;
  line-height: 2;
  margin-bottom: 50px;
  margin-left: 18px;
}
@media screen and (max-width: 767px) {
  .qa___1-iLK .qaContent___38ezG .qaItem___WhGBO ul {
    min-height: auto;
  }
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO ul li {
  white-space: pre-line;
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO ul li a {
  color: #33c3c7;
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO ul li a:hover {
  color: black;
  text-decoration: underline;
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .btnSeeAll___2HakS {
  width: 100%;
  max-width: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 45px;
  border-radius: 15px;
  border: 2px solid #33c3c7;
  color: #33c3c7;
  font-size: 18px;
  margin: auto;
  margin-bottom: 80px;
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .btnSeeAll___2HakS:active,
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .btnSeeAll___2HakS:hover,
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .btnSeeAll___2HakS:focus {
  background: #33c3c7;
  color: #fff;
}
.qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .hidePC___1BHxh {
  display: none;
}
.qa___1-iLK .seachForm___3m3ca .ant-input-group {
  display: flex;
  align-items: center;
}
.qa___1-iLK .seachForm___3m3ca .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  max-width: 500px;
  width: 100%;
  height: 50px;
  border: 2px solid #b7b7b7 !important;
  border-radius: 15px;
  margin-right: 8px;
  font-size: 22px;
}
.qa___1-iLK .seachForm___3m3ca .ant-input-search-button {
  max-width: 110px;
  width: 100%;
  height: 50px;
  border-radius: 20px !important;
  background: #33c3c7;
  color: #fff;
  border: 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  box-shadow: none;
}
@media screen and (max-width: 767px) {
  .qa___1-iLK .seachForm___3m3ca .ant-input-search-button {
    font-size: 16px;
  }
}
.qa___1-iLK .seachForm___3m3ca .ant-input-group-addon:last-child {
  width: 100%;
  max-width: 30%;
}
.qa___1-iLK .seachForm___3m3ca .ant-input-group-addon:last-child {
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .qa___1-iLK .seachForm___3m3ca .ant-input {
    font-size: 16px;
  }
}
.qa___1-iLK .displayNone___2R6mT {
  display: none;
}
@media screen and (max-width: 768px) {
  .qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .title___2wNrI {
    font-size: 20px;
  }
  .qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz {
    flex-wrap: wrap;
  }
  .qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .left___1tlkf {
    margin-bottom: 20px;
  }
  .qa___1-iLK .qaHeader___2SOVb .container___a7Z33 .headerContent___q0gkz .left___1tlkf .leftTitle___HVPwt {
    font-size: 16px;
  }
  .qa___1-iLK .qaContent___38ezG {
    font-size: 20px;
  }
  .qa___1-iLK .qaContent___38ezG .qaItem___WhGBO ul {
    min-height: auto;
  }
}
@media screen and (max-width: 576px) {
  .qa___1-iLK .qaContent___38ezG .qaItem___WhGBO .hidePC___1BHxh {
    display: block;
  }
  .qa___1-iLK .qaContent___38ezG .hideSM___fEspC {
    display: none;
  }
}
