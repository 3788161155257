@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___JcALY {
  display: flex;
  justify-content: center;
}
button.btn___33ToC {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___33ToC {
    padding: 10px;
  }
}
button.btn___33ToC:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___33ToC:focus {
  outline: none;
}
.btnWhite___k7iZg.btn___33ToC {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___k7iZg.btn___33ToC:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___QjaUk.btn___33ToC,
.btnGreen___QjaUk.btn___33ToC:hover,
.btnGreen___QjaUk.btn___33ToC:active,
.btnGreen___QjaUk.btn___33ToC:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1lN8A {
  color: #fe0000;
}
.errorMessage___bX1Gb {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1sbfu {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1i1D6 {
  display: none !important;
}
.m-auto___tyklq {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3aG3i {
  max-width: 120px !important;
}
.fc___2obEV .fc-timegrid-col___3zVhw.fc-day-today___3jpsR {
  background-color: #ffffff;
}
.fc___2obEV .fc-timegrid-slot___Wn1gD {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2obEV .fc-timegrid-slot___Wn1gD {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1hia4 .fc-direction-ltr___aoszQ .fc-timegrid-slot-label-frame___3aUkA {
    font-size: 10px;
  }
  .bookingCalendar___1hia4 .fc___2obEV .fc-timegrid-slot-label-cushion___1vUYx {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1hia4 .fc___2obEV .fc-scrollgrid-section-header___3x-DX .fc-scroller___3MrRT {
    overflow: unset !important;
  }
}
.ant-message-notice___KJVtk {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___35jq8 {
  width: 20px;
  height: 20px;
}
.bgTransparent___3yrxP {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1FDeZ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3HLUu {
  position: relative;
}
.inputCustomValue___3HLUu .ant-input___c_12K {
  padding-right: 50px;
}
.inputCustomValueText___1qva5 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___HYoxx .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___HYoxx .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2F_lG .ant-radio-inner___3ARuT {
  border-color: black !important ;
}
.ant-radio-checked___2F_lG .ant-radio-inner___3ARuT:after {
  background-color: black;
}
.ant-radio___1p6_g:hover .ant-radio-inner___3ARuT {
  border-color: black;
}
.ant-radio-checked___2F_lG .ant-radio-inner___3ARuT:focus {
  border-color: black;
}
.modal___3bQqM {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3bQqM .close___1KWub {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3bQqM .header___2shLw {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3bQqM .header___2shLw p {
  margin: 0;
}
.modal___3bQqM .content___30UWd {
  text-align: center;
  font-size: 14px;
}
.modal___3bQqM .content___30UWd p {
  margin: 0;
}
.modal___3bQqM .actions___3Ni_H {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3bQqM .actions___3Ni_H .button___28cwB {
  margin-top: 10px;
}
.modal___3bQqM .actions___3Ni_H:hover {
  color: #33c3c7;
}
.fc___2obEV .fc-timegrid-slot-minor___10aKZ {
  border-top-style: none;
}
.fc___2obEV .fc-timegrid-slot___Wn1gD {
  height: 1.5em !important;
}
.messageError___2Kzyh .ant-message-custom-content___29tp0 {
  display: flex;
  text-align: start;
}
.messageError___2Kzyh .ant-message-custom-content___29tp0 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___efRvL {
  background-color: #e6e4e4;
}
.notifyMessage___1hYDs .ant-message-notice-content___QOQdQ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1hYDs .ant-message-notice-content___QOQdQ .anticon___1zjIR {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1hYDs .ant-message-notice-content___QOQdQ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2megB {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2megB .ant-notification-notice-message___2Y6cL,
.notifyCustomize___2megB .ant-notification-notice-close___4ScTT,
.notifyCustomize___2megB .ant-notification-notice-icon___2_aSY {
  color: white;
}
.notifyCustomize___2megB .ant-notification-notice-message___2Y6cL {
  margin-left: 35px;
}
.notifyCustomize___2megB p {
  margin-bottom: 0;
}
.notifyCustomize___2megB p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1UNCo {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1UNCo .ant-notification-notice-message___2Y6cL {
  margin-left: 35px;
}
.notifyAlertDuplicate___1UNCo p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1UNCo p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3JNY5 {
  width: 434px !important;
}
.handleSubmitVote___3JNY5 .ant-btn-primary___9TVb1 {
  background: #59c3c7;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY .ant-modal-confirm-body___1xSUh {
  display: flex !important;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY .ant-modal-confirm-body___1xSUh .anticon___1zjIR {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY .ant-modal-confirm-btns___E_d20 button {
  font-weight: bold;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY .ant-modal-confirm-btns___E_d20 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY .ant-modal-confirm-btns___E_d20 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3JNY5 .ant-modal-body___11obY .ant-modal-confirm-btns___E_d20 .ant-btn-primary___9TVb1 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___Tz6Gj {
  margin-bottom: 0;
}
.mt-0___3cyS5 {
  margin-top: 0;
}
.tooltipFormat___iIJYq p {
  margin-bottom: 0;
}
.bgWhite___3eDia {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1nvKG {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1nvKG:hover,
.bgPrimaryBlue___1nvKG:focus,
.bgPrimaryBlue___1nvKG:active {
  background: #4f7ac7;
}
.bgDarkBlue___2smIo {
  background-color: #023768 !important;
}
.bgDarkBlue___2smIo:hover,
.bgDarkBlue___2smIo:focus,
.bgDarkBlue___2smIo:active {
  background: #194d7d;
}
.bgGreyBlue___3LA_T {
  background-color: #ebf0f9;
}
.bgLightBlue___W-yNs {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2MOqm {
  background-color: #e4453a;
}
.bgLightRed___2MOqm:hover,
.bgLightRed___2MOqm:focus,
.bgLightRed___2MOqm:active {
  background: #e17871;
}
.bgLightYellow___159CO {
  background-color: #ffd603;
}
.bgDarkGray___i2bxe {
  background-color: #333333;
}
.bgMediumGray___3Vv3I {
  background-color: #a2a2a2;
}
.bgLightGray___1fzWY {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2f4nr {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2ev8S {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___q8WZE {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___7RWbB {
  color: #3368c7 !important;
}
.textDarkBlue___2l7gr {
  color: #023768 !important;
}
.textLightBlue___1BgyQ {
  color: #b2cbf7;
}
.textDarkGray___2rMjj {
  color: #333333 !important;
}
.textMediumGray___2_g_2 {
  color: #a2a2a2 !important;
}
.textLightGray___3ohQB {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___M_yzz {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___35gld {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___-KpoG {
  border-radius: 5px !important;
}
.rounded-xs___2vgZt {
  border-radius: 10px !important;
}
.rounded-sm___2RXcB {
  border-radius: 15px !important;
}
.rounded-md___3-Lti {
  border-radius: 20px !important;
}
.rounded-lg___1riGs {
  border-radius: 25px !important;
}
.rounded-full___1PEHb {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___yLan- {
  margin: 0;
}
.mt-2___22QAI {
  margin-top: 0.5rem;
}
.my-2___34NfP {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___yqI6Q {
  padding: 0;
}
.px-1___3oTDq {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2Kk5e {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___NhFsT {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___FG9mu {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1mtWw {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___7_bi7 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1lHmL {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2YJFm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2qy7G {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1c6_1 {
  padding-bottom: 20px;
}
.h-full___M7NxZ {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___Ds2Uc {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3J8iU {
  font-weight: bold !important;
}
.fontWeight400___2jD2R {
  font-weight: 400 !important;
}
.fontWeight500___1owfp {
  font-weight: 500 !important;
}
.fontWeight600___3s9i5 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2UKzw {
  font-size: 12px !important;
}
.fontSize14___1PhU_ {
  font-size: 14px !important;
}
.fontSize16___kS587 {
  font-size: 16px !important;
}
.fontSize18___hQAPe {
  font-size: 18px !important;
}
.fontSize20___26dpw {
  font-size: 20px !important;
}
.fontSize24___209M7 {
  font-size: 24px !important;
}
.eventName___32BIN {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3loqs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2bHg0 > tr > th,
.ant-table-tbody___1AtQR > tr.ant-table-row___1FMvV > td,
.ant-table-summary___U55JV tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2bHg0 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1FoSW .ant-table-thead___2bHg0 > tr > th,
.table-portrait___1FoSW .ant-table-tbody___1AtQR > tr.ant-table-row___1FMvV > td,
.table-portrait___1FoSW .ant-table-summary___U55JV tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1FoSW .bgLightGray___1fzWY td {
  background: #ebebeb;
}
.table-portrait___1FoSW .bgLightBlue___W-yNs td {
  background: #b2cbf7;
}
.eventContent___2rq7e .timeText___1yz9_ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1AtQR > tr.bgLightGray___1fzWY:hover > td,
.ant-table-tbody___1AtQR > tr > td.ant-table-cell-row-hover___lEjLi {
  background: #ebebeb;
}
.ant-table-tbody___1AtQR > tr.bgLightBlue___W-yNs:hover > td,
.ant-table-tbody___1AtQR > tr > td.ant-table-cell-row-hover___lEjLi {
  background: #b2cbf7;
}
.ant-table-tbody___1AtQR > tr.bgWhite___3eDia:hover > td,
.ant-table-tbody___1AtQR > tr > td.ant-table-cell-row-hover___lEjLi {
  background: #ffffff;
}
.cancelBookingComplete___I0ybq {
  max-width: 1080px;
  width: 100%;
  margin: auto;
  padding-top: 60px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .cancelBookingComplete___I0ybq {
    padding-bottom: 80px;
  }
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H {
  display: flex;
  position: relative;
  max-width: 375px;
  margin: 0 auto 80px auto;
}
@media screen and (max-width: 767px) {
  .cancelBookingComplete___I0ybq .progressBar___q9i7H {
    padding: 0 40px 0 30px;
  }
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .firstStep___Zwcj7 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 12px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .firstStep___Zwcj7 p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  color: #414141;
  font-size: 13px;
  min-width: 120px;
  text-align: center;
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .activeStep___2sDax {
  background-color: #33c3c7 !important;
  color: #eff6fc !important;
  box-shadow: none !important;
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .dottedBar___1hnwV {
  height: 30px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .dottedBar___1hnwV::before {
  content: '.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   ';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  font-size: 20px;
  line-height: 1;
  color: #a1a0a0;
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .secondStep___21nFh {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 12px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc30;
  cursor: pointer;
  position: relative;
}
.cancelBookingComplete___I0ybq .progressBar___q9i7H .secondStep___21nFh p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  color: #414141;
  font-size: 13px;
  min-width: 120px;
  text-align: center;
}
.cancelBookingComplete___I0ybq .pageTitle___11xrT {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 38px;
  margin-left: 12px;
}
.cancelBookingComplete___I0ybq .pageTitle___11xrT .titleIcon___1BoUm {
  width: 8px;
  height: 23px;
  background-color: #33c3c7;
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
}
.cancelBookingComplete___I0ybq .pageTitle___11xrT span {
  font-size: 24px;
  color: #3c3c3c;
}
.cancelBookingComplete___I0ybq .detail___4JUtm {
  padding: 0 12px;
}
.cancelBookingComplete___I0ybq .detail___4JUtm p {
  line-height: 1.5;
  margin-bottom: 12px;
  font-size: 17px;
  color: #3c3c3c;
}
.cancelBookingComplete___I0ybq .divTitle___22RLg {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}
.cancelBookingComplete___I0ybq .divTitle___22RLg .blackIcon___3_FLj {
  width: 8px;
  height: 18px;
  background-color: #3c3c3c;
  margin-right: 6px;
}
.cancelBookingComplete___I0ybq .divTitle___22RLg span {
  color: #3c3c3c;
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .cancelBookingComplete___I0ybq .divTitle___22RLg span {
    font-size: 16px;
  }
}
.cancelBookingComplete___I0ybq .btnZone___2OhN0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 40px;
}
.cancelBookingComplete___I0ybq .btnZone___2OhN0 .confirmCancelBtn___88U3o {
  height: 49px;
  min-width: 312px;
  margin: 4px 10px;
  border-radius: 20px;
  color: #ffffff;
  font-size: 18px;
  background-color: #33c3c7;
  border: none;
}
.cancelBookingComplete___I0ybq .ownerInformation___1Hpln {
  margin: 25px 0;
}
.cancelBookingLayout___1aQfS {
  position: relative;
  min-height: 100vh;
  padding-bottom: 80px;
}
