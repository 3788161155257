.menuListSetting___3fliz {
  border-bottom: 0;
}
.menuListSetting___3fliz .headerLogo___23duY .imgLogo___3__oI {
  width: 90px;
  height: 22px;
  cursor: pointer;
  background-image: url(./static/logo-black.0de3d552.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.menuListSetting___3fliz .activeItem___d-Kic .ant-menu-title-content {
  font-weight: bold;
  border-bottom: 4px solid;
}
.menuListSetting___3fliz .ant-menu-item-selected {
  color: #4a6bc0 !important;
}
.menuListSetting___3fliz .ant-menu-item-selected:after {
  border-bottom: 0 solid transparent !important;
}
.menuListSetting___3fliz .ant-menu-item {
  font-size: 16px;
  color: #3c3c3c;
}
.menuListSetting___3fliz .ant-menu-item:after {
  bottom: 5px;
}
.menuListSetting___3fliz .ant-menu-item:first-child {
  margin-top: auto;
  margin-bottom: auto;
}
.menuListSetting___3fliz .ant-menu-item:not(:last-child) {
  margin-right: 2rem;
}
.menuListSetting___3fliz .ant-menu-item:hover {
  color: #4a6bc0 !important;
}
.menuListSetting___3fliz .ant-menu-item:hover:after {
  border-bottom: 0 solid transparent !important;
}
.menuListSetting___3fliz .ant-menu-title-content {
  padding-bottom: 0.2rem;
}
@media screen and (max-width: 1426px) {
  .menuListSetting___3fliz .ant-menu-item {
    padding: 0 15px;
  }
  .menuListSetting___3fliz .ant-menu-item:not(:last-child) {
    margin-right: 0.75rem;
  }
}
@media screen and (max-width: 1346px) {
  .menuListSetting___3fliz .ant-menu-item {
    padding: 0 7px;
    font-size: 14px;
  }
  .menuListSetting___3fliz .ant-menu-item:not(:last-child) {
    margin-right: unset;
  }
}
@media screen and (max-width: 1230px) {
  .menuListSetting___3fliz .ant-menu-item:not(:last-child) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1230px) {
  .menuListSetting___3fliz .ant-menu-item:not(:last-child) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1148px) {
  .menuListSetting___3fliz .ant-menu-item {
    padding: 0 5px;
  }
}
