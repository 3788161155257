@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3AKm_ {
  display: flex;
  justify-content: center;
}
button.btn___MGsdY {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___MGsdY {
    padding: 10px;
  }
}
button.btn___MGsdY:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___MGsdY:focus {
  outline: none;
}
.btnWhite___2fnPO.btn___MGsdY {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2fnPO.btn___MGsdY:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2sprr.btn___MGsdY,
.btnGreen___2sprr.btn___MGsdY:hover,
.btnGreen___2sprr.btn___MGsdY:active,
.btnGreen___2sprr.btn___MGsdY:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___34Q-X {
  color: #fe0000;
}
.errorMessage___KoVnl {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___s8lMp {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___ehoGJ {
  display: none !important;
}
.m-auto___3vwGm {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3ZmA- {
  max-width: 120px !important;
}
.fc___xudvS .fc-timegrid-col___J14_G.fc-day-today___2Tse3 {
  background-color: #ffffff;
}
.fc___xudvS .fc-timegrid-slot___2QOVn {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___xudvS .fc-timegrid-slot___2QOVn {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___WnqqO .fc-direction-ltr___2_1XS .fc-timegrid-slot-label-frame___3xQ7Q {
    font-size: 10px;
  }
  .bookingCalendar___WnqqO .fc___xudvS .fc-timegrid-slot-label-cushion___293gq {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___WnqqO .fc___xudvS .fc-scrollgrid-section-header___3YLzy .fc-scroller___3P0M7 {
    overflow: unset !important;
  }
}
.ant-message-notice___2EeUP {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___z0DAb {
  width: 20px;
  height: 20px;
}
.bgTransparent___3WNOv {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3cHRl {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1cCJA {
  position: relative;
}
.inputCustomValue___1cCJA .ant-input___202tt {
  padding-right: 50px;
}
.inputCustomValueText___3sqow {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3oAC6 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3oAC6 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2MAmK .ant-radio-inner___2MpVS {
  border-color: black !important ;
}
.ant-radio-checked___2MAmK .ant-radio-inner___2MpVS:after {
  background-color: black;
}
.ant-radio___2YXrS:hover .ant-radio-inner___2MpVS {
  border-color: black;
}
.ant-radio-checked___2MAmK .ant-radio-inner___2MpVS:focus {
  border-color: black;
}
.modal___1B8Hm {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1B8Hm .close___2Prfa {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1B8Hm .header___EuRyh {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1B8Hm .header___EuRyh p {
  margin: 0;
}
.modal___1B8Hm .content___2GKRV {
  text-align: center;
  font-size: 14px;
}
.modal___1B8Hm .content___2GKRV p {
  margin: 0;
}
.modal___1B8Hm .actions___k_2Kl {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1B8Hm .actions___k_2Kl .button___2v_H- {
  margin-top: 10px;
}
.modal___1B8Hm .actions___k_2Kl:hover {
  color: #33c3c7;
}
.fc___xudvS .fc-timegrid-slot-minor___1PCAJ {
  border-top-style: none;
}
.fc___xudvS .fc-timegrid-slot___2QOVn {
  height: 1.5em !important;
}
.messageError___1Tq17 .ant-message-custom-content___1WiEO {
  display: flex;
  text-align: start;
}
.messageError___1Tq17 .ant-message-custom-content___1WiEO span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1InjT {
  background-color: #e6e4e4;
}
.notifyMessage___1xC4L .ant-message-notice-content___2OF72 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1xC4L .ant-message-notice-content___2OF72 .anticon___nWP-g {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1xC4L .ant-message-notice-content___2OF72 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2nQH5 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2nQH5 .ant-notification-notice-message___2xTOf,
.notifyCustomize___2nQH5 .ant-notification-notice-close___2p4t9,
.notifyCustomize___2nQH5 .ant-notification-notice-icon___1eeQ3 {
  color: white;
}
.notifyCustomize___2nQH5 .ant-notification-notice-message___2xTOf {
  margin-left: 35px;
}
.notifyCustomize___2nQH5 p {
  margin-bottom: 0;
}
.notifyCustomize___2nQH5 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___Wd2bz {
  padding: 16px 14px;
}
.notifyAlertDuplicate___Wd2bz .ant-notification-notice-message___2xTOf {
  margin-left: 35px;
}
.notifyAlertDuplicate___Wd2bz p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___Wd2bz p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___ZPyyt {
  width: 434px !important;
}
.handleSubmitVote___ZPyyt .ant-btn-primary___1r0J3 {
  background: #59c3c7;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 .ant-modal-confirm-body___Uhx8- {
  display: flex !important;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 .ant-modal-confirm-body___Uhx8- .anticon___nWP-g {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 .ant-modal-confirm-btns___2KaBO button {
  font-weight: bold;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 .ant-modal-confirm-btns___2KaBO button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 .ant-modal-confirm-btns___2KaBO button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___ZPyyt .ant-modal-body___gMlh3 .ant-modal-confirm-btns___2KaBO .ant-btn-primary___1r0J3 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1CCGx {
  margin-bottom: 0;
}
.mt-0___351W0 {
  margin-top: 0;
}
.tooltipFormat___1aayz p {
  margin-bottom: 0;
}
.bgWhite___2FiGy {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___30KHL {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___30KHL:hover,
.bgPrimaryBlue___30KHL:focus,
.bgPrimaryBlue___30KHL:active {
  background: #4f7ac7;
}
.bgDarkBlue___3d56X {
  background-color: #023768 !important;
}
.bgDarkBlue___3d56X:hover,
.bgDarkBlue___3d56X:focus,
.bgDarkBlue___3d56X:active {
  background: #194d7d;
}
.bgGreyBlue___1OmR8 {
  background-color: #ebf0f9;
}
.bgLightBlue___1hFmu {
  background-color: #b2cbf7 !important;
}
.bgLightRed___106vo {
  background-color: #e4453a;
}
.bgLightRed___106vo:hover,
.bgLightRed___106vo:focus,
.bgLightRed___106vo:active {
  background: #e17871;
}
.bgLightYellow___3KdQb {
  background-color: #ffd603;
}
.bgDarkGray___wLVe7 {
  background-color: #333333;
}
.bgMediumGray___qMMWb {
  background-color: #a2a2a2;
}
.bgLightGray___bkFmH {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3Q0Eo {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1en9y {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1hMGB {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3oGkD {
  color: #3368c7 !important;
}
.textDarkBlue___3LHfX {
  color: #023768 !important;
}
.textLightBlue___hUeNa {
  color: #b2cbf7;
}
.textDarkGray___wQoFn {
  color: #333333 !important;
}
.textMediumGray___3v_dr {
  color: #a2a2a2 !important;
}
.textLightGray___3UgeE {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1GsH0 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3LcXQ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3FskS {
  border-radius: 5px !important;
}
.rounded-xs___2aF0f {
  border-radius: 10px !important;
}
.rounded-sm___1iFhU {
  border-radius: 15px !important;
}
.rounded-md___29eBd {
  border-radius: 20px !important;
}
.rounded-lg___2n4_v {
  border-radius: 25px !important;
}
.rounded-full___ADNXr {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___fKIM8 {
  margin: 0;
}
.mt-2___eSVgC {
  margin-top: 0.5rem;
}
.my-2___2y9FZ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1ZpKX {
  padding: 0;
}
.px-1___1wPfi {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___tWHfa {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3zv_Z {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___BrxCE {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___280Jz {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2NhRi {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2aiF1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___f6Jjj {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3ANk7 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___26IFA {
  padding-bottom: 20px;
}
.h-full___IxvoN {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___KgglF {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___SXV6l {
  font-weight: bold !important;
}
.fontWeight400___nJuUr {
  font-weight: 400 !important;
}
.fontWeight500___2IbU0 {
  font-weight: 500 !important;
}
.fontWeight600___20fCf {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3ZDR3 {
  font-size: 12px !important;
}
.fontSize14___ws6w2 {
  font-size: 14px !important;
}
.fontSize16___6OvOi {
  font-size: 16px !important;
}
.fontSize18___3ngqR {
  font-size: 18px !important;
}
.fontSize20___3Db1M {
  font-size: 20px !important;
}
.fontSize24___1PQ-T {
  font-size: 24px !important;
}
.eventName___3TcA- {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2nnMH {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2nToC > tr > th,
.ant-table-tbody___34dJc > tr.ant-table-row___2s3OY > td,
.ant-table-summary___1-N2_ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2nToC > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3NWNM .ant-table-thead___2nToC > tr > th,
.table-portrait___3NWNM .ant-table-tbody___34dJc > tr.ant-table-row___2s3OY > td,
.table-portrait___3NWNM .ant-table-summary___1-N2_ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3NWNM .bgLightGray___bkFmH td {
  background: #ebebeb;
}
.table-portrait___3NWNM .bgLightBlue___1hFmu td {
  background: #b2cbf7;
}
.eventContent___1P5e5 .timeText___YUz07 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___34dJc > tr.bgLightGray___bkFmH:hover > td,
.ant-table-tbody___34dJc > tr > td.ant-table-cell-row-hover___3HlRe {
  background: #ebebeb;
}
.ant-table-tbody___34dJc > tr.bgLightBlue___1hFmu:hover > td,
.ant-table-tbody___34dJc > tr > td.ant-table-cell-row-hover___3HlRe {
  background: #b2cbf7;
}
.ant-table-tbody___34dJc > tr.bgWhite___2FiGy:hover > td,
.ant-table-tbody___34dJc > tr > td.ant-table-cell-row-hover___3HlRe {
  background: #ffffff;
}
.bookingCalendarEmbed___2noZf {
  background: #fff;
  padding: 2rem 2.5rem;
  max-width: 1100px;
  margin: auto;
}
.bookingCalendarEmbed___2noZf .titleHeader___2wy2B {
  display: flex;
}
.bookingCalendarEmbed___2noZf .titleHeader___2wy2B .empty___3r_a6,
.bookingCalendarEmbed___2noZf .titleHeader___2wy2B .nameBooked___38P3n {
  width: 50%;
  flex: 1 1;
}
.bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 {
  flex: 1 1;
  width: 50%;
  max-width: 550px;
  padding: 1rem 1rem 0;
}
.bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT {
  margin-bottom: 2rem !important;
}
.bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
  min-height: 500px;
}
.bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT.last-child___UZl6C {
  margin-bottom: 0 !important;
}
.bookingCalendarEmbed___2noZf .calendar___2scNT {
  flex: 1 1;
}
@media screen and (min-width: 425px) {
  .bookingCalendarEmbed___2noZf .calendar___2scNT {
    padding-top: 1rem !important;
  }
}
.bookingCalendarEmbed___2noZf .title___2enED {
  position: relative;
  text-align: left;
  padding-left: 25px;
  line-height: 24px;
  margin-bottom: 20px;
}
.bookingCalendarEmbed___2noZf .title___2enED ::before {
  content: '';
  width: 7px;
  height: 24px;
  background-color: #33c2c6;
  position: absolute;
  top: 0;
  left: 0;
}
.bookingCalendarEmbed___2noZf .title___2enED ::after {
  content: '';
  width: 2px;
  height: 24px;
  background-color: #33c2c6;
  position: absolute;
  top: 0;
  left: 9px;
}
.bookingCalendarEmbed___2noZf .smallText___2323T {
  padding-left: 25px;
}
.bookingCalendarEmbed___2noZf .contentBooking___2cZOc {
  display: flex;
}
@media (max-width: 1090px) {
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 450px;
  }
}
@media (max-width: 997px) {
  .bookingCalendarEmbed___2noZf {
    padding: 2rem 1rem;
  }
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 400px;
  }
}
@media (max-width: 850px) {
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 350px;
  }
}
@media (max-width: 770px) {
  .bookingCalendarEmbed___2noZf {
    padding: 2rem 0.4rem;
  }
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 300px;
  }
}
@media (max-width: 650px) {
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 270px;
  }
}
@media (max-width: 590px) {
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 250px;
  }
}
@media (max-width: 425px) {
  .bookingCalendarEmbed___2noZf {
    width: 100%;
    padding: 2rem 0;
    height: unset;
  }
  .bookingCalendarEmbed___2noZf .contentBooking___2cZOc {
    display: unset;
  }
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 {
    width: unset;
  }
  .bookingCalendarEmbed___2noZf .settingCalendar___3X2f8 .infoSettingItem___3P5uT .imageCustomer___156uT {
    min-height: 350px;
  }
  .bookingCalendarEmbed___2noZf .titleHeader___2wy2B {
    display: unset;
  }
  .bookingCalendarEmbed___2noZf .titleHeader___2wy2B .empty___3r_a6 {
    display: none;
  }
  .bookingCalendarEmbed___2noZf .titleHeader___2wy2B .nameBooked___38P3n {
    padding-top: 1rem;
    width: 100%;
  }
  .bookingCalendarEmbed___2noZf .hideContentSuccessMb___2vzb3 {
    display: none;
  }
}
.stepCalendarTitle___1w-Bs {
  margin-top: 1rem;
  flex: 1 1;
}
.stepCalendarTitle___1w-Bs .titleIcon___2A4qS {
  display: flex;
}
.stepCalendarTitle___1w-Bs .titleIcon___2A4qS .bolderColIcon___27IDg {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 8px;
  margin-right: 5px;
}
.stepCalendarTitle___1w-Bs .titleIcon___2A4qS .normalColIcon___2BPGp {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 3px;
  margin-right: 10px;
}
.stepCalendarTitle___1w-Bs .titleIcon___2A4qS .titleSuccess___1TJeb {
  margin-top: 0.2rem;
  color: #707070;
}
.stepCalendarTitle___1w-Bs .titleIcon___2A4qS .titleSuccess___1TJeb span {
  font-size: 20px;
  font-weight: bold;
}
.stepCalendarTitle___1w-Bs .titleIcon___2A4qS .titleSuccess___1TJeb .childText___11J5h {
  margin-top: 1.5rem;
  font-size: 16px;
}
.stepCalendarTitle___1w-Bs.stepCalendarTitleMB___1h69t {
  display: none;
}
@media screen and (max-width: 425px) {
  .stepCalendarTitle___1w-Bs.stepCalendarTitlePC___3Scvq {
    display: none;
  }
  .stepCalendarTitle___1w-Bs.stepCalendarTitleMB___1h69t {
    display: unset;
  }
  .stepCalendarTitle___1w-Bs .titleIcon___2A4qS {
    padding: 0 0.75rem;
  }
  .stepCalendarTitle___1w-Bs .titleIcon___2A4qS .titleSuccess___1TJeb span {
    font-size: 17px;
    font-weight: bold;
  }
  .stepCalendarTitle___1w-Bs .titleIcon___2A4qS .titleSuccess___1TJeb .childText___11J5h {
    font-size: 14px;
  }
}
@media screen and (min-width: 425px) {
  .customContentBooking___1ujq9 {
    flex-direction: row-reverse;
  }
}
.dividerItem___33KOl,
.bookingTime___ZJXxe {
  display: none;
}
@media (max-width: 425px) {
  .dividerItem___33KOl,
  .bookingTime___ZJXxe {
    display: unset;
  }
}
.bookingCalendarEmbedExpirted___LjzD_ p {
  text-align: center;
}
.primaryStepTitle___3PsJQ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 73px);
}
.primaryStepTitle___3PsJQ .titleIcon___2A4qS {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.primaryStepTitle___3PsJQ .titleIcon___2A4qS .bolderColIcon___27IDg {
  display: inline-block;
  background-color: #3c3c3c;
  height: 20px;
  width: 6px;
  margin-right: 3px;
}
.primaryStepTitle___3PsJQ .titleIcon___2A4qS .normalColIcon___2BPGp {
  display: inline-block;
  background-color: #3c3c3c;
  height: 20px;
  width: 3px;
  margin-right: 8px;
}
.mgr12___1SjaT {
  margin-right: 12px !important;
}
.dividerPage___1e_Iz {
  margin: -1rem;
  padding: 1rem;
  -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.04));
          filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.04));
  background-color: #ffffff;
}
.dividerPage___1e_Iz .subStepTitle___Daujh {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 73px);
}
.dividerPage___1e_Iz .subStepTitle___Daujh .titleIcon___2A4qS {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.dividerPage___1e_Iz .subStepTitle___Daujh .titleIcon___2A4qS .bolderColIcon___27IDg {
  display: inline-block;
  background-color: #3c3c3c;
  height: 20px;
  width: 6px;
  margin-right: 8px;
}
.dividerPage___1e_Iz .subStepTitle___Daujh .blackTitleLine___teCUa :before {
  background-color: #3c3c3c !important;
}
.dividerPage___1e_Iz .subStepTitle___Daujh .blackTitleLine___teCUa :after {
  background-color: #3c3c3c !important;
  width: 3px !important;
  margin-left: 1px !important;
}
.dividerPage___1e_Iz .subStepTitle___Daujh .blackTitleLine___teCUa b {
  margin-left: -6px;
}
.dividerPage___1e_Iz .subStepTitle___Daujh .title___2enED {
  margin-bottom: 0.5rem;
}
.dividerPage___1e_Iz .pdf1dot4Rem___qAl7x {
  padding-left: 1.4rem;
  font-weight: bold;
}
