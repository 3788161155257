.loginGoogleMeetSuccess___23ZdU {
  padding: 100px 0;
}
.loginGoogleMeetSuccess___23ZdU h2 {
  text-align: center;
}
.loginGoogleMeetSuccess___23ZdU .btnBack___32epI {
  margin-top: 30px;
  text-align: center;
  text-decoration: underline;
  display: block;
}
