@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1I6EE {
  display: flex;
  justify-content: center;
}
button.btn___2wVCh {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2wVCh {
    padding: 10px;
  }
}
button.btn___2wVCh:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2wVCh:focus {
  outline: none;
}
.btnWhite___38F3Q.btn___2wVCh {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___38F3Q.btn___2wVCh:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3SOvf.btn___2wVCh,
.btnGreen___3SOvf.btn___2wVCh:hover,
.btnGreen___3SOvf.btn___2wVCh:active,
.btnGreen___3SOvf.btn___2wVCh:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___27iBZ {
  color: #fe0000;
}
.errorMessage___qA67A {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___vImJb {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1Xp3v {
  display: none !important;
}
.m-auto___1A4yb {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2qD4l {
  max-width: 120px !important;
}
.fc___HpkLE .fc-timegrid-col___2OELI.fc-day-today___1DC-F {
  background-color: #ffffff;
}
.fc___HpkLE .fc-timegrid-slot___YK70a {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___HpkLE .fc-timegrid-slot___YK70a {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___RpQpm .fc-direction-ltr___2Gokz .fc-timegrid-slot-label-frame___2TwBg {
    font-size: 10px;
  }
  .bookingCalendar___RpQpm .fc___HpkLE .fc-timegrid-slot-label-cushion___10_CJ {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___RpQpm .fc___HpkLE .fc-scrollgrid-section-header___XJIil .fc-scroller___3uRNF {
    overflow: unset !important;
  }
}
.ant-message-notice___2bU6g {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1y8IB {
  width: 20px;
  height: 20px;
}
.bgTransparent___1WH28 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1Pl5A {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1HWRA {
  position: relative;
}
.inputCustomValue___1HWRA .ant-input___1_bhz {
  padding-right: 50px;
}
.inputCustomValueText___3TKpv {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3kH9t .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3kH9t .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1TpcJ .ant-radio-inner___23hI5 {
  border-color: black !important ;
}
.ant-radio-checked___1TpcJ .ant-radio-inner___23hI5:after {
  background-color: black;
}
.ant-radio___2DvNv:hover .ant-radio-inner___23hI5 {
  border-color: black;
}
.ant-radio-checked___1TpcJ .ant-radio-inner___23hI5:focus {
  border-color: black;
}
.modal___1Mczb {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1Mczb .close___77Qcy {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1Mczb .header___176Mk {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1Mczb .header___176Mk p {
  margin: 0;
}
.modal___1Mczb .content___1SNo1 {
  text-align: center;
  font-size: 14px;
}
.modal___1Mczb .content___1SNo1 p {
  margin: 0;
}
.modal___1Mczb .actions___1Q7z3 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1Mczb .actions___1Q7z3 .button___d1DBH {
  margin-top: 10px;
}
.modal___1Mczb .actions___1Q7z3:hover {
  color: #33c3c7;
}
.fc___HpkLE .fc-timegrid-slot-minor___VxA-c {
  border-top-style: none;
}
.fc___HpkLE .fc-timegrid-slot___YK70a {
  height: 1.5em !important;
}
.messageError___u5hau .ant-message-custom-content___28esg {
  display: flex;
  text-align: start;
}
.messageError___u5hau .ant-message-custom-content___28esg span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1p_aM {
  background-color: #e6e4e4;
}
.notifyMessage___1Rzcn .ant-message-notice-content___2FkG6 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1Rzcn .ant-message-notice-content___2FkG6 .anticon___l5BKM {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1Rzcn .ant-message-notice-content___2FkG6 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3zz13 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3zz13 .ant-notification-notice-message___joUHk,
.notifyCustomize___3zz13 .ant-notification-notice-close___17eUf,
.notifyCustomize___3zz13 .ant-notification-notice-icon___2wsdT {
  color: white;
}
.notifyCustomize___3zz13 .ant-notification-notice-message___joUHk {
  margin-left: 35px;
}
.notifyCustomize___3zz13 p {
  margin-bottom: 0;
}
.notifyCustomize___3zz13 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3Wwkb {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3Wwkb .ant-notification-notice-message___joUHk {
  margin-left: 35px;
}
.notifyAlertDuplicate___3Wwkb p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3Wwkb p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1FltB {
  width: 434px !important;
}
.handleSubmitVote___1FltB .ant-btn-primary___178eo {
  background: #59c3c7;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc .ant-modal-confirm-body___2VTAn {
  display: flex !important;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc .ant-modal-confirm-body___2VTAn .anticon___l5BKM {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc .ant-modal-confirm-btns___3z1A2 button {
  font-weight: bold;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc .ant-modal-confirm-btns___3z1A2 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc .ant-modal-confirm-btns___3z1A2 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1FltB .ant-modal-body___i99yc .ant-modal-confirm-btns___3z1A2 .ant-btn-primary___178eo {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___X2bop {
  margin-bottom: 0;
}
.mt-0___jx46b {
  margin-top: 0;
}
.tooltipFormat___1n-m6 p {
  margin-bottom: 0;
}
.bgWhite___1tbkh {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3ilXI {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3ilXI:hover,
.bgPrimaryBlue___3ilXI:focus,
.bgPrimaryBlue___3ilXI:active {
  background: #4f7ac7;
}
.bgDarkBlue___3ENdQ {
  background-color: #023768 !important;
}
.bgDarkBlue___3ENdQ:hover,
.bgDarkBlue___3ENdQ:focus,
.bgDarkBlue___3ENdQ:active {
  background: #194d7d;
}
.bgGreyBlue____KhL8 {
  background-color: #ebf0f9;
}
.bgLightBlue___2SMd4 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___396St {
  background-color: #e4453a;
}
.bgLightRed___396St:hover,
.bgLightRed___396St:focus,
.bgLightRed___396St:active {
  background: #e17871;
}
.bgLightYellow___1w46H {
  background-color: #ffd603;
}
.bgDarkGray___RIJ7P {
  background-color: #333333;
}
.bgMediumGray___1NBRz {
  background-color: #a2a2a2;
}
.bgLightGray___1IKoq {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1ha2r {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___5iGcu {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___30KUz {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2j3Sh {
  color: #3368c7 !important;
}
.textDarkBlue___16MIt {
  color: #023768 !important;
}
.textLightBlue___1zhNv {
  color: #b2cbf7;
}
.textDarkGray___2z3Oe {
  color: #333333 !important;
}
.textMediumGray___3qk3V {
  color: #a2a2a2 !important;
}
.textLightGray___1Sw2H {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2ZeQo {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___7INSY {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2Kjao {
  border-radius: 5px !important;
}
.rounded-xs___28Q5K {
  border-radius: 10px !important;
}
.rounded-sm___S3sFj {
  border-radius: 15px !important;
}
.rounded-md___3tdZ0 {
  border-radius: 20px !important;
}
.rounded-lg___1ibGq {
  border-radius: 25px !important;
}
.rounded-full___2vsy7 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1it1h {
  margin: 0;
}
.mt-2___H-tkc {
  margin-top: 0.5rem;
}
.my-2___1cWY8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1VUKS {
  padding: 0;
}
.px-1___3yqeb {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2Hpia {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___24NzP {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3CwBg {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___27Gb5 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___28OL- {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___WKFRB {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2CHrG {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3qmeq {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3QZ8k {
  padding-bottom: 20px;
}
.h-full___1aVEr {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2rFZL {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2L3-v {
  font-weight: bold !important;
}
.fontWeight400___1KXi5 {
  font-weight: 400 !important;
}
.fontWeight500___3cJjb {
  font-weight: 500 !important;
}
.fontWeight600___3z7yT {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2eIMQ {
  font-size: 12px !important;
}
.fontSize14___3-VXw {
  font-size: 14px !important;
}
.fontSize16___3RamN {
  font-size: 16px !important;
}
.fontSize18___2jqXv {
  font-size: 18px !important;
}
.fontSize20___V5F8c {
  font-size: 20px !important;
}
.fontSize24___2TN4j {
  font-size: 24px !important;
}
.eventName___1Ecu5 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1NVue {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___r8lgy > tr > th,
.ant-table-tbody___QT13D > tr.ant-table-row___1fHkv > td,
.ant-table-summary___7ochQ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___r8lgy > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3xnQP .ant-table-thead___r8lgy > tr > th,
.table-portrait___3xnQP .ant-table-tbody___QT13D > tr.ant-table-row___1fHkv > td,
.table-portrait___3xnQP .ant-table-summary___7ochQ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3xnQP .bgLightGray___1IKoq td {
  background: #ebebeb;
}
.table-portrait___3xnQP .bgLightBlue___2SMd4 td {
  background: #b2cbf7;
}
.eventContent___xiR2c .timeText___zEqee {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___QT13D > tr.bgLightGray___1IKoq:hover > td,
.ant-table-tbody___QT13D > tr > td.ant-table-cell-row-hover___28nCW {
  background: #ebebeb;
}
.ant-table-tbody___QT13D > tr.bgLightBlue___2SMd4:hover > td,
.ant-table-tbody___QT13D > tr > td.ant-table-cell-row-hover___28nCW {
  background: #b2cbf7;
}
.ant-table-tbody___QT13D > tr.bgWhite___1tbkh:hover > td,
.ant-table-tbody___QT13D > tr > td.ant-table-cell-row-hover___28nCW {
  background: #ffffff;
}
.mainLayout___3rZt3 {
  background-color: #fefefe;
}
.mainLayout___3rZt3 .mainContent___2Gzat {
  width: 100%;
  margin: auto;
  max-width: 1080px;
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .mainLayout___3rZt3 .mainContent___2Gzat {
    padding: 0;
  }
}
.scheduleAdjustment___1ZY_E .inforTimeSelect___1V5-p {
  display: none;
  padding: 0.8rem 0;
  background: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  margin-bottom: 1rem;
}
.scheduleAdjustment___1ZY_E .inforTimeSelect___1V5-p .titleTime___3oYVR {
  display: flex;
  width: 95%;
  margin: auto;
}
.scheduleAdjustment___1ZY_E .inforTimeSelect___1V5-p .titleTime___3oYVR .boldLeft___1eatC {
  width: 6px;
  height: 18px;
  background: #3c3c3c;
  margin: auto 5px auto 0;
}
.scheduleAdjustment___1ZY_E .inforTimeSelect___1V5-p .titleTime___3oYVR label {
  color: #3c3c3c;
  font-size: 18px;
}
.scheduleAdjustment___1ZY_E .inforTimeSelect___1V5-p .rightTime___2GU4m {
  width: 95%;
  margin: auto;
  font-size: 1rem;
  padding-left: 13px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 {
  border-radius: 6px;
  padding: 35px 0 10px 0;
  background: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF {
  width: 70%;
  margin: auto;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label {
  color: #3c3c3c;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q {
  display: flex;
  justify-items: center;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:first-child {
  height: 17px;
  margin: auto 8px auto 0;
  width: 6px;
  background: #3c3c3c;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:first-child.itemPlus___SXz_J {
  height: 6px;
  width: 17px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child .formLabel___XA98m {
  font-size: 20px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child .required___27iBZ {
  margin-left: 20px;
  font-size: 17px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child .guestFormLabel___1q-oN {
  font-size: 16px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child .guestFormLabel___1q-oN .random___vImJb {
  font-size: 15px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF .addContactDescription___1P94t {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: -8px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .scheduleCollapse___3-ejz label::before {
  top: 0;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .addContactForm___2DIOm label {
  font-size: 16px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .addContactForm___2DIOm .textArea___3sS0w {
  resize: none;
  height: 100px;
  position: relative;
  overflow-x: auto;
  border: 2px solid #414141 !important;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .addContactForm___2DIOm .textArea___3sS0w:hover,
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .addContactForm___2DIOm .textArea___3sS0w:visited {
  border-color: #33c3c7 !important;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .inputSchedule___tXsKR {
  height: 42px;
  border-radius: 10px;
  border: 2px solid #414141 !important;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .inputSchedule___tXsKR:hover,
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .inputSchedule___tXsKR:visited {
  border-color: #33c3c7 !important;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .mb15___37xa2 {
  margin-bottom: 15px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .rightTime___2GU4m {
  font-size: 16px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .dateTime___1SbJt {
  margin-right: 20px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .labelRight___1BxPr {
  margin-bottom: 20px;
}
.scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .fs18___1bk1V {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___1ZY_E .inforTimeSelect___1V5-p {
    display: block;
  }
  .scheduleAdjustment___1ZY_E .mt30___1wnUK {
    margin-top: 30px;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF {
    width: 95%;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .scheduleLabelCustomMobileEmail___2bTq5 {
    margin-bottom: 15px;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .rightTime___2GU4m {
    font-size: 16px !important;
    margin: 15px 0 !important;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .labelRight___1BxPr {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child {
    font-size: 18px;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child .formLabel___XA98m {
    font-size: 18px;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF label .formInputItem___2lQ5Q div:last-child .required___27iBZ {
    font-size: 17px;
  }
  .scheduleAdjustment___1ZY_E .thirdStepDetail___2bFU8 .formScheduleAdjust___2JqMF .addContactDescription___1P94t {
    font-size: 14px;
  }
}
.addContact___1fhRV {
  background: #fafafa;
  color: #33c3c7;
  font-size: 17px;
  border: 0 solid #fafafa !important;
  padding: 0;
  font-weight: 700;
  box-shadow: none;
  margin-bottom: 24px;
  margin-top: 2px;
  outline: none;
  transition: all 0s;
  cursor: pointer;
}
.addContact___1fhRV:hover {
  color: #33c3c7 !important;
  background: #fafafa !important;
  border-color: #fafafa !important;
}
@media screen and (max-width: 767px) {
  .addContact___1fhRV {
    margin-bottom: 12px;
    font-size: 14px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.step3___1HqdV {
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .step3___1HqdV {
    padding-bottom: 0;
  }
}
.step3___1HqdV .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 9px 12px;
  background-color: #eaeaea;
  border: 0;
  border-radius: 0;
}
.step3___1HqdV .ant-collapse {
  border: 0;
}
.step3___1HqdV .ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.step3___1HqdV .ant-collapse-content {
  border: 2px solid #f5f5f5;
  background: #fcfcfc;
}
.scheduleBtnGroup___17DTO {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 30px auto;
}
.scheduleBtnGroup___17DTO button {
  min-width: 150px;
  height: 42px;
  border-radius: 18px;
  border: none;
  box-shadow: 1px 1px 10px #b3bcbc60;
}
.scheduleBtnGroup___17DTO .btnWhite___38F3Q {
  margin-right: 42px;
  color: #3c3c3c;
}
.scheduleBtnGroup___17DTO .btnGreen___3SOvf {
  background-color: #33c3c7;
  color: #ffffff;
}
@media screen and (max-width: 425px) {
  .scheduleBtnGroup___17DTO button {
    min-width: 100px;
    height: 34px;
    border-radius: 10px;
    border: none;
  }
}
