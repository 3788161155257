@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___odkCk {
  display: flex;
  justify-content: center;
}
button.btn___1ERIL {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1ERIL {
    padding: 10px;
  }
}
button.btn___1ERIL:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1ERIL:focus {
  outline: none;
}
.btnWhite___10FKm.btn___1ERIL {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___10FKm.btn___1ERIL:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___SrGeL.btn___1ERIL,
.btnGreen___SrGeL.btn___1ERIL:hover,
.btnGreen___SrGeL.btn___1ERIL:active,
.btnGreen___SrGeL.btn___1ERIL:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___TN3wP {
  color: #fe0000;
}
.errorMessage___ynRDn {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1OPov {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___n26Kb {
  display: none !important;
}
.m-auto___2D9X2 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1oX-Q {
  max-width: 120px !important;
}
.fc___wdI8b .fc-timegrid-col___1uINz.fc-day-today___1iwxD {
  background-color: #ffffff;
}
.fc___wdI8b .fc-timegrid-slot___3TQqx {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___wdI8b .fc-timegrid-slot___3TQqx {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3eQq4 .fc-direction-ltr___1B8eM .fc-timegrid-slot-label-frame___2uoUa {
    font-size: 10px;
  }
  .bookingCalendar___3eQq4 .fc___wdI8b .fc-timegrid-slot-label-cushion___sENnu {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3eQq4 .fc___wdI8b .fc-scrollgrid-section-header___3vWbM .fc-scroller___itNPd {
    overflow: unset !important;
  }
}
.ant-message-notice___1nkSn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___K-fNW {
  width: 20px;
  height: 20px;
}
.bgTransparent___3ntLR {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___fBy8b {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2PLwH {
  position: relative;
}
.inputCustomValue___2PLwH .ant-input___1I2zo {
  padding-right: 50px;
}
.inputCustomValueText___xjOcq {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1Yyn7 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1Yyn7 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___ieGzh .ant-radio-inner___2OHXV {
  border-color: black !important ;
}
.ant-radio-checked___ieGzh .ant-radio-inner___2OHXV:after {
  background-color: black;
}
.ant-radio___3os4P:hover .ant-radio-inner___2OHXV {
  border-color: black;
}
.ant-radio-checked___ieGzh .ant-radio-inner___2OHXV:focus {
  border-color: black;
}
.modal___2LqoW {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2LqoW .close___3ncES {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2LqoW .header___1iiFu {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2LqoW .header___1iiFu p {
  margin: 0;
}
.modal___2LqoW .content___2O61H {
  text-align: center;
  font-size: 14px;
}
.modal___2LqoW .content___2O61H p {
  margin: 0;
}
.modal___2LqoW .actions___1ACSl {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2LqoW .actions___1ACSl .button___nxTcv {
  margin-top: 10px;
}
.modal___2LqoW .actions___1ACSl:hover {
  color: #33c3c7;
}
.fc___wdI8b .fc-timegrid-slot-minor___2WDyJ {
  border-top-style: none;
}
.fc___wdI8b .fc-timegrid-slot___3TQqx {
  height: 1.5em !important;
}
.messageError___2lYw4 .ant-message-custom-content___2VsAM {
  display: flex;
  text-align: start;
}
.messageError___2lYw4 .ant-message-custom-content___2VsAM span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3dSSa {
  background-color: #e6e4e4;
}
.notifyMessage___2AMwF .ant-message-notice-content___gZwun {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2AMwF .ant-message-notice-content___gZwun .anticon___1L677 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2AMwF .ant-message-notice-content___gZwun span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2PXxn {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2PXxn .ant-notification-notice-message___mVpvS,
.notifyCustomize___2PXxn .ant-notification-notice-close___zPS2C,
.notifyCustomize___2PXxn .ant-notification-notice-icon___3Yulp {
  color: white;
}
.notifyCustomize___2PXxn .ant-notification-notice-message___mVpvS {
  margin-left: 35px;
}
.notifyCustomize___2PXxn p {
  margin-bottom: 0;
}
.notifyCustomize___2PXxn p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___MPHmq {
  padding: 16px 14px;
}
.notifyAlertDuplicate___MPHmq .ant-notification-notice-message___mVpvS {
  margin-left: 35px;
}
.notifyAlertDuplicate___MPHmq p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___MPHmq p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2jy-V {
  width: 434px !important;
}
.handleSubmitVote___2jy-V .ant-btn-primary___DFaDJ {
  background: #59c3c7;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 .ant-modal-confirm-body___-jJFP {
  display: flex !important;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 .ant-modal-confirm-body___-jJFP .anticon___1L677 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 .ant-modal-confirm-btns___1UzG4 button {
  font-weight: bold;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 .ant-modal-confirm-btns___1UzG4 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 .ant-modal-confirm-btns___1UzG4 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2jy-V .ant-modal-body___fVJ32 .ant-modal-confirm-btns___1UzG4 .ant-btn-primary___DFaDJ {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3K-l3 {
  margin-bottom: 0;
}
.mt-0___1rKNf {
  margin-top: 0;
}
.tooltipFormat___J_-6q p {
  margin-bottom: 0;
}
.bgWhite___1fpDH {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2nWE3 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2nWE3:hover,
.bgPrimaryBlue___2nWE3:focus,
.bgPrimaryBlue___2nWE3:active {
  background: #4f7ac7;
}
.bgDarkBlue___RZXz0 {
  background-color: #023768 !important;
}
.bgDarkBlue___RZXz0:hover,
.bgDarkBlue___RZXz0:focus,
.bgDarkBlue___RZXz0:active {
  background: #194d7d;
}
.bgGreyBlue___2-BZU {
  background-color: #ebf0f9;
}
.bgLightBlue___3ULZF {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1dP_n {
  background-color: #e4453a;
}
.bgLightRed___1dP_n:hover,
.bgLightRed___1dP_n:focus,
.bgLightRed___1dP_n:active {
  background: #e17871;
}
.bgLightYellow___1E7kt {
  background-color: #ffd603;
}
.bgDarkGray___3gfJG {
  background-color: #333333;
}
.bgMediumGray___i4DEl {
  background-color: #a2a2a2;
}
.bgLightGray___IUADP {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3z5Db {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___4AW26 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___29KMp {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2i-lb {
  color: #3368c7 !important;
}
.textDarkBlue___2jYcQ {
  color: #023768 !important;
}
.textLightBlue___734u_ {
  color: #b2cbf7;
}
.textDarkGray___6k1A2 {
  color: #333333 !important;
}
.textMediumGray___rIwWL {
  color: #a2a2a2 !important;
}
.textLightGray___qW6T_ {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2Nx8a {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3XDhg {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___esoaB {
  border-radius: 5px !important;
}
.rounded-xs___2Plth {
  border-radius: 10px !important;
}
.rounded-sm___2TfbV {
  border-radius: 15px !important;
}
.rounded-md___14ps7 {
  border-radius: 20px !important;
}
.rounded-lg___1ga8q {
  border-radius: 25px !important;
}
.rounded-full___2S6Tj {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2uJYW {
  margin: 0;
}
.mt-2___1am3q {
  margin-top: 0.5rem;
}
.my-2___1qdG8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2B1_M {
  padding: 0;
}
.px-1___333h2 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___26DjY {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1Kn1f {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2waj1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2QQMq {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3iOoH {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___29b4F {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3tQGi {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2iNI_ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2_yMV {
  padding-bottom: 20px;
}
.h-full___30e4s {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3m4CZ {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___OiXOp {
  font-weight: bold !important;
}
.fontWeight400___5-RC6 {
  font-weight: 400 !important;
}
.fontWeight500___1qEfo {
  font-weight: 500 !important;
}
.fontWeight600___2rNvR {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1HVYY {
  font-size: 12px !important;
}
.fontSize14___3KQ6W {
  font-size: 14px !important;
}
.fontSize16___33ikT {
  font-size: 16px !important;
}
.fontSize18___2mYzX {
  font-size: 18px !important;
}
.fontSize20___uctAE {
  font-size: 20px !important;
}
.fontSize24___33lR3 {
  font-size: 24px !important;
}
.eventName___2Pkh1 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1u6hF {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2bAD8 > tr > th,
.ant-table-tbody___3mmhM > tr.ant-table-row___JZrVE > td,
.ant-table-summary___36VVr tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2bAD8 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___20Ww- .ant-table-thead___2bAD8 > tr > th,
.table-portrait___20Ww- .ant-table-tbody___3mmhM > tr.ant-table-row___JZrVE > td,
.table-portrait___20Ww- .ant-table-summary___36VVr tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___20Ww- .bgLightGray___IUADP td {
  background: #ebebeb;
}
.table-portrait___20Ww- .bgLightBlue___3ULZF td {
  background: #b2cbf7;
}
.eventContent___166zk .timeText___3MXob {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3mmhM > tr.bgLightGray___IUADP:hover > td,
.ant-table-tbody___3mmhM > tr > td.ant-table-cell-row-hover___2gCWx {
  background: #ebebeb;
}
.ant-table-tbody___3mmhM > tr.bgLightBlue___3ULZF:hover > td,
.ant-table-tbody___3mmhM > tr > td.ant-table-cell-row-hover___2gCWx {
  background: #b2cbf7;
}
.ant-table-tbody___3mmhM > tr.bgWhite___1fpDH:hover > td,
.ant-table-tbody___3mmhM > tr > td.ant-table-cell-row-hover___2gCWx {
  background: #ffffff;
}
.wrapperFooter___1ofKN {
  padding-top: 50px;
}
@media screen and (max-width: 768px) {
  .wrapperFooter___1ofKN {
    padding-top: 50px;
  }
  .wrapperFooter___1ofKN .footerNotLogin___2qtqE {
    margin-bottom: 9rem;
  }
}
.footer___3sJ_u {
  margin-bottom: 50px;
  bottom: 0;
  width: 100%;
  left: 0;
  padding-bottom: 3rem;
}
@media screen and (max-width: 768px) {
  .footer___3sJ_u {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
}
.footer___3sJ_u .footerContent___1qzuH {
  display: flex;
  color: #989898;
  font-size: 20px;
  justify-content: center;
  letter-spacing: 2px;
}
.footer___3sJ_u .footerContent___1qzuH a {
  color: #989898;
}
.footer___3sJ_u .footerContent___1qzuH li {
  margin-right: 55px;
}
.footer___3sJ_u .footerContent___1qzuH li:last-child {
  margin-right: 0;
}
.footer___3sJ_u .footerContent___1qzuH .imgLogo___2yjb0 {
  width: 140px;
}
.footer___3sJ_u .footerContentMobile___3asBc {
  display: block;
  color: #989898;
  font-size: 20px;
  letter-spacing: 2px;
}
@media screen and (max-width: 767px) {
  .footer___3sJ_u .footerContentMobile___3asBc {
    font-size: 15px;
  }
}
@media screen and (max-width: 411px) {
  .footer___3sJ_u .footerContentMobile___3asBc {
    font-size: 12px;
  }
}
@media screen and (max-width: 344px) {
  .footer___3sJ_u .footerContentMobile___3asBc {
    font-size: 10.5px;
  }
}
.footer___3sJ_u .footerContentMobile___3asBc a {
  color: #989898;
}
.footer___3sJ_u .footerContentMobile___3asBc li {
  margin-right: 55px;
}
@media screen and (max-width: 767px) {
  .footer___3sJ_u .footerContentMobile___3asBc li {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 344px) {
  .footer___3sJ_u .footerContentMobile___3asBc li {
    margin-right: 7px;
  }
}
.footer___3sJ_u .footerContentMobile___3asBc li:last-child {
  margin-right: 0;
}
.footer___3sJ_u .footerContentMobile___3asBc .imgLogo___2yjb0 {
  width: 140px;
}
@media screen and (max-width: 411px) {
  .footer___3sJ_u .footerContentMobile___3asBc .imgLogo___2yjb0 {
    width: 110px;
    margin-top: -6px;
    height: 20px;
  }
}
.footerSuccessVote___240TZ .footerContent___1qzuH li:not(.footerSuccessVote___240TZ .footerContent___1qzuH li:last-child) {
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
  .footerSuccessVote___240TZ .footerContent___1qzuH li {
    margin-bottom: 0;
  }
}
