@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___21hye {
  display: flex;
  justify-content: center;
}
button.btn___2xbXP {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2xbXP {
    padding: 10px;
  }
}
button.btn___2xbXP:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2xbXP:focus {
  outline: none;
}
.btnWhite___2KUYB.btn___2xbXP {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2KUYB.btn___2xbXP:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1dVHV.btn___2xbXP,
.btnGreen___1dVHV.btn___2xbXP:hover,
.btnGreen___1dVHV.btn___2xbXP:active,
.btnGreen___1dVHV.btn___2xbXP:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3fiRP {
  color: #fe0000;
}
.errorMessage___2kfvT {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3CazM {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2vQ10 {
  display: none !important;
}
.m-auto___33XON {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3n4_a {
  max-width: 120px !important;
}
.fc___1Xqsg .fc-timegrid-col___1Qp7h.fc-day-today___x8iNk {
  background-color: #ffffff;
}
.fc___1Xqsg .fc-timegrid-slot___1LYDi {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1Xqsg .fc-timegrid-slot___1LYDi {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___5JwbJ .fc-direction-ltr___1G-qw .fc-timegrid-slot-label-frame___1EoOw {
    font-size: 10px;
  }
  .bookingCalendar___5JwbJ .fc___1Xqsg .fc-timegrid-slot-label-cushion___2td9s {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___5JwbJ .fc___1Xqsg .fc-scrollgrid-section-header___3V-xT .fc-scroller___10ZME {
    overflow: unset !important;
  }
}
.ant-message-notice___1xcbS {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1vK43 {
  width: 20px;
  height: 20px;
}
.bgTransparent___19pra {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1XEuv {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___uJG_M {
  position: relative;
}
.inputCustomValue___uJG_M .ant-input___2tt4u {
  padding-right: 50px;
}
.inputCustomValueText___1RByJ {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___leQII .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___leQII .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___dYQEP .ant-radio-inner___1OkCF {
  border-color: black !important ;
}
.ant-radio-checked___dYQEP .ant-radio-inner___1OkCF:after {
  background-color: black;
}
.ant-radio___x2JlO:hover .ant-radio-inner___1OkCF {
  border-color: black;
}
.ant-radio-checked___dYQEP .ant-radio-inner___1OkCF:focus {
  border-color: black;
}
.modal___p3Rto {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___p3Rto .close___1IrLm {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___p3Rto .header___1DflQ {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___p3Rto .header___1DflQ p {
  margin: 0;
}
.modal___p3Rto .content___dkhlt {
  text-align: center;
  font-size: 14px;
}
.modal___p3Rto .content___dkhlt p {
  margin: 0;
}
.modal___p3Rto .actions___2HSff {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___p3Rto .actions___2HSff .button___2aNc6 {
  margin-top: 10px;
}
.modal___p3Rto .actions___2HSff:hover {
  color: #33c3c7;
}
.fc___1Xqsg .fc-timegrid-slot-minor___xOEAn {
  border-top-style: none;
}
.fc___1Xqsg .fc-timegrid-slot___1LYDi {
  height: 1.5em !important;
}
.messageError___1FLhD .ant-message-custom-content___za1SV {
  display: flex;
  text-align: start;
}
.messageError___1FLhD .ant-message-custom-content___za1SV span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2H7kZ {
  background-color: #e6e4e4;
}
.notifyMessage___ue7av .ant-message-notice-content___5w2nf {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___ue7av .ant-message-notice-content___5w2nf .anticon___3Qkz4 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___ue7av .ant-message-notice-content___5w2nf span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___QKj2z {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___QKj2z .ant-notification-notice-message___3m-Vk,
.notifyCustomize___QKj2z .ant-notification-notice-close___1UxDL,
.notifyCustomize___QKj2z .ant-notification-notice-icon___2w28P {
  color: white;
}
.notifyCustomize___QKj2z .ant-notification-notice-message___3m-Vk {
  margin-left: 35px;
}
.notifyCustomize___QKj2z p {
  margin-bottom: 0;
}
.notifyCustomize___QKj2z p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___ev3gU {
  padding: 16px 14px;
}
.notifyAlertDuplicate___ev3gU .ant-notification-notice-message___3m-Vk {
  margin-left: 35px;
}
.notifyAlertDuplicate___ev3gU p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___ev3gU p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___RPG8u {
  width: 434px !important;
}
.handleSubmitVote___RPG8u .ant-btn-primary___s6k1Q {
  background: #59c3c7;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A {
  padding: 15px 30px 18px;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A .ant-modal-confirm-body___2fHI_ {
  display: flex !important;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A .ant-modal-confirm-body___2fHI_ .anticon___3Qkz4 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A .ant-modal-confirm-btns___1d5YG button {
  font-weight: bold;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A .ant-modal-confirm-btns___1d5YG button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A .ant-modal-confirm-btns___1d5YG button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___RPG8u .ant-modal-body___38M7A .ant-modal-confirm-btns___1d5YG .ant-btn-primary___s6k1Q {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___nV_O2 {
  margin-bottom: 0;
}
.mt-0___28f7P {
  margin-top: 0;
}
.tooltipFormat___2297- p {
  margin-bottom: 0;
}
.bgWhite___3YoJS {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1aV6n {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1aV6n:hover,
.bgPrimaryBlue___1aV6n:focus,
.bgPrimaryBlue___1aV6n:active {
  background: #4f7ac7;
}
.bgDarkBlue___2TEvV {
  background-color: #023768 !important;
}
.bgDarkBlue___2TEvV:hover,
.bgDarkBlue___2TEvV:focus,
.bgDarkBlue___2TEvV:active {
  background: #194d7d;
}
.bgGreyBlue___J2yEX {
  background-color: #ebf0f9;
}
.bgLightBlue___2rwWH {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2lLLC {
  background-color: #e4453a;
}
.bgLightRed___2lLLC:hover,
.bgLightRed___2lLLC:focus,
.bgLightRed___2lLLC:active {
  background: #e17871;
}
.bgLightYellow___2qglG {
  background-color: #ffd603;
}
.bgDarkGray___btCaa {
  background-color: #333333;
}
.bgMediumGray___1wgP_ {
  background-color: #a2a2a2;
}
.bgLightGray___3vj4a {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1e-oT {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3tJ7s {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___KkQJQ {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2K2WM {
  color: #3368c7 !important;
}
.textDarkBlue___1kXvX {
  color: #023768 !important;
}
.textLightBlue___3gmk7 {
  color: #b2cbf7;
}
.textDarkGray___2tqTw {
  color: #333333 !important;
}
.textMediumGray___CzfXC {
  color: #a2a2a2 !important;
}
.textLightGray___DnYDb {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1TI9U {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___68D5h {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___29PxR {
  border-radius: 5px !important;
}
.rounded-xs___29vth {
  border-radius: 10px !important;
}
.rounded-sm___8nGw4 {
  border-radius: 15px !important;
}
.rounded-md___1oTN0 {
  border-radius: 20px !important;
}
.rounded-lg___2P1vT {
  border-radius: 25px !important;
}
.rounded-full___2m6__ {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___wFHLq {
  margin: 0;
}
.mt-2___e5XlK {
  margin-top: 0.5rem;
}
.my-2___2cRYT {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3euBz {
  padding: 0;
}
.px-1___14esS {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2syqm {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___20uZ_ {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3TGKb {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2vS-U {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2yq_K {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3fK-9 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2XaNv {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3rqK5 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1Yz0G {
  padding-bottom: 20px;
}
.h-full___2dGCJ {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2dd6- {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___17ZfP {
  font-weight: bold !important;
}
.fontWeight400___2a9VK {
  font-weight: 400 !important;
}
.fontWeight500___I_2mx {
  font-weight: 500 !important;
}
.fontWeight600___2NbDv {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1RhrZ {
  font-size: 12px !important;
}
.fontSize14___7x9UB {
  font-size: 14px !important;
}
.fontSize16___26_Sz {
  font-size: 16px !important;
}
.fontSize18___3jHeo {
  font-size: 18px !important;
}
.fontSize20___12zds {
  font-size: 20px !important;
}
.fontSize24___3Pv6i {
  font-size: 24px !important;
}
.eventName___2RNsT {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3KGub {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___29qFF > tr > th,
.ant-table-tbody___3JWYc > tr.ant-table-row___tklL4 > td,
.ant-table-summary___1u0L_ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___29qFF > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3_MDQ .ant-table-thead___29qFF > tr > th,
.table-portrait___3_MDQ .ant-table-tbody___3JWYc > tr.ant-table-row___tklL4 > td,
.table-portrait___3_MDQ .ant-table-summary___1u0L_ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3_MDQ .bgLightGray___3vj4a td {
  background: #ebebeb;
}
.table-portrait___3_MDQ .bgLightBlue___2rwWH td {
  background: #b2cbf7;
}
.eventContent___18n8Z .timeText___yVkzc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3JWYc > tr.bgLightGray___3vj4a:hover > td,
.ant-table-tbody___3JWYc > tr > td.ant-table-cell-row-hover___D80Th {
  background: #ebebeb;
}
.ant-table-tbody___3JWYc > tr.bgLightBlue___2rwWH:hover > td,
.ant-table-tbody___3JWYc > tr > td.ant-table-cell-row-hover___D80Th {
  background: #b2cbf7;
}
.ant-table-tbody___3JWYc > tr.bgWhite___3YoJS:hover > td,
.ant-table-tbody___3JWYc > tr > td.ant-table-cell-row-hover___D80Th {
  background: #ffffff;
}
.mainHeader___xbZ0H {
  background-color: #fafafa;
  border-bottom: 1px solid #f3f3f3;
}
@media screen and (max-width: 425px) {
  .mainHeader___xbZ0H {
    padding: 0.3rem 0;
  }
}
.header___1DflQ {
  height: 60px;
  display: flex;
  align-items: center;
  flex-flow: row;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 0 15px;
}
.header___1DflQ .headerTitle___3XuJn {
  display: flex;
  flex-grow: 2;
}
.header___1DflQ .headerTitle___3XuJn .headerLogo___3S1-u {
  margin: auto 0;
  color: #414141;
}
.header___1DflQ .headerTitle___3XuJn .headerLogo___3S1-u .imgLogo___2m6lg {
  width: 132px;
  height: 30px;
  cursor: pointer;
  background-image: url(./static/logo-black.0de3d552.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs {
  max-width: 400px;
  width: 100%;
}
.header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3 {
  border: 1px solid #ececec;
  background: #fff;
  border-radius: 20px;
  padding: 0 15px;
  margin: auto;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  font-size: 1rem;
  line-height: 2.4rem;
  width: 96%;
  color: #3c3c3c;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
}
.header___1DflQ .headerTitle___3XuJn .headerInforBooking___2j9-M {
  font-size: 13px;
  background: #606060;
  padding: 0 16px;
  line-height: 2.4rem;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  display: none;
}
.header___1DflQ .headerTitle___3XuJn .headerInforBooking___2j9-M a {
  font-size: 14px;
  color: white;
}
.header___1DflQ .headerMenu___1SXIG {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItem___11dMM {
  cursor: pointer;
  font-size: 14px;
  margin-right: 56px;
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItem___11dMM:last-child {
  margin-right: 0;
  display: flex;
  align-items: center;
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItemAvt___4jh32 {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  margin-top: 3px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #33c3c7;
  overflow: hidden;
  display: inline-table;
}
@media screen and (max-width: 767px) {
  .header___1DflQ .headerMenu___1SXIG .headerMenuItemAvt___4jh32 {
    margin-right: 5px;
    margin-top: 0;
  }
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItemAvt___4jh32 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItemAvt___4jh32 .defaultAvatar___12-Z4 {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItemAvt___4jh32 .defaultAvatar___12-Z4 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}
.header___1DflQ .headerMenu___1SXIG .headerMenuItemAvt___4jh32 .defaultAvatar___12-Z4 .bgAvatar___2-6HA {
  background-image: url(./static/logo-black.0de3d552.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 768px) {
  .header___1DflQ .headerMenu___1SXIG {
    display: none;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .header___1DflQ .headerTitle___3XuJn {
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .header___1DflQ {
    padding: 0 7px;
  }
  .header___1DflQ .headerTitle___3XuJn {
    justify-content: space-between;
  }
  .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs {
    display: none;
    width: unset;
    flex-grow: 2;
  }
  .header___1DflQ .headerTitle___3XuJn .headerInforBooking___2j9-M {
    display: unset;
  }
}
@media screen and (max-width: 523px) {
  .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3 {
    padding: 0 7px;
    width: 94%;
  }
}
@media screen and (max-width: 511px) {
  .header___1DflQ .headerTitle___3XuJn .headerInforBooking___2j9-M {
    font-size: 11px;
  }
}
@media screen and (max-width: 500px) {
  .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3 {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 463px) {
  .header___1DflQ .headerMenu___1SXIG .headerMenuItem___11dMM {
    font-size: 11px;
  }
}
@media screen and (max-width: 437px) {
  .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs {
    width: 60%;
  }
  .header___1DflQ .headerTitle___3XuJn .headerInforBooking___2j9-M a {
    font-size: 11px;
  }
  .header___1DflQ .headerMenu___1SXIG {
    width: 20%;
  }
}
@media screen and (max-width: 425px) {
  .header___1DflQ {
    height: unset;
  }
  .header___1DflQ .headerTitle___3XuJn .headerLogo___3S1-u .imgLogo___2m6lg {
    width: 100px;
    height: 24px;
  }
  .header___1DflQ .headerTitle___3XuJn .headerInforBooking___2j9-M {
    line-height: 2.1rem;
  }
}
@media screen and (max-width: 408px) {
  .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs {
    width: 55%;
  }
  .header___1DflQ .headerMenu___1SXIG {
    width: 25%;
  }
}
.header___1DflQ .ant-dropdown-link {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .header___1DflQ .ant-dropdown-link {
    min-width: 83px;
  }
}
.header___1DflQ .ant-dropdown-trigger > .anticon.anticon-down,
.header___1DflQ .ant-dropdown-link > .anticon.anticon-down,
.header___1DflQ .ant-dropdown-button > .anticon.anticon-down {
  font-size: 13px;
  margin-left: 5px;
}
.dropdownMenu___YaJYQ {
  border-radius: 20px !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 160px;
  left: -20px;
  top: 30px;
}
.dropdownMenu___YaJYQ li:last-child {
  border-top: 1px solid #a1a0a0;
}
.dropdownMenuAccount___2fGqs {
  margin-top: 7px;
  border-radius: 3px !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownMenuAccount___2fGqs li:last-child {
  border-top: 1px solid #a1a0a0;
}
.logoutConfirm___leQII {
  border-radius: 20px !important;
}
.headerPreviewAdjustment___2DyC1,
.headerPreviewVote___zB_jM {
  display: block;
  margin-top: 0;
  margin-top: initial;
}
.headerPreviewAdjustment___2DyC1 .header___1DflQ,
.headerPreviewVote___zB_jM .header___1DflQ {
  max-width: none;
  max-width: initial;
}
@media screen and (min-width: 1300px) {
  .headerPreviewAdjustment___2DyC1 .header___1DflQ,
  .headerPreviewVote___zB_jM .header___1DflQ {
    max-width: 1300px;
  }
}
@media screen and (max-width: 768px) {
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .ant-dropdown-link,
  .headerPreviewVote___zB_jM .header___1DflQ .ant-dropdown-link {
    min-width: auto !important;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3,
  .headerPreviewVote___zB_jM .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3 {
    border-radius: 10px;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerMenuItemAvt___4jh32,
  .headerPreviewVote___zB_jM .header___1DflQ .headerMenuItemAvt___4jh32 {
    display: none;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerMenu___1SXIG li,
  .headerPreviewVote___zB_jM .header___1DflQ .headerMenu___1SXIG li {
    margin-right: 0;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerMenu___1SXIG .headerMenuItemFirst___2Pz9i,
  .headerPreviewVote___zB_jM .header___1DflQ .headerMenu___1SXIG .headerMenuItemFirst___2Pz9i {
    display: none;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .hideItemNav___10LT4,
  .headerPreviewVote___zB_jM .header___1DflQ .hideItemNav___10LT4 {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3,
  .headerPreviewVote___zB_jM .header___1DflQ .headerTitle___3XuJn .boxMessageName___GWiFs .headerTitleText___29xb3 {
    padding: 0 5px;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerMenuItemAvt___4jh32,
  .headerPreviewVote___zB_jM .header___1DflQ .headerMenuItemAvt___4jh32 {
    display: none;
  }
  .headerPreviewAdjustment___2DyC1 .header___1DflQ .headerMenu___1SXIG li,
  .headerPreviewVote___zB_jM .header___1DflQ .headerMenu___1SXIG li {
    margin-right: 0;
  }
}
.headerPreviewVote___zB_jM .header___1DflQ {
  max-width: 1080px;
}
.headerCalendar___1ardI {
  margin-top: -60px;
  z-index: 1;
}
.headerSuccessVote___AEdhv .header___1DflQ {
  max-width: 1080px !important;
}
.headerSuccessVote___AEdhv .header___1DflQ .headerMenu___1SXIG .headerMenuItem___11dMM .headerMenuItemAvt___4jh32 {
  display: none;
}
