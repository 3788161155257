@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___DniwP {
  display: flex;
  justify-content: center;
}
button.btn___2SHAS {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2SHAS {
    padding: 10px;
  }
}
button.btn___2SHAS:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2SHAS:focus {
  outline: none;
}
.btnWhite___1coM0.btn___2SHAS {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1coM0.btn___2SHAS:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2lubW.btn___2SHAS,
.btnGreen___2lubW.btn___2SHAS:hover,
.btnGreen___2lubW.btn___2SHAS:active,
.btnGreen___2lubW.btn___2SHAS:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2thka {
  color: #fe0000;
}
.errorMessage___3gFyF {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___39WH2 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___29JWU {
  display: none !important;
}
.m-auto___31eSN {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1ka9J {
  max-width: 120px !important;
}
.fc___3lm8r .fc-timegrid-col___2ILdA.fc-day-today___es9-7 {
  background-color: #ffffff;
}
.fc___3lm8r .fc-timegrid-slot___3cN2Q {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3lm8r .fc-timegrid-slot___3cN2Q {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___a0S-f .fc-direction-ltr___2Dc2Y .fc-timegrid-slot-label-frame___3wmKr {
    font-size: 10px;
  }
  .bookingCalendar___a0S-f .fc___3lm8r .fc-timegrid-slot-label-cushion___2YctF {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___a0S-f .fc___3lm8r .fc-scrollgrid-section-header___2D-Q6 .fc-scroller___3z8Fv {
    overflow: unset !important;
  }
}
.ant-message-notice___24yJT {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1WL5w {
  width: 20px;
  height: 20px;
}
.bgTransparent___4vLMd {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___jPCw- {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2E-zb {
  position: relative;
}
.inputCustomValue___2E-zb .ant-input___k32iy {
  padding-right: 50px;
}
.inputCustomValueText___1lEWu {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___16q-4 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___16q-4 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1zxhl .ant-radio-inner___Zi9B7 {
  border-color: black !important ;
}
.ant-radio-checked___1zxhl .ant-radio-inner___Zi9B7:after {
  background-color: black;
}
.ant-radio___1XR33:hover .ant-radio-inner___Zi9B7 {
  border-color: black;
}
.ant-radio-checked___1zxhl .ant-radio-inner___Zi9B7:focus {
  border-color: black;
}
.modal___3Y2Rj {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3Y2Rj .close___3e3xn {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3Y2Rj .header___2xb1H {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3Y2Rj .header___2xb1H p {
  margin: 0;
}
.modal___3Y2Rj .content___1o7ty {
  text-align: center;
  font-size: 14px;
}
.modal___3Y2Rj .content___1o7ty p {
  margin: 0;
}
.modal___3Y2Rj .actions___2AcMS {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3Y2Rj .actions___2AcMS .button___20ACX {
  margin-top: 10px;
}
.modal___3Y2Rj .actions___2AcMS:hover {
  color: #33c3c7;
}
.fc___3lm8r .fc-timegrid-slot-minor___1CCLj {
  border-top-style: none;
}
.fc___3lm8r .fc-timegrid-slot___3cN2Q {
  height: 1.5em !important;
}
.messageError___1BhBv .ant-message-custom-content___3Cf_n {
  display: flex;
  text-align: start;
}
.messageError___1BhBv .ant-message-custom-content___3Cf_n span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2fT_T {
  background-color: #e6e4e4;
}
.notifyMessage___OKgiU .ant-message-notice-content___2mf0J {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___OKgiU .ant-message-notice-content___2mf0J .anticon___2cLKf {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___OKgiU .ant-message-notice-content___2mf0J span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1jn3o {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1jn3o .ant-notification-notice-message___274f_,
.notifyCustomize___1jn3o .ant-notification-notice-close___1lILD,
.notifyCustomize___1jn3o .ant-notification-notice-icon___2EwZY {
  color: white;
}
.notifyCustomize___1jn3o .ant-notification-notice-message___274f_ {
  margin-left: 35px;
}
.notifyCustomize___1jn3o p {
  margin-bottom: 0;
}
.notifyCustomize___1jn3o p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3CWtf {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3CWtf .ant-notification-notice-message___274f_ {
  margin-left: 35px;
}
.notifyAlertDuplicate___3CWtf p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3CWtf p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___oqGqr {
  width: 434px !important;
}
.handleSubmitVote___oqGqr .ant-btn-primary___clo0N {
  background: #59c3c7;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB {
  padding: 15px 30px 18px;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB .ant-modal-confirm-body___bF3UK {
  display: flex !important;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB .ant-modal-confirm-body___bF3UK .anticon___2cLKf {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB .ant-modal-confirm-btns___3hnvD button {
  font-weight: bold;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB .ant-modal-confirm-btns___3hnvD button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB .ant-modal-confirm-btns___3hnvD button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___oqGqr .ant-modal-body___3DrBB .ant-modal-confirm-btns___3hnvD .ant-btn-primary___clo0N {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___Q_RBb {
  margin-bottom: 0;
}
.mt-0___RJ6HO {
  margin-top: 0;
}
.tooltipFormat___MTNJ- p {
  margin-bottom: 0;
}
.bgWhite___1qdFd {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1xbdO {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1xbdO:hover,
.bgPrimaryBlue___1xbdO:focus,
.bgPrimaryBlue___1xbdO:active {
  background: #4f7ac7;
}
.bgDarkBlue___qPO-0 {
  background-color: #023768 !important;
}
.bgDarkBlue___qPO-0:hover,
.bgDarkBlue___qPO-0:focus,
.bgDarkBlue___qPO-0:active {
  background: #194d7d;
}
.bgGreyBlue___3fSBD {
  background-color: #ebf0f9;
}
.bgLightBlue___1rpjt {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1E3vq {
  background-color: #e4453a;
}
.bgLightRed___1E3vq:hover,
.bgLightRed___1E3vq:focus,
.bgLightRed___1E3vq:active {
  background: #e17871;
}
.bgLightYellow___3865I {
  background-color: #ffd603;
}
.bgDarkGray___2eJO5 {
  background-color: #333333;
}
.bgMediumGray___D9SI7 {
  background-color: #a2a2a2;
}
.bgLightGray___2F6EU {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1mUMb {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1ouPT {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___KCoGy {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2vHE7 {
  color: #3368c7 !important;
}
.textDarkBlue___1aZ7s {
  color: #023768 !important;
}
.textLightBlue___Vc8q6 {
  color: #b2cbf7;
}
.textDarkGray___bWN-P {
  color: #333333 !important;
}
.textMediumGray___3rBj7 {
  color: #a2a2a2 !important;
}
.textLightGray___3_rrp {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3LoCg {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2ne07 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3yDVH {
  border-radius: 5px !important;
}
.rounded-xs___34SVT {
  border-radius: 10px !important;
}
.rounded-sm___13K1P {
  border-radius: 15px !important;
}
.rounded-md___1IQ3h {
  border-radius: 20px !important;
}
.rounded-lg___3kf88 {
  border-radius: 25px !important;
}
.rounded-full___1CY1c {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3W7XQ {
  margin: 0;
}
.mt-2___2crvk {
  margin-top: 0.5rem;
}
.my-2___1fcPH {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___21iw3 {
  padding: 0;
}
.px-1___162pR {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1QBT4 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3SnrB {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1ZZTl {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___23z4_ {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3t7kI {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3Aeik {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___h618D {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2JJuV {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3z-VI {
  padding-bottom: 20px;
}
.h-full___3-PBY {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2mRzT {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1C42i {
  font-weight: bold !important;
}
.fontWeight400___18d4k {
  font-weight: 400 !important;
}
.fontWeight500___1pqZB {
  font-weight: 500 !important;
}
.fontWeight600___FImgL {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2-P0T {
  font-size: 12px !important;
}
.fontSize14___159mD {
  font-size: 14px !important;
}
.fontSize16___1nBxm {
  font-size: 16px !important;
}
.fontSize18___39aJE {
  font-size: 18px !important;
}
.fontSize20___2W1Rc {
  font-size: 20px !important;
}
.fontSize24___1-jbA {
  font-size: 24px !important;
}
.eventName___1peVD {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3I4Dh {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3HBPV > tr > th,
.ant-table-tbody___1uPiC > tr.ant-table-row___Nz3Fg > td,
.ant-table-summary___1zDiM tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3HBPV > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___KURGj .ant-table-thead___3HBPV > tr > th,
.table-portrait___KURGj .ant-table-tbody___1uPiC > tr.ant-table-row___Nz3Fg > td,
.table-portrait___KURGj .ant-table-summary___1zDiM tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___KURGj .bgLightGray___2F6EU td {
  background: #ebebeb;
}
.table-portrait___KURGj .bgLightBlue___1rpjt td {
  background: #b2cbf7;
}
.eventContent___kw5-b .timeText___1aiM- {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1uPiC > tr.bgLightGray___2F6EU:hover > td,
.ant-table-tbody___1uPiC > tr > td.ant-table-cell-row-hover___2L09y {
  background: #ebebeb;
}
.ant-table-tbody___1uPiC > tr.bgLightBlue___1rpjt:hover > td,
.ant-table-tbody___1uPiC > tr > td.ant-table-cell-row-hover___2L09y {
  background: #b2cbf7;
}
.ant-table-tbody___1uPiC > tr.bgWhite___1qdFd:hover > td,
.ant-table-tbody___1uPiC > tr > td.ant-table-cell-row-hover___2L09y {
  background: #ffffff;
}
@media screen and (max-width: 767px) {
  .eventTemplateCustom___2XHr_ {
    margin-left: 175px;
  }
}
.eventTemplateList___3LAGP .paging___2RFYW {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .eventTemplateList___3LAGP .paging___2RFYW {
    justify-content: flex-start;
  }
}
.eventTemplateList___3LAGP .paging___2RFYW .onOff___3oW_D {
  background-color: #94d6d6;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.4), inset 1px 2px 5px 0px rgba(0, 0, 0, 0.19);
}
.eventTemplateList___3LAGP .paging___2RFYW .activeIndex___1Wtg2 {
  background-color: #e3f4f4;
}
.eventTemplateList___3LAGP .paging___2RFYW .pageIndex___3aQL8 {
  background: transparent !important;
}
.eventTemplateList___3LAGP .paging___2RFYW .pageIndex___3aQL8:hover {
  background-color: #f0f0f0 !important;
}
.eventTemplateList___3LAGP .paging___2RFYW .disabledTemplate___fDJVk {
  background-color: #95959560 !important;
  cursor: not-allowed;
}
.eventTemplateList___3LAGP .paging___2RFYW .disabledTemplate___fDJVk:hover {
  background-color: #95959560 !important;
}
.eventTemplateList___3LAGP .paging___2RFYW div {
  width: 30px;
  height: 22px;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.4);
  margin-right: 5px;
  cursor: pointer;
}
.eventTemplateList___3LAGP .paging___2RFYW .pencilIcon___2hjEy {
  width: 18px;
  height: 18px;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  margin-left: 10px;
}
.eventTemplateList___3LAGP .paging___2RFYW .pencilIcon___2hjEy img {
  width: 18px;
  height: 18px;
}
.templateModalContent___1SirU {
  max-width: 366px;
  width: 100%;
  margin: auto;
}
.templateModalContent___1SirU .headTitle___2HFSE {
  font-size: 22px;
  text-align: center;
  padding: 50px 0;
}
.templateModalContent___1SirU button {
  margin: 0 auto 50px auto;
}
.templateModalContent___1SirU button:hover {
  border: none;
}
.templateModalContent___1SirU .createAndCopyBtn___sjwk3 {
  display: block;
  height: 55px;
  max-width: 366px;
  width: 100%;
  padding: 0 15px;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
  background-color: #33c3c7;
  color: #ffffff;
  margin-bottom: 5px;
}
.templateModalContent___1SirU .notice___3Aoxi {
  font-size: 15px;
  margin-bottom: 40px;
}
.templateModalContent___1SirU .createBtn___1GKXE {
  display: block;
  height: 55px;
  max-width: 366px;
  width: 100%;
  padding: 0 15px;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
  background-color: #ffffff;
  color: #000000;
  border: none;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.75) !important;
}
.templateModalContent___1SirU .createPreviewBtn___2BanM {
  display: block;
  height: 55px;
  max-width: 366px;
  padding: 0 15px;
  width: 100%;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
  background-color: #ffffff;
  border: 2px solid #33c3c7;
  color: #000000;
}
.templateModalContent___1SirU .createPreviewBtn___2BanM:hover {
  border: 2px solid #33c3c7;
}
.templateModalContent___1SirU .cancelBtn___1FoxG {
  display: block;
  height: 55px;
  max-width: 366px;
  width: 100%;
  padding: 0 15px;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
  background-color: #e8e6e6;
  color: #000000;
}
.templateModalContent___1SirU .cancelBtn___1FoxG:hover,
.templateModalContent___1SirU .cancelBtn___1FoxG:focus {
  border: 2px solid #3d3d3d;
  opacity: 1;
  box-shadow: none;
  transition: none;
}
.templateModalContent___1SirU .cancelBtn___1FoxG:active,
.templateModalContent___1SirU .cancelBtn___1FoxG:hover.templateModalContent___1SirU .cancelBtn___1FoxG:active {
  background: #3c3c3c;
  color: #fff;
  border: 0;
}
.dropdownMenu___3xMdp {
  border-radius: 3px !important;
  overflow: hidden;
  min-width: 160px;
  left: 65px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.dropdownMenu___3xMdp li.dropdownMenuIcon___1lLOG {
  background-color: white !important;
  color: black;
}
.dropdownMenu___3xMdp li.dropdownMenuIcon___1lLOG:hover {
  background-color: #f0f0f0 !important;
  color: black;
}
.dropdownMenu___3xMdp li.disabledTemplate___fDJVk {
  background-color: white !important;
  color: black !important;
  cursor: not-allowed;
}
.dropdownMenu___3xMdp li.disabledTemplate___fDJVk:hover {
  background-color: #f0f0f0 !important;
}
