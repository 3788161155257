@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___27In6 {
  display: flex;
  justify-content: center;
}
button.btn___15wHE {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___15wHE {
    padding: 10px;
  }
}
button.btn___15wHE:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___15wHE:focus {
  outline: none;
}
.btnWhite___2Kwos.btn___15wHE {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2Kwos.btn___15wHE:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2N3KL.btn___15wHE,
.btnGreen___2N3KL.btn___15wHE:hover,
.btnGreen___2N3KL.btn___15wHE:active,
.btnGreen___2N3KL.btn___15wHE:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___zSxA8 {
  color: #fe0000;
}
.errorMessage___1nlTL {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1lJBF {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___Nfi6f {
  display: none !important;
}
.m-auto___16dIS {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3W8Z4 {
  max-width: 120px !important;
}
.fc___2Mn2p .fc-timegrid-col___1-MMr.fc-day-today___3_dSD {
  background-color: #ffffff;
}
.fc___2Mn2p .fc-timegrid-slot___3ZMbX {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2Mn2p .fc-timegrid-slot___3ZMbX {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1nTig .fc-direction-ltr___3Hkb- .fc-timegrid-slot-label-frame___1qA5f {
    font-size: 10px;
  }
  .bookingCalendar___1nTig .fc___2Mn2p .fc-timegrid-slot-label-cushion___21FWq {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1nTig .fc___2Mn2p .fc-scrollgrid-section-header___3X_dj .fc-scroller___1syrA {
    overflow: unset !important;
  }
}
.ant-message-notice___2cxmX {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2VEan {
  width: 20px;
  height: 20px;
}
.bgTransparent___2lDTy {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___zbrSP {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___35oIN {
  position: relative;
}
.inputCustomValue___35oIN .ant-input___2-ffz {
  padding-right: 50px;
}
.inputCustomValueText___mQE9r {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___81Vhc .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___81Vhc .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3bq1T .ant-radio-inner___21EIS {
  border-color: black !important ;
}
.ant-radio-checked___3bq1T .ant-radio-inner___21EIS:after {
  background-color: black;
}
.ant-radio___1sv50:hover .ant-radio-inner___21EIS {
  border-color: black;
}
.ant-radio-checked___3bq1T .ant-radio-inner___21EIS:focus {
  border-color: black;
}
.modal___q3QIf {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___q3QIf .close___2JMgU {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___q3QIf .header___2vmYX {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___q3QIf .header___2vmYX p {
  margin: 0;
}
.modal___q3QIf .content___2A1cs {
  text-align: center;
  font-size: 14px;
}
.modal___q3QIf .content___2A1cs p {
  margin: 0;
}
.modal___q3QIf .actions___1x0fW {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___q3QIf .actions___1x0fW .button___2iQ6n {
  margin-top: 10px;
}
.modal___q3QIf .actions___1x0fW:hover {
  color: #33c3c7;
}
.fc___2Mn2p .fc-timegrid-slot-minor___1LEgX {
  border-top-style: none;
}
.fc___2Mn2p .fc-timegrid-slot___3ZMbX {
  height: 1.5em !important;
}
.messageError___1a-4S .ant-message-custom-content___LTmeg {
  display: flex;
  text-align: start;
}
.messageError___1a-4S .ant-message-custom-content___LTmeg span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3qejq {
  background-color: #e6e4e4;
}
.notifyMessage___3mdOz .ant-message-notice-content___2eXTW {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3mdOz .ant-message-notice-content___2eXTW .anticon___2pNqx {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3mdOz .ant-message-notice-content___2eXTW span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___9l13p {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___9l13p .ant-notification-notice-message___1YQDp,
.notifyCustomize___9l13p .ant-notification-notice-close___2xIh2,
.notifyCustomize___9l13p .ant-notification-notice-icon___3_GfB {
  color: white;
}
.notifyCustomize___9l13p .ant-notification-notice-message___1YQDp {
  margin-left: 35px;
}
.notifyCustomize___9l13p p {
  margin-bottom: 0;
}
.notifyCustomize___9l13p p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___cb16Q {
  padding: 16px 14px;
}
.notifyAlertDuplicate___cb16Q .ant-notification-notice-message___1YQDp {
  margin-left: 35px;
}
.notifyAlertDuplicate___cb16Q p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___cb16Q p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1dQMq {
  width: 434px !important;
}
.handleSubmitVote___1dQMq .ant-btn-primary___21F4u {
  background: #59c3c7;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO .ant-modal-confirm-body___34AYE {
  display: flex !important;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO .ant-modal-confirm-body___34AYE .anticon___2pNqx {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO .ant-modal-confirm-btns___1UG-D button {
  font-weight: bold;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO .ant-modal-confirm-btns___1UG-D button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO .ant-modal-confirm-btns___1UG-D button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1dQMq .ant-modal-body___2ekkO .ant-modal-confirm-btns___1UG-D .ant-btn-primary___21F4u {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1ajkL {
  margin-bottom: 0;
}
.mt-0___1Yh4j {
  margin-top: 0;
}
.tooltipFormat___2dgZ- p {
  margin-bottom: 0;
}
.bgWhite___2OIuC {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2Vxm1 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2Vxm1:hover,
.bgPrimaryBlue___2Vxm1:focus,
.bgPrimaryBlue___2Vxm1:active {
  background: #4f7ac7;
}
.bgDarkBlue___1jFG2 {
  background-color: #023768 !important;
}
.bgDarkBlue___1jFG2:hover,
.bgDarkBlue___1jFG2:focus,
.bgDarkBlue___1jFG2:active {
  background: #194d7d;
}
.bgGreyBlue___1fstq {
  background-color: #ebf0f9;
}
.bgLightBlue___3Kyl7 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2w8o4 {
  background-color: #e4453a;
}
.bgLightRed___2w8o4:hover,
.bgLightRed___2w8o4:focus,
.bgLightRed___2w8o4:active {
  background: #e17871;
}
.bgLightYellow___1opbK {
  background-color: #ffd603;
}
.bgDarkGray___1k3Cg {
  background-color: #333333;
}
.bgMediumGray___75hV6 {
  background-color: #a2a2a2;
}
.bgLightGray___9p5TL {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2mIDu {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___EbciG {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1AY4k {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2PNU9 {
  color: #3368c7 !important;
}
.textDarkBlue___25xAK {
  color: #023768 !important;
}
.textLightBlue___1DQqf {
  color: #b2cbf7;
}
.textDarkGray___1Dfaa {
  color: #333333 !important;
}
.textMediumGray___38svb {
  color: #a2a2a2 !important;
}
.textLightGray___wycrZ {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2xaVI {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1lMk7 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3_G31 {
  border-radius: 5px !important;
}
.rounded-xs___HMX2Y {
  border-radius: 10px !important;
}
.rounded-sm___2sAPc {
  border-radius: 15px !important;
}
.rounded-md___2-kn_ {
  border-radius: 20px !important;
}
.rounded-lg___y9u-l {
  border-radius: 25px !important;
}
.rounded-full___1OzkW {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3x4RZ {
  margin: 0;
}
.mt-2_____jsW {
  margin-top: 0.5rem;
}
.my-2___-S5CY {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___Xt0Q_ {
  padding: 0;
}
.px-1___1J7Da {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3JEF_ {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2KfSG {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3kRn2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1nRfb {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___9ftH4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2pZ80 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___RwaiB {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___y0cvE {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2A-_p {
  padding-bottom: 20px;
}
.h-full___2l3kJ {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3h2RW {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1iy8o {
  font-weight: bold !important;
}
.fontWeight400___3WIwn {
  font-weight: 400 !important;
}
.fontWeight500___EapSz {
  font-weight: 500 !important;
}
.fontWeight600___2njG9 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3Z_3E {
  font-size: 12px !important;
}
.fontSize14___ErnwF {
  font-size: 14px !important;
}
.fontSize16___fvKv1 {
  font-size: 16px !important;
}
.fontSize18___3HD8_ {
  font-size: 18px !important;
}
.fontSize20___1wcWL {
  font-size: 20px !important;
}
.fontSize24___3Mr_x {
  font-size: 24px !important;
}
.eventName___1RUei {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___23ZWX {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1JY6d > tr > th,
.ant-table-tbody___3tW5F > tr.ant-table-row___2iqmM > td,
.ant-table-summary___1jxJS tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1JY6d > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___37Ga- .ant-table-thead___1JY6d > tr > th,
.table-portrait___37Ga- .ant-table-tbody___3tW5F > tr.ant-table-row___2iqmM > td,
.table-portrait___37Ga- .ant-table-summary___1jxJS tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___37Ga- .bgLightGray___9p5TL td {
  background: #ebebeb;
}
.table-portrait___37Ga- .bgLightBlue___3Kyl7 td {
  background: #b2cbf7;
}
.eventContent___2iJNn .timeText___3Zy_o {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3tW5F > tr.bgLightGray___9p5TL:hover > td,
.ant-table-tbody___3tW5F > tr > td.ant-table-cell-row-hover___RJdtL {
  background: #ebebeb;
}
.ant-table-tbody___3tW5F > tr.bgLightBlue___3Kyl7:hover > td,
.ant-table-tbody___3tW5F > tr > td.ant-table-cell-row-hover___RJdtL {
  background: #b2cbf7;
}
.ant-table-tbody___3tW5F > tr.bgWhite___2OIuC:hover > td,
.ant-table-tbody___3tW5F > tr > td.ant-table-cell-row-hover___RJdtL {
  background: #ffffff;
}
.calendarInfoContainer___3dbHJ {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
  position: relative;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1.3rem;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 .bolderColIcon___2H-_z {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 8px;
  margin-right: 5px;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 .normalColIcon___8zfYb {
  display: inline-block;
  background-color: #33c3c7;
  height: 35px;
  width: 3px;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy h2 {
  margin: 0;
  font-size: 24px;
}
.calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy p {
  font-size: 16px;
  width: 100%;
  padding-left: 58px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 {
  width: 100%;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
  display: flex;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 {
  margin-right: 10px;
  display: flex;
  height: 27px;
  padding-top: 4px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 div {
  display: inline-block;
  background-color: #33c3c7;
  height: 100%;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 div.bolderColIcon___2H-_z {
  width: 8px;
  margin-right: 5px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 div.normalColIcon___8zfYb {
  width: 3px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs {
  font-size: 18px;
  width: 100%;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .title___3qKIG {
  font-weight: 600;
  color: #33c3c7;
  text-transform: uppercase;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .description____St9V {
  color: #3c3c3c;
  padding-bottom: 0.8rem;
  font-weight: 600;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .description____St9V p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: normal;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .descriptionOption___-SpGk {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .descriptionOption___-SpGk div {
  width: 50%;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .descriptionOption___-SpGk div:last-child {
  text-align: right;
  color: #33c3c7;
  cursor: pointer;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputRadio___1JvNV .inputBlockTime___3JNRN {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputTool___vJ7YL p {
  font-size: 14px;
  margin-bottom: 0.2rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputTool___vJ7YL p span {
  color: #33c3c7;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputTool___vJ7YL div {
  display: flex;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputTool___vJ7YL div input {
  padding: 7px 11px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputTool___vJ7YL div span {
  width: 7%;
  line-height: 40px;
  text-align: right;
  font-size: 16px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA {
  display: flex;
  justify-content: space-between;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH {
  border: 1px solid #e2dfdf;
  border-radius: 13px;
  width: 24%;
  text-align: center;
  cursor: pointer;
  background: #fff;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH img {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH p {
  font-size: 12px;
  margin-bottom: 0.15rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:hover {
  border-color: #33c3c7;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:nth-child(1) p {
  text-transform: uppercase;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:nth-child(2) img {
  padding-bottom: 0.8rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:nth-child(3) img {
  padding-bottom: 0.15rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:nth-child(4) img {
  padding-top: 1.2rem;
  padding-bottom: 0.7rem;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationActive___EDW_M {
  border: 2px solid #33c3c7;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .btnDropdownTemplate___2iNHi {
  width: 100%;
  padding: 8px 10px;
  height: unset;
  position: relative;
  border: 2px solid #f0eeee;
  border-radius: 7px;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .btnDropdownTemplate___2iNHi p {
  margin-bottom: 0;
  color: #3c3c3c;
  font-weight: 500;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .btnDropdownTemplate___2iNHi img {
  position: absolute;
  right: 2.9%;
  top: 40%;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .errorNotice___c42LR {
  visibility: hidden;
  font-size: 11px;
  line-height: 1;
  transition: visibility 0.3s, color 0s;
  color: transparent;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .errorNotice___c42LR a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .showErrorNotice___1MjKx {
  visibility: visible;
  color: red;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .ant-radio-inner {
  height: 27px;
  width: 27px;
  position: relative;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .ant-radio-inner:after {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #00b6d3;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid white;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .ant-radio:hover .ant-radio-inner {
  border-color: #00b6d3 !important;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .ant-radio-checked .ant-radio-inner:focus {
  border-color: #00b6d3 !important;
}
.calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .ant-radio-wrapper span:last-child {
  margin: auto 0;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ {
  width: 49%;
  display: flex;
  font-size: 16px;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .fieldIcon___3dTMk {
  width: 7px;
  min-width: 7px;
  height: 17px;
  margin-top: 5px;
  background-color: #414141;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ p {
  margin: 0;
  min-width: 150px;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ input {
  flex-grow: 1;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .selectInput___3D5pN {
  width: 100%;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .errorNotice___c42LR {
  visibility: hidden;
  font-size: 12px;
  line-height: 1;
  transition: visibility 0.3s, color 0s;
  color: transparent;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .errorNotice___c42LR a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .showErrorNotice___1MjKx {
  visibility: visible;
  color: red;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .errorNoticeLocation___3k0Wk {
  font-size: 12px;
  color: red;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .errorNoticeLocation___3k0Wk a {
  color: #33c3c7;
  text-decoration: underline;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .moreInputField___1nDRG {
  width: 100%;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .radioSelect___LqtCN {
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid #ececec !important;
  width: 100%;
  padding: 2px 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .radioSelect___LqtCN .ant-radio-group {
  display: flex;
  justify-content: space-around;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ .title___3qKIG {
  display: flex;
}
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(2),
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(4),
.calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(6) {
  margin-left: auto;
}
.calendarInfoContainer___3dbHJ .btnCloseModal___bb6Jq {
  display: none;
  background: transparent;
  outline: none;
  border: 0 transparent;
  position: absolute;
  right: -3px;
  top: 11px;
}
.calendarInfoContainer___3dbHJ .btnCloseModal___bb6Jq img {
  width: 60%;
  height: 60%;
}
.calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
  border: none;
  border-radius: 0 0 5px 5px;
  display: none;
  justify-content: space-evenly;
  padding: 1rem 0;
  width: 100%;
  margin-top: 1rem;
}
.calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL button {
  width: 40%;
  padding: 0.5rem 0.2rem;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  color: white;
}
.calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL button:first-child {
  background: #d2d2d2;
}
.calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL button:last-child {
  background: #00b6d3;
}
.calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL button:last-child.active___2NDkC {
  background: #3f949e;
}
@media screen and (max-width: 1440px) {
  .calendarInfoContainer___3dbHJ {
    padding: 20px 20px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy h2 {
    font-size: 22px;
  }
}
@media screen and (max-width: 1409px) {
  .calendarInfoContainer___3dbHJ {
    padding: 20px 20px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 {
    margin-right: 5px;
    height: 23px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .title___3qKIG {
    font-size: 16px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .description____St9V {
    font-size: 15px;
  }
}
@media screen and (max-width: 1280px) {
  .calendarInfoContainer___3dbHJ {
    padding: 20px 18px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .calendarInfoContainer___3dbHJ {
    padding: 14px 10px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 {
    margin-right: 7px;
    height: 28px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 .bolderColIcon___2H-_z {
    height: 100%;
    margin-right: 3px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 .normalColIcon___8zfYb {
    height: 100%;
    width: 3px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy h2 {
    margin: 0;
    font-size: 20px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 {
    margin-right: 5px;
    height: 23px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .title___3qKIG {
    font-size: 18px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .description____St9V,
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .descriptionOption___-SpGk {
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy h2 {
    font-size: 17px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 {
    margin-right: 5px;
    height: 23px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .title___3qKIG {
    font-size: 18px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .description____St9V,
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .descriptionOption___-SpGk {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .calendarInfoContainer___3dbHJ {
    padding: 25px 0 0 0;
  }
  .calendarInfoContainer___3dbHJ .btnCloseModal___bb6Jq {
    display: unset;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 {
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy {
    flex-wrap: nowrap;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy .titleIcon___14IF8 {
    display: none;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy h2 {
    font-weight: 600;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 {
    padding: 0 10px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
    padding-left: 0.6rem;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 {
    margin-right: 5px;
    height: 25px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 div.bolderColIcon___2H-_z {
    width: 4px;
    margin-right: 2px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 div.normalColIcon___8zfYb {
    width: 2px;
  }
  .calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL {
    display: flex;
  }
  .calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL button {
    width: 31%;
    padding: 0.8rem 0.2rem;
  }
  .calendarInfoContainer___3dbHJ .moreInput___3o9i3 {
    flex-wrap: unset;
    flex-direction: column;
  }
  .calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ {
    width: 100%;
    align-items: unset;
    flex-direction: column;
  }
  .calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(2),
  .calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(4),
  .calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(6) {
    margin-left: unset;
  }
  .calendarInfoContainer___3dbHJ .moreInput___3o9i3 .inputField___1XXKQ:nth-child(6) {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 425px) {
  .calendarInfoContainer___3dbHJ {
    padding: 25px 0 6rem 0;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 {
    padding: 0 10px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
    padding: 0;
    margin-top: 1rem;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .titleIcon___14IF8 div.normalColIcon___8zfYb {
    display: none;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH {
    border-radius: 7px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .inputTool___vJ7YL p {
    font-size: 11px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .description____St9V {
    padding-bottom: 0.5rem;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 {
    padding: 0 10px;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 .stepCalendarTitle___JK1xy {
    padding-bottom: 0;
  }
  .calendarInfoContainer___3dbHJ .calendarInfoContent___3AZ24 button {
    width: 46%;
  }
  .calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL {
    position: fixed;
    bottom: 4%;
    width: 95.6%;
  }
  .calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL button {
    width: 46%;
  }
}
@media screen and (max-width: 375px) {
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ {
    padding: 0;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH img {
    padding: 0 0 0.3rem 0 !important;
    width: 26px;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH p {
    font-size: 9px;
    margin-bottom: 0;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:nth-child(3) {
    padding: 0.5rem 0;
  }
  .calendarInfoContainer___3dbHJ .formSettingBasic___1PkT5 .stepSetting___gBTsm .stepSettingItem___k-kRJ .content___2A1cs .locationSetting___QyurA .locationItem___24BKH:nth-child(3) img {
    width: 25px;
    margin-top: 0.3rem;
    padding: 0 !important;
  }
  .calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL {
    width: 95.1%;
  }
}
@media screen and (max-width: 339px) {
  .calendarInfoContainer___3dbHJ .groupSaveBasicSetting___3jOYL {
    width: 94.8%;
  }
}
.optionStyle___1pIsI {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.optionStyle___1pIsI .edit___3ZOu3 {
  display: none;
}
.optionStyle___1pIsI:hover .edit___3ZOu3 {
  display: block;
  width: 40px;
  height: 22px;
  display: flex;
  justify-content: flex-end;
  z-index: 111;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w {
  display: flex;
  justify-content: space-between;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW {
  display: flex;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTool___VMUur {
  position: absolute;
  top: 18px;
  left: -21px;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTool___VMUur.active___2NDkC {
  position: unset;
  margin-right: 1rem;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTool___VMUur.active___2NDkC .btnTogetherSettingBasic___2Lc-- img {
  transform: rotateY(180deg);
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTool___VMUur .btnTogetherSettingBasic___2Lc-- {
  background: white;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 7px;
  cursor: pointer;
  height: 35px;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU {
  display: flex;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 {
  margin-right: 15px;
  display: flex;
  height: 35px;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 div {
  background-color: #33c3c7;
  display: inline-block;
  height: 100%;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 .bolderColIcon___2H-_z {
  width: 8px;
  margin-right: 5px;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 .normalColIcon___8zfYb {
  width: 3px;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleText___1sWeK h2 {
  margin: 0;
  font-size: 24px;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleText___1sWeK h2 br {
  display: none;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderRight___3IkLp {
  display: none;
}
.calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderRight___3IkLp button {
  background: #636363;
  color: white;
  border: 1px solid #636363;
  border-radius: 6px;
  padding: 5px 1rem;
}
.calendarViewContainer___3-QWA .titleDescript___ta6_j {
  margin-left: 30px;
  font-size: 16px;
  margin-bottom: 0;
}
.calendarViewContainer___3-QWA .buttonDeleteCalendarContainer___2d3oP {
  text-align: right;
  padding-bottom: 1rem;
}
.calendarViewContainer___3-QWA .buttonDeleteCalendarContainer___2d3oP button {
  background: transparent;
  border: 0 solid;
  cursor: pointer;
}
.calendarViewContainer___3-QWA .buttonDeleteCalendarContainer___2d3oP button img {
  color: white;
  margin-right: 5px;
}
.calendarViewContainer___3-QWA .buttonDeleteCalendarContainer___2d3oP button .buttonDeleteCalendar___cgokN {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
}
.calendarViewContainer___3-QWA .showOnMobile___3JXUG {
  display: none;
}
.calendarViewContainer___3-QWA .notShowOnMobile___3byPN {
  display: block;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #33c3c7;
  border: 1px solid #9af0ed;
  border-radius: 5px;
  overflow: visible;
  position: relative;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn .resizeIconTop___3iBN1 {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn .resizeIconTop___3iBN1 div {
  height: 2px;
  background-color: #3c3e3e;
  width: 17px;
  margin: 1px 0;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn:hover {
  background-color: #33c3c7;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn:hover .resizeIconBottom___3_Ydi,
.calendarViewContainer___3-QWA .eventContent___2iJNn:hover .resizeIconTop___3iBN1 {
  opacity: 1;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn .timeText___3Zy_o {
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.calendarViewContainer___3-QWA .eventContent___2iJNn .timeText___3Zy_o p {
  font-weight: bold;
  margin: 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___3-QWA .eventContent___2iJNn .timeText___3Zy_o p p {
    margin: 10px 0;
  }
  .calendarViewContainer___3-QWA .eventContent___2iJNn .timeText___3Zy_o p span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    left: 50%;
  }
}
.calendarViewContainer___3-QWA .recentAdded___TweBP:hover,
.calendarViewContainer___3-QWA .recentAdded___TweBP:focus,
.calendarViewContainer___3-QWA .recentAdded___TweBP:active,
.calendarViewContainer___3-QWA .recentAdded___TweBP:visited {
  border: 2px dotted #33c3c7;
  background-color: #d1f3f2;
}
.calendarViewContainer___3-QWA .recentAdded___TweBP:hover .timeText___3Zy_o,
.calendarViewContainer___3-QWA .recentAdded___TweBP:focus .timeText___3Zy_o,
.calendarViewContainer___3-QWA .recentAdded___TweBP:active .timeText___3Zy_o,
.calendarViewContainer___3-QWA .recentAdded___TweBP:visited .timeText___3Zy_o {
  color: #818c8c !important;
}
.calendarViewContainer___3-QWA .isBooked___2_TVy {
  pointer-events: none;
  background-color: #e8e6e6;
  display: flex;
  overflow: hidden;
  border: 1px solid #e8e6e6;
}
.calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o {
  margin: 0;
  width: 100%;
  justify-content: left;
  flex-wrap: wrap;
  overflow: hidden;
}
.calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .time___1PD37 {
  width: 100%;
  text-align: left;
  color: #5b5b5b;
  padding: 0 5px;
  font-weight: normal;
  letter-spacing: 1px;
  text-shadow: 1px 0 0 #5b5b5b, 1px 0 0 #5b5b5b;
  position: relative;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .time___1PD37 {
    text-shadow: none;
    text-align: center;
    padding: 0;
    font-size: 11px;
  }
  .calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .time___1PD37 p {
    margin: 10px 0;
  }
  .calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .time___1PD37 span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    left: 50%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .time___1PD37 {
    font-size: 10px;
  }
}
.calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .eventName___1RUei {
  width: 100%;
  text-align: left;
  color: #5b5b5b;
  font-weight: bold;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___3-QWA .isBooked___2_TVy .timeText___3Zy_o .eventName___1RUei {
    padding: 0;
    font-weight: normal;
    font-size: 11px;
    text-align: center;
  }
}
.calendarViewContainer___3-QWA .isBooked___2_TVy:hover {
  background-color: #e8e6e6;
  border: 1px solid #555555;
}
.calendarViewContainer___3-QWA .isBooked___2_TVy:hover .resizeIconBottom___3_Ydi,
.calendarViewContainer___3-QWA .isBooked___2_TVy:hover .resizeIconTop___3iBN1 {
  opacity: 0;
}
.calendarViewContainer___3-QWA .currentEvent___1kOqi {
  background-color: #279ca0;
}
.calendarViewContainer___3-QWA .currentEvent___1kOqi .timeText___3Zy_o {
  color: #ffffff;
}
.calendarViewContainer___3-QWA .ant-switch-checked {
  min-width: 68px;
  height: 19px;
  border: 1px solid #e2dfdf;
  box-sizing: border-box;
  background-color: #e3f4f4 !important;
}
.calendarViewContainer___3-QWA .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 23px);
  width: 22px;
  height: 15px;
  top: 1px;
}
.calendarViewContainer___3-QWA .ant-switch-checked .ant-switch-handle::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAudJREFUOI1NlM2KHVUUhb/qW7e7YxttM9CJtmKrDQHF0BNFfACjw0Rw6BMY5xLyBupcHDlRM85IEAM+QRAUfwa300kgpPv+VdU5Z/85qLr3ZrBYB6r4ap21N1V9f3KfC1sjPrm4tw98ERHXIuKNiNgNdwIgYu29xVpultz9H3e/LSLfvnZ4OAWofnzwiI8v7l0G7pyKvvpfKTwWZWFGZ0ZyxyLQCCLA6M/ZnKUZrRp7BB9sj3mviklO6eq7x8d/VMv5/Dmq6t6fRQ4mRUjuNGYsVFmqkQewMYCHj2TvweciPCmCdC3vE3y2XU+klHdqqurLh2oHp6JYBOJOMqMdtEpsQx0+nLMHnRmNGloKnjN3m4aXxqODK1vVjZqITyciSDidO60ZSzMWasxV14k9INjAiwdLNRpVvGQ8ZTwl7s4LR8/sXKsDjs50k3CuxkyUcxEWqiR3PGAYIwAeUIYqPPfAlR60LW3lb9bhPpoPgKUaM1WmKgPYKO44Q9qBHdD3nDOe05C2w1OHdR3tiJ3a3Vmo0pizNGUmylSU2TA8Wa3WKm5Ev37ufQV5BU1416fuRlC7O1PV9SbMpNdclCJCuG+Aa7gTZj2o6/AuYd0Az4lcV9TubnPR0VSVhfZpp6poSoQIYTZcfrAIIpxQ7YFti7UN3nVYSlSloDvjXJvZX9vhl58UYa7KXGQzDCng/lRq1jWEat9p02Bdi6dElMwLEZjZ31vu/tOV8ZgzEWY5Y20zqMXbFmuawftk1iyfUv/Mu4TnTIjyVj0CuF2dTibPm/u9rx89fuXXs3NsNYiUCSl9FUPiiFjfIESG/d28+/pWxYcXdk/G4/Hb9fWTh7MfXrz00ee74zsvb9cHvyyE+1236Vi1r2K1DeFgQxUibIlwieBod4fDejSpR6OrN2/dmlXHv/2O58x3+8/uL5vmRts019uuO0wp7ZRSEBHcvf+TDW5mmBmqiqpmNfs3In7eqqpvvrp5cwrwP4MiKimxFYSsAAAAAElFTkSuQmCC) !important;
  right: 3px;
  left: 0 !important;
}
.calendarViewContainer___3-QWA .ant-switch {
  min-width: 68px;
  height: 21px;
  border: 1px solid #e2dfdf;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
}
@media screen and (max-width: 576px) {
  .calendarViewContainer___3-QWA .ant-switch {
    min-width: 30px;
  }
}
.calendarViewContainer___3-QWA .ant-switch .ant-switch-handle {
  width: 22px;
  height: 15px;
  top: 1px;
}
.calendarViewContainer___3-QWA .ant-switch .ant-switch-handle::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAPCAYAAADgbT9oAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAjhJREFUOI1tkz1OHEEQRr9me4Uj5AsYLJAILK1siUtYxNjXQHZsIS5gH8QyqX0P/yQEBCDyZT3TXVU9VUWw070D2pFKPcl8/epVTXhYLhFCQAjhJYBP7n7m7ofu/sLN4ADg3s714a1MlczsxsyuSinfXh8dLQEg/F+tEEJ4A+AX3PfdHeYON0N9nwbWx8zgZjAzqBl0GNCndM9E79+dnPwN3Wq1hxB+AzjAJNTG8i2hjbiGqiL1PVJKSCndicgiIoRzAAcANm3XCyaFqZIxvEIUERAzUkroU3rFzOcR7h8QQiMw9ydtNs9Tx/WSkZaIkHNGyhmp78HMH6MDx5j4rApUdaNiomEabGYtlHJu4cx8HN1s14ENreqmJo63BYsIcqVNCXntGMw8jza2Oh2Ybhne88FVWiJaKxgrr4kR68fVp5o12lIKVHXrqqnqegs227BWQgQRQTQzNrPd58SUM0QEqvpkE6rbMgzIOaPre/STcGaGqpZoqtfmvqiUqoo0DoNFGn3TAEBVMQwDUkroug5d1zUFpRS4+3VUsx9uttCx9Ty2lEfiQXWzyyOtmmEoBZmohRLRWp0ZdkL4Hu5vb/fM7I+I7KeU2gBqsA7Dk5+l6hKRNjwiarQA7ubz+SKa2aqUcppS+ply3q/DIGYIM8owtO2owVWFiIBFIKXAzBBCuJ/NZqcXl5cPUVXBIv9E5C0RfSaiM2I+JKJdEUEZP2qb495+nlIKTJUB3OzMZlcxxq9fLi6WAPAI1gYvMTb3g6oAAAAASUVORK5CYII=);
  background-color: unset;
  box-shadow: none;
  left: 3px;
  top: 3px;
}
.calendarViewContainer___3-QWA .ant-switch:hover {
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.36);
}
.calendarViewContainer___3-QWA .fc-scroller .fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none;
}
.calendarViewContainer___3-QWA .fc-scrollgrid-section-header .fc-scroller::-webkit-scrollbar {
  border-bottom: 1px solid #ddd;
}
.calendarViewContainer___3-QWA .ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.calendarViewContainer___3-QWA .fc-event-main {
  background-color: transparent;
  border-radius: 7px;
}
@media screen and (max-width: 767px) {
  .calendarViewContainer___3-QWA .fc .fc-view-harness {
    min-height: 400px;
  }
}
.calendarViewContainer___3-QWA .fc-media-screen .fc-timegrid-event {
  border: none;
}
.calendarViewContainer___3-QWA .fc-media-screen .fc-timegrid-event-harness {
  background-color: #e3f4f4;
  border-radius: 10px;
  overflow: unset;
}
.calendarViewContainer___3-QWA .fc-media-screen .fc-timegrid-event-harness:hover {
  width: 100% !important;
  left: 0 !important;
  z-index: 0.9 !important;
}
.calendarViewContainer___3-QWA .fc-media-screen .fc-timegrid-event {
  border-radius: 10px;
  overflow: unset;
  background-color: #e3f4f4;
}
@media screen and (max-width: 768px) {
  .calendarViewContainer___3-QWA .stepHeaderTool___VMUur,
  .calendarViewContainer___3-QWA .titleDescript___ta6_j,
  .calendarViewContainer___3-QWA .buttonDeleteCalendarContainer___2d3oP {
    display: none;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w {
    padding: 0 15px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderRight___3IkLp {
    display: unset;
  }
}
@media screen and (max-width: 700px) {
  .calendarViewContainer___3-QWA .date___lH-sU {
    margin-bottom: 0.2rem;
    font-size: 18px;
    font-weight: 500;
  }
  .calendarViewContainer___3-QWA .notShowOnMobile___3byPN {
    display: none;
  }
  .calendarViewContainer___3-QWA .showOnMobile___3JXUG {
    display: block;
    margin-bottom: 0.2rem;
    font-weight: 500;
  }
}
@media screen and (max-width: 612px) {
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 {
    height: 25px;
    margin-right: 10px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 .bolderColIcon___2H-_z {
    width: 5px;
    margin-right: 2px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleIcon___14IF8 .normalColIcon___8zfYb {
    width: 2px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleText___1sWeK h2 {
    font-weight: 600;
    font-size: 19px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleText___1sWeK h2 br {
    display: block;
  }
  .calendarViewContainer___3-QWA .titleDescript___ta6_j {
    margin-left: 25px;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 550px) {
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleText___1sWeK h2 {
    font-size: 17px;
  }
}
@media screen and (max-width: 425px) {
  .calendarViewContainer___3-QWA .titleDescript___ta6_j {
    font-size: 13px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderLeft___iTfgW .stepHeaderTitle___1CSoU .titleText___1sWeK h2 {
    font-size: 13px;
  }
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderRight___3IkLp button {
    font-size: 12px;
    padding: 3px 0.8rem;
  }
}
@media screen and (max-width: 375px) {
  .calendarViewContainer___3-QWA .stepHeader___1po_w .stepHeaderRight___3IkLp button {
    padding: 3px 0.5rem;
  }
}
.ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.fc-event-resizer-end {
  position: relative;
  height: 15px !important;
}
.fc-event-resizer-end::before {
  content: '';
  height: 2px;
  width: 17px;
  position: absolute;
  bottom: 10px;
  background-color: #3c3e3e;
  left: 50%;
  transform: translateX(-50%);
}
.fc-event-resizer-end::after {
  content: '';
  height: 2px;
  width: 17px;
  position: absolute;
  bottom: 5px;
  background-color: #3c3e3e;
  left: 50%;
  transform: translateX(-50%);
}
.deleteEventBtn___wcNua {
  background-color: #555555;
  position: absolute;
  bottom: calc(100% + 13px);
  height: 25px;
  width: 75px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 8px;
  z-index: 99;
}
@media screen and (max-width: 767px) {
  .deleteEventBtn___wcNua {
    height: 40px;
    width: 40px;
    flex-flow: column;
  }
}
.deleteEventBtn___wcNua::after {
  content: '';
  width: 10px;
  height: 24px;
  border-top: 10px solid #555555;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
  top: 100%;
}
.deleteEventBtn___wcNua img {
  margin-right: 7px;
  width: 11px;
  height: 13px;
}
@media screen and (max-width: 767px) {
  .deleteEventBtn___wcNua img {
    margin-right: 0;
  }
}
.imageEventTypeIcon___38Ahh {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-top: -6px;
}
.createEventButtonGroup___VhXA1 .editDetail___147lr {
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #707070;
  margin: 50px 0 70px 0;
}
.createEventButtonGroup___VhXA1 .editDetail___147lr .editDetailButton___MbnQM {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 34px;
  min-width: 105px;
  padding: 0 20px;
  font-size: 14px;
  color: #434343;
  border-radius: 16px;
  background-color: #e8e6e6;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C {
  display: flex;
  flex-flow: row;
  margin-bottom: 3rem;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .previewBtnZone___2wx93 {
  width: 34%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .previewBtnZone___2wx93 .previewButton___Jy_pm {
  margin: auto;
  height: 32px;
  min-width: 170px;
  background-color: #555555;
  color: #fffefe;
  text-align: center;
  border-radius: 6px;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .btnGroup___27In6 {
  width: 66%;
  display: flex;
  justify-content: flex-start;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .btnGroup___27In6 button {
  height: 48px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 17px;
  border-radius: 19px;
  width: 100%;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .btnGroup___27In6 button:first-child {
  margin: 0 20px 0 0;
  color: #535353;
  background-color: #fffefe;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border: none;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .btnGroup___27In6 button:last-child {
  margin: 0 20px;
  color: #fffefe;
  background-color: #00b6d3;
  transition: 0.3s;
}
.createEventButtonGroup___VhXA1 .buttonZone___U6x3C .btnGroup___27In6 .disabledBtnSave___KSL4a {
  background: #a6a3a3 !important;
}
@media screen and (max-width: 768px) {
  .createEventButtonGroup___VhXA1 .editDetail___147lr {
    margin-bottom: 2rem;
  }
  .createEventButtonGroup___VhXA1 .buttonZone___U6x3C {
    flex-flow: column;
    margin-bottom: 1rem;
  }
  .createEventButtonGroup___VhXA1 .buttonZone___U6x3C .previewBtnZone___2wx93 {
    width: 100%;
    justify-content: center;
    order: 2;
  }
  .createEventButtonGroup___VhXA1 .buttonZone___U6x3C .btnGroup___27In6 {
    display: none;
  }
}
.calendarView___22Jyl {
  width: 100%;
  position: relative;
}
.calendarView___22Jyl .disabledNav___HhNch {
  pointer-events: none;
  display: none !important;
}
.calendarView___22Jyl .ant-spin-nested-loading {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.calendarView___22Jyl .fc .fc-scroller {
  overflow: auto !important;
}
.calendarView___22Jyl .calendarContainer___132K0 {
  display: flex;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP {
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: -39px;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP .currentMonth___DoxGH {
  font-size: 19px;
  letter-spacing: 2px;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP .groupBtn___3y3-a {
  display: flex;
  justify-content: space-between;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP .groupBtnPrevious___2nbSv {
  justify-content: space-between;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP button {
  display: flex;
  background: transparent;
  border: 0;
  padding: 2px 0;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP button span {
  line-height: 35px;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP button .nextBtnMobile___3a-DT {
  height: 35px;
  width: 30px;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP button .nextBtnMobile___3a-DT:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(180deg);
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP button .prevBtnMobile___-4q-B {
  height: 35px;
  width: 30px;
  border: none;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}
.calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP button .prevBtnMobile___-4q-B:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(0deg);
}
.calendarView___22Jyl .calendarContainer___132K0 .multiBtn___2snuq {
  justify-content: space-between;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD,
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .nextBtn___1RCCx {
  background-color: #ffffff;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 3px, rgba(0, 0, 0, 0.23) 0 0 7px;
  border: none;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 52px;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD .btnImg___20mdl,
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .nextBtn___1RCCx .btnImg___20mdl {
  height: 13px;
  width: 14px;
  background-repeat: no-repeat;
  background-position: center;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD {
  left: 58px;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD .btnImg___20mdl {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  transform: rotate(180deg);
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD:hover .btnPreImg___3G9AB {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(0deg);
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .nextBtn___1RCCx {
  right: -18px;
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .nextBtn___1RCCx .btnImg___20mdl {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
}
.calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .nextBtn___1RCCx:hover .btnNextImg___AVFYf {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(180deg);
}
@media screen and (max-width: 1023px) {
  .calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .nextBtn___1RCCx {
    right: -12px;
  }
}
@media screen and (max-width: 768px) {
  .calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b {
    top: 0;
    overflow-x: auto;
    right: 0;
    width: 100%;
    justify-content: flex-end;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD {
    position: -webkit-sticky;
    position: sticky;
    left: 50px;
    box-shadow: none;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b .prevBtn___QgjyD {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    box-shadow: none;
  }
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT {
  width: 250px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
  margin-right: 10px;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6 {
  display: flex;
  color: #000000;
  cursor: pointer;
  padding: 1rem;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6 .beforeFieldIcon___1UScg {
  font-size: 16px;
  width: 7px;
  min-width: 7px;
  height: 17px;
  margin-right: 6px;
  background-color: #414141;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6 .listTeamTitle___JpZ0Q {
  margin-bottom: 0;
  margin-right: 10px;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6 .chevronDownImage___3BD4Z {
  color: #565656;
  margin-left: 1rem;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6:hover {
  color: #33c3c7;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6:hover .beforeFieldIcon___1UScg {
  background-color: #33c3c7;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonTeamMemberShow___1lkV6:hover .chevronDownImage___3BD4Z {
  color: #33c3c7;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .listCheckBoxTeamMember___1x6Iy {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-wrap: anywhere;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .listCheckBoxTeamMember___1x6Iy .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--background-color);
  border-color: var(--border-color);
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .addMember___1nqyb {
  padding: 1rem 1rem 0 1rem;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonAddMember___2UgR7 {
  padding-left: 1rem;
  padding-top: 1rem;
  color: #07989c;
  cursor: pointer;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .buttonAddMember___2UgR7:hover {
  color: #33c3c7;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT .lineClamp___2KbUi {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT {
  position: absolute;
  z-index: 100;
  top: 46px;
  left: -1%;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR {
  border-radius: 7px;
  border: none;
  height: auto;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 6px, rgba(0, 0, 0, 0.23) 0 0 6px;
  z-index: 1;
  background: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 13px 4px;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR .userBoldImage___3tTrc {
  color: #565656;
  margin-left: 3px;
  width: 17px;
  height: 16px;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR .chevronRightImage___1Jr0Q {
  font-size: 15px;
  color: #565656;
}
.calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR:hover .chevronRightImage___1Jr0Q {
  color: #33c3c7;
}
@media screen and (max-width: 768px) {
  .calendarView___22Jyl {
    overflow-x: auto;
    padding-top: 55px;
  }
  .calendarView___22Jyl .fc .fc-view-harness {
    min-height: 400px;
  }
  .calendarView___22Jyl .fc .fc-timegrid-slot .fc-timegrid-slot-label-frame {
    display: flex;
    justify-content: center;
  }
  .calendarView___22Jyl .fc .fc-timegrid-slot .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
    width: 50px;
    text-align: center;
    white-space: break-spaces;
  }
  .calendarView___22Jyl .ant-spin-nested-loading {
    box-shadow: unset;
    margin: auto;
  }
  .calendarView___22Jyl .calendarContainer___132K0 {
    position: relative;
    display: block;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .btnAction___1Ja9b {
    display: none;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .btnActionMobile___21sHP {
    display: flex;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT {
    top: -20px;
    left: 1.5%;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR {
    padding: 10px 0 10px 4px;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR .userBoldImage___3tTrc {
    height: 17px;
    width: 17px;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .teamMemberHide___11hnT .buttonTeamMemberHide___2criR .chevronRightImage___1Jr0Q {
    font-size: 17px;
  }
  .calendarView___22Jyl .calendarContainer___132K0 .teamMemberShow___iDRRT {
    position: absolute;
    background: white;
    z-index: 100;
    left: 1%;
    top: -3.5%;
  }
}
@media screen and (max-width: 550px) {
  .calendarView___22Jyl .btnActionMobile___21sHP .groupBtn___3y3-a {
    width: 30%;
  }
  .calendarView___22Jyl .btnActionMobile___21sHP .teamMemberHide___11hnT {
    left: 2%;
  }
}
@media screen and (max-width: 768px) {
  .calendarView___22Jyl {
    overflow-x: auto;
    padding-top: 40px;
  }
}
.sendEmailPop___VWMvM {
  text-align: center;
  height: 276px !important;
}
.sendEmailPop___VWMvM .close___2JMgU {
  margin-left: auto;
  padding: 0 !important;
}
.sendEmailPop___VWMvM .content___2A1cs {
  padding-left: 24px;
  padding-right: 24px;
}
.sendEmailPop___VWMvM .content___2A1cs .inputSendMail___ce8TT {
  border: 2px solid #000 !important;
}
.sendEmailPop___VWMvM .button___2iQ6n {
  margin-right: 5px;
  background-color: #33c3c7;
  color: #ffffff;
  border-radius: 12px;
  padding: 4px 24px;
  border: 0;
}
.headerTableCalendar___3TBGC {
  padding: 10px 0;
}
.headerTableCalendar___3TBGC p {
  margin-bottom: 0;
}
.headerTableCalendar___3TBGC .titleDaySm___3eprJ {
  display: none;
}
.headerTableCalendar___3TBGC .date___lH-sU {
  font-size: 24px;
  font-weight: 500;
}
.headerTableCalendar___3TBGC .dateSm___1013A {
  font-size: 24px;
  font-weight: 500;
  display: none;
}
.headerTableCalendar___3TBGC .titleMonth___39iXK,
.headerTableCalendar___3TBGC .titleDay___1TqS2 {
  display: block;
  font-weight: 500;
  color: #8d928d;
  font-size: 15px;
}
.headerTableCalendar___3TBGC .switchMobile___3YPwo {
  border: 1px solid #e8e6e6;
  background: #ffffff;
  padding: 5px 3px 1px 3px;
  border-radius: 26px;
  box-shadow: 0 2px 1px grey;
  min-height: 58px;
  cursor: pointer;
  width: 30px;
  margin: 0 auto 0.4rem auto;
  display: none;
  transition: all 0.3s;
}
.headerTableCalendar___3TBGC .switchMobile___3YPwo .checkedTime___1p6Wk {
  height: 22px;
  width: 22px;
  border-radius: 25px;
  background: #e2dfdf;
  margin: auto;
  box-shadow: 0px -1.4px 0px grey;
  border: 1px solid #cfd4d4;
  transition: all 0.3s;
}
.headerTableCalendar___3TBGC .switchMobile___3YPwo .dayWeek___CSLdL {
  margin-top: 3px;
}
.headerTableCalendar___3TBGC .switchMobile___3YPwo .dayWeek___CSLdL p {
  margin-bottom: 0;
  font-size: 9px;
  font-weight: normal;
}
.headerTableCalendar___3TBGC .activeSwitchSM___3PNKi {
  background: #e3f4f4;
}
.headerTableCalendar___3TBGC .activeSwitchSM___3PNKi .checkedTime___1p6Wk {
  background: #33c3c7;
  border-color: #33c3c7;
}
.headerTableCalendar___3TBGC .activeSwitchSM___3PNKi .dayWeek___CSLdL {
  height: 28px;
  display: flex;
}
.headerTableCalendar___3TBGC .activeSwitchSM___3PNKi .dayWeek___CSLdL p {
  margin: auto;
}
@media screen and (max-width: 700px) {
  .headerTableCalendar___3TBGC .switchMobile___3YPwo {
    display: block;
  }
  .headerTableCalendar___3TBGC .switchStatus___1TO42 {
    display: none;
  }
  .headerTableCalendar___3TBGC .date___lH-sU {
    font-size: 16px;
    display: none;
  }
  .headerTableCalendar___3TBGC .titleMonth___39iXK,
  .headerTableCalendar___3TBGC .titleDay___1TqS2 {
    display: none;
  }
  .headerTableCalendar___3TBGC .titleDaySm___3eprJ {
    display: block;
    margin-bottom: 0.2rem;
    font-weight: 500;
  }
  .headerTableCalendar___3TBGC .dateSm___1013A {
    font-size: 16px;
    display: unset;
  }
}
@media screen and (max-width: 700px) {
  .headerTableCalendar___3TBGC {
    padding: 0;
  }
}
.mt0___2NBKq {
  margin-top: 0 !important;
}
@media screen and (max-width: 767px) {
  .bookingCalendarParent___15DP8 {
    position: relative;
    overflow: scroll;
    height: 570px;
  }
  .bookingCalendarParent___15DP8 > div {
    position: absolute;
  }
}
.dropdownMenuTemplate___3DvgW {
  overflow: hidden;
  width: 80%;
  margin: 10px auto 0 auto;
  padding: 0.5rem 0;
  left: 65px;
  border: 1px solid #707070;
  border-radius: 5px;
}
.dropdownMenuTemplate___3DvgW li:not(:last-child) {
  margin-bottom: 0.5rem !important;
}
.dropdownMenuTemplate___3DvgW li.dropdownMenuIcon___2jG0J {
  background-color: white !important;
  color: black;
}
.dropdownMenuTemplate___3DvgW li.dropdownMenuIcon___2jG0J:last-child {
  margin: 0;
}
.dropdownMenuTemplate___3DvgW li.dropdownMenuIcon___2jG0J:hover {
  background-color: #f0f0f0 !important;
  color: black;
}
.dropdownMenuTemplate___3DvgW li.disabledTemplate___1nhkt {
  background-color: white !important;
  color: black !important;
  cursor: not-allowed;
}
.dropdownMenuTemplate___3DvgW li.disabledTemplate___1nhkt:hover {
  background-color: #f0f0f0 !important;
}
.dropdownMenuTemplate___3DvgW li span:last-child {
  margin-left: 0.5rem;
  font-weight: 500;
  color: #3c3c3c;
}
.dropdownMenuTemplate___3DvgW .activeIndex___1J1D6 {
  background-color: #e3f4f4;
}
.dropdownMenuTemplate___3DvgW .pageIndex___2xzXi {
  background: transparent !important;
}
.dropdownMenuTemplate___3DvgW .pageIndex___2xzXi:hover {
  background-color: #f0f0f0 !important;
}
.dropdownMenuTemplate___3DvgW .disabledTemplate___1nhkt {
  background-color: #95959560 !important;
  cursor: not-allowed;
}
.dropdownMenuTemplate___3DvgW .disabledTemplate___1nhkt:hover {
  background-color: #95959560 !important;
}
