@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2gQpF {
  display: flex;
  justify-content: center;
}
button.btn___T1Zpz {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___T1Zpz {
    padding: 10px;
  }
}
button.btn___T1Zpz:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___T1Zpz:focus {
  outline: none;
}
.btnWhite___LMZ1j.btn___T1Zpz {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___LMZ1j.btn___T1Zpz:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2Mb6N.btn___T1Zpz,
.btnGreen___2Mb6N.btn___T1Zpz:hover,
.btnGreen___2Mb6N.btn___T1Zpz:active,
.btnGreen___2Mb6N.btn___T1Zpz:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___37do_ {
  color: #fe0000;
}
.errorMessage___21wdP {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___21wBV {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2AI2U {
  display: none !important;
}
.m-auto___Q2WhF {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___x-T5_ {
  max-width: 120px !important;
}
.fc___2Kwo1 .fc-timegrid-col___2c8uG.fc-day-today___3ts8- {
  background-color: #ffffff;
}
.fc___2Kwo1 .fc-timegrid-slot___svMAX {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2Kwo1 .fc-timegrid-slot___svMAX {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3YT7G .fc-direction-ltr___2q8bR .fc-timegrid-slot-label-frame___vRqLo {
    font-size: 10px;
  }
  .bookingCalendar___3YT7G .fc___2Kwo1 .fc-timegrid-slot-label-cushion___2dXLq {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3YT7G .fc___2Kwo1 .fc-scrollgrid-section-header___2A9Nd .fc-scroller___1MwUe {
    overflow: unset !important;
  }
}
.ant-message-notice___1K2wU {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3j56_ {
  width: 20px;
  height: 20px;
}
.bgTransparent___2hWM5 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1aMg4 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1QiUI {
  position: relative;
}
.inputCustomValue___1QiUI .ant-input___dipfe {
  padding-right: 50px;
}
.inputCustomValueText___33gR0 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___36Jb0 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___36Jb0 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1BrSy .ant-radio-inner___3sJuM {
  border-color: black !important ;
}
.ant-radio-checked___1BrSy .ant-radio-inner___3sJuM:after {
  background-color: black;
}
.ant-radio___1QZEK:hover .ant-radio-inner___3sJuM {
  border-color: black;
}
.ant-radio-checked___1BrSy .ant-radio-inner___3sJuM:focus {
  border-color: black;
}
.modal___25NY2 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___25NY2 .close___1bJMp {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___25NY2 .header___1OOCK {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___25NY2 .header___1OOCK p {
  margin: 0;
}
.modal___25NY2 .content___2lfAZ {
  text-align: center;
  font-size: 14px;
}
.modal___25NY2 .content___2lfAZ p {
  margin: 0;
}
.modal___25NY2 .actions___94MT3 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___25NY2 .actions___94MT3 .button___r6mRW {
  margin-top: 10px;
}
.modal___25NY2 .actions___94MT3:hover {
  color: #33c3c7;
}
.fc___2Kwo1 .fc-timegrid-slot-minor___3DM1O {
  border-top-style: none;
}
.fc___2Kwo1 .fc-timegrid-slot___svMAX {
  height: 1.5em !important;
}
.messageError___2kP7V .ant-message-custom-content___bpUx1 {
  display: flex;
  text-align: start;
}
.messageError___2kP7V .ant-message-custom-content___bpUx1 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2lhHA {
  background-color: #e6e4e4;
}
.notifyMessage___1C8zp .ant-message-notice-content___js-0z {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1C8zp .ant-message-notice-content___js-0z .anticon___1uKHs {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1C8zp .ant-message-notice-content___js-0z span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2Tlru {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2Tlru .ant-notification-notice-message___1XTAL,
.notifyCustomize___2Tlru .ant-notification-notice-close___IA9wb,
.notifyCustomize___2Tlru .ant-notification-notice-icon___da76p {
  color: white;
}
.notifyCustomize___2Tlru .ant-notification-notice-message___1XTAL {
  margin-left: 35px;
}
.notifyCustomize___2Tlru p {
  margin-bottom: 0;
}
.notifyCustomize___2Tlru p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3lcYU {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3lcYU .ant-notification-notice-message___1XTAL {
  margin-left: 35px;
}
.notifyAlertDuplicate___3lcYU p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3lcYU p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___32frA {
  width: 434px !important;
}
.handleSubmitVote___32frA .ant-btn-primary___knKv4 {
  background: #59c3c7;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ {
  padding: 15px 30px 18px;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ .ant-modal-confirm-body___2US2i {
  display: flex !important;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ .ant-modal-confirm-body___2US2i .anticon___1uKHs {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ .ant-modal-confirm-btns___1XsVi button {
  font-weight: bold;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ .ant-modal-confirm-btns___1XsVi button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ .ant-modal-confirm-btns___1XsVi button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___32frA .ant-modal-body___3NskQ .ant-modal-confirm-btns___1XsVi .ant-btn-primary___knKv4 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2-USu {
  margin-bottom: 0;
}
.mt-0___-2H7p {
  margin-top: 0;
}
.tooltipFormat___3U3Uv p {
  margin-bottom: 0;
}
.bgWhite___1zTjZ {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3Zcmx {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3Zcmx:hover,
.bgPrimaryBlue___3Zcmx:focus,
.bgPrimaryBlue___3Zcmx:active {
  background: #4f7ac7;
}
.bgDarkBlue___32qTh {
  background-color: #023768 !important;
}
.bgDarkBlue___32qTh:hover,
.bgDarkBlue___32qTh:focus,
.bgDarkBlue___32qTh:active {
  background: #194d7d;
}
.bgGreyBlue___d92w6 {
  background-color: #ebf0f9;
}
.bgLightBlue___dnv3O {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3d7Pl {
  background-color: #e4453a;
}
.bgLightRed___3d7Pl:hover,
.bgLightRed___3d7Pl:focus,
.bgLightRed___3d7Pl:active {
  background: #e17871;
}
.bgLightYellow___nv4pc {
  background-color: #ffd603;
}
.bgDarkGray___3eLyp {
  background-color: #333333;
}
.bgMediumGray___i-OCr {
  background-color: #a2a2a2;
}
.bgLightGray___38cLJ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2X3tH {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1APBV {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2FfAW {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3-2ZR {
  color: #3368c7 !important;
}
.textDarkBlue___3g7O6 {
  color: #023768 !important;
}
.textLightBlue___3IfzO {
  color: #b2cbf7;
}
.textDarkGray___ROQDK {
  color: #333333 !important;
}
.textMediumGray___2dQew {
  color: #a2a2a2 !important;
}
.textLightGray___C2pK3 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___oVkGa {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___17E_g {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___jnyrK {
  border-radius: 5px !important;
}
.rounded-xs___366RL {
  border-radius: 10px !important;
}
.rounded-sm___1EAZU {
  border-radius: 15px !important;
}
.rounded-md___22KTc {
  border-radius: 20px !important;
}
.rounded-lg___1_Sxc {
  border-radius: 25px !important;
}
.rounded-full___1ITlq {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___vMx5R {
  margin: 0;
}
.mt-2___2s_6N {
  margin-top: 0.5rem;
}
.my-2___1iUZ1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___Dgnn8 {
  padding: 0;
}
.px-1___1FdMq {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___8TbuI {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3ItqE {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___vFrsi {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___bGgkE {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2fZtl {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2nZEX {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___ergPI {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2xVBc {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2NokA {
  padding-bottom: 20px;
}
.h-full___23TeF {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1-CoR {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3Ny8A {
  font-weight: bold !important;
}
.fontWeight400___2iSc4 {
  font-weight: 400 !important;
}
.fontWeight500___hI_AQ {
  font-weight: 500 !important;
}
.fontWeight600___2pwbG {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___KkFvi {
  font-size: 12px !important;
}
.fontSize14___385MN {
  font-size: 14px !important;
}
.fontSize16___2pYma {
  font-size: 16px !important;
}
.fontSize18___29IZm {
  font-size: 18px !important;
}
.fontSize20___3PrwH {
  font-size: 20px !important;
}
.fontSize24___36a1X {
  font-size: 24px !important;
}
.eventName___DCnlt {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3gXIJ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1ig4r > tr > th,
.ant-table-tbody___2mlEu > tr.ant-table-row___Tv1Cu > td,
.ant-table-summary___3-Aj8 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1ig4r > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1i6px .ant-table-thead___1ig4r > tr > th,
.table-portrait___1i6px .ant-table-tbody___2mlEu > tr.ant-table-row___Tv1Cu > td,
.table-portrait___1i6px .ant-table-summary___3-Aj8 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1i6px .bgLightGray___38cLJ td {
  background: #ebebeb;
}
.table-portrait___1i6px .bgLightBlue___dnv3O td {
  background: #b2cbf7;
}
.eventContent___2eDwQ .timeText___2DycX {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2mlEu > tr.bgLightGray___38cLJ:hover > td,
.ant-table-tbody___2mlEu > tr > td.ant-table-cell-row-hover___3UtOz {
  background: #ebebeb;
}
.ant-table-tbody___2mlEu > tr.bgLightBlue___dnv3O:hover > td,
.ant-table-tbody___2mlEu > tr > td.ant-table-cell-row-hover___3UtOz {
  background: #b2cbf7;
}
.ant-table-tbody___2mlEu > tr.bgWhite___1zTjZ:hover > td,
.ant-table-tbody___2mlEu > tr > td.ant-table-cell-row-hover___3UtOz {
  background: #ffffff;
}
.contractDetail___3sd4x {
  max-width: 1080px;
  margin: auto;
  padding: 45px 15px 90px;
}
.contractDetail___3sd4x button.customButton___1DQg2,
.contractDetail___3sd4x button.customButton___1DQg2:hover {
  height: 42px;
  font-size: 19px;
  background-color: #ffffff;
  border: 3px solid #33c3c7;
  border-radius: 15px;
  margin-top: 10px;
  text-align: center;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x button.customButton___1DQg2,
  .contractDetail___3sd4x button.customButton___1DQg2:hover {
    font-size: 16px;
    line-height: 1.2;
  }
}
.contractDetail___3sd4x button.btnChangeCard___2G9bB,
.contractDetail___3sd4x button.btnChangeCard___2G9bB:hover {
  margin-top: 0px;
  margin-left: 50px;
  font-size: 16px;
}
.contractDetail___3sd4x .boxContent___Tm7SJ {
  margin-left: 24px;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .boxContent___Tm7SJ {
    margin-left: 0;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .contractDetail___3sd4x .boxContent___Tm7SJ {
    margin-left: 0;
  }
}
.contractDetail___3sd4x .headTitle___mv021 {
  display: flex;
  align-items: center;
}
.contractDetail___3sd4x .headTitle___mv021 .bolderIcon___JKBLm {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.contractDetail___3sd4x .headTitle___mv021 .titleIcon___1A2aJ {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.contractDetail___3sd4x .headTitle___mv021 span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .headTitle___mv021 span {
    font-size: 20px;
  }
}
.contractDetail___3sd4x .headTitle___mv021 button,
.contractDetail___3sd4x .headTitle___mv021 button:hover {
  margin-left: 40px;
  margin-top: 0px;
}
.contractDetail___3sd4x .headTitle___mv021 button span {
  font-size: 19px;
}
.contractDetail___3sd4x .headTitle___mv021 button.btnFs16___nqNdj span {
  font-size: 16px;
}
.contractDetail___3sd4x .accountData___XYVOG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 780px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .accountData___XYVOG {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}
.contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn {
  height: 252px;
  width: 252px;
  box-shadow: 1px 1px 15px #b3bcbc60;
  border-radius: 10px;
  padding: 32px 32px 20px 32px;
  text-align: center;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn {
    width: 100%;
    margin-bottom: 20px;
    height: auto;
    max-height: 252px;
    padding: 15px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn {
    margin-right: 20px;
    padding: 32px 15px 23px;
  }
  .contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn:last-child {
    margin-right: 0;
  }
}
.contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn .deviderLine___1u-U0 {
  height: 2px;
  width: 100%;
  background-color: #b6b6b6;
  margin: 10px 0 50px 0;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn .deviderLine___1u-U0 {
    margin: 10px 0 15px 0;
  }
}
.contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn .amount___1U8eN {
  font-size: 31px;
  line-height: 41px;
  color: #33c3c7;
  margin: 0;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn .amount___1U8eN {
    font-size: 25px;
    line-height: 1.2;
  }
}
.contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn .contract___23pds {
  margin: 0;
  font-size: 27px;
  line-height: 44px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .accountData___XYVOG .dataBadge___E8fWn .contract___23pds {
    font-size: 20px;
    line-height: 1.2;
  }
}
.contractDetail___3sd4x .memberTable___B91Ve {
  margin: 34px 0 100px 0;
}
.contractDetail___3sd4x .wrapperCard___11tOM {
  display: flex;
  align-items: center;
}
.contractDetail___3sd4x .wrapperCard___11tOM .dot___3OO6M {
  line-height: 24px;
  height: 18px;
  display: block;
}
.contractDetail___3sd4x .ant-table-content table thead th {
  background: #ffffff;
  font-size: 16px;
  border-bottom: 3px solid #ececec;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .ant-table-content table thead th {
    font-size: 16px;
  }
}
.contractDetail___3sd4x .ant-table-content table tbody tr td {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .ant-table-content table tbody tr td {
    font-size: 14px;
  }
}
.contractDetail___3sd4x .ant-table-content table tbody tr:last-child td {
  border-bottom: none;
}
.contractDetail___3sd4x .showNoteTable .ant-table-content table tbody tr td {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .showNoteTable .ant-table-content table tbody tr td {
    font-size: 14px;
  }
}
.contractDetail___3sd4x .ant-pagination {
  padding: 0 30px;
}
.contractDetail___3sd4x .ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link {
  border-color: #33c3c7;
  color: #33c3c7;
}
.contractDetail___3sd4x .ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #33c3c7;
  color: #33c3c7;
}
.contractDetail___3sd4x .ant-pagination .ant-pagination-item:hover {
  border-color: #33c3c7;
}
.contractDetail___3sd4x .ant-pagination .ant-pagination-item:hover a {
  color: #33c3c7;
}
.contractDetail___3sd4x .ant-pagination .ant-pagination-item-active {
  border-color: #33c3c7;
}
.contractDetail___3sd4x .ant-pagination .ant-pagination-item-active a {
  color: #33c3c7;
}
.contractDetail___3sd4x .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.contractDetail___3sd4x .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}
.contractDetail___3sd4x .ant-table-footer {
  display: none;
}
.contractDetail___3sd4x .showNoteTable .ant-table-footer {
  text-align: center;
  display: block;
}
.contractDetail___3sd4x .tableCard___10ys7 .ant-table-content table tbody tr td {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .contractDetail___3sd4x .tableCard___10ys7 .ant-table-content table tbody tr td {
    font-size: 14px;
  }
}
.wrapperFooter___2bRz0 {
  padding-top: 110px;
}
