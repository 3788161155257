@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3TFNz {
  display: flex;
  justify-content: center;
}
button.btn___37LP1 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___37LP1 {
    padding: 10px;
  }
}
button.btn___37LP1:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___37LP1:focus {
  outline: none;
}
.btnWhite___1DEay.btn___37LP1 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1DEay.btn___37LP1:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2vwGe.btn___37LP1,
.btnGreen___2vwGe.btn___37LP1:hover,
.btnGreen___2vwGe.btn___37LP1:active,
.btnGreen___2vwGe.btn___37LP1:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2rM4c {
  color: #fe0000;
}
.errorMessage___21ATq {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2CPzJ {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___12Ctq {
  display: none !important;
}
.m-auto___vDWFY {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2ubbl {
  max-width: 120px !important;
}
.fc___axgvp .fc-timegrid-col___XVDgF.fc-day-today___3S9PD {
  background-color: #ffffff;
}
.fc___axgvp .fc-timegrid-slot___1Rbcf {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___axgvp .fc-timegrid-slot___1Rbcf {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___15u0H .fc-direction-ltr___1Q3Nn .fc-timegrid-slot-label-frame___Rzd8A {
    font-size: 10px;
  }
  .bookingCalendar___15u0H .fc___axgvp .fc-timegrid-slot-label-cushion___3fpfm {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___15u0H .fc___axgvp .fc-scrollgrid-section-header___3N1Sh .fc-scroller___1-x-4 {
    overflow: unset !important;
  }
}
.ant-message-notice___pIvOy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___Qjk2X {
  width: 20px;
  height: 20px;
}
.bgTransparent___-ACGR {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___d4e9A {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___fh2b2 {
  position: relative;
}
.inputCustomValue___fh2b2 .ant-input___IA5kJ {
  padding-right: 50px;
}
.inputCustomValueText___3PvJR {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3osrS .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3osrS .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___A908p .ant-radio-inner___1kk2T {
  border-color: black !important ;
}
.ant-radio-checked___A908p .ant-radio-inner___1kk2T:after {
  background-color: black;
}
.ant-radio___2xY4U:hover .ant-radio-inner___1kk2T {
  border-color: black;
}
.ant-radio-checked___A908p .ant-radio-inner___1kk2T:focus {
  border-color: black;
}
.modal___3J7t4 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3J7t4 .close___SaK2H {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3J7t4 .header___1Us2w {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3J7t4 .header___1Us2w p {
  margin: 0;
}
.modal___3J7t4 .content___YNMLJ {
  text-align: center;
  font-size: 14px;
}
.modal___3J7t4 .content___YNMLJ p {
  margin: 0;
}
.modal___3J7t4 .actions___327EP {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3J7t4 .actions___327EP .button___2uvB9 {
  margin-top: 10px;
}
.modal___3J7t4 .actions___327EP:hover {
  color: #33c3c7;
}
.fc___axgvp .fc-timegrid-slot-minor___3z_jD {
  border-top-style: none;
}
.fc___axgvp .fc-timegrid-slot___1Rbcf {
  height: 1.5em !important;
}
.messageError___25DOD .ant-message-custom-content___1zP3m {
  display: flex;
  text-align: start;
}
.messageError___25DOD .ant-message-custom-content___1zP3m span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1noHr {
  background-color: #e6e4e4;
}
.notifyMessage___1U-UJ .ant-message-notice-content___2US0O {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1U-UJ .ant-message-notice-content___2US0O .anticon___1ia9D {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1U-UJ .ant-message-notice-content___2US0O span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___VhW_k {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___VhW_k .ant-notification-notice-message___1Wm0r,
.notifyCustomize___VhW_k .ant-notification-notice-close___1c4x3,
.notifyCustomize___VhW_k .ant-notification-notice-icon___uP7dh {
  color: white;
}
.notifyCustomize___VhW_k .ant-notification-notice-message___1Wm0r {
  margin-left: 35px;
}
.notifyCustomize___VhW_k p {
  margin-bottom: 0;
}
.notifyCustomize___VhW_k p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1xHIE {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1xHIE .ant-notification-notice-message___1Wm0r {
  margin-left: 35px;
}
.notifyAlertDuplicate___1xHIE p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1xHIE p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___y1qJp {
  width: 434px !important;
}
.handleSubmitVote___y1qJp .ant-btn-primary___3UqQ7 {
  background: #59c3c7;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM {
  padding: 15px 30px 18px;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM .ant-modal-confirm-body___1173M {
  display: flex !important;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM .ant-modal-confirm-body___1173M .anticon___1ia9D {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM .ant-modal-confirm-btns___2l5Ui button {
  font-weight: bold;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM .ant-modal-confirm-btns___2l5Ui button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM .ant-modal-confirm-btns___2l5Ui button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___y1qJp .ant-modal-body___27loM .ant-modal-confirm-btns___2l5Ui .ant-btn-primary___3UqQ7 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___l3PL6 {
  margin-bottom: 0;
}
.mt-0___3ZFWJ {
  margin-top: 0;
}
.tooltipFormat___7zMFB p {
  margin-bottom: 0;
}
.bgWhite___3ZMef {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3SpNa {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3SpNa:hover,
.bgPrimaryBlue___3SpNa:focus,
.bgPrimaryBlue___3SpNa:active {
  background: #4f7ac7;
}
.bgDarkBlue___3Rp9d {
  background-color: #023768 !important;
}
.bgDarkBlue___3Rp9d:hover,
.bgDarkBlue___3Rp9d:focus,
.bgDarkBlue___3Rp9d:active {
  background: #194d7d;
}
.bgGreyBlue___32TZ6 {
  background-color: #ebf0f9;
}
.bgLightBlue___2O3yH {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2ACq5 {
  background-color: #e4453a;
}
.bgLightRed___2ACq5:hover,
.bgLightRed___2ACq5:focus,
.bgLightRed___2ACq5:active {
  background: #e17871;
}
.bgLightYellow___39QJN {
  background-color: #ffd603;
}
.bgDarkGray___2cq5n {
  background-color: #333333;
}
.bgMediumGray___28Ctq {
  background-color: #a2a2a2;
}
.bgLightGray___1XS6g {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1ziSR {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1HvDd {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___rFUlX {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1Vijt {
  color: #3368c7 !important;
}
.textDarkBlue___2oqpu {
  color: #023768 !important;
}
.textLightBlue___3G33y {
  color: #b2cbf7;
}
.textDarkGray___1aMoV {
  color: #333333 !important;
}
.textMediumGray___16rnw {
  color: #a2a2a2 !important;
}
.textLightGray___1f_8V {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___y4jei {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___36PTj {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2ggcL {
  border-radius: 5px !important;
}
.rounded-xs___2zM-P {
  border-radius: 10px !important;
}
.rounded-sm___39MKy {
  border-radius: 15px !important;
}
.rounded-md___2A-vK {
  border-radius: 20px !important;
}
.rounded-lg___1bVsw {
  border-radius: 25px !important;
}
.rounded-full___3ZxwU {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___36mIC {
  margin: 0;
}
.mt-2___35cvH {
  margin-top: 0.5rem;
}
.my-2____rLnw {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___flyew {
  padding: 0;
}
.px-1___H1k9b {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___20ueV {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___17hbl {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1BTFb {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___139pb {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1s2I2 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2kMZ_ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3m_Ik {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___zA7H- {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3cKwo {
  padding-bottom: 20px;
}
.h-full___1paKF {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2wjof {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2SUEP {
  font-weight: bold !important;
}
.fontWeight400___3MoQk {
  font-weight: 400 !important;
}
.fontWeight500___3-IV0 {
  font-weight: 500 !important;
}
.fontWeight600___ZimhJ {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1eQo2 {
  font-size: 12px !important;
}
.fontSize14___uTwlM {
  font-size: 14px !important;
}
.fontSize16___11GyO {
  font-size: 16px !important;
}
.fontSize18___2wd2f {
  font-size: 18px !important;
}
.fontSize20___kuVU_ {
  font-size: 20px !important;
}
.fontSize24___2b4B0 {
  font-size: 24px !important;
}
.eventName___2z7g2 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2AMOI {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1emBn > tr > th,
.ant-table-tbody___2_Vgb > tr.ant-table-row___3PXyB > td,
.ant-table-summary___3d9Y_ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1emBn > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1xf3e .ant-table-thead___1emBn > tr > th,
.table-portrait___1xf3e .ant-table-tbody___2_Vgb > tr.ant-table-row___3PXyB > td,
.table-portrait___1xf3e .ant-table-summary___3d9Y_ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1xf3e .bgLightGray___1XS6g td {
  background: #ebebeb;
}
.table-portrait___1xf3e .bgLightBlue___2O3yH td {
  background: #b2cbf7;
}
.eventContent___2gHg8 .timeText___2eTW5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2_Vgb > tr.bgLightGray___1XS6g:hover > td,
.ant-table-tbody___2_Vgb > tr > td.ant-table-cell-row-hover___3gFfM {
  background: #ebebeb;
}
.ant-table-tbody___2_Vgb > tr.bgLightBlue___2O3yH:hover > td,
.ant-table-tbody___2_Vgb > tr > td.ant-table-cell-row-hover___3gFfM {
  background: #b2cbf7;
}
.ant-table-tbody___2_Vgb > tr.bgWhite___3ZMef:hover > td,
.ant-table-tbody___2_Vgb > tr > td.ant-table-cell-row-hover___3gFfM {
  background: #ffffff;
}
.mainHeader___zSEjP .flexHeader___3bJ-L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #3368c7;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuLeft___3Erne {
  width: 20%;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  border: none;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuLeft___3Erne .backURL___GJ9fw {
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuLeft___3Erne .backURL___GJ9fw img {
  width: 100%;
  height: auto;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuLeft___3Erne .backURL___GJ9fw .itemName___3zaIW {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuLeft___3Erne.bgBackBtn___3-5vI {
  background: rgba(255, 255, 255, 0.1);
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuLeft___3Erne span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerTitle___3mMDa {
  width: 60%;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuRight___2Cm1S {
  width: 20%;
  display: flex;
  justify-content: right;
  align-items: center;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuRight___2Cm1S .backURL___GJ9fw {
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuRight___2Cm1S .backURL___GJ9fw img {
  width: 100%;
  height: auto;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuRight___2Cm1S .backURL___GJ9fw span {
  padding-left: 5px;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuRight___2Cm1S .backURL___GJ9fw .itemName___3zaIW {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainHeader___zSEjP .flexHeader___3bJ-L .headerMenuRightManual___M4FMU {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #0f87e4;
  font-weight: 500;
  border-radius: 5px;
}
.mainHeader___zSEjP .header___1Us2w {
  background-color: #3368c7;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.mainHeader___zSEjP .header___1Us2w .headerTitle___3mMDa {
  font-weight: 700;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
}
.mainHeader___zSEjP .textWhite___1aZ1M {
  color: #ffffff;
}
