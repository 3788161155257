@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___37s8- {
  display: flex;
  justify-content: center;
}
button.btn___1NgeD {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1NgeD {
    padding: 10px;
  }
}
button.btn___1NgeD:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1NgeD:focus {
  outline: none;
}
.btnWhite___1Rchh.btn___1NgeD {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1Rchh.btn___1NgeD:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___f7pY6.btn___1NgeD,
.btnGreen___f7pY6.btn___1NgeD:hover,
.btnGreen___f7pY6.btn___1NgeD:active,
.btnGreen___f7pY6.btn___1NgeD:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1_Ncg {
  color: #fe0000;
}
.errorMessage___5cgBz {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2PNlK {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___NYCHx {
  display: none !important;
}
.m-auto___1CDuB {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1DD6B {
  max-width: 120px !important;
}
.fc___d8NNG .fc-timegrid-col___lT7-1.fc-day-today___2h46C {
  background-color: #ffffff;
}
.fc___d8NNG .fc-timegrid-slot___3If9w {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___d8NNG .fc-timegrid-slot___3If9w {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___24ynh .fc-direction-ltr___24Kk9 .fc-timegrid-slot-label-frame___1sSji {
    font-size: 10px;
  }
  .bookingCalendar___24ynh .fc___d8NNG .fc-timegrid-slot-label-cushion___2E2I_ {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___24ynh .fc___d8NNG .fc-scrollgrid-section-header___ZrOv7 .fc-scroller___1F0a- {
    overflow: unset !important;
  }
}
.ant-message-notice___3k9Pv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3b_Hb {
  width: 20px;
  height: 20px;
}
.bgTransparent___b5Y8v {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___tL73a {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___bLnae {
  position: relative;
}
.inputCustomValue___bLnae .ant-input___1srXf {
  padding-right: 50px;
}
.inputCustomValueText___Fu2gm {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3wf40 .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3wf40 .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___5QnhW .ant-radio-inner___ntebs {
  border-color: black !important ;
}
.ant-radio-checked___5QnhW .ant-radio-inner___ntebs:after {
  background-color: black;
}
.ant-radio___35p_t:hover .ant-radio-inner___ntebs {
  border-color: black;
}
.ant-radio-checked___5QnhW .ant-radio-inner___ntebs:focus {
  border-color: black;
}
.modal___1Qn79 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1Qn79 .close___8mZSc {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1Qn79 .header___1l1fz {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1Qn79 .header___1l1fz p {
  margin: 0;
}
.modal___1Qn79 .content___DmzfD {
  text-align: center;
  font-size: 14px;
}
.modal___1Qn79 .content___DmzfD p {
  margin: 0;
}
.modal___1Qn79 .actions___1XTqT {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1Qn79 .actions___1XTqT .button___1GINm {
  margin-top: 10px;
}
.modal___1Qn79 .actions___1XTqT:hover {
  color: #33c3c7;
}
.fc___d8NNG .fc-timegrid-slot-minor___NnHlT {
  border-top-style: none;
}
.fc___d8NNG .fc-timegrid-slot___3If9w {
  height: 1.5em !important;
}
.messageError___1lz0K .ant-message-custom-content___d2mk0 {
  display: flex;
  text-align: start;
}
.messageError___1lz0K .ant-message-custom-content___d2mk0 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___8wjdt {
  background-color: #e6e4e4;
}
.notifyMessage___3HH7p .ant-message-notice-content___P86NC {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3HH7p .ant-message-notice-content___P86NC .anticon___3rCzi {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3HH7p .ant-message-notice-content___P86NC span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___34fnf {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___34fnf .ant-notification-notice-message___1uEFl,
.notifyCustomize___34fnf .ant-notification-notice-close___19DsO,
.notifyCustomize___34fnf .ant-notification-notice-icon___1E6Iu {
  color: white;
}
.notifyCustomize___34fnf .ant-notification-notice-message___1uEFl {
  margin-left: 35px;
}
.notifyCustomize___34fnf p {
  margin-bottom: 0;
}
.notifyCustomize___34fnf p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___XSNyX {
  padding: 16px 14px;
}
.notifyAlertDuplicate___XSNyX .ant-notification-notice-message___1uEFl {
  margin-left: 35px;
}
.notifyAlertDuplicate___XSNyX p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___XSNyX p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___38bWu {
  width: 434px !important;
}
.handleSubmitVote___38bWu .ant-btn-primary___33n9p {
  background: #59c3c7;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk {
  padding: 15px 30px 18px;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk .ant-modal-confirm-body___rInFT {
  display: flex !important;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk .ant-modal-confirm-body___rInFT .anticon___3rCzi {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk .ant-modal-confirm-btns___1ZeE0 button {
  font-weight: bold;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk .ant-modal-confirm-btns___1ZeE0 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk .ant-modal-confirm-btns___1ZeE0 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___38bWu .ant-modal-body___1GSjk .ant-modal-confirm-btns___1ZeE0 .ant-btn-primary___33n9p {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3_zpo {
  margin-bottom: 0;
}
.mt-0___2MkYR {
  margin-top: 0;
}
.tooltipFormat___357ZW p {
  margin-bottom: 0;
}
.bgWhite___2YUIt {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1GPrJ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1GPrJ:hover,
.bgPrimaryBlue___1GPrJ:focus,
.bgPrimaryBlue___1GPrJ:active {
  background: #4f7ac7;
}
.bgDarkBlue___gsxoS {
  background-color: #023768 !important;
}
.bgDarkBlue___gsxoS:hover,
.bgDarkBlue___gsxoS:focus,
.bgDarkBlue___gsxoS:active {
  background: #194d7d;
}
.bgGreyBlue___3Aoto {
  background-color: #ebf0f9;
}
.bgLightBlue___1xhiu {
  background-color: #b2cbf7 !important;
}
.bgLightRed___21SnX {
  background-color: #e4453a;
}
.bgLightRed___21SnX:hover,
.bgLightRed___21SnX:focus,
.bgLightRed___21SnX:active {
  background: #e17871;
}
.bgLightYellow___28SB4 {
  background-color: #ffd603;
}
.bgDarkGray___3MukX {
  background-color: #333333;
}
.bgMediumGray___28WPD {
  background-color: #a2a2a2;
}
.bgLightGray___34eqo {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1RgKP {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3oJGr {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1L2Jb {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2WoAc {
  color: #3368c7 !important;
}
.textDarkBlue___33Ha1 {
  color: #023768 !important;
}
.textLightBlue___3bicl {
  color: #b2cbf7;
}
.textDarkGray___2mDhz {
  color: #333333 !important;
}
.textMediumGray___3d9ST {
  color: #a2a2a2 !important;
}
.textLightGray___2HjO- {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___6PA7i {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___AK8C_ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___zLENA {
  border-radius: 5px !important;
}
.rounded-xs___3jT0b {
  border-radius: 10px !important;
}
.rounded-sm___3EAby {
  border-radius: 15px !important;
}
.rounded-md___WUXmL {
  border-radius: 20px !important;
}
.rounded-lg___55v0v {
  border-radius: 25px !important;
}
.rounded-full___1MXKi {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2g6xv {
  margin: 0;
}
.mt-2___3tHuz {
  margin-top: 0.5rem;
}
.my-2___WzXtE {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3Y4yx {
  padding: 0;
}
.px-1___6XWpZ {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___W5iIJ {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2-lXt {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___143KA {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___o-NOd {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2bOlw {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2eXuT {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___YtJnf {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1w7YB {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2NhWl {
  padding-bottom: 20px;
}
.h-full___1vpYi {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2HAY6 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3P5IA {
  font-weight: bold !important;
}
.fontWeight400___IzhCE {
  font-weight: 400 !important;
}
.fontWeight500___3hfWm {
  font-weight: 500 !important;
}
.fontWeight600___1Pm6o {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2M3Y7 {
  font-size: 12px !important;
}
.fontSize14___1DmZT {
  font-size: 14px !important;
}
.fontSize16___1VJjw {
  font-size: 16px !important;
}
.fontSize18___1EiLL {
  font-size: 18px !important;
}
.fontSize20___1_fm- {
  font-size: 20px !important;
}
.fontSize24___1kUxz {
  font-size: 24px !important;
}
.eventName___2okMf {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3C9Zt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1mmCC > tr > th,
.ant-table-tbody___1_2z5 > tr.ant-table-row___1-UMT > td,
.ant-table-summary___IAkEw tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1mmCC > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1VNmk .ant-table-thead___1mmCC > tr > th,
.table-portrait___1VNmk .ant-table-tbody___1_2z5 > tr.ant-table-row___1-UMT > td,
.table-portrait___1VNmk .ant-table-summary___IAkEw tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1VNmk .bgLightGray___34eqo td {
  background: #ebebeb;
}
.table-portrait___1VNmk .bgLightBlue___1xhiu td {
  background: #b2cbf7;
}
.eventContent___2gjWL .timeText___uFVfY {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1_2z5 > tr.bgLightGray___34eqo:hover > td,
.ant-table-tbody___1_2z5 > tr > td.ant-table-cell-row-hover___2bBI8 {
  background: #ebebeb;
}
.ant-table-tbody___1_2z5 > tr.bgLightBlue___1xhiu:hover > td,
.ant-table-tbody___1_2z5 > tr > td.ant-table-cell-row-hover___2bBI8 {
  background: #b2cbf7;
}
.ant-table-tbody___1_2z5 > tr.bgWhite___2YUIt:hover > td,
.ant-table-tbody___1_2z5 > tr > td.ant-table-cell-row-hover___2bBI8 {
  background: #ffffff;
}
.container___2Jj3e .groupBtn___3af98 {
  display: flex;
}
.container___2Jj3e .groupBtn___3af98 button:first-child {
  margin-right: 40px;
}
.container___2Jj3e .groupHeaderInput___1j5Wf input {
  height: 43px;
}
.container___2Jj3e .title___3jgRn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.container___2Jj3e .titleIcon___2fX3a {
  font-size: 12px;
  height: 10px;
  color: grey;
}
.container___2Jj3e .titleIconActive___1RXyW {
  color: black;
}
.container___2Jj3e .groupCaret___1_p0A {
  margin-left: 8px;
  display: inline-flex;
  flex-direction: column;
}
.container___2Jj3e .optionUpdate___-Jxka .ant-select {
  width: 100%;
  height: 43px;
}
.container___2Jj3e .optionUpdate___-Jxka .ant-select-selector {
  height: 100%;
}
.container___2Jj3e .optionUpdate___-Jxka .ant-select-selection-item,
.container___2Jj3e .optionUpdate___-Jxka .ant-select-selection-placeholder {
  line-height: 40px;
}
.container___2Jj3e .ant-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.container___2Jj3e .ant-row {
  margin: 8px 0;
}
.container___2Jj3e a.deleted {
  color: grey !important;
  cursor: default !important;
  pointer-events: none;
}
.container___2Jj3e .ant-row,
.container___2Jj3e .ant-form-item {
  text-align: left !important;
}
.container___2Jj3e .ant-picker {
  border: 2px solid #d9d9d9;
  border-radius: 8px;
}
.container___2Jj3e .ant-picker:hover {
  border-color: #33c3c7;
}
.container___2Jj3e thead .ant-table-cell {
  background: #ddf6f5;
  border: 1px solid black;
  border-bottom: none;
}
.container___2Jj3e thead .ant-table-cell.ant-table-column-has-sorters:hover {
  background: #ddf6f5;
}
.container___2Jj3e thead th:nth-child(2) {
  width: 120px !important;
}
.container___2Jj3e thead th:nth-child(2) label {
  margin-left: 8px;
}
.container___2Jj3e tbody .ant-table-cell {
  height: 100px;
  border: 1px solid black;
  padding-top: 0px;
  padding-bottom: 0px;
}
.container___2Jj3e tbody tr:not(:last-child) td {
  border-bottom: none;
}
.container___2Jj3e tbody tr td:nth-child(3) {
  max-width: 200px;
}
.container___2Jj3e .ant-checkbox-checked .ant-checkbox-inner {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAABHNCSVQICAgIfAhkiAAAAVNJREFUOE+lk1FOwkAURe/t9IM/cQd1BRZMTEBB2AGuwLgCdAXiDnAFLEFcAUSUJiZAdyBL0C/46PSZKRlSpBCq/ZpM5p3ee997xD++8tv4LqY75F8Y/mBWVO5iANDXQDM3xH//8FUc9QzACNCOKuWCGAWOWs5IeNaBjgrHB0PSFtIR5LJTHo27BNu/MxTK/UFKzl7HLZDPWwDIU+yyuwFJPLvL9rRWebQFWTlA8K2JVlirDM27NaQ8CjoQaZMsGp/2gbkn8LBWYQBKNcKL89De0R8FDUfQSycugvm0Xjnxg8BTET73ARIlCQQwf7tKexbBLYEGiJvkPkPBWok9mBFmzA6Io6RGME+rg8j1pF7tZ034ZrBmGrUeWpAtMG2cXla7u1Zkq8UmBydCn8Dpyoa8TOrV1r4dy5yTVasXIYVFrQte2Cx95YaYgmTRdOTtyiEN/QEnKYq7qOn2DAAAAABJRU5ErkJggg==) no-repeat;
  background-position: center;
}
.container___2Jj3e .ant-checkbox-checked .ant-checkbox-inner::after {
  border: none !important;
}
.container___2Jj3e .ant-checkbox-inner {
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 6px;
  box-shadow: inset 3px 3px 10px 0px rgba(0, 0, 0, 0.2);
}
.container___2Jj3e .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.85);
}
.container___2Jj3e .ant-pagination-item-active,
.container___2Jj3e .ant-pagination-item:hover,
.container___2Jj3e .ant-pagination-prev:hover .ant-pagination-item-link,
.container___2Jj3e .ant-pagination-next:hover .ant-pagination-item-link {
  color: #33c3c7;
  border-color: #33c3c7;
}
.container___2Jj3e .ant-pagination-item-active a,
.container___2Jj3e .ant-pagination-item:hover a,
.container___2Jj3e .ant-pagination-prev:hover .ant-pagination-item-link a,
.container___2Jj3e .ant-pagination-next:hover .ant-pagination-item-link a {
  color: #33c3c7;
}
.container___2Jj3e .accountTable___2OMLA {
  margin-top: 16px;
}
.container___2Jj3e a {
  color: #1890ff;
}
.container___2Jj3e a:hover {
  color: #40a9ff;
}
.container___2Jj3e a[disabled] {
  color: rgba(0, 0, 0, 0.2) !important;
}
.container___2Jj3e .groupAction___27cw_ > div {
  text-align: left;
}
.container___2Jj3e .groupBottom___3ANht {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.container___2Jj3e .groupBottom___3ANht .hereButton___dhw94 {
  cursor: pointer;
  text-decoration: underline;
}
.container___2Jj3e .groupBottom___3ANht .hereButton___dhw94:hover {
  color: #1890ff;
}
.editUserModal .ant-modal {
  width: 750px !important;
}
.editUserModal .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px;
}
.editUserModal .ant-picker {
  height: 34px;
  border: 2px solid #ececec;
  width: 100%;
  border-radius: 8px;
}
.editUserModal .ant-form-item-label {
  padding-bottom: 4px;
}
.editUserModal button {
  border-radius: 8px;
  border: 2px solid #ececec !important;
  height: 34px;
}
.editUserModal button:hover, .editUserModal button:focus {
  color: unset;
}
.editUserModal button.ant-btn-primary {
  border-color: transparent !important;
}
.editUserModal button.ant-btn-primary:hover {
  color: #ffffff;
}
.editUserModal .ant-form-item-required.ant-form-item-no-colon {
  align-items: baseline !important;
}
.editUserModal .ant-form-item-has-error .ant-picker:not([disabled]):hover, .editUserModal .ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f;
}
.editUserModal .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editUserModal .ant-checkbox-wrapper {
  margin-left: 10px;
}
.editUserModal .ant-checkbox-checked .ant-checkbox-inner {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAABHNCSVQICAgIfAhkiAAAAVNJREFUOE+lk1FOwkAURe/t9IM/cQd1BRZMTEBB2AGuwLgCdAXiDnAFLEFcAUSUJiZAdyBL0C/46PSZKRlSpBCq/ZpM5p3ee997xD++8tv4LqY75F8Y/mBWVO5iANDXQDM3xH//8FUc9QzACNCOKuWCGAWOWs5IeNaBjgrHB0PSFtIR5LJTHo27BNu/MxTK/UFKzl7HLZDPWwDIU+yyuwFJPLvL9rRWebQFWTlA8K2JVlirDM27NaQ8CjoQaZMsGp/2gbkn8LBWYQBKNcKL89De0R8FDUfQSycugvm0Xjnxg8BTET73ARIlCQQwf7tKexbBLYEGiJvkPkPBWok9mBFmzA6Io6RGME+rg8j1pF7tZ034ZrBmGrUeWpAtMG2cXla7u1Zkq8UmBydCn8Dpyoa8TOrV1r4dy5yTVasXIYVFrQte2Cx95YaYgmTRdOTtyiEN/QEnKYq7qOn2DAAAAABJRU5ErkJggg==) no-repeat;
  background-position: center;
}
.editUserModal .ant-checkbox-checked .ant-checkbox-inner::after {
  border: none !important;
}
.editUserModal .ant-checkbox-inner {
  height: 20px;
  width: 20px;
  border: none;
  border-radius: 6px;
  box-shadow: inset 3px 3px 10px 0px rgba(0, 0, 0, 0.2);
}
.suspenseConfirm button {
  border-radius: 8px;
  border: 2px solid #ececec !important;
  height: 34px;
}
.suspenseConfirm button:hover, .suspenseConfirm button:focus {
  color: unset;
}
.suspenseConfirm button.ant-btn-primary {
  border-color: transparent !important;
}
.suspenseConfirm button.ant-btn-primary:hover, .suspenseConfirm button.ant-btn-primary:focus {
  color: #ffffff;
}
.invoiceContainer___1eOqT {
  padding: 0 40px;
}
.invoiceContainer___1eOqT table {
  font-family: 'Open Sans';
}
.invoiceContainer___1eOqT tr th,
.invoiceContainer___1eOqT tr td {
  border: 1px solid black;
  margin: 0px;
}
