@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___6bOAL {
  display: flex;
  justify-content: center;
}
button.btn___3rq3Q {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3rq3Q {
    padding: 10px;
  }
}
button.btn___3rq3Q:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3rq3Q:focus {
  outline: none;
}
.btnWhite___2V-89.btn___3rq3Q {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2V-89.btn___3rq3Q:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___HiJBx.btn___3rq3Q,
.btnGreen___HiJBx.btn___3rq3Q:hover,
.btnGreen___HiJBx.btn___3rq3Q:active,
.btnGreen___HiJBx.btn___3rq3Q:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___26o1_ {
  color: #fe0000;
}
.errorMessage___3Ks0o {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2b9M4 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3IjiO {
  display: none !important;
}
.m-auto___1FmAN {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3VHnj {
  max-width: 120px !important;
}
.fc___NDrRs .fc-timegrid-col___3TPX5.fc-day-today___2Xx0l {
  background-color: #ffffff;
}
.fc___NDrRs .fc-timegrid-slot___ZZb38 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___NDrRs .fc-timegrid-slot___ZZb38 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2lfOt .fc-direction-ltr___MK4dI .fc-timegrid-slot-label-frame___t-AHI {
    font-size: 10px;
  }
  .bookingCalendar___2lfOt .fc___NDrRs .fc-timegrid-slot-label-cushion___1hwbw {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2lfOt .fc___NDrRs .fc-scrollgrid-section-header___2zZHn .fc-scroller___1EFsG {
    overflow: unset !important;
  }
}
.ant-message-notice___31YBy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___nBR9k {
  width: 20px;
  height: 20px;
}
.bgTransparent___287Xu {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1VOJO {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___20SkC {
  position: relative;
}
.inputCustomValue___20SkC .ant-input___3wdK2 {
  padding-right: 50px;
}
.inputCustomValueText___3Y9kK {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___mmuTP .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___mmuTP .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1hrl7 .ant-radio-inner___1wgEl {
  border-color: black !important ;
}
.ant-radio-checked___1hrl7 .ant-radio-inner___1wgEl:after {
  background-color: black;
}
.ant-radio___2FWeN:hover .ant-radio-inner___1wgEl {
  border-color: black;
}
.ant-radio-checked___1hrl7 .ant-radio-inner___1wgEl:focus {
  border-color: black;
}
.modal___2670K {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2670K .close___drRne {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2670K .header___v-MjF {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2670K .header___v-MjF p {
  margin: 0;
}
.modal___2670K .content___3mn49 {
  text-align: center;
  font-size: 14px;
}
.modal___2670K .content___3mn49 p {
  margin: 0;
}
.modal___2670K .actions___pM0Bf {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2670K .actions___pM0Bf .button___YluYY {
  margin-top: 10px;
}
.modal___2670K .actions___pM0Bf:hover {
  color: #33c3c7;
}
.fc___NDrRs .fc-timegrid-slot-minor___3RGd_ {
  border-top-style: none;
}
.fc___NDrRs .fc-timegrid-slot___ZZb38 {
  height: 1.5em !important;
}
.messageError___3XYBl .ant-message-custom-content___3wNGG {
  display: flex;
  text-align: start;
}
.messageError___3XYBl .ant-message-custom-content___3wNGG span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3rB9F {
  background-color: #e6e4e4;
}
.notifyMessage___3xyiN .ant-message-notice-content___2vXqu {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3xyiN .ant-message-notice-content___2vXqu .anticon___2M_Tl {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3xyiN .ant-message-notice-content___2vXqu span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2bK_3 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2bK_3 .ant-notification-notice-message___2lP8f,
.notifyCustomize___2bK_3 .ant-notification-notice-close___d1n7k,
.notifyCustomize___2bK_3 .ant-notification-notice-icon___J2bE- {
  color: white;
}
.notifyCustomize___2bK_3 .ant-notification-notice-message___2lP8f {
  margin-left: 35px;
}
.notifyCustomize___2bK_3 p {
  margin-bottom: 0;
}
.notifyCustomize___2bK_3 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3I5v1 {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3I5v1 .ant-notification-notice-message___2lP8f {
  margin-left: 35px;
}
.notifyAlertDuplicate___3I5v1 p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3I5v1 p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3uI8v {
  width: 434px !important;
}
.handleSubmitVote___3uI8v .ant-btn-primary___415mb {
  background: #59c3c7;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 .ant-modal-confirm-body___1psgw {
  display: flex !important;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 .ant-modal-confirm-body___1psgw .anticon___2M_Tl {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 .ant-modal-confirm-btns___2tt1g button {
  font-weight: bold;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 .ant-modal-confirm-btns___2tt1g button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 .ant-modal-confirm-btns___2tt1g button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3uI8v .ant-modal-body___3fxK0 .ant-modal-confirm-btns___2tt1g .ant-btn-primary___415mb {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___mBXrp {
  margin-bottom: 0;
}
.mt-0___3LBSy {
  margin-top: 0;
}
.tooltipFormat___3OTkZ p {
  margin-bottom: 0;
}
.bgWhite___N2E55 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2Ai-F {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2Ai-F:hover,
.bgPrimaryBlue___2Ai-F:focus,
.bgPrimaryBlue___2Ai-F:active {
  background: #4f7ac7;
}
.bgDarkBlue___-kpGl {
  background-color: #023768 !important;
}
.bgDarkBlue___-kpGl:hover,
.bgDarkBlue___-kpGl:focus,
.bgDarkBlue___-kpGl:active {
  background: #194d7d;
}
.bgGreyBlue___Nn-5t {
  background-color: #ebf0f9;
}
.bgLightBlue___3ozcJ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2spxQ {
  background-color: #e4453a;
}
.bgLightRed___2spxQ:hover,
.bgLightRed___2spxQ:focus,
.bgLightRed___2spxQ:active {
  background: #e17871;
}
.bgLightYellow___2pmae {
  background-color: #ffd603;
}
.bgDarkGray___2rzKg {
  background-color: #333333;
}
.bgMediumGray___OPNmB {
  background-color: #a2a2a2;
}
.bgLightGray___19zov {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___26WAG {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3_gTP {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___39Lc5 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1q2ZP {
  color: #3368c7 !important;
}
.textDarkBlue___1_6X5 {
  color: #023768 !important;
}
.textLightBlue___1_LI5 {
  color: #b2cbf7;
}
.textDarkGray___-nCRH {
  color: #333333 !important;
}
.textMediumGray___44ZZw {
  color: #a2a2a2 !important;
}
.textLightGray___4PJ2M {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3dQqM {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___7rdF3 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___ITYO9 {
  border-radius: 5px !important;
}
.rounded-xs___16JYa {
  border-radius: 10px !important;
}
.rounded-sm___o7AS8 {
  border-radius: 15px !important;
}
.rounded-md___3pZvK {
  border-radius: 20px !important;
}
.rounded-lg___2PHQh {
  border-radius: 25px !important;
}
.rounded-full___3QoM4 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___moLMp {
  margin: 0;
}
.mt-2___3rtlJ {
  margin-top: 0.5rem;
}
.my-2___27jhi {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___23src {
  padding: 0;
}
.px-1___3i9TI {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3FrcA {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___C4Uaf {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___7Dd5r {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___srQPY {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___JHqZh {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___TRrsu {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___RJsBs {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3-CYA {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2zkV2 {
  padding-bottom: 20px;
}
.h-full___34Vhu {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1o7Cw {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___5J3j3 {
  font-weight: bold !important;
}
.fontWeight400___2QKen {
  font-weight: 400 !important;
}
.fontWeight500___tIbfr {
  font-weight: 500 !important;
}
.fontWeight600___2FENd {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___VApc9 {
  font-size: 12px !important;
}
.fontSize14___2fvA4 {
  font-size: 14px !important;
}
.fontSize16___3hyS3 {
  font-size: 16px !important;
}
.fontSize18___z-8zi {
  font-size: 18px !important;
}
.fontSize20___8Oi5g {
  font-size: 20px !important;
}
.fontSize24___18YCr {
  font-size: 24px !important;
}
.eventName___iVm4e {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___37lpU {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3PnNO > tr > th,
.ant-table-tbody___uuRp8 > tr.ant-table-row___9wEX2 > td,
.ant-table-summary___39Spk tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3PnNO > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___fusr0 .ant-table-thead___3PnNO > tr > th,
.table-portrait___fusr0 .ant-table-tbody___uuRp8 > tr.ant-table-row___9wEX2 > td,
.table-portrait___fusr0 .ant-table-summary___39Spk tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___fusr0 .bgLightGray___19zov td {
  background: #ebebeb;
}
.table-portrait___fusr0 .bgLightBlue___3ozcJ td {
  background: #b2cbf7;
}
.eventContent___3WSR4 .timeText___3Scmp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___uuRp8 > tr.bgLightGray___19zov:hover > td,
.ant-table-tbody___uuRp8 > tr > td.ant-table-cell-row-hover___kVTff {
  background: #ebebeb;
}
.ant-table-tbody___uuRp8 > tr.bgLightBlue___3ozcJ:hover > td,
.ant-table-tbody___uuRp8 > tr > td.ant-table-cell-row-hover___kVTff {
  background: #b2cbf7;
}
.ant-table-tbody___uuRp8 > tr.bgWhite___N2E55:hover > td,
.ant-table-tbody___uuRp8 > tr > td.ant-table-cell-row-hover___kVTff {
  background: #ffffff;
}
.step___2ZVgA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 710px;
  padding: 0 20px;
  margin: 50px auto 0;
  position: relative;
  overflow: hidden;
  padding-top: 10px;
}
@media screen and (max-width: 767px) {
  .step___2ZVgA {
    margin-top: 20px;
  }
}
.step___2ZVgA .stepNumber___3D_Rs {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #565656;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto 15px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .step___2ZVgA .stepNumber___3D_Rs {
    width: 26px;
    height: 26px;
  }
}
.step___2ZVgA .stepNumber___3D_Rs.stepActive___3szg6 {
  background-color: #33c3c7;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  color: #ffffff;
}
.step___2ZVgA::after {
  content: '';
  position: absolute;
  left: 150px;
  color: #a1a0a0;
  margin-left: 20px;
  font-size: 50px;
  letter-spacing: 40px;
  top: 32px;
  width: calc(100% - 300px);
  height: 30px;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAd4AAAAHCAYAAACm2CyQAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA21JREFUaIHd2klzFVUUAODvJdkoQ4IzLtQEZVAU9yqi4liWhT9DBflFaKJl/AEuLMsSUREcUNGFIyGoQAguUISEUBV1cW5XtW2/x8J3O/Q7u9eL9/W5t/sOfW5nanKyg5uxDZcxg1/ki8K7H0s4hl8HxOvgFtGWTeU2qF7ZupSsk5mswluP+xryhkR+TXiFtQ2Lma2V8oq+W8RPODUg3krkdivuXQFvIXmnB8Dq6Y1gHK/jOvyFeezGt5luZiJ565I3hz34bgC8DckaS9bpZH2fwYK7MFXyTiXvhwHwNiZrNFknk/VjBqvwXsNa/C0Wn3vEy5LLm2rI24TJkvVzso5lsFbC25y8Nck7gZczeluStzp5s8mbabkFd+PVknc8ecczeVvxClYlbwZ7M3lNWsSEuy95xLu9F7OdqcnJt/CMWPETg9yneCDDjYziDTxd8Q7joZZ7Y8l6qmIdwvY+W8RCYhpPVLyD2NFy73rRlo9XrA/xaJ+tXt4HeCyTN42dDXg34E3RboW1jAMi335HN28/nszg3Zi8Rxrybkrejor3nnj322oRXymm8XDFe1fMEf2O9SWviGW8g2dbbBE73Wn/HvuX8TaeGxIdOCQauoNhsYLcnOFmrhEvQ523qeXetWJSqrM29tkiVlE7a7wtYmfaZm+VmICazK2bd2cGb7WYmJrw1oiBu2yNiJ3Nhj5bV/ImMnhrG/ZGxeBd54232Cq87TXePbgjgzcmNkAd//Vub7FFbFQerPG24rah9KMaw12u/9/o5o0MgHe15NZ03+Xwioe0GiPJ63c07fV6Vvrt9eq3tudWeHX/Owje1ZJbrmelWHBWI0d+TVq9vGGMDInPrtX4TZ665CI+qbl+Vp46YZPeQg8rR13yoigJ1Hk56oRNehfxWc31eXnqdivhHWnIu4Avulg56oTdvDPy1NL+xJcNeufxVRdvtsUW/IGjXbwTGbzz+Lrm+pw4F9BWi2jLb7p5w8/v2nVEbLXX4Xcxebwgz0m2JXwuPlsU3uEB8ZbEYDpesg4lK8fJubI3lryP8eIAeJfE4D1Rsg4ma67PVjfvo+SdyeAtismi3Ja5vKp1TtTKX8pgXcmbz+AtiMmp6LtzolbehDda8nZn8Jq0iAXhUVGCKLwDGb0LySv33fviIN7ZFlvUt+X+wvsHKspbE8elaeEAAAAASUVORK5CYII=);
}
@media screen and (max-width: 767px) {
  .step___2ZVgA::after {
    z-index: 111;
    width: 66px;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    margin-left: 0;
  }
}
.step___2ZVgA .stepTitle___2ub31 {
  color: #414141;
  font-size: 16px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .step___2ZVgA .stepTitle___2ub31 {
    font-size: 14px;
  }
}
.step___2ZVgA .stepItem___2940r {
  z-index: 9;
}
.step___2ZVgA .stepItem___2940r:last-child .stepNumber___3D_Rs::after {
  content: '';
}
.step___2ZVgA .stepContent___1BWih {
  width: 90px;
  background: #ffffff;
  margin: auto;
}
