@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___29IXO {
  display: flex;
  justify-content: center;
}
button.btn___3QFUu {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3QFUu {
    padding: 10px;
  }
}
button.btn___3QFUu:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3QFUu:focus {
  outline: none;
}
.btnWhite___1fPKk.btn___3QFUu {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1fPKk.btn___3QFUu:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3BDGp.btn___3QFUu,
.btnGreen___3BDGp.btn___3QFUu:hover,
.btnGreen___3BDGp.btn___3QFUu:active,
.btnGreen___3BDGp.btn___3QFUu:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___bKRhk {
  color: #fe0000;
}
.errorMessage___2INcZ {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2Ef87 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___aX8J5 {
  display: none !important;
}
.m-auto___pGpcR {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1fgM4 {
  max-width: 120px !important;
}
.fc___1__yy .fc-timegrid-col___3uNl4.fc-day-today___3gSRN {
  background-color: #ffffff;
}
.fc___1__yy .fc-timegrid-slot___2fzEQ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1__yy .fc-timegrid-slot___2fzEQ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2x_yR .fc-direction-ltr___138OI .fc-timegrid-slot-label-frame___336NU {
    font-size: 10px;
  }
  .bookingCalendar___2x_yR .fc___1__yy .fc-timegrid-slot-label-cushion___3829o {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2x_yR .fc___1__yy .fc-scrollgrid-section-header___1_m0Q .fc-scroller___2-2eo {
    overflow: unset !important;
  }
}
.ant-message-notice___2W-zg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2SSt_ {
  width: 20px;
  height: 20px;
}
.bgTransparent___gULuz {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___cHS9D {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2n2Hy {
  position: relative;
}
.inputCustomValue___2n2Hy .ant-input___1Q5Bb {
  padding-right: 50px;
}
.inputCustomValueText___3qte3 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2vsck .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2vsck .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___30bqv .ant-radio-inner___28YuE {
  border-color: black !important ;
}
.ant-radio-checked___30bqv .ant-radio-inner___28YuE:after {
  background-color: black;
}
.ant-radio___JbLus:hover .ant-radio-inner___28YuE {
  border-color: black;
}
.ant-radio-checked___30bqv .ant-radio-inner___28YuE:focus {
  border-color: black;
}
.modal___2yCT5 {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2yCT5 .close___1AKNw {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2yCT5 .header___31uAS {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2yCT5 .header___31uAS p {
  margin: 0;
}
.modal___2yCT5 .content___2roka {
  text-align: center;
  font-size: 14px;
}
.modal___2yCT5 .content___2roka p {
  margin: 0;
}
.modal___2yCT5 .actions___3k8j4 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2yCT5 .actions___3k8j4 .button___3LYHm {
  margin-top: 10px;
}
.modal___2yCT5 .actions___3k8j4:hover {
  color: #33c3c7;
}
.fc___1__yy .fc-timegrid-slot-minor___3FLUS {
  border-top-style: none;
}
.fc___1__yy .fc-timegrid-slot___2fzEQ {
  height: 1.5em !important;
}
.messageError___DL3AS .ant-message-custom-content___OTDpN {
  display: flex;
  text-align: start;
}
.messageError___DL3AS .ant-message-custom-content___OTDpN span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___Th4kY {
  background-color: #e6e4e4;
}
.notifyMessage___3qGGw .ant-message-notice-content___2pbYN {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3qGGw .ant-message-notice-content___2pbYN .anticon___1bIf1 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3qGGw .ant-message-notice-content___2pbYN span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2f3gy {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2f3gy .ant-notification-notice-message___3YGTR,
.notifyCustomize___2f3gy .ant-notification-notice-close___2XfD5,
.notifyCustomize___2f3gy .ant-notification-notice-icon___3wjhe {
  color: white;
}
.notifyCustomize___2f3gy .ant-notification-notice-message___3YGTR {
  margin-left: 35px;
}
.notifyCustomize___2f3gy p {
  margin-bottom: 0;
}
.notifyCustomize___2f3gy p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3we2a {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3we2a .ant-notification-notice-message___3YGTR {
  margin-left: 35px;
}
.notifyAlertDuplicate___3we2a p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3we2a p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1Dhve {
  width: 434px !important;
}
.handleSubmitVote___1Dhve .ant-btn-primary___1abgG {
  background: #59c3c7;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 .ant-modal-confirm-body___3qwyr {
  display: flex !important;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 .ant-modal-confirm-body___3qwyr .anticon___1bIf1 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 .ant-modal-confirm-btns___3DTjz button {
  font-weight: bold;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 .ant-modal-confirm-btns___3DTjz button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 .ant-modal-confirm-btns___3DTjz button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1Dhve .ant-modal-body____SWD3 .ant-modal-confirm-btns___3DTjz .ant-btn-primary___1abgG {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1Wsxr {
  margin-bottom: 0;
}
.mt-0___3EW5q {
  margin-top: 0;
}
.tooltipFormat___2joMI p {
  margin-bottom: 0;
}
.bgWhite___Cru1K {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___333ju {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___333ju:hover,
.bgPrimaryBlue___333ju:focus,
.bgPrimaryBlue___333ju:active {
  background: #4f7ac7;
}
.bgDarkBlue___16rdJ {
  background-color: #023768 !important;
}
.bgDarkBlue___16rdJ:hover,
.bgDarkBlue___16rdJ:focus,
.bgDarkBlue___16rdJ:active {
  background: #194d7d;
}
.bgGreyBlue___3oGoI {
  background-color: #ebf0f9;
}
.bgLightBlue___2B3xH {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2XuAQ {
  background-color: #e4453a;
}
.bgLightRed___2XuAQ:hover,
.bgLightRed___2XuAQ:focus,
.bgLightRed___2XuAQ:active {
  background: #e17871;
}
.bgLightYellow___97-qt {
  background-color: #ffd603;
}
.bgDarkGray___25Ft1 {
  background-color: #333333;
}
.bgMediumGray___1_c_6 {
  background-color: #a2a2a2;
}
.bgLightGray___34zeh {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___392Oy {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1IdX2 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___h7c-s {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___73uJu {
  color: #3368c7 !important;
}
.textDarkBlue___1eX2G {
  color: #023768 !important;
}
.textLightBlue___1-87W {
  color: #b2cbf7;
}
.textDarkGray___3WqCv {
  color: #333333 !important;
}
.textMediumGray___2Ua1a {
  color: #a2a2a2 !important;
}
.textLightGray___3Ifut {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1gVlr {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___my7n7 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___ZoqKI {
  border-radius: 5px !important;
}
.rounded-xs___27Znk {
  border-radius: 10px !important;
}
.rounded-sm___2Uw4V {
  border-radius: 15px !important;
}
.rounded-md___2DEvU {
  border-radius: 20px !important;
}
.rounded-lg___h3K85 {
  border-radius: 25px !important;
}
.rounded-full___3onF2 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___24SBX {
  margin: 0;
}
.mt-2___2qy-F {
  margin-top: 0.5rem;
}
.my-2___1S6tQ {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1o5y5 {
  padding: 0;
}
.px-1___2B5Bq {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1f0VW {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___31nGe {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___ZsqHi {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___aMIRr {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___G6LYf {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___tME1i {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1k7BG {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2usIi {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2QIc9 {
  padding-bottom: 20px;
}
.h-full___3dUMC {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3HrrZ {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___jBrz- {
  font-weight: bold !important;
}
.fontWeight400___3DzX_ {
  font-weight: 400 !important;
}
.fontWeight500___2niiT {
  font-weight: 500 !important;
}
.fontWeight600___1pECg {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2ft1R {
  font-size: 12px !important;
}
.fontSize14___aSB8h {
  font-size: 14px !important;
}
.fontSize16___Yw2nz {
  font-size: 16px !important;
}
.fontSize18___2c3KP {
  font-size: 18px !important;
}
.fontSize20___3NKtc {
  font-size: 20px !important;
}
.fontSize24___Bx2XK {
  font-size: 24px !important;
}
.eventName___jCL7P {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1Hd3n {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___cUzhG > tr > th,
.ant-table-tbody___kXIdU > tr.ant-table-row___2bFfQ > td,
.ant-table-summary___a1MU0 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___cUzhG > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___14KoG .ant-table-thead___cUzhG > tr > th,
.table-portrait___14KoG .ant-table-tbody___kXIdU > tr.ant-table-row___2bFfQ > td,
.table-portrait___14KoG .ant-table-summary___a1MU0 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___14KoG .bgLightGray___34zeh td {
  background: #ebebeb;
}
.table-portrait___14KoG .bgLightBlue___2B3xH td {
  background: #b2cbf7;
}
.eventContent___1jFXK .timeText___2I2RG {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___kXIdU > tr.bgLightGray___34zeh:hover > td,
.ant-table-tbody___kXIdU > tr > td.ant-table-cell-row-hover___BlS6i {
  background: #ebebeb;
}
.ant-table-tbody___kXIdU > tr.bgLightBlue___2B3xH:hover > td,
.ant-table-tbody___kXIdU > tr > td.ant-table-cell-row-hover___BlS6i {
  background: #b2cbf7;
}
.ant-table-tbody___kXIdU > tr.bgWhite___Cru1K:hover > td,
.ant-table-tbody___kXIdU > tr > td.ant-table-cell-row-hover___BlS6i {
  background: #ffffff;
}
.swipeable-list-item___3wkCD {
  overflow: unset;
}
.swipeActionDiv___rnjwB {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d64545;
  color: white;
  padding-inline: 10px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
