@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3vAjk {
  display: flex;
  justify-content: center;
}
button.btn___3rIYb {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3rIYb {
    padding: 10px;
  }
}
button.btn___3rIYb:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3rIYb:focus {
  outline: none;
}
.btnWhite___NribF.btn___3rIYb {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___NribF.btn___3rIYb:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2xoEr.btn___3rIYb,
.btnGreen___2xoEr.btn___3rIYb:hover,
.btnGreen___2xoEr.btn___3rIYb:active,
.btnGreen___2xoEr.btn___3rIYb:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3Y8ml {
  color: #fe0000;
}
.errorMessage___alEvh {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2ct4T {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___12Tms {
  display: none !important;
}
.m-auto___2kDKd {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3drGF {
  max-width: 120px !important;
}
.fc___SzhCw .fc-timegrid-col___1s7EL.fc-day-today___2blS0 {
  background-color: #ffffff;
}
.fc___SzhCw .fc-timegrid-slot___e9j-o {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___SzhCw .fc-timegrid-slot___e9j-o {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1oJqh .fc-direction-ltr___adBOr .fc-timegrid-slot-label-frame___3Z7x9 {
    font-size: 10px;
  }
  .bookingCalendar___1oJqh .fc___SzhCw .fc-timegrid-slot-label-cushion___3nVzv {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1oJqh .fc___SzhCw .fc-scrollgrid-section-header___102ri .fc-scroller___3Jr2a {
    overflow: unset !important;
  }
}
.ant-message-notice___2J6IB {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2PvmQ {
  width: 20px;
  height: 20px;
}
.bgTransparent___2LJkx {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___eQ019 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___36YHk {
  position: relative;
}
.inputCustomValue___36YHk .ant-input___3RvM4 {
  padding-right: 50px;
}
.inputCustomValueText___1RG-s {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3pKAB .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3pKAB .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2Vh8j .ant-radio-inner___2aHYn {
  border-color: black !important ;
}
.ant-radio-checked___2Vh8j .ant-radio-inner___2aHYn:after {
  background-color: black;
}
.ant-radio___18dnJ:hover .ant-radio-inner___2aHYn {
  border-color: black;
}
.ant-radio-checked___2Vh8j .ant-radio-inner___2aHYn:focus {
  border-color: black;
}
.modal___2x_eQ {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2x_eQ .close___2s5OF {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2x_eQ .header___2EvLT {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2x_eQ .header___2EvLT p {
  margin: 0;
}
.modal___2x_eQ .content___2WVZi {
  text-align: center;
  font-size: 14px;
}
.modal___2x_eQ .content___2WVZi p {
  margin: 0;
}
.modal___2x_eQ .actions___2TxED {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2x_eQ .actions___2TxED .button___1lSSO {
  margin-top: 10px;
}
.modal___2x_eQ .actions___2TxED:hover {
  color: #33c3c7;
}
.fc___SzhCw .fc-timegrid-slot-minor___1IbC4 {
  border-top-style: none;
}
.fc___SzhCw .fc-timegrid-slot___e9j-o {
  height: 1.5em !important;
}
.messageError___3eE-e .ant-message-custom-content___3lp6f {
  display: flex;
  text-align: start;
}
.messageError___3eE-e .ant-message-custom-content___3lp6f span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___33lXu {
  background-color: #e6e4e4;
}
.notifyMessage___3trBY .ant-message-notice-content___3wqSz {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3trBY .ant-message-notice-content___3wqSz .anticon___33l56 {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3trBY .ant-message-notice-content___3wqSz span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1bqzr {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1bqzr .ant-notification-notice-message___1YcPO,
.notifyCustomize___1bqzr .ant-notification-notice-close___xr4jx,
.notifyCustomize___1bqzr .ant-notification-notice-icon___1KqIm {
  color: white;
}
.notifyCustomize___1bqzr .ant-notification-notice-message___1YcPO {
  margin-left: 35px;
}
.notifyCustomize___1bqzr p {
  margin-bottom: 0;
}
.notifyCustomize___1bqzr p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___23JGM {
  padding: 16px 14px;
}
.notifyAlertDuplicate___23JGM .ant-notification-notice-message___1YcPO {
  margin-left: 35px;
}
.notifyAlertDuplicate___23JGM p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___23JGM p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1BJ3P {
  width: 434px !important;
}
.handleSubmitVote___1BJ3P .ant-btn-primary___1TrtX {
  background: #59c3c7;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT .ant-modal-confirm-body___1lL14 {
  display: flex !important;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT .ant-modal-confirm-body___1lL14 .anticon___33l56 {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT .ant-modal-confirm-btns___2reLb button {
  font-weight: bold;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT .ant-modal-confirm-btns___2reLb button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT .ant-modal-confirm-btns___2reLb button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1BJ3P .ant-modal-body___ikhvT .ant-modal-confirm-btns___2reLb .ant-btn-primary___1TrtX {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___39bmH {
  margin-bottom: 0;
}
.mt-0___2BD33 {
  margin-top: 0;
}
.tooltipFormat___2IGm3 p {
  margin-bottom: 0;
}
.bgWhite___yMvX0 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3MLPS {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3MLPS:hover,
.bgPrimaryBlue___3MLPS:focus,
.bgPrimaryBlue___3MLPS:active {
  background: #4f7ac7;
}
.bgDarkBlue___2sPtw {
  background-color: #023768 !important;
}
.bgDarkBlue___2sPtw:hover,
.bgDarkBlue___2sPtw:focus,
.bgDarkBlue___2sPtw:active {
  background: #194d7d;
}
.bgGreyBlue___44WZx {
  background-color: #ebf0f9;
}
.bgLightBlue___eJQ-Z {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1nGrN {
  background-color: #e4453a;
}
.bgLightRed___1nGrN:hover,
.bgLightRed___1nGrN:focus,
.bgLightRed___1nGrN:active {
  background: #e17871;
}
.bgLightYellow___3Jucf {
  background-color: #ffd603;
}
.bgDarkGray___2KQL8 {
  background-color: #333333;
}
.bgMediumGray___2A65E {
  background-color: #a2a2a2;
}
.bgLightGray___1XaIC {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___dJ2nM {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3L3J3 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___WxJH3 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3qndu {
  color: #3368c7 !important;
}
.textDarkBlue___TuaV9 {
  color: #023768 !important;
}
.textLightBlue___1zmZN {
  color: #b2cbf7;
}
.textDarkGray___37ymo {
  color: #333333 !important;
}
.textMediumGray___2keGI {
  color: #a2a2a2 !important;
}
.textLightGray___2R1Ev {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___9XXx3 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___toqfF {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2ZrMr {
  border-radius: 5px !important;
}
.rounded-xs___1ZHNF {
  border-radius: 10px !important;
}
.rounded-sm___1Kk_j {
  border-radius: 15px !important;
}
.rounded-md___syE2G {
  border-radius: 20px !important;
}
.rounded-lg___GdfZ7 {
  border-radius: 25px !important;
}
.rounded-full___2X-fW {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3S82X {
  margin: 0;
}
.mt-2___1rMxV {
  margin-top: 0.5rem;
}
.my-2___2utwG {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1Kt8w {
  padding: 0;
}
.px-1___bTs01 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___28Y27 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2LA5j {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___PXCiT {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1MPMR {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1mHxE {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___50mQ6 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3LQI4 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1a32W {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___36_E0 {
  padding-bottom: 20px;
}
.h-full___3d-MX {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1Xk6D {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___93heP {
  font-weight: bold !important;
}
.fontWeight400___1Ct3q {
  font-weight: 400 !important;
}
.fontWeight500___25LxL {
  font-weight: 500 !important;
}
.fontWeight600___1-5ld {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2GKzF {
  font-size: 12px !important;
}
.fontSize14___123el {
  font-size: 14px !important;
}
.fontSize16___3jssZ {
  font-size: 16px !important;
}
.fontSize18___gyQxh {
  font-size: 18px !important;
}
.fontSize20___3PT5S {
  font-size: 20px !important;
}
.fontSize24___24U4K {
  font-size: 24px !important;
}
.eventName___xPZuf {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1djUj {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1YvAG > tr > th,
.ant-table-tbody___19Icq > tr.ant-table-row___1Da5z > td,
.ant-table-summary___29HJM tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1YvAG > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___tZdI1 .ant-table-thead___1YvAG > tr > th,
.table-portrait___tZdI1 .ant-table-tbody___19Icq > tr.ant-table-row___1Da5z > td,
.table-portrait___tZdI1 .ant-table-summary___29HJM tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___tZdI1 .bgLightGray___1XaIC td {
  background: #ebebeb;
}
.table-portrait___tZdI1 .bgLightBlue___eJQ-Z td {
  background: #b2cbf7;
}
.eventContent___5jDPr .timeText___10Aul {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___19Icq > tr.bgLightGray___1XaIC:hover > td,
.ant-table-tbody___19Icq > tr > td.ant-table-cell-row-hover___3kpLu {
  background: #ebebeb;
}
.ant-table-tbody___19Icq > tr.bgLightBlue___eJQ-Z:hover > td,
.ant-table-tbody___19Icq > tr > td.ant-table-cell-row-hover___3kpLu {
  background: #b2cbf7;
}
.ant-table-tbody___19Icq > tr.bgWhite___yMvX0:hover > td,
.ant-table-tbody___19Icq > tr > td.ant-table-cell-row-hover___3kpLu {
  background: #ffffff;
}
.buttonZone___2NiDI {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.buttonZone___2NiDI .cancelBtn___1NLbn {
  height: 48px;
  min-width: 148px;
  background-color: #e8e6e6;
  border: 1px solid #e8e6e6;
  border-radius: 24px;
  margin: 0 10px;
  color: #414141;
}
@media screen and (max-width: 767px) {
  .buttonZone___2NiDI .cancelBtn___1NLbn {
    font-size: 12px;
    height: 35px;
    min-width: 90px;
  }
}
.buttonZone___2NiDI .saveBtn___24cbz {
  height: 48px;
  min-width: 148px;
  background-color: #33c3c7;
  border-radius: 24px;
  border: 1px solid #33c3c7;
  margin: 0 10px;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .buttonZone___2NiDI .saveBtn___24cbz {
    font-size: 12px;
    height: 35px;
    min-width: 90px;
  }
}
.helper___2PvmQ {
  display: inline-block;
  width: 23px;
  height: 23px;
  cursor: pointer;
  position: relative;
}
.helper___2PvmQ img {
  position: relative;
  width: 100%;
  height: 100%;
}
.helper___2PvmQ .helperTooltip___RRTfd {
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 50px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 120px;
  width: 400px;
  background-color: #414141;
  color: #fffefe;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.helper___2PvmQ .helperTooltip___RRTfd::-webkit-scrollbar {
  display: none;
}
.helper___2PvmQ .helperTooltip___RRTfd img {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.helper___2PvmQ:hover .helperTooltip___RRTfd {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
.devideIcon___3qb23 {
  margin: 0 9px;
}
.textareaField___2bTGu {
  margin-bottom: 28px;
}
.selectField___dvfwT {
  min-height: 110px;
}
.selectField___dvfwT .helper___2PvmQ {
  margin-top: 0;
}
.selectField___dvfwT .titleField___1Zv0E,
.textareaField___2bTGu .titleField___1Zv0E {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
}
.selectField___dvfwT .titleField___1Zv0E .toolTipAdvanced___12saj,
.textareaField___2bTGu .titleField___1Zv0E .toolTipAdvanced___12saj {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.selectField___dvfwT .titleField___1Zv0E .titleFieldIcon___2zZ4i,
.textareaField___2bTGu .titleField___1Zv0E .titleFieldIcon___2zZ4i {
  width: 7px;
  height: 17px;
  background-color: #414141;
  margin-right: 5px;
}
.selectField___dvfwT .titleField___1Zv0E p,
.textareaField___2bTGu .titleField___1Zv0E p {
  font-size: 17px;
  color: #3c3c3c;
  margin: 0 10px 0 0;
}
@media screen and (max-width: 767px) {
  .selectField___dvfwT .titleField___1Zv0E .fs16___IS9d5,
  .textareaField___2bTGu .titleField___1Zv0E .fs16___IS9d5 {
    font-size: 16px;
  }
}
.selectField___dvfwT .titleField___1Zv0E .helper___2PvmQ,
.textareaField___2bTGu .titleField___1Zv0E .helper___2PvmQ {
  margin-top: 0;
}
.selectField___dvfwT .selections___zhFzN .devideIcon___3qb23,
.textareaField___2bTGu .selections___zhFzN .devideIcon___3qb23 {
  margin: 0 9px;
}
.selectField___dvfwT .selections___zhFzN .width120___3JtXU,
.textareaField___2bTGu .selections___zhFzN .width120___3JtXU {
  width: 120px;
}
.selectField___dvfwT textarea,
.textareaField___2bTGu textarea {
  height: 120px;
  width: 100%;
  font-size: 16px;
  border-radius: 17px;
  border: 2px solid #ebebeb;
}
.selectField___dvfwT textarea:hover,
.textareaField___2bTGu textarea:hover {
  border: #33c3c7 2px solid !important;
}
.advancedSetting___31y4n:hover {
  cursor: pointer;
}
.displayNone___CygaN {
  display: none;
}
.tooltipAdvanced___1CkNp {
  white-space: pre-line;
}
.tooltipAdvanced___1CkNp .ant-tooltip-inner {
  height: 119px;
  min-width: 300px;
  justify-content: center;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  .tooltipAdvanced___1CkNp .ant-tooltip-inner {
    min-width: auto;
    height: auto;
  }
}
.tooltipAdvanced___1CkNp .ant-tooltip-content .ant-tooltip-arrow {
  width: 30px;
  height: 30px;
}
.tooltipAdvanced___1CkNp .ant-tooltip-content .ant-tooltip-arrow-content {
  background-color: transparent;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.75);
  transform: unset;
  box-shadow: unset;
  top: 13px;
}
@media screen and (max-width: 767px) {
  .timePicker___2bkKh .ant-picker-time-panel-column {
    float: left;
    height: 110%;
    overflow-y: auto;
  }
  .timePicker___2bkKh .ant-picker-time-panel-column::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .timePicker___2bkKh .ant-picker-time-panel-column::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
  }
  .timePicker___2bkKh .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }
}
.timePicker___2bkKh .ant-picker-panel .ant-picker-footer {
  display: none;
}
.timePicker___2bkKh .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.timePicker___2bkKh .ant-picker-time-panel-column {
  width: 80px;
}
.timePicker___2bkKh .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___2bkKh .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell::after {
  content: '時';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
.timePicker___2bkKh .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___2bkKh .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell::after {
  content: '分';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
