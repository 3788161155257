@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___1IEmD {
  display: flex;
  justify-content: center;
}
button.btn___1cPq4 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1cPq4 {
    padding: 10px;
  }
}
button.btn___1cPq4:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1cPq4:focus {
  outline: none;
}
.btnWhite___iAHL1.btn___1cPq4 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___iAHL1.btn___1cPq4:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2svLH.btn___1cPq4,
.btnGreen___2svLH.btn___1cPq4:hover,
.btnGreen___2svLH.btn___1cPq4:active,
.btnGreen___2svLH.btn___1cPq4:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___tC-nn {
  color: #fe0000;
}
.errorMessage___2Ua4H {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3jHCI {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2D7en {
  display: none !important;
}
.m-auto___2W1dW {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___PbLGp {
  max-width: 120px !important;
}
.fc___3jPj- .fc-timegrid-col___2iwht.fc-day-today___38Uz0 {
  background-color: #ffffff;
}
.fc___3jPj- .fc-timegrid-slot___2U5ge {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3jPj- .fc-timegrid-slot___2U5ge {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L .fc-direction-ltr___3LrFB .fc-timegrid-slot-label-frame___2k2Nx {
    font-size: 10px;
  }
  .bookingCalendar___2bc8L .fc___3jPj- .fc-timegrid-slot-label-cushion___10fm9 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2bc8L .fc___3jPj- .fc-scrollgrid-section-header___1SEfg .fc-scroller___3YOLt {
    overflow: unset !important;
  }
}
.ant-message-notice___2GNf4 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___c2dCk {
  width: 20px;
  height: 20px;
}
.bgTransparent___2EMF1 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___TSrDy {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___pAk4o {
  position: relative;
}
.inputCustomValue___pAk4o .ant-input___3jF4c {
  padding-right: 50px;
}
.inputCustomValueText___2gr8k {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3PqzB .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3PqzB .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3dc_- .ant-radio-inner___1e3p1 {
  border-color: black !important ;
}
.ant-radio-checked___3dc_- .ant-radio-inner___1e3p1:after {
  background-color: black;
}
.ant-radio___6JlrK:hover .ant-radio-inner___1e3p1 {
  border-color: black;
}
.ant-radio-checked___3dc_- .ant-radio-inner___1e3p1:focus {
  border-color: black;
}
.modal___13lIA {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___13lIA .close___1XFGt {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___13lIA .header___1kE6H {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___13lIA .header___1kE6H p {
  margin: 0;
}
.modal___13lIA .content___rLDgM {
  text-align: center;
  font-size: 14px;
}
.modal___13lIA .content___rLDgM p {
  margin: 0;
}
.modal___13lIA .actions___3vJpf {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___13lIA .actions___3vJpf .button___27jT0 {
  margin-top: 10px;
}
.modal___13lIA .actions___3vJpf:hover {
  color: #33c3c7;
}
.fc___3jPj- .fc-timegrid-slot-minor___35k9x {
  border-top-style: none;
}
.fc___3jPj- .fc-timegrid-slot___2U5ge {
  height: 1.5em !important;
}
.messageError___20cCF .ant-message-custom-content___20_gd {
  display: flex;
  text-align: start;
}
.messageError___20cCF .ant-message-custom-content___20_gd span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___yTQ6T {
  background-color: #e6e4e4;
}
.notifyMessage___3dUe7 .ant-message-notice-content___2IHkZ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3dUe7 .ant-message-notice-content___2IHkZ .anticon___eML7i {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3dUe7 .ant-message-notice-content___2IHkZ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___36i9u {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___36i9u .ant-notification-notice-message___3OQSk,
.notifyCustomize___36i9u .ant-notification-notice-close___1qVrA,
.notifyCustomize___36i9u .ant-notification-notice-icon___2CV86 {
  color: white;
}
.notifyCustomize___36i9u .ant-notification-notice-message___3OQSk {
  margin-left: 35px;
}
.notifyCustomize___36i9u p {
  margin-bottom: 0;
}
.notifyCustomize___36i9u p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3y_eZ {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3y_eZ .ant-notification-notice-message___3OQSk {
  margin-left: 35px;
}
.notifyAlertDuplicate___3y_eZ p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3y_eZ p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3uZzB {
  width: 434px !important;
}
.handleSubmitVote___3uZzB .ant-btn-primary___3h-Nz {
  background: #59c3c7;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 .ant-modal-confirm-body___luh-l {
  display: flex !important;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 .ant-modal-confirm-body___luh-l .anticon___eML7i {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 .ant-modal-confirm-btns___uuVbt button {
  font-weight: bold;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 .ant-modal-confirm-btns___uuVbt button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 .ant-modal-confirm-btns___uuVbt button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3uZzB .ant-modal-body___D3Ev1 .ant-modal-confirm-btns___uuVbt .ant-btn-primary___3h-Nz {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___WoaLB {
  margin-bottom: 0;
}
.mt-0___vWRll {
  margin-top: 0;
}
.tooltipFormat___36Idx p {
  margin-bottom: 0;
}
.bgWhite___3ppkx {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2AaNX {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2AaNX:hover,
.bgPrimaryBlue___2AaNX:focus,
.bgPrimaryBlue___2AaNX:active {
  background: #4f7ac7;
}
.bgDarkBlue___17uPs {
  background-color: #023768 !important;
}
.bgDarkBlue___17uPs:hover,
.bgDarkBlue___17uPs:focus,
.bgDarkBlue___17uPs:active {
  background: #194d7d;
}
.bgGreyBlue___3JPhE {
  background-color: #ebf0f9;
}
.bgLightBlue___t7Ngb {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1lA5x {
  background-color: #e4453a;
}
.bgLightRed___1lA5x:hover,
.bgLightRed___1lA5x:focus,
.bgLightRed___1lA5x:active {
  background: #e17871;
}
.bgLightYellow___1hkox {
  background-color: #ffd603;
}
.bgDarkGray___3kGtN {
  background-color: #333333;
}
.bgMediumGray___3rRRm {
  background-color: #a2a2a2;
}
.bgLightGray___2ybPx {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2a5fN {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3L4c- {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___26Utm {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2_FJj {
  color: #3368c7 !important;
}
.textDarkBlue___25d8x {
  color: #023768 !important;
}
.textLightBlue___Aadv_ {
  color: #b2cbf7;
}
.textDarkGray___DPG2a {
  color: #333333 !important;
}
.textMediumGray___3z8zb {
  color: #a2a2a2 !important;
}
.textLightGray___y1SqP {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___L8gH7 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1QRWG {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___bGhef {
  border-radius: 5px !important;
}
.rounded-xs___2vbm1 {
  border-radius: 10px !important;
}
.rounded-sm___3T7wL {
  border-radius: 15px !important;
}
.rounded-md___3jap9 {
  border-radius: 20px !important;
}
.rounded-lg___1suhI {
  border-radius: 25px !important;
}
.rounded-full___2-Mc8 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___21Dug {
  margin: 0;
}
.mt-2___1bCiq {
  margin-top: 0.5rem;
}
.my-2___23oUL {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___T_IIx {
  padding: 0;
}
.px-1___3LISi {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___32GT4 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___Rdyhq {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1gzfI {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2pji4 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___dvNCp {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3SjaA {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3wvUK {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2L8ZM {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___lRxdo {
  padding-bottom: 20px;
}
.h-full___1i2hd {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2yWxZ {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1_EJF {
  font-weight: bold !important;
}
.fontWeight400___1Bp_s {
  font-weight: 400 !important;
}
.fontWeight500___1V4qf {
  font-weight: 500 !important;
}
.fontWeight600___2Ta90 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___yswee {
  font-size: 12px !important;
}
.fontSize14___1-pER {
  font-size: 14px !important;
}
.fontSize16___1RY6r {
  font-size: 16px !important;
}
.fontSize18___2tgN2 {
  font-size: 18px !important;
}
.fontSize20___1sXxb {
  font-size: 20px !important;
}
.fontSize24___20e06 {
  font-size: 24px !important;
}
.eventName___iT9kb {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___22UUZ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___WzMaO > tr > th,
.ant-table-tbody___37eF- > tr.ant-table-row___1xkbt > td,
.ant-table-summary___3upWi tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___WzMaO > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1s-ST .ant-table-thead___WzMaO > tr > th,
.table-portrait___1s-ST .ant-table-tbody___37eF- > tr.ant-table-row___1xkbt > td,
.table-portrait___1s-ST .ant-table-summary___3upWi tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1s-ST .bgLightGray___2ybPx td {
  background: #ebebeb;
}
.table-portrait___1s-ST .bgLightBlue___t7Ngb td {
  background: #b2cbf7;
}
.eventContent___1FnxM .timeText___1zOWZ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___37eF- > tr.bgLightGray___2ybPx:hover > td,
.ant-table-tbody___37eF- > tr > td.ant-table-cell-row-hover___Z9LX7 {
  background: #ebebeb;
}
.ant-table-tbody___37eF- > tr.bgLightBlue___t7Ngb:hover > td,
.ant-table-tbody___37eF- > tr > td.ant-table-cell-row-hover___Z9LX7 {
  background: #b2cbf7;
}
.ant-table-tbody___37eF- > tr.bgWhite___3ppkx:hover > td,
.ant-table-tbody___37eF- > tr > td.ant-table-cell-row-hover___Z9LX7 {
  background: #ffffff;
}
.bookingCalendar___2bc8L {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L {
    box-shadow: none;
  }
}
.bookingCalendar___2bc8L .fc-scroller .fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none;
}
.bookingCalendar___2bc8L .fc-scrollgrid-section-header .fc-scroller::-webkit-scrollbar {
  border-bottom: 1px solid #ddd;
}
.bookingCalendar___2bc8L .ant-tooltip-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.bookingCalendar___2bc8L .fc-event-main {
  background-color: transparent;
  border-radius: 7px;
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L .fc .fc-view-harness {
    min-height: 400px;
  }
}
.bookingCalendar___2bc8L .fc-media-screen .fc-timegrid-event {
  border: none;
}
.bookingCalendar___2bc8L .fc-media-screen .fc-timegrid-event-harness {
  background-color: #e3f4f4;
  border-radius: 10px;
  overflow: unset;
}
.bookingCalendar___2bc8L .fc-media-screen .fc-timegrid-event-harness:hover {
  width: 100% !important;
  left: 0 !important;
  z-index: 0.9 !important;
}
.bookingCalendar___2bc8L .fc-media-screen .fc-timegrid-event {
  border-radius: 10px;
  overflow: unset;
  background-color: #e3f4f4;
}
.bookingCalendar___2bc8L .disabledNav___2rztv {
  pointer-events: none;
  display: none !important;
}
.bookingCalendar___2bc8L .prevBtn___1aLl- {
  background-color: #ffffff;
  box-shadow: 0 0 5px #b3bcbc60;
  border: none;
  border-radius: 7px;
  position: absolute;
  top: 27px;
  left: -15px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bookingCalendar___2bc8L .prevBtn___1aLl- .nextBtnIcon___35tel {
  height: 35px;
  width: 30px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}
.bookingCalendar___2bc8L .prevBtn___1aLl- span {
  font-size: 0.7rem;
}
.bookingCalendar___2bc8L .prevBtn___1aLl-:hover .nextBtnIcon___35tel,
.bookingCalendar___2bc8L .prevBtn___1aLl-:active .nextBtnIcon___35tel {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(0deg);
}
@media screen and (max-width: 1023px) {
  .bookingCalendar___2bc8L .prevBtn___1aLl- {
    position: -webkit-sticky;
    position: sticky;
    left: 5px;
    background-color: transparent;
    box-shadow: none;
    margin-right: 16px;
  }
  .bookingCalendar___2bc8L .prevBtn___1aLl- span {
    display: none;
  }
  .bookingCalendar___2bc8L .prevBtn___1aLl- .nextBtnIcon___35tel {
    height: 25px;
    width: 25px;
  }
}
.bookingCalendar___2bc8L .nextBtn___24C41 {
  background-color: #ffffff;
  box-shadow: 0 0 5px #b3bcbc60;
  border: none;
  border-radius: 7px;
  position: absolute;
  top: 27px;
  right: -15px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bookingCalendar___2bc8L .nextBtn___24C41 .nextBtnIcon___35tel {
  height: 35px;
  width: 30px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAA8klEQVQ4jZWSsVHEMBBFHz4SQrdAuiFu4cILt4W7EqAEKEFuYUs4SsDpZpC6BUISaUbYq0Nsppn35n9Je1DVRxHB3b/pmAFIwKeqPvUKACNw7ZEGwP4jHdx9EZEVOAEPgIrIl7t7KAA0pNXdl61wVx9ynWuuB3Axs3mXUMbdVxF5BzQnnbZJA5sxswWYgAIlVU1hpU29Mdcrrzab2aUpVFLKFQGmm0IlfuSk4+4OAfxc1WrfIcMJOOfjYmbTfQMcgdcaBo5hQut1wkp/wb+EYC12MOSfDuCXCAYYVFXZL9xbBJeEMze2M6zUCxdhBqYeGOAHUbNr0xXssJ8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.bookingCalendar___2bc8L .nextBtn___24C41 span {
  font-size: 0.7rem;
}
.bookingCalendar___2bc8L .nextBtn___24C41:hover .nextBtnIcon___35tel,
.bookingCalendar___2bc8L .nextBtn___24C41:active .nextBtnIcon___35tel {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAN1JREFUOI2VlMsRgyAURY92YBUWYAuhhGTNgtESTCs6LtynBNOCBbBIDbaQRR4ZggSRJXMu73cfkHnqaW4Aikx4AFpAlSdgAJKCAF6BNZpSPc0VMABXD1bW6G0nEHgBmhCGoOgIPFqjO58pzsAgRUuPXx58j8EAhcALUMldZ40eY7CL0Au8AbcU7AT+y48U/K1BTu/8ciRwKTTAciQqJQ3XkUpEl38Cfw5Z3QonfSiKeSkU/Ux8Z29r9AooPqYDaMXm8QhepNBbT2u0Sq5oRJReUdkBP728T0Ci9cD4BhK7XoZJdCuSAAAAAElFTkSuQmCC);
  transform: rotate(180deg);
}
@media screen and (max-width: 1023px) {
  .bookingCalendar___2bc8L .nextBtn___24C41 {
    position: -webkit-sticky;
    position: sticky;
    right: 5px;
    background-color: transparent;
    box-shadow: none;
    margin-left: 16px;
  }
  .bookingCalendar___2bc8L .nextBtn___24C41 span {
    display: none;
  }
  .bookingCalendar___2bc8L .nextBtn___24C41 .nextBtnIcon___35tel {
    height: 25px;
    width: 25px;
  }
}
.bookingCalendar___2bc8L .btnScheduleAdjust___qRmBE {
  padding: 0.4rem 0.3rem;
  flex-direction: column;
}
.bookingCalendar___2bc8L .btnScheduleAdjust___qRmBE .nextBtnIcon___35tel {
  height: 15px !important;
  width: 20px !important;
  margin-bottom: 3px;
}
.bookingCalendar___2bc8L .calendarHeader___1whW8 {
  margin: 5px 0 5px 0;
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L .calendarHeader___1whW8 {
    margin: 5px 0 5px 0;
  }
}
.bookingCalendar___2bc8L .calendarHeader___1whW8 p {
  font-size: 13px;
  color: #c6c8c6;
  margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L .calendarHeader___1whW8 p {
    margin-bottom: 0;
  }
}
.bookingCalendar___2bc8L .calendarHeader___1whW8 .date___1QlY2 {
  font-size: 19px;
  color: #414141;
}
.bookingCalendar___2bc8L .eventContent___1FnxM {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #00b6d3;
  border-radius: 5px;
  overflow: visible;
  position: relative;
}
.bookingCalendar___2bc8L .eventContent___1FnxM .timeText___1zOWZ {
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookingCalendar___2bc8L .eventContent___1FnxM .timeText___1zOWZ p {
  color: #252525;
  font-size: 15px;
  font-weight: bolder;
  margin: 0;
  position: relative;
}
@media screen and (max-width: 1120px) {
  .bookingCalendar___2bc8L .eventContent___1FnxM .timeText___1zOWZ p {
    font-size: 12px;
  }
  .bookingCalendar___2bc8L .eventContent___1FnxM .timeText___1zOWZ p p {
    margin: 10px 0;
  }
  .bookingCalendar___2bc8L .eventContent___1FnxM .timeText___1zOWZ p span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    left: 50%;
  }
}
.bookingCalendar___2bc8L .eventContent___1FnxM:hover {
  background-color: #00b6d3;
}
.bookingCalendar___2bc8L .eventContent___1FnxM:hover .timeText___1zOWZ p {
  color: #ffffff;
}
.bookingCalendar___2bc8L .eventContent___1FnxM:visited {
  background-color: #00b6d3;
  border: 1px solid #9af0ed;
}
.bookingCalendar___2bc8L .isBooked___3PRc7 {
  pointer-events: none;
  background-color: #e8e6e6;
  display: flex;
  overflow: hidden;
  border: 1px solid #e8e6e6;
}
.bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ {
  margin: 0;
  width: 100%;
  justify-content: left;
  flex-wrap: wrap;
  overflow: hidden;
}
.bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .time___jcbuh {
  width: 100%;
  text-align: left;
  color: #5b5b5b;
  padding: 0 5px;
  font-weight: normal;
  text-shadow: 1px 0 0 #5b5b5b, 1px 0 0 #5b5b5b;
  position: relative;
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .time___jcbuh {
    text-shadow: none;
    text-align: center;
    padding: 0;
    font-size: 11px;
  }
  .bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .time___jcbuh p {
    margin: 10px 0;
  }
  .bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .time___jcbuh span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    left: 50%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .time___jcbuh {
    font-size: 10px;
  }
}
.bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .eventName___iT9kb {
  width: 100%;
  text-align: left;
  color: #5b5b5b;
  font-weight: bold;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2bc8L .isBooked___3PRc7 .timeText___1zOWZ .eventName___iT9kb {
    padding: 0;
    font-weight: normal;
    font-size: 11px;
    text-align: center;
  }
}
.bookingCalendar___2bc8L .isBooked___3PRc7:hover {
  background-color: #e8e6e6;
  border: 1px solid #555555;
}
.bookingCalendar___2bc8L .isBooked___3PRc7:hover .resizeIconBottom___1GVuJ,
.bookingCalendar___2bc8L .isBooked___3PRc7:hover .resizeIconTop___136Yw {
  opacity: 0;
}
.bookingCalendar___2bc8L .currentEvent___2Mr6r {
  background-color: #279ca0;
}
.bookingCalendar___2bc8L .currentEvent___2Mr6r .timeText___1zOWZ {
  color: #ffffff;
}
.btnAction___2tMZQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .btnAction___2tMZQ {
    display: none;
  }
}
.bookingCalendar___2bc8L .showOnMobile___2x4qf {
  display: none;
}
@media screen and (max-width: 576px) {
  .bookingCalendar___2bc8L .showOnMobile___2x4qf {
    display: block;
  }
  .bookingCalendar___2bc8L .showOnMobile___2x4qf .rotateOnMobile___1vSne {
    color: #252525;
    display: inline-block;
    transform: rotate(90deg);
  }
}
@media screen and (max-width: 405px) {
  .bookingCalendar___2bc8L .showOnMobile___2x4qf p {
    font-size: 10px !important;
  }
}
.bookingCalendar___2bc8L .notShowOnMobile___6-G1g {
  display: block;
}
@media screen and (max-width: 576px) {
  .bookingCalendar___2bc8L .notShowOnMobile___6-G1g {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .bookingCalendar___2bc8L .fc .fc-view-harness {
    min-height: 400px;
  }
  .bookingCalendar___2bc8L .fc .fc-timegrid-slot .fc-timegrid-slot-label-frame {
    display: flex;
    justify-content: center;
  }
  .bookingCalendar___2bc8L .fc .fc-timegrid-slot .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
    width: 50px;
    text-align: center;
    white-space: break-spaces;
  }
}
.btnActionMobile___Ux-9U {
  display: none;
}
@media screen and (max-width: 768px) {
  .btnActionMobile___Ux-9U {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: -50px;
    position: absolute;
    width: 100%;
  }
  .btnActionMobile___Ux-9U div {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 375px) {
  .btnActionMobile___Ux-9U {
    top: -32px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendarParent___Wfiku {
    position: relative;
    overflow: scroll;
    height: 570px;
  }
  .bookingCalendarParent___Wfiku > div {
    position: absolute;
  }
}
