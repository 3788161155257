.addImage___15e0X {
  background: #ececec;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid transparent;
  border-radius: 12px;
  text-align: center;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 550px;
}
.addImage___15e0X .plus___1Pw0R {
  font-size: 30px;
  color: #3c3c3c;
}
.addImage___15e0X p {
  color: #3c3c3c;
  font-size: 14px;
  margin-bottom: 0.1rem;
}
@media screen and (max-width: 425px) {
  .addImage___15e0X {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 300px;
  }
}
