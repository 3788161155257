@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___UbPLm {
  display: flex;
  justify-content: center;
}
button.btn___2MN3m {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2MN3m {
    padding: 10px;
  }
}
button.btn___2MN3m:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2MN3m:focus {
  outline: none;
}
.btnWhite___2utbm.btn___2MN3m {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2utbm.btn___2MN3m:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___TJD33.btn___2MN3m,
.btnGreen___TJD33.btn___2MN3m:hover,
.btnGreen___TJD33.btn___2MN3m:active,
.btnGreen___TJD33.btn___2MN3m:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___ip24x {
  color: #fe0000;
}
.errorMessage___226U5 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2eb5k {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3JYgJ {
  display: none !important;
}
.m-auto___18bc0 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___tyM-N {
  max-width: 120px !important;
}
.fc___2EbXG .fc-timegrid-col___spfK4.fc-day-today___2ZsTa {
  background-color: #ffffff;
}
.fc___2EbXG .fc-timegrid-slot___38XW6 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2EbXG .fc-timegrid-slot___38XW6 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1pToU .fc-direction-ltr___34ySK .fc-timegrid-slot-label-frame___3BjYr {
    font-size: 10px;
  }
  .bookingCalendar___1pToU .fc___2EbXG .fc-timegrid-slot-label-cushion___1xSr9 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1pToU .fc___2EbXG .fc-scrollgrid-section-header___o87Tc .fc-scroller___2HiTd {
    overflow: unset !important;
  }
}
.ant-message-notice___ixE-0 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3qHnJ {
  width: 20px;
  height: 20px;
}
.bgTransparent___1TrAs {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3Lm03 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1MFr- {
  position: relative;
}
.inputCustomValue___1MFr- .ant-input___1C2Rl {
  padding-right: 50px;
}
.inputCustomValueText___2yqku {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1m_3a .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1m_3a .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2xc7O .ant-radio-inner___3Uu5s {
  border-color: black !important ;
}
.ant-radio-checked___2xc7O .ant-radio-inner___3Uu5s:after {
  background-color: black;
}
.ant-radio___16c-9:hover .ant-radio-inner___3Uu5s {
  border-color: black;
}
.ant-radio-checked___2xc7O .ant-radio-inner___3Uu5s:focus {
  border-color: black;
}
.modal____NKjh {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal____NKjh .close___-iNLB {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal____NKjh .header___11ia8 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal____NKjh .header___11ia8 p {
  margin: 0;
}
.modal____NKjh .content___3GNYe {
  text-align: center;
  font-size: 14px;
}
.modal____NKjh .content___3GNYe p {
  margin: 0;
}
.modal____NKjh .actions___UwzAD {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal____NKjh .actions___UwzAD .button___3YarW {
  margin-top: 10px;
}
.modal____NKjh .actions___UwzAD:hover {
  color: #33c3c7;
}
.fc___2EbXG .fc-timegrid-slot-minor___2UD6e {
  border-top-style: none;
}
.fc___2EbXG .fc-timegrid-slot___38XW6 {
  height: 1.5em !important;
}
.messageError___3qnQi .ant-message-custom-content___1xsCa {
  display: flex;
  text-align: start;
}
.messageError___3qnQi .ant-message-custom-content___1xsCa span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___Ho9Yr {
  background-color: #e6e4e4;
}
.notifyMessage___1VTrI .ant-message-notice-content___o6sWy {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1VTrI .ant-message-notice-content___o6sWy .anticon___20k_- {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1VTrI .ant-message-notice-content___o6sWy span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___133wL {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___133wL .ant-notification-notice-message___394ox,
.notifyCustomize___133wL .ant-notification-notice-close___2oUo5,
.notifyCustomize___133wL .ant-notification-notice-icon___NG-dg {
  color: white;
}
.notifyCustomize___133wL .ant-notification-notice-message___394ox {
  margin-left: 35px;
}
.notifyCustomize___133wL p {
  margin-bottom: 0;
}
.notifyCustomize___133wL p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3khja {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3khja .ant-notification-notice-message___394ox {
  margin-left: 35px;
}
.notifyAlertDuplicate___3khja p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3khja p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___mVb4t {
  width: 434px !important;
}
.handleSubmitVote___mVb4t .ant-btn-primary___2SjAr {
  background: #59c3c7;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz {
  padding: 15px 30px 18px;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz .ant-modal-confirm-body___ftmVk {
  display: flex !important;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz .ant-modal-confirm-body___ftmVk .anticon___20k_- {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz .ant-modal-confirm-btns___1A16p button {
  font-weight: bold;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz .ant-modal-confirm-btns___1A16p button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz .ant-modal-confirm-btns___1A16p button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___mVb4t .ant-modal-body___3LwSz .ant-modal-confirm-btns___1A16p .ant-btn-primary___2SjAr {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___Rszqg {
  margin-bottom: 0;
}
.mt-0___21ZIS {
  margin-top: 0;
}
.tooltipFormat___1VgPp p {
  margin-bottom: 0;
}
.bgWhite___2VyYC {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___151gh {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___151gh:hover,
.bgPrimaryBlue___151gh:focus,
.bgPrimaryBlue___151gh:active {
  background: #4f7ac7;
}
.bgDarkBlue___2EJDK {
  background-color: #023768 !important;
}
.bgDarkBlue___2EJDK:hover,
.bgDarkBlue___2EJDK:focus,
.bgDarkBlue___2EJDK:active {
  background: #194d7d;
}
.bgGreyBlue___3RrtQ {
  background-color: #ebf0f9;
}
.bgLightBlue___1EPWa {
  background-color: #b2cbf7 !important;
}
.bgLightRed___hbWRL {
  background-color: #e4453a;
}
.bgLightRed___hbWRL:hover,
.bgLightRed___hbWRL:focus,
.bgLightRed___hbWRL:active {
  background: #e17871;
}
.bgLightYellow___1Jsfv {
  background-color: #ffd603;
}
.bgDarkGray___1yJMb {
  background-color: #333333;
}
.bgMediumGray___1JGw9 {
  background-color: #a2a2a2;
}
.bgLightGray___2gx2E {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2pkem {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___MUf6z {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___GvC9I {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___gbJoO {
  color: #3368c7 !important;
}
.textDarkBlue___2OQHQ {
  color: #023768 !important;
}
.textLightBlue___xysph {
  color: #b2cbf7;
}
.textDarkGray___bEPdm {
  color: #333333 !important;
}
.textMediumGray___2BDmi {
  color: #a2a2a2 !important;
}
.textLightGray___2I9nT {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___pjRrE {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2SDz_ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3NALa {
  border-radius: 5px !important;
}
.rounded-xs___2MVm3 {
  border-radius: 10px !important;
}
.rounded-sm___2gMqR {
  border-radius: 15px !important;
}
.rounded-md___1zliY {
  border-radius: 20px !important;
}
.rounded-lg___3o3J3 {
  border-radius: 25px !important;
}
.rounded-full___2Fuin {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3wLBy {
  margin: 0;
}
.mt-2___1sYNJ {
  margin-top: 0.5rem;
}
.my-2___31gGt {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2gnMn {
  padding: 0;
}
.px-1___1MTk7 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2xHcg {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3YSp9 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3SPIX {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3c7Cg {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___37D9I {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3XR6g {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3LmAR {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3mk0W {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___KMURf {
  padding-bottom: 20px;
}
.h-full___1VbZF {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2O7h9 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1LHiR {
  font-weight: bold !important;
}
.fontWeight400___1G0eY {
  font-weight: 400 !important;
}
.fontWeight500___23_tp {
  font-weight: 500 !important;
}
.fontWeight600___2lgq3 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___16TUC {
  font-size: 12px !important;
}
.fontSize14___1zgZt {
  font-size: 14px !important;
}
.fontSize16___3N2qU {
  font-size: 16px !important;
}
.fontSize18___1_au5 {
  font-size: 18px !important;
}
.fontSize20___3QiNy {
  font-size: 20px !important;
}
.fontSize24___2VB5V {
  font-size: 24px !important;
}
.eventName___2ehDo {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1aYdz {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___28lfp > tr > th,
.ant-table-tbody___3ktbq > tr.ant-table-row___1hv0i > td,
.ant-table-summary___2WPZc tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___28lfp > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___34YLl .ant-table-thead___28lfp > tr > th,
.table-portrait___34YLl .ant-table-tbody___3ktbq > tr.ant-table-row___1hv0i > td,
.table-portrait___34YLl .ant-table-summary___2WPZc tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___34YLl .bgLightGray___2gx2E td {
  background: #ebebeb;
}
.table-portrait___34YLl .bgLightBlue___1EPWa td {
  background: #b2cbf7;
}
.eventContent___2hvh3 .timeText___16dWZ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3ktbq > tr.bgLightGray___2gx2E:hover > td,
.ant-table-tbody___3ktbq > tr > td.ant-table-cell-row-hover___24QMl {
  background: #ebebeb;
}
.ant-table-tbody___3ktbq > tr.bgLightBlue___1EPWa:hover > td,
.ant-table-tbody___3ktbq > tr > td.ant-table-cell-row-hover___24QMl {
  background: #b2cbf7;
}
.ant-table-tbody___3ktbq > tr.bgWhite___2VyYC:hover > td,
.ant-table-tbody___3ktbq > tr > td.ant-table-cell-row-hover___24QMl {
  background: #ffffff;
}
.setting___3piMZ {
  margin-top: 60px;
  border-top: 1px solid #f3f3f3;
  padding: 60px 0 80px;
  background-color: #fcfcfc;
}
@media screen and (max-width: 767px) {
  .setting___3piMZ {
    padding: 30px 0 170px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .setting___3piMZ {
    padding: 60px 15px 80px;
  }
}
.setting___3piMZ .settingContent___2KO5g {
  max-width: 820px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .setting___3piMZ .settingContent___2KO5g {
    padding: 0 15px;
  }
}
.setting___3piMZ .settingDescript1___1sRs_ {
  font-size: 14px;
  color: #414141;
  line-height: 1.37;
}
@media screen and (max-width: 767px) {
  .setting___3piMZ .settingDescript1___1sRs_ {
    font-size: 16px;
  }
}
.setting___3piMZ .settingDescript2___kobzq {
  font-size: 14px;
  color: #888888;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .setting___3piMZ .settingDescript2___kobzq {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
.setting___3piMZ .settingBtnGroup___3jdsM {
  margin-bottom: 50px;
}
.setting___3piMZ .settingForm___39w92 {
  position: relative;
  display: flex;
  justify-content: center;
}
.setting___3piMZ .settingForm___39w92 .labelCheckbox___3KmnP {
  font-size: 16px;
}
.setting___3piMZ .settingIcon___ZLS3n {
  font-size: 23px;
  margin: 0 24px;
}
.labelCheckbox___3KmnP {
  margin-left: 50px;
  font-size: 25px;
  display: flex;
  align-items: center;
}
.checkboxSelect___2366D {
  display: flex;
  margin-left: 60px;
  align-items: center;
  min-width: 50%;
}
@media screen and (max-width: 767px) {
  .checkboxSelect___2366D {
    margin-left: 0;
  }
}
.selectTime___2X7Mg {
  width: 100%;
  max-width: 170px;
  height: 50px;
  border: 2px solid #d4d4d4;
  border-radius: 15px;
  padding: 0 28px;
  font-size: 20px;
}
.checkboxCustom___Fuooo {
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .checkboxCustom___Fuooo {
    margin-bottom: 10px;
  }
}
.checkboxCustom___Fuooo .checkboxContent___2qn8E {
  display: flex;
  width: 100%;
}
.checkboxItem___1JydM {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 615px;
}
@media screen and (max-width: 767px) {
  .checkboxItem___1JydM {
    justify-content: flex-start;
  }
}
.checkboxItem___1JydM .errorTime___2Z3mC {
  color: #fe0000;
  display: block;
  width: 100%;
  margin-left: 20px;
}
.setting___3piMZ .ant-checkbox-input,
.setting___3piMZ .ant-checkbox-inner {
  display: none;
}
.setting___3piMZ .ant-checkbox-checked::after {
  display: none;
}
.setting___3piMZ .ant-checkbox::before {
  content: '';
  width: 34px;
  height: 35px;
  position: absolute;
  left: 0;
  top: -15px;
  border-radius: 9px;
  box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.26);
}
.setting___3piMZ .ant-checkbox.ant-checkbox-checked::before {
  background: url(./static/icon-checked.3d17b49b.svg) center no-repeat;
  background-size: 70% 70%;
}
.setting___3piMZ .ant-checkbox-group {
  width: 100%;
}
.setting___3piMZ .ant-select {
  max-width: 170px;
  height: 50px;
  border: 2px solid #d4d4d4;
  border-radius: 15px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.setting___3piMZ .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  height: auto;
  padding: 0px 28px;
  background: transparent;
  box-shadow: none;
}
.setting___3piMZ .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}
.setting___3piMZ .ant-picker {
  max-width: 170px;
  height: 50px;
  border: 2px solid #d4d4d4;
  border-radius: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #333;
  padding: 0 10px 0 28px;
}
.setting___3piMZ .ant-picker-input > input {
  font-size: 16px;
}
.setting___3piMZ .btn.btnWhite {
  font-size: 16px;
}
.setting___3piMZ .btn.btnWhite:hover {
  border: 2px solid #909090;
}
.setting___3piMZ .btn.btnGreen {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .timePicker___28_Ri .ant-picker-time-panel-column {
    float: left;
    height: 110%;
    overflow-y: auto;
  }
  .timePicker___28_Ri .ant-picker-time-panel-column::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .timePicker___28_Ri .ant-picker-time-panel-column::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
  }
  .timePicker___28_Ri .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }
}
.timePicker___28_Ri .ant-picker-panel .ant-picker-footer {
  display: none;
}
.timePicker___28_Ri .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.timePicker___28_Ri .ant-picker-time-panel-column {
  width: 80px;
}
.timePicker___28_Ri .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___28_Ri .ant-picker-time-panel-column:first-child .ant-picker-time-panel-cell::after {
  content: '時';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
.timePicker___28_Ri .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell {
  position: relative;
}
.timePicker___28_Ri .ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell::after {
  content: '分';
  position: absolute;
  top: 4px;
  left: 40px;
  width: 29px;
  height: 100%;
}
