.settingTemplate___2GLE1 {
  background: #f0f5f8;
  position: relative;
}
.settingTemplate___2GLE1 .navigate___39KDd {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0.25rem 1.5rem;
}
.settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 {
  margin: auto 0;
}
.settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 h3 {
  margin-bottom: 0;
  padding-left: 1rem;
  color: #3c3c3c;
}
.settingTemplate___2GLE1 .navigate___39KDd .menu___2Oj8z {
  display: flex;
  justify-content: space-between;
  min-width: 70%;
}
.settingTemplate___2GLE1 .navigate___39KDd .menu___2Oj8z h3 {
  margin-bottom: 0;
}
.settingTemplate___2GLE1 .contentTemplate___RnmNf {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1580px) {
  .settingTemplate___2GLE1 .navigate___39KDd {
    padding: 0.25rem 0.75rem;
  }
  .settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 h3 {
    font-size: 1rem;
  }
  .settingTemplate___2GLE1 .navigate___39KDd .menu___2Oj8z {
    min-width: 65%;
  }
}
@media screen and (max-width: 1440px) {
  .settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 h3 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1320px) {
  .settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 h3 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1250px) {
  .settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 h3 {
    padding-left: unset;
  }
}
@media screen and (max-width: 425px) {
  .settingTemplate___2GLE1 .navigate___39KDd .title___1TdD6 {
    display: none;
  }
  .settingTemplate___2GLE1 .navigate___39KDd .menu___2Oj8z {
    margin: auto;
  }
  .settingTemplate___2GLE1 .contentTemplate___RnmNf {
    padding: 0.75rem;
  }
}
