@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3l16S {
  display: flex;
  justify-content: center;
}
button.btn___1dMKZ {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1dMKZ {
    padding: 10px;
  }
}
button.btn___1dMKZ:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1dMKZ:focus {
  outline: none;
}
.btnWhite___2wKjS.btn___1dMKZ {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2wKjS.btn___1dMKZ:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3TR0y.btn___1dMKZ,
.btnGreen___3TR0y.btn___1dMKZ:hover,
.btnGreen___3TR0y.btn___1dMKZ:active,
.btnGreen___3TR0y.btn___1dMKZ:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___M1GHg {
  color: #fe0000;
}
.errorMessage___3BwYq {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2Xboc {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2aWRs {
  display: none !important;
}
.m-auto___1BMv0 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___CVzWb {
  max-width: 120px !important;
}
.fc___1ro6n .fc-timegrid-col___1acfS.fc-day-today___gDpPx {
  background-color: #ffffff;
}
.fc___1ro6n .fc-timegrid-slot___1vAN1 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1ro6n .fc-timegrid-slot___1vAN1 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2S6x- .fc-direction-ltr___2Jgm4 .fc-timegrid-slot-label-frame___3QbLD {
    font-size: 10px;
  }
  .bookingCalendar___2S6x- .fc___1ro6n .fc-timegrid-slot-label-cushion___1L-0i {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2S6x- .fc___1ro6n .fc-scrollgrid-section-header___2XPPW .fc-scroller___pPP02 {
    overflow: unset !important;
  }
}
.ant-message-notice___-GqxX {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1T0U1 {
  width: 20px;
  height: 20px;
}
.bgTransparent___16NC6 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___2qDUN {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1W14y {
  position: relative;
}
.inputCustomValue___1W14y .ant-input___3eXYW {
  padding-right: 50px;
}
.inputCustomValueText___2T5TN {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___Ag7YQ .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___Ag7YQ .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3GhrX .ant-radio-inner___1ZaJv {
  border-color: black !important ;
}
.ant-radio-checked___3GhrX .ant-radio-inner___1ZaJv:after {
  background-color: black;
}
.ant-radio___1dqJ4:hover .ant-radio-inner___1ZaJv {
  border-color: black;
}
.ant-radio-checked___3GhrX .ant-radio-inner___1ZaJv:focus {
  border-color: black;
}
.modal___37eUv {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___37eUv .close___1u13A {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___37eUv .header___2J4Jd {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___37eUv .header___2J4Jd p {
  margin: 0;
}
.modal___37eUv .content___1mP_V {
  text-align: center;
  font-size: 14px;
}
.modal___37eUv .content___1mP_V p {
  margin: 0;
}
.modal___37eUv .actions___3zdsY {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___37eUv .actions___3zdsY .button___1nL4x {
  margin-top: 10px;
}
.modal___37eUv .actions___3zdsY:hover {
  color: #33c3c7;
}
.fc___1ro6n .fc-timegrid-slot-minor___33ncf {
  border-top-style: none;
}
.fc___1ro6n .fc-timegrid-slot___1vAN1 {
  height: 1.5em !important;
}
.messageError___2vNzg .ant-message-custom-content___3NuQ0 {
  display: flex;
  text-align: start;
}
.messageError___2vNzg .ant-message-custom-content___3NuQ0 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___mwORa {
  background-color: #e6e4e4;
}
.notifyMessage___1BQcf .ant-message-notice-content___39xcP {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1BQcf .ant-message-notice-content___39xcP .anticon___YZZGI {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1BQcf .ant-message-notice-content___39xcP span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2TAGx {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2TAGx .ant-notification-notice-message___34tG1,
.notifyCustomize___2TAGx .ant-notification-notice-close___a2SOO,
.notifyCustomize___2TAGx .ant-notification-notice-icon___1mfoz {
  color: white;
}
.notifyCustomize___2TAGx .ant-notification-notice-message___34tG1 {
  margin-left: 35px;
}
.notifyCustomize___2TAGx p {
  margin-bottom: 0;
}
.notifyCustomize___2TAGx p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2gNgI {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2gNgI .ant-notification-notice-message___34tG1 {
  margin-left: 35px;
}
.notifyAlertDuplicate___2gNgI p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2gNgI p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1NZMQ {
  width: 434px !important;
}
.handleSubmitVote___1NZMQ .ant-btn-primary___1A3cs {
  background: #59c3c7;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB .ant-modal-confirm-body___28A6Z {
  display: flex !important;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB .ant-modal-confirm-body___28A6Z .anticon___YZZGI {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB .ant-modal-confirm-btns___3O5XG button {
  font-weight: bold;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB .ant-modal-confirm-btns___3O5XG button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB .ant-modal-confirm-btns___3O5XG button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1NZMQ .ant-modal-body___3oYjB .ant-modal-confirm-btns___3O5XG .ant-btn-primary___1A3cs {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3cfeu {
  margin-bottom: 0;
}
.mt-0___80L9X {
  margin-top: 0;
}
.tooltipFormat___3_DCI p {
  margin-bottom: 0;
}
.bgWhite___1bmxf {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3J4Rv {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3J4Rv:hover,
.bgPrimaryBlue___3J4Rv:focus,
.bgPrimaryBlue___3J4Rv:active {
  background: #4f7ac7;
}
.bgDarkBlue___1KJcP {
  background-color: #023768 !important;
}
.bgDarkBlue___1KJcP:hover,
.bgDarkBlue___1KJcP:focus,
.bgDarkBlue___1KJcP:active {
  background: #194d7d;
}
.bgGreyBlue___FoYh3 {
  background-color: #ebf0f9;
}
.bgLightBlue___3BHcI {
  background-color: #b2cbf7 !important;
}
.bgLightRed___5bDlS {
  background-color: #e4453a;
}
.bgLightRed___5bDlS:hover,
.bgLightRed___5bDlS:focus,
.bgLightRed___5bDlS:active {
  background: #e17871;
}
.bgLightYellow___1fNSA {
  background-color: #ffd603;
}
.bgDarkGray___1X6R4 {
  background-color: #333333;
}
.bgMediumGray___1f985 {
  background-color: #a2a2a2;
}
.bgLightGray___2Q307 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2Dinf {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1Zw2g {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2QqYf {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3_QrR {
  color: #3368c7 !important;
}
.textDarkBlue___qhuNA {
  color: #023768 !important;
}
.textLightBlue___3ZXub {
  color: #b2cbf7;
}
.textDarkGray___3uBeU {
  color: #333333 !important;
}
.textMediumGray___1-t6G {
  color: #a2a2a2 !important;
}
.textLightGray___3UYND {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1oNe5 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2vMfs {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2yAhJ {
  border-radius: 5px !important;
}
.rounded-xs___3Vbd4 {
  border-radius: 10px !important;
}
.rounded-sm___2hrU2 {
  border-radius: 15px !important;
}
.rounded-md___1CwmE {
  border-radius: 20px !important;
}
.rounded-lg___KUCof {
  border-radius: 25px !important;
}
.rounded-full___2erzP {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___17hjD {
  margin: 0;
}
.mt-2___3Tv-l {
  margin-top: 0.5rem;
}
.my-2___ufenY {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___36qDb {
  padding: 0;
}
.px-1___eT8P6 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3_8EK {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1-bOG {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3yKAZ {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___GW-E3 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___10lcs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2WRX1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1VCXV {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1LkRA {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2VRzB {
  padding-bottom: 20px;
}
.h-full___2qp7H {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1u_C5 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1108W {
  font-weight: bold !important;
}
.fontWeight400___zrrNy {
  font-weight: 400 !important;
}
.fontWeight500___EN2b4 {
  font-weight: 500 !important;
}
.fontWeight600___265yQ {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2C3TX {
  font-size: 12px !important;
}
.fontSize14___1Tb14 {
  font-size: 14px !important;
}
.fontSize16___U0Wb8 {
  font-size: 16px !important;
}
.fontSize18___2eWG1 {
  font-size: 18px !important;
}
.fontSize20___2qM-B {
  font-size: 20px !important;
}
.fontSize24___35k3H {
  font-size: 24px !important;
}
.eventName___nrON_ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3OiF1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___17tMO > tr > th,
.ant-table-tbody___2kjuy > tr.ant-table-row___Nw0s6 > td,
.ant-table-summary___2ERjb tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___17tMO > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___dCyr3 .ant-table-thead___17tMO > tr > th,
.table-portrait___dCyr3 .ant-table-tbody___2kjuy > tr.ant-table-row___Nw0s6 > td,
.table-portrait___dCyr3 .ant-table-summary___2ERjb tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___dCyr3 .bgLightGray___2Q307 td {
  background: #ebebeb;
}
.table-portrait___dCyr3 .bgLightBlue___3BHcI td {
  background: #b2cbf7;
}
.eventContent___2nkWC .timeText___1fJ2E {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2kjuy > tr.bgLightGray___2Q307:hover > td,
.ant-table-tbody___2kjuy > tr > td.ant-table-cell-row-hover___XTs6h {
  background: #ebebeb;
}
.ant-table-tbody___2kjuy > tr.bgLightBlue___3BHcI:hover > td,
.ant-table-tbody___2kjuy > tr > td.ant-table-cell-row-hover___XTs6h {
  background: #b2cbf7;
}
.ant-table-tbody___2kjuy > tr.bgWhite___1bmxf:hover > td,
.ant-table-tbody___2kjuy > tr > td.ant-table-cell-row-hover___XTs6h {
  background: #ffffff;
}
.tooltip___3TzW7 p {
  margin-bottom: 0;
}
.eventManage___1yeOy {
  font-weight: 500;
  width: calc(100vw - 300px);
  margin-left: auto;
}
.eventManage___1yeOy .tabName___3bLUa {
  position: relative;
}
.eventManage___1yeOy .tabName___3bLUa img {
  position: absolute;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 20px;
}
@media screen and (max-width: 767px) {
  .eventManage___1yeOy .tabName___3bLUa img {
    right: -21px;
  }
}
.eventManage___1yeOy .ant-tabs-nav {
  margin-top: 30px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .eventManage___1yeOy .ant-tabs-nav {
    margin: 30px 0 0;
  }
}
.eventManage___1yeOy .ant-tabs-nav-list {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .eventManage___1yeOy .ant-tabs-nav-list {
    padding: 0 11px;
    align-items: center;
    justify-content: center;
  }
}
.eventManage___1yeOy .ant-tabs-tab {
  margin: 0 80px 0 0;
  padding: 0 0 12px 0;
}
@media screen and (max-width: 768px) {
  .eventManage___1yeOy .ant-tabs-tab {
    margin: 0 40px 0 0;
  }
  .eventManage___1yeOy .ant-tabs-tab:nth-child(3) {
    margin: 0;
  }
}
.eventManage___1yeOy .ant-tabs-tab-btn {
  font-size: 25px;
}
@media screen and (max-width: 767px) {
  .eventManage___1yeOy .ant-tabs-tab-btn {
    font-size: 16px;
  }
}
.eventManage___1yeOy .ant-tabs-content-holder {
  background-color: #fcfcfc;
  min-height: calc(100vh - 215px);
}
.eventManage___1yeOy .ant-tabs-content {
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 0 15px;
}
@media screen and (max-width: 767px) {
  .eventManage___1yeOy .ant-tabs-content {
    padding: 0;
  }
}
@media only screen and (max-width: 1140px) {
  .eventManage___1yeOy {
    width: unset;
    margin-left: unset;
  }
}
