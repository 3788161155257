@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2EMgg {
  display: flex;
  justify-content: center;
}
button.btn___1TC5n {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1TC5n {
    padding: 10px;
  }
}
button.btn___1TC5n:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1TC5n:focus {
  outline: none;
}
.btnWhite___wLFpp.btn___1TC5n {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___wLFpp.btn___1TC5n:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3YICp.btn___1TC5n,
.btnGreen___3YICp.btn___1TC5n:hover,
.btnGreen___3YICp.btn___1TC5n:active,
.btnGreen___3YICp.btn___1TC5n:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3tmaO {
  color: #fe0000;
}
.errorMessage___3wspH {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___rwt_F {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1vWDj {
  display: none !important;
}
.m-auto___98OvH {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___16HMu {
  max-width: 120px !important;
}
.fc___3i5tr .fc-timegrid-col___18wQn.fc-day-today___3YDD6 {
  background-color: #ffffff;
}
.fc___3i5tr .fc-timegrid-slot___ESAQe {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3i5tr .fc-timegrid-slot___ESAQe {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___GIGln .fc-direction-ltr___2etPI .fc-timegrid-slot-label-frame___32wZG {
    font-size: 10px;
  }
  .bookingCalendar___GIGln .fc___3i5tr .fc-timegrid-slot-label-cushion___3of40 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___GIGln .fc___3i5tr .fc-scrollgrid-section-header___18Zh- .fc-scroller___1vuvW {
    overflow: unset !important;
  }
}
.ant-message-notice___2OC33 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___TDgnC {
  width: 20px;
  height: 20px;
}
.bgTransparent___qltlf {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1ZisY {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1bKCD {
  position: relative;
}
.inputCustomValue___1bKCD .ant-input___2KmGv {
  padding-right: 50px;
}
.inputCustomValueText___le6Qp {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3Tk0W .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3Tk0W .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2hHG0 .ant-radio-inner___1hagm {
  border-color: black !important ;
}
.ant-radio-checked___2hHG0 .ant-radio-inner___1hagm:after {
  background-color: black;
}
.ant-radio___RxvkX:hover .ant-radio-inner___1hagm {
  border-color: black;
}
.ant-radio-checked___2hHG0 .ant-radio-inner___1hagm:focus {
  border-color: black;
}
.modal___2Ljgg {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2Ljgg .close___3Rz6y {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2Ljgg .header___3GPNC {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2Ljgg .header___3GPNC p {
  margin: 0;
}
.modal___2Ljgg .content___1uy2R {
  text-align: center;
  font-size: 14px;
}
.modal___2Ljgg .content___1uy2R p {
  margin: 0;
}
.modal___2Ljgg .actions___1rzTM {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2Ljgg .actions___1rzTM .button___zKDFA {
  margin-top: 10px;
}
.modal___2Ljgg .actions___1rzTM:hover {
  color: #33c3c7;
}
.fc___3i5tr .fc-timegrid-slot-minor___y5wT_ {
  border-top-style: none;
}
.fc___3i5tr .fc-timegrid-slot___ESAQe {
  height: 1.5em !important;
}
.messageError___2TOwl .ant-message-custom-content___KBCVd {
  display: flex;
  text-align: start;
}
.messageError___2TOwl .ant-message-custom-content___KBCVd span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3zPm4 {
  background-color: #e6e4e4;
}
.notifyMessage___6WHyW .ant-message-notice-content___bq5aG {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___6WHyW .ant-message-notice-content___bq5aG .anticon___18mfO {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___6WHyW .ant-message-notice-content___bq5aG span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3SFcv {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3SFcv .ant-notification-notice-message___xshpn,
.notifyCustomize___3SFcv .ant-notification-notice-close___3CYoY,
.notifyCustomize___3SFcv .ant-notification-notice-icon___1CI54 {
  color: white;
}
.notifyCustomize___3SFcv .ant-notification-notice-message___xshpn {
  margin-left: 35px;
}
.notifyCustomize___3SFcv p {
  margin-bottom: 0;
}
.notifyCustomize___3SFcv p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___28wtI {
  padding: 16px 14px;
}
.notifyAlertDuplicate___28wtI .ant-notification-notice-message___xshpn {
  margin-left: 35px;
}
.notifyAlertDuplicate___28wtI p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___28wtI p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___d_h2E {
  width: 434px !important;
}
.handleSubmitVote___d_h2E .ant-btn-primary___LMvuC {
  background: #59c3c7;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU {
  padding: 15px 30px 18px;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU .ant-modal-confirm-body___1FYnp {
  display: flex !important;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU .ant-modal-confirm-body___1FYnp .anticon___18mfO {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU .ant-modal-confirm-btns___2vum7 button {
  font-weight: bold;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU .ant-modal-confirm-btns___2vum7 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU .ant-modal-confirm-btns___2vum7 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___d_h2E .ant-modal-body___dXJXU .ant-modal-confirm-btns___2vum7 .ant-btn-primary___LMvuC {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3QC_y {
  margin-bottom: 0;
}
.mt-0___1FrXO {
  margin-top: 0;
}
.tooltipFormat___2Ot5S p {
  margin-bottom: 0;
}
.bgWhite___30KbX {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1vgtl {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1vgtl:hover,
.bgPrimaryBlue___1vgtl:focus,
.bgPrimaryBlue___1vgtl:active {
  background: #4f7ac7;
}
.bgDarkBlue___2QLqX {
  background-color: #023768 !important;
}
.bgDarkBlue___2QLqX:hover,
.bgDarkBlue___2QLqX:focus,
.bgDarkBlue___2QLqX:active {
  background: #194d7d;
}
.bgGreyBlue___TPydp {
  background-color: #ebf0f9;
}
.bgLightBlue___1dVZ3 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1F3h5 {
  background-color: #e4453a;
}
.bgLightRed___1F3h5:hover,
.bgLightRed___1F3h5:focus,
.bgLightRed___1F3h5:active {
  background: #e17871;
}
.bgLightYellow___ssUeZ {
  background-color: #ffd603;
}
.bgDarkGray___247l6 {
  background-color: #333333;
}
.bgMediumGray___1VbjR {
  background-color: #a2a2a2;
}
.bgLightGray___10p2k {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1-ghd {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3vJMS {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___19AgA {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2RSiP {
  color: #3368c7 !important;
}
.textDarkBlue___3n_Hf {
  color: #023768 !important;
}
.textLightBlue___3_w63 {
  color: #b2cbf7;
}
.textDarkGray___r8tPZ {
  color: #333333 !important;
}
.textMediumGray___js8Zv {
  color: #a2a2a2 !important;
}
.textLightGray___D3Fwu {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1Czqs {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___18DrE {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3-GYi {
  border-radius: 5px !important;
}
.rounded-xs___3w1gK {
  border-radius: 10px !important;
}
.rounded-sm___AW1bG {
  border-radius: 15px !important;
}
.rounded-md___1Xt8v {
  border-radius: 20px !important;
}
.rounded-lg___BMf6g {
  border-radius: 25px !important;
}
.rounded-full___11fu2 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___sp7ZI {
  margin: 0;
}
.mt-2___2pFl7 {
  margin-top: 0.5rem;
}
.my-2___fqdm5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___rjp3U {
  padding: 0;
}
.px-1___2z7Nr {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3xO55 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___E_qik {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___28kNE {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___34XH_ {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___31JsM {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___mxlZ_ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1bb9z {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2hB2e {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3bMEU {
  padding-bottom: 20px;
}
.h-full___39UDz {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___298qi {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___G3GRF {
  font-weight: bold !important;
}
.fontWeight400___1cYom {
  font-weight: 400 !important;
}
.fontWeight500___2fatY {
  font-weight: 500 !important;
}
.fontWeight600___2dxWt {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___70TDA {
  font-size: 12px !important;
}
.fontSize14___1_f9X {
  font-size: 14px !important;
}
.fontSize16___2UmyT {
  font-size: 16px !important;
}
.fontSize18___y4wWW {
  font-size: 18px !important;
}
.fontSize20___37KlF {
  font-size: 20px !important;
}
.fontSize24___157hX {
  font-size: 24px !important;
}
.eventName___1DYjM {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___33g1l {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2c05U > tr > th,
.ant-table-tbody___2dqP8 > tr.ant-table-row___1z6wQ > td,
.ant-table-summary___3U1Be tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2c05U > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3cX3L .ant-table-thead___2c05U > tr > th,
.table-portrait___3cX3L .ant-table-tbody___2dqP8 > tr.ant-table-row___1z6wQ > td,
.table-portrait___3cX3L .ant-table-summary___3U1Be tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3cX3L .bgLightGray___10p2k td {
  background: #ebebeb;
}
.table-portrait___3cX3L .bgLightBlue___1dVZ3 td {
  background: #b2cbf7;
}
.eventContent___to0xj .timeText___3OtJf {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2dqP8 > tr.bgLightGray___10p2k:hover > td,
.ant-table-tbody___2dqP8 > tr > td.ant-table-cell-row-hover___2SJLl {
  background: #ebebeb;
}
.ant-table-tbody___2dqP8 > tr.bgLightBlue___1dVZ3:hover > td,
.ant-table-tbody___2dqP8 > tr > td.ant-table-cell-row-hover___2SJLl {
  background: #b2cbf7;
}
.ant-table-tbody___2dqP8 > tr.bgWhite___30KbX:hover > td,
.ant-table-tbody___2dqP8 > tr > td.ant-table-cell-row-hover___2SJLl {
  background: #ffffff;
}
.dropdownMenu___148jo {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.dropdownMenu___148jo .dropdownContent____E-M0 {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease-in, opacity 0.1s ease-in;
  position: absolute;
  min-width: 116px;
  z-index: 300;
  right: -12px;
}
.dropdownHoverMenu___2WkeW:hover .dropdownContent____E-M0 {
  visibility: visible;
  opacity: 1;
}
.dropdownHoverMenu___2WkeW:hover .ant-dropdown-link span {
  font-weight: bold;
}
@media screen and (max-width: 425px) {
  .dropdownHoverMenu___2WkeW:hover .dropdownContent____E-M0 {
    visibility: hidden;
    opacity: 0;
  }
  .dropdownHoverMenu___2WkeW:hover .ant-dropdown-link span {
    font-weight: normal;
  }
}
.visibleDropdownMenu___VUv2O .dropdownContent____E-M0 {
  visibility: visible !important;
  opacity: 1 !important;
}
.visibleDropdownMenu___VUv2O .ant-dropdown-link span {
  font-weight: bold;
}
.dropDownCustomLeft___2ktvx {
  margin-bottom: 1.5rem;
}
.dropDownCustomLeft___2ktvx .dropdownContent____E-M0 {
  left: 100%;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
