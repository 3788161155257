@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___ot3VC {
  display: flex;
  justify-content: center;
}
button.btn___2ILhl {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2ILhl {
    padding: 10px;
  }
}
button.btn___2ILhl:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2ILhl:focus {
  outline: none;
}
.btnWhite___fwCSt.btn___2ILhl {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___fwCSt.btn___2ILhl:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3YNPu.btn___2ILhl,
.btnGreen___3YNPu.btn___2ILhl:hover,
.btnGreen___3YNPu.btn___2ILhl:active,
.btnGreen___3YNPu.btn___2ILhl:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___16z2p {
  color: #fe0000;
}
.errorMessage___32hsc {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___27DzD {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2LGCp {
  display: none !important;
}
.m-auto___2SbIM {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1HdJP {
  max-width: 120px !important;
}
.fc___2uABt .fc-timegrid-col___lcgZb.fc-day-today___1qUQN {
  background-color: #ffffff;
}
.fc___2uABt .fc-timegrid-slot___1C520 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2uABt .fc-timegrid-slot___1C520 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1gQ_I .fc-direction-ltr___1SrGI .fc-timegrid-slot-label-frame___2n3Qq {
    font-size: 10px;
  }
  .bookingCalendar___1gQ_I .fc___2uABt .fc-timegrid-slot-label-cushion___1BgXB {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1gQ_I .fc___2uABt .fc-scrollgrid-section-header___2V4cB .fc-scroller___1C-ME {
    overflow: unset !important;
  }
}
.ant-message-notice___2_FUz {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___vNUfm {
  width: 20px;
  height: 20px;
}
.bgTransparent___31lrT {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3SbAb {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___Ez_Gu {
  position: relative;
}
.inputCustomValue___Ez_Gu .ant-input___3IXJa {
  padding-right: 50px;
}
.inputCustomValueText___19Dam {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___WhK5H .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___WhK5H .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___4-oiq .ant-radio-inner___37G25 {
  border-color: black !important ;
}
.ant-radio-checked___4-oiq .ant-radio-inner___37G25:after {
  background-color: black;
}
.ant-radio___1nR8o:hover .ant-radio-inner___37G25 {
  border-color: black;
}
.ant-radio-checked___4-oiq .ant-radio-inner___37G25:focus {
  border-color: black;
}
.modal___1AXUc {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1AXUc .close___2bYrb {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1AXUc .header___2ACsy {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1AXUc .header___2ACsy p {
  margin: 0;
}
.modal___1AXUc .content___3sKb7 {
  text-align: center;
  font-size: 14px;
}
.modal___1AXUc .content___3sKb7 p {
  margin: 0;
}
.modal___1AXUc .actions___3E8iw {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1AXUc .actions___3E8iw .button___2UHDn {
  margin-top: 10px;
}
.modal___1AXUc .actions___3E8iw:hover {
  color: #33c3c7;
}
.fc___2uABt .fc-timegrid-slot-minor___1lPYY {
  border-top-style: none;
}
.fc___2uABt .fc-timegrid-slot___1C520 {
  height: 1.5em !important;
}
.messageError___2RDI0 .ant-message-custom-content___SHOr_ {
  display: flex;
  text-align: start;
}
.messageError___2RDI0 .ant-message-custom-content___SHOr_ span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2i-sg {
  background-color: #e6e4e4;
}
.notifyMessage___2atT8 .ant-message-notice-content___1ISzQ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2atT8 .ant-message-notice-content___1ISzQ .anticon___2EwkH {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2atT8 .ant-message-notice-content___1ISzQ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1ixEc {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1ixEc .ant-notification-notice-message___3bcJG,
.notifyCustomize___1ixEc .ant-notification-notice-close___2XTte,
.notifyCustomize___1ixEc .ant-notification-notice-icon___1QPUh {
  color: white;
}
.notifyCustomize___1ixEc .ant-notification-notice-message___3bcJG {
  margin-left: 35px;
}
.notifyCustomize___1ixEc p {
  margin-bottom: 0;
}
.notifyCustomize___1ixEc p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1dARy {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1dARy .ant-notification-notice-message___3bcJG {
  margin-left: 35px;
}
.notifyAlertDuplicate___1dARy p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1dARy p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2a1-8 {
  width: 434px !important;
}
.handleSubmitVote___2a1-8 .ant-btn-primary___1Dr1i {
  background: #59c3c7;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D .ant-modal-confirm-body___2z40i {
  display: flex !important;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D .ant-modal-confirm-body___2z40i .anticon___2EwkH {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D .ant-modal-confirm-btns___2iiSD button {
  font-weight: bold;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D .ant-modal-confirm-btns___2iiSD button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D .ant-modal-confirm-btns___2iiSD button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2a1-8 .ant-modal-body___1MN4D .ant-modal-confirm-btns___2iiSD .ant-btn-primary___1Dr1i {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3bU6_ {
  margin-bottom: 0;
}
.mt-0___3pxwE {
  margin-top: 0;
}
.tooltipFormat___3yxXE p {
  margin-bottom: 0;
}
.bgWhite___1HpGS {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3M6nb {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3M6nb:hover,
.bgPrimaryBlue___3M6nb:focus,
.bgPrimaryBlue___3M6nb:active {
  background: #4f7ac7;
}
.bgDarkBlue___2z8Ak {
  background-color: #023768 !important;
}
.bgDarkBlue___2z8Ak:hover,
.bgDarkBlue___2z8Ak:focus,
.bgDarkBlue___2z8Ak:active {
  background: #194d7d;
}
.bgGreyBlue___tUpEm {
  background-color: #ebf0f9;
}
.bgLightBlue___34eez {
  background-color: #b2cbf7 !important;
}
.bgLightRed___39xp4 {
  background-color: #e4453a;
}
.bgLightRed___39xp4:hover,
.bgLightRed___39xp4:focus,
.bgLightRed___39xp4:active {
  background: #e17871;
}
.bgLightYellow___3I2L6 {
  background-color: #ffd603;
}
.bgDarkGray___iHcd4 {
  background-color: #333333;
}
.bgMediumGray___1K_Bg {
  background-color: #a2a2a2;
}
.bgLightGray___2qCXW {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1SNcS {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___yG9Oj {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___iBVs7 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___394EH {
  color: #3368c7 !important;
}
.textDarkBlue___2NKVS {
  color: #023768 !important;
}
.textLightBlue___3UBsw {
  color: #b2cbf7;
}
.textDarkGray___1Elv9 {
  color: #333333 !important;
}
.textMediumGray___3wMe9 {
  color: #a2a2a2 !important;
}
.textLightGray___3KHDu {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1m1pR {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___39kFG {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___27Z3G {
  border-radius: 5px !important;
}
.rounded-xs___3Ig5y {
  border-radius: 10px !important;
}
.rounded-sm___ryWGm {
  border-radius: 15px !important;
}
.rounded-md___1-pNn {
  border-radius: 20px !important;
}
.rounded-lg___2WuIA {
  border-radius: 25px !important;
}
.rounded-full___AxSVg {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3pXm7 {
  margin: 0;
}
.mt-2___2xKV4 {
  margin-top: 0.5rem;
}
.my-2___2lmdV {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2G-f1 {
  padding: 0;
}
.px-1___30g65 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1iq8F {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1YtkQ {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2x8Zn {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1Av98 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1zEO8 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3SIK5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3AyOm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2EhVv {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___o4Fwv {
  padding-bottom: 20px;
}
.h-full___25Ai4 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3M7K7 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___26e3M {
  font-weight: bold !important;
}
.fontWeight400___28tsi {
  font-weight: 400 !important;
}
.fontWeight500___qQLbD {
  font-weight: 500 !important;
}
.fontWeight600___39rDD {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___191Bh {
  font-size: 12px !important;
}
.fontSize14___3NjgG {
  font-size: 14px !important;
}
.fontSize16___2Zzi4 {
  font-size: 16px !important;
}
.fontSize18___3Bki- {
  font-size: 18px !important;
}
.fontSize20___mMNDR {
  font-size: 20px !important;
}
.fontSize24___1uPPS {
  font-size: 24px !important;
}
.eventName___26hvr {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1Yath {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3CTdB > tr > th,
.ant-table-tbody___18QBl > tr.ant-table-row___LXQrq > td,
.ant-table-summary___33yey tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3CTdB > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3nn_I .ant-table-thead___3CTdB > tr > th,
.table-portrait___3nn_I .ant-table-tbody___18QBl > tr.ant-table-row___LXQrq > td,
.table-portrait___3nn_I .ant-table-summary___33yey tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3nn_I .bgLightGray___2qCXW td {
  background: #ebebeb;
}
.table-portrait___3nn_I .bgLightBlue___34eez td {
  background: #b2cbf7;
}
.eventContent___k9_L8 .timeText___1BI4P {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___18QBl > tr.bgLightGray___2qCXW:hover > td,
.ant-table-tbody___18QBl > tr > td.ant-table-cell-row-hover___2cDg2 {
  background: #ebebeb;
}
.ant-table-tbody___18QBl > tr.bgLightBlue___34eez:hover > td,
.ant-table-tbody___18QBl > tr > td.ant-table-cell-row-hover___2cDg2 {
  background: #b2cbf7;
}
.ant-table-tbody___18QBl > tr.bgWhite___1HpGS:hover > td,
.ant-table-tbody___18QBl > tr > td.ant-table-cell-row-hover___2cDg2 {
  background: #ffffff;
}
.container___2cIfR {
  padding: 20px;
}
.container___2cIfR .inputField___2X2iU {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
}
.container___2cIfR .addPartner___3bef0 {
  display: flex;
  justify-content: right;
}
.container___2cIfR .addPartner___3bef0 .addPartnerBtn___SEj6P {
  border: none;
  height: 40px;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.container___2cIfR .savePartnerBtn___XiwrF {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.container___2cIfR .selectField___2Kb1T {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container___2cIfR .selectField___2Kb1T .formField___zyCy8 {
  width: 100%;
  margin-top: 10px;
}
.container___2cIfR .selectField___2Kb1T .formField___zyCy8 .inputField___2X2iU {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.container___2cIfR .selectField___2Kb1T .removeSelectBtn___SvG-_ {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0 10px;
  height: auto;
}
