@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___O0R12 {
  display: flex;
  justify-content: center;
}
button.btn___DW45Q {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___DW45Q {
    padding: 10px;
  }
}
button.btn___DW45Q:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___DW45Q:focus {
  outline: none;
}
.btnWhite___3uBM8.btn___DW45Q {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3uBM8.btn___DW45Q:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___JDHwg.btn___DW45Q,
.btnGreen___JDHwg.btn___DW45Q:hover,
.btnGreen___JDHwg.btn___DW45Q:active,
.btnGreen___JDHwg.btn___DW45Q:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___20ctc {
  color: #fe0000;
}
.errorMessage___ERjjX {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3dL86 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3Ngkf {
  display: none !important;
}
.m-auto___tFDlr {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2UsHt {
  max-width: 120px !important;
}
.fc___2wpBF .fc-timegrid-col___3Tc9B.fc-day-today___35Msm {
  background-color: #ffffff;
}
.fc___2wpBF .fc-timegrid-slot___3fqQZ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2wpBF .fc-timegrid-slot___3fqQZ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1AP_- .fc-direction-ltr___34XDa .fc-timegrid-slot-label-frame___3EUlG {
    font-size: 10px;
  }
  .bookingCalendar___1AP_- .fc___2wpBF .fc-timegrid-slot-label-cushion___g_4KU {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1AP_- .fc___2wpBF .fc-scrollgrid-section-header___yZE5d .fc-scroller___xh2ti {
    overflow: unset !important;
  }
}
.ant-message-notice___3aqZE {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1tMk6 {
  width: 20px;
  height: 20px;
}
.bgTransparent___3vwtn {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3C_q1 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2cPRE {
  position: relative;
}
.inputCustomValue___2cPRE .ant-input___2vutj {
  padding-right: 50px;
}
.inputCustomValueText___Sl-qa {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___Id2lI .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___Id2lI .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1Ow5v .ant-radio-inner___VMDIS {
  border-color: black !important ;
}
.ant-radio-checked___1Ow5v .ant-radio-inner___VMDIS:after {
  background-color: black;
}
.ant-radio___2sUzO:hover .ant-radio-inner___VMDIS {
  border-color: black;
}
.ant-radio-checked___1Ow5v .ant-radio-inner___VMDIS:focus {
  border-color: black;
}
.modal___1cWTl {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1cWTl .close___1rwoR {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1cWTl .header___38la2 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1cWTl .header___38la2 p {
  margin: 0;
}
.modal___1cWTl .content___2pKsq {
  text-align: center;
  font-size: 14px;
}
.modal___1cWTl .content___2pKsq p {
  margin: 0;
}
.modal___1cWTl .actions___3Fi9m {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1cWTl .actions___3Fi9m .button___-NTEh {
  margin-top: 10px;
}
.modal___1cWTl .actions___3Fi9m:hover {
  color: #33c3c7;
}
.fc___2wpBF .fc-timegrid-slot-minor___2fJZc {
  border-top-style: none;
}
.fc___2wpBF .fc-timegrid-slot___3fqQZ {
  height: 1.5em !important;
}
.messageError___3QxAP .ant-message-custom-content___1zXgD {
  display: flex;
  text-align: start;
}
.messageError___3QxAP .ant-message-custom-content___1zXgD span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___mh2zd {
  background-color: #e6e4e4;
}
.notifyMessage___2jHDm .ant-message-notice-content___12-t7 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2jHDm .ant-message-notice-content___12-t7 .anticon___79f6_ {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2jHDm .ant-message-notice-content___12-t7 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3wIq1 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3wIq1 .ant-notification-notice-message___nFlaQ,
.notifyCustomize___3wIq1 .ant-notification-notice-close___2UFHN,
.notifyCustomize___3wIq1 .ant-notification-notice-icon___GQnig {
  color: white;
}
.notifyCustomize___3wIq1 .ant-notification-notice-message___nFlaQ {
  margin-left: 35px;
}
.notifyCustomize___3wIq1 p {
  margin-bottom: 0;
}
.notifyCustomize___3wIq1 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2G87z {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2G87z .ant-notification-notice-message___nFlaQ {
  margin-left: 35px;
}
.notifyAlertDuplicate___2G87z p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2G87z p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___GYa8n {
  width: 434px !important;
}
.handleSubmitVote___GYa8n .ant-btn-primary___TUcYR {
  background: #59c3c7;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR {
  padding: 15px 30px 18px;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR .ant-modal-confirm-body___3kEvg {
  display: flex !important;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR .ant-modal-confirm-body___3kEvg .anticon___79f6_ {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR .ant-modal-confirm-btns___3b45w button {
  font-weight: bold;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR .ant-modal-confirm-btns___3b45w button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR .ant-modal-confirm-btns___3b45w button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___GYa8n .ant-modal-body___1-AqR .ant-modal-confirm-btns___3b45w .ant-btn-primary___TUcYR {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2Xpq_ {
  margin-bottom: 0;
}
.mt-0___12NG- {
  margin-top: 0;
}
.tooltipFormat___2fJQE p {
  margin-bottom: 0;
}
.bgWhite___31CI- {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___25pGC {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___25pGC:hover,
.bgPrimaryBlue___25pGC:focus,
.bgPrimaryBlue___25pGC:active {
  background: #4f7ac7;
}
.bgDarkBlue___3rF3E {
  background-color: #023768 !important;
}
.bgDarkBlue___3rF3E:hover,
.bgDarkBlue___3rF3E:focus,
.bgDarkBlue___3rF3E:active {
  background: #194d7d;
}
.bgGreyBlue___n25mM {
  background-color: #ebf0f9;
}
.bgLightBlue___nEY3N {
  background-color: #b2cbf7 !important;
}
.bgLightRed___BPEfH {
  background-color: #e4453a;
}
.bgLightRed___BPEfH:hover,
.bgLightRed___BPEfH:focus,
.bgLightRed___BPEfH:active {
  background: #e17871;
}
.bgLightYellow___2xrQr {
  background-color: #ffd603;
}
.bgDarkGray___2_gsm {
  background-color: #333333;
}
.bgMediumGray___1pN9_ {
  background-color: #a2a2a2;
}
.bgLightGray___1nfdd {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3zMyw {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___bVTKC {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2LZyd {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2Kkkq {
  color: #3368c7 !important;
}
.textDarkBlue___3FqNz {
  color: #023768 !important;
}
.textLightBlue___e8tiP {
  color: #b2cbf7;
}
.textDarkGray___3No67 {
  color: #333333 !important;
}
.textMediumGray___cdzlz {
  color: #a2a2a2 !important;
}
.textLightGray___2-hEz {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3ILjE {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3COSe {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___ac5u8 {
  border-radius: 5px !important;
}
.rounded-xs___3bjeX {
  border-radius: 10px !important;
}
.rounded-sm___3SBtE {
  border-radius: 15px !important;
}
.rounded-md___37qga {
  border-radius: 20px !important;
}
.rounded-lg___3aGxs {
  border-radius: 25px !important;
}
.rounded-full___3pUzl {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2ygVd {
  margin: 0;
}
.mt-2___2Q0Fs {
  margin-top: 0.5rem;
}
.my-2___1KzXt {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2hu5W {
  padding: 0;
}
.px-1___LhrmP {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___18TaD {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1V4Ha {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3o98G {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2T3nD {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1aHiG {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___16ZSL {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___cTruJ {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2EFA4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___7KNr6 {
  padding-bottom: 20px;
}
.h-full___3q_YP {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2fwf6 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3vyp7 {
  font-weight: bold !important;
}
.fontWeight400___3HtLQ {
  font-weight: 400 !important;
}
.fontWeight500___3n5lw {
  font-weight: 500 !important;
}
.fontWeight600___2uInm {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___114S6 {
  font-size: 12px !important;
}
.fontSize14___37bnL {
  font-size: 14px !important;
}
.fontSize16___2zOa5 {
  font-size: 16px !important;
}
.fontSize18___3YiM1 {
  font-size: 18px !important;
}
.fontSize20___mK89- {
  font-size: 20px !important;
}
.fontSize24___1iz5b {
  font-size: 24px !important;
}
.eventName___37NmA {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___KSiFL {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1r8vv > tr > th,
.ant-table-tbody___3RzQI > tr.ant-table-row___18Fzx > td,
.ant-table-summary___NwtcB tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1r8vv > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___32XGU .ant-table-thead___1r8vv > tr > th,
.table-portrait___32XGU .ant-table-tbody___3RzQI > tr.ant-table-row___18Fzx > td,
.table-portrait___32XGU .ant-table-summary___NwtcB tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___32XGU .bgLightGray___1nfdd td {
  background: #ebebeb;
}
.table-portrait___32XGU .bgLightBlue___nEY3N td {
  background: #b2cbf7;
}
.eventContent___2ccR_ .timeText___1Wqne {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3RzQI > tr.bgLightGray___1nfdd:hover > td,
.ant-table-tbody___3RzQI > tr > td.ant-table-cell-row-hover___1eVy5 {
  background: #ebebeb;
}
.ant-table-tbody___3RzQI > tr.bgLightBlue___nEY3N:hover > td,
.ant-table-tbody___3RzQI > tr > td.ant-table-cell-row-hover___1eVy5 {
  background: #b2cbf7;
}
.ant-table-tbody___3RzQI > tr.bgWhite___31CI-:hover > td,
.ant-table-tbody___3RzQI > tr > td.ant-table-cell-row-hover___1eVy5 {
  background: #ffffff;
}
.container___1qEAL .pageTitle___PekH8 {
  font-size: 30px;
  margin: 50px 0 20px;
}
.container___1qEAL .ant-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.container___1qEAL .ant-pagination {
  text-align: right;
  padding: 20px 0;
}
.container___1qEAL .ant-row {
  margin: 8px 0;
}
.container___1qEAL thead .ant-table-cell {
  background: #ddf6f5;
  border: 1px solid black;
  border-bottom: none;
}
.container___1qEAL thead tr th:last-child {
  border-color: black !important;
}
.container___1qEAL tbody .ant-table-cell {
  border: 1px solid black !important;
}
.container___1qEAL tbody tr td {
  border-top: 0 !important;
}
.container___1qEAL tbody tr:not(:last-child) td {
  border-bottom: 0 !important;
}
.container___1qEAL tbody tr td:not(:last-child) {
  border-right: 0 !important;
}
.container___1qEAL .ant-pagination {
  margin-bottom: 30px;
}
.container___1qEAL .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.85);
}
.container___1qEAL .ant-pagination-item-active,
.container___1qEAL .ant-pagination-item:hover,
.container___1qEAL .ant-pagination-prev:hover .ant-pagination-item-link,
.container___1qEAL .ant-pagination-next:hover .ant-pagination-item-link {
  color: #33c3c7;
  border-color: #33c3c7;
}
.container___1qEAL .ant-pagination-item-active a,
.container___1qEAL .ant-pagination-item:hover a,
.container___1qEAL .ant-pagination-prev:hover .ant-pagination-item-link a,
.container___1qEAL .ant-pagination-next:hover .ant-pagination-item-link a {
  color: #33c3c7;
}
.container___1qEAL a {
  color: #1890ff;
}
.container___1qEAL a:hover {
  color: #40a9ff;
}
.container___1qEAL .agencyTable___1vS73 {
  margin-top: 16px;
}
.customButton___2M9ZK {
  margin-left: 10px;
}
.customButton___2M9ZK:hover {
  border-color: #d9d9d9;
  color: black;
}
.modalUpdateAgency___3nmrK {
  display: grid;
  grid-template-columns: 50% 50%;
}
.modalUpdateAgency___3nmrK .ant-form-item {
  padding: 0 10px;
}
