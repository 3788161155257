@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3k-6n {
  display: flex;
  justify-content: center;
}
button.btn___1ZUq5 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1ZUq5 {
    padding: 10px;
  }
}
button.btn___1ZUq5:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1ZUq5:focus {
  outline: none;
}
.btnWhite___3GebH.btn___1ZUq5 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3GebH.btn___1ZUq5:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2-Oon.btn___1ZUq5,
.btnGreen___2-Oon.btn___1ZUq5:hover,
.btnGreen___2-Oon.btn___1ZUq5:active,
.btnGreen___2-Oon.btn___1ZUq5:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1Fgs3 {
  color: #fe0000;
}
.errorMessage___5QtMc {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2phHm {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2pw_- {
  display: none !important;
}
.m-auto___3-rJb {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width____K7aL {
  max-width: 120px !important;
}
.fc___2UEhD .fc-timegrid-col____P4mq.fc-day-today___28kQj {
  background-color: #ffffff;
}
.fc___2UEhD .fc-timegrid-slot___2mqGQ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2UEhD .fc-timegrid-slot___2mqGQ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1z9b_ .fc-direction-ltr___-0AfO .fc-timegrid-slot-label-frame___2P977 {
    font-size: 10px;
  }
  .bookingCalendar___1z9b_ .fc___2UEhD .fc-timegrid-slot-label-cushion___2FEWT {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1z9b_ .fc___2UEhD .fc-scrollgrid-section-header___Zkf-4 .fc-scroller___HYZXI {
    overflow: unset !important;
  }
}
.ant-message-notice___3ygbU {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3jwqI {
  width: 20px;
  height: 20px;
}
.bgTransparent___-aTtB {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3Z8KJ {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2c1B- {
  position: relative;
}
.inputCustomValue___2c1B- .ant-input___3SWs9 {
  padding-right: 50px;
}
.inputCustomValueText___2vC0W {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2yF7E .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2yF7E .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1axao .ant-radio-inner___1RifN {
  border-color: black !important ;
}
.ant-radio-checked___1axao .ant-radio-inner___1RifN:after {
  background-color: black;
}
.ant-radio___FD01V:hover .ant-radio-inner___1RifN {
  border-color: black;
}
.ant-radio-checked___1axao .ant-radio-inner___1RifN:focus {
  border-color: black;
}
.modal___4QVyn {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___4QVyn .close___3d1u7 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___4QVyn .header___1cq4K {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___4QVyn .header___1cq4K p {
  margin: 0;
}
.modal___4QVyn .content___u1Atk {
  text-align: center;
  font-size: 14px;
}
.modal___4QVyn .content___u1Atk p {
  margin: 0;
}
.modal___4QVyn .actions___3irge {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___4QVyn .actions___3irge .button___IEI1m {
  margin-top: 10px;
}
.modal___4QVyn .actions___3irge:hover {
  color: #33c3c7;
}
.fc___2UEhD .fc-timegrid-slot-minor___1kSfS {
  border-top-style: none;
}
.fc___2UEhD .fc-timegrid-slot___2mqGQ {
  height: 1.5em !important;
}
.messageError___nyR7X .ant-message-custom-content___1HpLt {
  display: flex;
  text-align: start;
}
.messageError___nyR7X .ant-message-custom-content___1HpLt span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1y-JT {
  background-color: #e6e4e4;
}
.notifyMessage___3PrVd .ant-message-notice-content___2LSVk {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3PrVd .ant-message-notice-content___2LSVk .anticon___36H4L {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3PrVd .ant-message-notice-content___2LSVk span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3Vc75 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3Vc75 .ant-notification-notice-message___3aFnl,
.notifyCustomize___3Vc75 .ant-notification-notice-close___kpQjt,
.notifyCustomize___3Vc75 .ant-notification-notice-icon___2MRZK {
  color: white;
}
.notifyCustomize___3Vc75 .ant-notification-notice-message___3aFnl {
  margin-left: 35px;
}
.notifyCustomize___3Vc75 p {
  margin-bottom: 0;
}
.notifyCustomize___3Vc75 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___23Ylz {
  padding: 16px 14px;
}
.notifyAlertDuplicate___23Ylz .ant-notification-notice-message___3aFnl {
  margin-left: 35px;
}
.notifyAlertDuplicate___23Ylz p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___23Ylz p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3r7PR {
  width: 434px !important;
}
.handleSubmitVote___3r7PR .ant-btn-primary___3d90p {
  background: #59c3c7;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX .ant-modal-confirm-body___1cw93 {
  display: flex !important;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX .ant-modal-confirm-body___1cw93 .anticon___36H4L {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX .ant-modal-confirm-btns___2wF4t button {
  font-weight: bold;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX .ant-modal-confirm-btns___2wF4t button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX .ant-modal-confirm-btns___2wF4t button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3r7PR .ant-modal-body___mqRHX .ant-modal-confirm-btns___2wF4t .ant-btn-primary___3d90p {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___pzKlC {
  margin-bottom: 0;
}
.mt-0___3iZAi {
  margin-top: 0;
}
.tooltipFormat___2oAqI p {
  margin-bottom: 0;
}
.bgWhite___5x91L {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2Tfb4 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2Tfb4:hover,
.bgPrimaryBlue___2Tfb4:focus,
.bgPrimaryBlue___2Tfb4:active {
  background: #4f7ac7;
}
.bgDarkBlue___2QHvb {
  background-color: #023768 !important;
}
.bgDarkBlue___2QHvb:hover,
.bgDarkBlue___2QHvb:focus,
.bgDarkBlue___2QHvb:active {
  background: #194d7d;
}
.bgGreyBlue___30c9T {
  background-color: #ebf0f9;
}
.bgLightBlue___2mL9C {
  background-color: #b2cbf7 !important;
}
.bgLightRed___QpOBz {
  background-color: #e4453a;
}
.bgLightRed___QpOBz:hover,
.bgLightRed___QpOBz:focus,
.bgLightRed___QpOBz:active {
  background: #e17871;
}
.bgLightYellow___1sQqd {
  background-color: #ffd603;
}
.bgDarkGray___1YLR- {
  background-color: #333333;
}
.bgMediumGray___3RSDN {
  background-color: #a2a2a2;
}
.bgLightGray___35zPE {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1-fLM {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1INwn {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2F184 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___4PkFU {
  color: #3368c7 !important;
}
.textDarkBlue___A_A1L {
  color: #023768 !important;
}
.textLightBlue___1x6dN {
  color: #b2cbf7;
}
.textDarkGray___3g3rA {
  color: #333333 !important;
}
.textMediumGray___3xCq1 {
  color: #a2a2a2 !important;
}
.textLightGray___dsA5I {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1Cvhl {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3P3k9 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___HLoBd {
  border-radius: 5px !important;
}
.rounded-xs___8WHmO {
  border-radius: 10px !important;
}
.rounded-sm___25Gr- {
  border-radius: 15px !important;
}
.rounded-md___3Gt-K {
  border-radius: 20px !important;
}
.rounded-lg___3lcgU {
  border-radius: 25px !important;
}
.rounded-full___TonKH {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1zDuM {
  margin: 0;
}
.mt-2___3uWZQ {
  margin-top: 0.5rem;
}
.my-2___MsJV0 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___I_G03 {
  padding: 0;
}
.px-1___3ELQy {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1Cjfc {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1mWtU {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3ITl6 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___28PIH {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1O6Pf {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1uUEr {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___lnbbk {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1fj3D {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2kAVe {
  padding-bottom: 20px;
}
.h-full___1n4VX {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1CYq- {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2F7QI {
  font-weight: bold !important;
}
.fontWeight400___6fpa4 {
  font-weight: 400 !important;
}
.fontWeight500___TZAKz {
  font-weight: 500 !important;
}
.fontWeight600___2PD-Y {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3Zo4x {
  font-size: 12px !important;
}
.fontSize14___1bAPH {
  font-size: 14px !important;
}
.fontSize16___Wp3xF {
  font-size: 16px !important;
}
.fontSize18___1iLCW {
  font-size: 18px !important;
}
.fontSize20___Fna0t {
  font-size: 20px !important;
}
.fontSize24___13ZLg {
  font-size: 24px !important;
}
.eventName___2NNs7 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___38FsK {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___O25Nz > tr > th,
.ant-table-tbody___2geyX > tr.ant-table-row___361NL > td,
.ant-table-summary___32cUg tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___O25Nz > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3R3Kv .ant-table-thead___O25Nz > tr > th,
.table-portrait___3R3Kv .ant-table-tbody___2geyX > tr.ant-table-row___361NL > td,
.table-portrait___3R3Kv .ant-table-summary___32cUg tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3R3Kv .bgLightGray___35zPE td {
  background: #ebebeb;
}
.table-portrait___3R3Kv .bgLightBlue___2mL9C td {
  background: #b2cbf7;
}
.eventContent___3gED1 .timeText___2f9Mk {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2geyX > tr.bgLightGray___35zPE:hover > td,
.ant-table-tbody___2geyX > tr > td.ant-table-cell-row-hover___2DCIL {
  background: #ebebeb;
}
.ant-table-tbody___2geyX > tr.bgLightBlue___2mL9C:hover > td,
.ant-table-tbody___2geyX > tr > td.ant-table-cell-row-hover___2DCIL {
  background: #b2cbf7;
}
.ant-table-tbody___2geyX > tr.bgWhite___5x91L:hover > td,
.ant-table-tbody___2geyX > tr > td.ant-table-cell-row-hover___2DCIL {
  background: #ffffff;
}
.calendarCreation___3ONQi {
  max-width: 1080px;
  margin: auto;
  padding: 25px 15px 50px;
}
@media screen and (max-width: 767px) {
  .calendarCreation___3ONQi {
    padding: 25px 15px 130px;
  }
}
.calendarCreation___3ONQi .saveToTemplateBtn___r48aa {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;
}
@media screen and (max-width: 767px) {
  .calendarCreation___3ONQi .saveToTemplateBtn___r48aa {
    flex-wrap: wrap;
  }
}
.calendarCreation___3ONQi .saveToTemplateBtn___r48aa button {
  height: 40px;
  width: 33%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .calendarCreation___3ONQi .saveToTemplateBtn___r48aa button {
    width: 100%;
    margin-bottom: 15px;
  }
}
.calendarCreation___3ONQi .registerTemplateText___3XIgV {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
}
.createEventType___TmJ__ {
  padding-bottom: 80px;
}
.advancedSettingContainer___37W7e {
  max-width: 1080px;
  padding: 30px 15px 80px;
  margin: auto;
}
.advancedSettingContainer___37W7e .collapseSettingContainer____EQ9E {
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 35px;
}
.advancedSettingContainer___37W7e .messageSettingContainer___1bCmu .ant-collapse-header .ant-collapse-arrow {
  left: 250px !important;
}
.advancedSettingContainer___37W7e .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 180px;
  right: unset;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%);
}
.advancedSettingContainer___37W7e .ant-collapse {
  border: 0;
  background-color: #fff;
}
.advancedSettingContainer___37W7e .ant-collapse-header,
.advancedSettingContainer___37W7e .ant-collapse > .ant-collapse-item:last-child,
.advancedSettingContainer___37W7e .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: #fff;
  border-radius: 20px;
}
.advancedSettingContainer___37W7e .ant-collapse-content,
.advancedSettingContainer___37W7e .ant-collapse-item:last-child > .ant-collapse-content {
  border-top: 0;
  border-radius: 0 0 20px 20px;
}
.advancedSettingContainer___37W7e .ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.advancedSettingContainer___37W7e .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 10px;
  height: 23px;
  min-width: 99px;
  border: 2px solid #d9d9d9 !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: #414141;
  line-height: 23px;
  display: flex;
  align-items: center;
}
.advancedSettingContainer___37W7e .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.advancedSettingContainer___37W7e .ant-picker {
  max-width: 100px;
  font-size: 13px;
  height: 24px;
  border-radius: 15px;
  border-width: 2px;
}
.advancedSettingContainer___37W7e .ant-picker-input > input {
  font-size: 13px;
}
.advancedSettingContainer___37W7e .scheduleSetting___2RZOh {
  padding: 24px 64px 0 64px;
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 40px;
}
.advancedSettingContainer___37W7e .timeSetting___7-YUr {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___37W7e .messageSetting___46Kjm {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___37W7e .dropdownHeader___3Gf_x {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___37W7e .dropdownHeader___3Gf_x {
    position: relative;
    width: 100%;
  }
}
.advancedSettingContainer___37W7e .dropdownHeader___3Gf_x .dropdownHeaderTitle___1fU4P {
  font-size: 26px;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___37W7e .dropdownHeader___3Gf_x .dropdownHeaderTitle___1fU4P {
    font-size: 16px;
  }
}
.advancedSettingContainer___37W7e .dropdownHeader___3Gf_x img {
  height: 23px;
  width: 23px;
  margin-right: 8px;
}
.advancedSettingContainer___37W7e .dropdownHeader___3Gf_x a {
  font-size: 25px;
  color: #414141;
}
.advancedSettingContainer___37W7e .dropdownHeader___3Gf_x a .anticon-down {
  margin-left: 30px;
}
.buttonZoneContainer___3yAZm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.buttonZoneContainer___3yAZm .backBtn___B3AFF {
  height: 48px;
  min-width: 148px;
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #ffffff;
  color: #414141;
  box-shadow: 1px 1px 12px #b3bcbc60;
}
.helper___3jwqI {
  margin-left: 75px;
  margin-top: 7px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 767px) {
  .helper___3jwqI {
    position: absolute;
    right: 0;
  }
}
.helper___3jwqI img {
  position: relative;
}
.helper___3jwqI .helperTooltip___1WfH9 {
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 50px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 120px;
  width: 400px;
  background-color: #414141;
  color: #fffefe;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.helper___3jwqI .helperTooltip___1WfH9::-webkit-scrollbar {
  display: none;
}
.helper___3jwqI .helperTooltip___1WfH9 img {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.helper___3jwqI:hover .helperTooltip___1WfH9 {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
.tooltipAdvanced___1cIwg {
  white-space: pre-line;
}
.tooltipAdvanced___1cIwg .ant-tooltip-inner {
  height: 119px;
  min-width: 300px;
  justify-content: center;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  .tooltipAdvanced___1cIwg .ant-tooltip-inner {
    min-width: auto;
    height: auto;
  }
}
.tooltipAdvanced___1cIwg .ant-tooltip-content .ant-tooltip-arrow {
  width: 30px;
  height: 30px;
}
.tooltipAdvanced___1cIwg .ant-tooltip-content .ant-tooltip-arrow-content {
  background-color: transparent;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.75);
  transform: unset;
  box-shadow: unset;
  top: 13px;
}
@media screen and (max-width: 767px) {
  .bookingCalendarParent___2gAS- {
    position: relative;
    overflow: scroll;
    height: 80vh;
  }
  .bookingCalendarParent___2gAS- > div {
    position: absolute;
  }
}
.btnCreateCalendar___G8uxL {
  position: absolute;
  right: 20px;
  bottom: 30px;
  padding: 15px;
  background: #0f87e4;
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  z-index: 999;
}
.calendarLinkOtherDesc___1L3zL {
  background-color: rgba(24, 144, 255, 0.15);
  border-radius: 20px;
  padding: 15px;
  color: #0f87e4;
}
.calendarLinkOtherDesc___1L3zL .calendarLinkOtherTitle___3r0p4 {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}
.calendarLinkOtherDesc___1L3zL .calendarLinkOtherTitle___3r0p4 > svg {
  fill: #0c3dc9;
  margin-right: 5px;
}
.calendarLinkOtherDesc___1L3zL .calendarLinkOtherContent___29cpo {
  font-size: 0.9rem;
}
.calendarLinkOtherBtn___3wJRu {
  padding-top: 20px;
}
.calendarLinkOtherBtn___3wJRu .calendarLinkOtherItem___3Dh9W {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b3bcbc;
  background: transparent;
  padding: 5px 10px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.calendarLinkOtherBtn___3wJRu .calendarLinkOtherItem___3Dh9W > img {
  width: 25px;
  margin-right: 10px;
}
.calendarLinkOtherFooter___2LvVJ {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendarLinkOtherFooter___2LvVJ > a {
  padding: 0 10px;
  color: #0f87e4;
  text-decoration: underline;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background: #0f87e4;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.75);
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon > svg {
  color: #ffffff;
}
