.xsMenu___3-Dn- {
  display: none;
}
@media screen and (max-width: 767px) {
  .xsMenu___3-Dn- {
    display: block;
  }
  .xsMenu___3-Dn- .fixed___18fLP {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 111;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  }
  .xsMenu___3-Dn- .menu___--EU6 {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi {
    flex-basis: 25%;
    text-align: center;
    font-size: 11px;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi img {
    width: 30px;
    margin-bottom: 3px;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi .imgActive___32Zpv {
    display: none;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi.active___1liWd .imgActive___32Zpv {
    display: inline-block;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi.active___1liWd .imgNonActive___1JvnK {
    display: none;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi.active___1liWd .text___2YsAZ {
    color: #33c3c7;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi .textList___Ddkpg {
    color: #3c3c3c;
  }
  .xsMenu___3-Dn- .menu___--EU6 .menuItem___3sGhi .textCreate___2d24y {
    color: #060606;
  }
}
.menuCreateRoom___2cTba {
  box-shadow: rgba(0, 0, 0, 0.2) 0 12px 28px 0, rgba(0, 0, 0, 0.1) 0 2px 4px 0, rgba(255, 255, 255, 0.05) 0 0 0 1px inset !important;
}
.menuCreateRoom___2cTba li {
  padding-left: 22px !important;
  display: flex;
  align-items: center;
  height: 64px !important;
  line-height: 64px !important;
  margin-bottom: 0;
  background: transparent !important;
  transition: all 0.3s;
}
.menuCreateRoom___2cTba li span h4 {
  margin: 0 !important;
  height: 14px;
  font-size: 14px;
  font-weight: bold;
}
.menuCreateRoom___2cTba li span p {
  margin: 5px 0 0 0 !important;
  font-size: 14px !important;
}
.menuCreateRoom___2cTba li:hover,
.menuCreateRoom___2cTba li:visited {
  background: #f0f0f0 !important;
}
