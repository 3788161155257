.headerContainer___2z9Ty {
  border-bottom: 1px solid #acd9db;
}
.headerContainer___2z9Ty .homeHeader___3F3M7 {
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .headerContainer___2z9Ty .homeHeader___3F3M7 {
    padding: 0 10px;
    height: 60px;
  }
}
.headerContainer___2z9Ty .homeHeader___3F3M7 .logo___3_kgW {
  height: 40px;
  width: 180px;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .headerContainer___2z9Ty .homeHeader___3F3M7 .logo___3_kgW {
    width: 120px;
    height: auto;
  }
}
.headerContainer___2z9Ty .homeHeader___3F3M7 .logo___3_kgW img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 767px) {
  .headerContainer___2z9Ty .homeHeader___3F3M7 .logo___3_kgW img {
    position: unset;
    object-fit: unset;
    object-position: unset;
  }
}
@media screen and (max-width: 767px) {
  .headerContainer___2z9Ty .homeHeader___3F3M7 .menu___1e8u5 {
    display: flex;
  }
}
.headerContainer___2z9Ty .homeHeader___3F3M7 .menu___1e8u5 span {
  min-width: 100px;
  display: inline-block;
  margin-left: 43px;
  text-align: left;
  text-decoration: underline;
  font-size: 18px;
  letter-spacing: 0px;
  color: #038b8f;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .headerContainer___2z9Ty .homeHeader___3F3M7 .menu___1e8u5 span {
    min-width: auto;
    margin-left: 15px;
    font-size: 13px;
  }
}
