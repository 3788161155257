.markCalendar___3FmQM {
  padding: 1rem 1rem 1.5rem 1rem;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.markCalendar___3FmQM .calendarTemplateChild___1JhWi {
  position: relative;
  padding: 0.6rem 0.6rem 0.6rem 0.4rem;
  border: 3px solid #4a6bc0;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s;
}
.markCalendar___3FmQM .calendarTemplateChild___1JhWi .code___2RZ99 {
  position: absolute;
  background: #4a6bc0;
  border: 4px solid #fff;
  border-radius: 25px;
  width: 40px;
  height: 40px;
  text-align: center;
  left: -16px;
  top: -19px;
}
.markCalendar___3FmQM .calendarTemplateChild___1JhWi .code___2RZ99 span {
  line-height: 28px;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
}
.markCalendar___3FmQM .calendarTemplateChild___1JhWi.errorActive___3grzL {
  border-color: red;
}
.markCalendar___3FmQM .calendarTemplateChild___1JhWi.confirmTemplate___2Qnyc {
  border: transparent !important;
}
.markCalendar___3FmQM .calendarTemplateChild___1JhWi.confirmTemplate___2Qnyc .code___2RZ99 {
  display: none;
}
.markCalendar___3FmQM.markActive___2ml-D {
  z-index: 1;
}
.markCalendar___3FmQM .error___2sDzo {
  color: red;
  transition: all 0.3s;
  display: none;
  margin-top: 1rem;
}
.markCalendar___3FmQM .error___2sDzo.errorActive___3grzL {
  display: block;
}
@media screen and (max-width: 425px) {
  .markCalendar___3FmQM {
    padding: unset;
  }
  .markCalendar___3FmQM .calendarTemplateChild___1JhWi {
    border: unset;
  }
  .markCalendar___3FmQM .calendarTemplateChild___1JhWi .code___2RZ99 {
    display: none;
  }
}
