.position___3zS_N {
  margin-bottom: 0;
  padding-left: 1rem;
  color: #3c3c3c;
  background: transparent;
  border: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-weight: bold;
}
.position___3zS_N input {
  border: transparent !important;
  outline: none;
  cursor: pointer;
  width: 100%;
}
.position___3zS_N span {
  word-break: break-all;
}
