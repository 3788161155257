@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___37GTx {
  display: flex;
  justify-content: center;
}
button.btn___1bpSw {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1bpSw {
    padding: 10px;
  }
}
button.btn___1bpSw:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1bpSw:focus {
  outline: none;
}
.btnWhite___39szN.btn___1bpSw {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___39szN.btn___1bpSw:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2XhaD.btn___1bpSw,
.btnGreen___2XhaD.btn___1bpSw:hover,
.btnGreen___2XhaD.btn___1bpSw:active,
.btnGreen___2XhaD.btn___1bpSw:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1rSQA {
  color: #fe0000;
}
.errorMessage___urLMO {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1ktZX {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___As9tK {
  display: none !important;
}
.m-auto___JRu2d {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1HtT9 {
  max-width: 120px !important;
}
.fc___2DXzF .fc-timegrid-col___tJ-5b.fc-day-today___2oSC4 {
  background-color: #ffffff;
}
.fc___2DXzF .fc-timegrid-slot___30XRU {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2DXzF .fc-timegrid-slot___30XRU {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___31Zp4 .fc-direction-ltr___22ThY .fc-timegrid-slot-label-frame___1iRPq {
    font-size: 10px;
  }
  .bookingCalendar___31Zp4 .fc___2DXzF .fc-timegrid-slot-label-cushion___3BVwf {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___31Zp4 .fc___2DXzF .fc-scrollgrid-section-header___1bz4b .fc-scroller___12Vkv {
    overflow: unset !important;
  }
}
.ant-message-notice___C3hOJ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2MJa4 {
  width: 20px;
  height: 20px;
}
.bgTransparent___2bROf {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1g5ZR {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___LOlgt {
  position: relative;
}
.inputCustomValue___LOlgt .ant-input___j5_hr {
  padding-right: 50px;
}
.inputCustomValueText___1uiaa {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1tFXv .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1tFXv .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___24o19 .ant-radio-inner___1h-dq {
  border-color: black !important ;
}
.ant-radio-checked___24o19 .ant-radio-inner___1h-dq:after {
  background-color: black;
}
.ant-radio___3fWF1:hover .ant-radio-inner___1h-dq {
  border-color: black;
}
.ant-radio-checked___24o19 .ant-radio-inner___1h-dq:focus {
  border-color: black;
}
.modal___2BlVY {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2BlVY .close___2Qid4 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2BlVY .header___2_SeB {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2BlVY .header___2_SeB p {
  margin: 0;
}
.modal___2BlVY .content___3tTnE {
  text-align: center;
  font-size: 14px;
}
.modal___2BlVY .content___3tTnE p {
  margin: 0;
}
.modal___2BlVY .actions___27P56 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2BlVY .actions___27P56 .button___1YiBl {
  margin-top: 10px;
}
.modal___2BlVY .actions___27P56:hover {
  color: #33c3c7;
}
.fc___2DXzF .fc-timegrid-slot-minor___2pw_k {
  border-top-style: none;
}
.fc___2DXzF .fc-timegrid-slot___30XRU {
  height: 1.5em !important;
}
.messageError___3ZHhj .ant-message-custom-content___1uOA1 {
  display: flex;
  text-align: start;
}
.messageError___3ZHhj .ant-message-custom-content___1uOA1 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3ej75 {
  background-color: #e6e4e4;
}
.notifyMessage___342Jg .ant-message-notice-content___2kq9W {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___342Jg .ant-message-notice-content___2kq9W .anticon___1F2zV {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___342Jg .ant-message-notice-content___2kq9W span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1QF1I {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1QF1I .ant-notification-notice-message___2UTbC,
.notifyCustomize___1QF1I .ant-notification-notice-close___L2COb,
.notifyCustomize___1QF1I .ant-notification-notice-icon___3i2vW {
  color: white;
}
.notifyCustomize___1QF1I .ant-notification-notice-message___2UTbC {
  margin-left: 35px;
}
.notifyCustomize___1QF1I p {
  margin-bottom: 0;
}
.notifyCustomize___1QF1I p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___23f9i {
  padding: 16px 14px;
}
.notifyAlertDuplicate___23f9i .ant-notification-notice-message___2UTbC {
  margin-left: 35px;
}
.notifyAlertDuplicate___23f9i p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___23f9i p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___o1cOL {
  width: 434px !important;
}
.handleSubmitVote___o1cOL .ant-btn-primary___38Q8v {
  background: #59c3c7;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 {
  padding: 15px 30px 18px;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 .ant-modal-confirm-body___gd-PA {
  display: flex !important;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 .ant-modal-confirm-body___gd-PA .anticon___1F2zV {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 .ant-modal-confirm-btns___1pxiy button {
  font-weight: bold;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 .ant-modal-confirm-btns___1pxiy button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 .ant-modal-confirm-btns___1pxiy button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___o1cOL .ant-modal-body___1qOu3 .ant-modal-confirm-btns___1pxiy .ant-btn-primary___38Q8v {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2ainR {
  margin-bottom: 0;
}
.mt-0___2HUQj {
  margin-top: 0;
}
.tooltipFormat___S-Pf8 p {
  margin-bottom: 0;
}
.bgWhite___1D0_b {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___XWb82 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___XWb82:hover,
.bgPrimaryBlue___XWb82:focus,
.bgPrimaryBlue___XWb82:active {
  background: #4f7ac7;
}
.bgDarkBlue___3qkE5 {
  background-color: #023768 !important;
}
.bgDarkBlue___3qkE5:hover,
.bgDarkBlue___3qkE5:focus,
.bgDarkBlue___3qkE5:active {
  background: #194d7d;
}
.bgGreyBlue___2m2tx {
  background-color: #ebf0f9;
}
.bgLightBlue___2qmGO {
  background-color: #b2cbf7 !important;
}
.bgLightRed___jIHBl {
  background-color: #e4453a;
}
.bgLightRed___jIHBl:hover,
.bgLightRed___jIHBl:focus,
.bgLightRed___jIHBl:active {
  background: #e17871;
}
.bgLightYellow___28BB5 {
  background-color: #ffd603;
}
.bgDarkGray___2Jzyq {
  background-color: #333333;
}
.bgMediumGray___14Ujc {
  background-color: #a2a2a2;
}
.bgLightGray___2sDRJ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3x4j5 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2gUL6 {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2X2wz {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___4zirD {
  color: #3368c7 !important;
}
.textDarkBlue___8NXdn {
  color: #023768 !important;
}
.textLightBlue___2zRkm {
  color: #b2cbf7;
}
.textDarkGray___98dp9 {
  color: #333333 !important;
}
.textMediumGray___21Zkm {
  color: #a2a2a2 !important;
}
.textLightGray___ehmOc {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2rBR4 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___13GCO {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___34KWs {
  border-radius: 5px !important;
}
.rounded-xs___2Sc5B {
  border-radius: 10px !important;
}
.rounded-sm___3DcmA {
  border-radius: 15px !important;
}
.rounded-md___2-2QR {
  border-radius: 20px !important;
}
.rounded-lg___3pbWD {
  border-radius: 25px !important;
}
.rounded-full___HuUoa {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2nzIp {
  margin: 0;
}
.mt-2___1hZ-0 {
  margin-top: 0.5rem;
}
.my-2___3irjE {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1gtkb {
  padding: 0;
}
.px-1___eNtV7 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___28hAI {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___hrEfX {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2_3h_ {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1cYci {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2r3H3 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2IAxT {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2J5AK {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1fQ9e {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___17fhi {
  padding-bottom: 20px;
}
.h-full___10UZw {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1DZ0y {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2iu9R {
  font-weight: bold !important;
}
.fontWeight400___21eIA {
  font-weight: 400 !important;
}
.fontWeight500___2e5-v {
  font-weight: 500 !important;
}
.fontWeight600___1AHPJ {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___cBZxs {
  font-size: 12px !important;
}
.fontSize14___2gWuH {
  font-size: 14px !important;
}
.fontSize16___1F4KY {
  font-size: 16px !important;
}
.fontSize18___o31YL {
  font-size: 18px !important;
}
.fontSize20___1tcgq {
  font-size: 20px !important;
}
.fontSize24___b2TiZ {
  font-size: 24px !important;
}
.eventName___38lF_ {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2J9xN {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___4CSP9 > tr > th,
.ant-table-tbody___30I0_ > tr.ant-table-row___2rBIT > td,
.ant-table-summary___F4D51 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___4CSP9 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2H8QU .ant-table-thead___4CSP9 > tr > th,
.table-portrait___2H8QU .ant-table-tbody___30I0_ > tr.ant-table-row___2rBIT > td,
.table-portrait___2H8QU .ant-table-summary___F4D51 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2H8QU .bgLightGray___2sDRJ td {
  background: #ebebeb;
}
.table-portrait___2H8QU .bgLightBlue___2qmGO td {
  background: #b2cbf7;
}
.eventContent___2wt6V .timeText___2rUPo {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___30I0_ > tr.bgLightGray___2sDRJ:hover > td,
.ant-table-tbody___30I0_ > tr > td.ant-table-cell-row-hover___1UBi9 {
  background: #ebebeb;
}
.ant-table-tbody___30I0_ > tr.bgLightBlue___2qmGO:hover > td,
.ant-table-tbody___30I0_ > tr > td.ant-table-cell-row-hover___1UBi9 {
  background: #b2cbf7;
}
.ant-table-tbody___30I0_ > tr.bgWhite___1D0_b:hover > td,
.ant-table-tbody___30I0_ > tr > td.ant-table-cell-row-hover___1UBi9 {
  background: #ffffff;
}
.forgotPassword___10XTi {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 80px;
}
.forgotPassword___10XTi h2 {
  font-size: 30px;
  text-align: center;
  padding: 100px 0 700px 0;
}
.forgotPassword___10XTi .bodyContent___2bHb6 {
  padding: 20px 0;
}
.forgotPassword___10XTi .bodyContent___2bHb6 p {
  font-size: 24px;
  text-indent: 37px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___10XTi .bodyContent___2bHb6 p {
    text-indent: 0;
  }
}
.forgotPassword___10XTi .bodyContent___2bHb6 .headTitle___3vxqs {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___10XTi .bodyContent___2bHb6 .headTitle___3vxqs {
    margin-bottom: 20px;
  }
}
.forgotPassword___10XTi .bodyContent___2bHb6 .headTitle___3vxqs .bolderIcon___2VrhO {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .headTitle___3vxqs .titleIcon___37918 {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .headTitle___3vxqs span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___10XTi .bodyContent___2bHb6 .headTitle___3vxqs span {
    font-size: 35px;
  }
}
.forgotPassword___10XTi .bodyContent___2bHb6 .intruction___2jJbC {
  font-size: 18px;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .inputField___2I03L {
  margin: auto;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .inputField___2I03L span {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .inputField___2I03L input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 0;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .inputField___2I03L .errorNotice___8d4yY {
  color: #ff4d4f;
  font-size: 14px;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .btnZone___2CoE6 {
  margin-top: 20px;
  margin-bottom: 25px;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .btnZone___2CoE6 .signUpBtn___Ngp6J {
  width: 100%;
  height: 40px;
  background-color: #023768;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.forgotPassword___10XTi .bodyContent___2bHb6 .btnZone___2CoE6 .signUpBtn___Ngp6J:hover {
  background-color: #1f4f96;
}
.forgotPassword___10XTi .ant-form-item {
  margin: 0;
}
.forgotPassword___10XTi .ant-form {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
