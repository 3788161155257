.calendarBookingTemplate___3VLIm {
  padding: 0.1rem 0.5rem 0.5rem 0.5rem;
}
.calendarBookingTemplate___3VLIm .title___18D6I {
  display: flex;
  margin-bottom: 0.6rem;
}
.calendarBookingTemplate___3VLIm .title___18D6I div:first-child {
  background: #3c3c3c;
  height: 1.2rem;
  width: 0.35rem;
  margin: auto 0;
}
.calendarBookingTemplate___3VLIm .title___18D6I div:last-child {
  padding-left: 0.4rem;
}
.calendarBookingTemplate___3VLIm .title___18D6I div:last-child h3 {
  color: #3c3c3c;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.calendarBookingTemplate___3VLIm .title___18D6I div:last-child span {
  display: none;
  font-size: 14px;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ button {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: transparent;
  padding: 0.1rem 0;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ button span {
  font-weight: bold;
  font-size: 16px;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ button:first-child img {
  padding-right: 0.5rem;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ button:last-child span {
  color: #38b9bc;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ button:last-child img {
  padding-left: 0.5rem;
}
.calendarBookingTemplate___3VLIm .nextWeek___zZGmZ button.active___3bX66 span {
  color: #38b9bc;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha {
  border: 5px solid #f7f6f6;
  max-height: 100vh;
  overflow-y: auto;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ {
  padding: 0.8rem 1rem;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ:not(:last-child) {
  border-bottom: 2px solid #d0d0d0;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .title___18D6I {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Noto Sans JP';
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ div {
  cursor: pointer;
  color: #3c3c3c;
  font-size: 16px;
  font-weight: bold;
  width: 31%;
  text-align: center;
  border: 2px solid #33c3c7;
  padding: 0.7rem 0;
  border-radius: 8px;
  box-shadow: #3c3c3c 3px 2px 4px 0;
  margin-bottom: 1rem;
  max-width: 145px;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ div.divHide___1HU9N {
  background: transparent !important;
  border: transparent !important;
  box-shadow: none !important;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ span {
  font-family: NotoSansJP-Regular;
  color: #7b7b7b;
  font-size: 16px;
}
.calendarBookingTemplate___3VLIm .calendarForm___2agha .dataUndefined___381Sg {
  text-align: center;
  padding: 0.5rem 0;
}
.calendarBookingTemplate___3VLIm .commonTitle___2Xa-T {
  display: flex;
  margin-bottom: 1rem;
}
.calendarBookingTemplate___3VLIm .commonTitle___2Xa-T div:first-child {
  background: #3c3c3c;
  height: 1.2rem;
  width: 0.35rem;
  margin: auto 0;
}
.calendarBookingTemplate___3VLIm .commonTitle___2Xa-T div:last-child {
  padding-left: 0.4rem;
}
.calendarBookingTemplate___3VLIm .commonTitle___2Xa-T div:last-child h3 {
  color: #3c3c3c;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.calendarBookingTemplate___3VLIm .commonTitle___2Xa-T div:last-child span {
  display: none;
  font-size: 14px;
}
.calendarBookingTemplate___3VLIm .squareTitle___l2eaR {
  display: flex;
  margin-bottom: 1rem;
}
.calendarBookingTemplate___3VLIm .squareTitle___l2eaR div:first-child {
  background: #3c3c3c;
  height: 1.2rem;
  width: 0.35rem;
  margin: auto 0;
}
.calendarBookingTemplate___3VLIm .squareTitle___l2eaR div:last-child {
  padding-left: 0.4rem;
}
.calendarBookingTemplate___3VLIm .squareTitle___l2eaR div:last-child h3 {
  color: #3c3c3c;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.calendarBookingTemplate___3VLIm .squareTitle___l2eaR div:last-child span {
  display: none;
  font-size: 14px;
}
@media screen and (max-width: 1350px) {
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ {
    padding: 0.8rem 1rem;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ div {
    font-size: 14px;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ span {
    color: #7b7b7b;
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .calendarBookingTemplate___3VLIm {
    padding: 0 1rem 1rem 1rem;
  }
}
@media screen and (max-width: 425px) {
  .calendarBookingTemplate___3VLIm {
    padding: 0 1rem 1rem 1rem;
  }
  .calendarBookingTemplate___3VLIm .title___18D6I {
    margin-bottom: 2rem;
  }
  .calendarBookingTemplate___3VLIm .title___18D6I div:first-child {
    margin: 0.1rem 0 0 0;
  }
  .calendarBookingTemplate___3VLIm .title___18D6I div:last-child h3 {
    font-weight: normal;
    margin-bottom: 0.3rem;
  }
  .calendarBookingTemplate___3VLIm .title___18D6I div:last-child span {
    display: unset;
  }
  .calendarBookingTemplate___3VLIm .title___18D6I div:nth-child(2) {
    background: #3c3c3c;
    height: 1.2rem;
    width: 0.2rem;
    margin-top: 2px;
    margin-left: 3px;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha {
    border-left: transparent;
    border-right: transparent;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ {
    padding: 0.8rem 0 1rem 1rem;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ:not(:last-child) {
    border-bottom: 1px solid #ababab;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .title___18D6I {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ {
    flex-wrap: nowrap;
    grid-gap: 1rem;
    gap: 1rem;
    overflow-x: auto;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ div {
    min-width: 26%;
    margin-bottom: 3px;
    border: 2px solid #33c3c7;
    font-weight: 600;
  }
}
@media screen and (max-width: 375px) {
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ div {
    font-size: 11px;
  }
  .calendarBookingTemplate___3VLIm .calendarForm___2agha .calenderItem___ue1gJ .listBooking____pfcQ div:not(:last-child) {
    margin-right: 0.8rem;
  }
}
