@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___13WOX {
  display: flex;
  justify-content: center;
}
button.btn___3VFbf {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3VFbf {
    padding: 10px;
  }
}
button.btn___3VFbf:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3VFbf:focus {
  outline: none;
}
.btnWhite___2kAjr.btn___3VFbf {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2kAjr.btn___3VFbf:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___FDFqv.btn___3VFbf,
.btnGreen___FDFqv.btn___3VFbf:hover,
.btnGreen___FDFqv.btn___3VFbf:active,
.btnGreen___FDFqv.btn___3VFbf:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2Qd6r {
  color: #fe0000;
}
.errorMessage___RBOLP {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3sCyo {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2D7B5 {
  display: none !important;
}
.m-auto___1l-C1 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2Ndkg {
  max-width: 120px !important;
}
.fc___3uZgZ .fc-timegrid-col___2xDEd.fc-day-today___2liws {
  background-color: #ffffff;
}
.fc___3uZgZ .fc-timegrid-slot___14OeT {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3uZgZ .fc-timegrid-slot___14OeT {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2TRfr .fc-direction-ltr___1_TvI .fc-timegrid-slot-label-frame___1XyS7 {
    font-size: 10px;
  }
  .bookingCalendar___2TRfr .fc___3uZgZ .fc-timegrid-slot-label-cushion___lZk5H {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2TRfr .fc___3uZgZ .fc-scrollgrid-section-header___33gTe .fc-scroller___3WkD7 {
    overflow: unset !important;
  }
}
.ant-message-notice___1f2BG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2VJnI {
  width: 20px;
  height: 20px;
}
.bgTransparent___1rmQs {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___s0wvs {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2_-U6 {
  position: relative;
}
.inputCustomValue___2_-U6 .ant-input___3WZAK {
  padding-right: 50px;
}
.inputCustomValueText___9fxo_ {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___22DOc .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___22DOc .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___19FUX .ant-radio-inner___3GAqy {
  border-color: black !important ;
}
.ant-radio-checked___19FUX .ant-radio-inner___3GAqy:after {
  background-color: black;
}
.ant-radio___owhAa:hover .ant-radio-inner___3GAqy {
  border-color: black;
}
.ant-radio-checked___19FUX .ant-radio-inner___3GAqy:focus {
  border-color: black;
}
.modal___3YT6K {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3YT6K .close___1zQkN {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3YT6K .header___2MgGh {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3YT6K .header___2MgGh p {
  margin: 0;
}
.modal___3YT6K .content___3MYgG {
  text-align: center;
  font-size: 14px;
}
.modal___3YT6K .content___3MYgG p {
  margin: 0;
}
.modal___3YT6K .actions___2oYK- {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3YT6K .actions___2oYK- .button___2_yZh {
  margin-top: 10px;
}
.modal___3YT6K .actions___2oYK-:hover {
  color: #33c3c7;
}
.fc___3uZgZ .fc-timegrid-slot-minor___26u94 {
  border-top-style: none;
}
.fc___3uZgZ .fc-timegrid-slot___14OeT {
  height: 1.5em !important;
}
.messageError___CoWLL .ant-message-custom-content___3eP3p {
  display: flex;
  text-align: start;
}
.messageError___CoWLL .ant-message-custom-content___3eP3p span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___H3s3V {
  background-color: #e6e4e4;
}
.notifyMessage___Zx4Iy .ant-message-notice-content___3vB01 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___Zx4Iy .ant-message-notice-content___3vB01 .anticon___1q_QL {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___Zx4Iy .ant-message-notice-content___3vB01 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2SPyo {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2SPyo .ant-notification-notice-message___3_Bio,
.notifyCustomize___2SPyo .ant-notification-notice-close___1oSWk,
.notifyCustomize___2SPyo .ant-notification-notice-icon___GmOB0 {
  color: white;
}
.notifyCustomize___2SPyo .ant-notification-notice-message___3_Bio {
  margin-left: 35px;
}
.notifyCustomize___2SPyo p {
  margin-bottom: 0;
}
.notifyCustomize___2SPyo p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___RMLxV {
  padding: 16px 14px;
}
.notifyAlertDuplicate___RMLxV .ant-notification-notice-message___3_Bio {
  margin-left: 35px;
}
.notifyAlertDuplicate___RMLxV p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___RMLxV p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2UP8E {
  width: 434px !important;
}
.handleSubmitVote___2UP8E .ant-btn-primary___2OlqQ {
  background: #59c3c7;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf .ant-modal-confirm-body___1kVp_ {
  display: flex !important;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf .ant-modal-confirm-body___1kVp_ .anticon___1q_QL {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf .ant-modal-confirm-btns___3MX8S button {
  font-weight: bold;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf .ant-modal-confirm-btns___3MX8S button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf .ant-modal-confirm-btns___3MX8S button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2UP8E .ant-modal-body___2JGYf .ant-modal-confirm-btns___3MX8S .ant-btn-primary___2OlqQ {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1NC91 {
  margin-bottom: 0;
}
.mt-0___3sGmS {
  margin-top: 0;
}
.tooltipFormat___219jf p {
  margin-bottom: 0;
}
.bgWhite___3g0QS {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___M3TX5 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___M3TX5:hover,
.bgPrimaryBlue___M3TX5:focus,
.bgPrimaryBlue___M3TX5:active {
  background: #4f7ac7;
}
.bgDarkBlue___1mC2c {
  background-color: #023768 !important;
}
.bgDarkBlue___1mC2c:hover,
.bgDarkBlue___1mC2c:focus,
.bgDarkBlue___1mC2c:active {
  background: #194d7d;
}
.bgGreyBlue___zW2os {
  background-color: #ebf0f9;
}
.bgLightBlue___iWwz5 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___-RPKC {
  background-color: #e4453a;
}
.bgLightRed___-RPKC:hover,
.bgLightRed___-RPKC:focus,
.bgLightRed___-RPKC:active {
  background: #e17871;
}
.bgLightYellow___35Z-e {
  background-color: #ffd603;
}
.bgDarkGray___278UC {
  background-color: #333333;
}
.bgMediumGray___gLNUP {
  background-color: #a2a2a2;
}
.bgLightGray___FNv0_ {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3LVHK {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2GTRI {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3CyjD {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1s1VY {
  color: #3368c7 !important;
}
.textDarkBlue___2ro6Q {
  color: #023768 !important;
}
.textLightBlue___2KnQw {
  color: #b2cbf7;
}
.textDarkGray___2MYOm {
  color: #333333 !important;
}
.textMediumGray___LMAkR {
  color: #a2a2a2 !important;
}
.textLightGray___1sqB2 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___IiKeG {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1nkmd {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1YVxB {
  border-radius: 5px !important;
}
.rounded-xs___cR43f {
  border-radius: 10px !important;
}
.rounded-sm___2tbWF {
  border-radius: 15px !important;
}
.rounded-md___2Wx-d {
  border-radius: 20px !important;
}
.rounded-lg___PC7g3 {
  border-radius: 25px !important;
}
.rounded-full___2ZUtG {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2wpyj {
  margin: 0;
}
.mt-2___3cnsJ {
  margin-top: 0.5rem;
}
.my-2___2eTJ2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2k4qA {
  padding: 0;
}
.px-1___3R4df {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___38kxi {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2Ps1g {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3oqn5 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3IW17 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___Bp-YN {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___UF6Tw {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___S_rKB {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1Ldo0 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1nYJl {
  padding-bottom: 20px;
}
.h-full___21Tl7 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___24uGI {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___RkqvR {
  font-weight: bold !important;
}
.fontWeight400___3NJ7f {
  font-weight: 400 !important;
}
.fontWeight500___1zqJI {
  font-weight: 500 !important;
}
.fontWeight600___1o61o {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2C77I {
  font-size: 12px !important;
}
.fontSize14___3BXMj {
  font-size: 14px !important;
}
.fontSize16___BRGu2 {
  font-size: 16px !important;
}
.fontSize18___2dC0z {
  font-size: 18px !important;
}
.fontSize20___1conL {
  font-size: 20px !important;
}
.fontSize24___2obrX {
  font-size: 24px !important;
}
.eventName___3Q4kc {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___4Ujpt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1jOpq > tr > th,
.ant-table-tbody___undVi > tr.ant-table-row___2qC7p > td,
.ant-table-summary___vkvQ_ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1jOpq > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2E-6m .ant-table-thead___1jOpq > tr > th,
.table-portrait___2E-6m .ant-table-tbody___undVi > tr.ant-table-row___2qC7p > td,
.table-portrait___2E-6m .ant-table-summary___vkvQ_ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2E-6m .bgLightGray___FNv0_ td {
  background: #ebebeb;
}
.table-portrait___2E-6m .bgLightBlue___iWwz5 td {
  background: #b2cbf7;
}
.eventContent___3u11d .timeText___13R0A {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___undVi > tr.bgLightGray___FNv0_:hover > td,
.ant-table-tbody___undVi > tr > td.ant-table-cell-row-hover___2dy63 {
  background: #ebebeb;
}
.ant-table-tbody___undVi > tr.bgLightBlue___iWwz5:hover > td,
.ant-table-tbody___undVi > tr > td.ant-table-cell-row-hover___2dy63 {
  background: #b2cbf7;
}
.ant-table-tbody___undVi > tr.bgWhite___3g0QS:hover > td,
.ant-table-tbody___undVi > tr > td.ant-table-cell-row-hover___2dy63 {
  background: #ffffff;
}
.signup___3GCTj {
  max-width: 1080px;
  margin: auto;
  padding: 36px 44px 180px;
}
.signup___3GCTj .eventTitle___1zxTn {
  color: #3c3c3c;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.signup___3GCTj .eventTitle___1zxTn .titleIcon___1hWiv {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.signup___3GCTj .eventTitle___1zxTn .titleIcon___1hWiv .bolderColIcon___65wPB {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 13px;
  margin-right: 5px;
}
.signup___3GCTj .eventTitle___1zxTn .titleIcon___1hWiv .normalColIcon___1iGkZ {
  display: inline-block;
  background-color: #33c3c7;
  height: 52px;
  width: 5px;
}
.signup___3GCTj .eventTitle___1zxTn h2 {
  font-size: 45px;
  margin-bottom: 0;
}
.signup___3GCTj .signupHeader___UwAt0 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;
}
.signup___3GCTj .signupHeaderRightGreen___2wiWv {
  background: #33c3c7;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 31px;
  color: #fff;
  font-weight: 700;
  padding: 6px 30px 6px 18px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 8px;
}
.signup___3GCTj .signupHeaderRightGreen___2wiWv::before {
  content: '';
  position: absolute;
  left: -45px;
  width: 0;
  height: 0;
  top: 0px;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 45px solid #33c3c7;
}
.signup___3GCTj .signupHeaderRightText___1pe1Q {
  text-align: right;
  margin-right: 30px;
  font-size: 16px;
  margin-top: 7px;
}
.signup___3GCTj .signupOtherText___2F-g4 {
  font-size: 26px;
  padding-bottom: 80px;
  max-width: 730px;
  margin: auto;
}
.signup___3GCTj .labelCheckbox___1qQp9 {
  font-size: 25px;
  position: relative;
  letter-spacing: 1px;
}
.signup___3GCTj .labelCheckbox___1qQp9 a {
  color: #33c3c7;
  text-decoration: underline;
}
.signup___3GCTj .signupCheckbox___3gP6A {
  text-align: center;
  margin-bottom: 40px;
}
.signup___3GCTj .btnGoogle___3IejH {
  background: #daeeff;
}
.signup___3GCTj .btnOther___3BTdm {
  font-size: 25px;
  width: 100%;
  max-width: 460px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 0 auto 30px;
  box-shadow: 6px 6px 3px -3px #ccc;
}
.signup___3GCTj .btnOther___3BTdm img {
  margin-right: 48px;
}
.signup___3GCTj .btnOffice___33bfs {
  background: #eb3c03;
  color: #fff;
}
.signup___3GCTj .signupLinkOther___GmwuE {
  margin-top: 100px;
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
  color: #353535;
}
.signup___3GCTj .btnLogin___2VYF9 {
  font-size: 22px;
  color: #fff;
  background: #d8d8d8;
  max-width: 460px;
  display: block;
  text-align: center;
  margin: auto;
  border-radius: 20px;
  padding: 5px 20px;
  width: 100%;
  height: auto;
  font-weight: bold;
}
.signup___3GCTj .signupCheckboxItem___nLMuO {
  display: flex;
  align-items: center;
}
.signup___3GCTj .ant-checkbox-input,
.signup___3GCTj .ant-checkbox-inner {
  display: none;
}
.signup___3GCTj .ant-checkbox-checked::after {
  display: none;
}
.signup___3GCTj .ant-checkbox::before {
  content: '';
  width: 28px;
  height: 28px;
  position: absolute;
  left: -53px;
  border-radius: 9px;
  border: 3px solid #7f7f7f;
  top: -12px;
}
.signup___3GCTj .ant-checkbox.ant-checkbox-checked::before {
  background: url(./static/icon-checked.3d17b49b.svg) center no-repeat;
  background-size: 70% 70%;
}
