@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___Rtj_0 {
  display: flex;
  justify-content: center;
}
button.btn___183WV {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___183WV {
    padding: 10px;
  }
}
button.btn___183WV:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___183WV:focus {
  outline: none;
}
.btnWhite___wFyWy.btn___183WV {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___wFyWy.btn___183WV:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2xv02.btn___183WV,
.btnGreen___2xv02.btn___183WV:hover,
.btnGreen___2xv02.btn___183WV:active,
.btnGreen___2xv02.btn___183WV:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1vA-W {
  color: #fe0000;
}
.errorMessage___2ez9U {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2E9Ai {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2Cm6o {
  display: none !important;
}
.m-auto___1H0mV {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___-1OJB {
  max-width: 120px !important;
}
.fc___3Ftli .fc-timegrid-col___1oede.fc-day-today___2E8G1 {
  background-color: #ffffff;
}
.fc___3Ftli .fc-timegrid-slot___1gJ4Z {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3Ftli .fc-timegrid-slot___1gJ4Z {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3T56h .fc-direction-ltr___3SvYw .fc-timegrid-slot-label-frame___2ixOo {
    font-size: 10px;
  }
  .bookingCalendar___3T56h .fc___3Ftli .fc-timegrid-slot-label-cushion___3ECy- {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3T56h .fc___3Ftli .fc-scrollgrid-section-header___B5rEe .fc-scroller___2ymDg {
    overflow: unset !important;
  }
}
.ant-message-notice___5zdfb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2-U5f {
  width: 20px;
  height: 20px;
}
.bgTransparent___8ezQ_ {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___vR0i0 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3P6TA {
  position: relative;
}
.inputCustomValue___3P6TA .ant-input___mPuUU {
  padding-right: 50px;
}
.inputCustomValueText___e7ZqY {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___LJW9i .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___LJW9i .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2rqHg .ant-radio-inner___2v13d {
  border-color: black !important ;
}
.ant-radio-checked___2rqHg .ant-radio-inner___2v13d:after {
  background-color: black;
}
.ant-radio___9BX6p:hover .ant-radio-inner___2v13d {
  border-color: black;
}
.ant-radio-checked___2rqHg .ant-radio-inner___2v13d:focus {
  border-color: black;
}
.modal___3l5xm {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3l5xm .close___2h_ki {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3l5xm .header___17Nz1 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3l5xm .header___17Nz1 p {
  margin: 0;
}
.modal___3l5xm .content___26f4M {
  text-align: center;
  font-size: 14px;
}
.modal___3l5xm .content___26f4M p {
  margin: 0;
}
.modal___3l5xm .actions___12Cvt {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3l5xm .actions___12Cvt .button___1kAlJ {
  margin-top: 10px;
}
.modal___3l5xm .actions___12Cvt:hover {
  color: #33c3c7;
}
.fc___3Ftli .fc-timegrid-slot-minor___1YK5r {
  border-top-style: none;
}
.fc___3Ftli .fc-timegrid-slot___1gJ4Z {
  height: 1.5em !important;
}
.messageError___eWzdo .ant-message-custom-content___1s5tB {
  display: flex;
  text-align: start;
}
.messageError___eWzdo .ant-message-custom-content___1s5tB span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___Ci-5X {
  background-color: #e6e4e4;
}
.notifyMessage___1iYi2 .ant-message-notice-content___UPl8W {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1iYi2 .ant-message-notice-content___UPl8W .anticon___UUipw {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1iYi2 .ant-message-notice-content___UPl8W span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___jwDr8 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___jwDr8 .ant-notification-notice-message___2c8mG,
.notifyCustomize___jwDr8 .ant-notification-notice-close___2SIr0,
.notifyCustomize___jwDr8 .ant-notification-notice-icon___2oawa {
  color: white;
}
.notifyCustomize___jwDr8 .ant-notification-notice-message___2c8mG {
  margin-left: 35px;
}
.notifyCustomize___jwDr8 p {
  margin-bottom: 0;
}
.notifyCustomize___jwDr8 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3Zz5B {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3Zz5B .ant-notification-notice-message___2c8mG {
  margin-left: 35px;
}
.notifyAlertDuplicate___3Zz5B p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3Zz5B p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___11MsU {
  width: 434px !important;
}
.handleSubmitVote___11MsU .ant-btn-primary___2nbxn {
  background: #59c3c7;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU {
  padding: 15px 30px 18px;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU .ant-modal-confirm-body___2rQvP {
  display: flex !important;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU .ant-modal-confirm-body___2rQvP .anticon___UUipw {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU .ant-modal-confirm-btns___18r01 button {
  font-weight: bold;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU .ant-modal-confirm-btns___18r01 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU .ant-modal-confirm-btns___18r01 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___11MsU .ant-modal-body___Np_fU .ant-modal-confirm-btns___18r01 .ant-btn-primary___2nbxn {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___16QnP {
  margin-bottom: 0;
}
.mt-0___26kF3 {
  margin-top: 0;
}
.tooltipFormat___1kVmK p {
  margin-bottom: 0;
}
.bgWhite___1vE88 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2QYA0 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2QYA0:hover,
.bgPrimaryBlue___2QYA0:focus,
.bgPrimaryBlue___2QYA0:active {
  background: #4f7ac7;
}
.bgDarkBlue___1zx4_ {
  background-color: #023768 !important;
}
.bgDarkBlue___1zx4_:hover,
.bgDarkBlue___1zx4_:focus,
.bgDarkBlue___1zx4_:active {
  background: #194d7d;
}
.bgGreyBlue___CyPcR {
  background-color: #ebf0f9;
}
.bgLightBlue___3p0b- {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1cyAf {
  background-color: #e4453a;
}
.bgLightRed___1cyAf:hover,
.bgLightRed___1cyAf:focus,
.bgLightRed___1cyAf:active {
  background: #e17871;
}
.bgLightYellow___1i2Ea {
  background-color: #ffd603;
}
.bgDarkGray___2MY-9 {
  background-color: #333333;
}
.bgMediumGray___3IJYz {
  background-color: #a2a2a2;
}
.bgLightGray___3C2uz {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___GKGJr {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2uQ7c {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1ggcL {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3-qTZ {
  color: #3368c7 !important;
}
.textDarkBlue___3g4jQ {
  color: #023768 !important;
}
.textLightBlue___39Cev {
  color: #b2cbf7;
}
.textDarkGray___1eoaf {
  color: #333333 !important;
}
.textMediumGray___vkA-T {
  color: #a2a2a2 !important;
}
.textLightGray___1ZsMu {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___m8Gy6 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3tTch {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___q0sqI {
  border-radius: 5px !important;
}
.rounded-xs___2yHml {
  border-radius: 10px !important;
}
.rounded-sm___2O2b- {
  border-radius: 15px !important;
}
.rounded-md___1sEPH {
  border-radius: 20px !important;
}
.rounded-lg___2Es8X {
  border-radius: 25px !important;
}
.rounded-full___lis8A {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3Q-Ew {
  margin: 0;
}
.mt-2___2A8SY {
  margin-top: 0.5rem;
}
.my-2___TdEpE {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2sn5U {
  padding: 0;
}
.px-1___1ZUrV {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___30uvB {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___sBWaY {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3T_Wk {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1N9Np {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___11sei {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___HCdhx {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2S5JP {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___AsZ5k {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3ewKO {
  padding-bottom: 20px;
}
.h-full___3DBSW {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2OSdH {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3F2Hl {
  font-weight: bold !important;
}
.fontWeight400___2i4Yk {
  font-weight: 400 !important;
}
.fontWeight500___beSQv {
  font-weight: 500 !important;
}
.fontWeight600___2pGh6 {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3FY8N {
  font-size: 12px !important;
}
.fontSize14___FODmS {
  font-size: 14px !important;
}
.fontSize16___f7ta8 {
  font-size: 16px !important;
}
.fontSize18___1Znja {
  font-size: 18px !important;
}
.fontSize20___1AphK {
  font-size: 20px !important;
}
.fontSize24___2hOvF {
  font-size: 24px !important;
}
.eventName___wJPvq {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2PDuj {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1ZzQR > tr > th,
.ant-table-tbody___1YFF3 > tr.ant-table-row___27rPH > td,
.ant-table-summary___1AiHx tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1ZzQR > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3H1Vx .ant-table-thead___1ZzQR > tr > th,
.table-portrait___3H1Vx .ant-table-tbody___1YFF3 > tr.ant-table-row___27rPH > td,
.table-portrait___3H1Vx .ant-table-summary___1AiHx tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3H1Vx .bgLightGray___3C2uz td {
  background: #ebebeb;
}
.table-portrait___3H1Vx .bgLightBlue___3p0b- td {
  background: #b2cbf7;
}
.eventContent___307Ri .timeText___3bqBr {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1YFF3 > tr.bgLightGray___3C2uz:hover > td,
.ant-table-tbody___1YFF3 > tr > td.ant-table-cell-row-hover___39DHS {
  background: #ebebeb;
}
.ant-table-tbody___1YFF3 > tr.bgLightBlue___3p0b-:hover > td,
.ant-table-tbody___1YFF3 > tr > td.ant-table-cell-row-hover___39DHS {
  background: #b2cbf7;
}
.ant-table-tbody___1YFF3 > tr.bgWhite___1vE88:hover > td,
.ant-table-tbody___1YFF3 > tr > td.ant-table-cell-row-hover___39DHS {
  background: #ffffff;
}
.forgotPassword___2VA1x {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 80px;
}
.forgotPassword___2VA1x h2 {
  font-size: 30px;
  text-align: center;
  padding: 100px 0 700px 0;
}
.forgotPassword___2VA1x .bodyContent___28xUR {
  padding: 30px 0 127px;
}
.forgotPassword___2VA1x .bodyContent___28xUR p {
  font-size: 24px;
  text-indent: 37px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___2VA1x .bodyContent___28xUR p {
    text-indent: 0;
  }
}
.forgotPassword___2VA1x .bodyContent___28xUR .headTitle___3TSN1 {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___2VA1x .bodyContent___28xUR .headTitle___3TSN1 {
    margin-bottom: 20px;
  }
}
.forgotPassword___2VA1x .bodyContent___28xUR .headTitle___3TSN1 .bolderIcon___1qNVX {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.forgotPassword___2VA1x .bodyContent___28xUR .headTitle___3TSN1 .titleIcon___2XJid {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.forgotPassword___2VA1x .bodyContent___28xUR .headTitle___3TSN1 span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___2VA1x .bodyContent___28xUR .headTitle___3TSN1 span {
    font-size: 35px;
  }
}
.forgotPassword___2VA1x .bodyContent___28xUR .intruction___3Dhhg {
  font-size: 18px;
}
.forgotPassword___2VA1x .bodyContent___28xUR .inputField___2BfSu {
  width: 100%;
  margin: auto;
}
.forgotPassword___2VA1x .bodyContent___28xUR .inputField___2BfSu span {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.forgotPassword___2VA1x .bodyContent___28xUR .inputField___2BfSu input {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 0;
}
.forgotPassword___2VA1x .bodyContent___28xUR .inputField___2BfSu .errorNotice___9dkyL {
  color: #ff4d4f;
  font-size: 14px;
}
.forgotPassword___2VA1x .bodyContent___28xUR .btnZone___3FdRT {
  margin-top: 20px;
  margin-bottom: 25px;
}
.forgotPassword___2VA1x .bodyContent___28xUR .btnZone___3FdRT .signUpBtn___3oDYo {
  width: 100%;
  height: 40px;
  background-color: #023768;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.forgotPassword___2VA1x .bodyContent___28xUR .btnZone___3FdRT .signUpBtn___3oDYo:hover {
  background-color: #1f4f96;
}
.forgotPassword___2VA1x .ant-form-item {
  margin: 0;
}
.forgotPassword___2VA1x .ant-form {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
