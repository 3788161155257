.loginMSTeamSuccess___-MwY- {
  padding: 100px 0;
}
.loginMSTeamSuccess___-MwY- h2 {
  text-align: center;
}
.loginMSTeamSuccess___-MwY- .btnBack___1487L {
  margin-top: 30px;
  text-align: center;
  text-decoration: underline;
  display: block;
}
