@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3qeQc {
  display: flex;
  justify-content: center;
}
button.btn___2pshE {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2pshE {
    padding: 10px;
  }
}
button.btn___2pshE:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2pshE:focus {
  outline: none;
}
.btnWhite___1uUfE.btn___2pshE {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1uUfE.btn___2pshE:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___NPbys.btn___2pshE,
.btnGreen___NPbys.btn___2pshE:hover,
.btnGreen___NPbys.btn___2pshE:active,
.btnGreen___NPbys.btn___2pshE:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___35_J5 {
  color: #fe0000;
}
.errorMessage___1Sa94 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3D38- {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2PwyP {
  display: none !important;
}
.m-auto___2GULf {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___-0TVM {
  max-width: 120px !important;
}
.fc___1QSLL .fc-timegrid-col___PEX7g.fc-day-today___a05yu {
  background-color: #ffffff;
}
.fc___1QSLL .fc-timegrid-slot___-MKjl {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1QSLL .fc-timegrid-slot___-MKjl {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3GA4L .fc-direction-ltr___EwvU4 .fc-timegrid-slot-label-frame___1Wg6F {
    font-size: 10px;
  }
  .bookingCalendar___3GA4L .fc___1QSLL .fc-timegrid-slot-label-cushion___25bvq {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3GA4L .fc___1QSLL .fc-scrollgrid-section-header___3N1XU .fc-scroller___1kdEq {
    overflow: unset !important;
  }
}
.ant-message-notice___1U0Dh {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1P5yd {
  width: 20px;
  height: 20px;
}
.bgTransparent___2UvBy {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1n103 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2cQJE {
  position: relative;
}
.inputCustomValue___2cQJE .ant-input___3WX9c {
  padding-right: 50px;
}
.inputCustomValueText___3FBm0 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___S2PE- .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___S2PE- .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3UsN3 .ant-radio-inner___1m1ip {
  border-color: black !important ;
}
.ant-radio-checked___3UsN3 .ant-radio-inner___1m1ip:after {
  background-color: black;
}
.ant-radio___35opO:hover .ant-radio-inner___1m1ip {
  border-color: black;
}
.ant-radio-checked___3UsN3 .ant-radio-inner___1m1ip:focus {
  border-color: black;
}
.modal___2c64_ {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2c64_ .close___3lTPD {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2c64_ .header___2Ljdt {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2c64_ .header___2Ljdt p {
  margin: 0;
}
.modal___2c64_ .content___35TW- {
  text-align: center;
  font-size: 14px;
}
.modal___2c64_ .content___35TW- p {
  margin: 0;
}
.modal___2c64_ .actions___3RG3w {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2c64_ .actions___3RG3w .button___scJdO {
  margin-top: 10px;
}
.modal___2c64_ .actions___3RG3w:hover {
  color: #33c3c7;
}
.fc___1QSLL .fc-timegrid-slot-minor___3y836 {
  border-top-style: none;
}
.fc___1QSLL .fc-timegrid-slot___-MKjl {
  height: 1.5em !important;
}
.messageError___3Vv1v .ant-message-custom-content___3L2Kl {
  display: flex;
  text-align: start;
}
.messageError___3Vv1v .ant-message-custom-content___3L2Kl span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1A3X2 {
  background-color: #e6e4e4;
}
.notifyMessage___37xHs .ant-message-notice-content___dfsxr {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___37xHs .ant-message-notice-content___dfsxr .anticon___3s6EL {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___37xHs .ant-message-notice-content___dfsxr span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2okyk {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2okyk .ant-notification-notice-message___1WF2z,
.notifyCustomize___2okyk .ant-notification-notice-close___JP3xx,
.notifyCustomize___2okyk .ant-notification-notice-icon___1VV_7 {
  color: white;
}
.notifyCustomize___2okyk .ant-notification-notice-message___1WF2z {
  margin-left: 35px;
}
.notifyCustomize___2okyk p {
  margin-bottom: 0;
}
.notifyCustomize___2okyk p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___1vA8Y {
  padding: 16px 14px;
}
.notifyAlertDuplicate___1vA8Y .ant-notification-notice-message___1WF2z {
  margin-left: 35px;
}
.notifyAlertDuplicate___1vA8Y p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___1vA8Y p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___23mxr {
  width: 434px !important;
}
.handleSubmitVote___23mxr .ant-btn-primary___3BAX6 {
  background: #59c3c7;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul {
  padding: 15px 30px 18px;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul .ant-modal-confirm-body___nK4e9 {
  display: flex !important;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul .ant-modal-confirm-body___nK4e9 .anticon___3s6EL {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul .ant-modal-confirm-btns___AOam4 button {
  font-weight: bold;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul .ant-modal-confirm-btns___AOam4 button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul .ant-modal-confirm-btns___AOam4 button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___23mxr .ant-modal-body___zODul .ant-modal-confirm-btns___AOam4 .ant-btn-primary___3BAX6 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3haz4 {
  margin-bottom: 0;
}
.mt-0___2Yrpf {
  margin-top: 0;
}
.tooltipFormat___3r924 p {
  margin-bottom: 0;
}
.bgWhite___2T2D6 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___2wtDP {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___2wtDP:hover,
.bgPrimaryBlue___2wtDP:focus,
.bgPrimaryBlue___2wtDP:active {
  background: #4f7ac7;
}
.bgDarkBlue___-37MI {
  background-color: #023768 !important;
}
.bgDarkBlue___-37MI:hover,
.bgDarkBlue___-37MI:focus,
.bgDarkBlue___-37MI:active {
  background: #194d7d;
}
.bgGreyBlue___wcRwI {
  background-color: #ebf0f9;
}
.bgLightBlue___2D7sn {
  background-color: #b2cbf7 !important;
}
.bgLightRed___19YF1 {
  background-color: #e4453a;
}
.bgLightRed___19YF1:hover,
.bgLightRed___19YF1:focus,
.bgLightRed___19YF1:active {
  background: #e17871;
}
.bgLightYellow___2SQIP {
  background-color: #ffd603;
}
.bgDarkGray___3iFu4 {
  background-color: #333333;
}
.bgMediumGray___1HCVB {
  background-color: #a2a2a2;
}
.bgLightGray___22kkY {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___14k_P {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___39e7H {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___27zsG {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2iBWw {
  color: #3368c7 !important;
}
.textDarkBlue___2tLGN {
  color: #023768 !important;
}
.textLightBlue___1Cv_l {
  color: #b2cbf7;
}
.textDarkGray___3MCPn {
  color: #333333 !important;
}
.textMediumGray___141il {
  color: #a2a2a2 !important;
}
.textLightGray___1XjlZ {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___hzW47 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1CqyC {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___QDzii {
  border-radius: 5px !important;
}
.rounded-xs___1dhsQ {
  border-radius: 10px !important;
}
.rounded-sm___3zIpb {
  border-radius: 15px !important;
}
.rounded-md___3iD7n {
  border-radius: 20px !important;
}
.rounded-lg___3fkmn {
  border-radius: 25px !important;
}
.rounded-full___aImok {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3rBkj {
  margin: 0;
}
.mt-2___QPGd2 {
  margin-top: 0.5rem;
}
.my-2___3RGzS {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2UMOc {
  padding: 0;
}
.px-1___DbTn4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2EGCR {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___lkuzW {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3JUuY {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1rgPj {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3lBFF {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___10w9S {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___DgapA {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2LAto {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2CQc5 {
  padding-bottom: 20px;
}
.h-full___3a8G- {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___wDfxo {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3Fqkq {
  font-weight: bold !important;
}
.fontWeight400___359NK {
  font-weight: 400 !important;
}
.fontWeight500___23ab9 {
  font-weight: 500 !important;
}
.fontWeight600___2KjFv {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3BUvM {
  font-size: 12px !important;
}
.fontSize14___3nakM {
  font-size: 14px !important;
}
.fontSize16___3sNJV {
  font-size: 16px !important;
}
.fontSize18___3D9nL {
  font-size: 18px !important;
}
.fontSize20___1wLfe {
  font-size: 20px !important;
}
.fontSize24___Mz7-V {
  font-size: 24px !important;
}
.eventName___TLlhG {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2x6Xs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1MEZC > tr > th,
.ant-table-tbody___n08kc > tr.ant-table-row___3VpxF > td,
.ant-table-summary___31plm tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1MEZC > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1c_oy .ant-table-thead___1MEZC > tr > th,
.table-portrait___1c_oy .ant-table-tbody___n08kc > tr.ant-table-row___3VpxF > td,
.table-portrait___1c_oy .ant-table-summary___31plm tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1c_oy .bgLightGray___22kkY td {
  background: #ebebeb;
}
.table-portrait___1c_oy .bgLightBlue___2D7sn td {
  background: #b2cbf7;
}
.eventContent___3q8-z .timeText___Z4xUa {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___n08kc > tr.bgLightGray___22kkY:hover > td,
.ant-table-tbody___n08kc > tr > td.ant-table-cell-row-hover___1KopM {
  background: #ebebeb;
}
.ant-table-tbody___n08kc > tr.bgLightBlue___2D7sn:hover > td,
.ant-table-tbody___n08kc > tr > td.ant-table-cell-row-hover___1KopM {
  background: #b2cbf7;
}
.ant-table-tbody___n08kc > tr.bgWhite___2T2D6:hover > td,
.ant-table-tbody___n08kc > tr > td.ant-table-cell-row-hover___1KopM {
  background: #ffffff;
}
.footer___1A5YB .footerContent___1xwva {
  display: flex;
  color: #989898;
  font-size: 20px;
  justify-content: center;
  letter-spacing: 2px;
}
@media screen and (max-width: 767px) {
  .footer___1A5YB .footerContent___1xwva {
    flex-wrap: wrap;
    width: 100%;
    font-size: 15px;
  }
}
.footer___1A5YB .footerContent___1xwva a {
  color: #1f4f96;
  text-decoration: underline;
}
.footer___1A5YB .footerContent___1xwva li {
  margin-right: 55px;
}
@media screen and (max-width: 767px) {
  .footer___1A5YB .footerContent___1xwva li {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
.footer___1A5YB .footerContent___1xwva li:last-child {
  margin-right: 0;
}
