@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___WqQD- {
  display: flex;
  justify-content: center;
}
button.btn___3KPKV {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3KPKV {
    padding: 10px;
  }
}
button.btn___3KPKV:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3KPKV:focus {
  outline: none;
}
.btnWhite___1JF7X.btn___3KPKV {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1JF7X.btn___3KPKV:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2Aq8H.btn___3KPKV,
.btnGreen___2Aq8H.btn___3KPKV:hover,
.btnGreen___2Aq8H.btn___3KPKV:active,
.btnGreen___2Aq8H.btn___3KPKV:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___7R7yx {
  color: #fe0000;
}
.errorMessage___3Mn-z {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2d6TZ {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1GsR1 {
  display: none !important;
}
.m-auto___3ynXZ {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width____HPfu {
  max-width: 120px !important;
}
.fc___3qZ8R .fc-timegrid-col___xGBaw.fc-day-today___98u81 {
  background-color: #ffffff;
}
.fc___3qZ8R .fc-timegrid-slot___QHUZV {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3qZ8R .fc-timegrid-slot___QHUZV {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___36dLW .fc-direction-ltr___36QyM .fc-timegrid-slot-label-frame___1J0kp {
    font-size: 10px;
  }
  .bookingCalendar___36dLW .fc___3qZ8R .fc-timegrid-slot-label-cushion___3mW1t {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___36dLW .fc___3qZ8R .fc-scrollgrid-section-header___3xr3L .fc-scroller___3QXfu {
    overflow: unset !important;
  }
}
.ant-message-notice___30iK3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___ce2-y {
  width: 20px;
  height: 20px;
}
.bgTransparent___g6HSw {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___102Ov {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2Teh3 {
  position: relative;
}
.inputCustomValue___2Teh3 .ant-input___2BrYw {
  padding-right: 50px;
}
.inputCustomValueText___1bhgp {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___1XvsU .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___1XvsU .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3pHj6 .ant-radio-inner___1eVHQ {
  border-color: black !important ;
}
.ant-radio-checked___3pHj6 .ant-radio-inner___1eVHQ:after {
  background-color: black;
}
.ant-radio___1pty4:hover .ant-radio-inner___1eVHQ {
  border-color: black;
}
.ant-radio-checked___3pHj6 .ant-radio-inner___1eVHQ:focus {
  border-color: black;
}
.modal___yXIaC {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___yXIaC .close___3QJsv {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___yXIaC .header___j697j {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___yXIaC .header___j697j p {
  margin: 0;
}
.modal___yXIaC .content___dr20f {
  text-align: center;
  font-size: 14px;
}
.modal___yXIaC .content___dr20f p {
  margin: 0;
}
.modal___yXIaC .actions___PHF8p {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___yXIaC .actions___PHF8p .button___1Q7CR {
  margin-top: 10px;
}
.modal___yXIaC .actions___PHF8p:hover {
  color: #33c3c7;
}
.fc___3qZ8R .fc-timegrid-slot-minor___3wx4j {
  border-top-style: none;
}
.fc___3qZ8R .fc-timegrid-slot___QHUZV {
  height: 1.5em !important;
}
.messageError___XI-Et .ant-message-custom-content___264MW {
  display: flex;
  text-align: start;
}
.messageError___XI-Et .ant-message-custom-content___264MW span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___1IYUM {
  background-color: #e6e4e4;
}
.notifyMessage___GR5za .ant-message-notice-content___bXssh {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___GR5za .ant-message-notice-content___bXssh .anticon___1m0sV {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___GR5za .ant-message-notice-content___bXssh span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2RaZA {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2RaZA .ant-notification-notice-message___3eXQ4,
.notifyCustomize___2RaZA .ant-notification-notice-close___22Hqw,
.notifyCustomize___2RaZA .ant-notification-notice-icon___1CbcQ {
  color: white;
}
.notifyCustomize___2RaZA .ant-notification-notice-message___3eXQ4 {
  margin-left: 35px;
}
.notifyCustomize___2RaZA p {
  margin-bottom: 0;
}
.notifyCustomize___2RaZA p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3g61D {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3g61D .ant-notification-notice-message___3eXQ4 {
  margin-left: 35px;
}
.notifyAlertDuplicate___3g61D p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3g61D p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3YwPc {
  width: 434px !important;
}
.handleSubmitVote___3YwPc .ant-btn-primary___2CdQH {
  background: #59c3c7;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT .ant-modal-confirm-body___3i0Wk {
  display: flex !important;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT .ant-modal-confirm-body___3i0Wk .anticon___1m0sV {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT .ant-modal-confirm-btns___3isZE button {
  font-weight: bold;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT .ant-modal-confirm-btns___3isZE button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT .ant-modal-confirm-btns___3isZE button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3YwPc .ant-modal-body___3r3KT .ant-modal-confirm-btns___3isZE .ant-btn-primary___2CdQH {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1E4lQ {
  margin-bottom: 0;
}
.mt-0___2mdLM {
  margin-top: 0;
}
.tooltipFormat___1BTyT p {
  margin-bottom: 0;
}
.bgWhite___12IdE {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___P23z3 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___P23z3:hover,
.bgPrimaryBlue___P23z3:focus,
.bgPrimaryBlue___P23z3:active {
  background: #4f7ac7;
}
.bgDarkBlue___2iYeD {
  background-color: #023768 !important;
}
.bgDarkBlue___2iYeD:hover,
.bgDarkBlue___2iYeD:focus,
.bgDarkBlue___2iYeD:active {
  background: #194d7d;
}
.bgGreyBlue___2thyL {
  background-color: #ebf0f9;
}
.bgLightBlue___ZILg_ {
  background-color: #b2cbf7 !important;
}
.bgLightRed___1WSan {
  background-color: #e4453a;
}
.bgLightRed___1WSan:hover,
.bgLightRed___1WSan:focus,
.bgLightRed___1WSan:active {
  background: #e17871;
}
.bgLightYellow___1VRDt {
  background-color: #ffd603;
}
.bgDarkGray___1NsE9 {
  background-color: #333333;
}
.bgMediumGray___2wUxv {
  background-color: #a2a2a2;
}
.bgLightGray___3-_IR {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___1bj-v {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___34QlG {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1mJfK {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2nI8e {
  color: #3368c7 !important;
}
.textDarkBlue___2r0vt {
  color: #023768 !important;
}
.textLightBlue___2vnPV {
  color: #b2cbf7;
}
.textDarkGray___1-Jx0 {
  color: #333333 !important;
}
.textMediumGray___6XF7Y {
  color: #a2a2a2 !important;
}
.textLightGray___BCDvb {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___27ss0 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___39gX3 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___193VV {
  border-radius: 5px !important;
}
.rounded-xs___1Xn56 {
  border-radius: 10px !important;
}
.rounded-sm___dyuN5 {
  border-radius: 15px !important;
}
.rounded-md___1c5YZ {
  border-radius: 20px !important;
}
.rounded-lg___zl7O8 {
  border-radius: 25px !important;
}
.rounded-full___1F_rX {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3tiYr {
  margin: 0;
}
.mt-2___3aloB {
  margin-top: 0.5rem;
}
.my-2___IIU9R {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___35Aa6 {
  padding: 0;
}
.px-1___sy0-f {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1HH6I {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3U1kc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___T57UC {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3vvpF {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2JOvH {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___198ul {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1-PMn {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3bLrd {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___sZV13 {
  padding-bottom: 20px;
}
.h-full___1KT6v {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2wbIp {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2hady {
  font-weight: bold !important;
}
.fontWeight400___yCLYl {
  font-weight: 400 !important;
}
.fontWeight500___3ylFz {
  font-weight: 500 !important;
}
.fontWeight600___4seFc {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___2DckQ {
  font-size: 12px !important;
}
.fontSize14___391bC {
  font-size: 14px !important;
}
.fontSize16___7K2db {
  font-size: 16px !important;
}
.fontSize18___1JkQX {
  font-size: 18px !important;
}
.fontSize20___hJFbY {
  font-size: 20px !important;
}
.fontSize24___Am05X {
  font-size: 24px !important;
}
.eventName___3-bj0 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3hEIJ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1n639 > tr > th,
.ant-table-tbody___3vmNe > tr.ant-table-row___2wvSq > td,
.ant-table-summary___g30G9 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1n639 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___2AFZL .ant-table-thead___1n639 > tr > th,
.table-portrait___2AFZL .ant-table-tbody___3vmNe > tr.ant-table-row___2wvSq > td,
.table-portrait___2AFZL .ant-table-summary___g30G9 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___2AFZL .bgLightGray___3-_IR td {
  background: #ebebeb;
}
.table-portrait___2AFZL .bgLightBlue___ZILg_ td {
  background: #b2cbf7;
}
.eventContent___2eQ1Y .timeText___2cjBx {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3vmNe > tr.bgLightGray___3-_IR:hover > td,
.ant-table-tbody___3vmNe > tr > td.ant-table-cell-row-hover___1kkFd {
  background: #ebebeb;
}
.ant-table-tbody___3vmNe > tr.bgLightBlue___ZILg_:hover > td,
.ant-table-tbody___3vmNe > tr > td.ant-table-cell-row-hover___1kkFd {
  background: #b2cbf7;
}
.ant-table-tbody___3vmNe > tr.bgWhite___12IdE:hover > td,
.ant-table-tbody___3vmNe > tr > td.ant-table-cell-row-hover___1kkFd {
  background: #ffffff;
}
.googleIconImage___2keac {
  margin-left: -5px;
  margin-right: 10px;
}
.microsoftIconImage___OggrU {
  margin-left: 1px;
  margin-right: 5px;
}
