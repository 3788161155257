.commit___2SvtR {
  background: #fbfbfb;
  border: 2px solid #ebebeb;
  border-radius: 12px;
  padding: 1rem;
  height: 300px;
  white-space: normal;
  word-break: break-all;
  position: relative;
  width: 100%;
  overflow-y: auto;
}
.commit___2SvtR .title___1Oy8G {
  color: #2f3840;
  text-align: center;
  font-size: 14px;
  padding-bottom: 1.5rem;
}
.commit___2SvtR .title___1Oy8G button {
  font-weight: bold;
  background: transparent;
  border: transparent;
  cursor: pointer;
  width: auto;
}
.commit___2SvtR .title___1Oy8G button input {
  cursor: pointer;
  background: transparent;
  border: transparent;
  outline: none;
  text-align: center;
}
.commit___2SvtR .description___2TALx {
  color: #2f3840;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.commit___2SvtR .description___2TALx textarea {
  outline: none;
  border: transparent;
  width: 100%;
  background: transparent;
  cursor: pointer;
}
.commit___2SvtR .description___2TALx p {
  margin-bottom: 0;
}
@media screen and (max-width: 425px) {
  .commit___2SvtR .description___2TALx p br {
    display: none;
  }
}
.scrollable___Xr2Lk {
  overflow-y: auto;
  max-height: 39vh;
}
