@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3TKAO {
  display: flex;
  justify-content: center;
}
button.btn___7UTK6 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___7UTK6 {
    padding: 10px;
  }
}
button.btn___7UTK6:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___7UTK6:focus {
  outline: none;
}
.btnWhite___1t0sS.btn___7UTK6 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___1t0sS.btn___7UTK6:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___zf55Y.btn___7UTK6,
.btnGreen___zf55Y.btn___7UTK6:hover,
.btnGreen___zf55Y.btn___7UTK6:active,
.btnGreen___zf55Y.btn___7UTK6:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___Wtqlv {
  color: #fe0000;
}
.errorMessage___DTQ0K {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___Co0sH {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___3EAEw {
  display: none !important;
}
.m-auto___2PZbK {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___bsxHR {
  max-width: 120px !important;
}
.fc___1FWtl .fc-timegrid-col___3-o9S.fc-day-today___1TQcG {
  background-color: #ffffff;
}
.fc___1FWtl .fc-timegrid-slot___23g9p {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1FWtl .fc-timegrid-slot___23g9p {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1V0p0 .fc-direction-ltr___1RtUu .fc-timegrid-slot-label-frame___1qit0 {
    font-size: 10px;
  }
  .bookingCalendar___1V0p0 .fc___1FWtl .fc-timegrid-slot-label-cushion___3bXNo {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1V0p0 .fc___1FWtl .fc-scrollgrid-section-header___guZiL .fc-scroller___33ysH {
    overflow: unset !important;
  }
}
.ant-message-notice___1zbZt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___24RVI {
  width: 20px;
  height: 20px;
}
.bgTransparent___BpAjx {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___Vbban {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3XnLf {
  position: relative;
}
.inputCustomValue___3XnLf .ant-input___3vtza {
  padding-right: 50px;
}
.inputCustomValueText___25HYc {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___MXAga .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___MXAga .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___y3TQR .ant-radio-inner___2fbuz {
  border-color: black !important ;
}
.ant-radio-checked___y3TQR .ant-radio-inner___2fbuz:after {
  background-color: black;
}
.ant-radio___wWzSe:hover .ant-radio-inner___2fbuz {
  border-color: black;
}
.ant-radio-checked___y3TQR .ant-radio-inner___2fbuz:focus {
  border-color: black;
}
.modal___1g2TN {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1g2TN .close___1xKdK {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1g2TN .header___3DquA {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1g2TN .header___3DquA p {
  margin: 0;
}
.modal___1g2TN .content___1lA59 {
  text-align: center;
  font-size: 14px;
}
.modal___1g2TN .content___1lA59 p {
  margin: 0;
}
.modal___1g2TN .actions___26WEZ {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1g2TN .actions___26WEZ .button___1FQ2f {
  margin-top: 10px;
}
.modal___1g2TN .actions___26WEZ:hover {
  color: #33c3c7;
}
.fc___1FWtl .fc-timegrid-slot-minor___2HY-V {
  border-top-style: none;
}
.fc___1FWtl .fc-timegrid-slot___23g9p {
  height: 1.5em !important;
}
.messageError___3rzxv .ant-message-custom-content___uY2-O {
  display: flex;
  text-align: start;
}
.messageError___3rzxv .ant-message-custom-content___uY2-O span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2eJFE {
  background-color: #e6e4e4;
}
.notifyMessage___2SB9p .ant-message-notice-content___uwdto {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2SB9p .ant-message-notice-content___uwdto .anticon___SzIHt {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2SB9p .ant-message-notice-content___uwdto span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3eIs- {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3eIs- .ant-notification-notice-message___3KCHY,
.notifyCustomize___3eIs- .ant-notification-notice-close___3BvPH,
.notifyCustomize___3eIs- .ant-notification-notice-icon___ut1MX {
  color: white;
}
.notifyCustomize___3eIs- .ant-notification-notice-message___3KCHY {
  margin-left: 35px;
}
.notifyCustomize___3eIs- p {
  margin-bottom: 0;
}
.notifyCustomize___3eIs- p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___16dtU {
  padding: 16px 14px;
}
.notifyAlertDuplicate___16dtU .ant-notification-notice-message___3KCHY {
  margin-left: 35px;
}
.notifyAlertDuplicate___16dtU p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___16dtU p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___gcnH1 {
  width: 434px !important;
}
.handleSubmitVote___gcnH1 .ant-btn-primary___LZBCB {
  background: #59c3c7;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU {
  padding: 15px 30px 18px;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU .ant-modal-confirm-body___Xyv-o {
  display: flex !important;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU .ant-modal-confirm-body___Xyv-o .anticon___SzIHt {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU .ant-modal-confirm-btns___2o9Py button {
  font-weight: bold;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU .ant-modal-confirm-btns___2o9Py button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU .ant-modal-confirm-btns___2o9Py button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___gcnH1 .ant-modal-body___2nNuU .ant-modal-confirm-btns___2o9Py .ant-btn-primary___LZBCB {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___18ehj {
  margin-bottom: 0;
}
.mt-0___2Wc4F {
  margin-top: 0;
}
.tooltipFormat___j_4oi p {
  margin-bottom: 0;
}
.bgWhite___1f2bA {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1Kv5H {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1Kv5H:hover,
.bgPrimaryBlue___1Kv5H:focus,
.bgPrimaryBlue___1Kv5H:active {
  background: #4f7ac7;
}
.bgDarkBlue___3aEYc {
  background-color: #023768 !important;
}
.bgDarkBlue___3aEYc:hover,
.bgDarkBlue___3aEYc:focus,
.bgDarkBlue___3aEYc:active {
  background: #194d7d;
}
.bgGreyBlue___2yUhg {
  background-color: #ebf0f9;
}
.bgLightBlue___309-P {
  background-color: #b2cbf7 !important;
}
.bgLightRed___32YdE {
  background-color: #e4453a;
}
.bgLightRed___32YdE:hover,
.bgLightRed___32YdE:focus,
.bgLightRed___32YdE:active {
  background: #e17871;
}
.bgLightYellow___1S70C {
  background-color: #ffd603;
}
.bgDarkGray___1as8e {
  background-color: #333333;
}
.bgMediumGray___2u_ts {
  background-color: #a2a2a2;
}
.bgLightGray___3eCWw {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2bDC6 {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1olGe {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2LWj1 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2zHLI {
  color: #3368c7 !important;
}
.textDarkBlue___2HCd5 {
  color: #023768 !important;
}
.textLightBlue___3Kmq8 {
  color: #b2cbf7;
}
.textDarkGray___1xfbK {
  color: #333333 !important;
}
.textMediumGray___3vb7K {
  color: #a2a2a2 !important;
}
.textLightGray___2T5Vf {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2LDoE {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___yl7m5 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2gKDk {
  border-radius: 5px !important;
}
.rounded-xs___unBWR {
  border-radius: 10px !important;
}
.rounded-sm___1WlSI {
  border-radius: 15px !important;
}
.rounded-md___3Rk4E {
  border-radius: 20px !important;
}
.rounded-lg___23QqU {
  border-radius: 25px !important;
}
.rounded-full___1AnG5 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___1gDcC {
  margin: 0;
}
.mt-2___2_aQ- {
  margin-top: 0.5rem;
}
.my-2___1_tk1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2xfYp {
  padding: 0;
}
.px-1___1Z_3c {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___1rKWr {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___27B_N {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___qC1ga {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3t9dU {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___BFzGo {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3mBhZ {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1NyXd {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1caIY {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___1M92q {
  padding-bottom: 20px;
}
.h-full___Iyr6h {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___AnPcP {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___15v1e {
  font-weight: bold !important;
}
.fontWeight400___YlKQh {
  font-weight: 400 !important;
}
.fontWeight500___3-5KA {
  font-weight: 500 !important;
}
.fontWeight600___3ylMO {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___yl2pO {
  font-size: 12px !important;
}
.fontSize14___1DQCA {
  font-size: 14px !important;
}
.fontSize16___3FyIy {
  font-size: 16px !important;
}
.fontSize18___2xHW2 {
  font-size: 18px !important;
}
.fontSize20___2gNFS {
  font-size: 20px !important;
}
.fontSize24___l-b9m {
  font-size: 24px !important;
}
.eventName___3WKxX {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2lij8 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1FNiO > tr > th,
.ant-table-tbody___14CeO > tr.ant-table-row___34Lkp > td,
.ant-table-summary___2sqsJ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1FNiO > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___1l8nO .ant-table-thead___1FNiO > tr > th,
.table-portrait___1l8nO .ant-table-tbody___14CeO > tr.ant-table-row___34Lkp > td,
.table-portrait___1l8nO .ant-table-summary___2sqsJ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___1l8nO .bgLightGray___3eCWw td {
  background: #ebebeb;
}
.table-portrait___1l8nO .bgLightBlue___309-P td {
  background: #b2cbf7;
}
.eventContent___2qeqa .timeText___X33U0 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___14CeO > tr.bgLightGray___3eCWw:hover > td,
.ant-table-tbody___14CeO > tr > td.ant-table-cell-row-hover___19K13 {
  background: #ebebeb;
}
.ant-table-tbody___14CeO > tr.bgLightBlue___309-P:hover > td,
.ant-table-tbody___14CeO > tr > td.ant-table-cell-row-hover___19K13 {
  background: #b2cbf7;
}
.ant-table-tbody___14CeO > tr.bgWhite___1f2bA:hover > td,
.ant-table-tbody___14CeO > tr > td.ant-table-cell-row-hover___19K13 {
  background: #ffffff;
}
.calendarCreationContainer___3DaiT {
  padding: 20px;
}
.calendarCreationContainer___3DaiT hr {
  margin: 20px 0;
}
.calendarCreationContainer___3DaiT .labelName___1t-J0 {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}
.calendarCreationContainer___3DaiT .labelName___1t-J0 .labelIcon___2rKVY {
  padding-right: 3px;
  width: 20px;
}
.calendarCreationContainer___3DaiT .inputField___2s7I0 {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
}
.calendarCreationContainer___3DaiT .selectField___26O6M {
  width: 40%;
  height: 40px;
}
.calendarCreationContainer___3DaiT .candidateContainer___3Y1wN {
  display: flex;
  align-items: center;
}
.calendarCreationContainer___3DaiT .candidateContainer___3Y1wN .candidateButton___3dGdJ {
  height: 40px;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
  margin-right: 10px;
}
.calendarCreationContainer___3DaiT .candidateContainer___3Y1wN .candidateBtnTooltip___TBhhQ {
  padding: 0;
  margin: 0;
  border: none;
}
.calendarCreationContainer___3DaiT .candidateContainer___3Y1wN img {
  margin-left: 5px;
  width: 30px;
}
.calendarCreationContainer___3DaiT .calendarTimeGroup___2HXdv {
  padding: 20px 30px 0 30px;
}
.calendarCreationContainer___3DaiT .calendarTimeGroup___2HXdv .datePickerContainer___1B3y9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.calendarCreationContainer___3DaiT .calendarTimeGroup___2HXdv .datePickerContainer___1B3y9 .datePickerItem___3PXQi {
  font-weight: 500;
  font-size: 1.2rem;
}
.calendarCreationContainer___3DaiT .calendarTimeGroup___2HXdv .calendarBtn___2uICv {
  margin-top: 15px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.calendarCreationContainer___3DaiT .calendarTimeGroup___2HXdv .calendarTime___2Uwp_ {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #3368c7;
  border: 1px solid #3368c7;
}
.calendarCreationContainer___3DaiT .collapseContainer___2fB35 {
  margin-top: 15px;
  background-color: #ffffff;
}
.calendarCreationContainer___3DaiT .collapseContainer___2fB35 .collapseExtractCandidate___3hhWc {
  padding: 10px;
}
.calendarCreationContainer___3DaiT .collapseContainer___2fB35 .collapseExtractCandidate___3hhWc .candidateHeader___3ZX8t {
  padding: 0 10px !important;
}
.calendarCreationContainer___3DaiT .collapseContainer___2fB35 .collapseExtractCandidate___3hhWc .calendarTimeContainer___PYFwi {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 10px;
}
.calendarCreationContainer___3DaiT .collapseContainer___2fB35 .collapseExtractCandidate___3hhWc .candidateItem___1T5mt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.calendarCreationContainer___3DaiT .collapseContainer___2fB35 .collapseExtractCandidate___3hhWc .candidateItem___1T5mt .candidateItemName___3zEtq {
  color: #3368c7;
  font-weight: 500;
}
.calendarCreationContainer___3DaiT .saveBtn___1vt3O {
  width: 50%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #3368c7;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 {
  border: 1px solid #7f7e7e;
  border-radius: 10px;
  padding: 10px;
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 .shareCalendarLink___1yW6W {
  color: #0f87e4;
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 .shareCalendarItem___1oDtO {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 .shareCalendarItem___1oDtO .bgBlue500___1K48n {
  background-color: #4a6bc0;
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 .shareCalendarItem___1oDtO .bgBlue700___2gcq- {
  background-color: #1f4f96;
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 .shareCalendarItem___1oDtO .shareCalendarTitle___1FGZp {
  font-weight: 500;
}
.modalContent___VHK08 .shareCalendarFrame___2U4y9 .shareCalendarItem___1oDtO .shareCalendarBtn___2_eBE {
  height: 25px;
  padding: 0 10px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  box-shadow: 0 2px 3px rgba(50, 100, 200, 0.3);
}
@media screen and (max-width: 767px) {
  .bookingCalendarParent___ihsQ3 {
    position: relative;
    overflow: scroll;
    height: 75dvh;
  }
  .bookingCalendarParent___ihsQ3 > div {
    position: absolute;
  }
}
.calendarLinkOtherDesc___21b3C {
  background-color: rgba(24, 144, 255, 0.15);
  border-radius: 20px;
  padding: 15px;
  color: #0f87e4;
}
.calendarLinkOtherDesc___21b3C .calendarLinkOtherTitle___vGBs8 {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}
.calendarLinkOtherDesc___21b3C .calendarLinkOtherTitle___vGBs8 > svg {
  fill: #0c3dc9;
  margin-right: 5px;
}
.calendarLinkOtherDesc___21b3C .calendarLinkOtherContent___HMf-2 {
  font-size: 0.9rem;
}
.calendarLinkOtherBtn___2_ElO {
  padding-top: 20px;
}
.calendarLinkOtherBtn___2_ElO .calendarLinkOtherItem___28jPw {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b3bcbc;
  background: transparent;
  padding: 5px 10px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.calendarLinkOtherBtn___2_ElO .calendarLinkOtherItem___28jPw > img {
  width: 25px;
  margin-right: 10px;
}
.calendarLinkOtherFooter___1RFxR {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendarLinkOtherFooter___1RFxR > a {
  padding: 0 10px;
  color: #0f87e4;
  text-decoration: underline;
}
.deleteTimeSelected___1Kdbt {
  width: 100%;
  border: 0;
  border-radius: 0;
  font-weight: 700;
  background-color: #eb3c03;
  color: #ffffff;
}
.ant-form-item {
  margin-bottom: 0;
}
.ant-collapse {
  border-radius: 5px !important;
}
.ant-collapse .ant-collapse-item {
  border-radius: 10px;
}
.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 10px 20px;
}
.ant-collapse .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
  height: 30vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.ant-collapse .ant-checkbox-group {
  width: 100%;
}
.ant-picker {
  width: 100%;
  border-radius: 10px;
}
.ant-picker .ant-picker-input input {
  text-align: center;
}
.ant-picker .ant-picker-input .ant-picker-suffix {
  display: none;
}
.ant-picker .ant-picker-input .ant-picker-clear {
  display: none;
}
