.colorPicker___2dsEd {
  display: flex;
  flex-flow: wrap;
  max-width: 300px;
}
.colorPicker___2dsEd .rowColor___3_WYk {
  display: flex;
}
.colorPicker___2dsEd .rowColor___3_WYk:not(:last-child) {
  margin-bottom: 0.5rem;
}
.colorPicker___2dsEd .rowColor___3_WYk .colorItem___3ie2F {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 1px solid #c5c5c5;
}
.colorPicker___2dsEd .rowColor___3_WYk .active___3Z1Xq {
  border-color: #0f87e4;
}
.searchColor___2vhQT {
  margin-top: 1rem;
  display: flex;
}
.searchColor___2vhQT div {
  width: 55%;
}
.searchColor___2vhQT div span {
  font-size: 14px;
  color: #6d6d6d;
}
.searchColor___2vhQT input {
  width: 40%;
  border: 2px solid #c5c5c5;
  outline: none;
}
.errorColor____5GMk {
  color: red;
}
