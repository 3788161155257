.smoothLogin___cGI-z {
  position: relative;
}
.smoothLogin___cGI-z .ant-tabs-nav-wrap {
  max-width: 1080px;
  width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 65px;
}
.smoothLogin___cGI-z .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 25px;
}
@media screen and (max-width: 767px) {
  .smoothLogin___cGI-z .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
  }
}
