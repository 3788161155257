@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___W5KxD {
  display: flex;
  justify-content: center;
}
button.btn___ApEOA {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___ApEOA {
    padding: 10px;
  }
}
button.btn___ApEOA:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___ApEOA:focus {
  outline: none;
}
.btnWhite___3QqQ2.btn___ApEOA {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3QqQ2.btn___ApEOA:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___29dZo.btn___ApEOA,
.btnGreen___29dZo.btn___ApEOA:hover,
.btnGreen___29dZo.btn___ApEOA:active,
.btnGreen___29dZo.btn___ApEOA:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___rnHvi {
  color: #fe0000;
}
.errorMessage___3-8PH {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___231g0 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___q8GiK {
  display: none !important;
}
.m-auto___1BXVR {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___c4Rgw {
  max-width: 120px !important;
}
.fc___3ddvz .fc-timegrid-col___3tTqJ.fc-day-today___1jyeB {
  background-color: #ffffff;
}
.fc___3ddvz .fc-timegrid-slot___3e2_z {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3ddvz .fc-timegrid-slot___3e2_z {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___YQxV4 .fc-direction-ltr___3TolQ .fc-timegrid-slot-label-frame___5AOM8 {
    font-size: 10px;
  }
  .bookingCalendar___YQxV4 .fc___3ddvz .fc-timegrid-slot-label-cushion___1aYIV {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___YQxV4 .fc___3ddvz .fc-scrollgrid-section-header___3czxf .fc-scroller___2NDa7 {
    overflow: unset !important;
  }
}
.ant-message-notice___2ECck {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3fJyk {
  width: 20px;
  height: 20px;
}
.bgTransparent___2687- {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___13hkr {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___sOLuE {
  position: relative;
}
.inputCustomValue___sOLuE .ant-input___3EbON {
  padding-right: 50px;
}
.inputCustomValueText___3talQ {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2cnZz .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2cnZz .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___38CC6 .ant-radio-inner___3-JbC {
  border-color: black !important ;
}
.ant-radio-checked___38CC6 .ant-radio-inner___3-JbC:after {
  background-color: black;
}
.ant-radio___3zQgl:hover .ant-radio-inner___3-JbC {
  border-color: black;
}
.ant-radio-checked___38CC6 .ant-radio-inner___3-JbC:focus {
  border-color: black;
}
.modal___1gJ9o {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1gJ9o .close___2yUXY {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1gJ9o .header___3YV2a {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1gJ9o .header___3YV2a p {
  margin: 0;
}
.modal___1gJ9o .content___1tq53 {
  text-align: center;
  font-size: 14px;
}
.modal___1gJ9o .content___1tq53 p {
  margin: 0;
}
.modal___1gJ9o .actions___1nHKq {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1gJ9o .actions___1nHKq .button___JBOlG {
  margin-top: 10px;
}
.modal___1gJ9o .actions___1nHKq:hover {
  color: #33c3c7;
}
.fc___3ddvz .fc-timegrid-slot-minor___18YOV {
  border-top-style: none;
}
.fc___3ddvz .fc-timegrid-slot___3e2_z {
  height: 1.5em !important;
}
.messageError___2b8lU .ant-message-custom-content___2jmM7 {
  display: flex;
  text-align: start;
}
.messageError___2b8lU .ant-message-custom-content___2jmM7 span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3syBA {
  background-color: #e6e4e4;
}
.notifyMessage___3xcVp .ant-message-notice-content___1ynXZ {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3xcVp .ant-message-notice-content___1ynXZ .anticon___1w-kB {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3xcVp .ant-message-notice-content___1ynXZ span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3ozy7 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3ozy7 .ant-notification-notice-message___yM4Ih,
.notifyCustomize___3ozy7 .ant-notification-notice-close___1kK2I,
.notifyCustomize___3ozy7 .ant-notification-notice-icon___3lPYF {
  color: white;
}
.notifyCustomize___3ozy7 .ant-notification-notice-message___yM4Ih {
  margin-left: 35px;
}
.notifyCustomize___3ozy7 p {
  margin-bottom: 0;
}
.notifyCustomize___3ozy7 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___LcPYb {
  padding: 16px 14px;
}
.notifyAlertDuplicate___LcPYb .ant-notification-notice-message___yM4Ih {
  margin-left: 35px;
}
.notifyAlertDuplicate___LcPYb p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___LcPYb p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3K8_G {
  width: 434px !important;
}
.handleSubmitVote___3K8_G .ant-btn-primary___3ICG9 {
  background: #59c3c7;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q .ant-modal-confirm-body___2GNrn {
  display: flex !important;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q .ant-modal-confirm-body___2GNrn .anticon___1w-kB {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q .ant-modal-confirm-btns___3RpHD button {
  font-weight: bold;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q .ant-modal-confirm-btns___3RpHD button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q .ant-modal-confirm-btns___3RpHD button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3K8_G .ant-modal-body___Hv52Q .ant-modal-confirm-btns___3RpHD .ant-btn-primary___3ICG9 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___hKaCU {
  margin-bottom: 0;
}
.mt-0___2-5gN {
  margin-top: 0;
}
.tooltipFormat___2Ll21 p {
  margin-bottom: 0;
}
.bgWhite___3Z0WQ {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___15zjo {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___15zjo:hover,
.bgPrimaryBlue___15zjo:focus,
.bgPrimaryBlue___15zjo:active {
  background: #4f7ac7;
}
.bgDarkBlue___1lxl3 {
  background-color: #023768 !important;
}
.bgDarkBlue___1lxl3:hover,
.bgDarkBlue___1lxl3:focus,
.bgDarkBlue___1lxl3:active {
  background: #194d7d;
}
.bgGreyBlue___1Zzij {
  background-color: #ebf0f9;
}
.bgLightBlue___1kfcz {
  background-color: #b2cbf7 !important;
}
.bgLightRed___SteB7 {
  background-color: #e4453a;
}
.bgLightRed___SteB7:hover,
.bgLightRed___SteB7:focus,
.bgLightRed___SteB7:active {
  background: #e17871;
}
.bgLightYellow___3PNaQ {
  background-color: #ffd603;
}
.bgDarkGray___3CJ0O {
  background-color: #333333;
}
.bgMediumGray___2reyb {
  background-color: #a2a2a2;
}
.bgLightGray___1X4Mt {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2i13V {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2URk_ {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___43VIp {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___26ElZ {
  color: #3368c7 !important;
}
.textDarkBlue___tAp4F {
  color: #023768 !important;
}
.textLightBlue___1BHZU {
  color: #b2cbf7;
}
.textDarkGray___1Z1Ij {
  color: #333333 !important;
}
.textMediumGray___3fAul {
  color: #a2a2a2 !important;
}
.textLightGray___mhQaX {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3_0-N {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___18Z0T {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3KITb {
  border-radius: 5px !important;
}
.rounded-xs___2iIgc {
  border-radius: 10px !important;
}
.rounded-sm___btsaV {
  border-radius: 15px !important;
}
.rounded-md___3i7iC {
  border-radius: 20px !important;
}
.rounded-lg___yyslB {
  border-radius: 25px !important;
}
.rounded-full___2TZP4 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___h23xX {
  margin: 0;
}
.mt-2___2aO7S {
  margin-top: 0.5rem;
}
.my-2___1_zCS {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3y6vg {
  padding: 0;
}
.px-1___MJJRb {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2Hh_W {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1sx_w {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2s9vm {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___17S0O {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___1WRah {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___b-ywt {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3DHt4 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3hm8c {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___Smq1G {
  padding-bottom: 20px;
}
.h-full___G8t7S {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2um-U {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___ioH79 {
  font-weight: bold !important;
}
.fontWeight400___1p3of {
  font-weight: 400 !important;
}
.fontWeight500___3_w2d {
  font-weight: 500 !important;
}
.fontWeight600___UB8ci {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___UB2Fg {
  font-size: 12px !important;
}
.fontSize14___3ZIg9 {
  font-size: 14px !important;
}
.fontSize16___10ICv {
  font-size: 16px !important;
}
.fontSize18___1fh8H {
  font-size: 18px !important;
}
.fontSize20___cWxH5 {
  font-size: 20px !important;
}
.fontSize24___2F_os {
  font-size: 24px !important;
}
.eventName___1qKKq {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1-O_t {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___ea3jI > tr > th,
.ant-table-tbody___2RGeS > tr.ant-table-row___wqwFV > td,
.ant-table-summary___3B611 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___ea3jI > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___30lTz .ant-table-thead___ea3jI > tr > th,
.table-portrait___30lTz .ant-table-tbody___2RGeS > tr.ant-table-row___wqwFV > td,
.table-portrait___30lTz .ant-table-summary___3B611 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___30lTz .bgLightGray___1X4Mt td {
  background: #ebebeb;
}
.table-portrait___30lTz .bgLightBlue___1kfcz td {
  background: #b2cbf7;
}
.eventContent___1QIrD .timeText___-Th79 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2RGeS > tr.bgLightGray___1X4Mt:hover > td,
.ant-table-tbody___2RGeS > tr > td.ant-table-cell-row-hover___2Z7V5 {
  background: #ebebeb;
}
.ant-table-tbody___2RGeS > tr.bgLightBlue___1kfcz:hover > td,
.ant-table-tbody___2RGeS > tr > td.ant-table-cell-row-hover___2Z7V5 {
  background: #b2cbf7;
}
.ant-table-tbody___2RGeS > tr.bgWhite___3Z0WQ:hover > td,
.ant-table-tbody___2RGeS > tr > td.ant-table-cell-row-hover___2Z7V5 {
  background: #ffffff;
}
.accountStatus___1Lk3f {
  max-width: 1080px;
  margin: auto;
  padding: 45px 15px 0 15px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f {
    padding: 15px 15px 0 15px;
  }
}
.accountStatus___1Lk3f .headTitle___3tORC {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .headTitle___3tORC {
    margin-bottom: 20px;
  }
}
.accountStatus___1Lk3f .headTitle___3tORC .bolderIcon___1Q88K {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.accountStatus___1Lk3f .headTitle___3tORC .titleIcon___1IgOs {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.accountStatus___1Lk3f .headTitle___3tORC span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .headTitle___3tORC span {
    font-size: 20px;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .accountData___1kiXs {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs .largerDataBadge___3U04W {
  padding: 32px 20px 23px 20px !important;
}
.accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga {
  height: 252px;
  width: 326px;
  box-shadow: 1px 1px 15px #b3bcbc60;
  border-radius: 10px;
  padding: 32px 25px 23px 25px;
  text-align: center;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga {
    margin-bottom: 20px;
    height: auto;
    padding: 15px 26px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga {
    min-width: unset;
    padding: 32px 15px 23px;
    font-size: 16px;
    margin-right: 15px;
    flex-basis: calc((100% / 3) - 10px);
  }
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga:last-child {
    margin-right: 0;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga .deviderLine___2dfjL {
  height: 2px;
  width: 100%;
  background-color: #b6b6b6;
  margin: 20px 0 45px 0;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga .deviderLine___2dfjL {
    margin: 8px 0 10px 0;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga .amount___MrQ3B {
  font-size: 28px;
  line-height: 37px;
  color: #33c3c7;
  margin: 0;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga .amount___MrQ3B {
    font-size: 20px;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga .contract___fdELp {
  margin: 0;
  font-size: 25px;
  line-height: 33px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga .contract___fdELp {
    font-size: 20px;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga button {
  min-width: 196px;
  height: 42px;
  font-size: 19px;
  background-color: #ffffff;
  border: 3px solid #33c3c7;
  border-radius: 20px;
  margin-top: 20px;
  text-align: center;
  color: #3c3c3c;
}
@media screen and (max-width: 767px) {
  .accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga button {
    font-size: 16px;
    height: 40px;
  }
}
.accountStatus___1Lk3f .accountData___1kiXs .dataBadge___3bqga:first-child {
  padding-left: 21px;
  padding-right: 21px;
}
.accountStatus___1Lk3f .actionBtn___FBpAw {
  cursor: pointer;
}
.accountStatus___1Lk3f .actionBtn___FBpAw img {
  height: 21px;
  width: 23px;
}
.dropdownAccountOptions .hideContractOption, .dropdownAccountOptions .hideRoleOption {
  display: none !important;
}
.dropdownAccountOptions ul {
  padding: 0;
  border-radius: 10px;
}
.dropdownAccountOptions ul li:first-of-type, .dropdownAccountOptions ul .ant-dropdown-menu-submenu-title:first-of-type:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dropdownAccountOptions ul li:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dropdownAccountOptions ul .ant-dropdown-menu-submenu-title:hover {
  background-color: #e3f4f4;
}
.dropdownAccountOptions ul .ant-dropdown-menu-submenu-arrow {
  display: none;
}
.dropdownAccountOptions ul .menuTitle {
  background: #e3f4f4;
}
.dropdownAccountOptions ul .hideContract, .dropdownAccountOptions ul .hideRole {
  display: none;
}
.bottomContentContainer___kDbCe .memberTable___1PBFI {
  max-width: 1080px;
  margin: 30px auto 50px;
}
.bottomContentContainer___kDbCe .ant-tabs-tab {
  padding: 0 0 12px 0;
}
.bottomContentContainer___kDbCe .ant-tabs-content.ant-tabs-content-top {
  background-color: #fafafa;
}
.bottomContentContainer___kDbCe .ant-tabs-tab {
  font-size: 25px;
}
@media screen and (max-width: 767px) {
  .bottomContentContainer___kDbCe .ant-tabs-tab {
    font-size: 16px;
    margin: 0 15px 0 0;
  }
}
.bottomContentContainer___kDbCe .ant-tabs-nav {
  margin-bottom: 0;
}
.bottomContentContainer___kDbCe .ant-tabs-nav::before {
  border-bottom: 3px solid #f0f0f0;
}
.bottomContentContainer___kDbCe .ant-tabs-nav-list {
  max-width: 1080px;
  margin: auto;
  width: 100%;
  padding: 0 15px;
}
.bottomContentContainer___kDbCe .ant-table {
  font-size: 16px;
}
.bottomContentContainer___kDbCe .ant-table-thead tr th {
  font-weight: 500;
  border-bottom: 3px solid #f0f0f0;
}
.bottomContentContainer___kDbCe .ant-table-tbody tr td {
  background-color: #fafafa;
  border-bottom: 3px solid #f0f0f0;
}
@media screen and (max-width: 767px) {
  .bottomContentContainer___kDbCe .ant-table-tbody tr td {
    max-width: 100px !important;
  }
}
.bottomContentContainer___kDbCe .ant-table-tbody tr td:nth-child(1) {
  max-width: 220px;
}
.bottomContentContainer___kDbCe .ant-table-tbody tr td:nth-child(2) {
  max-width: 400px;
}
.bottomContentContainer___kDbCe .ant-table-tbody tr:last-child td {
  border-bottom: none;
}
.bottomContentContainer___kDbCe .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.bottomContentContainer___kDbCe .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}
.bottomContentContainer___kDbCe .ant-table table {
  font-size: 14px;
}
.bottomContentContainer___kDbCe .returnHomeBtn___1GK08 {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding-top: 80px;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
}
.bottomContentContainer___kDbCe .returnHomeBtn___1GK08 button {
  height: 49px;
  min-width: 250px;
  color: #9c9c9c;
  border-radius: 25px;
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
  font-size: 18px;
  background: #ffffff;
}
