.forgotPassword___1siOv {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 80px;
}
.forgotPassword___1siOv h2 {
  font-size: 30px;
  text-align: center;
  padding: 100px 0 700px 0;
}
.forgotPassword___1siOv .bodyContent___3ogMy {
  padding: 65px 0 127px;
}
.forgotPassword___1siOv .bodyContent___3ogMy p {
  font-size: 24px;
  text-indent: 37px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___1siOv .bodyContent___3ogMy p {
    text-indent: 0;
  }
}
.forgotPassword___1siOv .bodyContent___3ogMy .headTitle___3zsAp {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___1siOv .bodyContent___3ogMy .headTitle___3zsAp {
    margin-bottom: 20px;
  }
}
.forgotPassword___1siOv .bodyContent___3ogMy .headTitle___3zsAp .bolderIcon___2egjo {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.forgotPassword___1siOv .bodyContent___3ogMy .headTitle___3zsAp .titleIcon___1MsQU {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.forgotPassword___1siOv .bodyContent___3ogMy .headTitle___3zsAp span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .forgotPassword___1siOv .bodyContent___3ogMy .headTitle___3zsAp span {
    font-size: 35px;
  }
}
.forgotPassword___1siOv .bodyContent___3ogMy .intruction___2ZajC {
  font-size: 18px;
}
.forgotPassword___1siOv .bodyContent___3ogMy .inputField___1hKOO {
  margin: auto;
}
.forgotPassword___1siOv .bodyContent___3ogMy .inputField___1hKOO span {
  margin-bottom: 10px;
  font-size: 25px;
}
.forgotPassword___1siOv .bodyContent___3ogMy .inputField___1hKOO input {
  height: 58px;
  border-radius: 20px;
  font-size: 22px;
}
.forgotPassword___1siOv .bodyContent___3ogMy .inputField___1hKOO .errorNotice___15PGj {
  color: #ff4d4f;
  font-size: 14px;
}
.forgotPassword___1siOv .bodyContent___3ogMy .btnZone___sUz6H {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 25px;
}
.forgotPassword___1siOv .bodyContent___3ogMy .btnZone___sUz6H .signUpBtn___tqaLv {
  min-width: 237px;
  height: 50px;
  background-color: #33c3c7;
  color: #ffffff;
  font-size: 18px;
  border-radius: 20px;
  margin: 0;
}
.forgotPassword___1siOv .ant-form-item {
  margin: 0;
}
.forgotPassword___1siOv .ant-form {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
