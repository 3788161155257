@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___KZH2o {
  display: flex;
  justify-content: center;
}
button.btn___3Xsw_ {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3Xsw_ {
    padding: 10px;
  }
}
button.btn___3Xsw_:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3Xsw_:focus {
  outline: none;
}
.btnWhite___2F0Pp.btn___3Xsw_ {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2F0Pp.btn___3Xsw_:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___5ClB6.btn___3Xsw_,
.btnGreen___5ClB6.btn___3Xsw_:hover,
.btnGreen___5ClB6.btn___3Xsw_:active,
.btnGreen___5ClB6.btn___3Xsw_:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2aibo {
  color: #fe0000;
}
.errorMessage___10hJI {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___LCdkw {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___dmO2K {
  display: none !important;
}
.m-auto___1ryCc {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___ibwVD {
  max-width: 120px !important;
}
.fc___1i6C2 .fc-timegrid-col___xdA_E.fc-day-today___hsCwX {
  background-color: #ffffff;
}
.fc___1i6C2 .fc-timegrid-slot___17RmJ {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1i6C2 .fc-timegrid-slot___17RmJ {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1_2xg .fc-direction-ltr___R8nAs .fc-timegrid-slot-label-frame___2F4U7 {
    font-size: 10px;
  }
  .bookingCalendar___1_2xg .fc___1i6C2 .fc-timegrid-slot-label-cushion___bDmUm {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1_2xg .fc___1i6C2 .fc-scrollgrid-section-header___3BnDB .fc-scroller___1a2H3 {
    overflow: unset !important;
  }
}
.ant-message-notice___wj57b {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___2RDHG {
  width: 20px;
  height: 20px;
}
.bgTransparent___1F-Tl {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3qKPD {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1OqPq {
  position: relative;
}
.inputCustomValue___1OqPq .ant-input___1XD9m {
  padding-right: 50px;
}
.inputCustomValueText___2vBMw {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2bgeq .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2bgeq .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2Vulo .ant-radio-inner___2XDBF {
  border-color: black !important ;
}
.ant-radio-checked___2Vulo .ant-radio-inner___2XDBF:after {
  background-color: black;
}
.ant-radio___Kj36c:hover .ant-radio-inner___2XDBF {
  border-color: black;
}
.ant-radio-checked___2Vulo .ant-radio-inner___2XDBF:focus {
  border-color: black;
}
.modal___2pRRz {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___2pRRz .close___2na79 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___2pRRz .header___2GQ-z {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___2pRRz .header___2GQ-z p {
  margin: 0;
}
.modal___2pRRz .content___19atq {
  text-align: center;
  font-size: 14px;
}
.modal___2pRRz .content___19atq p {
  margin: 0;
}
.modal___2pRRz .actions___3qCap {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___2pRRz .actions___3qCap .button___18FYI {
  margin-top: 10px;
}
.modal___2pRRz .actions___3qCap:hover {
  color: #33c3c7;
}
.fc___1i6C2 .fc-timegrid-slot-minor___vwMce {
  border-top-style: none;
}
.fc___1i6C2 .fc-timegrid-slot___17RmJ {
  height: 1.5em !important;
}
.messageError___3vpUK .ant-message-custom-content___3MzKn {
  display: flex;
  text-align: start;
}
.messageError___3vpUK .ant-message-custom-content___3MzKn span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3Rb6d {
  background-color: #e6e4e4;
}
.notifyMessage___3O3-O .ant-message-notice-content___3oWY3 {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___3O3-O .ant-message-notice-content___3oWY3 .anticon___3lI4j {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___3O3-O .ant-message-notice-content___3oWY3 span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___Fe0LU {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___Fe0LU .ant-notification-notice-message___3ZZRM,
.notifyCustomize___Fe0LU .ant-notification-notice-close___kSXzK,
.notifyCustomize___Fe0LU .ant-notification-notice-icon___pHnMd {
  color: white;
}
.notifyCustomize___Fe0LU .ant-notification-notice-message___3ZZRM {
  margin-left: 35px;
}
.notifyCustomize___Fe0LU p {
  margin-bottom: 0;
}
.notifyCustomize___Fe0LU p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3eMFk {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3eMFk .ant-notification-notice-message___3ZZRM {
  margin-left: 35px;
}
.notifyAlertDuplicate___3eMFk p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3eMFk p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1qMbJ {
  width: 434px !important;
}
.handleSubmitVote___1qMbJ .ant-btn-primary___1_JDl {
  background: #59c3c7;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ .ant-modal-confirm-body___XYzve {
  display: flex !important;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ .ant-modal-confirm-body___XYzve .anticon___3lI4j {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ .ant-modal-confirm-btns___3j7Zv button {
  font-weight: bold;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ .ant-modal-confirm-btns___3j7Zv button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ .ant-modal-confirm-btns___3j7Zv button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1qMbJ .ant-modal-body___3i3xJ .ant-modal-confirm-btns___3j7Zv .ant-btn-primary___1_JDl {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___17IVw {
  margin-bottom: 0;
}
.mt-0___2NSA7 {
  margin-top: 0;
}
.tooltipFormat___1fWTp p {
  margin-bottom: 0;
}
.bgWhite___2yuO6 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___34Jj- {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___34Jj-:hover,
.bgPrimaryBlue___34Jj-:focus,
.bgPrimaryBlue___34Jj-:active {
  background: #4f7ac7;
}
.bgDarkBlue___2leXt {
  background-color: #023768 !important;
}
.bgDarkBlue___2leXt:hover,
.bgDarkBlue___2leXt:focus,
.bgDarkBlue___2leXt:active {
  background: #194d7d;
}
.bgGreyBlue___1YcHU {
  background-color: #ebf0f9;
}
.bgLightBlue___32542 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2sH66 {
  background-color: #e4453a;
}
.bgLightRed___2sH66:hover,
.bgLightRed___2sH66:focus,
.bgLightRed___2sH66:active {
  background: #e17871;
}
.bgLightYellow___3CA5o {
  background-color: #ffd603;
}
.bgDarkGray___1-_4w {
  background-color: #333333;
}
.bgMediumGray___1JH9q {
  background-color: #a2a2a2;
}
.bgLightGray___18q5m {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___16sMG {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3ujic {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___HuXLY {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___bd8yH {
  color: #3368c7 !important;
}
.textDarkBlue___1_4b0 {
  color: #023768 !important;
}
.textLightBlue___a2c4a {
  color: #b2cbf7;
}
.textDarkGray___1xww9 {
  color: #333333 !important;
}
.textMediumGray___1M0gj {
  color: #a2a2a2 !important;
}
.textLightGray___3_l1O {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1nlQ4 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___2K_zn {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___27XtP {
  border-radius: 5px !important;
}
.rounded-xs___3zeH- {
  border-radius: 10px !important;
}
.rounded-sm___35GHH {
  border-radius: 15px !important;
}
.rounded-md___2Aj3w {
  border-radius: 20px !important;
}
.rounded-lg____R5zS {
  border-radius: 25px !important;
}
.rounded-full___1RbtW {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2e4q8 {
  margin: 0;
}
.mt-2___3kVjh {
  margin-top: 0.5rem;
}
.my-2___3Sgny {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___j2EXM {
  padding: 0;
}
.px-1___Tl9uR {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___3IXGb {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1q3bP {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3Kv6t {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___ZEC7D {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2JT6N {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3UaH5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1z4tO {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1bXNt {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___stT7n {
  padding-bottom: 20px;
}
.h-full___qoAmU {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___2Yqpp {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___27hpP {
  font-weight: bold !important;
}
.fontWeight400___yjd_P {
  font-weight: 400 !important;
}
.fontWeight500___3WYlg {
  font-weight: 500 !important;
}
.fontWeight600___2KaIM {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___9GuXI {
  font-size: 12px !important;
}
.fontSize14___1L-xk {
  font-size: 14px !important;
}
.fontSize16___vD07V {
  font-size: 16px !important;
}
.fontSize18___80eHo {
  font-size: 18px !important;
}
.fontSize20___2xD0o {
  font-size: 20px !important;
}
.fontSize24___220za {
  font-size: 24px !important;
}
.eventName___1c7Mg {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1cumt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2hIIw > tr > th,
.ant-table-tbody___36R6g > tr.ant-table-row___1VB_o > td,
.ant-table-summary___3BCBJ tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2hIIw > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___29a_N .ant-table-thead___2hIIw > tr > th,
.table-portrait___29a_N .ant-table-tbody___36R6g > tr.ant-table-row___1VB_o > td,
.table-portrait___29a_N .ant-table-summary___3BCBJ tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___29a_N .bgLightGray___18q5m td {
  background: #ebebeb;
}
.table-portrait___29a_N .bgLightBlue___32542 td {
  background: #b2cbf7;
}
.eventContent___3y2uf .timeText___13eR9 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___36R6g > tr.bgLightGray___18q5m:hover > td,
.ant-table-tbody___36R6g > tr > td.ant-table-cell-row-hover___iXecc {
  background: #ebebeb;
}
.ant-table-tbody___36R6g > tr.bgLightBlue___32542:hover > td,
.ant-table-tbody___36R6g > tr > td.ant-table-cell-row-hover___iXecc {
  background: #b2cbf7;
}
.ant-table-tbody___36R6g > tr.bgWhite___2yuO6:hover > td,
.ant-table-tbody___36R6g > tr > td.ant-table-cell-row-hover___iXecc {
  background: #ffffff;
}
.appointmentSelectionConfirmContainer___ZBI0f {
  max-width: 1080px;
  margin: auto;
  width: 100%;
}
.appointmentSelectionConfirmContainer___ZBI0f .header___2GQ-z {
  font-size: 24px;
  font-weight: bold;
}
.appointmentSelectionConfirmContainer___ZBI0f .inputField___kN2nr {
  max-width: 508px;
  margin: 0 auto 20px auto;
}
.appointmentSelectionConfirmContainer___ZBI0f .inputField___kN2nr .fieldFlex___1f--V {
  display: flex;
  justify-content: space-between;
}
.appointmentSelectionConfirmContainer___ZBI0f .inputField___kN2nr .fieldLabel___1hdS3 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.appointmentSelectionConfirmContainer___ZBI0f .inputField___kN2nr .fieldLabel___1hdS3 .inputRequired___19o-_ {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 10px;
}
.appointmentSelectionConfirmContainer___ZBI0f .inputField___kN2nr input {
  width: 100%;
  height: 40px;
  border: 1px solid #999999 !important;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.appointmentSelectionConfirmContainer___ZBI0f .inputField___kN2nr .errorNotice___3wYL7 {
  color: #ff4d4f;
  font-size: 14px;
}
.appointmentSelectionConfirmContainer___ZBI0f .btnZone___1VcbF {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.appointmentSelectionConfirmContainer___ZBI0f .btnZone___1VcbF .confirmBtn___1TC_K {
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.appointmentSelectionConfirmContainer___ZBI0f .btnZone___1VcbF .confirmBtn___1TC_K:hover {
  background-color: #1f4f96;
}
.appointmentSelectionConfirmContainer___ZBI0f .btnZone___1VcbF .backBtn___3HVao {
  width: 100%;
  height: 40px;
  background-color: #9db9fa;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.appointmentSelectionConfirmContainer___ZBI0f .btnZone___1VcbF .backBtn___3HVao:hover {
  background-color: #9db9fa;
}
