.toolBox___38u4J {
  border-radius: 5px;
  width: 415px;
  background: #dff3ff;
  padding: 0.8rem;
  box-shadow: #00000026 0 1px 2px 0, #00000026 0 2px 6px 2px;
}
.toolBox___38u4J .title___1BcPo {
  margin-bottom: 2rem;
  text-align: center;
}
.toolBox___38u4J .title___1BcPo p {
  font-weight: bold;
  color: #4a6bc0;
  font-size: 20px;
  margin-bottom: 0;
}
.toolBox___38u4J .stepItem___3x-p6 {
  padding-left: 0.8rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q {
  display: flex;
  font-size: 16px;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q .lineItem___3Ze-- {
  display: flex;
  height: 21px;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q .lineItem___3Ze-- div {
  background: #4a6bc0;
  height: 100%;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q .lineItem___3Ze-- div.lineBig___3onaG {
  width: 6px;
  margin-right: 0.1rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q .lineItem___3Ze-- div.lineChild___2JaS4 {
  width: 2px;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q .stepName___3377V {
  padding: 0 0.7rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q .stepName___3377V p {
  font-weight: bold;
  color: #3c3c3c;
  margin-bottom: 0;
  text-transform: uppercase;
}
.toolBox___38u4J .stepItem___3x-p6 .stepHeader___3j28q span {
  font-weight: bold;
  color: #3c3c3c;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN {
  padding: 1rem 0 2rem 20px;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .inputName___3NOvf {
  border: 2px solid #3c3c3c;
  border-radius: 12px;
  width: 83%;
  outline: none;
  padding: 0.2rem 0.5rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .switchSetting___2De7k {
  border: 2px solid #3c3c3c;
  border-radius: 15px;
  width: 83%;
  display: flex;
  font-size: 16px;
  background: #fff;
  margin-bottom: 1.5rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .switchSetting___2De7k div {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  flex: 1 1;
  text-align: center;
  border-radius: 12px 0 0 12px;
  color: #3c3c3c;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .switchSetting___2De7k div:last-child {
  border-radius: 0 12px 12px 0;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .switchSetting___2De7k div.active____Wp_C {
  background: #4a6bc0;
  color: #fff;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:not(:last-child) {
  padding-bottom: 2rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk .formSettingTitle___3nTlk {
  font-weight: bold;
  font-size: 16px;
  color: #3c3c3c;
  margin-bottom: 0.5rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk .formSettingContent___9c5nY {
  width: 83%;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk .formSettingContent___9c5nY .fontSize___1Fplb {
  text-align: center;
  width: 33%;
  outline: none;
  border: 2px solid #3c3c3c !important;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk .formSettingContent___9c5nY .selectFontSize___LPpgz {
  width: 33%;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY {
  display: flex;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY .widthHeight___3zO8C {
  display: flex;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY .widthHeight___3zO8C:first-child {
  padding-right: 1rem;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY .widthHeight___3zO8C .title___1BcPo {
  line-height: 1.9rem;
  color: #6d6d6d;
  font-size: 14px;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY .widthHeight___3zO8C .content___1juX4 {
  width: 65%;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY .widthHeight___3zO8C .content___1juX4 .ant-select-single .ant-select-selector {
  width: 90px !important;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .formSettingItem___2UZgk:last-child .formSettingContent___9c5nY .widthHeight___3zO8C .selectButton___3eful {
  width: 100%;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .ant-input-suffix {
  margin-left: 0;
}
.toolBox___38u4J .stepItem___3x-p6 .stepContent___3AHcN .ant-select-single .ant-select-selector {
  border: 2px solid #3c3c3c !important;
  border-radius: 8px !important;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL button {
  height: 90%;
  border: 1px solid transparent;
  background: #fff;
  color: #3c3c3c;
  border-radius: 0.6rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  box-shadow: #00000017 0 2px 8px 0;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL button:first-child {
  margin: auto 1rem auto 0;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL button:last-child {
  color: #fff;
  background: #3773e1;
  margin: auto 0 auto 1rem;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL button .iconNextLef___2q5-m {
  margin-right: 0.3rem;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL button .iconNextRight___2uICT {
  margin-left: 0.3rem;
}
.toolBox___38u4J .btnGroupSubmit___2HRIL.btnGroupSubmitText___3hPag {
  margin-top: 0;
}
@media screen and (max-width: 1440px) {
  .toolBox___38u4J {
    width: 460px;
  }
}
@media screen and (max-width: 1268px) {
  .toolBox___38u4J {
    width: 480px;
  }
}
