.loginAdmin___sdYvM {
  background: #e8ecef;
}
.loginAdmin___sdYvM .laContainer___1RKsc {
  max-width: 500px;
  width: 100%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.loginAdmin___sdYvM .laContainer___1RKsc .laContent___3GpUb {
  width: 100%;
  text-align: center;
}
.loginAdmin___sdYvM .laContainer___1RKsc .laTitle___2NtV1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
.loginAdmin___sdYvM .btnGroup___3lEOu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginAdmin___sdYvM .laForm___fXDCs {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 30px 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}
.loginAdmin___sdYvM .forgotPass___35FMe {
  color: #1890ff;
}
.loginAdmin___sdYvM .laFormTitle___3TU7M {
  margin-bottom: 20px;
  font-size: 20px;
}
.loginAdmin___sdYvM .ant-input {
  height: 44px;
  padding-left: 20px;
  padding-right: 20px;
}
.loginAdmin___sdYvM .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  padding: 0 20px;
  border: 2px solid #ececec !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.loginAdmin___sdYvM .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  height: 40px;
}
.loginAdmin___sdYvM .ant-input-group-addon:last-child {
  border: 2px solid #ececec;
  border-left: 0;
}
.loginAdmin___sdYvM .ant-input-group .ant-input-affix-wrapper:not(:last-child):focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
  outline: none;
}
.loginAdmin___sdYvM .ant-input-group .ant-input-affix-wrapper:not(:last-child):hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
  outline: none;
}
.loginAdmin___sdYvM .ant-input-group .ant-input-affix-wrapper:not(:last-child):active {
  outline: none;
}
.loginAdmin___sdYvM .ant-form-item-has-error .ant-input-affix-wrapper:not(:last-child):focus {
  border-right-width: 2px !important;
}
.loginAdmin___sdYvM .ant-btn-primary {
  background: #33c3c7;
  border: 0;
  border-radius: 10px;
}
.loginAdmin___sdYvM .ant-form-item.ant-form-item-with-help.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: #ff4d4f !important;
}
.loginAdmin___sdYvM .ant-input-group-addon {
  color: black !important;
}
