@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___svoPO {
  display: flex;
  justify-content: center;
}
button.btn___2wH4d {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2wH4d {
    padding: 10px;
  }
}
button.btn___2wH4d:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2wH4d:focus {
  outline: none;
}
.btnWhite___LtCer.btn___2wH4d {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___LtCer.btn___2wH4d:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___GZlqq.btn___2wH4d,
.btnGreen___GZlqq.btn___2wH4d:hover,
.btnGreen___GZlqq.btn___2wH4d:active,
.btnGreen___GZlqq.btn___2wH4d:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___fsGly {
  color: #fe0000;
}
.errorMessage___2Mpor {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___1tjYG {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2Wrkh {
  display: none !important;
}
.m-auto___2eHyR {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1u4aH {
  max-width: 120px !important;
}
.fc___cjxIZ .fc-timegrid-col___1-zMf.fc-day-today___ypUeL {
  background-color: #ffffff;
}
.fc___cjxIZ .fc-timegrid-slot___XL_tv {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___cjxIZ .fc-timegrid-slot___XL_tv {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1lIkQ .fc-direction-ltr___29D3y .fc-timegrid-slot-label-frame___1M7Yl {
    font-size: 10px;
  }
  .bookingCalendar___1lIkQ .fc___cjxIZ .fc-timegrid-slot-label-cushion___22JXW {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1lIkQ .fc___cjxIZ .fc-scrollgrid-section-header___2yDkk .fc-scroller___skX3G {
    overflow: unset !important;
  }
}
.ant-message-notice___E4c1H {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___385Vc {
  width: 20px;
  height: 20px;
}
.bgTransparent___2VufB {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___2LYx2 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___2vF1K {
  position: relative;
}
.inputCustomValue___2vF1K .ant-input___3dNh9 {
  padding-right: 50px;
}
.inputCustomValueText___2ore8 {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___17ZnI .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___17ZnI .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1abzM .ant-radio-inner___2qVXn {
  border-color: black !important ;
}
.ant-radio-checked___1abzM .ant-radio-inner___2qVXn:after {
  background-color: black;
}
.ant-radio___3ZK6O:hover .ant-radio-inner___2qVXn {
  border-color: black;
}
.ant-radio-checked___1abzM .ant-radio-inner___2qVXn:focus {
  border-color: black;
}
.modal___1Y6Gw {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1Y6Gw .close___2Uaa2 {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1Y6Gw .header___1HNc3 {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1Y6Gw .header___1HNc3 p {
  margin: 0;
}
.modal___1Y6Gw .content___3LYxh {
  text-align: center;
  font-size: 14px;
}
.modal___1Y6Gw .content___3LYxh p {
  margin: 0;
}
.modal___1Y6Gw .actions___3hmDf {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1Y6Gw .actions___3hmDf .button___1g-bc {
  margin-top: 10px;
}
.modal___1Y6Gw .actions___3hmDf:hover {
  color: #33c3c7;
}
.fc___cjxIZ .fc-timegrid-slot-minor___1vNxZ {
  border-top-style: none;
}
.fc___cjxIZ .fc-timegrid-slot___XL_tv {
  height: 1.5em !important;
}
.messageError___IQ2QB .ant-message-custom-content___1mXAX {
  display: flex;
  text-align: start;
}
.messageError___IQ2QB .ant-message-custom-content___1mXAX span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3UNs7 {
  background-color: #e6e4e4;
}
.notifyMessage___YFTE5 .ant-message-notice-content___2-YHl {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___YFTE5 .ant-message-notice-content___2-YHl .anticon___3U4Rn {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___YFTE5 .ant-message-notice-content___2-YHl span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___1R2AM {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___1R2AM .ant-notification-notice-message___j-IQ1,
.notifyCustomize___1R2AM .ant-notification-notice-close___31D5_,
.notifyCustomize___1R2AM .ant-notification-notice-icon___2f8Fe {
  color: white;
}
.notifyCustomize___1R2AM .ant-notification-notice-message___j-IQ1 {
  margin-left: 35px;
}
.notifyCustomize___1R2AM p {
  margin-bottom: 0;
}
.notifyCustomize___1R2AM p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___31hIR {
  padding: 16px 14px;
}
.notifyAlertDuplicate___31hIR .ant-notification-notice-message___j-IQ1 {
  margin-left: 35px;
}
.notifyAlertDuplicate___31hIR p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___31hIR p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3usMg {
  width: 434px !important;
}
.handleSubmitVote___3usMg .ant-btn-primary___3Nsy7 {
  background: #59c3c7;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og .ant-modal-confirm-body___fbxum {
  display: flex !important;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og .ant-modal-confirm-body___fbxum .anticon___3U4Rn {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og .ant-modal-confirm-btns___2IaJH button {
  font-weight: bold;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og .ant-modal-confirm-btns___2IaJH button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og .ant-modal-confirm-btns___2IaJH button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3usMg .ant-modal-body___1x-og .ant-modal-confirm-btns___2IaJH .ant-btn-primary___3Nsy7 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1PY2P {
  margin-bottom: 0;
}
.mt-0___2xO0D {
  margin-top: 0;
}
.tooltipFormat___1Kiy0 p {
  margin-bottom: 0;
}
.bgWhite___1AjMN {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___uNp7f {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___uNp7f:hover,
.bgPrimaryBlue___uNp7f:focus,
.bgPrimaryBlue___uNp7f:active {
  background: #4f7ac7;
}
.bgDarkBlue___1YKEZ {
  background-color: #023768 !important;
}
.bgDarkBlue___1YKEZ:hover,
.bgDarkBlue___1YKEZ:focus,
.bgDarkBlue___1YKEZ:active {
  background: #194d7d;
}
.bgGreyBlue___Fe6vo {
  background-color: #ebf0f9;
}
.bgLightBlue___1ZsNI {
  background-color: #b2cbf7 !important;
}
.bgLightRed___YwyiQ {
  background-color: #e4453a;
}
.bgLightRed___YwyiQ:hover,
.bgLightRed___YwyiQ:focus,
.bgLightRed___YwyiQ:active {
  background: #e17871;
}
.bgLightYellow___hKhb- {
  background-color: #ffd603;
}
.bgDarkGray___273uI {
  background-color: #333333;
}
.bgMediumGray___1zh8i {
  background-color: #a2a2a2;
}
.bgLightGray___32PGg {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___2MnOv {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___1D1Kl {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1MVOy {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3ogFc {
  color: #3368c7 !important;
}
.textDarkBlue___2kZ-d {
  color: #023768 !important;
}
.textLightBlue___3WS2W {
  color: #b2cbf7;
}
.textDarkGray___3ojgj {
  color: #333333 !important;
}
.textMediumGray___1CHFy {
  color: #a2a2a2 !important;
}
.textLightGray___3mBfR {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3vjmt {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1BVu7 {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___1mG6M {
  border-radius: 5px !important;
}
.rounded-xs___3f-Fn {
  border-radius: 10px !important;
}
.rounded-sm___1Peku {
  border-radius: 15px !important;
}
.rounded-md___1HgEd {
  border-radius: 20px !important;
}
.rounded-lg___3qTo3 {
  border-radius: 25px !important;
}
.rounded-full___2zyDQ {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___qZCtJ {
  margin: 0;
}
.mt-2___10U8A {
  margin-top: 0.5rem;
}
.my-2___1efgo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2uKCe {
  padding: 0;
}
.px-1___2iAjR {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2bxsw {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2-WaV {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___38gUI {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___20NM7 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2kIS5 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1Rl5W {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2Vubw {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___1ZfcW {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___3nYCc {
  padding-bottom: 20px;
}
.h-full___3mZui {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3tPmy {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___i6RAx {
  font-weight: bold !important;
}
.fontWeight400___2W1n3 {
  font-weight: 400 !important;
}
.fontWeight500___2bQ1O {
  font-weight: 500 !important;
}
.fontWeight600___306ww {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3OWY4 {
  font-size: 12px !important;
}
.fontSize14___2262k {
  font-size: 14px !important;
}
.fontSize16___3ccs0 {
  font-size: 16px !important;
}
.fontSize18___3URAl {
  font-size: 18px !important;
}
.fontSize20___OCTZl {
  font-size: 20px !important;
}
.fontSize24___2c2yh {
  font-size: 24px !important;
}
.eventName___1ew5L {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1W-ag {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2rZrQ > tr > th,
.ant-table-tbody___21UY0 > tr.ant-table-row___MD2Uw > td,
.ant-table-summary___2UheD tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2rZrQ > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___37ngX .ant-table-thead___2rZrQ > tr > th,
.table-portrait___37ngX .ant-table-tbody___21UY0 > tr.ant-table-row___MD2Uw > td,
.table-portrait___37ngX .ant-table-summary___2UheD tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___37ngX .bgLightGray___32PGg td {
  background: #ebebeb;
}
.table-portrait___37ngX .bgLightBlue___1ZsNI td {
  background: #b2cbf7;
}
.eventContent___3KWSa .timeText___21cI_ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___21UY0 > tr.bgLightGray___32PGg:hover > td,
.ant-table-tbody___21UY0 > tr > td.ant-table-cell-row-hover___1GiUO {
  background: #ebebeb;
}
.ant-table-tbody___21UY0 > tr.bgLightBlue___1ZsNI:hover > td,
.ant-table-tbody___21UY0 > tr > td.ant-table-cell-row-hover___1GiUO {
  background: #b2cbf7;
}
.ant-table-tbody___21UY0 > tr.bgWhite___1AjMN:hover > td,
.ant-table-tbody___21UY0 > tr > td.ant-table-cell-row-hover___1GiUO {
  background: #ffffff;
}
.calendarCreation___2jKxo {
  max-width: 1080px;
  margin: auto;
  padding: 25px 0 50px 0;
}
@media screen and (max-width: 767px) {
  .calendarCreation___2jKxo {
    padding: 0 0 50px;
  }
}
.advancedSettingContainer___3TuKV {
  max-width: 1080px;
  padding: 30px 0 0 0;
  margin: auto;
}
.advancedSettingContainer___3TuKV .collapseSettingContainer___3EEBe {
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 35px;
}
.advancedSettingContainer___3TuKV .messageSettingContainer___1jWaB .ant-collapse-header .ant-collapse-arrow {
  left: 250px !important;
}
.advancedSettingContainer___3TuKV .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 180px;
  right: unset;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%);
}
.advancedSettingContainer___3TuKV .ant-collapse {
  border: 0;
  background-color: #fff;
}
.advancedSettingContainer___3TuKV .ant-collapse-header,
.advancedSettingContainer___3TuKV .ant-collapse > .ant-collapse-item:last-child,
.advancedSettingContainer___3TuKV .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: #fff;
  border-radius: 20px;
}
.advancedSettingContainer___3TuKV .ant-collapse-content,
.advancedSettingContainer___3TuKV .ant-collapse-item:last-child > .ant-collapse-content {
  border-top: 0;
  border-radius: 0 0 20px 20px;
}
.advancedSettingContainer___3TuKV .ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.advancedSettingContainer___3TuKV .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 10px;
  height: 23px;
  min-width: 99px;
  border: 2px solid #d9d9d9 !important;
  border-radius: 12px !important;
  font-size: 13px;
  color: #414141;
  line-height: 23px;
  display: flex;
  align-items: center;
}
.advancedSettingContainer___3TuKV .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
}
.advancedSettingContainer___3TuKV .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item img {
  height: 15px;
  width: 15px;
}
.advancedSettingContainer___3TuKV .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item > div {
  height: 100%;
  display: flex;
  align-items: center;
}
.advancedSettingContainer___3TuKV .ant-picker {
  max-width: 100px;
  font-size: 13px;
  height: 24px;
  border-radius: 15px;
  border-width: 2px;
}
.advancedSettingContainer___3TuKV .ant-picker-input > input {
  font-size: 13px;
}
.advancedSettingContainer___3TuKV .scheduleSetting___12yjK {
  padding: 24px 64px 0 64px;
  box-shadow: 1px 1px 12px #b3bcbc30;
  border-radius: 20px;
  margin-bottom: 40px;
}
.advancedSettingContainer___3TuKV .timeSetting___2XPNe {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___3TuKV .messageSetting___rc48r {
  display: flex;
  flex-wrap: wrap;
}
.advancedSettingContainer___3TuKV .dropdownHeader___2AMLP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___3TuKV .dropdownHeader___2AMLP {
    width: 100%;
    position: relative;
  }
}
.advancedSettingContainer___3TuKV .dropdownHeader___2AMLP .dropdownHeaderTitle___3ARyJ {
  font-size: 26px;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___3TuKV .dropdownHeader___2AMLP .dropdownHeaderTitle___3ARyJ {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___3TuKV .dropdownHeader___2AMLP .dropdownHeaderTitleOther___2Apgp {
    font-size: 16px;
  }
}
.advancedSettingContainer___3TuKV .dropdownHeader___2AMLP .helperDropdown___2qLwi {
  margin-left: 55px;
}
@media screen and (max-width: 768px) {
  .advancedSettingContainer___3TuKV .dropdownHeader___2AMLP .helperDropdown___2qLwi {
    position: absolute;
    right: 0;
  }
}
.advancedSettingContainer___3TuKV .dropdownHeader___2AMLP img {
  height: 26px;
  width: 26px;
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  .advancedSettingContainer___3TuKV .dropdownHeader___2AMLP img {
    height: 20px;
    width: 20px;
  }
}
.advancedSettingContainer___3TuKV .dropdownHeader___2AMLP a {
  font-size: 25px;
  color: #414141;
}
.advancedSettingContainer___3TuKV .dropdownHeader___2AMLP a .anticon-down {
  margin-left: 30px;
}
.buttonZoneContainer___2R_sG {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
}
.buttonZoneContainer___2R_sG .cancelButton___1UPiM {
  background-color: #e8e6e6;
  height: 48px;
  max-width: 150px;
  width: 100%;
  padding: 0 15px;
  font-size: 17px;
  color: #535353;
  border-radius: 19px;
  margin: 0 20px;
  box-shadow: 3px -1px 15px #b3bcbc60;
  border: none;
}
@media screen and (max-width: 767px) {
  .buttonZoneContainer___2R_sG .cancelButton___1UPiM {
    margin: 0 10px;
    font-size: 14px;
  }
}
.buttonZoneContainer___2R_sG .submitButton___3vqX9 {
  height: 48px;
  max-width: 150px;
  width: 100%;
  padding: 0 15px;
  font-size: 17px;
  color: #fffefe;
  border-radius: 19px;
  background-color: #33c3c7;
  margin: 0 20px;
}
@media screen and (max-width: 767px) {
  .buttonZoneContainer___2R_sG .submitButton___3vqX9 {
    margin: 0 10px;
    font-size: 14px;
  }
}
.helper___385Vc {
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.helper___385Vc img {
  position: relative;
}
.helper___385Vc .helperTooltip___3EsJ6 {
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 50px);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 120px;
  width: 400px;
  background-color: #414141;
  color: #fffefe;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.helper___385Vc .helperTooltip___3EsJ6::-webkit-scrollbar {
  display: none;
}
.helper___385Vc .helperTooltip___3EsJ6 img {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.helper___385Vc:hover .helperTooltip___3EsJ6 {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
