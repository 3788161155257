@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3_03n {
  display: flex;
  justify-content: center;
}
button.btn___2me02 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2me02 {
    padding: 10px;
  }
}
button.btn___2me02:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2me02:focus {
  outline: none;
}
.btnWhite___2iaIs.btn___2me02 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2iaIs.btn___2me02:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___1oWCF.btn___2me02,
.btnGreen___1oWCF.btn___2me02:hover,
.btnGreen___1oWCF.btn___2me02:active,
.btnGreen___1oWCF.btn___2me02:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1x_-2 {
  color: #fe0000;
}
.errorMessage___3-f7G {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2JwjN {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___AquPO {
  display: none !important;
}
.m-auto___1S3o2 {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1_FHP {
  max-width: 120px !important;
}
.fc___1hyQM .fc-timegrid-col___3tU32.fc-day-today___2_SoO {
  background-color: #ffffff;
}
.fc___1hyQM .fc-timegrid-slot___1Ve5O {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1hyQM .fc-timegrid-slot___1Ve5O {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1HMJr .fc-direction-ltr___d7Gdh .fc-timegrid-slot-label-frame___36NDp {
    font-size: 10px;
  }
  .bookingCalendar___1HMJr .fc___1hyQM .fc-timegrid-slot-label-cushion___3oHgj {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1HMJr .fc___1hyQM .fc-scrollgrid-section-header___3Q6qp .fc-scroller___3ot7e {
    overflow: unset !important;
  }
}
.ant-message-notice___YiBnq {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___19wdv {
  width: 20px;
  height: 20px;
}
.bgTransparent___2dlL4 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___uGek- {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___aIPlt {
  position: relative;
}
.inputCustomValue___aIPlt .ant-input___1bY3V {
  padding-right: 50px;
}
.inputCustomValueText___1A-Yj {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___2NKnx .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___2NKnx .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___3-mvQ .ant-radio-inner___4D3i6 {
  border-color: black !important ;
}
.ant-radio-checked___3-mvQ .ant-radio-inner___4D3i6:after {
  background-color: black;
}
.ant-radio___cp8pN:hover .ant-radio-inner___4D3i6 {
  border-color: black;
}
.ant-radio-checked___3-mvQ .ant-radio-inner___4D3i6:focus {
  border-color: black;
}
.modal___1i34y {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1i34y .close___2b-1r {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1i34y .header___3iFew {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1i34y .header___3iFew p {
  margin: 0;
}
.modal___1i34y .content___AJ5Yt {
  text-align: center;
  font-size: 14px;
}
.modal___1i34y .content___AJ5Yt p {
  margin: 0;
}
.modal___1i34y .actions___1ho79 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1i34y .actions___1ho79 .button___ZM0HR {
  margin-top: 10px;
}
.modal___1i34y .actions___1ho79:hover {
  color: #33c3c7;
}
.fc___1hyQM .fc-timegrid-slot-minor___3y9j5 {
  border-top-style: none;
}
.fc___1hyQM .fc-timegrid-slot___1Ve5O {
  height: 1.5em !important;
}
.messageError___2HWjw .ant-message-custom-content___1atIw {
  display: flex;
  text-align: start;
}
.messageError___2HWjw .ant-message-custom-content___1atIw span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___fTQQG {
  background-color: #e6e4e4;
}
.notifyMessage___nEPaf .ant-message-notice-content___2WiNX {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___nEPaf .ant-message-notice-content___2WiNX .anticon___11Sru {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___nEPaf .ant-message-notice-content___2WiNX span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___2IDV6 {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___2IDV6 .ant-notification-notice-message___22SYy,
.notifyCustomize___2IDV6 .ant-notification-notice-close___HoDxD,
.notifyCustomize___2IDV6 .ant-notification-notice-icon___3Uc9T {
  color: white;
}
.notifyCustomize___2IDV6 .ant-notification-notice-message___22SYy {
  margin-left: 35px;
}
.notifyCustomize___2IDV6 p {
  margin-bottom: 0;
}
.notifyCustomize___2IDV6 p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2AIvW {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2AIvW .ant-notification-notice-message___22SYy {
  margin-left: 35px;
}
.notifyAlertDuplicate___2AIvW p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2AIvW p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___3TZYh {
  width: 434px !important;
}
.handleSubmitVote___3TZYh .ant-btn-primary___2IjZd {
  background: #59c3c7;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt {
  padding: 15px 30px 18px;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt .ant-modal-confirm-body___SZpdF {
  display: flex !important;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt .ant-modal-confirm-body___SZpdF .anticon___11Sru {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt .ant-modal-confirm-btns___1QLCz button {
  font-weight: bold;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt .ant-modal-confirm-btns___1QLCz button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt .ant-modal-confirm-btns___1QLCz button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___3TZYh .ant-modal-body___23JGt .ant-modal-confirm-btns___1QLCz .ant-btn-primary___2IjZd {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___zhvHf {
  margin-bottom: 0;
}
.mt-0___Ze55y {
  margin-top: 0;
}
.tooltipFormat___1iog8 p {
  margin-bottom: 0;
}
.bgWhite___2k1Uy {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3L7Wu {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3L7Wu:hover,
.bgPrimaryBlue___3L7Wu:focus,
.bgPrimaryBlue___3L7Wu:active {
  background: #4f7ac7;
}
.bgDarkBlue___JSCum {
  background-color: #023768 !important;
}
.bgDarkBlue___JSCum:hover,
.bgDarkBlue___JSCum:focus,
.bgDarkBlue___JSCum:active {
  background: #194d7d;
}
.bgGreyBlue___etsxl {
  background-color: #ebf0f9;
}
.bgLightBlue___SaKz7 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3V6YI {
  background-color: #e4453a;
}
.bgLightRed___3V6YI:hover,
.bgLightRed___3V6YI:focus,
.bgLightRed___3V6YI:active {
  background: #e17871;
}
.bgLightYellow___123FN {
  background-color: #ffd603;
}
.bgDarkGray___3okgZ {
  background-color: #333333;
}
.bgMediumGray___3W5R1 {
  background-color: #a2a2a2;
}
.bgLightGray___bs_GE {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___28hNx {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___2B2QQ {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___2l13k {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3fXba {
  color: #3368c7 !important;
}
.textDarkBlue___1zND9 {
  color: #023768 !important;
}
.textLightBlue___3bLeg {
  color: #b2cbf7;
}
.textDarkGray___3oRcX {
  color: #333333 !important;
}
.textMediumGray___2m2-D {
  color: #a2a2a2 !important;
}
.textLightGray___2FBgF {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2-tiS {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1m0IL {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3apqC {
  border-radius: 5px !important;
}
.rounded-xs___37n8q {
  border-radius: 10px !important;
}
.rounded-sm___1BlFU {
  border-radius: 15px !important;
}
.rounded-md___3h_2L {
  border-radius: 20px !important;
}
.rounded-lg___2GYj_ {
  border-radius: 25px !important;
}
.rounded-full___275zE {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3BxHo {
  margin: 0;
}
.mt-2___1cijR {
  margin-top: 0.5rem;
}
.my-2___2Ua_m {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___3KueB {
  padding: 0;
}
.px-1___jVP5P {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___9P8vd {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1WiUu {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___35eub {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___1hz9Y {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2kT1D {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___hVLSX {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___IgpAm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___2Dcw6 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2kA_S {
  padding-bottom: 20px;
}
.h-full___CXo8M {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3zwQu {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___1bT1v {
  font-weight: bold !important;
}
.fontWeight400___3fBwP {
  font-weight: 400 !important;
}
.fontWeight500___20tr3 {
  font-weight: 500 !important;
}
.fontWeight600___1EnCO {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___1Apo7 {
  font-size: 12px !important;
}
.fontSize14___1wVfI {
  font-size: 14px !important;
}
.fontSize16___gYiSz {
  font-size: 16px !important;
}
.fontSize18___3aGj2 {
  font-size: 18px !important;
}
.fontSize20___29Qy9 {
  font-size: 20px !important;
}
.fontSize24___J9RaO {
  font-size: 24px !important;
}
.eventName___1SsCW {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___hEQGm {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2ynw4 > tr > th,
.ant-table-tbody___16Kha > tr.ant-table-row___3Nulf > td,
.ant-table-summary___2ps9a tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2ynw4 > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___M5k0M .ant-table-thead___2ynw4 > tr > th,
.table-portrait___M5k0M .ant-table-tbody___16Kha > tr.ant-table-row___3Nulf > td,
.table-portrait___M5k0M .ant-table-summary___2ps9a tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___M5k0M .bgLightGray___bs_GE td {
  background: #ebebeb;
}
.table-portrait___M5k0M .bgLightBlue___SaKz7 td {
  background: #b2cbf7;
}
.eventContent___bDg5j .timeText___30vdU {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___16Kha > tr.bgLightGray___bs_GE:hover > td,
.ant-table-tbody___16Kha > tr > td.ant-table-cell-row-hover___3pnO3 {
  background: #ebebeb;
}
.ant-table-tbody___16Kha > tr.bgLightBlue___SaKz7:hover > td,
.ant-table-tbody___16Kha > tr > td.ant-table-cell-row-hover___3pnO3 {
  background: #b2cbf7;
}
.ant-table-tbody___16Kha > tr.bgWhite___2k1Uy:hover > td,
.ant-table-tbody___16Kha > tr > td.ant-table-cell-row-hover___3pnO3 {
  background: #ffffff;
}
.modalInputNumber___3n7A6 {
  width: 100% !important;
}
.btnZoneModal___25njZ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.btnZoneModal___25njZ .updateBtn___saEqB {
  height: 35px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 14px;
  color: #fffefe;
  border-radius: 19px;
  background-color: #33c3c7;
  border-color: #33c3c7;
  margin: 0 20px;
  width: 100%;
}
.btnZoneModal___25njZ .cancelBtn___ydYXs {
  height: 35px;
  max-width: 150px;
  padding: 0 15px;
  font-size: 14px;
  color: #535353;
  border-radius: 19px;
  background-color: #fffefe;
  margin: 0 20px;
  box-shadow: 1px 1px 15px #b3bcbc60;
  border: none;
  width: 100%;
}
.errorNotice___3rBLy {
  color: #ff4d4f;
  font-size: 12px;
}
.changeTime___s5Uaz {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 60px;
}
.changeTime___s5Uaz .center___3rrji {
  text-align: center;
}
.changeTime___s5Uaz .title___3_QN4 {
  font-size: 26px;
  font-weight: 700;
  border-bottom: 4px solid #33c3c7;
  display: inline-block;
  margin-bottom: 50px;
}
.changeTime___s5Uaz .description___2Xl4c {
  display: flex;
  align-items: center;
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .changeTime___s5Uaz .description___2Xl4c {
    font-size: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .changeTime___s5Uaz .description___2Xl4c img {
    margin-top: -7px;
    width: 29px;
    height: 25px;
  }
}
@media screen and (max-width: 767px) {
  .changeTime___s5Uaz .description___2Xl4c div {
    text-align: left;
  }
}
.changeTime___s5Uaz .description2___JhR7c {
  font-size: 22px;
  text-align: left;
  padding-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .changeTime___s5Uaz .description2___JhR7c {
    font-size: 12px;
  }
}
.changeTime___s5Uaz .form___1-Lnj {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.changeTime___s5Uaz .form___1-Lnj .text___1Jyd8 {
  margin-right: 45px;
  font-size: 22px;
}
.changeTime___s5Uaz .form___1-Lnj .text___1Jyd8:last-child {
  margin-right: 0;
}
.changeTime___s5Uaz .form___1-Lnj .ant-form-item {
  margin-bottom: 0;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number {
  width: 58px;
  height: 58px;
  border-radius: 13px;
  border: 0;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  margin-right: 10px;
}
.changeTime___s5Uaz .form___1-Lnj .ant-form-item .ant-input-number-handler-wrap {
  opacity: 1;
  border: 0;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-up {
  position: absolute;
  top: -57px;
  width: 58px;
  left: -36px;
  height: 42px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAnElEQVQokY3RsQpBcRTH8Q/dsngEkcED2O9AGcQDyCuYzEoms81mlVWRwW402O02D0AyIFL3f+9vOp3fOd9+nZOL41hABUywwSFpKAoR0EQb9xAkHwBMsUMdY2xRywqpYo/RX7+DI/ppkB5OaCWkK2KJ2e9u/sdcYPWu0zTEGQ1ehy1jji6uuGWA5FDBGoPI90NHXPDIAPmkL6HwBMCWF0Xn+GuwAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-up svg {
  display: none;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-up:hover {
  height: 42px !important;
  background-color: #ddf6f5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAnElEQVQokY3RsQpBcRTH8Q/dsngEkcED2O9AGcQDyCuYzEoms81mlVWRwW402O02D0AyIFL3f+9vOp3fOd9+nZOL41hABUywwSFpKAoR0EQb9xAkHwBMsUMdY2xRywqpYo/RX7+DI/ppkB5OaCWkK2KJ2e9u/sdcYPWu0zTEGQ1ehy1jji6uuGWA5FDBGoPI90NHXPDIAPmkL6HwBMCWF0Xn+GuwAAAAAElFTkSuQmCC);
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-up:active {
  height: 42px !important;
  background-color: #33c3c7;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAJJJREFUKJGNkSEOwlAQBaekSU0lkpAguASqOBIOQLgC9wCLw3EMVH0lV8DiEJUQBkMINJ//O8m6eW+TXVQiU6g7dRbzcuLMgQXwAJq/VmTD1l9O6jTkhsITtTZMq65TJau3mGKvDrolpXrsEf7molYqmToGDsASuAH3xLEBMmAItMAmh8+HzsAVePYoASiBEVC8AI7WLXosqfA2AAAAAElFTkSuQmCC);
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down {
  position: absolute;
  bottom: -131px;
  border: 0;
  height: auto;
  width: 66px;
  left: -40px;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down svg {
  display: none;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down .ant-input-number-handler-down-inner {
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAl0lEQVQYlY3OsQpBcRiG8Z/TGczqXAvl7C5ABoNdFOUOrEabshkscgEuQNmUXdmIpIjVQumUf+cdn973+b5CmqYJehjghJd8KSLBDPMYZ4xQQien5JsxhrhGH3BHF1Uccgj2qKGPK0SZwhoVLAKSJcpY/cKsCI5ooI3nD7+hhTou2VEcuDzB5vPBE03s/pVDIthiikdIAm8E2x3HKxuWhQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  width: 58px;
  height: 42px;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down:hover {
  height: auto !important;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down:hover .ant-input-number-handler-down-inner {
  height: 42px !important;
  background-color: #ddf6f5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAl0lEQVQYlY3OsQpBcRiG8Z/TGczqXAvl7C5ABoNdFOUOrEabshkscgEuQNmUXdmIpIjVQumUf+cdn973+b5CmqYJehjghJd8KSLBDPMYZ4xQQien5JsxhrhGH3BHF1Uccgj2qKGPK0SZwhoVLAKSJcpY/cKsCI5ooI3nD7+hhTou2VEcuDzB5vPBE03s/pVDIthiikdIAm8E2x3HKxuWhQAAAABJRU5ErkJggg==);
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down:active {
  background-color: transparent;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-handler-down:active .ant-input-number-handler-down-inner {
  height: 42px !important;
  background-color: #33c3c7;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAAF1R1/AAAAjElEQVQYlZXRoQrCYBiF4VdYWLGIg92EYPYCvAq7zeCdGNbWDAvivQhaFeuSxWF8TT+Mof+2D0475ynfRM2AHbAHauDDsEuBDDgCFSrqVC0cfwd1phKgkJX6HADc1XV724VQc/UUQc7qvLv7BYVs1aYFvNTNv34MQl2qD/WmLmLdpOczF6AE3sA1VvwCmqo9E5QyjH4AAAAASUVORK5CYII=);
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-input-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changeTime___s5Uaz .form___1-Lnj .ant-input-number-input {
  height: 100%;
  font-size: 26px;
  padding: 0 0 5px 0;
  z-index: 111;
  width: 30px;
  text-align: center;
}
.btnScheduleTime___2GBkb {
  height: 47px;
  width: 100%;
  max-width: 150px;
  border-radius: 20px;
  font-size: 18px;
  border: 0;
  margin-bottom: 30px;
}
.btnScheduleTime___2GBkb:hover {
  opacity: 0.8;
}
.cancelScheduleBtn___1rN3E {
  background-color: #e8e6e6;
  margin-right: 55px;
}
.cancelScheduleBtn___1rN3E:hover,
.cancelScheduleBtn___1rN3E:focus,
.cancelScheduleBtn___1rN3E:active {
  background-color: #e8e6e6;
  color: #3c3c3c;
}
.updateScheduleBtn___1DilS {
  background-color: #33c3c7;
  color: #fff;
}
.updateScheduleBtn___1DilS:hover,
.updateScheduleBtn___1DilS:focus,
.updateScheduleBtn___1DilS:active {
  background-color: #33c3c7;
  color: #fff;
}
