@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3tww0 {
  display: flex;
  justify-content: center;
}
button.btn___24BVt {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___24BVt {
    padding: 10px;
  }
}
button.btn___24BVt:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___24BVt:focus {
  outline: none;
}
.btnWhite___3ZgQ3.btn___24BVt {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3ZgQ3.btn___24BVt:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3U50t.btn___24BVt,
.btnGreen___3U50t.btn___24BVt:hover,
.btnGreen___3U50t.btn___24BVt:active,
.btnGreen___3U50t.btn___24BVt:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___2rZNN {
  color: #fe0000;
}
.errorMessage___3DDOh {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3Dnf9 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2jFvK {
  display: none !important;
}
.m-auto___o6j7i {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___1sEMP {
  max-width: 120px !important;
}
.fc___SrJci .fc-timegrid-col___heJyJ.fc-day-today___2A6Ou {
  background-color: #ffffff;
}
.fc___SrJci .fc-timegrid-slot___2yNr4 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___SrJci .fc-timegrid-slot___2yNr4 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___2f8zF .fc-direction-ltr___32urt .fc-timegrid-slot-label-frame___3d0P4 {
    font-size: 10px;
  }
  .bookingCalendar___2f8zF .fc___SrJci .fc-timegrid-slot-label-cushion___4BLux {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___2f8zF .fc___SrJci .fc-scrollgrid-section-header___EVR0R .fc-scroller___1kDeg {
    overflow: unset !important;
  }
}
.ant-message-notice___1p7y2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1qMHq {
  width: 20px;
  height: 20px;
}
.bgTransparent___3joC4 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___23Ecd {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___37wAs {
  position: relative;
}
.inputCustomValue___37wAs .ant-input___1guAT {
  padding-right: 50px;
}
.inputCustomValueText___2EJhq {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3KxFn .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3KxFn .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___1Ifti .ant-radio-inner___1V1qz {
  border-color: black !important ;
}
.ant-radio-checked___1Ifti .ant-radio-inner___1V1qz:after {
  background-color: black;
}
.ant-radio___2c1vB:hover .ant-radio-inner___1V1qz {
  border-color: black;
}
.ant-radio-checked___1Ifti .ant-radio-inner___1V1qz:focus {
  border-color: black;
}
.modal___22WJN {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___22WJN .close___F4cKW {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___22WJN .header___22wiN {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___22WJN .header___22wiN p {
  margin: 0;
}
.modal___22WJN .content___nUTId {
  text-align: center;
  font-size: 14px;
}
.modal___22WJN .content___nUTId p {
  margin: 0;
}
.modal___22WJN .actions___2nHMz {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___22WJN .actions___2nHMz .button___1Uq1N {
  margin-top: 10px;
}
.modal___22WJN .actions___2nHMz:hover {
  color: #33c3c7;
}
.fc___SrJci .fc-timegrid-slot-minor___2oEsu {
  border-top-style: none;
}
.fc___SrJci .fc-timegrid-slot___2yNr4 {
  height: 1.5em !important;
}
.messageError___2K1hK .ant-message-custom-content___2gTgR {
  display: flex;
  text-align: start;
}
.messageError___2K1hK .ant-message-custom-content___2gTgR span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___27YJK {
  background-color: #e6e4e4;
}
.notifyMessage___Fbk0t .ant-message-notice-content___3r7Qj {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___Fbk0t .ant-message-notice-content___3r7Qj .anticon___10_4a {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___Fbk0t .ant-message-notice-content___3r7Qj span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___LZp5W {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___LZp5W .ant-notification-notice-message___VK3Og,
.notifyCustomize___LZp5W .ant-notification-notice-close___2owXp,
.notifyCustomize___LZp5W .ant-notification-notice-icon___2otQ9 {
  color: white;
}
.notifyCustomize___LZp5W .ant-notification-notice-message___VK3Og {
  margin-left: 35px;
}
.notifyCustomize___LZp5W p {
  margin-bottom: 0;
}
.notifyCustomize___LZp5W p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3rxZT {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3rxZT .ant-notification-notice-message___VK3Og {
  margin-left: 35px;
}
.notifyAlertDuplicate___3rxZT p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3rxZT p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___aZ9RU {
  width: 434px !important;
}
.handleSubmitVote___aZ9RU .ant-btn-primary___2jxcX {
  background: #59c3c7;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb {
  padding: 15px 30px 18px;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb .ant-modal-confirm-body___2OfeR {
  display: flex !important;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb .ant-modal-confirm-body___2OfeR .anticon___10_4a {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb .ant-modal-confirm-btns___3apXK button {
  font-weight: bold;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb .ant-modal-confirm-btns___3apXK button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb .ant-modal-confirm-btns___3apXK button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___aZ9RU .ant-modal-body___Y-bEb .ant-modal-confirm-btns___3apXK .ant-btn-primary___2jxcX {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___2gCRS {
  margin-bottom: 0;
}
.mt-0___-iKRg {
  margin-top: 0;
}
.tooltipFormat___3BP5W p {
  margin-bottom: 0;
}
.bgWhite___18DJh {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___ySizm {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___ySizm:hover,
.bgPrimaryBlue___ySizm:focus,
.bgPrimaryBlue___ySizm:active {
  background: #4f7ac7;
}
.bgDarkBlue___3qN6_ {
  background-color: #023768 !important;
}
.bgDarkBlue___3qN6_:hover,
.bgDarkBlue___3qN6_:focus,
.bgDarkBlue___3qN6_:active {
  background: #194d7d;
}
.bgGreyBlue___xGmfr {
  background-color: #ebf0f9;
}
.bgLightBlue___1LIvT {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2J7eZ {
  background-color: #e4453a;
}
.bgLightRed___2J7eZ:hover,
.bgLightRed___2J7eZ:focus,
.bgLightRed___2J7eZ:active {
  background: #e17871;
}
.bgLightYellow___3CkDU {
  background-color: #ffd603;
}
.bgDarkGray___3Bv-Z {
  background-color: #333333;
}
.bgMediumGray___3--5a {
  background-color: #a2a2a2;
}
.bgLightGray___26XPR {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___CfWTq {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3OnNB {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___1ysMg {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___18Yce {
  color: #3368c7 !important;
}
.textDarkBlue___UmzKR {
  color: #023768 !important;
}
.textLightBlue___11fXO {
  color: #b2cbf7;
}
.textDarkGray___2v37p {
  color: #333333 !important;
}
.textMediumGray___1KFMY {
  color: #a2a2a2 !important;
}
.textLightGray___2hfg8 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___12bJL {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1mh7w {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___6fuwV {
  border-radius: 5px !important;
}
.rounded-xs___21HrT {
  border-radius: 10px !important;
}
.rounded-sm___3SHdB {
  border-radius: 15px !important;
}
.rounded-md___2-DGN {
  border-radius: 20px !important;
}
.rounded-lg___1IwRZ {
  border-radius: 25px !important;
}
.rounded-full___3saD2 {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2zvrg {
  margin: 0;
}
.mt-2___3pM4H {
  margin-top: 0.5rem;
}
.my-2___nWUPP {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1d8xE {
  padding: 0;
}
.px-1___17NrP {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2Z-wv {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___31nd2 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___NtMDi {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3TTjT {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2eDGk {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___1fYL7 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3CWzl {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3xXRI {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___JhRAF {
  padding-bottom: 20px;
}
.h-full___162xe {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___1a2yV {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___3KjoR {
  font-weight: bold !important;
}
.fontWeight400___o2DVB {
  font-weight: 400 !important;
}
.fontWeight500___1KPtK {
  font-weight: 500 !important;
}
.fontWeight600___BG0Fz {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___19RNA {
  font-size: 12px !important;
}
.fontSize14___2x3iL {
  font-size: 14px !important;
}
.fontSize16___1Ra8p {
  font-size: 16px !important;
}
.fontSize18___KlPtd {
  font-size: 18px !important;
}
.fontSize20___1ROva {
  font-size: 20px !important;
}
.fontSize24___2MgH4 {
  font-size: 24px !important;
}
.eventName___sGMAD {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___31MaE {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___22LlA > tr > th,
.ant-table-tbody___z-1UB > tr.ant-table-row___1wUqc > td,
.ant-table-summary___eBiBU tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___22LlA > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___Q6QMJ .ant-table-thead___22LlA > tr > th,
.table-portrait___Q6QMJ .ant-table-tbody___z-1UB > tr.ant-table-row___1wUqc > td,
.table-portrait___Q6QMJ .ant-table-summary___eBiBU tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___Q6QMJ .bgLightGray___26XPR td {
  background: #ebebeb;
}
.table-portrait___Q6QMJ .bgLightBlue___1LIvT td {
  background: #b2cbf7;
}
.eventContent___k1vO- .timeText___2NQE_ {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___z-1UB > tr.bgLightGray___26XPR:hover > td,
.ant-table-tbody___z-1UB > tr > td.ant-table-cell-row-hover___115mO {
  background: #ebebeb;
}
.ant-table-tbody___z-1UB > tr.bgLightBlue___1LIvT:hover > td,
.ant-table-tbody___z-1UB > tr > td.ant-table-cell-row-hover___115mO {
  background: #b2cbf7;
}
.ant-table-tbody___z-1UB > tr.bgWhite___18DJh:hover > td,
.ant-table-tbody___z-1UB > tr > td.ant-table-cell-row-hover___115mO {
  background: #ffffff;
}
.mainLayout___jQ3k5 {
  background-color: #fefefe;
  cursor: default !important;
}
.mainLayout___jQ3k5 .backToList___14Buh {
  height: 94px;
  width: 100%;
  max-width: 1300px;
  background-color: #cdcdcd;
  display: flex;
  align-items: center;
  padding: 23px 30px;
  border-radius: 5px;
  margin: 22px 0 28px 0;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .backToList___14Buh {
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
  }
}
.mainLayout___jQ3k5 .backToList___14Buh .noticeText___2Ff9q {
  width: 80%;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .backToList___14Buh .noticeText___2Ff9q {
    width: 100%;
  }
}
.mainLayout___jQ3k5 .backToList___14Buh .noticeText___2Ff9q p {
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .backToList___14Buh .noticeText___2Ff9q p {
    font-size: 15px;
    margin-bottom: 10px;
  }
}
.mainLayout___jQ3k5 .backToList___14Buh .btnZone___Y3bmU {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .backToList___14Buh .btnZone___Y3bmU {
    width: 100%;
    text-align: center;
  }
}
.mainLayout___jQ3k5 .backToList___14Buh .btnZone___Y3bmU button {
  height: 48px;
  border-radius: 20px;
  width: 148px;
  background-color: #ffffff;
  color: #3e3e3e;
  font-size: 17px;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .backToList___14Buh .btnZone___Y3bmU button {
    height: 40px;
  }
}
.mainLayout___jQ3k5 .mainContent___2PdNl {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 15px;
}
@media screen and (max-width: 1300px) {
  .mainLayout___jQ3k5 .mainContent___2PdNl {
    width: 100% !important;
  }
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .mainContent___2PdNl {
    padding: 0;
  }
}
.mainLayout___jQ3k5 .desktop___2OcW9 {
  display: block;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .desktop___2OcW9 {
    display: none;
  }
}
.mainLayout___jQ3k5 .mobile___ucwc7 {
  display: none;
}
@media screen and (max-width: 767px) {
  .mainLayout___jQ3k5 .mobile___ucwc7 {
    display: block;
  }
}
.scheduleAdjustment___18R9h {
  font-weight: 500;
}
.scheduleAdjustment___18R9h .bigTitle___1hrn0 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.scheduleAdjustment___18R9h .bigTitle___1hrn0 .bolderIcon___fcB9D {
  width: 8px;
  height: 35px;
  background-color: #33c3c7;
  margin-right: 4px;
  display: inline-block;
}
.scheduleAdjustment___18R9h .bigTitle___1hrn0 .titleIcon___z_2cv {
  width: 3px;
  height: 35px;
  background-color: #33c3c7;
  margin-right: 15px;
  display: inline-block;
}
.scheduleAdjustment___18R9h .bigTitle___1hrn0 span {
  font-size: 24px;
  color: #000000;
}
.scheduleAdjustment___18R9h .intruction___dhAKo {
  font-size: 21px;
  color: #414141;
  margin: 18px 0 24px 0;
  padding-left: 17px;
  border-left: 7px solid #282728;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___18R9h .intruction___dhAKo {
    border-left-width: 5px;
    font-size: 16px;
  }
}
.scheduleAdjustment___18R9h .progressBar___xsTz6 {
  padding: 0 55px;
  display: flex;
  position: relative;
  margin: auto;
  justify-content: space-between;
  max-width: 53%;
}
.scheduleAdjustment___18R9h .progressBar___xsTz6::after {
  content: '';
  position: absolute;
  top: 90%;
  left: 95px;
  transform: translateY(-50%);
  width: calc(100% - 190px);
  height: 100%;
  padding: 0 35px;
  font-size: 20px;
  line-height: 1;
  color: #a1a0a0;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjYAAAAECAYAAACdm3W6AAAA/ElEQVRoge2YsRHCMAxFHxmFFWAEskJauwojhBEYw2lhBVZghayQFWhc5NLENjYXGan1k+6/O6nxYXTuBDyACeiMtTMbNTo3AD3wMtZeC/BRmUrziQ67cv6Rgzpv8zU4633KdPhHZ91VmQ5fOTfAHTgCFz8opAbf04/OXQrwsZlK8yDfOSWTOufna3DW+8zPp2RS5/yZ1Dmsdr2rDfBePE4BA5bcvOrPxcdmKs0vOanOKZnUOT9fg7PeZ34+JZM658+kzmG1611tjLU3oANaY+0zYABA63vOId9WsXxsptK8L9HOKZlK8+os01nvU6ZDLF+Ds+6qTIdYfu3wAZJTby0ImNz8AAAAAElFTkSuQmCC);
  background-size: contain;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___18R9h .progressBar___xsTz6::after {
    left: 40px;
    width: calc(100% - 80px);
  }
}
.scheduleAdjustment___18R9h .progressBar___xsTz6 .firstStep___3A3F6 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 16px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.scheduleAdjustment___18R9h .progressBar___xsTz6 .firstStep___3A3F6 p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 7px);
  color: #414141;
  font-size: 16px;
  min-width: 200px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___18R9h .progressBar___xsTz6 .firstStep___3A3F6 p {
    font-size: 14px;
  }
}
.scheduleAdjustment___18R9h .progressBar___xsTz6 .activeStep___2LMK- {
  background-color: #33c3c7 !important;
  color: #eff6fc !important;
  box-shadow: none !important;
}
.scheduleAdjustment___18R9h .progressBar___xsTz6 .endStep___3JoYt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #414141;
  font-size: 16px;
  z-index: 1;
  box-shadow: 1px 1px 10px #b3bcbc60;
  cursor: pointer;
  position: relative;
}
.scheduleAdjustment___18R9h .progressBar___xsTz6 .endStep___3JoYt p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 7px);
  color: #414141;
  font-size: 16px;
  min-width: 120px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___18R9h .progressBar___xsTz6 .endStep___3JoYt p {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___18R9h .progressBar___xsTz6 {
    padding: 0 35px 0 35px;
    max-width: 75%;
  }
  .scheduleAdjustment___18R9h .progressBar___xsTz6 .firstStep___3A3F6 p,
  .scheduleAdjustment___18R9h .progressBar___xsTz6 .endStep___3JoYt p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 425px) {
  .scheduleAdjustment___18R9h .progressBar___xsTz6 {
    max-width: 85%;
  }
  .scheduleAdjustment___18R9h .progressBar___xsTz6 .firstStep___3A3F6 p,
  .scheduleAdjustment___18R9h .progressBar___xsTz6 .endStep___3JoYt p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}
.scheduleAdjustment___18R9h .pinnedText___3liAN {
  font-size: 16px;
  margin: 0 0 15px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___18R9h .pinnedText___3liAN {
    margin: 0 0 15px 0;
  }
}
.scheduleAdjustment___18R9h .pinnedText___3liAN img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV {
  margin-top: 73px;
  padding-top: 25px;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .meetingInfo___2533N {
  position: relative;
  padding-bottom: 1.2rem;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .meetingInfo___2533N .meetingMethod___1o7pP {
  word-break: break-all;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .meetingInfo___2533N .meetingDetail___HWmXh {
  width: 90%;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .bigTitle___1hrn0 {
  margin-bottom: 37px;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___18R9h .firstStepDetail___3_wzV .bigTitle___1hrn0 {
    margin-bottom: 15px;
    flex-wrap: nowrap;
    line-height: 1.3;
  }
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ p {
  margin: 0;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ .infoCompany___3u4gV {
  font-size: 16px;
  margin-bottom: 12px;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ .avatar___2CKNm {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  overflow: hidden;
  position: relative;
  border: 2px solid #33c3c7;
}
@media screen and (max-width: 767px) {
  .scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ .avatar___2CKNm {
    width: 50px;
    height: 50px;
  }
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ .avatar___2CKNm img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-position: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ .infoName___2ygQL {
  font-size: 13px;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .userInfo___270m_ .meetingDetail___HWmXh {
  width: 90%;
  font-size: 10px;
  margin: 20px 0 30px 0;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK {
  padding: 25px 10px;
  box-shadow: 1px 1px 10px #b3bcbc60;
  border-radius: 20px;
  min-height: 420px;
  height: 430px;
  overflow-y: auto;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK::-webkit-scrollbar {
  display: none;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK .listFrame___1jSTz {
  height: 390px;
  width: 100%;
  overflow-y: auto;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK .listFrame___1jSTz::-webkit-scrollbar {
  display: none;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK .listFrame___1jSTz .frame___eWOfV {
  height: 40px;
  min-width: 160px;
  margin-bottom: 14px;
  border: 2px solid #dadada;
  color: #414141;
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK .listFrame___1jSTz .frame___eWOfV:hover {
  border: 3px solid #33c3c7;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK .listFrame___1jSTz .chosenFrame___2Oiri {
  border: 3px solid #33c3c7;
}
.scheduleAdjustment___18R9h .firstStepDetail___3_wzV .listTimeFrame___3jxxK .listFrame___1jSTz .completedFrame___3xWrP {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
@media screen and (max-width: 768px) {
  .scheduleAdjustment___18R9h .firstStepDetail___3_wzV .boxInforUser___2GC88 {
    display: none;
  }
  .scheduleAdjustment___18R9h .firstStepDetail___3_wzV .boxBooking___36U3y {
    max-width: 100% !important;
    width: 100% !important;
    flex: 0 0 100% !important;
  }
}
.scheduleAdjustment___18R9h .secondStepHeader___17MDI {
  width: 90%;
}
.scheduleAdjustment___18R9h .secondStepHeader___17MDI .searchKeyword___2yS5e textarea {
  padding: 8px 10px;
  background: #fbfbfb;
  border: 2px solid #ebebeb;
  border-radius: 11px;
  width: 100%;
}
.scheduleAdjustmentModal___2ZiyN {
  max-width: 350px;
  margin: auto;
}
.scheduleAdjustmentModal___2ZiyN .firstStepDetail___3_wzV {
  margin-top: 0;
  padding-top: 0;
}
.scheduleAdjustmentModal___2ZiyN .firstStepDetail___3_wzV .userInfo___270m_ {
  margin-bottom: 0;
}
.scheduleAdjustmentModal___2ZiyN .firstStepDetail___3_wzV .meetingInfo___2533N {
  padding-bottom: 0;
}
.scheduleAdjustmentModal___2ZiyN .dottedBar___A7MBo {
  font-size: 20px;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  line-height: 1;
  color: #a1a0a0;
  padding: 15px 0;
}
@media screen and (max-width: 768px) {
  .padding___1xczn {
    padding: 0 15px;
  }
}
