@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___3KRRB {
  display: flex;
  justify-content: center;
}
button.btn___2_Jev {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___2_Jev {
    padding: 10px;
  }
}
button.btn___2_Jev:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___2_Jev:focus {
  outline: none;
}
.btnWhite___UamWK.btn___2_Jev {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___UamWK.btn___2_Jev:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___ncL_6.btn___2_Jev,
.btnGreen___ncL_6.btn___2_Jev:hover,
.btnGreen___ncL_6.btn___2_Jev:active,
.btnGreen___ncL_6.btn___2_Jev:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___94uhM {
  color: #fe0000;
}
.errorMessage___879h0 {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3Kp9F {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___cjhoI {
  display: none !important;
}
.m-auto___4v3Gr {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___IwKhB {
  max-width: 120px !important;
}
.fc___3OPxa .fc-timegrid-col___13Ash.fc-day-today___124Zh {
  background-color: #ffffff;
}
.fc___3OPxa .fc-timegrid-slot___2cH7m {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___3OPxa .fc-timegrid-slot___2cH7m {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3zJZL .fc-direction-ltr___3EgN2 .fc-timegrid-slot-label-frame___9e-r8 {
    font-size: 10px;
  }
  .bookingCalendar___3zJZL .fc___3OPxa .fc-timegrid-slot-label-cushion___2gXB1 {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3zJZL .fc___3OPxa .fc-scrollgrid-section-header___2scm6 .fc-scroller___1FOA2 {
    overflow: unset !important;
  }
}
.ant-message-notice___Kv-K3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1_yu2 {
  width: 20px;
  height: 20px;
}
.bgTransparent___3EtiM {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___1_k1X {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1DlpI {
  position: relative;
}
.inputCustomValue___1DlpI .ant-input___2JvGK {
  padding-right: 50px;
}
.inputCustomValueText___2vOGe {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm____Jaxh .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm____Jaxh .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___J8NPf .ant-radio-inner___1sskJ {
  border-color: black !important ;
}
.ant-radio-checked___J8NPf .ant-radio-inner___1sskJ:after {
  background-color: black;
}
.ant-radio___2X787:hover .ant-radio-inner___1sskJ {
  border-color: black;
}
.ant-radio-checked___J8NPf .ant-radio-inner___1sskJ:focus {
  border-color: black;
}
.modal___1p_eW {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___1p_eW .close___1btf_ {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___1p_eW .header___ex_xd {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___1p_eW .header___ex_xd p {
  margin: 0;
}
.modal___1p_eW .content___3SmDj {
  text-align: center;
  font-size: 14px;
}
.modal___1p_eW .content___3SmDj p {
  margin: 0;
}
.modal___1p_eW .actions___3ZBEq {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___1p_eW .actions___3ZBEq .button___2KWAJ {
  margin-top: 10px;
}
.modal___1p_eW .actions___3ZBEq:hover {
  color: #33c3c7;
}
.fc___3OPxa .fc-timegrid-slot-minor___2l0-M {
  border-top-style: none;
}
.fc___3OPxa .fc-timegrid-slot___2cH7m {
  height: 1.5em !important;
}
.messageError___3_ucP .ant-message-custom-content___1TOGE {
  display: flex;
  text-align: start;
}
.messageError___3_ucP .ant-message-custom-content___1TOGE span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___2IB8K {
  background-color: #e6e4e4;
}
.notifyMessage___M2VJa .ant-message-notice-content___34Dla {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___M2VJa .ant-message-notice-content___34Dla .anticon___iW34_ {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___M2VJa .ant-message-notice-content___34Dla span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___nWFnr {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___nWFnr .ant-notification-notice-message___-prgz,
.notifyCustomize___nWFnr .ant-notification-notice-close___vo3LG,
.notifyCustomize___nWFnr .ant-notification-notice-icon___22DRA {
  color: white;
}
.notifyCustomize___nWFnr .ant-notification-notice-message___-prgz {
  margin-left: 35px;
}
.notifyCustomize___nWFnr p {
  margin-bottom: 0;
}
.notifyCustomize___nWFnr p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___16lwi {
  padding: 16px 14px;
}
.notifyAlertDuplicate___16lwi .ant-notification-notice-message___-prgz {
  margin-left: 35px;
}
.notifyAlertDuplicate___16lwi p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___16lwi p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___2bM18 {
  width: 434px !important;
}
.handleSubmitVote___2bM18 .ant-btn-primary___3kkTw {
  background: #59c3c7;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n {
  padding: 15px 30px 18px;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n .ant-modal-confirm-body___L8jzk {
  display: flex !important;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n .ant-modal-confirm-body___L8jzk .anticon___iW34_ {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n .ant-modal-confirm-btns___s8QRU button {
  font-weight: bold;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n .ant-modal-confirm-btns___s8QRU button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n .ant-modal-confirm-btns___s8QRU button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___2bM18 .ant-modal-body___3QO_n .ant-modal-confirm-btns___s8QRU .ant-btn-primary___3kkTw {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___pusZp {
  margin-bottom: 0;
}
.mt-0___3TB8L {
  margin-top: 0;
}
.tooltipFormat___RMTuC p {
  margin-bottom: 0;
}
.bgWhite___3B5hs {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3PtZQ {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3PtZQ:hover,
.bgPrimaryBlue___3PtZQ:focus,
.bgPrimaryBlue___3PtZQ:active {
  background: #4f7ac7;
}
.bgDarkBlue___F7dXp {
  background-color: #023768 !important;
}
.bgDarkBlue___F7dXp:hover,
.bgDarkBlue___F7dXp:focus,
.bgDarkBlue___F7dXp:active {
  background: #194d7d;
}
.bgGreyBlue___27CtH {
  background-color: #ebf0f9;
}
.bgLightBlue___1_7ht {
  background-color: #b2cbf7 !important;
}
.bgLightRed___30lTw {
  background-color: #e4453a;
}
.bgLightRed___30lTw:hover,
.bgLightRed___30lTw:focus,
.bgLightRed___30lTw:active {
  background: #e17871;
}
.bgLightYellow___1NHJB {
  background-color: #ffd603;
}
.bgDarkGray___UxAdR {
  background-color: #333333;
}
.bgMediumGray___2jujf {
  background-color: #a2a2a2;
}
.bgLightGray___1Q5DF {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3tv5N {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___P2Kjz {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___3Z1wh {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1BF8i {
  color: #3368c7 !important;
}
.textDarkBlue___tQzRT {
  color: #023768 !important;
}
.textLightBlue___NtWcF {
  color: #b2cbf7;
}
.textDarkGray___2CiGL {
  color: #333333 !important;
}
.textMediumGray____gNZi {
  color: #a2a2a2 !important;
}
.textLightGray___3m4UW {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___1lqz- {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3oDVH {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2Wj_o {
  border-radius: 5px !important;
}
.rounded-xs___2SbpF {
  border-radius: 10px !important;
}
.rounded-sm___1-gjW {
  border-radius: 15px !important;
}
.rounded-md___s0qCs {
  border-radius: 20px !important;
}
.rounded-lg___2taIF {
  border-radius: 25px !important;
}
.rounded-full___306VP {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3JAzl {
  margin: 0;
}
.mt-2___2WodO {
  margin-top: 0.5rem;
}
.my-2___34gf8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___1NVEW {
  padding: 0;
}
.px-1___3ZMFF {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2RuBg {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___1N74C {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3Wlrz {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2QqNw {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___MtI_v {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3-SEa {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___3Cu-h {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___3a_Qv {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2Kfub {
  padding-bottom: 20px;
}
.h-full___2jvw3 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___33_bt {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold____10x9 {
  font-weight: bold !important;
}
.fontWeight400___2T5Tm {
  font-weight: 400 !important;
}
.fontWeight500___1k3gj {
  font-weight: 500 !important;
}
.fontWeight600___UJHTt {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3pv3g {
  font-size: 12px !important;
}
.fontSize14___2FjR9 {
  font-size: 14px !important;
}
.fontSize16___3zjWp {
  font-size: 16px !important;
}
.fontSize18___1MxU_ {
  font-size: 18px !important;
}
.fontSize20___2aIpo {
  font-size: 20px !important;
}
.fontSize24___2lrPi {
  font-size: 24px !important;
}
.eventName___2ah24 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___2pJeg {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1ABGb > tr > th,
.ant-table-tbody___22neH > tr.ant-table-row___2hVhK > td,
.ant-table-summary___3u5H6 tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1ABGb > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___S3e6a .ant-table-thead___1ABGb > tr > th,
.table-portrait___S3e6a .ant-table-tbody___22neH > tr.ant-table-row___2hVhK > td,
.table-portrait___S3e6a .ant-table-summary___3u5H6 tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___S3e6a .bgLightGray___1Q5DF td {
  background: #ebebeb;
}
.table-portrait___S3e6a .bgLightBlue___1_7ht td {
  background: #b2cbf7;
}
.eventContent___3p5QY .timeText___3W7R- {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___22neH > tr.bgLightGray___1Q5DF:hover > td,
.ant-table-tbody___22neH > tr > td.ant-table-cell-row-hover___2oh1r {
  background: #ebebeb;
}
.ant-table-tbody___22neH > tr.bgLightBlue___1_7ht:hover > td,
.ant-table-tbody___22neH > tr > td.ant-table-cell-row-hover___2oh1r {
  background: #b2cbf7;
}
.ant-table-tbody___22neH > tr.bgWhite___3B5hs:hover > td,
.ant-table-tbody___22neH > tr > td.ant-table-cell-row-hover___2oh1r {
  background: #ffffff;
}
.modalDelete___TifDd .modalTitle___s4Wvo {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;
  text-align: center;
}
.modalDelete___TifDd .modalDescription___29xqo {
  text-align: center;
  font-size: 17px;
  margin-bottom: 50px;
}
.modalDelete___TifDd .btn.btnWhite:hover {
  border: 2px solid #909090;
}
