@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2xDFF {
  display: flex;
  justify-content: center;
}
button.btn___3ilte {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___3ilte {
    padding: 10px;
  }
}
button.btn___3ilte:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___3ilte:focus {
  outline: none;
}
.btnWhite___2IMk4.btn___3ilte {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2IMk4.btn___3ilte:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2etSE.btn___3ilte,
.btnGreen___2etSE.btn___3ilte:hover,
.btnGreen___2etSE.btn___3ilte:active,
.btnGreen___2etSE.btn___3ilte:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___1AXIT {
  color: #fe0000;
}
.errorMessage___25WDJ {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___2N6Zz {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___2GmV9 {
  display: none !important;
}
.m-auto___3mKjY {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___XhOWc {
  max-width: 120px !important;
}
.fc___2j-oY .fc-timegrid-col___gdcHA.fc-day-today___xLyJm {
  background-color: #ffffff;
}
.fc___2j-oY .fc-timegrid-slot___b67dD {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___2j-oY .fc-timegrid-slot___b67dD {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3EPZP .fc-direction-ltr___1tvQ_ .fc-timegrid-slot-label-frame___1d3Fn {
    font-size: 10px;
  }
  .bookingCalendar___3EPZP .fc___2j-oY .fc-timegrid-slot-label-cushion___2LVfK {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3EPZP .fc___2j-oY .fc-scrollgrid-section-header___IcJ3b .fc-scroller___16msV {
    overflow: unset !important;
  }
}
.ant-message-notice___iO9Dz {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3brEi {
  width: 20px;
  height: 20px;
}
.bgTransparent___1JfGA {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___EeSQE {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3rXKl {
  position: relative;
}
.inputCustomValue___3rXKl .ant-input___2Veqa {
  padding-right: 50px;
}
.inputCustomValueText___VOknw {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___3XXLH .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___3XXLH .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___39HH9 .ant-radio-inner___C_zAY {
  border-color: black !important ;
}
.ant-radio-checked___39HH9 .ant-radio-inner___C_zAY:after {
  background-color: black;
}
.ant-radio___2FTnv:hover .ant-radio-inner___C_zAY {
  border-color: black;
}
.ant-radio-checked___39HH9 .ant-radio-inner___C_zAY:focus {
  border-color: black;
}
.modal___3y1V- {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___3y1V- .close___1rATq {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___3y1V- .header___336mr {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___3y1V- .header___336mr p {
  margin: 0;
}
.modal___3y1V- .content___3n-Ju {
  text-align: center;
  font-size: 14px;
}
.modal___3y1V- .content___3n-Ju p {
  margin: 0;
}
.modal___3y1V- .actions___B2JE5 {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___3y1V- .actions___B2JE5 .button___1hfiN {
  margin-top: 10px;
}
.modal___3y1V- .actions___B2JE5:hover {
  color: #33c3c7;
}
.fc___2j-oY .fc-timegrid-slot-minor___2apkg {
  border-top-style: none;
}
.fc___2j-oY .fc-timegrid-slot___b67dD {
  height: 1.5em !important;
}
.messageError___2R9pA .ant-message-custom-content___2sgUK {
  display: flex;
  text-align: start;
}
.messageError___2R9pA .ant-message-custom-content___2sgUK span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3M-Vl {
  background-color: #e6e4e4;
}
.notifyMessage___2Oanb .ant-message-notice-content___3sCDd {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___2Oanb .ant-message-notice-content___3sCDd .anticon___W4_gL {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___2Oanb .ant-message-notice-content___3sCDd span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3TIZW {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3TIZW .ant-notification-notice-message___1zdJv,
.notifyCustomize___3TIZW .ant-notification-notice-close___30LVa,
.notifyCustomize___3TIZW .ant-notification-notice-icon___2u-Yq {
  color: white;
}
.notifyCustomize___3TIZW .ant-notification-notice-message___1zdJv {
  margin-left: 35px;
}
.notifyCustomize___3TIZW p {
  margin-bottom: 0;
}
.notifyCustomize___3TIZW p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___2gize {
  padding: 16px 14px;
}
.notifyAlertDuplicate___2gize .ant-notification-notice-message___1zdJv {
  margin-left: 35px;
}
.notifyAlertDuplicate___2gize p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___2gize p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1Zuxl {
  width: 434px !important;
}
.handleSubmitVote___1Zuxl .ant-btn-primary___2cdCE {
  background: #59c3c7;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt .ant-modal-confirm-body___3Rwb2 {
  display: flex !important;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt .ant-modal-confirm-body___3Rwb2 .anticon___W4_gL {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt .ant-modal-confirm-btns___30z3Y button {
  font-weight: bold;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt .ant-modal-confirm-btns___30z3Y button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt .ant-modal-confirm-btns___30z3Y button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1Zuxl .ant-modal-body___3d0pt .ant-modal-confirm-btns___30z3Y .ant-btn-primary___2cdCE {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___1rXzH {
  margin-bottom: 0;
}
.mt-0___a32dm {
  margin-top: 0;
}
.tooltipFormat___2SwpM p {
  margin-bottom: 0;
}
.bgWhite___1dIaf {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___26CoO {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___26CoO:hover,
.bgPrimaryBlue___26CoO:focus,
.bgPrimaryBlue___26CoO:active {
  background: #4f7ac7;
}
.bgDarkBlue___1gUH_ {
  background-color: #023768 !important;
}
.bgDarkBlue___1gUH_:hover,
.bgDarkBlue___1gUH_:focus,
.bgDarkBlue___1gUH_:active {
  background: #194d7d;
}
.bgGreyBlue___3m9zg {
  background-color: #ebf0f9;
}
.bgLightBlue___2FDP7 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___3WAkB {
  background-color: #e4453a;
}
.bgLightRed___3WAkB:hover,
.bgLightRed___3WAkB:focus,
.bgLightRed___3WAkB:active {
  background: #e17871;
}
.bgLightYellow___xxdvs {
  background-color: #ffd603;
}
.bgDarkGray___1zsl7 {
  background-color: #333333;
}
.bgMediumGray___2qLTy {
  background-color: #a2a2a2;
}
.bgLightGray___27c0Z {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___Zv5Ok {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3qpIh {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___AjUdw {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___2-RVd {
  color: #3368c7 !important;
}
.textDarkBlue___WdWBd {
  color: #023768 !important;
}
.textLightBlue___2gsJ_ {
  color: #b2cbf7;
}
.textDarkGray___3CESu {
  color: #333333 !important;
}
.textMediumGray___3SMRr {
  color: #a2a2a2 !important;
}
.textLightGray___kG5d8 {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___3B_5t {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___1krWZ {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___3T_MH {
  border-radius: 5px !important;
}
.rounded-xs___3sY7P {
  border-radius: 10px !important;
}
.rounded-sm___2qhFo {
  border-radius: 15px !important;
}
.rounded-md___E4lVE {
  border-radius: 20px !important;
}
.rounded-lg___2qFqr {
  border-radius: 25px !important;
}
.rounded-full___3a0QO {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___ACgpI {
  margin: 0;
}
.mt-2___1DcqU {
  margin-top: 0.5rem;
}
.my-2___ni8oD {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2FRYp {
  padding: 0;
}
.px-1___22Xiv {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2yUby {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___27W-- {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___1kCiM {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2wx5G {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___3orcz {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3Tw7Z {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___mbzZ1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___Sx2BD {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2zXqB {
  padding-bottom: 20px;
}
.h-full___3w3rs {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___A0YZ7 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2Zcd- {
  font-weight: bold !important;
}
.fontWeight400___2G1EP {
  font-weight: 400 !important;
}
.fontWeight500___8yTBs {
  font-weight: 500 !important;
}
.fontWeight600___20erY {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___GAskM {
  font-size: 12px !important;
}
.fontSize14___2ieb3 {
  font-size: 14px !important;
}
.fontSize16___lWds7 {
  font-size: 16px !important;
}
.fontSize18___JEefK {
  font-size: 18px !important;
}
.fontSize20___20GH_ {
  font-size: 20px !important;
}
.fontSize24___3ofQe {
  font-size: 24px !important;
}
.eventName___Mtmt2 {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___3M2O5 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___2LEkw > tr > th,
.ant-table-tbody___1Irya > tr.ant-table-row___1GurM > td,
.ant-table-summary___2uCcN tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___2LEkw > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___BfAVo .ant-table-thead___2LEkw > tr > th,
.table-portrait___BfAVo .ant-table-tbody___1Irya > tr.ant-table-row___1GurM > td,
.table-portrait___BfAVo .ant-table-summary___2uCcN tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___BfAVo .bgLightGray___27c0Z td {
  background: #ebebeb;
}
.table-portrait___BfAVo .bgLightBlue___2FDP7 td {
  background: #b2cbf7;
}
.eventContent___2lFmu .timeText___1d24N {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___1Irya > tr.bgLightGray___27c0Z:hover > td,
.ant-table-tbody___1Irya > tr > td.ant-table-cell-row-hover___39uad {
  background: #ebebeb;
}
.ant-table-tbody___1Irya > tr.bgLightBlue___2FDP7:hover > td,
.ant-table-tbody___1Irya > tr > td.ant-table-cell-row-hover___39uad {
  background: #b2cbf7;
}
.ant-table-tbody___1Irya > tr.bgWhite___1dIaf:hover > td,
.ant-table-tbody___1Irya > tr > td.ant-table-cell-row-hover___39uad {
  background: #ffffff;
}
.expiredFree___cH2jd {
  position: relative;
  padding-bottom: 80px;
  min-height: 100vh;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX {
  max-width: 1080px;
  width: 100%;
  margin: auto;
  padding: 65px 15px;
}
@media screen and (max-width: 767px) {
  .expiredFree___cH2jd .expiredFreeContainer___60lbX {
    padding: 65px 15px 130px;
  }
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .eventTitle___3EMd8 {
  color: #414141;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .eventTitle___3EMd8 .titleIcon___319j- {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .eventTitle___3EMd8 .titleIcon___319j- .bolderColIcon___2Y67w {
  display: inline-block;
  background-color: #33c3c7;
  height: 53px;
  width: 13px;
  margin-right: 6px;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .eventTitle___3EMd8 .titleIcon___319j- .normalColIcon___T6kNR {
  display: inline-block;
  background-color: #33c3c7;
  height: 53px;
  width: 2px;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .eventTitle___3EMd8 h2 {
  margin: 0;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .expiredFree___cH2jd .expiredFreeContainer___60lbX .eventTitle___3EMd8 h2 {
    font-size: 25px;
  }
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .description___1_LPk {
  font-size: 14px;
  margin-bottom: 60px;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .btnGroup___2xDFF {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .expiredFree___cH2jd .expiredFreeContainer___60lbX .btnGroup___2xDFF {
    flex-wrap: wrap;
  }
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .btnGroup___2xDFF .btnCustom___3MQdr {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 74px;
  width: 100%;
  max-width: 336px;
  border-radius: 20px;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .btnGroup___2xDFF .btnGreen___2etSE {
  background-color: #33c3c7;
  color: #fff;
  border: 0;
}
@media screen and (max-width: 767px) {
  .expiredFree___cH2jd .expiredFreeContainer___60lbX .btnGroup___2xDFF .btnGreen___2etSE {
    margin-bottom: 30px;
  }
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .btnGroup___2xDFF .btnWhite___2IMk4 {
  background-color: #fff;
  color: #33c3c7;
  border: 3px solid #33c3c7;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .imgContent___2qe_M {
  text-align: center;
}
.expiredFree___cH2jd .expiredFreeContainer___60lbX .imgContent___2qe_M img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
