@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___2PoNY {
  display: flex;
  justify-content: center;
}
button.btn___1SJFe {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1SJFe {
    padding: 10px;
  }
}
button.btn___1SJFe:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1SJFe:focus {
  outline: none;
}
.btnWhite___2go-M.btn___1SJFe {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___2go-M.btn___1SJFe:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___3_As3.btn___1SJFe,
.btnGreen___3_As3.btn___1SJFe:hover,
.btnGreen___3_As3.btn___1SJFe:active,
.btnGreen___3_As3.btn___1SJFe:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3Gso1 {
  color: #fe0000;
}
.errorMessage___1Tn8h {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___3XX32 {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1sgrl {
  display: none !important;
}
.m-auto___1ncAB {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___2nXsJ {
  max-width: 120px !important;
}
.fc___1vNtO .fc-timegrid-col___QcBh-.fc-day-today___hi4T_ {
  background-color: #ffffff;
}
.fc___1vNtO .fc-timegrid-slot___3DIrX {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___1vNtO .fc-timegrid-slot___3DIrX {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___1F5Xa .fc-direction-ltr___3GSDG .fc-timegrid-slot-label-frame___iEoGN {
    font-size: 10px;
  }
  .bookingCalendar___1F5Xa .fc___1vNtO .fc-timegrid-slot-label-cushion___2FkXw {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___1F5Xa .fc___1vNtO .fc-scrollgrid-section-header___1dyh0 .fc-scroller___EsBss {
    overflow: unset !important;
  }
}
.ant-message-notice___Qiquk {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___1DfhM {
  width: 20px;
  height: 20px;
}
.bgTransparent___1sLfZ {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___3vGnc {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___3wfdX {
  position: relative;
}
.inputCustomValue___3wfdX .ant-input___sFHUo {
  padding-right: 50px;
}
.inputCustomValueText___3wmKG {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___gw2GV .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___gw2GV .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___2REZa .ant-radio-inner___35aAe {
  border-color: black !important ;
}
.ant-radio-checked___2REZa .ant-radio-inner___35aAe:after {
  background-color: black;
}
.ant-radio___2fQfg:hover .ant-radio-inner___35aAe {
  border-color: black;
}
.ant-radio-checked___2REZa .ant-radio-inner___35aAe:focus {
  border-color: black;
}
.modal___23N6e {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___23N6e .close___1CljC {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___23N6e .header___2RlIO {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___23N6e .header___2RlIO p {
  margin: 0;
}
.modal___23N6e .content___3VAv5 {
  text-align: center;
  font-size: 14px;
}
.modal___23N6e .content___3VAv5 p {
  margin: 0;
}
.modal___23N6e .actions___OmPGs {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___23N6e .actions___OmPGs .button___3f8Yg {
  margin-top: 10px;
}
.modal___23N6e .actions___OmPGs:hover {
  color: #33c3c7;
}
.fc___1vNtO .fc-timegrid-slot-minor___21NxJ {
  border-top-style: none;
}
.fc___1vNtO .fc-timegrid-slot___3DIrX {
  height: 1.5em !important;
}
.messageError___31g21 .ant-message-custom-content___261Wy {
  display: flex;
  text-align: start;
}
.messageError___31g21 .ant-message-custom-content___261Wy span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___DYcRZ {
  background-color: #e6e4e4;
}
.notifyMessage___1M4o6 .ant-message-notice-content___1m9ka {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___1M4o6 .ant-message-notice-content___1m9ka .anticon___1QOyi {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___1M4o6 .ant-message-notice-content___1m9ka span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3N39t {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3N39t .ant-notification-notice-message___uFNr0,
.notifyCustomize___3N39t .ant-notification-notice-close___JEbS7,
.notifyCustomize___3N39t .ant-notification-notice-icon___1pFj7 {
  color: white;
}
.notifyCustomize___3N39t .ant-notification-notice-message___uFNr0 {
  margin-left: 35px;
}
.notifyCustomize___3N39t p {
  margin-bottom: 0;
}
.notifyCustomize___3N39t p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3ocSF {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3ocSF .ant-notification-notice-message___uFNr0 {
  margin-left: 35px;
}
.notifyAlertDuplicate___3ocSF p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3ocSF p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1CUYi {
  width: 434px !important;
}
.handleSubmitVote___1CUYi .ant-btn-primary___2cGC3 {
  background: #59c3c7;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI .ant-modal-confirm-body___2d9zo {
  display: flex !important;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI .ant-modal-confirm-body___2d9zo .anticon___1QOyi {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI .ant-modal-confirm-btns___VXN0K button {
  font-weight: bold;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI .ant-modal-confirm-btns___VXN0K button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI .ant-modal-confirm-btns___VXN0K button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1CUYi .ant-modal-body___2gCVI .ant-modal-confirm-btns___VXN0K .ant-btn-primary___2cGC3 {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___z9dsu {
  margin-bottom: 0;
}
.mt-0___2Ijbs {
  margin-top: 0;
}
.tooltipFormat___1c0gk p {
  margin-bottom: 0;
}
.bgWhite___3GAZ8 {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___1DlY3 {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___1DlY3:hover,
.bgPrimaryBlue___1DlY3:focus,
.bgPrimaryBlue___1DlY3:active {
  background: #4f7ac7;
}
.bgDarkBlue___dFfvD {
  background-color: #023768 !important;
}
.bgDarkBlue___dFfvD:hover,
.bgDarkBlue___dFfvD:focus,
.bgDarkBlue___dFfvD:active {
  background: #194d7d;
}
.bgGreyBlue___C37Is {
  background-color: #ebf0f9;
}
.bgLightBlue___1cgjE {
  background-color: #b2cbf7 !important;
}
.bgLightRed___2IC0U {
  background-color: #e4453a;
}
.bgLightRed___2IC0U:hover,
.bgLightRed___2IC0U:focus,
.bgLightRed___2IC0U:active {
  background: #e17871;
}
.bgLightYellow___29GYK {
  background-color: #ffd603;
}
.bgDarkGray___i1vGV {
  background-color: #333333;
}
.bgMediumGray___1J3yn {
  background-color: #a2a2a2;
}
.bgLightGray___3_TKY {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___3hOTl {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___3lBEN {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___w1sS1 {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___1tkXC {
  color: #3368c7 !important;
}
.textDarkBlue___2Wc09 {
  color: #023768 !important;
}
.textLightBlue___2lKY_ {
  color: #b2cbf7;
}
.textDarkGray___1XnpC {
  color: #333333 !important;
}
.textMediumGray___27qfP {
  color: #a2a2a2 !important;
}
.textLightGray___1EZKf {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___2mIIM {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___3qxxF {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___2kPqu {
  border-radius: 5px !important;
}
.rounded-xs___3RUjJ {
  border-radius: 10px !important;
}
.rounded-sm___qAQRf {
  border-radius: 15px !important;
}
.rounded-md___39ktP {
  border-radius: 20px !important;
}
.rounded-lg___10gN2 {
  border-radius: 25px !important;
}
.rounded-full___18Sn- {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___2WbE2 {
  margin: 0;
}
.mt-2___2Yhu6 {
  margin-top: 0.5rem;
}
.my-2___1jcpC {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2pctn {
  padding: 0;
}
.px-1___1AmGi {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2tv3R {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___3YGET {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___3lyAz {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___3gtu8 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___9LYz7 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___3Oyvt {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___1p5-s {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___-YeSh {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___209HP {
  padding-bottom: 20px;
}
.h-full___2qiX3 {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___i64HU {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2hrKi {
  font-weight: bold !important;
}
.fontWeight400___1h4Zv {
  font-weight: 400 !important;
}
.fontWeight500___3QCZ7 {
  font-weight: 500 !important;
}
.fontWeight600___29pYP {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___El57g {
  font-size: 12px !important;
}
.fontSize14___ctnmL {
  font-size: 14px !important;
}
.fontSize16___3m48u {
  font-size: 16px !important;
}
.fontSize18___3cdgp {
  font-size: 18px !important;
}
.fontSize20___bExZs {
  font-size: 20px !important;
}
.fontSize24___251Ts {
  font-size: 24px !important;
}
.eventName___3RpnF {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___nruc5 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___1MJ4k > tr > th,
.ant-table-tbody___2HyRV > tr.ant-table-row___aQZU8 > td,
.ant-table-summary___19-1K tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___1MJ4k > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___3ebx7 .ant-table-thead___1MJ4k > tr > th,
.table-portrait___3ebx7 .ant-table-tbody___2HyRV > tr.ant-table-row___aQZU8 > td,
.table-portrait___3ebx7 .ant-table-summary___19-1K tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___3ebx7 .bgLightGray___3_TKY td {
  background: #ebebeb;
}
.table-portrait___3ebx7 .bgLightBlue___1cgjE td {
  background: #b2cbf7;
}
.eventContent___2shaO .timeText___15xyp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___2HyRV > tr.bgLightGray___3_TKY:hover > td,
.ant-table-tbody___2HyRV > tr > td.ant-table-cell-row-hover___3nrYK {
  background: #ebebeb;
}
.ant-table-tbody___2HyRV > tr.bgLightBlue___1cgjE:hover > td,
.ant-table-tbody___2HyRV > tr > td.ant-table-cell-row-hover___3nrYK {
  background: #b2cbf7;
}
.ant-table-tbody___2HyRV > tr.bgWhite___3GAZ8:hover > td,
.ant-table-tbody___2HyRV > tr > td.ant-table-cell-row-hover___3nrYK {
  background: #ffffff;
}
.login___2s8-r {
  max-width: 1080px;
  margin: auto;
  padding: 36px 15px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
}
.login___2s8-r .eventTitle___3PfuW {
  color: #3c3c3c;
  display: flex;
  align-items: center;
}
.login___2s8-r .eventTitle___3PfuW .titleIcon___PrTxL {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.login___2s8-r .eventTitle___3PfuW .titleIcon___PrTxL .bolderColIcon___3MH3B {
  display: inline-block;
  background-color: #33c3c7;
  margin-right: 5px;
  height: 30px;
  width: 8px;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .eventTitle___3PfuW .titleIcon___PrTxL .bolderColIcon___3MH3B {
    height: 27px;
    width: 6.5px;
  }
}
.login___2s8-r .eventTitle___3PfuW .titleIcon___PrTxL .normalColIcon___bPwFk {
  display: inline-block;
  background-color: #33c3c7;
  height: 30px;
  width: 4px;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .eventTitle___3PfuW .titleIcon___PrTxL .normalColIcon___bPwFk {
    height: 27px;
    width: 2px;
  }
}
.login___2s8-r .eventTitle___3PfuW h2 {
  font-size: 28px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .eventTitle___3PfuW h2 {
    font-size: 19px;
    font-weight: bold;
    margin-left: -11px;
  }
}
.login___2s8-r .eventTitle___3PfuW .scrollToCardPersonButton___wTuPf {
  font-size: 12.5px;
  background-color: #e8e6e6;
  padding: 1px 9px 3px;
  border-radius: 7px;
  margin: 0;
  cursor: pointer;
  font-weight: 500;
}
.login___2s8-r .loginHeader___2qJyQ {
  justify-content: space-between;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .loginHeader___2qJyQ {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}
.login___2s8-r .subTitle___t2nOf {
  margin-top: 8px;
  margin-left: 38px;
}
.login___2s8-r .subTitle___t2nOf span {
  margin-left: -8px;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .subTitle___t2nOf span {
    margin-left: -22px;
  }
}
.login___2s8-r .loginHeaderRightGreen___UbQ1c {
  background: #33c3c7;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  padding: 6px 15px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-left: 42px;
  margin-right: 14px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .loginHeaderRightGreen___UbQ1c {
    font-size: 16px;
    padding: 8.8px 2px;
    margin-left: 42px;
    margin-right: 14px;
  }
}
.login___2s8-r .loginHeaderRightGreen___UbQ1c::before {
  content: '';
  position: absolute;
  left: -25px;
  width: 0;
  height: 0;
  top: 0px;
  border-top: 21.5px solid transparent;
  border-bottom: 21.5px solid transparent;
  border-right: 25px solid #33c3c7;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .loginHeaderRightGreen___UbQ1c::before {
    left: -29.7px;
    border-top: 22px solid transparent;
    border-bottom: 21px solid transparent;
    border-right: 30px solid #33c3c7;
  }
}
.login___2s8-r .loginHeaderRightText___3X12Z {
  font-size: 12px;
  margin: 0 25px;
  margin-top: 7px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .login___2s8-r .loginHeaderRightText___3X12Z {
    margin: 0;
    margin-top: 7px;
  }
}
.login___2s8-r .loginCheckbox___1R1fH {
  text-align: center;
}
.login___2s8-r .labelCheckbox___3keIt {
  margin-left: 22px;
  font-size: 12px;
  position: relative;
  letter-spacing: 1px;
}
.login___2s8-r .labelCheckbox___3keIt a {
  color: #33c3c7;
  text-decoration: underline;
}
.login___2s8-r .checkTerm___175w2 {
  width: 100%;
  max-width: 465px;
  margin: auto;
}
.login___2s8-r .checkTerm___175w2 .preventClickCover___2A5IE {
  pointer-events: none;
  cursor: not-allowed;
}
.login___2s8-r .errorNotice___UvACy {
  color: #ff4d4f;
  font-size: 14px;
}
.login___2s8-r .main___33FbZ {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .main___33FbZ {
    display: block;
  }
}
.login___2s8-r .card___1SvX0 {
  width: 420px;
  position: relative;
  border-radius: 16px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
@media screen and (max-width: 832px) {
  .login___2s8-r .card___1SvX0 {
    margin: auto;
    width: 90%;
  }
}
.login___2s8-r .card___1SvX0:not(:last-child) {
  margin-right: 32px;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .card___1SvX0:not(:last-child) {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}
.login___2s8-r .card___1SvX0 .grid___1Ng3U {
  text-align: -webkit-center;
  position: relative;
  padding: 24px 24px;
  height: auto;
}
.login___2s8-r .card___1SvX0 .grid___1Ng3U:not(:first-child) {
  border-top: 1.5px solid rgba(0, 0, 0, 0.12);
}
.login___2s8-r .card___1SvX0 .grid___1Ng3U > div:not(:last-child) {
  margin-bottom: 16px;
}
.login___2s8-r .card___1SvX0 .title___tsn4N {
  display: flex;
  justify-content: center;
  width: 100%;
}
.login___2s8-r .card___1SvX0 .title___tsn4N h1 {
  margin-bottom: 0;
  font-weight: 500;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .card___1SvX0 .title___tsn4N h1 {
    font-size: 20px;
  }
}
.login___2s8-r .card___1SvX0 .title___tsn4N img {
  height: 35px;
  margin-right: 16px;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .card___1SvX0 .title___tsn4N img {
    height: 26px;
  }
}
.login___2s8-r .card___1SvX0 .footer___3_bN2 {
  padding: 32px;
}
.login___2s8-r .card___1SvX0 .footer___3_bN2 .loginLinkOther___2FGDt {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  text-align: center;
  color: #353535;
}
@media screen and (max-width: 832px) {
}
.login___2s8-r .card___1SvX0 .footer___3_bN2 .registerBtn___3Aj-x {
  font-size: 16px;
  color: #fff;
  background: #d8d8d8;
  display: block;
  text-align: center;
  margin: auto;
  border-radius: 6px;
  padding: 5px 40px;
  height: auto;
  width: 178px;
  font-weight: bold;
}
.login___2s8-r .loginButton___2hRY7 {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  background-color: #e3f4f4;
  width: 326px;
  border: 0;
  height: auto;
  padding: 16px 27px;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .loginButton___2hRY7 {
    font-size: 10.5px;
    padding: 10px 19px;
    width: 249px;
  }
}
.login___2s8-r .loginButton___2hRY7 img {
  margin-right: 16px;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .loginButton___2hRY7 img {
    margin-right: 8px;
  }
}
.login___2s8-r .loginButton___2hRY7 .rightSpan___3FrA_ {
  color: #33c3c7;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
@media screen and (max-width: 832px) {
  .login___2s8-r .loginButton___2hRY7 .rightSpan___3FrA_ {
    font-size: 10.5px;
  }
}
.login___2s8-r .loginButton___2hRY7:hover {
  background-color: #33c3c7;
  color: #fff !important;
}
.login___2s8-r .loginButton___2hRY7:hover span:last-child {
  color: #fff !important;
}
.login___2s8-r .ant-checkbox-input,
.login___2s8-r .ant-checkbox-inner {
  display: none;
}
.login___2s8-r .ant-checkbox-checked::after {
  display: none;
}
.login___2s8-r .ant-checkbox::before {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  border-radius: 6px;
  border: 3px solid #7f7f7f;
  top: -18px;
}
.login___2s8-r .ant-checkbox.ant-checkbox-checked::before {
  background: url(./static/icon-checked.3d17b49b.svg) center no-repeat;
  background-size: 70% 70%;
}
