@font-face {
  font-family: 'HGSGothic';
  src: url(./static/hgrsmp.029ba85b.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Regular.45fe8c8a.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Light.9130577d.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Noto Sans JP';
  src: url(./static/NotoSansJP-Medium.1b7dde00.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'NotoSansJP_Bold';
  src: url(./static/NotoSansJP-Bold.221b8841.ttf) format('truetype');
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: #333333;
  height: 100dvh;
}
pre {
  font-family: 'Noto Sans JP', sans-serif;
  white-space: pre-wrap;
}
ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
a {
  color: #414141;
}
a:hover {
  color: #414141;
}
button:focus {
  outline: none;
}
.ant-tabs-ink-bar {
  height: 8px !important;
  background-color: #3368c7 !important;
}
@media screen and (max-width: 767px) {
  .ant-tabs-ink-bar {
    height: 3px !important;
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #33c3c7 !important;
}
.ant-tabs-tab {
  padding: 0 0 12px 0;
}
.ant-tabs-tab:hover {
  color: #3c3c3c !important;
}
.ant-tabs-tab-btn:active {
  color: #33c3c7;
}
.ant-input:focus {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-input:hover {
  border: #33c3c7 2px solid !important;
  box-shadow: none;
}
.ant-form-item-has-error .ant-input:focus {
  border-right-width: 2px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
}
.fc-event-main {
  background-color: transparent;
}
.ant-switch-checked {
  background-color: #e3f4f4;
}
.ant-switch-inner {
  color: #454646;
  font-size: 9px;
  line-height: 1.5;
  padding: 0 3px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}
.fc-media-screen .fc-timegrid-event-harness {
  margin: 0 !important;
}
.fc-timegrid-event {
  border: 1px solid transparent;
}
.fc-timegrid-event .fc-event-time {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.fc-timegrid-event .fc-event-title {
  visibility: hidden;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.ant-picker:hover, .ant-picker-focused {
  border-right-width: 2px !important;
  box-shadow: none !important;
  border-color: #33c3c7 !important;
}
.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f !important;
  box-shadow: none !important;
}
.ant-select-single .ant-select-selector {
  border: 2px solid #ececec !important;
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid #33c3c7 !important;
}
.ant-modal-content {
  border: 1px solid #dadada;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 74, 116, 0.15);
}
.ant-input-affix-wrapper {
  border-width: 2px;
}
.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}
.ant-spin-dot-item {
  background-color: #33c3c7;
}
.ant-tooltip {
  max-width: 40%;
}
@media screen and (max-width: 600px) {
  .ant-tooltip {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .ant-tooltip {
    max-width: 70%;
  }
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e3f4f4;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #33c3c7;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:active::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:focus::after, .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:hover {
  background: #33c3c7;
  color: #fff;
}
.ant-picker-time-panel-column > li:hover .ant-picker-time-panel-cell-inner {
  color: #fff;
}
.ant-picker-time-panel-column > li:hover::after {
  color: #fff;
}
.ant-picker-time-panel-column > li:active, .ant-picker-time-panel-column > li:focus, .ant-picker-time-panel-column > li:visited {
  background: #e3f4f4;
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:focus .ant-picker-time-panel-cell-inner, .ant-picker-time-panel-column > li:visited .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-time-panel-column > li:active::after, .ant-picker-time-panel-column > li:focus::after, .ant-picker-time-panel-column > li:visited::after {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-panel-container {
  border: 1px solid #7e7d7d;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 0;
}
.ant-picker-content .ant-picker-time-panel-column:last-child {
  overflow-y: unset;
}
.btnGroup___eeTZh {
  display: flex;
  justify-content: center;
}
button.btn___1lTy4 {
  border-radius: 8px;
  font-size: 20px;
  padding: 10px 40px;
  width: 100%;
  max-width: 250px;
  height: auto;
}
@media screen and (max-width: 767px) {
  button.btn___1lTy4 {
    padding: 10px;
  }
}
button.btn___1lTy4:hover {
  opacity: 0.8;
  cursor: pointer;
}
button.btn___1lTy4:focus {
  outline: none;
}
.btnWhite___3XkQH.btn___1lTy4 {
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #909090;
  margin-right: 77px;
}
.btnWhite___3XkQH.btn___1lTy4:hover {
  background: #ffffff;
  color: #909090;
}
.btnGreen___2QPER.btn___1lTy4,
.btnGreen___2QPER.btn___1lTy4:hover,
.btnGreen___2QPER.btn___1lTy4:active,
.btnGreen___2QPER.btn___1lTy4:focus {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.required___3Uvs- {
  color: #fe0000;
}
.errorMessage___gG-JU {
  color: #fe0000;
  font-size: 13px;
  white-space: pre-line;
}
.random___K7FPC {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 20px;
}
textarea {
  resize: none;
}
.hideOption___1qydR {
  display: none !important;
}
.m-auto___27FJA {
  margin: auto;
}
.btnGreenOutline {
  background: #ffffff;
  color: #33c3c7;
  border: 2px solid #33c3c7;
}
.btnGreenOutline:hover {
  background-color: #33c3c7;
  color: #ffffff;
  border: 2px solid #33c3c7;
}
.btn-grey, .btn-grey:hover, .btn-grey:focus {
  background: #909090;
  color: #ffffff;
  border: #909090;
}
.btn-white__shadow {
  box-shadow: 1px 1px 10px #b3bcbc60;
  border: none;
}
.btn-white__shadow, .btn-white__shadow:hover {
  color: #3c3c3c;
}
.btn-custom-height {
  height: 49px !important;
  padding: 4px 15px !important;
  border-radius: 20px !important;
}
.btn-small {
  padding: 4px 15px !important;
}
.loading-page {
  height: 100vh;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ant-tooltip-inner {
  word-break: break-all;
}
.modalPeriod .ant-modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modalPeriod .ant-modal-body {
  padding-top: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal-content .ant-modal-close .ant-modal-close-icon {
  background-color: #3368c7 !important;
}
.btn-small-width___3N93d {
  max-width: 120px !important;
}
.fc___8VN1d .fc-timegrid-col___IpVF5.fc-day-today___HLe-n {
  background-color: #ffffff;
}
.fc___8VN1d .fc-timegrid-slot___2_9J8 {
  height: 40px;
  max-width: 50px !important;
}
@media screen and (max-width: 767px) {
  .fc___8VN1d .fc-timegrid-slot___2_9J8 {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .bookingCalendar___3709m .fc-direction-ltr___3Vxxc .fc-timegrid-slot-label-frame___3tKp4 {
    font-size: 10px;
  }
  .bookingCalendar___3709m .fc___8VN1d .fc-timegrid-slot-label-cushion___3BRag {
    white-space: break-spaces;
    text-align: center;
  }
  .bookingCalendar___3709m .fc___8VN1d .fc-scrollgrid-section-header___1lfTD .fc-scroller___JZGFJ {
    overflow: unset !important;
  }
}
.ant-message-notice___4Usx1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
button:hover {
  opacity: 0.8;
}
.helper___3PO_7 {
  width: 20px;
  height: 20px;
}
.bgTransparent___3C8L0 {
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.customTitleDescript___2d6j0 {
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
}
.inputCustomValue___1_sGi {
  position: relative;
}
.inputCustomValue___1_sGi .ant-input___1MbZQ {
  padding-right: 50px;
}
.inputCustomValueText___2O4du {
  position: absolute;
  top: 47px;
  right: 10px;
  background: #fff;
}
.logoutConfirm___32UsC .ant-btn {
  background: #1890ff;
  color: #fff;
  border: 0;
}
.logoutConfirm___32UsC .ant-btn-primary {
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
}
.ant-radio-checked___vLCu_ .ant-radio-inner___1fC8V {
  border-color: black !important ;
}
.ant-radio-checked___vLCu_ .ant-radio-inner___1fC8V:after {
  background-color: black;
}
.ant-radio___AXag4:hover .ant-radio-inner___1fC8V {
  border-color: black;
}
.ant-radio-checked___vLCu_ .ant-radio-inner___1fC8V:focus {
  border-color: black;
}
.modal___29yxC {
  font-size: 12px;
  width: 328px;
  height: 355px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 5px #b3bcbc60;
  border-radius: 10px;
}
.modal___29yxC .close___Yukep {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 1rem;
  line-height: 24px;
  font-size: 18px;
  width: 24px;
  height: 24px;
}
.modal___29yxC .header___3sBGB {
  text-align: center;
  font-size: 16px;
  margin-top: -10px;
  font-weight: bold;
}
.modal___29yxC .header___3sBGB p {
  margin: 0;
}
.modal___29yxC .content___1IoqI {
  text-align: center;
  font-size: 14px;
}
.modal___29yxC .content___1IoqI p {
  margin: 0;
}
.modal___29yxC .actions___3Ox7c {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  border-radius: 5px;
  width: 70%;
  border: none;
  text-align: center;
  line-height: 40px;
  box-shadow: 0 0 5px #b3bcbc60;
}
.modal___29yxC .actions___3Ox7c .button___2iLOh {
  margin-top: 10px;
}
.modal___29yxC .actions___3Ox7c:hover {
  color: #33c3c7;
}
.fc___8VN1d .fc-timegrid-slot-minor___ShGeG {
  border-top-style: none;
}
.fc___8VN1d .fc-timegrid-slot___2_9J8 {
  height: 1.5em !important;
}
.messageError___2IN6- .ant-message-custom-content___32aiz {
  display: flex;
  text-align: start;
}
.messageError___2IN6- .ant-message-custom-content___32aiz span:first-child {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.bgGrey___3b1WC {
  background-color: #e6e4e4;
}
.notifyMessage___eZo01 .ant-message-notice-content___2YREU {
  background-color: #de4a39;
  position: relative;
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 21px;
  max-width: 900px;
  width: auto;
}
.notifyMessage___eZo01 .ant-message-notice-content___2YREU .anticon___22rAE {
  font-size: 13px;
  position: absolute;
  right: 0;
  color: white;
  top: 10px;
}
.notifyMessage___eZo01 .ant-message-notice-content___2YREU span:last-child {
  color: white !important;
  text-align: left !important;
  float: left;
}
.notifyCustomize___3gEac {
  background-color: red;
  padding: 16px 14px;
}
.notifyCustomize___3gEac .ant-notification-notice-message___id38M,
.notifyCustomize___3gEac .ant-notification-notice-close___2CX5A,
.notifyCustomize___3gEac .ant-notification-notice-icon___1y8yr {
  color: white;
}
.notifyCustomize___3gEac .ant-notification-notice-message___id38M {
  margin-left: 35px;
}
.notifyCustomize___3gEac p {
  margin-bottom: 0;
}
.notifyCustomize___3gEac p span {
  cursor: pointer;
  font-weight: 600;
}
.notifyAlertDuplicate___3KW9u {
  padding: 16px 14px;
}
.notifyAlertDuplicate___3KW9u .ant-notification-notice-message___id38M {
  margin-left: 35px;
}
.notifyAlertDuplicate___3KW9u p {
  margin-bottom: 0;
}
.notifyAlertDuplicate___3KW9u p span {
  cursor: pointer;
  font-weight: 600;
  color: #161615;
}
.handleSubmitVote___1CB8C {
  width: 434px !important;
}
.handleSubmitVote___1CB8C .ant-btn-primary___uetBb {
  background: #59c3c7;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar {
  padding: 15px 30px 18px;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar .ant-modal-confirm-body___9d5We {
  display: flex !important;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar .ant-modal-confirm-body___9d5We .anticon___22rAE {
  margin-bottom: auto;
  margin-top: auto;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar .ant-modal-confirm-btns___2QpPe button {
  font-weight: bold;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar .ant-modal-confirm-btns___2QpPe button:first-child {
  border: 1px solid #3c3c3c;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar .ant-modal-confirm-btns___2QpPe button:first-child:hover {
  border-color: #3c3c3c;
}
.handleSubmitVote___1CB8C .ant-modal-body___2LNar .ant-modal-confirm-btns___2QpPe .ant-btn-primary___uetBb {
  background: #59c3c7;
  border: 1px solid #59c3c7;
}
.mb-0___3EKet {
  margin-bottom: 0;
}
.mt-0___33fLX {
  margin-top: 0;
}
.tooltipFormat___13v3E p {
  margin-bottom: 0;
}
.bgWhite___2m2WM {
  background-color: #ffffff;
}
/* Background Color Classes */
.bgPrimaryBlue___3cD1q {
  background-color: #3368c7 !important;
}
.bgPrimaryBlue___3cD1q:hover,
.bgPrimaryBlue___3cD1q:focus,
.bgPrimaryBlue___3cD1q:active {
  background: #4f7ac7;
}
.bgDarkBlue___1Eatv {
  background-color: #023768 !important;
}
.bgDarkBlue___1Eatv:hover,
.bgDarkBlue___1Eatv:focus,
.bgDarkBlue___1Eatv:active {
  background: #194d7d;
}
.bgGreyBlue___cq9Ln {
  background-color: #ebf0f9;
}
.bgLightBlue___3Oxo7 {
  background-color: #b2cbf7 !important;
}
.bgLightRed___12Yqm {
  background-color: #e4453a;
}
.bgLightRed___12Yqm:hover,
.bgLightRed___12Yqm:focus,
.bgLightRed___12Yqm:active {
  background: #e17871;
}
.bgLightYellow___fQEWa {
  background-color: #ffd603;
}
.bgDarkGray___11kB3 {
  background-color: #333333;
}
.bgMediumGray___2SLaV {
  background-color: #a2a2a2;
}
.bgLightGray___2JPg3 {
  background-color: #ebebeb;
}
/* Border Color Classes */
.borderMediumGray___Ik_kT {
  border: 1px solid #a2a2a2 !important;
}
.borderPrimaryBlue___69eAp {
  border: 1px solid #3368c7 !important;
}
.borderPrimaryLight___CK7Sn {
  border: 1px solid #ebebeb !important;
}
/* Text Color Classes */
.textPrimaryBlue___3Lb53 {
  color: #3368c7 !important;
}
.textDarkBlue___1wjPJ {
  color: #023768 !important;
}
.textLightBlue___2L6GU {
  color: #b2cbf7;
}
.textDarkGray___3zvhT {
  color: #333333 !important;
}
.textMediumGray___39l6F {
  color: #a2a2a2 !important;
}
.textLightGray___ALbA- {
  color: #ebebeb !important;
}
/* Box Shadow Classes */
.shadowPrimary___25sO7 {
  box-shadow: 0 3px 7px 1px rgba(50, 50, 50, 0.2) !important;
}
.shadowSecondary___38B0o {
  box-shadow: 0 0 5px 0 #565656 !important;
}
/* Border Radius Classes */
.rounded___G0vJB {
  border-radius: 5px !important;
}
.rounded-xs___1Pj_5 {
  border-radius: 10px !important;
}
.rounded-sm___VYxe4 {
  border-radius: 15px !important;
}
.rounded-md___2UhlU {
  border-radius: 20px !important;
}
.rounded-lg___zEBE_ {
  border-radius: 25px !important;
}
.rounded-full___1voZK {
  border-radius: 50% !important;
}
/* Margin Classes */
.m0___3X0TE {
  margin: 0;
}
.mt-2___1CBGD {
  margin-top: 0.5rem;
}
.my-2___zflVp {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/* Padding Classes */
.p0___2agVS {
  padding: 0;
}
.px-1___2K6Qt {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2___2ppQ9 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3___2YyEa {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4___2cUJs {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0___2V2Fu {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1___2GsTQ {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2___2N1vo {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3___2i-N- {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4___32Mx0 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pb-2___2KW58 {
  padding-bottom: 20px;
}
.h-full___3LlUR {
  height: 100% !important;
}
/* Background Opacity Classes */
.bgPrimaryWhiteOpacity___3S43I {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
/* Font Weight Classes */
.fontWeightBold___2ediE {
  font-weight: bold !important;
}
.fontWeight400___OUMcN {
  font-weight: 400 !important;
}
.fontWeight500___eCOzu {
  font-weight: 500 !important;
}
.fontWeight600___dGjYn {
  font-weight: 600 !important;
}
/* Font Size Classes */
.fontSize12___3P4aH {
  font-size: 12px !important;
}
.fontSize14___3zQKp {
  font-size: 14px !important;
}
.fontSize16___2ttHj {
  font-size: 16px !important;
}
.fontSize18___26Ct- {
  font-size: 18px !important;
}
.fontSize20___2AkU- {
  font-size: 20px !important;
}
.fontSize24___1yq2W {
  font-size: 24px !important;
}
.eventName___3LJtI {
  white-space: break-spaces;
  line-break: anywhere;
}
.truncate___1LN_m {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-table-thead___3CoUu > tr > th,
.ant-table-tbody___3OwQT > tr.ant-table-row___2PBm_ > td,
.ant-table-summary___wsSVd tfoot > tr > td {
  text-align: center !important;
  padding: 0 !important;
}
.ant-table-thead___3CoUu > tr > th {
  background: #5b8bfc;
  color: #fff;
  font-weight: 600;
  padding: 10px 4px;
  height: 38.84px;
}
.table-portrait___22UhX .ant-table-thead___3CoUu > tr > th,
.table-portrait___22UhX .ant-table-tbody___3OwQT > tr.ant-table-row___2PBm_ > td,
.table-portrait___22UhX .ant-table-summary___wsSVd tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.table-portrait___22UhX .bgLightGray___2JPg3 td {
  background: #ebebeb;
}
.table-portrait___22UhX .bgLightBlue___3Oxo7 td {
  background: #b2cbf7;
}
.eventContent___MJ8Sh .timeText___2YA66 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
}
.ant-table-tbody___3OwQT > tr.bgLightGray___2JPg3:hover > td,
.ant-table-tbody___3OwQT > tr > td.ant-table-cell-row-hover___3b7DG {
  background: #ebebeb;
}
.ant-table-tbody___3OwQT > tr.bgLightBlue___3Oxo7:hover > td,
.ant-table-tbody___3OwQT > tr > td.ant-table-cell-row-hover___3b7DG {
  background: #b2cbf7;
}
.ant-table-tbody___3OwQT > tr.bgWhite___2m2WM:hover > td,
.ant-table-tbody___3OwQT > tr > td.ant-table-cell-row-hover___3b7DG {
  background: #ffffff;
}
.signUp___19UK9 {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 80px;
}
.signUp___19UK9 .bodyContent___2rKgL {
  padding: 30px 0 30px;
}
.signUp___19UK9 .bodyContent___2rKgL p {
  font-size: 24px;
  text-indent: 37px;
}
@media screen and (max-width: 767px) {
  .signUp___19UK9 .bodyContent___2rKgL p {
    text-indent: 0;
  }
}
.signUp___19UK9 .bodyContent___2rKgL .headTitle___GoaA7 {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .signUp___19UK9 .bodyContent___2rKgL .headTitle___GoaA7 {
    margin-bottom: 20px;
  }
}
.signUp___19UK9 .bodyContent___2rKgL .headTitle___GoaA7 .bolderIcon___27vPd {
  width: 12px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.signUp___19UK9 .bodyContent___2rKgL .headTitle___GoaA7 .titleIcon___wJVTk {
  width: 5px;
  height: 52px;
  background-color: #33c3c7;
  margin-right: 8px;
  display: inline-block;
}
.signUp___19UK9 .bodyContent___2rKgL .headTitle___GoaA7 span {
  color: #3c3c3c;
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .signUp___19UK9 .bodyContent___2rKgL .headTitle___GoaA7 span {
    font-size: 35px;
  }
}
.signUp___19UK9 .bodyContent___2rKgL .intruction___1pwF_ {
  font-size: 18px;
}
.signUp___19UK9 .bodyContent___2rKgL .inputField___195FK {
  margin: 0 auto 20px auto;
}
.signUp___19UK9 .bodyContent___2rKgL .inputField___195FK .fieldFlex___3YE91 {
  display: flex;
  justify-content: space-between;
}
.signUp___19UK9 .bodyContent___2rKgL .inputField___195FK .fieldLabel___19ltl {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.signUp___19UK9 .bodyContent___2rKgL .inputField___195FK .fieldLabel___19ltl .inputRequired___HF7K_ {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 10px;
}
.signUp___19UK9 .bodyContent___2rKgL .inputField___195FK input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.signUp___19UK9 .bodyContent___2rKgL .inputField___195FK .errorNotice___1qOhp {
  color: #ff4d4f;
  font-size: 14px;
}
.signUp___19UK9 .bodyContent___2rKgL .errorNoticeTerm___3rR2h {
  color: #ff4d4f;
  font-size: 14px;
  text-align: center;
}
.signUp___19UK9 .bodyContent___2rKgL .btnZone___15Dir {
  margin-top: 20px;
  margin-bottom: 25px;
}
.signUp___19UK9 .bodyContent___2rKgL .btnZone___15Dir .signUpBtn___3GWu5 {
  width: 100%;
  height: 40px;
  background-color: #023768;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.signUp___19UK9 .bodyContent___2rKgL .btnZone___15Dir .signUpBtn___3GWu5:hover {
  background-color: #1f4f96;
}
.signUp___19UK9 .bodyContent___2rKgL .card___3Fvr1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.signUp___19UK9 .bodyContent___2rKgL .card___3Fvr1 .loginButton___qWSmf {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  color: #555555;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px solid #999999;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2);
}
.signUp___19UK9 .bodyContent___2rKgL .card___3Fvr1 .loginButton___qWSmf img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.signUp___19UK9 .checkTerm___1ehgf {
  margin: 0;
  text-align: center;
  font-weight: 400;
}
.signUp___19UK9 .checkTerm___1ehgf a {
  color: #40a9ff;
  text-decoration: underline;
}
.signUp___19UK9 .ant-form-item {
  margin: 0;
}
.signUp___19UK9 .ant-form {
  max-width: 1080px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
.signUp___19UK9 .ant-form .fieldFlex___3YE91 .ant-row .ant-col > div:nth-child(2) {
  width: 150px;
}
.note___2ue41 {
  color: #eb3c03;
  font-weight: 600;
}
.termOfUser___2-Ksv {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.termOfUser___2-Ksv h1 {
  width: 100%;
  text-align: center;
  margin: auto;
  font-weight: bold;
}
.termOfUser___2-Ksv .title___eqIyJ {
  font-size: 1.2rem;
  letter-spacing: 0;
  color: #161615;
  margin-bottom: 10px;
  font-weight: bold;
}
.termOfUser___2-Ksv p {
  font-size: 0.8rem;
  letter-spacing: 0;
  color: #161615;
  margin-bottom: 10px;
  text-align: justify;
  text-justify: inter-word;
}
.termOfUser___2-Ksv .termOfUserFooter___2VDpe {
  width: 100%;
}
